import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { Facet } from 'ish-core/models/facet/facet.model';
import { Filter } from 'ish-core/models/filter/filter.model';
import { URLFormParams } from 'ish-core/utils/url-form-params';

@Component({
  selector: 'camfil-filter-dropdown',
  templateUrl: './camfil-filter-dropdown.component.html',
  styleUrls: ['./camfil-filter-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CamfilFilterDropdownComponent implements OnInit {
  @Input() filterElement: Filter;
  @Input() placeholderType: 'groupName' | 'selectedFacets' = 'groupName';
  @Output() applyFilter: EventEmitter<{ searchParameter: URLFormParams }> = new EventEmitter();

  placeholder = '';
  selectedFacets: Facet[] = [];

  get multipleSelection() {
    return this.filterElement?.selectionType?.startsWith('multi');
  }

  apply(facets: Facet[]) {
    const selectedFacetNames = facets.map(facet => facet.name);

    const removedFacets = this.filterElement.facets.filter(
      facet => facet.selected && !selectedFacetNames.includes(facet.name)
    );

    for (const removedFacet of removedFacets) {
      this.applyFilter.emit({ searchParameter: removedFacet.searchParameter });
    }

    const addedFacets = this.filterElement.facets.filter(
      facet => !facet.selected && selectedFacetNames.includes(facet.name)
    );

    for (const addedFacet of addedFacets) {
      this.applyFilter.emit({ searchParameter: addedFacet.searchParameter });
    }
  }

  trackByFn(_: number, item: Facet) {
    return item.name;
  }

  ngOnInit() {
    this.initPlaceHolder();
  }

  private initPlaceHolder() {
    this.placeholder = this.filterElement.name;
    this.selectedFacets = this.filterElement.facets.filter(x => x.selected);

    if (this.placeholderType === 'selectedFacets') {
      const placeholder = this.selectedFacets.map(x => x.displayName).join(', ');

      if (placeholder) {
        this.placeholder = placeholder;
      }
    }
  }
}
