import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { CamfilBasketService } from 'camfil-core/services/camfil-basket/camfil-basket.service';
import { concatMap, filter, mergeMap } from 'rxjs';

import { mapErrorToAction, mapToPayload } from 'ish-core/utils/operators';

import {
  loadCustomerDeliveryTerm,
  loadCustomerDeliveryTermFail,
  loadCustomerDeliveryTermSuccess,
} from './camfil-customer-delivery-terms.actions';
import { getSelectedCamfilCustomersDeliveryTerms } from './camfil-customer-delivery-terms.selectors';

@Injectable()
export class CamfilCustomerDeliveryTermsEffects {
  constructor(private actions$: Actions, private store: Store, private camfilBasketService: CamfilBasketService) {}

  loadCustomerDeliveryTerm$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadCustomerDeliveryTerm),
      mapToPayload(),
      concatLatestFrom(() => this.store.pipe(select(getSelectedCamfilCustomersDeliveryTerms))),
      filter(([, term]) => !term),
      concatMap(([{ customerId }]) =>
        this.camfilBasketService.loadCustomerDeliveryTerm(customerId).pipe(
          mergeMap(term => [loadCustomerDeliveryTermSuccess({ term })]),
          mapErrorToAction(loadCustomerDeliveryTermFail)
        )
      )
    )
  );
}
