<!-- eslint-disable @angular-eslint/template/mouse-events-have-key-events -->
<ul class="navbar-nav main-navigation-list w-100 overflow-scroll text-nowrap">
  <ng-container *ngFor="let category of categories$ | async">
    <li
      #submenu
      class="dropdown"
      tabindex="0"
      [ngClass]="{ open: isOpened(category.uniqueId) }"
      (mouseover)="subMenuShow(submenu)"
      (mouseleave)="subMenuHide(submenu)"
      (click)="toggleSubMenu($event, submenu)"
      (keydown.enter)="toggleSubMenu($event, submenu)"
    >
      <ng-container *ngIf="isMobileView">
        <p class="pseudo-mobile-link">
          {{ category.name }}
        </p>
      </ng-container>
      <a class="category-link" [routerLink]="category.url" [ngStyle]="{ width: !category.hasChildren ? '100%' : '' }">
        {{ category.name }}
      </a>
      <ng-container *ngIf="category.hasChildren">
        <a
          class="dropdown-toggle category-link"
          (click)="toggleOpen(category.uniqueId)"
          (keydown.enter)="toggleOpen(category.uniqueId)"
          tabindex="0"
        >
          <mat-icon>arrow_{{ isOpened(category.uniqueId) ? 'left' : 'right' }}</mat-icon>
        </a>
        <camfil-subcategory-navigation
          [categoryUniqueId]="category.uniqueId"
          [subCategoriesDepth]="1"
          [url]="category.url"
        />
      </ng-container>
    </li>
  </ng-container>
  <li class="dropdown with-separator" *ngIf="isLoggedIn$ | async">
    <camfil-product-compare-status *ishFeature="'compare'" />
  </li>
  <li class="dropdown with-separator" *ngIf="isLoggedIn$ | async">
    <a [routerLink]="[ahuUrl$ | async]">
      {{ 'camfil.ahu.link' | translate }}
    </a>
  </li>
  <li class="dropdown with-separator" *ngIf="isLoggedIn$ | async">
    <a class="category-link" [routerLink]="['/account/camcards']">{{ 'camfil.account.cam_card.link' | translate }}</a>
  </li>
  <li class="dropdown with-separator" *ngIf="isAuthorizedToViewOrderHistory$ | async">
    <a [routerLink]="['/account/orders']">
      {{ 'account.order_history.link' | translate }}
    </a>
  </li>
  <ng-container *ishHasNotRole="['APP_B2B_CXML_USER', 'APP_B2B_OCI_USER']">
    <li class="dropdown with-separator" *ngIf="isAuthorizedToViewQuotes$ | async">
      <a [routerLink]="['/account/quotes/']">
        {{ 'account.quotes.link' | translate }}
      </a>
    </li>
  </ng-container>
  <li class="dropdown with-separator" *ngIf="isAuthorizedToViewPunchout$ | async">
    <a [routerLink]="['/account/punchout']">
      {{ 'account.punchout.link' | translate }}
    </a>
  </li>
</ul>
