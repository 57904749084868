/* eslint-disable ish-custom-rules/no-object-literal-type-assertion, ish-custom-rules/no-intelligence-in-artifacts, rxjs/no-nested-subscribe */
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  HostBinding,
  Inject,
  OnInit,
  inject,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Actions, ofType } from '@ngrx/effects';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { CamfilAccountFacade } from 'camfil-core/facades/camfil-account.facade';
import { CamCardsFacade } from 'camfil-core/facades/camfil-cam-cards.facade';
import { CamfilConfigurationFacade } from 'camfil-core/facades/camfil-configuration.facade';
import { CamfilCustomersFacade } from 'camfil-core/facades/camfil-customers.facade';
import { createCamCardFail, createCamCardSuccess } from 'camfil-core/store/cam-cards/cam-cards.actions';
import { CamfilAddress } from 'camfil-models/camfil-address/camfil-address.model';
import { AddToCamCardItem, CamCard } from 'camfil-models/camfil-cam-card/cam-card.model';
import { CamfilMaxLengthKey } from 'camfil-models/camfil-configuration/camfil-configuration.model';
import { CamfilCustomer } from 'camfil-models/camfil-customer/camfil-customer.model';
import { markAsTouchedRecursive } from 'camfil-shared/formly/utils/camfil-form-utils';
import { CamfilFormsService } from 'camfil-shared/formly/utils/forms.service';
import {
  BehaviorSubject,
  Observable,
  Subject,
  combineLatest,
  distinctUntilChanged,
  map,
  race,
  switchMap,
  take,
} from 'rxjs';
import { startWith } from 'rxjs/operators';

import { whenTruthy } from 'ish-core/utils/operators';

export type CamCardCreateDialogComponentData = AddToCamCardItem[];

export interface CamCardCreateDialogComponentResult {
  id: string;
  name: string;
}

@Component({
  templateUrl: './cam-card-create-dialog.component.html',
  styleUrls: ['./cam-card-create-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CamCardCreateDialogComponent implements OnInit, AfterViewInit {
  @HostBinding('class.camfil-dialog') camfilDialogClass = true;

  form = new UntypedFormGroup({});
  fields: FormlyFieldConfig[];

  customers$: Observable<CamfilCustomer[]>;
  addresses$: Subject<CamfilAddress[]> = new BehaviorSubject([]);
  zipCodesLoading$: Observable<boolean>;

  private maxLengthValues: Record<CamfilMaxLengthKey, number>;

  private destroyRef = inject(DestroyRef);

  constructor(
    public matDialogRef: MatDialogRef<CamCardCreateDialogComponent, CamCardCreateDialogComponentResult>,
    private accountFacade: CamfilAccountFacade,
    private camCardsFacade: CamCardsFacade,
    private camfilCustomersFacade: CamfilCustomersFacade,
    private cdr: ChangeDetectorRef,
    private actions$: Actions,
    private camfilConfigurationFacade: CamfilConfigurationFacade,
    // eslint-disable-next-line ish-custom-rules/use-type-safe-injection-token
    @Inject(MAT_DIALOG_DATA) public dialogData: CamCardCreateDialogComponentData
  ) {}

  ngOnInit() {
    this.camfilConfigurationFacade.allMaxLengths$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(value => {
      this.maxLengthValues = value;
    });
    this.customers$ = this.camfilCustomersFacade.customers$;
    this.zipCodesLoading$ = this.accountFacade.zipCodesLoading$;
    this.fields = this.getFields();
  }

  // TODO (extMlk): Very bad code, needs to be refactored and cleaned up by using camfil field library;
  private getFields(): FormlyFieldConfig[] {
    return [
      {
        fieldGroup: [
          {
            key: 'name',
            type: 'camfil-text-input-field',
            props: {
              label: 'camfil.modal.createCamcard.input.name',
              required: true,
            },
            validators: {
              validation: [Validators.maxLength(this.maxLengthValues.camCardTitle)],
            },
            validation: {
              messages: {
                maxlength: 'camfil.modal.createCamcard.input.name.error.maxlength',
                required: 'camfil.modal.createCamcard.input.name.error.required',
              },
            },
          },
          {
            key: 'customer',
            type: 'camfil-select-field',
            hooks: {
              onInit: (field: FormlyFieldConfig) => {
                const customerControl = field.form.get('customer');

                customerControl.valueChanges
                  .pipe(startWith(customerControl.value), takeUntilDestroyed(this.destroyRef))
                  .subscribe((value: string) => {
                    if (value === '') {
                      field.form.get('customer').setValue(undefined);
                      this.addresses$.next([]);
                      return;
                    }
                    this.camfilCustomersFacade
                      .getDeliveryAddressesForCustomer$(value)
                      .pipe(take(1), takeUntilDestroyed(this.destroyRef))
                      .subscribe(addresses => {
                        this.addresses$.next(addresses);
                      });
                  });
              },
            },
            props: {
              placeholder: 'camfil.modal.createOrder.order-form.select.customer',
              options: CamfilFormsService.getCamfilCustomersOptions(this.customers$),
            },
          },
          {
            key: 'orderMark',
            type: 'camfil-text-input-field',
            props: {
              label: 'camfil.modal.createCamcard.input.order_mark',
            },
            validators: {
              validation: [Validators.maxLength(this.maxLengthValues.orderMark)],
            },
            validation: {
              messages: {
                maxlength: 'camfil.checkout.order_header.order_mark.error.maxLength',
              },
            },
          },
          {
            key: 'invoiceLabel',
            type: 'camfil-text-input-field',
            props: {
              label: 'camfil.modal.createCamcard.input.invoice_mark',
            },
            validators: {
              validation: [Validators.maxLength(this.maxLengthValues.invoiceLabel)],
            },
            validation: {
              messages: {
                maxlength: 'camfil.checkout.order_header.invoice_label.error.maxLength',
              },
            },
          },
          {
            key: 'deliveryAddressSelect',
            type: 'camfil-select-field',
            hooks: {
              onInit: (field: FormlyFieldConfig) => {
                field.props.options = CamfilFormsService.getDeliveryAddressOptions(this.addresses$);

                field.form
                  .get('customer')
                  .valueChanges.pipe(takeUntilDestroyed(this.destroyRef))
                  .subscribe((value: string) => {
                    field.form.get('deliveryAddressSelect').setValue(undefined);
                    if (value) {
                      field.props.options = CamfilFormsService.getDeliveryAddressOptions(this.addresses$);
                    }
                  });

                field.form
                  .get('deliveryAddressSelect')
                  .valueChanges.pipe(
                    switchMap(value =>
                      this.addresses$.pipe(
                        take(1),
                        map(addresses => ({ value, addresses })),
                        takeUntilDestroyed(this.destroyRef)
                      )
                    ),
                    takeUntilDestroyed(this.destroyRef)
                  )
                  .subscribe(({ value, addresses }) => {
                    if (value === '') {
                      this.pickAddress(undefined);
                    } else if (value) {
                      const selectedAddress = addresses?.find(element => element.id === value);
                      this.pickAddress(selectedAddress);
                    }
                  });
              },
            },
            validation: {
              messages: {
                required: 'camfil.modal.createOrder.order-form.input.addressLine1.error.required',
              },
            },
            props: {
              label: 'camfil.modal.createOrder.order-form.select.address',
              options: [{ label: '--', value: '' }],
            },
          },
          {
            key: 'company',
            type: 'camfil-text-input-field',
            validators: {
              validation: [Validators.maxLength(this.maxLengthValues.company)],
            },
            props: {
              label: 'camfil.modal.createCamcard.input.company',
              required: true,
            },
            validation: {
              messages: {
                required: 'camfil.modal.createOrder.order-form.input.companyName.error.required',
              },
            },
          },
          {
            key: 'addressLine1',
            type: 'camfil-textarea-field',
            props: {
              label: 'camfil.modal.createCamcard.input.addressLine1',
              required: true,
              rows: 3,
              autosize: false,
            },
            validation: {
              messages: {
                required: 'camfil.modal.createCamcard.input.addressLine1.error.required',
              },
            },
          },
          {
            key: 'addressLine2',
            type: 'camfil-textarea-field',
            props: {
              label: 'account.default_address.street2.label',
              rows: 5,
              autosize: false,
            },
            expressions: {
              hide: this.camfilConfigurationFacade.isEnabled$('use2ndAddressLineInCamCardForm').pipe(map(v => !v)),
            },
          },
          {
            fieldGroupClassName: 'd-flex justify-content-between',
            fieldGroup: [
              {
                key: 'zipcode',
                type: 'camfil-postal-code-input-field',
                props: {
                  label: 'camfil.account.cam_card.add_to_form.postal_code',
                  required: true,
                },
                validation: {
                  messages: {
                    zipCodePattern: 'camfil.account.apply_form.zipCode.error.pattern',
                    incorrectZipCode: 'camfil.account.apply_form.zipCode.error.invalid',
                    required: 'camfil.account.apply_form.zipCode.error.required',
                  },
                },
              },
              {
                key: 'city',
                type: 'camfil-city-dynamic-field',
                props: {
                  label: 'camfil.form.pick_city',
                  required: true,
                  hideRequiredMarker: true,
                },
                validation: {
                  messages: {
                    required: 'camfil.modal.createOrder.order-form.input.city.error.required',
                  },
                },
              },
            ],
          },
        ],
        fieldGroupClassName: 'formly-wrapper',
      },
    ];
  }

  pickAddress(address: CamfilAddress | undefined) {
    this.form?.patchValue({
      company: address?.addressName,
      addressLine1: address?.addressLine1,
      addressLine2: address?.addressLine2,
      zipcode: address?.postalCode,
    });
  }

  ngAfterViewInit(): void {
    this.customers$
      .pipe(whenTruthy(), distinctUntilChanged(), takeUntilDestroyed(this.destroyRef))
      .subscribe(customers => {
        if (customers.length === 1) {
          this.form.get('customer')!.setValue(customers[0].id);
        }
        this.cdr.detectChanges();
      });
  }

  submitForm(): void {
    if (this.form.invalid) {
      markAsTouchedRecursive(this.form);
      return;
    }

    const {
      name,
      customer,
      orderMark,
      invoiceLabel,
      company,
      addressLine1,
      addressLine2,
      zipcode,
      city,
      deliveryAddressSelect,
    } = this.form.value;

    this.form.disable();

    // TODO (extMlk): Very bad code, needs to be refactored and cleaned up by using camfil field library;
    combineLatest([this.addresses$, this.customers$, this.camfilConfigurationFacade.countryCode$])
      .pipe(
        take(1),
        switchMap(([addresses, customers, countryCode]) => {
          this.camCardsFacade.addCamCard(
            {
              name,
              orderLabel: orderMark,
              invoiceLabel,
              customer: customers.find(({ id }) => id === customer),
              deliveryAddress: {
                companyName1: company,
                addressLine1,
                addressLine2,
                postalCode: zipcode,
                city,
                countryCode: addresses?.find(({ id }) => id === deliveryAddressSelect)?.countryCode || countryCode,
              } as CamfilAddress,
            } as CamCard,
            this.dialogData
          );
          return race(
            this.actions$.pipe(ofType(createCamCardSuccess)),
            this.actions$.pipe(ofType(createCamCardFail))
          ).pipe(take(1));
        }),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(result => {
        switch (result.type) {
          case createCamCardSuccess.type:
            this.matDialogRef.close();
            break;
          case createCamCardFail.type:
            this.form.enable();
            break;
        }
      });
  }
}
