import { createAction } from '@ngrx/store';
import { CamfilLineItem } from 'camfil-models/camfil-line-item/camfil-line-item.model';

import { Order } from 'ish-core/models/order/order.model';
import { payload } from 'ish-core/utils/ngrx-creators';

export const trackCreateOrderCamfil = createAction(
  '[CamfilOrders] Tracking Create Camfil Order',
  payload<{ order: Order; items?: CamfilLineItem[] }>()
);
