import { EntityState, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { CamfilCustomerDeliveryTerm } from 'camfil-models/camfil-customer-delivery-term/camfil-customer-delivery-term.model';

import { HttpError } from 'ish-core/models/http-error/http-error.model';
import { setLoadingOn, unsetLoadingAndErrorOn } from 'ish-core/utils/ngrx-creators';

import {
  loadCustomerDeliveryTerm,
  loadCustomerDeliveryTermSuccess,
  selectloadCustomerDeliveryTerm,
} from './camfil-customer-delivery-terms.actions';

export const camfilCustomerDeliveryTermFeatureKey = 'camfilCustomerDeliveryTerm';

export const camfilCustomerDeliveryTermsAdapter = createEntityAdapter<CamfilCustomerDeliveryTerm>({
  selectId: camfilCustomerDeliveryTerm => camfilCustomerDeliveryTerm?.customerId,
});

export interface CamfilCustomerDeliveryTermsState extends EntityState<CamfilCustomerDeliveryTerm> {
  loading: boolean;
  selected?: string;
  error: HttpError;
}

const initialState: CamfilCustomerDeliveryTermsState = camfilCustomerDeliveryTermsAdapter.getInitialState({
  loading: false,
  selected: undefined,
  error: undefined,
});

export const reducer = createReducer(
  initialState,
  setLoadingOn(loadCustomerDeliveryTerm),
  unsetLoadingAndErrorOn(loadCustomerDeliveryTermSuccess),
  on(
    selectloadCustomerDeliveryTerm,
    (state: CamfilCustomerDeliveryTermsState, action): CamfilCustomerDeliveryTermsState => ({
      ...state,
      selected: action.payload.customerId,
    })
  ),
  on(loadCustomerDeliveryTermSuccess, (state: CamfilCustomerDeliveryTermsState, action) => {
    const { term } = action.payload;

    return {
      ...camfilCustomerDeliveryTermsAdapter.addOne(term, state),
    };
  })
);
