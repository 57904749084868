<ng-container *ngIf="rangeDates$ | async as rangeDates">
  <ng-container *ngIf="rangeDates.length; else noDatesToPick">
    <div class="d-flex flex-column align-items-center justify-content-center p-4" [style.maxWidth.px]="500">
      <mat-icon [svgIcon]="'delete'" />
      <h2 camfilDialogTitle>
        <camfil-content-by-role>
          <div class="role-default">{{ 'camfil.checkout.order.bulk_delivery_date.modal.heading' | translate }}</div>
          <div class="role-quote">{{ 'camfil.checkout.order.bulk_delivery_date.modal.heading_quote' | translate }}</div>
          <div class="role-requisition">
            {{ 'camfil.checkout.order.bulk_delivery_date.modal.heading_requisition' | translate }}
          </div>
        </camfil-content-by-role>
      </h2>
    </div>

    <div camfilDialogContent separated>
      {{ 'camfil.checkout.order.bulk_delivery_date.modal.list' | translate }}
      <ng-container *ngFor="let bucket of buckets">
        <div>
          {{ 'camfil.dynamic.checkout.order' | translate }} {{ bucket.position }} -
          {{ bucket.orderMark }}
        </div>
      </ng-container>
      <hr />

      <ng-container *ngIf="oneDayScope; else calendar">
        <div class="d-flex align-items-center justify-content-center">
          <mat-icon svgIcon="calendar" class="remove-icon mr-4" />
          <span
            [innerHTML]="
              'camfil.checkout.order.bulk_delivery_date.modal.one_date'
                | translate : { '0': rangeDates[0] | camfilDate }
            "
          ></span>
        </div>
      </ng-container>
      <ng-template #calendar>
        <div class="d-flex align-items-center mb-2">
          <mat-icon svgIcon="calendar" class="remove-icon mr-2" />
          {{ 'camfil.checkout.order.bulk_delivery_date.modal.description' | translate }}
        </div>
        <form [formGroup]="calendarForm.instance" *ngIf="calendarForm" class="formly-form__container">
          <formly-form
            [form]="calendarForm.instance"
            [model]="calendarForm.model$ | async"
            [fields]="calendarForm.fields$ | async"
            [options]="calendarForm.options$ | async"
          />
        </form>
      </ng-template>
    </div>

    <div camfilDialogActions>
      <button mat-raised-button type="button" class="secondary-button" (click)="matDialogRef.close()">
        {{ 'camfil.checkout.order.bulk_delivery_date.modal.no' | translate }}
      </button>
      <button
        mat-raised-button
        color="primary"
        type="button"
        class="create-order__button"
        (click)="confirmChnageDate()"
      >
        {{ 'camfil.checkout.order.bulk_delivery_date.modal.yes' | translate }}
      </button>
    </div>
  </ng-container>

  <ng-template #noDatesToPick>
    <div camfilDialogContent>
      <p class="p-2">
        <strong>
          {{ 'camfil.checkout.order.bulk_delivery_date.modal.no_dates_to_pick' | translate }}
        </strong>
      </p>
    </div>
  </ng-template>
</ng-container>
