import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BucketExtension } from 'camfil-models/bucket-extension/bucket-extension.model';
import { CamfilBucketData, CamfilBucketDataFromExtension } from 'camfil-models/camfil-bucket/camfil-bucket.interface';
import { CamfilBucketMapper } from 'camfil-models/camfil-bucket/camfil-bucket.mapper';
import { CamfilBucket } from 'camfil-models/camfil-bucket/camfil-bucket.model';
import { CamfilCalendarException } from 'camfil-models/camfil-caledar-exceptions/camfil-caledar-exceptions.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Basket } from 'ish-core/models/basket/basket.model';
import { ApiService } from 'ish-core/services/api/api.service';

@Injectable({
  providedIn: 'root',
})
export class CamfilBucketService {
  constructor(private ishApiService: ApiService) {}

  private camfilHeaders = new HttpHeaders({
    'content-type': 'application/json',
    Accept: 'application/vnd.intershop.basket.v1+json',
  });

  getCamfilBuckets(basket: Basket): Observable<CamfilBucket[]> {
    const params = new HttpParams().set('include', 'shipToAddress');

    return this.ishApiService
      .get<CamfilBucketData>(`baskets/${basket.id}/buckets`, {
        headers: this.camfilHeaders,
        params,
      })
      .pipe(map(camfilBuckets => CamfilBucketMapper.fromListData(camfilBuckets, basket)));
  }

  getCamfilBucketsExtension(
    camfilBuckets: CamfilBucket[],
    basketId: string,
    // TODO: TMP - remove when earliestDeliveryDate will be fix
    exceptions: CamfilCalendarException[]
  ): Observable<Partial<CamfilBucket>[]> {
    const params = new HttpParams().set('include', 'camfilBucketLineItemsInclude');
    return this.ishApiService
      .get<CamfilBucketDataFromExtension>(`baskets/${basketId}/camfil`, {
        params,
      })
      .pipe(
        map(camfilBucketsExtensionData =>
          CamfilBucketMapper.fromBucketExtensionListData(camfilBuckets, camfilBucketsExtensionData, exceptions)
        )
      );
  }

  updateCamfilBucket(
    basketId: string,
    addressId: string,
    bucketExtension: BucketExtension
  ): Observable<BucketExtension> {
    return this.ishApiService.post(`baskets/${basketId}/camfil/${addressId}`, {
      ...bucketExtension,
    });
  }

  deleteCamfilBucket(basketId: string, bucketId: string) {
    return this.ishApiService.delete(`baskets/${basketId}/buckets/${bucketId}`, {
      headers: this.camfilHeaders,
    });
  }
}
