<div *ngIf="selected" class="row">
  <div class="col-md-10">
    <div *ngFor="let select of selected" class="filter-navigation-badges">
      <button type="button" class="btn btn-link btn-link-action" (click)="apply(select.searchParameter)">
        {{ select.filterName }}: {{ select.displayName }}
        <fa-icon [icon]="['fas', 'times']" class="form-control-feedback" />
      </button>
    </div>
  </div>
  <div *ngIf="selected.length" class="col-md-2 text-right">
    <button type="button" class="btn btn-link btn-link-action" (click)="clear()">
      {{ 'product.remove_all_product_filters.text' | translate }}
    </button>
  </div>
</div>
