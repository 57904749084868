<div *ngIf="(!products || products.length === 0) && (loading$ | async) === false" class="empty">
  {{ 'search.noresult.filtered_products.message' | translate }}
</div>

<camfil-product-item
  *ngFor="let sku of products | slice : 0 : limit"
  [viewType]="viewType"
  ishProductContext
  [sku]="sku"
  [category]="category"
/>

<camfil-loading *ngIf="limit === undefined && (loading$ | async)" [standalone]="true" />
