import { Attribute } from 'ish-core/models/attribute/attribute.model';
import { ProductView } from 'ish-core/models/product-view/product-view.model';

import { CamfilAttributeGroupTypes } from './camfil-attribute-group.types';

export class CamfilAttributeGroupHelper {
  static getAttributesGroupByType(productView: ProductView, type: CamfilAttributeGroupTypes): undefined | Attribute[] {
    return productView?.attributeGroups?.[type]?.attributes;
  }
}
