import { createReducer, on } from '@ngrx/store';

import { ProductLinksDictionary } from 'ish-core/models/product-links/product-links.model';

import { loadCategoryLinksSuccess } from './categories.actions';

export const camfilCategoriesStateKey = 'camfilCategories';

export interface CamfilCategoriesState {
  links?: { [sku: string]: ProductLinksDictionary };
}

const initialState: CamfilCategoriesState = {};

export const reducer = createReducer(
  initialState,
  on(loadCategoryLinksSuccess, (state: CamfilCategoriesState, action): CamfilCategoriesState => {
    const { key, links } = action.payload;
    return {
      ...state,
      links: {
        ...state.links,
        [key]: links,
      },
    };
  })
);
