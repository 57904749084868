import { Injectable } from '@angular/core';
import { FormlyFieldConfig } from '@ngx-formly/core';
import {
  calculateNextDeliveryDate,
  findTopParentForm,
  valueChanges$,
} from 'camfil-shared/formly/utils/camfil-form-utils';
import { formatISO } from 'date-fns';

import { FieldLibraryConfiguration } from 'ish-shared/formly/field-library/configurations/field-library-configuration';

@Injectable()
export class NextDeliveryDateConfiguration extends FieldLibraryConfiguration {
  id = 'nextDeliveryDateFLC';

  getFieldConfig(): FormlyFieldConfig {
    return {
      key: 'nextDeliveryDate',
      type: 'camfil-datepicker-input-field',
      props: {
        label: 'camfil.account.cam_card.add_to_form.next_delivery',
        required: false,
        disabled: true,
      },
      hooks: {
        onInit: (field: FormlyFieldConfig) => {
          valueChanges$(field, 'lastDeliveryDate').subscribe(() => {
            this.recalculateAndUpdateNextDeliveryDate(field);
          });
          valueChanges$(field, 'deliveryInterval').subscribe(() => {
            this.recalculateAndUpdateNextDeliveryDate(field);
          });
        },
      },
    };
  }

  private recalculateAndUpdateNextDeliveryDate(field: FormlyFieldConfig) {
    try {
      const form = findTopParentForm(field);
      const lastDeliveryDateControl = form.get('lastDeliveryDate');
      const deliveryIntervalControl = form.get('deliveryInterval');
      const nextDeliveryDateControl = form.get('nextDeliveryDate');

      if (lastDeliveryDateControl?.value && deliveryIntervalControl?.value && nextDeliveryDateControl) {
        const lastDeliveryDate = new Date(lastDeliveryDateControl.value);
        const deliveryInterval = Number(deliveryIntervalControl.value);
        const nextDeliveryDate = calculateNextDeliveryDate(lastDeliveryDate, deliveryInterval);

        nextDeliveryDateControl.setValue(formatISO(nextDeliveryDate, { representation: 'date' }));
      }
    } catch (error) {
      console.error('Error while calculating next delivery date', error);
    }
  }
}
