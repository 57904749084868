import { ChangeDetectionStrategy, Component, DestroyRef, Input, OnInit, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { CamfilConfigurationFacade } from 'camfil-core/facades/camfil-configuration.facade';

import { Address } from 'ish-core/models/address/address.model';

/**
 * The Address Component displays an address. The readout is country-dependent.
 *
 * @example
 * <camfil-address
 *   [address]="order.invoiceToAddress"
 * ></camfil-address>
 */
@Component({
  selector: 'camfil-address',
  templateUrl: './camfil-address.component.html',
  styleUrls: ['./camfil-address.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CamfilAddressComponent implements OnInit {
  /**
   * The Address to be displayed.
   *
   */
  @Input() address: Partial<Address>;

  /**
   * If set to true, the email is displayed as part of the address.
   *
   */
  @Input() displayEmail = false;

  private destroyRef = inject(DestroyRef);
  countryCode: string;

  constructor(private camfilConfigurationFacade: CamfilConfigurationFacade) {}

  ngOnInit(): void {
    this.camfilConfigurationFacade.countryCode$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(channelCode => (this.countryCode = channelCode));
  }
}
