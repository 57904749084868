<!-- error messages -->
<ng-container *ngIf="camfilErrorMessages$ | async as bucketErrors">
  <ng-container *ngTemplateOutlet="messages; context: { buckets: bucketErrors, class: 'alert-danger' }" />
</ng-container>

<!-- info messages -->
<ng-container *ngIf="camfilInfoMessages$ | async as bucketInfos">
  <ng-container *ngTemplateOutlet="messages; context: { buckets: bucketInfos, class: 'alert-warning' }" />
</ng-container>

<ng-template #messages let-class="class" let-buckets="buckets">
  <div
    *ngFor="let bucket of buckets"
    [class]="class"
    (click)="scrollTo(bucket.id)"
    (keyup.enter)="scrollTo(bucket.id)"
    role="alert"
    class="alert"
  >
    <p>
      {{ 'camfil.dynamic.checkout.order' | translate }}&nbsp;{{ bucket.index }}/{{ (camfilBuckets$ | async)?.length }}
    </p>
    <ul class="pl-4">
      <li *ngFor="let message of bucket.messages">
        {{ message }}
      </li>
    </ul>
  </div>
</ng-template>
