import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { getCamfilOrderTrackAndTraceByOwnerId } from 'camfil-core/store/camfil-order-track-and-trace/camfil-order-track-and-trace.selectors';
import { cloneCamfilOrder, loadCamfilOrders } from 'camfil-core/store/camfil-orders/camfil-orders.actions';
import { getOrders, getOrdersLoading, getSelectedOrder } from 'camfil-core/store/camfil-orders/camfil-orders.selectors';
import { loadCamfilRequisitions } from 'camfil-core/store/camfil-requisitions/camfil-requisitions.actions';
import { getCamfilRequisitions } from 'camfil-core/store/camfil-requisitions/camfil-requisitions.selectors';
import { loadZipCodeAddressIfNotLoaded } from 'camfil-core/store/camfil-zip-code-address/camfil-zip-code-address.actions';
import {
  getAllCamfilZipCodeAddresses,
  getCamfilZipCodeAddress,
  getCamfilZipCodeAddressError,
  getCamfilZipCodeAddressLoading,
} from 'camfil-core/store/camfil-zip-code-address/camfil-zip-code-address.selectors';
import {
  applyForAnAccount,
  requestUsernameReminder,
  resetUsernameReminder,
} from 'camfil-core/store/user/camfil-user.actions';
import { CamfilRequisitionViewer } from 'camfil-models/camfil-requisition/camfil-requisition.model';
import { CamfilUnavailableProduct } from 'camfil-models/camfil-unavailable-product/camfil-unavailable-product.model';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { AllProductTypes } from 'ish-core/models/product/product.model';
import { getUserPermissions } from 'ish-core/store/customer/authorization/authorization.selectors';
import { getProductEntities } from 'ish-core/store/shopping/products';
import { whenTruthy } from 'ish-core/utils/operators';

import { CamfilUser, CamfilUsernameReminder } from '../../models/camfil-user/camfil-user.model';
import {
  getCamfilUser,
  getCamfilUserError,
  getCamfilUserLoading,
  getCamfilUserReminderError,
  getCamfilUserReminderSuccess,
} from '../store/user/camfil-user.selectors';

@Injectable({ providedIn: 'root' })
export class CamfilAccountFacade {
  constructor(private store: Store) {}

  error$ = this.store.pipe(select(getCamfilUserError));
  loading$ = this.store.pipe(select(getCamfilUserLoading));
  ordersLoading$ = this.store.pipe(select(getOrdersLoading));
  reminderError$ = this.store.pipe(select(getCamfilUserReminderError));
  reminderSuccess$ = this.store.pipe(select(getCamfilUserReminderSuccess));
  selectedOrder$ = this.store.pipe(select(getSelectedOrder));
  selectedOrderTrackAndTrace$ = this.selectedOrder$.pipe(
    whenTruthy(),
    switchMap(order => this.store.pipe(select(getCamfilOrderTrackAndTraceByOwnerId(order.id))))
  );
  user$ = this.store.pipe(select(getCamfilUser));
  zipCodesError$ = this.store.pipe(select(getCamfilZipCodeAddressError));
  zipCodesLoading$ = this.store.pipe(select(getCamfilZipCodeAddressLoading));
  userPermissions$ = this.store.pipe(select(getUserPermissions));

  applyForAnAccount(user: CamfilUser) {
    this.store.dispatch(applyForAnAccount({ user }));
  }

  resetUsernameReminder() {
    this.store.dispatch(resetUsernameReminder());
  }

  requestUsernameReminder(data: CamfilUsernameReminder) {
    this.store.dispatch(requestUsernameReminder({ data }));
  }

  orders$() {
    this.store.dispatch(loadCamfilOrders());
    return this.store.pipe(select(getOrders));
  }

  /**
   * Load zip code information like city, area, country etc
   *
   * @param code
   * @param countryCode is optional, if not provided, the current country code will be used
   */
  loadZipCodeAddress$(code: string, countryCode?: string) {
    this.store.dispatch(loadZipCodeAddressIfNotLoaded({ code, countryCode }));
  }

  getAllZipCodeAddresses$() {
    return this.store.pipe(select(getAllCamfilZipCodeAddresses));
  }

  getZipCodeAddress$(postalCode: string) {
    return this.store.pipe(select(getCamfilZipCodeAddress(postalCode)));
  }

  cloneCamfilOrder(orderId: string) {
    this.store.dispatch(cloneCamfilOrder({ orderId }));
  }

  canNotReorderList(
    skus: string[]
  ): Observable<{ unavailableProducts: CamfilUnavailableProduct[]; missingSkus: string[] }> {
    const skusSet = new Set(skus);
    return this.store.pipe(
      select(getProductEntities),
      map(entities => Object.values(entities)),
      map(products => {
        const missingSkus = this.findMissingSkus(skusSet, products);
        const unavailableProducts = this.findUnavailableProducts(skusSet, products);
        return { unavailableProducts, missingSkus };
      })
    );
  }
  requisitions$(view: CamfilRequisitionViewer) {
    this.store.dispatch(loadCamfilRequisitions({ view }));
    return this.store.pipe(select(getCamfilRequisitions));
  }

  private findMissingSkus(skus: Set<string>, products: AllProductTypes[]): string[] {
    return Array.from(skus).filter(sku => !products.some(product => product.sku === sku));
  }

  private findUnavailableProducts(skus: Set<string>, products: AllProductTypes[]): CamfilUnavailableProduct[] {
    return products
      .filter(({ sku, available, failed }) => skus.has(sku) && !available && failed)
      .map(({ sku, available, failed, name }) => ({ sku, name, available, failed }));
  }
}
