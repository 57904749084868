import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { CamfilQueueTask, CamfilQueueTaskStatus } from 'camfil-models/camfil-queue/camfil-queue.model';

import { setLoadingOn, unsetLoadingOn } from 'ish-core/utils/ngrx-creators';

import {
  clearQueue,
  completeTask,
  dequeueTask,
  enqueueTask,
  failTask,
  retryTask,
  startTask,
} from './camfil-queue.actions';

export const camfilQueueFeatureKey = 'camfilQueue';

export interface State extends EntityState<CamfilQueueTask> {
  loading: boolean;
  selected?: string;
}

export const queueAdapter: EntityAdapter<CamfilQueueTask> = createEntityAdapter<CamfilQueueTask>({
  selectId: task => task.id,
  sortComparer: (a, b) => a.enqueueTime - b.enqueueTime,
});

export const initialState: State = queueAdapter.getInitialState({
  loading: false,
  selected: undefined,
});

export const reducer = createReducer(
  initialState,
  setLoadingOn(startTask),
  unsetLoadingOn(completeTask, failTask, clearQueue),
  on(enqueueTask, (state, action) => queueAdapter.addOne(action.payload.task, state)),
  on(dequeueTask, (state, action) => queueAdapter.removeOne(action.payload.id, state)),
  on(startTask, (state, action) =>
    queueAdapter.updateOne(
      { id: action.payload.id, changes: { status: CamfilQueueTaskStatus.Processing } },
      { ...state, selected: action.payload.id }
    )
  ),
  on(completeTask, (state, action) =>
    queueAdapter.updateOne(
      { id: action.payload.id, changes: { status: CamfilQueueTaskStatus.Completed } },
      { ...state, selected: undefined }
    )
  ),
  on(failTask, (state, action) =>
    queueAdapter.updateOne(
      { id: action.payload.id, changes: { status: CamfilQueueTaskStatus.Failed } },
      { ...state, selected: undefined }
    )
  ),
  on(retryTask, (state, action) =>
    queueAdapter.updateOne({ id: action.payload.id, changes: { status: CamfilQueueTaskStatus.Queued } }, state)
  ),
  on(clearQueue, state => queueAdapter.removeAll({ ...state, selected: undefined }))
);
