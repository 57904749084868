import { CamfilAttributeGroupHelper } from 'camfil-models/camfil-attribute-group/camfil-attribute-group.helper';
import { CamfilImageTypeIds, CamfilImageTypes } from 'camfil-models/camfil-image/camfil-image.types';
import { CamfilTechnicalDocumentHelper } from 'camfil-models/camfil-technical-document/camfil-technical-document.helper';

import { AttributeHelper } from 'ish-core/models/attribute/attribute.helper';
import { Attribute } from 'ish-core/models/attribute/attribute.model';
import { Image } from 'ish-core/models/image/image.model';
import { ProductView } from 'ish-core/models/product-view/product-view.model';
import { ProductHelper } from 'ish-core/models/product/product.helper';

import { CamfilAttributeGroupTypes } from '../camfil-attribute-group/camfil-attribute-group.types';

export class CamfilProductHelper {
  /* For some reason we show "in stock" level depending on Arrigocode attribute. Currently we do only detects A1 that mean "in stock".
    The function should return undefined when attributes are not found but for now its ok to return false. */
  static isInStock(productView: ProductView): boolean {
    const attrs = CamfilProductHelper.getProductListLabelAttributes(productView);
    return !!attrs && AttributeHelper.getAttributeValueByAttributeName(attrs, 'Arrigocode') === 'A1';
  }

  /**
   lead time is represented as delivery days in the product attributes
   */
  static getLeadTime(productView: ProductView): number {
    const attrs = productView?.attributes || [];
    const attrsList = CamfilProductHelper.getProductListLabelAttributes(productView) || [];
    const allAttrs = [...attrs, ...attrsList];
    return allAttrs.length ? AttributeHelper.getAttributeValueByAttributeName(allAttrs, 'Deliverydays') : 0;
  }

  static getLeadTimeFormatted(productView: ProductView): string {
    const leadTime = CamfilProductHelper.getLeadTime(productView);
    return leadTime < 10 ? `${leadTime} ` : `${leadTime}`;
  }

  static getProductListLabelAttributes(productView: ProductView) {
    return CamfilAttributeGroupHelper.getAttributesGroupByType(
      productView,
      CamfilAttributeGroupTypes.ProductsListLabelAttributes
    );
  }

  static getPrimaryImage(productView: ProductView, imageType: CamfilImageTypeIds): undefined | Image {
    return productView.images?.find(image => image.typeID === imageType && image.primaryImage);
  }

  static getImages(productView: ProductView): Image[] {
    return productView.images?.filter(image => image.typeID === CamfilImageTypes.Image) || [];
  }

  static getBadges(productView: ProductView): Image[] {
    return productView.images?.filter(image => image.typeID === CamfilImageTypes.Badge) || [];
  }

  static getDetailAttributes(productView: ProductView): Attribute[] {
    return (
      CamfilAttributeGroupHelper.getAttributesGroupByType(
        productView,
        CamfilAttributeGroupTypes.ProductsDetailAttributes
      ) ||
      productView.attributes ||
      []
    );
  }

  static getTechnicalDocuments(
    product: ProductView,
    showAllDocsType: boolean,
    filterDocsByLanguage: boolean,
    currentLocale: string
  ): Image[] {
    return (
      product.images?.filter(
        image =>
          CamfilTechnicalDocumentHelper.isTechnicalDocumentType(image.typeID) &&
          (showAllDocsType || image.viewID === 'default') &&
          /* Very weak check */
          (filterDocsByLanguage ? image.effectiveUrl.includes(currentLocale) : true)
      ) || []
    );
  }

  static getSpecificAttributeNames(products: ProductView[]): string[] {
    const commonAttributeNames = ProductHelper.getCommonAttributeNames(products);
    const specificAttributeNames = new Set<string>();

    products.forEach(product => {
      product.attributes.forEach(attribute => {
        if (!commonAttributeNames.includes(attribute.name)) {
          specificAttributeNames.add(attribute.name);
        }
      });
    });

    return Array.from(specificAttributeNames);
  }

  static getProductsWithoutSimilarities(products: ProductView[]): ProductView[] {
    if (products.length < 2) {
      return products;
    }

    const extractValuesForAttribute = (attributeName: string, products: ProductView[]) =>
      products.map(product => {
        const topLevelAttribute = product.attributes.find(attr => attr.name === attributeName)?.value;
        const groupAttributes = Object.values(product.attributeGroups || {}).flatMap(
          group => group.attributes.find(attr => attr.name === attributeName)?.value
        );
        // Use `null` to represent the absence of an attribute (to distinguish it from `undefined` values explicitly set)
        // eslint-disable-next-line unicorn/no-null
        return [topLevelAttribute, ...groupAttributes].find(value => value !== undefined) ?? null;
      });

    // Determine unique attributes
    const allAttributeNames = new Set<string>();
    products.forEach(product => {
      product.attributes.forEach(attr => allAttributeNames.add(attr.name));
      Object.values(product.attributeGroups || {}).forEach(group =>
        group.attributes.forEach(attr => allAttributeNames.add(attr.name))
      );
    });

    const uniqueAttributeNames = Array.from(allAttributeNames).filter(attributeName => {
      const values = extractValuesForAttribute(attributeName, products);
      // eslint-disable-next-line unicorn/no-null
      const nonNullValues = values.filter(value => value !== null);
      // An attribute is considered unique if it has more than one distinct value
      // or if it's only present in one of the compared products (making it inherently unique).
      return new Set(nonNullValues).size > 1 || nonNullValues.length < products.length;
    });

    // Filter products to only include unique attributes
    return products.map(product => ({
      ...product,
      attributes: product.attributes.filter(attr => uniqueAttributeNames.includes(attr.name)),
      attributeGroups: Object.fromEntries(
        Object.entries(product.attributeGroups || {}).map(([groupId, group]) => [
          groupId,
          {
            ...group,
            attributes: group.attributes.filter(attr => uniqueAttributeNames.includes(attr.name)),
          },
        ])
      ),
    }));
  }
}
