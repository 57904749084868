<mat-slide-toggle
  [checked]="dndOn"
  class="w-100 pb-3"
  (click)="dndOn = !dndOn"
  *ngIf="(deviceType$ | async) !== 'mobile'"
>
  {{ dndText | translate }}
</mat-slide-toggle>
<ng-container *ngIf="deviceType$ | async as deviceType">
  <div
    cdkDropList
    [cdkDropListDisabled]="deviceType === 'mobile' || !dndOn"
    class="d-flex {{ dropListClass }}"
    [attr.data-cam-card-id]="camCard?.id"
    [cdkDropListData]="camCard?.camCardItems"
    (cdkDropListDropped)="drop($event, camCard)"
    #scrollContainer
    [style.height]="
      (currentScrollPosition$ | async) && camCard?.camCardItems?.length
        ? containerSize(camCard?.camCardItems?.length, deviceType)
        : 'auto'
    "
  >
    <div style="flex: 1">
      <ng-container *ngIf="camCard?.camCardItems?.length; else noItems">
        <div
          *ngFor="let item of camCard.camCardItems; index as i; trackBy: trackBy"
          cdkDrag
          cdkDragLockAxis="y"
          [cdkDragData]="item"
          (cdkDragStarted)="setCCEditMode()"
          (cdkDragMoved)="dragMoved($event)"
          (cdkDragEnded)="dragEnded($event)"
          [style.minHeight.px]="itemSize"
        >
          <camfil-account-cam-card-detail-line-item
            ishProductContext
            [mode]="'edit'"
            [sku]="getMoreItemsCalc(i) ? item?.product?.sku : undefined"
            [camCard]="camCard"
            [camCardItem]="item"
            [selectedItemsForm]="selectedItemsForm"
            [isIntervalVisible]="isIntervalVisible$ | async"
            [index]="i + 1"
            (delete)="openDeleteProductConfirmationDialog($event)"
            [shouldFetchProduct]="getMoreItemsCalc(i + 1)"
            (qtyWithError)="disabledEditSaveBtn($event)"
          />
        </div>
      </ng-container>

      <ng-template #noItems>
        <ng-container *ngIf="camCard?.rootCamCard; else noTopLevelItems">
          <p class="p-2 pl-3 pr-3 m-2 bg-light">
            {{ 'camfil.account.cam_card.details.no_items_in_section' | translate }}
          </p>
        </ng-container>
        <ng-template #noTopLevelItems>
          <p class="p-2 pl-3 pr-3 m-2 bg-light">
            {{ 'camfil.account.cam_card.details.no_items' | translate }}
          </p>
        </ng-template>
      </ng-template>
    </div>
  </div>
</ng-container>
