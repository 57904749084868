import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { routerNavigatedAction } from '@ngrx/router-store';
import { Store, select } from '@ngrx/store';
import { AhuService } from 'camfil-core/services/ahu/ahu.service';
import { AhuHelper } from 'camfil-models/camfil-ahu/ahu.helper';
import { filter, map, mergeMap, switchMap } from 'rxjs/operators';

import { ofUrl, selectRouteParam } from 'ish-core/store/core/router';
import { mapErrorToAction, mapToPayloadProperty, whenFalsy, whenTruthy } from 'ish-core/utils/operators';

import { deselectAhuUnit, loadAhuUnits } from '../unit/unit.actions';

import {
  deselectAhuManufacturer,
  loadAhuManufacturers,
  loadAhuManufacturersFail,
  loadAhuManufacturersSuccess,
  selectAhuManufacturer,
} from './manufacturer.actions';
import { areAhuManufacturersLoaded, getSelectedAhuManufacturerId } from './manufacturer.selectors';

@Injectable()
export class AhuManufacturerEffects {
  constructor(private actions$: Actions, private store: Store, private ahuService: AhuService) {}

  loadAhuManufacturers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadAhuManufacturers),
      switchMap(() =>
        this.ahuService.getManufacturers().pipe(
          map(manufacturers => loadAhuManufacturersSuccess({ manufacturers })),
          mapErrorToAction(loadAhuManufacturersFail)
        )
      )
    )
  );

  selectAhuManufacturer$ = createEffect(() =>
    this.actions$.pipe(
      ofType(selectAhuManufacturer),
      mapToPayloadProperty('manufacturerId'),
      whenTruthy(),
      map(manufacturerId => loadAhuUnits({ manufacturerId }))
    )
  );

  routeListenerForSelectingAhuManufacturer$ = createEffect(() =>
    this.store.pipe(
      ofUrl(AhuHelper.urlRegExp),
      select(selectRouteParam(AhuHelper.MANUFACTURER_ID_QUERY_PARAM_NAME)),
      whenTruthy(),
      concatLatestFrom(() => this.store.pipe(select(getSelectedAhuManufacturerId))),
      filter(([fromAction, selectedManufacturerId]) => fromAction && fromAction !== selectedManufacturerId),
      map(([manufacturerId]) => manufacturerId),
      map(manufacturerId => selectAhuManufacturer({ manufacturerId }))
    )
  );

  routeListenerForDeselectingAhuManufacturerAndAhuUnit$ = createEffect(() =>
    this.actions$.pipe(
      ofType(routerNavigatedAction),
      switchMap(() =>
        this.store.pipe(
          ofUrl(AhuHelper.urlRegExp),
          select(selectRouteParam(AhuHelper.MANUFACTURER_ID_QUERY_PARAM_NAME)),
          concatLatestFrom(() => this.store.pipe(select(getSelectedAhuManufacturerId))),
          filter(([routeParamMID, selectedMID]) => !routeParamMID && !!selectedMID),
          mergeMap(() => [deselectAhuManufacturer(), deselectAhuUnit()])
        )
      )
    )
  );

  routeListenerForManufacturers$ = createEffect(() =>
    this.store.pipe(
      ofUrl(AhuHelper.urlRegExp),
      select(areAhuManufacturersLoaded),
      whenFalsy(),
      map(loadAhuManufacturers)
    )
  );
}
