import { EntityState, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { UnitFilter } from 'camfil-models/camfil-ahu/unit-filter/unit-filter.model';

import { HttpError } from 'ish-core/models/http-error/http-error.model';
import { setErrorOn, setLoadingOn, unsetLoadingAndErrorOn } from 'ish-core/utils/ngrx-creators';

import { deselectAhuUnit, selectAhuUnit } from '../unit/unit.actions';

import { loadAhuUnitFilter, loadAhuUnitFilterFail, loadAhuUnitFilterSuccess } from './unit-filter.actions';

export const ahuUnitFilterFeatureKey = 'ahuUnitFilter';

export interface AhuUnitFilterState extends EntityState<UnitFilter> {
  loading: boolean;
  selected: number;
  error: HttpError;
}

export const unitFilterAdapter = createEntityAdapter<UnitFilter>({
  selectId: unitFilter => unitFilter.id,
});

const initialState: AhuUnitFilterState = unitFilterAdapter.getInitialState({
  loading: false,
  selected: undefined,
  error: undefined,
});

export const reducer = createReducer(
  initialState,
  setLoadingOn(loadAhuUnitFilter),
  setErrorOn(loadAhuUnitFilterFail),
  unsetLoadingAndErrorOn(loadAhuUnitFilterSuccess, deselectAhuUnit),
  on(loadAhuUnitFilterSuccess, (state, action) => {
    const { unitFilter } = action.payload;
    return {
      ...unitFilterAdapter.upsertOne(unitFilter, state),
      selected: unitFilter.id,
    };
  }),
  on(
    loadAhuUnitFilterFail,
    deselectAhuUnit,
    (state): AhuUnitFilterState => ({
      ...state,
      selected: undefined,
    })
  ),
  on(selectAhuUnit, (state, action): AhuUnitFilterState => {
    const { unitId } = action.payload;
    return {
      ...state,
      selected: unitId,
    };
  })
);
