<form
  [formGroup]="form"
  #addressForm="ngForm"
  (ngSubmit)="submitForm()"
  class="form-horizontal"
  novalidate="novalidate"
>
  <p class="indicates-required"><span class="required">*</span>{{ 'account.required_field.message' | translate }}</p>
  <ish-formly-address-form
    [parentForm]="form"
    [businessCustomer]="businessCustomer$ | async"
    [prefilledAddress]="address"
  />

  <ish-formly-address-extension-form *ngIf="extension" [form]="extensionForm" [model]="extensionModel" />

  <div class="row">
    <div class="offset-md-4 col-md-8 offset-lg-0 col-lg-12 offset-lg-4 col-xl-8">
      <button [disabled]="formDisabled" type="submit" class="btn btn-primary">
        {{ buttonLabel | translate }}
      </button>
      <button (click)="cancelForm()" type="reset" class="btn btn-secondary">
        {{ 'checkout.address.cancel.button.label' | translate }}
      </button>
    </div>
  </div>
</form>
