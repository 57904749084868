import { Injectable } from '@angular/core';
import { Actions, OnInitEffects, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { CamfilConfigurationService } from 'camfil-core/services/configuration/camfil-configuration.service';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { HttpError } from 'ish-core/models/http-error/http-error.model';

import { loadConfiguration, loadConfigurationFailure, loadConfigurationSuccess } from './camfil-configuration.actions';

@Injectable()
export class CamfilConfigurationEffects implements OnInitEffects {
  loadConfiguration$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadConfiguration),
      switchMap(() =>
        this.configurationService.getConfiguration().pipe(
          map(configuration => loadConfigurationSuccess({ configuration })),
          catchError((error: HttpError) => of(loadConfigurationFailure({ error })))
        )
      )
    )
  );

  ngrxOnInitEffects(): Action {
    return loadConfiguration();
  }

  constructor(private actions$: Actions, private configurationService: CamfilConfigurationService) {}
}
