import { ChangeDetectionStrategy, Component, HostBinding, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CamfilUnavailableProduct } from 'camfil-models/camfil-unavailable-product/camfil-unavailable-product.model';
import { CamfilDialogConfirmResult } from 'camfil-shared/dialog/camfil-dialog-confirm/camfil-dialog-confirm.component';

export interface CamfilReorderItemsErrorDialogData {
  unavailableProducts: CamfilUnavailableProduct[];
  missingSkus: string[];
  skus: string[]; // all expected reOrder skus
}

export type CamfilReorderItemsErrorDialogResult = CamfilDialogConfirmResult;

@Component({
  selector: 'camfil-reorder-items-error-dialog',
  templateUrl: './camfil-reorder-items-error-dialog.component.html',
  styleUrls: ['./camfil-reorder-items-error-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CamfilReorderItemsErrorDialogComponent {
  @HostBinding('class.camfil-dialog') camfilDialogClass = true;

  constructor(
    public matDialogRef: MatDialogRef<CamfilReorderItemsErrorDialogComponent, CamfilDialogConfirmResult>,
    // eslint-disable-next-line ish-custom-rules/use-type-safe-injection-token
    @Inject(MAT_DIALOG_DATA) public dialogData: CamfilReorderItemsErrorDialogData
  ) {}

  confirmReOrder() {
    this.matDialogRef.close(CamfilDialogConfirmResult.CONFIRMED);
  }

  get possibleToOrder() {
    const { unavailableProducts, missingSkus, skus } = this.dialogData;
    const result = [...new Set([...unavailableProducts.map(({ sku }) => sku), ...missingSkus])];
    return [...new Set(skus)].length - result.length;
  }
}
