import { Injectable } from '@angular/core';
import { concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { routerNavigationAction } from '@ngrx/router-store';
import { select } from '@ngrx/store';
import { getCamfilMinLengthByName } from 'camfil-core/store/camfil-configuration/camfil-configuration.selectors';
import { getCamfilSearchProductListingId } from 'camfil-core/store/shopping/camfil-search/camfil-search.selectors';
import { CamfilSearchHelper, TypeOfSearchInput } from 'camfil-models/camfil-search/camfil-search.helper';
import { CAMFIL_QUICK_SEARCH_ID } from 'camfil-shared/camfil-constants';
import { from } from 'rxjs';
import { concatMap, distinctUntilChanged, filter, map, mergeMap, takeWhile } from 'rxjs/operators';

import { mapToRouterState, selectRouteParam, selectUrl } from 'ish-core/store/core/router';
import { getNavigationCategories } from 'ish-core/store/shopping/categories';
import {
  getProductListingItemsPerPage,
  loadMoreProducts,
  setProductListingPages,
} from 'ish-core/store/shopping/product-listing';
import { loadProductSuccess } from 'ish-core/store/shopping/products';
import { searchProducts, searchProductsFail } from 'ish-core/store/shopping/search';
import { SearchEffects } from 'ish-core/store/shopping/search/search.effects';
import { mapErrorToAction, mapToPayload, mapToPayloadProperty, whenTruthy } from 'ish-core/utils/operators';

import {
  closeSearchResultsOverlay,
  openSearchResultsOverlay,
  quickSearchProducts,
  redirectAfterClearSearchValue,
  setSearchTerm,
  setSearchTermValid,
} from './camfil-search.actions';

/* eslint-disable @typescript-eslint/dot-notation */

@Injectable()
export class CamfilSearchEffects extends SearchEffects {
  override searchProducts$ = createEffect(() =>
    this['actions$'].pipe(
      ofType(searchProducts),
      mapToPayload(),
      map(payload => ({ ...payload, page: payload.page ? payload.page : 1, sorting: payload?.sorting || undefined })),
      concatLatestFrom(() => this['store'].pipe(select(getProductListingItemsPerPage('search')))),
      map(([payload, pageSize]) => ({ ...payload, amount: pageSize, offset: (payload.page - 1) * pageSize })),
      concatMap(({ searchTerm, amount, sorting, offset, page }) => {
        const correctedTerm = CamfilSearchHelper.handleSearchTermWithSlash(searchTerm);
        return this['productsService'].searchProducts(correctedTerm, amount, sorting, offset).pipe(
          concatMap(({ total, products, sortableAttributes }) => [
            ...products.map(product => loadProductSuccess({ product })),
            setProductListingPages(
              this['productListingMapper'].createPages(
                products.map(p => p.sku),
                'search',
                searchTerm,
                amount,
                {
                  startPage: page,
                  sorting,
                  sortableAttributes,
                  itemCount: total,
                }
              )
            ),
          ]),
          mapErrorToAction(searchProductsFail)
        );
      })
    )
  );

  quickSearchProducts$ = createEffect(() =>
    this['actions$'].pipe(
      ofType(quickSearchProducts),
      mapToPayload(),
      map(payload => ({ ...payload, page: payload.page ? payload.page : 1, sorting: payload?.sorting || undefined })),
      concatLatestFrom(() => this['store'].pipe(select(getProductListingItemsPerPage('search')))),
      map(([payload, pageSize]) => ({ ...payload, amount: pageSize, offset: (payload.page - 1) * pageSize })),
      concatMap(({ searchTerm, amount, sorting, offset, page }) => {
        const correctedTerm = CamfilSearchHelper.handleSearchTermWithSlash(searchTerm);
        return this['productsService'].searchProducts(correctedTerm, amount, sorting, offset).pipe(
          concatMap(({ total, products, sortableAttributes }) => [
            ...products.map(product => loadProductSuccess({ product })),
            setProductListingPages(
              this['productListingMapper'].createPages(
                products.map(p => p.sku),
                CAMFIL_QUICK_SEARCH_ID,
                correctedTerm,
                amount,
                {
                  startPage: page,
                  sorting,
                  sortableAttributes,
                  itemCount: total,
                }
              )
            ),
            openSearchResultsOverlay(),
          ]),
          mapErrorToAction(searchProductsFail)
        );
      })
    )
  );

  setSearchTermValid$ = createEffect(() =>
    this['actions$'].pipe(
      takeWhile(() => !SSR),
      ofType(setSearchTerm),
      mapToPayloadProperty('searchTerm'),
      distinctUntilChanged(),
      concatLatestFrom(() => this['store'].pipe(select(getCamfilMinLengthByName('searchTerm')))),
      map(([searchTerm, minLength]) =>
        setSearchTermValid({
          searchTermValid: searchTerm?.trim().length >= minLength,
        })
      )
    )
  );

  triggerQuickSearch$ = createEffect(() =>
    this['actions$'].pipe(
      takeWhile(() => !SSR),
      ofType(setSearchTermValid),
      mapToPayloadProperty('searchTermValid'),
      distinctUntilChanged(),
      whenTruthy(),
      concatLatestFrom(() => this['store'].pipe(select(getCamfilSearchProductListingId))),
      mergeMap(([, id]) => [loadMoreProducts({ id }), openSearchResultsOverlay()])
    )
  );

  routeListenerForSearchTerm$ = createEffect(() =>
    this['store'].pipe(
      select(selectRouteParam('searchTerm')),
      distinctUntilChanged(),
      whenTruthy(),
      map(searchTerm => setSearchTerm({ searchTerm }))
    )
  );

  routeListenerForClosingOverlay$ = createEffect(() =>
    this['actions$'].pipe(
      ofType(routerNavigationAction),
      mapToRouterState(),
      map(() => closeSearchResultsOverlay())
    )
  );

  redirectAfterClearSearchValue$ = createEffect(
    () =>
      this['actions$'].pipe(
        ofType(redirectAfterClearSearchValue),
        mapToPayloadProperty('searchType'),
        filter(type => type === TypeOfSearchInput.Search),
        concatLatestFrom(() => [
          this['store'].pipe(select(getNavigationCategories(undefined))),
          this['store'].pipe(select(selectUrl)),
        ]),
        filter(([_, roots, url]) => !!roots.length && url.includes('/search')),
        concatMap(([_, roots]) => from(this['router'].navigate([roots[0].url])))
      ),
    { dispatch: false }
  );
}
