<!-- keep-localization-pattern: ^locale\..*\.(long|short)$ -->
<div *ngIf="locale$ | async as locale" class="language-switch">
  <ng-container *ngIf="availableLocales$ | async as availableLocales">
    <ng-container *ngIf="availableLocales.length === 1">
      <ng-container *ngTemplateOutlet="noLocalesMenuTemplate; context: { locale: locale }" />
    </ng-container>
    <ng-container *ngIf="availableLocales.length > 1">
      <a
        class="language-switch-link"
        role="button"
        aria-haspopup="menu"
        [matMenuTriggerFor]="menu"
        #menuTrigger="matMenuTrigger"
      >
        <mat-icon svgIcon="globe" />
        <span class="language-switch-current-selection d-inline pl-2">
          {{ 'locale.' + locale + '.short' | translate }}
        </span>
        <mat-icon>
          {{ menuTrigger.menuOpen ? 'arrow_drop_up' : 'arrow_drop_down' }}
        </mat-icon>
        <span class="d-inline-block mr-2"> &nbsp; </span>
      </a>
      <div class="language-switch-container dropdown-menu" role="menu">
        <div class="language-switch-menu-container">
          <mat-menu #menu="matMenu">
            <ng-container *ngFor="let l of availableLocales">
              <a
                mat-menu-item
                [href]="location | makeHref : { lang: l } | async"
                role="menuitem"
                *ngIf="l !== locale"
                (click)="setLocaleCookie(l)"
              >
                {{ 'locale.' + l + '.long' | translate }}
              </a>
            </ng-container>
          </mat-menu>
        </div>
      </div>
    </ng-container>
  </ng-container>
</div>

<ng-template #noLocalesMenuTemplate let-locale="locale">
  <span class="language-switch-link" role="button">
    <mat-icon svgIcon="globe" />
    <span class="language-switch-current-selection d-inline pl-2">
      {{ 'locale.' + locale + '.short' | translate }}
    </span>
    <span class="d-inline-block mr-2"> &nbsp; </span>
  </span>
</ng-template>
