<mat-icon svgIcon="cart" color="primary" camfilDialogIcon />
<h2 camfilDialogTitle>{{ 'camfil.modal.addToCart.title' | translate }}</h2>
<form class="content" [formGroup]="form" (ngSubmit)="onSubmit()" camfilDialogContent separated>
  <camfil-product-form-dialog-product
    *ngFor="let product of dialogData; index as index"
    class="product"
    [name]="product.name"
    [sku]="product.sku"
    [control]="form.controls.products.controls[index]"
  />
  <ng-container *ngIf="buckets$ | async as buckets">
    <h3 class="buckets-header">{{ bucketsHeaderLabel$ | async | translate }}</h3>
    <div class="buckets">
      <label
        *ngFor="let b of buckets"
        class="bucket"
        [class.bucket-selected]="bucket.value === b.shipToAddress"
        matRipple
      >
        <input type="radio" [value]="b.shipToAddress" formControlName="bucket" class="bucket-input" />
        <dl class="bucket-properties">
          <dt>{{ bucketLabel$ | async | translate }}</dt>
          <dd>{{ b.index }}</dd>
          <ng-container *ngIf="b.orderMark">
            <dt>{{ bucketOrderMarkLabel$ | async | translate }}</dt>
            <dd>{{ b.orderMark }}</dd>
          </ng-container>
          <ng-container *ngIf="bucket.value === b.shipToAddress">
            <ng-container *ngIf="b.invoiceLabel">
              <dt>{{ 'camfil.checkout.form.invoice_mark' | translate }}</dt>
              <dd>{{ b.invoiceLabel }}</dd>
            </ng-container>
            <dt>{{ 'camfil.account.cam_card.add_to_form.delivery_address' | translate }}</dt>
            <dd>
              <camfil-address
                class="bucket-properties-address-content"
                [address]="b.deliveryAddress"
                [displayEmail]="false"
              />
            </dd>
          </ng-container>
        </dl>
      </label>
    </div>
  </ng-container>
</form>
<div camfilDialogActions>
  <button
    mat-button
    color="primary"
    type="button"
    [disabled]="form.invalid"
    (click)="createNewOrder()"
    *ngIf="showNewOrderBtn$ | async"
  >
    {{ createNewOrderLabel$ | async | translate }}
  </button>

  <button
    mat-raised-button
    color="primary"
    type="button"
    [disabled]="form.invalid || !(currentBucket$ | async)?.shipToAddress"
    (click)="onSubmit()"
  >
    {{ addToExistingOrderLabel$ | async | translate }}
  </button>
</div>
