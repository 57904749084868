<mat-icon svgIcon="camcard" color="primary" camfilDialogIcon />
<h2 camfilDialogTitle>{{ 'camfil.modal.createCamcard.title' | translate }}</h2>
<form [formGroup]="form" (ngSubmit)="submitForm()" camfilDialogContent separated class="content">
  <formly-form [form]="form" [fields]="fields" />
  <camfil-loading *ngIf="zipCodesLoading$ | async" />
</form>
<div camfilDialogActions>
  <button mat-button type="button" (click)="matDialogRef.close()">
    {{ 'camfil.modal.createCamcard.button.cancel' | translate }}
  </button>
  <button mat-button color="primary" type="button" [disabled]="form.invalid || form.disabled" (click)="submitForm()">
    {{ 'camfil.modal.createCamcard.title' | translate }}
  </button>
</div>
