import { MAT_MOMENT_DATE_FORMATS } from '@angular/material-moment-adapter';
import { MatDateFormats } from '@angular/material/core';

export const CAMFIL_NAMESPACE = 'camfil';

export const CAMFIL_FALLBACK_LANG = 'en_GB';

export const CAMFIL_DATE_FORMATS: MatDateFormats = {
  ...MAT_MOMENT_DATE_FORMATS,
  parse: {
    dateInput: 'L',
  },
  display: {
    dateInput: 'L',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

export const CAMFIL_QUICK_SEARCH_ID = 'camfil-quick-search';

export const CAMFIL_STAY_SCROLL_PARAMS = ['filters', 'slots'];
export const CAMFIL_STAY_SCROLL_HOST_PARTS = [['/manufacturer/', '/unit/']];

export const CAMFIL_CROSSSELLING_CHEKOUT_CATEGORY = 'Products';

export const CAMFIL_SEARCH_VARIABLES = {
  maxVisibleProducts: 5,
  maxVisibleCategories: 9,
  debounceTime: 400,
};

// by bootstrap variables $grid-breakpoints
export const CAMFIL_BREAKPOINT_SM = 576;
export const CAMFIL_BREAKPOINT_MD = 768;
export const CAMFIL_BREAKPOINT_LG = 992;
export const CAMFIL_BREAKPOINT_XL = 1200;
