import { createAction } from '@ngrx/store';
import { CamfilOrder } from 'camfil-models/camfil-order/camfil-order.model';

import { httpError, payload } from 'ish-core/utils/ngrx-creators';

export const loadCamfilOrders = createAction('[CamfilOrders] Load CamfilOrderss');

export const loadCamfilOrdersIfNotLoaded = createAction('[CamfilOrders] Load CamfilOrders if not loaded');

export const loadCamfilOrdersSuccess = createAction(
  '[CamfilOrders] Load CamfilOrders Success',
  payload<{ orders: CamfilOrder[] }>()
);

export const loadCamfilOrdersFailure = createAction('[CamfilOrders] Load CamfilOrders Failure', httpError());

export const selectOrder = createAction('[CamfilOrders] Select Order', payload<{ camfilOrderId: string }>());

export const loadCamfilOrder = createAction('[CamfilOrders] Load Order', payload<{ camfilOrderId: string }>());

export const loadCamfilOrderIfNotLoaded = createAction(
  '[CamfilOrders] Load Order if not loaded',
  payload<{ camfilOrderId: string }>()
);

export const loadCamfilOrderFail = createAction('[CamfilOrders] Load Order Fail', httpError());

export const loadCamfilOrderSuccess = createAction(
  '[CamfilOrders] Load Order Success',
  payload<{ order: CamfilOrder }>()
);

export const cloneCamfilOrder = createAction('[CamfilOrders] Create order duplicate', payload<{ orderId: string }>());

export const cloneCamfilOrderFail = createAction('[CamfilOrders] Create order duplicate Fail', httpError());

export const cloneCamfilOrderSuccess = createAction('[CamfilOrders] Create order duplicate Success');

export const createCamfilOrder = createAction('[CamfilOrders] Create Camfil Order');
