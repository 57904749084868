import { NgModule } from '@angular/core';

import { LazyCamfilSearchComponent } from './lazy-camfil-search/lazy-camfil-search.component';

@NgModule({
  imports: [],
  declarations: [LazyCamfilSearchComponent],
  exports: [LazyCamfilSearchComponent],
})
export class CamfilPwaExportsModule {}
