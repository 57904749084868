<div
  class="banner-container"
  [ngClass]="pagelet.stringParam('CSSClass')"
  [ngStyle]="{ 'background-image': 'url(' + pagelet.stringParam('Image') + ')' }"
>
  <div class="container banner-text pb-5">
    <h1 *ngIf="pagelet.hasParam('Heading1')">
      {{ pagelet.stringParam('Heading1') }}
    </h1>
    <p *ngIf="pagelet.hasParam('Description')" class="mb-3" [ishServerHtml]="pagelet.stringParam('Description')"></p>

    <ng-container *ngIf="pagelet.hasParam('Link') && pagelet.hasParam('LinkText')">
      <ng-container *ngIf="isRouterLink(pagelet, 'Link'); else externalLink">
        <a
          [routerLink]="routerLink(pagelet, 'Link')"
          [attr.title]="pagelet.stringParam('LinkTitle')"
          mat-raised-button
          class="px-5"
          color="primary"
        >
          {{ pagelet.stringParam('LinkText') }}
        </a>
      </ng-container>
      <ng-template #externalLink>
        <a
          [href]="pagelet.stringParam('Link')"
          [attr.title]="pagelet.stringParam('LinkTitle')"
          mat-raised-button
          class="px-5"
          color="primary"
        >
          {{ pagelet.stringParam('LinkText') }}
        </a>
      </ng-template>
    </ng-container>
  </div>
</div>
