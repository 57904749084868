import { ChangeDetectionStrategy, Component, HostBinding, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

export type CamfilCmsLightboxArticleData = string;

export type CamfilCmsLightboxArticleResult = void;

/* TODO: It would be good to show title too, but current pagelet does not have such field. */
@Component({
  selector: 'camfil-cms-lightbox-article',
  templateUrl: './camfil-cms-lightbox-article.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CamfilCmsLightboxArticleComponent implements OnInit {
  @HostBinding('class.camfil-dialog') camfilDialogClass = true;

  contents: SafeHtml;

  constructor(
    private matDialogRef: MatDialogRef<CamfilCmsLightboxArticleComponent, CamfilCmsLightboxArticleResult>,
    // eslint-disable-next-line ish-custom-rules/use-type-safe-injection-token
    @Inject(MAT_DIALOG_DATA) private dialogData: CamfilCmsLightboxArticleData,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    /* DIALOG_DATA is injection token with any type we have to cast it */
    this.contents = this.sanitizer.bypassSecurityTrustHtml(this.dialogData);
  }

  close() {
    this.matDialogRef.close();
  }
}
