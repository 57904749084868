import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { CamfilMeasurementFilterFacade } from 'camfil-core/facades/camfil-measurement-filter.facade';
import { Observable } from 'rxjs';

import { ShoppingFacade } from 'ish-core/facades/shopping.facade';
import { FilterNavigation } from 'ish-core/models/filter-navigation/filter-navigation.model';

@Component({
  selector: 'camfil-filter-navigation',
  templateUrl: './camfil-filter-navigation.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CamfilFilterNavigationComponent implements OnInit {
  @Input() showCategoryFilter = true;

  filter$: Observable<FilterNavigation>;

  constructor(private shoppingFacade: ShoppingFacade, private measurementFilterFacade: CamfilMeasurementFilterFacade) {}

  applyFilter = this.measurementFilterFacade.applyFilter.bind(this.measurementFilterFacade);

  ngOnInit() {
    this.filter$ = this.shoppingFacade.currentFilter$(this.showCategoryFilter);
  }
}
