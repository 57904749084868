import { NgModule } from '@angular/core';
import { FORMLY_CONFIG } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';

import { registerCamfilFormlyTranslateExtension } from './camfil-formly-translate.extension';

@NgModule({
  providers: [
    {
      provide: FORMLY_CONFIG,
      multi: true,
      useFactory: registerCamfilFormlyTranslateExtension,
      deps: [TranslateService],
    },
  ],
})
export class CamfilFormlyExtensionsModule {}
