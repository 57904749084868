import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { CamfilZipCodeAddressService } from 'camfil-core/services/camfil-zip-code-address/camfil-zip-code-address.service';
import { selectCountryCode3 } from 'camfil-core/store/camfil-configuration/camfil-configuration.selectors';
import { filter, map, mergeMap, switchMap } from 'rxjs/operators';

import { mapErrorToAction, mapToPayload } from 'ish-core/utils/operators';

import {
  loadZipCodeAddress,
  loadZipCodeAddressFail,
  loadZipCodeAddressIfNotLoaded,
  loadZipCodeAddressSuccess,
} from './camfil-zip-code-address.actions';
import { getAllCamfilZipCodeAddresses } from './camfil-zip-code-address.selectors';

@Injectable()
export class CamfilZipCodeAddressEffects {
  constructor(
    private actions$: Actions,
    private camfilZipCodeAddressService: CamfilZipCodeAddressService,
    private store: Store
  ) {}

  loadZipCode$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadZipCodeAddress),
      mapToPayload(),
      concatLatestFrom(() => this.store.pipe(select(selectCountryCode3))),
      switchMap(([{ code, countryCode }, currentCountryCode]) =>
        this.camfilZipCodeAddressService.loadZipCodeAddress(code, countryCode || currentCountryCode).pipe(
          mergeMap(address => [loadZipCodeAddressSuccess({ code, address })]),
          mapErrorToAction(loadZipCodeAddressFail)
        )
      )
    )
  );

  loadZipCodeIfNotLoaded$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadZipCodeAddressIfNotLoaded),
      mapToPayload(),
      concatLatestFrom(() => this.store.pipe(select(getAllCamfilZipCodeAddresses))),
      filter(([{ code }, codes]) => !codes?.[code]),
      map(([{ code, countryCode }]) => loadZipCodeAddress({ code, countryCode }))
    )
  );
}
