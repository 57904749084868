import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { AhuService } from 'camfil-core/services/ahu/ahu.service';
import { UnitFilterHelper } from 'camfil-models/camfil-ahu/unit-filter/unit-filter.helper';
import { mergeMap } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';

import { serverError } from 'ish-core/store/core/error';
import { mapErrorToAction, mapToPayload, mapToPayloadProperty, whenTruthy } from 'ish-core/utils/operators';

import { getSelectedAhuUnit } from '../unit/unit.selectors';

import {
  loadAhuUnitFilter,
  loadAhuUnitFilterFail,
  loadAhuUnitFilterIfNotLoaded,
  loadAhuUnitFilterSuccess,
} from './unit-filter.actions';
import { getAhuUnitFilterEntities } from './unit-filter.selectors';

@Injectable()
export class AhuUnitFilterEffects {
  constructor(private actions$: Actions, private ahuService: AhuService, private store: Store) {}

  loadAhuUnitFilter$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadAhuUnitFilter),
      mapToPayload(),
      switchMap(({ manufacturerId, unitId }) =>
        this.ahuService.getUnitFilters(manufacturerId, unitId).pipe(
          map(unitFilter => loadAhuUnitFilterSuccess({ unitFilter })),
          mapErrorToAction(loadAhuUnitFilterFail)
        )
      )
    )
  );

  loadAhuUnitFilterIfNotLoaded$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadAhuUnitFilterIfNotLoaded),
      mapToPayload(),
      concatLatestFrom(() => this.store.pipe(select(getAhuUnitFilterEntities))),
      filter(([{ unitId }, entities]) => !UnitFilterHelper.isSufficientlyLoaded(entities[unitId])),
      map(([{ manufacturerId, unitId }]) => loadAhuUnitFilter({ manufacturerId, unitId }))
    )
  );

  loadAhuUnitFilterForSelectedUnit$ = createEffect(() =>
    this.store.pipe(
      select(getSelectedAhuUnit),
      whenTruthy(),
      mergeMap(unit => [loadAhuUnitFilterIfNotLoaded({ unitId: unit.id, manufacturerId: unit.manufacturerId })])
    )
  );

  loadAhuUnitFilterFail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadAhuUnitFilterFail),
      mapToPayloadProperty('error'),
      map(error => serverError({ error })) // Force server error handler when Error 404
    )
  );
}
