<ng-container *camfilChannelToggle="'showPrintButtonOnQuotations'">
  <button
    mat-stroked-button
    color="primary"
    (click)="printQuote()"
    [disabled]="canPrintQuote === false"
    class="secondary-button"
  >
    <mat-icon svgIcon="print" class="size-15" />
    {{ 'camfil.quotes.quoteslist.cta.print.label' | translate }}
  </button>
</ng-container>
