import { ChangeDetectionStrategy, Component, DestroyRef, HostBinding, Inject, OnInit, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CamCardsFacade } from 'camfil-core/facades/camfil-cam-cards.facade';
import { CamfilCustomersFacade } from 'camfil-core/facades/camfil-customers.facade';
import { CamCard } from 'camfil-models/camfil-cam-card/cam-card.model';
import { CamfilContact } from 'camfil-models/camfil-customer/camfil-customer.model';
import { Observable } from 'rxjs';

import { markAsDirtyRecursive } from 'ish-shared/forms/utils/form-utils';

export type CamfilUserAccessCamCardDialogData = CamCard;

export type CamfilUserAccessCamCardDialogResult = never;

@Component({
  selector: 'camfil-user-access-cam-card-dialog',
  templateUrl: './camfil-user-access-cam-card-dialog.component.html',
  styleUrls: ['./camfil-user-access-cam-card-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CamfilUserAccessCamCardDialogComponent implements OnInit {
  @HostBinding('class.camfil-dialog') camfilDialogClass = true;

  accessForm: FormGroup;
  allContacts$: Observable<CamfilContact[]>;
  contacts: CamfilContact[];
  users: string[] = ['all', 'single'];
  selectedUser: string;

  validators = {
    user: [
      {
        error: 'required',
        message: 'camfil.account.cam_card.access_user.dialog.error.user.default',
      },
    ],
    contact: [
      {
        error: 'required',
        message: 'camfil.account.cam_card.access_user.dialog.error.contact.default',
      },
    ],
  };

  private destroyRef = inject(DestroyRef);

  constructor(
    private camfilCustomersFacade: CamfilCustomersFacade,
    private camCardsFacade: CamCardsFacade,
    private fb: FormBuilder,
    private matDialogRef: MatDialogRef<CamCard, CamfilUserAccessCamCardDialogResult>,
    // eslint-disable-next-line ish-custom-rules/use-type-safe-injection-token
    @Inject(MAT_DIALOG_DATA) private dialogData: CamfilUserAccessCamCardDialogData
  ) {}

  private initForm() {
    this.accessForm = this.fb.group({
      user: ['', [Validators.required]],
      contact: [this.contacts],
    });
  }

  ngOnInit() {
    this.initForm();

    if (this.dialogData?.customerId) {
      const { customerId } = this.dialogData;
      this.camfilCustomersFacade.loadContactsByCustomer(customerId);
      this.allContacts$ = this.camfilCustomersFacade.getContactsByCustomer$(customerId);
      this.allContacts$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(contacts => {
        this.contacts = contacts;
      });

      this.accessForm.get('contact').patchValue(this.dialogData.contacts.map(c => c.profileId));
    }

    // determine which radio button is preselected
    if (this.dialogData?.contacts?.length) {
      this.selectedUser = 'single';
    } else {
      this.selectedUser = 'all';
    }
  }

  submit() {
    if (this.accessForm.valid) {
      const camCardContacts: CamfilContact[] = this.isUserSingle()
        ? this.accessForm.get('contact').value.map((item: string) => ({ profileId: item }))
        : [];

      this.camCardsFacade.updateCamCardContacts(this.dialogData.id, camCardContacts);
      this.matDialogRef.close();
    } else {
      markAsDirtyRecursive(this.accessForm);
    }
  }

  getField(name: string) {
    return this.accessForm.get(name);
  }

  isUserSingle() {
    return this.accessForm.get('user').value === 'single';
  }

  closeDialog(): void {
    this.matDialogRef.close();
  }
}
