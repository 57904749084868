import { createSelector } from '@ngrx/store';
import { getCamfilFeatureState } from 'camfil-core/store/camfil-store';

import { CamfilSearchState, camfilSearchFeatureKey } from './camfil-search.reducer';

const getCamfilSearchState = getCamfilFeatureState<CamfilSearchState>(camfilSearchFeatureKey);

export const getCamfilSearchLoading = createSelector(getCamfilSearchState, state => state.loading);

export const getCamfilSearchError = createSelector(getCamfilSearchState, state => state.error);

export const getCamfilSearchOverlayOpen = createSelector(getCamfilSearchState, state => state.overlayOpen);

export const getCamfilSearchProductListingId = createSelector(getCamfilSearchState, state => state.productListingId);

export const getCamfilSearchTerm = createSelector(getCamfilSearchState, state => state.searchTerm);

export const getCamfilSearchTermValid = createSelector(getCamfilSearchState, state => state.searchTermValid);
