<ng-container *ngIf="links$ | async as links">
  <ng-container *ngIf="links.crossselling?.products?.length">
    <ng-container *ngTemplateOutlet="list; context: { type: 'crossselling', available: true }" />
  </ng-container>

  <ng-container *ngIf="links.camfilcheckoutcrosssell?.products?.length">
    <ng-container *ngTemplateOutlet="list; context: { type: 'camfilcheckoutcrosssell', available: true }" />
  </ng-container>

  <!-- TODO: refactor as crossselling example -->
  <ng-container *ngIf="links.upselling?.products?.length">
    <camfil-product-links-list
      [links]="links.upselling"
      [productLinkTitle]="'product.product_links.upselling.title' | translate"
      [displayOnlyAvailableProducts]="true"
    />
  </ng-container>
  <ng-container *ngIf="links.spareparts?.products?.length">
    <camfil-product-links-list
      [links]="links.spareparts"
      [productLinkTitle]="'product.product_links.spareparts.title' | translate"
    />
  </ng-container>
  <ng-container *ngIf="links.accessory?.products?.length">
    <camfil-product-links-list
      [links]="links.accessory"
      [productLinkTitle]="'product.product_links.accessory.title' | translate"
    />
  </ng-container>
  <ng-container *ngIf="links.differentorderunit?.products?.length">
    <camfil-product-links-list
      [links]="links.differentorderunit"
      [productLinkTitle]="'product.product_links.differentorderunit.title' | translate"
    />
  </ng-container>
  <ng-container *ngIf="links.followup?.products?.length">
    <camfil-product-links-list
      [links]="links.followup"
      [productLinkTitle]="'product.product_links.followup.title' | translate"
    />
  </ng-container>
  <ng-container *ngIf="links.other?.products?.length">
    <camfil-product-links-list
      [links]="links.other"
      [productLinkTitle]="'product.product_links.other.title' | translate"
    />
  </ng-container>
  <ng-container *ngIf="links.replacement?.products?.length">
    <camfil-product-links-list
      [links]="links.replacement"
      [productLinkTitle]="'product.product_links.replacement.title' | translate"
    />
  </ng-container>

  <ng-template #list let-type="type" let-available="available">
    <camfil-product-links-list
      [links]="links?.[type]"
      [productLinkTitle]="getTitle(type) | translate"
      [displayOnlyAvailableProducts]="available"
      [showAll]="fullList?.[type]"
      [showCTAButton]="showCTAButton"
      [itemInRow]="itemsInRow"
      (emitExpanded)="detectExpanded($event)"
      class="mx-4 d-flex"
    />
    <div *ngIf="showAllLink(links, type)" class="text-right p-4">
      <button mat-stroked-button color="primary" (click)="showAllProducts(type)">
        {{ 'common.view_all.link' | translate }}
      </button>
    </div>
  </ng-template>
</ng-container>
