<ng-container>
  <div class="d-flex align-items-center justify-content-center form-header">
    <h2 camfilDialogTitle>{{ 'approval.detailspage.update.delivery.date.modal.header' | translate }}</h2>
  </div>
  <div class="form-content__container" camfilDialogContent separated>
    {{ 'approval.detailspage.update.delivery.date.modal.content' | translate : { '0': (minDeliveryDate | async) } }}
  </div>
  <div camfilDialogActions>
    <button mat-raised-button type="button" (click)="matDialogRef.close()">
      {{ 'approval.detailspage.update.delivery.date.modal.btn.cancel' | translate }}
    </button>
    <button mat-raised-button color="primary" type="button" (click)="approveRequisition()">
      {{ 'approval.detailspage.update.delivery.date.modal.btn.confirm' | translate }}
    </button>
  </div>
</ng-container>
