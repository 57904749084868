import { createAction } from '@ngrx/store';

import { payload } from 'ish-core/utils/ngrx-creators';

export const quickSearchProducts = createAction(
  '[Camfil Search Internal] Quick Search Products',
  payload<{ searchTerm: string; page?: number; sorting?: string }>()
);

export const openSearchResultsOverlay = createAction('[Camfil Search Internal] Open Search Results Overlay');

export const closeSearchResultsOverlay = createAction('[Camfil Search Internal] Close Search Results Overlay');

export const setSearchTerm = createAction(
  '[Camfil Search Internal] Set Search Term',
  payload<{ searchTerm: string }>()
);

export const setSearchTermValid = createAction(
  '[Camfil Search Internal] Set Search Term Valid',
  payload<{ searchTermValid: boolean }>()
);

export const unsetSearchTerm = createAction('[Camfil Search Internal] Unset Search Term');

export const redirectAfterClearSearchValue = createAction(
  '[Camfil Search Internal] Redirect After Clear Search Term',
  payload<{ searchType: number }>()
);
