import { EntityState, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { CamfilB2bUser } from 'camfil-models/camfil-organization/user/camfil-b2b-user.model';

import { HttpError } from 'ish-core/models/http-error/http-error.model';
import { setErrorOn, setLoadingOn, unsetLoadingAndErrorOn } from 'ish-core/utils/ngrx-creators';

import { updateCustomerUserRolesSuccess } from '../b2b-roles/b2b-roles.actions';

import {
  activateCustomerUser,
  activateCustomerUserFail,
  activateCustomerUserSuccess,
  connectContactWithUserAndCustomer,
  connectContactWithUserAndCustomerFail,
  connectContactWithUserAndCustomerSuccess,
  createCustomerUser,
  createCustomerUserFail,
  createCustomerUserSuccess,
  deactivateCustomerUser,
  deactivateCustomerUserFail,
  deactivateCustomerUserSuccess,
  disconnectUserFromCustomer,
  disconnectUserFromCustomerFail,
  disconnectUserFromCustomerSuccess,
  loadCustomerUser,
  loadCustomerUserApproversSuccess,
  loadCustomerUserFail,
  loadCustomerUserSuccess,
  loadCustomerUsers,
  loadCustomerUsersFail,
  loadCustomerUsersSuccess,
  loadOrganizationUsers,
  loadOrganizationUsersFail,
  loadOrganizationUsersSuccess,
  selectUser,
  updateCustomerUser,
  updateCustomerUserFail,
  updateCustomerUserSuccess,
} from './b2b-users.actions';

export const camfilB2bUsersFeatureKey = 'b2bUsers';

export const userAdapter = createEntityAdapter<CamfilB2bUser>({
  selectId: user => user?.id,
});

export interface B2bUsersState extends EntityState<CamfilB2bUser> {
  loading: boolean;
  selected: string;
  error: HttpError;
  initialized: boolean;
}

export const initialState: B2bUsersState = userAdapter.getInitialState({
  loading: false,
  selected: undefined,
  error: undefined,
  initialized: false,
});

export const reducer = createReducer(
  initialState,
  setLoadingOn(
    loadCustomerUsers,
    loadCustomerUser,
    activateCustomerUser,
    deactivateCustomerUser,
    updateCustomerUser,
    createCustomerUser,
    disconnectUserFromCustomer,
    connectContactWithUserAndCustomer,
    loadOrganizationUsers
  ),
  setErrorOn(
    loadCustomerUsersFail,
    loadCustomerUserFail,
    activateCustomerUserFail,
    deactivateCustomerUserFail,
    updateCustomerUserFail,
    createCustomerUserFail,
    disconnectUserFromCustomerFail,
    connectContactWithUserAndCustomerFail,
    loadOrganizationUsersFail
  ),
  unsetLoadingAndErrorOn(
    loadCustomerUsersSuccess,
    loadCustomerUserSuccess,
    activateCustomerUserSuccess,
    deactivateCustomerUserSuccess,
    updateCustomerUserSuccess,
    createCustomerUserSuccess,
    disconnectUserFromCustomerSuccess,
    connectContactWithUserAndCustomerSuccess,
    loadOrganizationUsersSuccess
  ),
  on(
    // eslint-disable-next-line @ngrx/avoid-duplicate-actions-in-reducer
    loadCustomerUsersSuccess,
    loadCustomerUserSuccess,
    activateCustomerUserSuccess,
    deactivateCustomerUserSuccess,
    updateCustomerUserSuccess,
    createCustomerUserSuccess,
    disconnectUserFromCustomerSuccess,
    connectContactWithUserAndCustomerSuccess,
    loadOrganizationUsersSuccess,
    (state: B2bUsersState): B2bUsersState => ({ ...state, initialized: true })
  ),
  on(
    selectUser,
    (state: B2bUsersState, action): B2bUsersState => ({
      ...state,
      selected: action.payload.userId,
    })
  ),
  // eslint-disable-next-line @ngrx/avoid-duplicate-actions-in-reducer
  on(loadCustomerUsersSuccess, (state: B2bUsersState, action): B2bUsersState => {
    const { customerId, users } = action.payload;
    const usersWithCustomerId = [...users.map(user => ({ ...user, customerId }))];

    return userAdapter.upsertMany(usersWithCustomerId, state);
  }),
  on(loadCustomerUserSuccess, (state: B2bUsersState, action): B2bUsersState => {
    const { customerId, user } = action.payload;
    const userWithCustomerId = { ...user, customerId };

    return userAdapter.upsertOne(userWithCustomerId, state);
  }),
  on(activateCustomerUserSuccess, deactivateCustomerUserSuccess, (state: B2bUsersState, action): B2bUsersState => {
    const { active, customerId, userId } = action.payload;

    const changedUser = { ...state.entities?.[userId], active, customerId };

    return userAdapter.upsertOne(changedUser, state);
  }),
  on(updateCustomerUserSuccess, createCustomerUserSuccess, (state: B2bUsersState, action): B2bUsersState => {
    const { user } = action.payload;

    // Dirty hack since we are using existing Intershop API which does not return `user.id`
    const changedUser = { ...Object.values(state?.entities)?.find(u => u.login === user.login), ...user };

    // Skip roleIDs relations;
    delete changedUser.roleIDs;

    return userAdapter.upsertOne(changedUser, state);
  }),
  on(updateCustomerUserRolesSuccess, (state: B2bUsersState, action): B2bUsersState => {
    const { roles, userId } = action.payload;

    const roleIDs = roles.map(role => role.id);

    return userAdapter.updateOne(
      {
        id: userId,
        changes: {
          roleIDs,
        },
      },
      state
    );
  }),
  on(
    disconnectUserFromCustomerSuccess,
    connectContactWithUserAndCustomerSuccess,
    (state: B2bUsersState, action): B2bUsersState => {
      const { user } = action.payload;

      return userAdapter.upsertOne(user, state);
    }
  ),
  on(loadOrganizationUsersSuccess, (state: B2bUsersState, action): B2bUsersState => {
    const { users } = action.payload;

    return userAdapter.upsertMany(users, state);
  }),
  on(loadCustomerUserApproversSuccess, (state: B2bUsersState, action): B2bUsersState => {
    const { userId, approvers } = action.payload;

    const entities = { ...state.entities };
    const user = entities[userId];
    if (user) {
      entities[userId] = { ...user, approvers };
    }

    return { ...state, entities };
  })
);
