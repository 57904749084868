import { ChangeDetectionStrategy, Component, HostBinding, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface CamfilBulkDeliveryDateConfirmModalData {
  joint: unknown[];
  partial: unknown[];
}

export type CamfilBulkDeliveryDateConfirmModalResult = Date;

@Component({
  selector: 'camfil-bulk-delivery-date-confirm-modal',
  templateUrl: './camfil-bulk-delivery-date-confirm-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CamfilBulkDeliveryDateConfirmModalComponent {
  @HostBinding('class.camfil-dialog') camfilDialogClass = true;

  constructor(
    public matDialogRef: MatDialogRef<
      CamfilBulkDeliveryDateConfirmModalComponent,
      CamfilBulkDeliveryDateConfirmModalResult
    >,
    // eslint-disable-next-line ish-custom-rules/use-type-safe-injection-token
    @Inject(MAT_DIALOG_DATA) public dialogData: CamfilBulkDeliveryDateConfirmModalData
  ) {}

  partial = this.dialogData.partial as { orderMark: string; position: number }[];
  joint = this.dialogData.joint as { orderMark: string; position: number }[];
}
