import { Pipe, PipeTransform } from '@angular/core';

import { AttributeHelper } from 'ish-core/models/attribute/attribute.helper';
import { Product } from 'ish-core/models/product/product.model';

@Pipe({ name: 'camfilProductAttributeValue', pure: true })
export class CamfilProductAttributeValuePipe implements PipeTransform {
  transform(product: Product, attrName: string): string {
    return AttributeHelper.getAttributeValueByAttributeName(product.attributes, attrName);
  }
}
