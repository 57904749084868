import { ChangeDetectionStrategy, Component, HostBinding, OnDestroy } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { CamCardsFacade } from 'camfil-core/facades/camfil-cam-cards.facade';
import { CamCardMapper } from 'camfil-models/camfil-cam-card/cam-card.mapper';
import { distinctUntilChanged, filter, map, withLatestFrom } from 'rxjs';

@Component({
  selector: 'camfil-add-cam-card-feedback-dialog',
  templateUrl: './camfil-add-cam-card-feedback-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CamfilAddCamCardFeedbackDialogComponent implements OnDestroy {
  @HostBinding('class.camfil-dialog') camfilDialogClass = true;

  camCardsFeedback$ = this.camCardsFacade.getCamCardsFeedback$.pipe(
    filter(infos => !!infos?.length),
    withLatestFrom(this.camCardsFacade.camCards$),
    distinctUntilChanged(),
    map(([data, ccs]) => CamCardMapper.supplementCamCardsFeedback(data, ccs))
  );

  constructor(
    private camCardsFacade: CamCardsFacade,
    private matDialogRef: MatDialogRef<void, CamfilAddCamCardFeedbackDialogComponent>
  ) {}

  ngOnDestroy() {
    this.camCardsFacade.cleanUpCamCardsFeedback();
  }

  close(): void {
    this.matDialogRef.close();
  }
}
