<div
  *ngIf="pagelet.hasParam('Video')"
  class="video-container"
  [ngClass]="pagelet.stringParam('CSSClass')"
  [ngStyle]="{ 'width.px': videoWidth, 'height.px': videoHeight }"
>
  <ng-container *ngIf="iframeVideoUrl; else nativeVideo">
    <div class="video-wrapper">
      <!-- spell-checker: words allowfullscreen -->
      <iframe
        [title]="pagelet?.displayName"
        loading="lazy"
        [src]="iframeVideoUrl"
        frameborder="0"
        allowfullscreen
      ></iframe>
    </div>
  </ng-container>

  <ng-template #nativeVideo>
    <video
      #videoPlayer
      [src]="nativeVideoUrl"
      class="video"
      width="100%"
      height="auto"
      [autoplay]="autoplay"
      [controls]="autoplay"
      [muted]="mute"
      [poster]="pagelet.stringParam('Image')"
      [title]="pagelet?.displayName"
    ></video>

    <div *ngIf="!autoplay && !playing" class="video-text">
      <h1 *ngIf="pagelet.hasParam('Heading')">{{ pagelet.stringParam('Heading') }}</h1>
      <button type="button" class="video-link btn btn-link btn-link-action" (click)="playing = true; playVideo()">
        <fa-icon [icon]="['fas', 'play-circle']" />
      </button>
    </div>
  </ng-template>
</div>
