import { ChangeDetectionStrategy, Component, HostBinding, Inject, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { CamfilBucket } from 'camfil-models/camfil-bucket/camfil-bucket.model';
import { CamfilSpecialValidators } from 'camfil-shared/formly/validators/special-validators';

import { markAsDirtyRecursive } from 'ish-shared/forms/utils/form-utils';

export type CamfilAddEmailRecipientFormDialogData = CamfilBucket;

export type CamfilAddEmailRecipientFormDialogResult = string;

@Component({
  selector: 'camfil-add-email-recipient-form',
  templateUrl: './camfil-add-email-recipient-form.component.html',
  styleUrls: ['./camfil-add-email-recipient-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CamfilAddEmailRecipientFormComponent implements OnInit {
  @HostBinding('class.camfil-dialog') camfilDialogClass = true;

  form = new UntypedFormGroup({});
  fields: FormlyFieldConfig[];

  constructor(
    public matDialogRef: MatDialogRef<CamfilAddEmailRecipientFormComponent, CamfilAddEmailRecipientFormDialogResult>,
    // eslint-disable-next-line ish-custom-rules/use-type-safe-injection-token
    @Inject(MAT_DIALOG_DATA) public camfilBucket: CamfilAddEmailRecipientFormDialogData
  ) {}

  ngOnInit() {
    this.fields = this.getFields();
  }

  submitAddEmailRecipientForm() {
    if (this.form.valid) {
      this.matDialogRef.close(this.form.get('emailRecipients').value);
      this.form.reset();
      this.form.markAsPristine();
    } else {
      markAsDirtyRecursive(this.form);
    }
  }

  private getFields() {
    return [
      {
        fieldGroup: [
          {
            key: 'emailRecipients',
            type: 'camfil-text-input-field',
            defaultValue: '',
            props: {
              label: 'camfil.checkout.cta.enter_email_address',
              required: true,
            },
            validators: {
              validation: [CamfilSpecialValidators.commaSeparatedEmailValidator],
            },
          },
        ],
        fieldGroupClassName: 'formly-wrapper',
      },
    ];
  }
}
