// eslint-disable-next-line ish-custom-rules/ban-imports-file-pattern
import { ChangeDetectionStrategy, Component, DestroyRef, Input, OnInit, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { CamfilQueryParametersFacade } from 'camfil-core/facades/camfil-query-parameters.facade';
import { Observable, filter, map } from 'rxjs';

import { AppFacade } from 'ish-core/facades/app.facade';
import { BreadcrumbItem } from 'ish-core/models/breadcrumb-item/breadcrumb-item.interface';

/**
 * component for setting the breadcrumb trail of a specific page
 *
 * Breadcrumbs can be set in two specific ways:
 * - setting the 'breadcrumbData' field as routing data
 * - dispatching a setBreadcrumbData action in an effect
 */
@Component({
  selector: 'camfil-breadcrumb',
  templateUrl: './camfil-breadcrumb.component.html',
  styleUrls: ['./camfil-breadcrumb.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CamfilBreadcrumbComponent implements OnInit {
  @Input() showHome = true;
  @Input() url: string;
  @Input() productDetail: boolean;

  trail$: Observable<BreadcrumbItem[]>;
  filters: string;

  private destroyRef = inject(DestroyRef);

  constructor(private appFacade: AppFacade, private queryParametersFacade: CamfilQueryParametersFacade) {}

  ngOnInit(): void {
    this.trail$ = this.appFacade.breadcrumbData$;

    this.queryParametersFacade
      .queryParam$('filters')
      .pipe(
        filter(filters => !!filters),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(filters => {
        this.filters = filters;
      });
  }

  get isHomePage(): Observable<boolean> {
    return this.trail$.pipe(map(trail => trail.length === 0));
  }
}
