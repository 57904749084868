<ng-container *ngIf="isMaintenanceMode$ | async; else appTemplate">
  <router-outlet />
</ng-container>

<ng-template #appTemplate>
  <div [ngClass]="wrapperClasses$ | async" class="d-flex flex-column min-vh-100">
    <camfil-header />
    <div class="wrapper">
      <camfil-breadcrumb />
      <main role="main" class="container main-container">
        <router-outlet />
      </main>
    </div>
    <footer data-testing-id="section-footer" class="mt-auto">
      <camfil-footer />
    </footer>
  </div>
  <ng-container *ngIf="livechatLicense$ | async as livechatLicense">
    <livechat-widget [license]="livechatLicense" [visibility]="livechatVisibility$ | async" />
  </ng-container>
  <camfil-queue />
</ng-template>
