<!-- eslint-disable @angular-eslint/template/click-events-have-key-events -->
<ng-container *ngIf="mode === 'edit'">
  <form [formGroup]="commentForm">
    <ng-container *ngFor="let input of inputs">
      <mat-form-field
        *ngIf="input !== 'text' || visibility"
        appearance="fill"
        class="form-field ml-md-2"
        ngClass="field_{{ input }}"
      >
        <mat-label>{{ 'camfil.account.cam_card.edit.comment.' + input + '.label' | translate }}</mat-label>
        <textarea
          #autosize="cdkTextareaAutosize"
          class="overflow-hidden"
          (blur)="onBlur($event.target, input)"
          (keyup.enter)="keyupEnter($event.target)"
          [camfilMaxLength]="'boxLabel'"
          attr.data-testing-id="cam-card-edit-comment-{{ input }}"
          formControlName="{{ input }}"
          markRequiredLabel="off"
          cdkAutosizeMaxRows="3"
          cdkAutosizeMinRows="1"
          cdkTextareaAutosize
          matInput
          name="boxLabel"
          type="text"
          [placeholder]="'camfil.account.cam_card.edit.comment.' + input + '.placeholder' | translate"
        ></textarea>
      </mat-form-field>
    </ng-container>
  </form>
</ng-container>

<ng-container *ngIf="(currCamCardItem?.comment?.text && mode === 'view') || (mode === 'edit' && !visibility)">
  <span
    #tooltip="matTooltip"
    aria-hidden="true"
    (click)="mode === 'edit' ? toggleVisibility() : tooltip.show()"
    (mouseenter)="$event.stopImmediatePropagation()"
    class="cam-card-comment ml-2"
    [ngClass]="!currCamCardItem?.comment?.text ? 'color-secondary' : 'color-primary'"
    matTooltip="{{ currCamCardItem?.comment?.text }}"
    matTooltipClass="cam-card-tooltip"
    matTooltipPosition="below"
  >
    <mat-icon class="size-24" svgIcon="comment" />
  </span>
</ng-container>
