import { DestroyRef, Directive, Input, TemplateRef, ViewContainerRef, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { CamfilAuthorizationToggleService } from 'camfil-core/utils/auth/authorization-toggle.service';
import { ReplaySubject, Subscription, distinctUntilChanged } from 'rxjs';

@Directive({
  selector: '[camfilIsNotAuthorizedTo]',
})
export class CamfilNotAuthorizationToggleDirective {
  private subscription: Subscription;
  private disabled$ = new ReplaySubject<boolean>(1);

  private destroyRef = inject(DestroyRef);

  constructor(
    private templateRef: TemplateRef<unknown>,
    private viewContainer: ViewContainerRef,
    private authorizationToggle: CamfilAuthorizationToggleService
  ) {
    this.disabled$.next(false);
    this.disabled$.pipe(distinctUntilChanged(), takeUntilDestroyed()).subscribe(disabled => {
      if (disabled) {
        this.viewContainer.clear();
      } else {
        this.viewContainer.createEmbeddedView(this.templateRef);
      }
    });
  }

  @Input() set camfilIsNotAuthorizedTo(permission: string | string[]) {
    // end previous subscription and subscribe to new permission
    if (this.subscription) {
      // eslint-disable-next-line ban/ban
      this.subscription.unsubscribe();
    }

    const permissions = Array.isArray(permission) ? permission : Array(permission);

    this.subscription = this.authorizationToggle
      .isAuthorizedToCheckArrAny(permissions)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({ next: val => this.disabled$.next(val) });
  }
}
