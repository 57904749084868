import { UrlMatchResult, UrlSegment } from '@angular/router';

// matcher to check if a given url is a product url
const productRouteFormat = /\/(?!ctg)(?!.*-ctg.*-prd)(.*?)-?prd(.*?)(-ctg(.*))?$/;

/**
 * check if a given url is a product url
 *
 * @param segments current url segments
 * @returns match result if given url is a product route or not
 */
export function camfilMatchProductRoute(segments: UrlSegment[]): UrlMatchResult {
  // compatibility to old routes
  const isSimpleProduct = segments && segments.length > 0 && segments[0].path === 'product';
  const isContextProduct = segments?.length > 2 && segments[0].path === 'category' && segments[2].path === 'product';
  if (isSimpleProduct || isContextProduct) {
    return { consumed: [] };
  }

  // generate complete url path
  const url = `/${segments
    .map(s => s.path)
    .join('/')
    .replace(/\r?\n|\r/g, '')}`;

  // check that complete url path is a product route
  if (productRouteFormat.test(url)) {
    // select product sku and categoryUniqueId to render a product detail page
    const match = productRouteFormat.exec(url);
    const posParams: { [id: string]: UrlSegment } = {};
    if (match[4]) {
      posParams.categoryUniqueId = new UrlSegment(match[4], {});
    }
    if (match[2]) {
      posParams.sku = new UrlSegment(match[2], {});
    }
    return {
      consumed: [],
      posParams,
    };
  }
  return;
}
