import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { CamCardsFacade } from 'camfil-core/facades/camfil-cam-cards.facade';
import { CamCard } from 'camfil-models/camfil-cam-card/cam-card.model';
import { Observable } from 'rxjs';

@Component({
  selector: 'camfil-cam-card-edit-mode',
  templateUrl: './cam-card-edit-mode.component.html',
  changeDetection: ChangeDetectionStrategy.Default,
})
export class CamCardEditModeComponent implements OnInit {
  constructor(protected camCardFacade: CamCardsFacade) {}
  getEditMode$: Observable<CamCard>;

  ngOnInit() {
    this.getEditMode$ = this.camCardFacade.getCamCardEditMode$;
  }

  onFocus() {
    this.setCCEditMode(true);
  }

  setCCEditMode(flag: boolean) {
    this.camCardFacade.setCamCardEditMode(flag);
  }
}
