<ng-container *ngIf="isEditable; else labelValue">
  <ng-container *ngIf="lineItem?.requisitionLineItemStatus !== 'APPROVED'; else labelValue">
    <form class="qty-form_container" [formGroup]="productItemForm">
      <camfil-product-quantity
        [allowZeroQuantity]="false"
        [isInLineItem]="true"
        [lineItemId]="lineItem?.id | slugify : 'line-item-box-label'"
        [parentForm]="productItemForm"
        [product]="product"
        [quantityLabel]="''"
        controlName="quantity"
      />
    </form>
  </ng-container>
</ng-container>
<ng-template #labelValue>
  <span>{{ this.lineItem?.quantity?.value || 1 }}</span>
</ng-template>
