import { Injectable } from '@angular/core';
import { Params, Router } from '@angular/router';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { routerNavigatedAction } from '@ngrx/router-store';
import { Store, select } from '@ngrx/store';
import { AhuService } from 'camfil-core/services/ahu/ahu.service';
import { AhuHelper } from 'camfil-models/camfil-ahu/ahu.helper';
import { UnitFilterSlotItemQueryParam } from 'camfil-models/camfil-ahu/unit-filter/unit-filter.model';
import { UnitHelper } from 'camfil-models/camfil-ahu/unit/unit.helper';
import { from } from 'rxjs';
import { concatMap, filter, map, mergeMap, switchMap } from 'rxjs/operators';

import { displayErrorMessage, displaySuccessMessage } from 'ish-core/store/core/messages';
import { ofUrl, selectQueryParam, selectRouteParam } from 'ish-core/store/core/router';
import { loadProductFail, loadProductVariationsFail } from 'ish-core/store/shopping/products';
import { mapErrorToAction, mapToPayload, mapToPayloadProperty, whenTruthy } from 'ish-core/utils/operators';

import {
  addAhuSlotItemToList,
  deselectAhuUnit,
  loadAhuUnits,
  loadAhuUnitsFail,
  loadAhuUnitsSuccess,
  removeAhuSlotItemFromList,
  selectAhuUnit,
  sendAhuRequestComment,
} from './unit.actions';
import { getSelectedAhuUnitId } from './unit.selectors';

@Injectable()
export class AhuUnitEffects {
  constructor(
    private actions$: Actions,
    private ahuService: AhuService,
    private router: Router,
    private store: Store
  ) {}

  loadAhuUnits$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadAhuUnits),
      mapToPayloadProperty('manufacturerId'),
      whenTruthy(),
      switchMap(manufacturerId =>
        this.ahuService.getUnits(manufacturerId).pipe(
          map(units => loadAhuUnitsSuccess({ units })),
          mapErrorToAction(loadAhuUnitsFail)
        )
      )
    )
  );

  routeListenerForSelectingAhuUnit$ = createEffect(() =>
    this.store.pipe(
      ofUrl(AhuHelper.urlRegExp),
      select(selectRouteParam(AhuHelper.UNIT_ID_QUERY_PARAM_NAME)),
      whenTruthy(),
      concatLatestFrom(() => this.store.pipe(select(getSelectedAhuUnitId))),
      filter(([fromAction, selectedUnitId]) => fromAction && fromAction !== `${selectedUnitId}`),
      map(([selectedUnitId]) => Number(selectedUnitId)),
      map(unitId => selectAhuUnit({ unitId }))
    )
  );

  addAhuSlotItemToList$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(addAhuSlotItemToList),
        mapToPayload(),
        concatLatestFrom(() => this.store.pipe(select(selectQueryParam(AhuHelper.SLOTS_QUERY_PARAM_NAME)))),
        concatMap(([slotItem, slotsQueryParam]) =>
          this.navigateTo(undefined, UnitHelper.addAhuSlotItemToList(slotsQueryParam, slotItem))
        )
      ),
    { dispatch: false }
  );

  removeAhuSlotItemFromList$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(removeAhuSlotItemFromList),
        mapToPayload(),
        concatLatestFrom(() => this.store.pipe(select(selectQueryParam(AhuHelper.SLOTS_QUERY_PARAM_NAME)))),
        concatMap(([ahuSlotItemParams, slotsQueryParam]) =>
          this.navigateTo(undefined, UnitHelper.removeAhuSlotItemFromList(slotsQueryParam, ahuSlotItemParams))
        )
      ),
    { dispatch: false }
  );

  removeFromListWhenProductFail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadProductFail, loadProductVariationsFail),
      mapToPayloadProperty('sku'),
      concatLatestFrom(() => this.store.pipe(select(selectQueryParam(AhuHelper.SLOTS_QUERY_PARAM_NAME)))),
      mergeMap(([productSku, slotsQueryParam]) => {
        const slots: UnitFilterSlotItemQueryParam = AhuHelper.parseQs(slotsQueryParam);
        const itemNumber = String(productSku);
        const removeActions = [];

        for (const slotId in slots) {
          if (slots.hasOwnProperty(slotId)) {
            const canBeRemoved = Boolean(slots[slotId]?.filter(item => item === itemNumber)?.length);
            if (canBeRemoved) {
              removeActions.push(
                removeAhuSlotItemFromList({
                  slotId: Number(slotId),
                  itemNumber,
                })
              );
            }
          }
        }

        return removeActions;
      })
    )
  );

  routeListenerForDeselectingAhuUnit$ = createEffect(() =>
    this.actions$.pipe(
      ofType(routerNavigatedAction),
      switchMap(() =>
        this.actions$.pipe(
          ofType(routerNavigatedAction),
          switchMap(() =>
            this.store.pipe(
              ofUrl(AhuHelper.urlRegExp),
              select(selectRouteParam(AhuHelper.UNIT_ID_QUERY_PARAM_NAME)),
              concatLatestFrom(() => this.store.pipe(select(getSelectedAhuUnitId))),
              filter(([routeParamUID, selectedUID]) => !routeParamUID && !!selectedUID),
              map(() => deselectAhuUnit())
            )
          )
        )
      )
    )
  );

  sendAhuRequestComment$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(sendAhuRequestComment),
        mapToPayloadProperty('data'),
        mergeMap(data =>
          this.ahuService.sendAhuRequestComment(data).pipe(
            map(() =>
              displaySuccessMessage({
                message: 'camfil.ahu.slot.no_items.sent.text',
              })
            ),
            mapErrorToAction(() => displayErrorMessage({ message: 'camfil.ahu.slot.no_items.error.text' }))
          )
        )
      ),
    { dispatch: false }
  );

  private navigateTo(path: string, queryParams?: Params) {
    let currentRoute = this.router.routerState.root;

    while (currentRoute.firstChild) {
      currentRoute = currentRoute.firstChild;
    }

    return from(
      this.router.navigate(path ? [path] : [], {
        relativeTo: currentRoute,
        queryParams,
        queryParamsHandling: 'merge',
      })
    );
  }
}
