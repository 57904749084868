<div *ngIf="object">
  {{ companyName1 }} {{ companyName2 }}
  <span *ngIf="taxationID" data-testing-id="taxationID">
    <br />{{ 'checkout.widget.buyer.TaxationID' | translate }} {{ taxationID }}
  </span>
  <p>
    {{ userName }}<br />
    {{ object.email || object.invoiceToAddress?.email }}
  </p>

  <div *ngIf="object.costCenter" data-testing-id="buyer-cost-center">
    <strong>{{ 'checkout.widget.buyer.costcenter' | translate }}</strong>
    <p>{{ object.costCenter }} {{ costCenterName }}</p>
  </div>

  <div *ngIf="object.externalOrderReference" data-testing-id="orderReferenceID">
    <a
      *ngIf="editRouterLink"
      class="float-right btn-tool"
      [routerLink]="editRouterLink"
      [title]="'checkout.update.label' | translate : { '0': 'checkout.widget.buyer.heading' | translate }"
    >
      <fa-icon [icon]="['fas', 'pencil-alt']" />
    </a>
    <strong>{{ 'checkout.widget.buyer.orderReferenceId' | translate }}</strong>
    <p>{{ object.externalOrderReference }}</p>
  </div>
</div>
