import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { CamCardsFacade } from 'camfil-core/facades/camfil-cam-cards.facade';
import { CamfilConfigurationFacade } from 'camfil-core/facades/camfil-configuration.facade';
import { IntervalDataType } from 'camfil-models/camfil-cam-card/cam-card.interface';
import { CamCard, CamCardItem } from 'camfil-models/camfil-cam-card/cam-card.model';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

import { CamCardEditModeComponent } from '../cam-card-edit-mode/cam-card-edit-mode.component';

@Component({
  selector: 'camfil-cam-card-delivery-interval',
  templateUrl: './cam-card-delivery-interval.component.html',
  styleUrls: ['./cam-card-delivery-interval.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CamCardDeliveryIntervalComponent extends CamCardEditModeComponent implements OnInit, OnChanges {
  private static deliveryIntervalOptions = 100;

  @Input() intervaltype: IntervalDataType = 'subCamCard';
  @Input() camCard: CamCard;
  @Input() mainDeliveryInterval: number;
  @Input() camCardItem: CamCardItem;
  deliveryIntervalFilteredOptions$: Observable<string[]>;
  deliveryIntervalForm: FormGroup;
  deliveryIntervalOptions: string[] = [...Array(CamCardDeliveryIntervalComponent.deliveryIntervalOptions).keys()].map(
    i => (i === 0 ? '--' : i.toString())
  );
  isVisible$: Observable<boolean>;
  deliveryFormControl: FormControl;
  constructor(
    private fb: FormBuilder,
    private camCardsFacade: CamCardsFacade,
    private camfilConfigurationFacade: CamfilConfigurationFacade
  ) {
    super(camCardsFacade);
  }

  ngOnInit(): void {
    this.initDeliveryIntervalForm();
    this.deliveryFormControl = this.deliveryIntervalForm.get('deliveryInterval') as FormControl;
    this.deliveryIntervalFilteredOptions$ = this.deliveryIntervalForm.get('deliveryInterval').valueChanges.pipe(
      startWith(''),
      map(value => this._deliveryIntervalFilter(value))
    );

    this.isVisible$ = this.camfilConfigurationFacade?.isEnabled$('showDeliveryIntervalOnCCDetailPage');
  }

  ngOnChanges(): void {
    if (this.camCard && this.deliveryIntervalForm) {
      this.patchDeliveryIntervalForm();
    }
  }

  initDeliveryIntervalForm() {
    this.deliveryIntervalForm = this.fb.group({
      lastDelivery: [''],
      deliveryInterval: [''],
      nextDelivery: [{ value: '', disabled: true }],
    });

    this.patchDeliveryIntervalForm();
  }

  // eslint-disable-next-line complexity
  patchDeliveryIntervalForm() {
    if (this.intervaltype === 'subCamCard' && this.camCard) {
      const { lastDeliveryDate, deliveryInterval, nextDeliveryDate } = this.camCard;

      const interalValue = this.creatIntervalValue(deliveryInterval);

      this.deliveryIntervalForm.patchValue({
        lastDelivery: lastDeliveryDate ? new Date(lastDeliveryDate) : '',
        deliveryInterval: interalValue,
        nextDelivery: nextDeliveryDate ? new Date(nextDeliveryDate) : '',
      });
    } else if (this.intervaltype === 'articleRow' && this.camCardItem) {
      const { lastDeliveryDate, deliveryInterval } = this.camCardItem;

      const interalValue = deliveryInterval
        ? deliveryInterval
        : this.mainDeliveryInterval
        ? this.mainDeliveryInterval
        : '';
      this.deliveryIntervalForm.patchValue({
        lastDelivery: lastDeliveryDate ? new Date(lastDeliveryDate) : '',
        deliveryInterval: interalValue,
      });
    }
  }

  creatIntervalValue(deliveryInterval: number): string | number {
    return deliveryInterval ? deliveryInterval : this.mainDeliveryInterval ? this.mainDeliveryInterval : '';
  }

  applyDeliveryInterval() {
    if (this.deliveryIntervalForm.get('deliveryInterval').value === '--') {
      this.deliveryIntervalForm.get('deliveryInterval').setValue(undefined);
    }
    this.pickInterval();
    this.onBlurSubmit();
  }

  pickInterval() {
    const date = new Date(this.deliveryIntervalForm.get('lastDelivery').value);
    date.setMonth(
      date.getMonth() +
        (isNaN(this.deliveryIntervalForm.get('deliveryInterval').value)
          ? 0
          : +this.deliveryIntervalForm.get('deliveryInterval').value)
    );
    this.deliveryIntervalForm.patchValue({
      nextDelivery: date,
    });
  }

  validateDeliveryInterval() {
    const maxDeliveryInterval = CamCardDeliveryIntervalComponent.deliveryIntervalOptions - 1;
    if (this.deliveryIntervalForm.get('deliveryInterval').value === '--') {
      this.deliveryIntervalForm.get('deliveryInterval').setValue(undefined);
      return;
    }
    if (this.deliveryIntervalForm.get('deliveryInterval').value > maxDeliveryInterval) {
      this.deliveryIntervalForm.get('deliveryInterval').setValue(`${maxDeliveryInterval}`);
      return;
    }
    if (this.deliveryIntervalForm.get('deliveryInterval').value <= 0) {
      this.deliveryIntervalForm.get('deliveryInterval').setValue(undefined);
      return;
    }
  }

  private _deliveryIntervalFilter(value: string): string[] {
    if (!value) {
      return this.deliveryIntervalOptions;
    }
    return this.deliveryIntervalOptions.filter(option => option.toLowerCase().startsWith(value.toString()));
  }

  onBlurSubmit() {
    if (this.intervaltype === 'subCamCard') {
      this.camCardsFacade.updateSubCamCard({
        ...this.camCard,
        deliveryInterval: isNaN(this.deliveryIntervalForm.get('deliveryInterval').value)
          ? undefined
          : this.deliveryIntervalForm.get('deliveryInterval').value,
      });
    } else {
      const newItem = {
        ...this.camCardItem,
        deliveryInterval: isNaN(this.deliveryIntervalForm.get('deliveryInterval').value)
          ? undefined
          : this.deliveryIntervalForm.get('deliveryInterval').value,
      };
      this.camCardsFacade.updateCamCardProduct(this.camCard.rootCamCard, this.camCard.id, newItem);
    }
  }
}
