import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  inject,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { CamfilMeasurementFilterFacade, MeasurementsModel } from 'camfil-core/facades/camfil-measurement-filter.facade';
import { CamfilFormClass } from 'camfil-shared/formly/utils/camfil-form';
import { isEqual } from 'lodash-es';
import { debounceTime, distinctUntilChanged } from 'rxjs';
import { filter, take } from 'rxjs/operators';

import { ShoppingFacade } from 'ish-core/facades/shopping.facade';
import { whenTruthy } from 'ish-core/utils/operators';
import { URLFormParams } from 'ish-core/utils/url-form-params';
import { markAsDirtyRecursive } from 'ish-shared/forms/utils/form-utils';

@Component({
  selector: 'camfil-filter-measurements',
  templateUrl: './camfil-filter-measurements.component.html',
  styleUrls: ['./camfil-filter-measurements.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CamfilFilterMeasurementsComponent implements OnInit {
  constructor(private shoppingFacade: ShoppingFacade, private measurementFilterFacade: CamfilMeasurementFilterFacade) {}

  private destroyRef = inject(DestroyRef);

  @Input() fragmentOnRouting: string;
  @Input() showCategoryFilter = true;
  @Output() applyFilter: EventEmitter<{ searchParameter: URLFormParams }> = new EventEmitter();

  categoryParam: string;
  measurementsForm: CamfilFormClass<MeasurementsModel>;
  submitted = false;
  tolerance2D = this.measurementFilterFacade.tolerance2D;
  tolerance3D = this.measurementFilterFacade.tolerance3D;
  currentFilterParams$ = this.measurementFilterFacade.currentFilterParams$;
  measurementModes$ = this.measurementFilterFacade.measurementModes$;
  currentMeasurementMode$ = this.measurementFilterFacade.currentMeasurementMode$;
  applyMeasurementMode = this.measurementFilterFacade.applyMeasurementMode.bind(this.measurementFilterFacade);

  ngOnInit(): void {
    this.shoppingFacade.selectedCategory$?.pipe(whenTruthy(), takeUntilDestroyed(this.destroyRef)).subscribe(value => {
      this.categoryParam = value?.uniqueId?.replace(/\./g, '/');
    });

    this.measurementsForm = this.createMeasurementsForm();
    this.measurementsForm
      .valueChanges$()
      .pipe(
        debounceTime(800),
        distinctUntilChanged(isEqual),
        filter(formModel => Object.values(formModel).some(value => value !== undefined)),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(() => {
        this.submitForm();
      });
  }

  get formDisabled() {
    return this.measurementsForm.instance.invalid && this.submitted;
  }

  get formValue(): MeasurementsModel {
    return this.measurementsForm.instance.getRawValue();
  }

  submitForm() {
    if (this.measurementsForm.instance.invalid) {
      this.submitted = true;
      markAsDirtyRecursive(this.measurementsForm.instance);
      return;
    }

    this.measurementFilterFacade
      .getMeasurementQueryParams$(this.formValue)
      .pipe(take(1), takeUntilDestroyed(this.destroyRef))
      .subscribe(searchParameter => {
        this.applyFilter.emit({ searchParameter });
      });
  }

  private createMeasurementsForm() {
    const form = new CamfilFormClass();

    form.setModel$(this.measurementFilterFacade.getMeasurementsFormModel$());
    form.setFields$(this.measurementFilterFacade.getMeasurementsFormFields$());

    return form;
  }
}
