import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'join', pure: true })
export class CamfilJoinPipe implements PipeTransform {
  transform(array: unknown[], separator = ', ', withAmpresand = true): string {
    if (!Array.isArray(array)) {
      return '';
    }

    const output = array.map(v => `${v}`).join(separator);

    if (withAmpresand) {
      const escapedSeparator = separator.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
      const regex = new RegExp(`${escapedSeparator}([^${escapedSeparator}]*)$`);
      return output.replace(regex, ` & $1`);
    }

    return output;
  }
}
