import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { loadCamfilCustomers, loadContactsByCustomer } from 'camfil-core/store/camfil-customer/camfil-customer.actions';
import {
  getCamfilCustomer,
  getCamfilCustomers,
  getContactsByCustomerId,
  getDeliveryAddressesByCustomerId,
  getUserContactByCustomerNo,
} from 'camfil-core/store/camfil-customer/camfil-customer.selectors';
import { CamfilAddress } from 'camfil-models/camfil-address/camfil-address.model';
import { CamfilContact, CamfilCustomer } from 'camfil-models/camfil-customer/camfil-customer.model';
import { Observable } from 'rxjs';
import { filter, map, switchMap, take } from 'rxjs/operators';

import { AccountFacade } from 'ish-core/facades/account.facade';
import { HttpError } from 'ish-core/models/http-error/http-error.model';
import { whenTruthy } from 'ish-core/utils/operators';

@Injectable({ providedIn: 'root' })
export class CamfilCustomersFacade {
  constructor(private store: Store, private accountFacade: AccountFacade) {}

  customers$: Observable<CamfilCustomer[]> = this.store.pipe(
    select(getCamfilCustomers),
    filter(customers => !!customers?.length)
  );

  currentCustomer$ = this.accountFacade.customer$.pipe(
    switchMap(currentCustomer =>
      this.customers$.pipe(
        map(customers => customers.find(customer => customer.customerNo === currentCustomer.customerNo))
      )
    ),
    whenTruthy(),
    take(1)
  );

  loadContactsByCustomer(customerId: string): void | HttpError {
    this.store.dispatch(loadContactsByCustomer({ customerId }));
  }

  getContactsByCustomer$(customerId: string): Observable<CamfilContact[]> {
    return this.store.pipe(select(getContactsByCustomerId(customerId)));
  }

  getCamfilCustomerById$(customerId: string): Observable<CamfilCustomer> {
    return this.store.pipe(select(getCamfilCustomer(customerId)));
  }

  getUserContactForCustomerNo$(customerNo: string): Observable<CamfilContact> {
    return this.store.pipe(select(getUserContactByCustomerNo(customerNo)));
  }

  getDeliveryAddressesForCustomer$(customerId: string): Observable<CamfilAddress[]> {
    return this.store.pipe(select(getDeliveryAddressesByCustomerId(customerId)));
  }

  loadCustomers() {
    this.store.dispatch(loadCamfilCustomers());
  }
}
