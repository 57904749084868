import { createSelector } from '@ngrx/store';
import { getCamfilFeatureState } from 'camfil-core/store/camfil-store';
import { Unit } from 'camfil-models/camfil-ahu/unit/unit.model';

import { AhuUnitState, ahuUnitFeatureKey, unitAdapter } from './unit.reducer';

const getAhuUnitState = getCamfilFeatureState<AhuUnitState>(ahuUnitFeatureKey);

const { selectAll, selectEntities } = unitAdapter.getSelectors(getAhuUnitState);

export const getAllAhuUnits = selectAll;

export const getAhuUnitsLoading = createSelector(getAhuUnitState, state => state.loading);

export const getAhuUnitsError = createSelector(getAhuUnitState, state => state.error);

export const getSelectedAhuUnitId = createSelector(getAhuUnitState, state => state.selected);

export const getSelectedAhuUnit = createSelector(
  selectEntities,
  getSelectedAhuUnitId,
  (entities, id): Unit => id && entities[id]
);
