import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'camfil-logo',
  templateUrl: './camfil-logo.component.html',
  styleUrls: ['./camfil-logo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CamfilLogoComponent {
  @Input() logoVersion: 'full' | 'mobile' = 'mobile';
}
