import {
  AfterViewInit,
  DestroyRef,
  Directive,
  ElementRef,
  HostListener,
  Input,
  OnInit,
  Renderer2,
  inject,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { TranslateService } from '@ngx-translate/core';
import { CamfilConfigurationFacade } from 'camfil-core/facades/camfil-configuration.facade';
import { CamfilMaxLengthKey } from 'camfil-models/camfil-configuration/camfil-configuration.model';

@Directive({
  selector: '[camfilMaxLength]',
})
export class CamfilMaxLengthDirective implements OnInit, AfterViewInit {
  private maxLength: number;
  private errorElement: HTMLElement;
  private fieldName: CamfilMaxLengthKey;
  private destroyRef = inject(DestroyRef);

  constructor(
    private element: ElementRef,
    private translateService: TranslateService,
    private renderer2: Renderer2,
    private camfilConfigurationFacade: CamfilConfigurationFacade
  ) {}

  @Input() set camfilMaxLength(fieldName: CamfilMaxLengthKey) {
    this.fieldName = fieldName;
  }

  @HostListener('input', ['$event']) onKeyDown(event: KeyboardEvent) {
    if (event.target instanceof HTMLInputElement || event.target instanceof HTMLTextAreaElement) {
      const input = event.target;
      this.checkIfMaxLengthReached(input);
    }
  }

  @HostListener('focusin', ['$event']) onFocus(event: KeyboardEvent) {
    if (event.target instanceof HTMLInputElement || event.target instanceof HTMLTextAreaElement) {
      const input = event.target;
      this.checkIfMaxLengthReached(input);
    }
  }

  @HostListener('focusout', ['$event']) onBlur() {
    this.toggleMaxLengthWarning(false);
  }

  ngOnInit() {
    this.camfilConfigurationFacade
      .maxLength$(this.fieldName)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((value: number) => {
        this.maxLength = value;

        this.element.nativeElement.setAttribute('maxLength', value);
      });
  }

  ngAfterViewInit() {
    const formField = this.element.nativeElement.closest('.mat-form-field, .mat-mdc-form-field');
    this.errorElement = this.renderer2.createElement('mat-error');
    this.errorElement.classList.add('mat-error');
    this.errorElement.style.display = 'none';
    this.errorElement.style.color = 'var($camfil-color-special-primary)';
    this.errorElement.textContent = `${this.translateService.instant(`camfil.form.error.maxLength`, {
      0: this.maxLength,
    })}`;
    this.renderer2.appendChild(formField, this.errorElement);
  }

  private toggleMaxLengthWarning(showMessage = false) {
    this.errorElement.style.display = showMessage ? 'block' : 'none';
  }

  private checkIfMaxLengthReached(input: HTMLInputElement | HTMLTextAreaElement) {
    this.toggleMaxLengthWarning(input?.value.length >= this.maxLength);
  }
}
