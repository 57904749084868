import { CamfilQuoteSurchargeItemMapper } from 'camfil-models/camfil-quotes/camfil-quote-surcharge-item/camfil-quote-surcharge-item.mapper';

import { AddressData } from 'ish-core/models/address/address.interface';
import { AddressMapper } from 'ish-core/models/address/address.mapper';
import { PriceItem } from 'ish-core/models/price-item/price-item.model';
import { PriceData } from 'ish-core/models/price/price.interface';
import { PriceMapper } from 'ish-core/models/price/price.mapper';

import { CamfilQuoteData } from './camfil-quote.interface';
import { CamfilQuote, CamfilQuoteStatus, CamfilQuoteType } from './camfil-quote.model';

export class CamfilQuoteMapper {
  static fromData(data: CamfilQuoteData): CamfilQuote {
    if (data) {
      const items = data?.items.map(item => {
        let singlePrice: typeof item.singlePrice;

        if (!item.singlePrice && item.totalPrice && item.quantity) {
          singlePrice = {
            ...item.totalPrice,
            value: item.totalPrice.value / item.quantity.value,
          };
        }
        return {
          ...item,
          originSinglePrice: PriceMapper.fromData(item.originSinglePrice as PriceData),
          originTotalPrice: PriceMapper.fromData(item.originTotalPrice as PriceData),
          singlePrice: PriceMapper.fromData(singlePrice as PriceData),
          totalPrice: PriceMapper.fromData(item.totalPrice as PriceData),
          totalTax: PriceMapper.fromData(item.totalTax as PriceData),
        };
      });

      const surchargeItems = data?.surchargeItems?.length
        ? CamfilQuoteSurchargeItemMapper.fromListData(data.surchargeItems)
        : undefined;

      return {
        ...data,
        items,
        surchargeItems,
        ERPnumber: data?.erpnumber,
        customerNo: data?.customerId,
        customerQuoteNumber: data?.number,
        deliveryAddress: AddressMapper.fromData(data?.deliveryAddress as AddressData),
        orderMark: data?.quotationReference,
        originTotal: data?.originTotal as PriceItem,
        quotationDate: data?.submittedDate,
        quotationType: data?.quotationType?.toLowerCase() as CamfilQuoteType,
        requestedDate: data?.creationDate,
        status: data?.status as CamfilQuoteStatus,
        taxAmount: data?.taxAmount as PriceItem,
        total: data?.total as PriceItem,
        totalPriceAfterDiscountExVAT: data?.totalPriceAfterDiscountExVAT as PriceItem,
      };
    } else {
      throw new Error(`QuoteDetails data is required`);
    }
  }
}
