<div class="d-flex flex-column align-items-center justify-content-center p-4" [style.maxWidth.px]="500">
  <h2 camfilDialogTitle>
    <camfil-content-by-role>
      <div class="role-default">{{ 'camfil.checkout.order.bulk_delivery_date.confirm.modal.heading' | translate }}</div>
      <div class="role-quote">
        {{ 'camfil.checkout.order.bulk_delivery_date.confirm.modal.heading_quote' | translate }}
      </div>
      <div class="role-requisition">
        {{ 'camfil.checkout.order.bulk_delivery_date.confirm.modal.heading_requisition' | translate }}
      </div>
    </camfil-content-by-role>
  </h2>
</div>

<div camfilDialogContent separated>
  <ng-container *ngIf="joint.length">
    {{ 'camfil.checkout.order.bulk_delivery_date.confirm.modal.joint_list' | translate }}
    <ng-container *ngFor="let bucket of joint">
      <div>
        {{ 'camfil.dynamic.checkout.order' | translate }}
        {{ bucket.position }} - {{ bucket.orderMark }}
      </div>
    </ng-container>
    <hr *ngIf="partial.length" />
  </ng-container>

  <ng-container *ngIf="partial.length">
    {{ 'camfil.checkout.order.bulk_delivery_date.confirm.modal.partial_list' | translate }}
    <ng-container *ngFor="let bucket of partial">
      <div>
        {{ 'camfil.dynamic.checkout.order' | translate }}
        {{ bucket.position }} - {{ bucket.orderMark }}
      </div>
    </ng-container>
  </ng-container>
</div>

<div camfilDialogActions>
  <button mat-raised-button color="primary" type="button" class="create-order__button" (click)="matDialogRef.close()">
    OK
  </button>
</div>
