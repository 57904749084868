import { Injectable } from '@angular/core';
import { FormlyFieldConfig } from '@ngx-formly/core';

import { FieldLibraryConfiguration } from 'ish-shared/formly/field-library/configurations/field-library-configuration';

@Injectable()
export class MeasurementWidthConfiguration extends FieldLibraryConfiguration {
  id = 'measurementWidthFLC';

  getFieldConfig(): FormlyFieldConfig {
    return {
      key: 'width',
      type: 'camfil-text-input-field',
      props: {
        label: 'camfil.modal.addToCamcard.width.label',
        required: false,
        type: 'number',
      },
    };
  }
}
