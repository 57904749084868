import { createAction } from '@ngrx/store';
import { CamfilUserData } from 'camfil-models/camfil-user/camfil-user.interface';
import { CamfilUser, CamfilUsernameReminder } from 'camfil-models/camfil-user/camfil-user.model';

import { httpError, payload } from 'ish-core/utils/ngrx-creators';

export const resetUsernameReminder = createAction('[Camfil User] Reset Username Reminder Data');

export const requestUsernameReminder = createAction(
  '[Camfil User] Request Username Reminder',
  payload<{ data: CamfilUsernameReminder }>()
);

export const requestUsernameReminderSuccess = createAction('[Camfil User API]  Request Username Reminder Success');

export const requestUsernameReminderFail = createAction(
  '[Camfil User API] Request Username Reminder Fail',
  httpError()
);

export const applyForAnAccount = createAction(
  '[Camfil Account API] Apply For An Account',
  payload<{ user: CamfilUser }>()
);

export const applyForAnAccountSuccess = createAction(
  '[Camfil Account API] Apply For An Account Success',
  payload<{ user: CamfilUserData }>()
);

export const applyForAnAccountFail = createAction('[Camfil Account API] Apply For An Account Failed', httpError());
