<input
  *ngIf="type !== 'number'; else numberTmp"
  matInput
  [id]="id"
  [name]="field.name"
  [type]="type || 'text'"
  [readonly]="props.readonly"
  [required]="required"
  [errorStateMatcher]="errorStateMatcher"
  [formControl]="formControl"
  [formlyAttributes]="field"
  [tabIndex]="props.tabindex"
  [placeholder]="props.placeholder"
/>

<ng-template #numberTmp>
  <input
    matInput
    [id]="id"
    [name]="field.name"
    type="number"
    [readonly]="props.readonly"
    [required]="required"
    [errorStateMatcher]="errorStateMatcher"
    [formControl]="formControl"
    [formlyAttributes]="field"
    [tabIndex]="props.tabindex"
    [placeholder]="props.placeholder"
  />
</ng-template>

<ng-template #prefix>
  <ng-container matPrefix>
    <mat-icon [svgIcon]="props.showSpinner ? 'spinner' : 'search'" />
  </ng-container>
</ng-template>

<ng-template #suffix>
  <ng-container matSuffix>
    <div class="buttons d-flex align-items-center">
      <button
        mat-icon-button
        type="button"
        [style.visibility]="formControl.value ? 'visible' : 'hidden'"
        [title]="field.props.clearLabel"
        (click)="clearValue()"
      >
        <mat-icon>close</mat-icon>
      </button>
      <button mat-flat-button [color]="field.props.color" [disabled]="to.disabled" class="align-text-bottom mr-2">
        {{ field.props.button }}
      </button>
    </div>
  </ng-container>
</ng-template>
