<ng-template #searchFormTemplate>
  <form
    #formElement
    [formGroup]="searchForm.instance"
    (ngSubmit)="submitForm()"
    [style.max-width]="isMobile ? 'none' : searchFormWidth + 'px'"
    class="w-100"
  >
    <formly-form
      [form]="searchForm.instance"
      [model]="searchForm.model$ | async"
      [fields]="searchForm.fields$ | async"
    />
  </form>
</ng-template>

<ng-template #productsResultsTemplate>
  <mat-card *ngIf="productsCount$ | async as productsCount" class="w-100 mt-2">
    <mat-card-header class="px-4">
      <mat-card-title>
        {{ 'camfil.listing.only.list.products.title' | translate }}
      </mat-card-title>
      <mat-card-subtitle>
        {{
          'camfil.listing.only.list.products.showing'
            | translate
              : {
                  '0': maxVisibleProducts && productsCount > maxVisibleProducts ? maxVisibleProducts : productsCount,
                  '1': productsCount
                }
        }}
      </mat-card-subtitle>
    </mat-card-header>
    <mat-card-content class="px-0 pt-2">
      <camfil-product-listing
        *ngIf="searchTerm$ | async as searchTerm"
        [id]="productListingId$ | async"
        [limitItemsOnList]="maxVisibleProducts"
        [onlyList]="true"
      />
    </mat-card-content>
  </mat-card>
</ng-template>

<ng-template #categoriesResultsTemplate>
  <ng-container *ngIf="categoriesResults$ | async | slice : 0 : maxVisibleCategories as categories">
    <mat-card *ngIf="categories?.length" class="w-100 mt-2">
      <mat-card-header class="px-4">
        <mat-card-title>
          {{ 'camfil.search.searchbox.categories.title' | translate }}
        </mat-card-title>
      </mat-card-header>
      <mat-card-content class="px-4 pb-2 pt-2">
        <mat-grid-list [cols]="gridForCategories" rowHeight="75px" gutterSize="8px">
          <mat-grid-tile *ngFor="let category of categories" [colspan]="1" [rowspan]="1">
            <camfil-category-box class="w-100 mx-1" size="small" [category]="category" />
          </mat-grid-tile>
        </mat-grid-list>
      </mat-card-content>
    </mat-card>
  </ng-container>
</ng-template>

<ng-template #showAllResultsTemplate>
  <ng-container *ngIf="hasProducts$ | async">
    <button mat-raised-button class="mt-2" color="primary" (click)="submitForm()">
      {{ 'camfil.search.searchbox.show.all.results' | translate }}
    </button>
  </ng-container>
</ng-template>

<ng-template #overlayTemplate>
  <div
    class="search-form-container d-flex flex-column flex-nowrap align-items-center justify-content-start w-100 gap-4"
    tabindex="0"
  >
    <!-- search form -->
    <ng-container *ngTemplateOutlet="searchFormTemplate" />
    <!-- products results -->
    <ng-container *ngTemplateOutlet="productsResultsTemplate" />
    <!-- categories results -->
    <ng-container *ngTemplateOutlet="categoriesResultsTemplate" />
    <!-- show all results -->
    <ng-container *ngTemplateOutlet="showAllResultsTemplate" />
  </div>
</ng-template>

<div #searchBoxElement class="searchBoxElement">
  <ng-container *ngTemplateOutlet="searchFormTemplate" />
</div>
