import { createSelector } from '@ngrx/store';

import { getFailedProducts } from 'ish-core/store/shopping/products';
import { getShoppingState } from 'ish-core/store/shopping/shopping-store';

const getProductsIds = createSelector(getShoppingState, state => state.products.ids as string[]);

export const getAllProductsIds = createSelector(getProductsIds, getFailedProducts, (products, failed) => [
  ...products,
  ...failed,
]);
