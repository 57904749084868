<ng-container [formGroup]="control">
  <h3 class="my-3">{{ name }}</h3>
  <div class="alert alert-danger mb-4" *ngIf="control.errors?.area">
    {{ 'camfil.modal.filter_area.error.text' | translate }}
  </div>
  <div *ngIf="requiresMeasurement$ | async" class="measurements">
    <mat-form-field appearance="outline">
      <mat-label>{{ 'camfil.modal.addToCamcard.width.label' | translate }}</mat-label>
      <input formControlName="width" matInput type="number" />
      <mat-error *ngIf="control.controls.width.errors?.required || control.controls.width.errors?.min">
        {{ 'camfil.modal.measurement.error.global' | translate }}
      </mat-error>
      <mat-error *ngIf="control.controls.width.errors?.max">
        {{ 'camfil.modal.measurement.error.max' | translate : { '0': control.controls.width.errors.max.max } }}
      </mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>{{ 'camfil.modal.addToCamcard.height.label' | translate }}</mat-label>
      <input formControlName="height" matInput type="number" />
      <mat-error *ngIf="control.controls.height.errors?.required || control.controls.height.errors?.min">
        {{ 'camfil.modal.measurement.error.global' | translate }}
      </mat-error>
      <mat-error *ngIf="control.controls.height.errors?.max">
        {{ 'camfil.modal.measurement.error.max' | translate : { '0': control.controls.height.errors.max.max } }}
      </mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline" *ngIf="showDepthField$ | async">
      <mat-label>{{ 'camfil.modal.addToCamcard.depth.label' | translate }}</mat-label>
      <input formControlName="depth" matInput type="number" />
    </mat-form-field>
  </div>
  <camfil-product-counter class="quantity" [amount]="control.controls.quantity" />
  <mat-form-field class="w-100" appearance="outline">
    <mat-label>{{ 'camfil.modal.addToCamcard.box.label' | translate }}</mat-label>
    <input class="label-input" matInput formControlName="boxLabel" type="text" />
    <mat-error *ngIf="control.controls.boxLabel.errors?.maxlength">{{
      'camfil.form.error.maxLength' | translate : { '0': boxLabelMaxLength }
    }}</mat-error>
  </mat-form-field>
</ng-container>
