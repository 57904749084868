import { Injectable } from '@angular/core';
import { FormlyFieldConfig } from '@ngx-formly/core';

import { FieldLibraryConfiguration } from 'ish-shared/formly/field-library/configurations/field-library-configuration';

@Injectable()
export class DeliveryAddressPostalCodeConfiguration extends FieldLibraryConfiguration {
  id = 'deliveryAddressPostalCodeFLC';

  getFieldConfig(): FormlyFieldConfig {
    return {
      key: 'deliveryAddress.postalCode',
      type: 'camfil-postal-code-input-field',
      props: {
        label: 'camfil.account.cam_card.add_to_form.postal_code',
        required: true,
      },
      validation: {
        messages: {
          required: 'camfil.account.apply_form.zipCode.error.required',
          zipCodePattern: 'camfil.account.apply_form.zipCode.error.pattern',
          incorrectZipCode: 'camfil.account.apply_form.zipCode.error.invalid',
        },
      },
    };
  }
}
