/* eslint-disable ish-custom-rules/ban-imports-file-pattern */
import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import {
  getCamfilConfigurationParameter,
  selectExceptionWindows,
  selectIcmChannel,
  selectMaintenanceWindows,
  selectTimeZone,
} from 'camfil-core/store/camfil-configuration/camfil-configuration.selectors';
import { isWithinWindows } from 'camfil-core/utils/functions';
import { CamfilConfigurationHelper } from 'camfil-models/camfil-configuration/camfil-configuration.helper';
import { CamfilChannelSetting } from 'camfil-models/camfil-configuration/camfil-configuration.model';
import { Observable, combineLatest, map, of, switchMap } from 'rxjs';
import { defaultIfEmpty } from 'rxjs/operators';

import { getICMBaseURL } from 'ish-core/store/core/configuration';
import { getGeneralError } from 'ish-core/store/core/error';
import { whenTruthy } from 'ish-core/utils/operators';

import channelSettings from './settings';

@Injectable({ providedIn: 'root' })
export class CamfilConfigurationService {
  constructor(private store: Store) {}

  private readonly uatPattern = /^https:\/\/uat-api\.shop\.camfil\.com\/?/;
  private readonly intPattern = /^https:\/\/int-api\.shop\.camfil\.com\/?/;
  private readonly prdPattern = /^https:\/\/api\.shop\.camfil\.com\/?/;

  isUAT$ = this.store.pipe(
    select(getICMBaseURL),
    map(baseUrl => this.uatPattern.test(baseUrl))
  );

  isINT$ = this.store.pipe(
    select(getICMBaseURL),
    map(baseUrl => this.intPattern.test(baseUrl))
  );

  isPRD$ = this.store.pipe(
    select(getICMBaseURL),
    map(baseUrl => this.prdPattern.test(baseUrl))
  );

  isMaintenanceMode$ = combineLatest([
    this.store.pipe(
      select(getGeneralError),
      map(error => typeof error !== 'string' && error?.status === 503)
    ),
    this.store.pipe(select(selectTimeZone)),
    this.store.pipe(select(selectMaintenanceWindows)),
    this.store.pipe(select(selectExceptionWindows)),
  ]).pipe(
    map(([generalError, timeZone, maintenanceWindows, exceptionWindows]) => {
      if (generalError) {
        return true;
      }

      const currentDate = new Date();
      const inMaintenance = isWithinWindows(currentDate, maintenanceWindows, timeZone);

      if (inMaintenance && exceptionWindows) {
        const inException = isWithinWindows(currentDate, exceptionWindows, timeZone);
        return !inException;
      }

      return inMaintenance;
    })
  );

  private getCamfilConfigurationFromFile(channelName: string) {
    return of(channelSettings).pipe(
      map(settings => ({
        data: { ...CamfilConfigurationHelper.getSettingsByChannelName(settings, channelName) },
      }))
    );
  }

  getConfiguration() {
    return this.store.pipe(
      select(selectIcmChannel),
      whenTruthy(),
      switchMap(icmChannel => this.getCamfilConfigurationFromFile(icmChannel)),
      map(payload => payload.data)
    );
  }

  isEnabled$(setting: CamfilChannelSetting): Observable<boolean> {
    return this.store.pipe(
      select(getCamfilConfigurationParameter<boolean, CamfilChannelSetting>(setting)),
      map(value => (typeof value === 'boolean' ? value : false)),
      defaultIfEmpty(false)
    );
  }
}

export function fullBaseUrlFactory(baseHref: string, document: Document): string {
  const normalizedBaseHref = baseHref.startsWith('/') ? baseHref : `/${baseHref}`;

  if (normalizedBaseHref === '/') {
    if (!SSR) {
      return window.location.origin;
    } else {
      return document.location.origin || '';
    }
  }

  if (!SSR) {
    return `${window.location.origin}${normalizedBaseHref}`;
  } else {
    const origin = document.location.origin || '';
    return `${origin}${normalizedBaseHref}`;
  }
}
