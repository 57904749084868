import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { CamfilBasketService } from 'camfil-core/services/camfil-basket/camfil-basket.service';
import { CamfilBucket } from 'camfil-models/camfil-bucket/camfil-bucket.model';
import { filter, map, mergeMap } from 'rxjs';

import { displayErrorMessage, displaySuccessMessage } from 'ish-core/store/core/messages';
import { deleteBasketItem, getCurrentBasket, loadBasketSuccess } from 'ish-core/store/customer/basket';
import { mapErrorToAction, mapToPayload, mapToPayloadProperty } from 'ish-core/utils/operators';

import {
  addProductToBasket,
  doubleCamfilBucketItemsQuantity,
  loadBasketAddresses,
  startCamfilCheckout,
} from '../camfil-basket/camfil-basket.actions';
import {
  getBucketEditMode,
  getCamfilBasketAddresses,
  getSelectedCamfilBasket,
} from '../camfil-basket/camfil-basket.selectors';
import {
  getCurrentCamfilBuckets,
  getEmptyCamfilBuckets,
  selectCamfilEmailRecipients,
} from '../camfil-bucket/camfil-bucket.selectors';

import {
  addProductToBasketEditMode,
  applyChangesFromEditMode,
  copyBasket,
  copyBasketSuccess,
  deleteBasketItemInEditMode,
  doubleCamfilBucketItemsQuantityInEditMode,
  saveBuckets,
  saveBucketsFail,
  saveBucketsSuccess,
  saveOnlyEmptyBuckets,
} from './camfil-edit-basket.actions';
import { CamflEditBasketHelper } from './camfil-edit-basket.helper';

@Injectable()
export class CamfilEditBasketEffects {
  constructor(private actions$: Actions, private store: Store, private camfilBasketService: CamfilBasketService) {}

  editModeActions$ = this.actions$.pipe(
    concatLatestFrom(() => this.store.pipe(select(getBucketEditMode))),
    filter(([, edit]) => !!edit),
    map(([s]) => s)
  );

  copyBasket$ = createEffect(() =>
    this.actions$.pipe(
      ofType(copyBasket),
      mapToPayloadProperty('id'),
      concatLatestFrom(() => [
        this.store.pipe(select(getBucketEditMode)),
        this.store.pipe(select(getCurrentBasket)),
        this.store.pipe(select(getCurrentCamfilBuckets)),
        this.store.pipe(select(getCamfilBasketAddresses)),
      ]),
      filter(([, edit]) => !edit),
      map(([id, , basket, camfilBuckets, addresses]) => copyBasketSuccess({ id, basket, camfilBuckets, addresses }))
    )
  );

  applyChangesFromEditMode$ = createEffect(() =>
    this.actions$.pipe(
      ofType(applyChangesFromEditMode),
      concatLatestFrom(() => [
        this.store.pipe(select(getCurrentCamfilBuckets)),
        this.store.pipe(select(selectCamfilEmailRecipients)),
        this.store.pipe(select(getEmptyCamfilBuckets)),
        this.store.pipe(select(getSelectedCamfilBasket)),
      ]),
      map(([, bs, recipients, emptyBuckets, basket]) => {
        const camfilBuckets = bs
          ?.filter(b => b.lineItems.length)
          .map((b: CamfilBucket) => {
            const emailRecipients = recipients?.find(
              (r: { urn: string; emailRecipients: string[] }) => r.urn === b?.deliveryAddressId
            )?.emailRecipients;
            return CamflEditBasketHelper.updatedBucketFromEditMode(b, emailRecipients);
          });
        const emptyLineItems = emptyBuckets.some(b => b.lineItems?.length);
        return emptyBuckets?.length && !emptyLineItems && !camfilBuckets?.length
          ? saveOnlyEmptyBuckets({ basketId: basket.id, emptyBuckets })
          : saveBuckets({ buckets: camfilBuckets, basket });
      })
    )
  );

  saveBucketsAfterApplyChangesFromEditMode$ = createEffect(() =>
    this.editModeActions$.pipe(
      ofType(saveBuckets),
      mapToPayload(),
      mergeMap(({ buckets, basket }) =>
        this.camfilBasketService.updateBuckets(buckets, basket.id).pipe(
          map(basket => saveBucketsSuccess({ basket })),
          mapErrorToAction(saveBucketsFail)
        )
      )
    )
  );

  saveBucketsAfterApplyChangesFromEditModeSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(saveBucketsSuccess),
      mapToPayloadProperty('basket'),
      mergeMap(basket => [
        loadBasketAddresses(),
        loadBasketSuccess({ basket }),
        startCamfilCheckout(),
        displaySuccessMessage({
          message: `camfil.checkout.edit_mode.apply_changes.success.message`,
        }),
      ])
    )
  );

  saveBucketsAfterApplyChangesFromEditModeFail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(saveBucketsFail),
      mapToPayloadProperty('error'),
      map(() =>
        displayErrorMessage({
          message: 'camfil.checkout.edit_mode.apply_changes.error.message',
        })
      )
    )
  );

  addProductToBasketEditMode$ = createEffect(() =>
    this.editModeActions$.pipe(
      ofType(addProductToBasket),
      mapToPayload(),
      map(({ sku, quantity, lineItemAttributes, camfilBucketId }) =>
        addProductToBasketEditMode({
          sku,
          quantity,
          lineItemAttributes,
          camfilBucketId,
        })
      )
    )
  );

  deleteBasketItemInEditMode$ = createEffect(() =>
    this.editModeActions$.pipe(
      ofType(deleteBasketItem),
      mapToPayloadProperty('itemId'),
      concatLatestFrom(() => this.store.pipe(select(getBucketEditMode))),
      map(([itemId, bucketId]) => deleteBasketItemInEditMode({ itemId, bucketId }))
    )
  );

  doubleBucketItemsQuantityInEditMode$ = createEffect(() =>
    this.editModeActions$.pipe(
      ofType(doubleCamfilBucketItemsQuantity),
      mapToPayloadProperty('bucketId'),
      map(bucketId => doubleCamfilBucketItemsQuantityInEditMode({ bucketId }))
    )
  );
}
