<ng-container *ngIf="size === 'normal'; else small" />

<ng-template #small>
  <mat-card>
    <a [routerLink]="category | ishCategoryRoute" (click)="handleClick()" class="d-flex align-items-center">
      <figure class="d-flex justify-content-center align-items-center">
        <img *ngIf="category.images" [src]="category.images[0]?.effectiveUrl" [alt]="category.images[0]?.name || ''" />
        <mat-icon *ngIf="!category.images" svgIcon="logo" class="logo m-0" />
      </figure>
      <strong>
        {{ category.name }}<br />
        <small class="text-muted">{{ category.description | slice : 0 : 40 }}</small>
      </strong>
      <mat-icon svgIcon="chevron-right-fab" class="" />
    </a>
  </mat-card>
</ng-template>
