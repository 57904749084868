/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable ish-custom-rules/ban-imports-file-pattern */
import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { concatLatestFrom } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { CamfilConfigurationFacade } from 'camfil-core/facades/camfil-configuration.facade';
import { selectAhuManufacturer } from 'camfil-core/store/ahu/manufacturer/manufacturer.actions';
import {
  getAhuManufacturerError,
  getAhuManufacturerLoading,
  getAllAhuManufacturers,
  getSelectedAhuManufacturer,
  getSelectedAhuManufacturerId,
  isManufacturerInitialized,
} from 'camfil-core/store/ahu/manufacturer/manufacturer.selectors';
import {
  getAhuUnitFiltersError,
  getAhuUnitFiltersLoading,
  getAllAhuUnitFilters,
  getSelectedAhuUnitFilter,
  getSelectedAhuUnitFilterId,
} from 'camfil-core/store/ahu/unit-filter/unit-filter.selectors';
import {
  addAhuSlotItemToList,
  removeAhuSlotItemFromList,
  selectAhuUnit,
  sendAhuRequestComment,
} from 'camfil-core/store/ahu/unit/unit.actions';
import {
  getAhuUnitsError,
  getAhuUnitsLoading,
  getAllAhuUnits,
  getSelectedAhuUnit,
  getSelectedAhuUnitId,
} from 'camfil-core/store/ahu/unit/unit.selectors';
import { PropType } from 'camfil-core/utils/utility-types';
import { AhuHelper } from 'camfil-models/camfil-ahu/ahu.helper';
import { AhuProductItem, AhuSupportMessage } from 'camfil-models/camfil-ahu/ahu.model';
import { Manufacturer } from 'camfil-models/camfil-ahu/manufacturer/manufacturer.model';
import {
  UnitFilter,
  UnitFilterSlot,
  UnitFilterSlotItemParams,
  UnitFilterSlotParams,
  UnitFilterSlotType,
} from 'camfil-models/camfil-ahu/unit-filter/unit-filter.model';
import { UnitHelper } from 'camfil-models/camfil-ahu/unit/unit.helper';
import { Unit, UnitBasket, UnitBasketItem } from 'camfil-models/camfil-ahu/unit/unit.model';
import { ProductItem } from 'camfil-models/camfil-product/product.model';
import { flatten, isEqual, memoize, uniqBy } from 'lodash-es';
import { Observable, combineLatest, of } from 'rxjs';
import {
  concatMap,
  defaultIfEmpty,
  distinctUntilChanged,
  filter,
  map,
  skipWhile,
  switchMap,
  take,
} from 'rxjs/operators';

import { AppFacade } from 'ish-core/facades/app.facade';
import { ShoppingFacade } from 'ish-core/facades/shopping.facade';
import { HttpError } from 'ish-core/models/http-error/http-error.model';
import { PriceHelper, Pricing } from 'ish-core/models/price/price.model';
import { ProductView } from 'ish-core/models/product-view/product-view.model';
import { ProductCompletenessLevel } from 'ish-core/models/product/product.helper';
import { selectQueryParam } from 'ish-core/store/core/router';
import { getProduct, getProductEntities } from 'ish-core/store/shopping/products';
import { whenTruthy } from 'ish-core/utils/operators';

@Injectable({ providedIn: 'root' })
export class CamfilAhuFacade {
  constructor(
    private store: Store,
    private shoppingFacade: ShoppingFacade,
    private appFacade: AppFacade,
    private camfilConfigurationFacade: CamfilConfigurationFacade
  ) {}

  private createAhuUnitBasketProductItemsForm: (item: ProductItem, boxLabelMaxLength: number) => FormGroup = memoize(
    (item, boxLabelMaxLength) =>
      new FormGroup({
        quantity: new FormControl(item?.quantity || item?.product?.minOrderQuantity || 0),
        boxLabel: new FormControl('', Validators.maxLength(boxLabelMaxLength)),
      })
  );

  ahuSlotQueryParam$ = this.store.pipe(
    select(selectQueryParam(AhuHelper.SLOTS_QUERY_PARAM_NAME)),
    distinctUntilChanged()
  );

  /**
   * Manufacturers
   */
  selectAhuManufacturer(manufacturerId: string) {
    this.store.dispatch(selectAhuManufacturer({ manufacturerId }));
  }

  ahuManufacturers$: Observable<Manufacturer[]> = this.store.pipe(select(getAllAhuManufacturers));
  ahuManufacturersLoading$: Observable<boolean> = this.store.pipe(select(getAhuManufacturerLoading));
  ahuManufacturersError$: Observable<HttpError> = this.store.pipe(select(getAhuManufacturerError));
  ahuManufacturersInitialized$: Observable<boolean> = this.store.pipe(select(isManufacturerInitialized));
  selectedAhuManufacturer$: Observable<Manufacturer> = this.store.pipe(select(getSelectedAhuManufacturer));
  selectedAhuManufacturerId$: Observable<PropType<Manufacturer, 'id'>> = this.store.pipe(
    select(getSelectedAhuManufacturerId)
  );

  /**
   * Units
   */
  selectAhuUnit(unitId: number) {
    this.store.dispatch(selectAhuUnit({ unitId }));
  }

  ahuUnits$: Observable<Unit[]> = this.store.pipe(select(getAllAhuUnits));
  ahuUnitsLoading$: Observable<boolean> = this.store.pipe(select(getAhuUnitsLoading));
  ahuUnitsError$: Observable<HttpError> = this.store.pipe(select(getAhuUnitsError));
  ahuUnitsByManufacturerId$: Observable<Unit[]> = this.selectedAhuManufacturer$.pipe(
    whenTruthy(),
    switchMap(manufacturer =>
      this.ahuUnits$.pipe(
        map(units => units.filter(unit => unit?.manufacturerId === manufacturer?.id)),
        defaultIfEmpty([])
      )
    )
  );
  selectedAhuUnit$: Observable<Unit> = this.store.pipe(select(getSelectedAhuUnit));
  selectedAhuUnitId$: Observable<PropType<Unit, 'id'>> = this.store.pipe(select(getSelectedAhuUnitId));

  /**
   * Unit Filters
   */
  ahuUnitFilters$: Observable<UnitFilter[]> = this.store.pipe(select(getAllAhuUnitFilters));
  ahuUnitFiltersLoading$: Observable<boolean> = this.store.pipe(select(getAhuUnitFiltersLoading));
  ahuUnitFiltersError$: Observable<HttpError> = this.store.pipe(select(getAhuUnitFiltersError));
  ahuUnitFiltersByManufacturerId$: Observable<UnitFilter[]> = this.selectedAhuManufacturer$.pipe(
    whenTruthy(),
    switchMap(manufacturer =>
      this.ahuUnitFilters$.pipe(
        map(unitFilters => unitFilters.filter(unitFilter => unitFilter?.manufacturerId === manufacturer?.id)),
        defaultIfEmpty([])
      )
    )
  );

  selectedAhuUnitFilter$: Observable<UnitFilter> = this.store.pipe(select(getSelectedAhuUnitFilter));
  selectedAhuUnitFilterId$: Observable<PropType<UnitFilter, 'id'>> = this.store.pipe(
    select(getSelectedAhuUnitFilterId)
  );
  selectedAhuUnitFilterSlots$: Observable<UnitFilterSlot[]> = this.selectedAhuUnitFilter$.pipe(
    whenTruthy(),
    map(unitFilter => [...unitFilter.supplySlotFilters, ...unitFilter.exhaustSlotFilters]),
    map(unitSlotFilters =>
      uniqBy<UnitFilterSlot>(
        unitSlotFilters.map<UnitFilterSlot>(({ amount, slotId, slotName, type }, _i, arr) => ({
          amount,
          slotId,
          slotName,
          type,
          items: arr
            .filter(a => a.slotId === slotId)
            .map(({ itemName, itemNumber }) => ({
              itemName,
              itemNumber,
            })),
        })),
        unitFilterSlot => unitFilterSlot.slotId
      )
    )
  );
  selectedAhuUnitFilterSlotTypes$: Observable<UnitFilterSlotType[]> = this.selectedAhuUnitFilterSlots$.pipe(
    whenTruthy(),
    map(unitFilterSlots =>
      uniqBy(
        unitFilterSlots.reduce<UnitFilterSlotType[]>((acc, { type }, _i, arr) => {
          const dimensions = []
            .concat(arr)
            .filter(a => a.type === type)
            .map(a => a.slotName);
          const amount = dimensions?.length;
          const name = `camfil.dynamic.ahu.slot_type.${type}.name`;

          acc.push({
            type,
            dimensions,
            amount,
            name,
          });

          return acc;
        }, []),
        unitFilterSlotType => unitFilterSlotType.type
      )
    )
  );

  /**
   * Unit Basket
   */

  selectedAhuUnitBasket$ = combineLatest([this.ahuSlotQueryParam$, this.selectedAhuUnitFilterSlots$]).pipe(
    map(([slotsQueryParam, ahuSlots]) =>
      ahuSlots.map(ahuSlot => {
        // eslint-disable-next-line ish-custom-rules/no-object-literal-type-assertion
        const newAhuSlot = { ...ahuSlot } as unknown as UnitBasket;

        const getSlotItemParams = (item: UnitBasketItem): UnitFilterSlotItemParams => ({
          slotId: newAhuSlot.slotId,
          itemNumber: item.itemNumber,
        });

        newAhuSlot.items = newAhuSlot?.items?.map(item => {
          const newAhuSlotItem = { ...item };
          newAhuSlotItem.itemQuantity = UnitHelper.countAddedItemsBySku(slotsQueryParam, getSlotItemParams(item));
          return newAhuSlotItem;
        });

        newAhuSlot.items = newAhuSlot.items?.filter(item =>
          UnitHelper.isAhuUnitSlotItemAdded(slotsQueryParam, getSlotItemParams(item))
        );

        const addedItems = flatten(newAhuSlot.items.map(item => new Array(item.itemQuantity).fill(1)));

        newAhuSlot.remainingAmount = String(Number(newAhuSlot.amount) - addedItems?.length);

        return newAhuSlot;
      })
    )
  );

  selectedAhuUnitBasketItems$ = this.selectedAhuUnitBasket$.pipe(
    map(ahuUnitBasket => [].concat(...ahuUnitBasket.map(slot => slot?.items)) as UnitBasketItem[])
  );

  selectedAhuUnitBasketSkus$ = this.selectedAhuUnitBasketItems$.pipe(
    map(items => [...new Set<string>(flatten(items.map(item => new Array(item.itemQuantity).fill(item.itemNumber))))])
  );

  selectedAhuUnitBasketProductItems$: Observable<ProductItem[]> = this.ahuSlotQueryParam$.pipe(
    whenTruthy(),
    switchMap(() =>
      this.store.pipe(select(getProductEntities)).pipe(
        map(entities => Object.keys(entities)),
        concatLatestFrom(() => this.selectedAhuUnitBasketSkus$),
        skipWhile(([, selectedSkus]) => selectedSkus.length === 0),
        filter(([skus, selectedSkus]) => selectedSkus.every(elem => skus.includes(elem))),
        concatMap(([, selectedSkus]) => this.getProductsBySkus(selectedSkus)),
        distinctUntilChanged((x, y) => x.map(p => p.sku).join(',') === y.map(p => p.sku).join(',')),
        concatLatestFrom(() => this.selectedAhuUnitBasketItems$),
        map(
          ([products, items]) =>
            items
              .map(({ itemQuantity, itemNumber }) => ({
                quantity: itemQuantity,
                product: products.find(p => p.sku === itemNumber),
              }))
              .filter(item => !!item.product?.sku) as ProductItem[]
        )
      )
    )
  );

  selectedAhuUnitBasketAhuProductItems$: Observable<AhuProductItem[]> = this.selectedAhuUnitBasketProductItems$.pipe(
    concatLatestFrom(() => this.camfilConfigurationFacade.maxLength$('boxLabel')),
    map(([items, boxLabelMaxLength]) =>
      items.map(item => ({ ...item, form: this.createAhuUnitBasketProductItemsForm(item, boxLabelMaxLength) }))
    )
  );

  selectedAhuUnitBasketSummary$ = this.selectedAhuUnitBasketItems$.pipe(
    switchMap(items => {
      const skus = flatten<string>(items.map(item => new Array(item.itemQuantity).fill(item.itemNumber)));

      if (skus.length === 0) {
        return this.appFacade.currentCurrency$.pipe(
          map(currency => ({
            total: PriceHelper.getPrice(currency, 0),
          }))
        );
      }

      return this.getPricingsBySkus(skus).pipe(
        map(pricings => ({
          total: UnitHelper.totalPrice(pricings),
        }))
      );
    })
  );

  ahuValid$ = this.ahuSlotQueryParam$.pipe(
    map(slotsQueryParam => !!slotsQueryParam),
    switchMap(slotsQueryParam =>
      combineLatest([this.ahuSlotQueryParam$.pipe(take(1)), this.selectedAhuUnitFilterSlots$]).pipe(
        map(([queryParams, ahuUnitFilterSlots]) =>
          ahuUnitFilterSlots.map(({ slotId }) =>
            UnitHelper.isAhuUnitSlotValid(queryParams, { slotId }, ahuUnitFilterSlots)
          )
        ),
        map(bools => !!(bools.length && bools.every(b => b))),
        defaultIfEmpty(slotsQueryParam)
      )
    )
  );

  // Unit -> Slot

  isAhuUnitSlotValid$(ahuSlotParams: UnitFilterSlotParams): Observable<boolean> {
    return this.ahuSlotQueryParam$.pipe(
      concatLatestFrom(() => this.selectedAhuUnitFilterSlots$),
      map(([slotsQueryParam, ahuUnitFilterSlots]) =>
        UnitHelper.isAhuUnitSlotValid(slotsQueryParam, ahuSlotParams, ahuUnitFilterSlots)
      )
    );
  }

  // Unit -> Slot -> Item

  addAhuUnitSlotItemToList(ahuSlotItemParams: UnitFilterSlotItemParams & { quantity: number }) {
    this.store.dispatch(addAhuSlotItemToList(ahuSlotItemParams));
  }

  removeAhuUnitSlotItemFromList(ahuSlotItemParams: UnitFilterSlotItemParams) {
    this.store.dispatch(removeAhuSlotItemFromList(ahuSlotItemParams));
  }

  isAhuUnitSlotItemAdded$(ahuSlotItemParams: UnitFilterSlotItemParams): Observable<boolean> {
    return this.ahuSlotQueryParam$.pipe(
      map(slotsQueryParam => UnitHelper.isAhuUnitSlotItemAdded(slotsQueryParam, ahuSlotItemParams))
    );
  }

  getAhuUnitSlotItemQuantity$(ahuSlotItemParams: UnitFilterSlotItemParams): Observable<number> {
    return this.ahuSlotQueryParam$.pipe(
      map(slotsQueryParam => UnitHelper.countAddedItemsBySku(slotsQueryParam, ahuSlotItemParams))
    );
  }

  getAhuUnitSlotMaxQuantity$(ahuSlotParams: UnitFilterSlotParams): Observable<number> {
    return this.selectedAhuUnitBasket$.pipe(
      map(baskets => {
        if (baskets?.length) {
          return Number(baskets.find(basket => basket.slotId === ahuSlotParams.slotId).remainingAmount);
        }

        return 0;
      }),
      distinctUntilChanged(isEqual)
    );
  }

  getAhuUnitSlotItemProduct$(ahuSlotItemParams: UnitFilterSlotItemParams, level = ProductCompletenessLevel.Detail) {
    const { slotId, itemNumber } = ahuSlotItemParams;

    const ahuSlotParams: UnitFilterSlotParams = {
      slotId,
    };

    return this.shoppingFacade.product$(itemNumber, level).pipe(
      whenTruthy(),
      concatLatestFrom(() => this.getAhuUnitSlotMaxQuantity$(ahuSlotParams)),
      map(([product, remainingOrderQuantity]) => ({
        ...product,
        maxOrderQuantity: remainingOrderQuantity,
      }))
    ) as Observable<ProductView>;
  }

  isAhuUnitSlotItemProductAvailable$(props: { itemNumber: string }): Observable<boolean> {
    return this.store.pipe(
      select(getProduct(props.itemNumber)),
      map(product => (product ? Boolean(!product?.failed) : false))
    );
  }

  /**
   * Common
   */

  ahuLoading$ = combineLatest([this.ahuManufacturersLoading$, this.ahuUnitsLoading$, this.ahuUnitFiltersLoading$]).pipe(
    map(resources => resources.some(loading => loading))
  );

  ahuError$ = combineLatest([this.ahuManufacturersError$, this.ahuUnitsError$, this.ahuUnitFiltersError$]).pipe(
    map(resources => resources.some(loading => loading))
  );

  ahuCurrentUrl$ = combineLatest([this.selectedAhuManufacturerId$, this.selectedAhuUnitId$]).pipe(
    map(([manufacturerId, unitId]) => AhuHelper.generateAhuUrl(manufacturerId, unitId))
  );

  ahuUrl$ = of(AhuHelper.generateAhuUrl());

  /**
   * Send Feedback to CS
   * @param data
   */

  sendAhuRequestComment(data: AhuSupportMessage) {
    this.store.dispatch(sendAhuRequestComment({ data }));
  }

  private getProductsBySkus(skus: string[]): Observable<ProductView[]> {
    return combineLatest(skus.map(sku => this.shoppingFacade.product$(sku, ProductCompletenessLevel.Detail)));
  }

  private getPricingsBySkus(skus: string[], fresh = false): Observable<Pricing[]> {
    return combineLatest(skus.map(sku => this.shoppingFacade.productPrices$(sku, fresh)));
  }
}
