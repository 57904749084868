<camfil-product-image
  [product]="product$ | async"
  [imageType]="['images', 'M', 'S']"
  [width]="viewType === 'simple' ? 40 : 120"
  class="image"
/>

<a [routerLink]="productURL$ | async" [matTooltip]="productName$ | async" class="name">
  {{ productName$ | async }}
</a>

<ng-container *ngIf="viewType === 'detailed'">
  <div class="category" *ngIf="productCategoryName$ | async as productCategoryName">{{ productCategoryName }}</div>
</ng-container>

<div class="attributes">
  <dl class="attributes__item">
    <dt>{{ 'camfil.product.id.label' | translate }}</dt>
    <dd>{{ (product$ | async)?.sku }}</dd>
  </dl>
  <dl class="attributes__item" *ngIf="productAttributeDimensions$ | async as productAttributeDimensions">
    <dt>{{ 'camfil.product.dimensions.label' | translate }}</dt>
    <dd>
      {{ productAttributeDimensions }}
    </dd>
  </dl>
  <dl
    *ngIf="productAttributeIsoClassAndEfficiency$ | async as productAttributeIsoClassAndEfficiency"
    class="attributes__item"
  >
    <dt>{{ 'camfil.product.class_and_efficiency.label' | translate }}</dt>
    <dd>{{ productAttributeIsoClassAndEfficiency | ishAttribute }}</dd>
  </dl>
  <camfil-product-lead-time *ngIf="viewType === 'simple'" [showText]="false" class="ml-2" />

  <ng-container *ngIf="viewType === 'detailed'">
    <ng-container *ngIf="productAttributeEuroventEnergyClass$ | async as productAttributeEuroventEnergyClass">
      <dt>{{ 'camfil.product.energyClass.label' | translate }}</dt>
      <dd>{{ productAttributeEuroventEnergyClass | ishAttribute }}</dd>
    </ng-container>
    <ng-container *ngIf="productAttributeFrameProduct$ | async as productAttributeFrameProduct">
      <dt>{{ 'camfil.product.quickView.frame.label' | translate }}</dt>
      <dd>{{ productAttributeFrameProduct | ishAttribute }}</dd>
    </ng-container>
    <ng-container *ngIf="productAttributePressureDrop$ | async as productAttributePressureDrop">
      <dt>{{ 'camfil.product.pressureDrop.label' | translate }}</dt>
      <dd>{{ productAttributePressureDrop | ishAttribute }}</dd>
    </ng-container>
    <ng-container *ngIf="productAttributeFilterbags$ | async as productAttributeFilterbags">
      <dt>{{ 'camfil.product.quickView.bags.label' | translate }}</dt>
      <dd>{{ productAttributeFilterbags | ishAttribute }}</dd>
    </ng-container>
  </ng-container>
</div>

<button
  type="button"
  mat-stroked-button
  color="primary"
  class="quickview"
  (click)="showProductModal()"
  attr.aria-label="{{ 'camfil.product.quickview.text.label' | translate }}"
>
  <mat-icon svgIcon="search" class="quickview__icon" />
</button>

<camfil-product-lead-time *ngIf="viewType !== 'simple'" [showText]="viewType !== 'grid'" class="ml-2" />

<camfil-product-price class="price" />

<camfil-product-form
  *ngIf="viewType !== 'grid'"
  [density]="'compact'"
  class="form"
  [customActions]="customFormActions"
  [initialQuantity]="initialQuantity"
  [initialMinQuantity]="initialMinQuantity"
  [initialMaxQuantity]="initialMaxQuantity"
/>

<camfil-loading *ngIf="loading$ | async" [standalone]="true" />
