<ng-container *ngIf="filterNavigation">
  <ng-container *ngFor="let element of filters" [ngSwitch]="element.displayType">
    <camfil-filter-collapsable [title]="element.name" [(collapsed)]="collapsedElements[element.name]">
      <!-- default: text & text_clear -->
      <camfil-filter-text
        *ngSwitchDefault
        [filterElement]="element"
        (applyFilter)="applyFilter.emit($event)"
        [(showAll)]="showAllElements[element.name]"
      />
      <!-- checkbox -->
      <ish-filter-checkbox
        *ngSwitchCase="'checkbox'"
        [filterElement]="element"
        (applyFilter)="applyFilter.emit($event)"
        [(showAll)]="showAllElements[element.name]"
      />
      <!-- swatch -->
      <ish-filter-swatch-images
        *ngSwitchCase="'swatch'"
        [filterElement]="element"
        (applyFilter)="applyFilter.emit($event)"
      />
      <!-- dropdown -->
      <ng-container *ngSwitchCase="'dropdown'">
        <div class="filter-dropdown">
          <camfil-filter-dropdown
            [filterElement]="element"
            [placeholderType]="'selectedFacets'"
            (applyFilter)="applyFilter.emit($event)"
          />
        </div>
      </ng-container>
    </camfil-filter-collapsable>
  </ng-container>
</ng-container>
