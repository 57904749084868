<ng-container *ngIf="currentPage && pageIndices.length > 1">
  <ul class="product-list-paging">
    <li>
      <ng-container *ngIf="currentPage > 1; else inactiveFirstLink">
        <a
          [routerLink]="[]"
          queryParamsHandling="merge"
          [queryParams]="{ page: currentPage - 1 }"
          [fragment]="fragmentOnRouting"
          class="product-list-paging-previous"
          >&laquo;</a
        >
      </ng-container>
      <ng-template #inactiveFirstLink> &laquo; </ng-template>
    </li>
    <li *ngFor="let p of pageIndices">
      <a
        [routerLink]="[]"
        queryParamsHandling="merge"
        [queryParams]="{ page: p.value }"
        [fragment]="fragmentOnRouting"
        [ngClass]="{ active: p.value === currentPage }"
        >{{ p.display }}</a
      >
    </li>
    <li class="pagination-list-next">
      <ng-container *ngIf="pageIndices[pageIndices.length - 1].value !== currentPage; else inactiveLastLink">
        <a
          [routerLink]="[]"
          queryParamsHandling="merge"
          [queryParams]="{ page: currentPage + 1 }"
          [fragment]="fragmentOnRouting"
          >&raquo;</a
        >
      </ng-container>
      <ng-template #inactiveLastLink> &raquo; </ng-template>
    </li>
  </ul>
</ng-container>
