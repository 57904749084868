import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  CamfilMeasurementFilterFacade,
  MeasurementQueryParam,
  measurementFilterKeys,
} from 'camfil-core/facades/camfil-measurement-filter.facade';

import { FilterNavigationData } from 'ish-core/models/filter-navigation/filter-navigation.interface';
import { FilterNavigationMapper } from 'ish-core/models/filter-navigation/filter-navigation.mapper';
import { FilterNavigation } from 'ish-core/models/filter-navigation/filter-navigation.model';
import { Filter } from 'ish-core/models/filter/filter.model';

@Injectable({ providedIn: 'root' })
export class CamfilFilterNavigationMapper extends FilterNavigationMapper {
  constructor(store: Store, private measurementFilterFacade: CamfilMeasurementFilterFacade) {
    super(store);
  }

  private transformFilterNavigationForMeasurements(filter: Filter, filterId: MeasurementQueryParam): void {
    filter.displayType = 'text';
    filter.facets = filter.facets.map(facet => {
      const newFacet = { ...facet };
      const tolerance = this.measurementFilterFacade.getFilterTolerance(filterId);

      if (tolerance > 0) {
        const value = Number(newFacet.displayName);
        const { minValue, maxValue } = this.measurementFilterFacade.getFilterRange(filterId, value);
        const displayName = isNaN(value) ? facet.displayName : `[${minValue} TO ${maxValue}]`;
        const name = isNaN(value) ? facet.name : `${filterId}=${displayName}`;
        const searchParameter = {
          ...newFacet.searchParameter,
          ...this.measurementFilterFacade.createFilterRangeParams(filterId, value),
        };

        newFacet.name = name;
        newFacet.displayName = displayName;
        newFacet.searchParameter = searchParameter;

        const exactFacet = filter.facets.find(f => f.displayName === newFacet.displayName);

        newFacet.selected = !!exactFacet?.selected;

        delete newFacet.searchParameter[filterId];
      }

      return newFacet;
    });
  }

  override fromData(data: FilterNavigationData): FilterNavigation {
    const filterNavigation = super.fromData(data);

    if (filterNavigation && this.measurementFilterFacade.transformFilterNavigation) {
      filterNavigation.filter.forEach(filter => {
        const filterId = filter.id as MeasurementQueryParam;
        if (measurementFilterKeys.includes(filterId)) {
          this.transformFilterNavigationForMeasurements(filter, filterId);
        }
      });
    }

    return filterNavigation;
  }
}
