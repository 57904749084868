import { AfterViewInit, ChangeDetectionStrategy, Component, OnInit, ViewChild } from '@angular/core';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatInput } from '@angular/material/input';
import { FieldTypeConfig } from '@ngx-formly/core';
import { FieldType } from '@ngx-formly/material/form-field';
import { Observable, isObservable, of, switchMap } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Component({
  selector: 'camfil-autocomplete-input-field',
  templateUrl: './camfil-autocomplete-input-field.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
// eslint-disable-next-line ish-custom-rules/require-formly-code-documentation
export class CamfilAutocompleteInputFieldComponent extends FieldType<FieldTypeConfig> implements OnInit, AfterViewInit {
  @ViewChild(MatInput) formFieldControl: MatInput;
  @ViewChild(MatAutocompleteTrigger) autocomplete: MatAutocompleteTrigger;

  get type() {
    return this.props.type || 'text';
  }

  override defaultOptions = {
    props: {
      displayWith: (value: string): string => value,
      autoActiveFirstOption: true,
      autoSelectActiveOption: true,
      hideSingleSelectionIndicator: false,
    },
  };

  filteredOptions$: Observable<string[]>;

  displayWith = (value: string): string => this.props.displayWith(value);

  ngOnInit() {
    this.filteredOptions$ = this.formControl.valueChanges.pipe(
      startWith(''),
      switchMap(value => this._filter(value || ''))
    );
  }

  ngAfterViewInit() {
    // temporary fix for https://github.com/angular/material2/issues/6728
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (<any>this.autocomplete)._formField = this.formField;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private _filter(value: string): Observable<string[]> {
    const filterValue = (typeof value === 'string' ? value : this.displayWith(value))?.toString()?.toLowerCase();
    const options$ = isObservable(this.props.options) ? this.props.options : of(this.props.options);

    return options$.pipe(
      map(optionsArray =>
        optionsArray.map(this.displayWith).filter(option => option.toString().toLowerCase().includes(filterValue))
      )
    );
  }
}
