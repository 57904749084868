import { createSelector } from '@ngrx/store';

import { getCamfilFeatureState } from '../camfil-store';

import { CamfilExceptionsState, camfilCalendarExceptionsFeatureKey } from './camfil-calendar-exceptions.reducer';

const getCamfilCalendarExceptionstate = getCamfilFeatureState<CamfilExceptionsState>(
  camfilCalendarExceptionsFeatureKey
);

export const getCamfilCalendarExceptions = createSelector(getCamfilCalendarExceptionstate, state => state.dates);
