import { Injectable } from '@angular/core';
import { createEffect, ofType } from '@ngrx/effects';
import { select } from '@ngrx/store';
import { first, mergeMap, switchMap } from 'rxjs/operators';

import { Product } from 'ish-core/models/product/product.model';
import { loadProductsForFilter } from 'ish-core/store/shopping/filter';
import { getProductListingItemsPerPage, setProductListingPages } from 'ish-core/store/shopping/product-listing';
import { loadProductFail, loadProductSuccess } from 'ish-core/store/shopping/products';
import { ProductsEffects } from 'ish-core/store/shopping/products/products.effects';
import { mapErrorToAction, mapToPayload, whenTruthy } from 'ish-core/utils/operators';

/* eslint-disable @typescript-eslint/dot-notation */

@Injectable()
export class CamfilProductsEffects extends ProductsEffects {
  override loadFilteredProducts$ = createEffect(() =>
    this['actions$'].pipe(
      ofType(loadProductsForFilter),
      mapToPayload(),
      switchMap(({ id, searchParameter, page, sorting }) =>
        this['store'].pipe(
          select(getProductListingItemsPerPage(id.type)),
          whenTruthy(),
          first(),
          switchMap(pageSize =>
            this['productsService']
              .getFilteredProducts(searchParameter, pageSize, sorting, ((page || 1) - 1) * pageSize)
              .pipe(
                mergeMap(({ products, total, sortableAttributes }) => [
                  ...products.map((product: Product) => loadProductSuccess({ product })),
                  setProductListingPages(
                    this['productListingMapper'].createPages(
                      products.map(p => p.sku),
                      id.type,
                      id.value,
                      pageSize,
                      {
                        filters: id.filters,
                        itemCount: total,
                        startPage: page,
                        sortableAttributes,
                        sorting,
                      }
                    )
                  ),
                ]),
                mapErrorToAction(loadProductFail)
              )
          )
        )
      )
    )
  );
}
