import { createSelector } from '@ngrx/store';
import { CamfilCustomerDeliveryTerm } from 'camfil-models/camfil-customer-delivery-term/camfil-customer-delivery-term.model';

import { getCamfilFeatureState } from '../camfil-store';

import {
  CamfilCustomerDeliveryTermsState,
  camfilCustomerDeliveryTermFeatureKey,
  camfilCustomerDeliveryTermsAdapter,
} from './camfil-customer-delivery-terms.reducer';

const getCamfilCustomerDeliveryTermsState = getCamfilFeatureState<CamfilCustomerDeliveryTermsState>(
  camfilCustomerDeliveryTermFeatureKey
);

const { selectEntities } = camfilCustomerDeliveryTermsAdapter.getSelectors(getCamfilCustomerDeliveryTermsState);

const getSelectedCamfilCustomerDeliveryTermId = createSelector(
  getCamfilCustomerDeliveryTermsState,
  state => state.selected
);

export const getSelectedCamfilCustomersDeliveryTerms = createSelector(
  selectEntities,
  getSelectedCamfilCustomerDeliveryTermId,
  (entities, id): CamfilCustomerDeliveryTerm => id && entities[id]
);

export const getCamfilCustomerDeliveryTerm = (customerId: string) =>
  createSelector(selectEntities, (entities): CamfilCustomerDeliveryTerm => customerId && entities[customerId]);
