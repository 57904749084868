import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'addressSort',
})
export class CamfilAddressSortPipe implements PipeTransform {
  transform<T>(array: T[], fieldName: keyof T): T[] {
    if (!Array.isArray(array)) {
      return [];
    }
    return array.slice().sort((a: T, b: T) => {
      if (`${a[fieldName]}`?.toLowerCase() < `${b[fieldName]}`.toLowerCase()) {
        return -1;
      } else if (`${a[fieldName]}`.toLowerCase() > `${b[fieldName]}`.toLowerCase()) {
        return 1;
      } else {
        return 0;
      }
    });
  }
}
