// eslint-disable-next-line ish-custom-rules/ban-imports-file-pattern
import { sortBy } from 'lodash-es';

import { PriceHelper } from 'ish-core/models/price/price.helper';

import { CamfilPriceItemMapper } from '../camfil-price-item/camfil-price-item.mapper';

import { CamfilSurchargeHelper } from './camfil-surcharge.helper';
import { CamfilSurchargeData } from './camfil-surcharge.interface';
import { CamfilSurcharge } from './camfil-surcharge.model';
import { CamfilSurchargeTypes } from './camfil-surcharge.types';

export class CamfilSurchargeMapper {
  static fromData(data: CamfilSurchargeData): CamfilSurcharge {
    if (data) {
      return {
        amount: CamfilPriceItemMapper.fromPriceItem(data.amount),
        displayName: data.name,
        description: data.description,
        taxes: data?.taxes?.map(CamfilPriceItemMapper.fromPriceItem)?.filter(Boolean),
      };
    }
  }

  static fromListData(data: CamfilSurchargeData[]): CamfilSurcharge[] {
    if (data?.length) {
      let surcharges = data.map(CamfilSurchargeMapper.fromData);

      const shippingDiscountSurcharge = CamfilSurchargeHelper.select(surcharges, CamfilSurchargeTypes.ShippingDiscount);
      const shippingFeeSurcharge = CamfilSurchargeHelper.select(surcharges, CamfilSurchargeTypes.ShippingDiscount);

      if (CamfilSurchargeHelper.equal(shippingDiscountSurcharge, shippingFeeSurcharge)) {
        surcharges = surcharges
          ?.map(surcharge => ({ ...surcharge, strikethrough: surcharge?.amount.net <= 0 }))
          ?.map(surcharge => {
            switch (surcharge.displayName) {
              case CamfilSurchargeTypes.ShippingFee:
                return;
              case CamfilSurchargeTypes.ShippingDiscount:
                return { ...surcharge, amount: PriceHelper.invert(surcharge.amount) };
              default:
                return surcharge;
            }
          })
          .filter(Boolean);
      }

      return sortBy(surcharges, 'displayName');
    }
  }
}
