import { AfterViewInit, ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { AbstractControl, ValidationErrors } from '@angular/forms';
import { FieldTypeConfig } from '@ngx-formly/core';
import { FieldType } from '@ngx-formly/material/form-field';
import { CamfilAccountFacade } from 'camfil-core/facades/camfil-account.facade';
import { CamfilConfigurationFacade } from 'camfil-core/facades/camfil-configuration.facade';
import { Observable, catchError, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { whenTruthy } from 'ish-core/utils/operators';

/* eslint-disable unicorn/no-null */

@Component({
  selector: 'camfil-postal-code-input-field',
  templateUrl: './camfil-postal-code-input-field.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
// eslint-disable-next-line ish-custom-rules/require-formly-code-documentation
export class CamfilPostalCodeInputFieldComponent extends FieldType<FieldTypeConfig> implements AfterViewInit, OnInit {
  constructor(
    private accountFacade: CamfilAccountFacade,
    private camfilConfigurationFacade: CamfilConfigurationFacade
  ) {
    super();
  }

  get type() {
    return this.props.type || 'text';
  }

  ngOnInit() {
    if (this.formControl.valid) {
      this.accountFacade.loadZipCodeAddress$(this.formControl.value);
    }
  }

  ngAfterViewInit(): void {
    this.formControl.setAsyncValidators(this.zipCodeValidator);
  }

  private zipCodeValidator = (control: AbstractControl): Observable<ValidationErrors | null> =>
    this.zipCodePatternValidator(control).pipe(
      switchMap(validationError => {
        if (validationError) {
          // If zipCodePatternValidator fails, return its error
          return of(validationError);
        } else {
          // If zipCodePatternValidator succeeds, proceed to getZipCodeDetails
          return this.zipCodeDetailsValidator(control);
        }
      })
    );

  private zipCodePatternValidator = (control: AbstractControl): Observable<ValidationErrors | null> =>
    this.camfilConfigurationFacade.zipCodeRegExp$.pipe(
      map(regExp => {
        const isValidRegExp = new RegExp(regExp).test(control?.value);
        return isValidRegExp ? null : { zipCodePattern: { valid: false } };
      })
    );

  private zipCodeDetailsValidator = (control: AbstractControl): Observable<ValidationErrors | null> => {
    const zipCode = control.value;
    this.accountFacade.loadZipCodeAddress$(zipCode);
    return this.accountFacade.getAllZipCodeAddresses$().pipe(
      whenTruthy(),
      map(zipMap => (zipMap[zipCode]?.[0]?.id ? null : { incorrectZipCode: true })),
      catchError(() => of({ incorrectZipCode: true }))
    );
  };
}
