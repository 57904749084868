import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { CamfilBasketService } from 'camfil-core/services/camfil-basket/camfil-basket.service';
import { concatMap, filter, map, mergeMap } from 'rxjs';

import { CheckoutStepType } from 'ish-core/models/checkout/checkout-step.type';
import { RoleToggleService } from 'ish-core/role-toggle.module';
import { displaySuccessMessage } from 'ish-core/store/core/messages';
import {
  createBasket,
  getCurrentBasketId,
  loadBasket,
  loadBasketFail,
  loadBasketSuccess,
  resetBasketErrors,
  submitBasketFail,
  submitBasketSuccess,
  updateBasketFail,
} from 'ish-core/store/customer/basket';
import { mapErrorToAction, mapToPayload, mapToPayloadProperty } from 'ish-core/utils/operators';

import {
  cancelBucketEditMode,
  deleteCamfilBucketSuccess,
  deleteCamfilBucketsSuccess,
} from '../camfil-bucket/camfil-bucket.actions';
import { getWarehouseCalendar } from '../camfil-calendar-exceptions/camfil-calendar-exceptions.actions';
import { copyBasket, saveOnlyEmptyBuckets } from '../camfil-edit-basket/camfil-edit-basket.actions';
import { getCamfilEditBasketState } from '../camfil-edit-basket/camfil-edit-basket.selectors';

import {
  cancelBasketEditMode,
  continueCamfilCheckout,
  continueCamfilCheckoutFail,
  continueCamfilCheckoutWithIssues,
  continueCamfilCheckoutWithIssuesAsFail,
  loadCamfilBasket,
  loadCamfilBasketSuccess,
  placeCamfilOrder,
  placeCamfilOrderFail,
  placeCamfilOrderSuccess,
  reloadBasket,
  resetCamfilBasketErrors,
  selectCamfilBasket,
  setBasketToEditMode,
  setBucketEditMode,
  showSubmittedInfo,
  updateCamfilBasket,
  updateCamfilBasketFail,
} from './camfil-basket.actions';
import { getBucketEditMode, getSelectedCamfilBasketId } from './camfil-basket.selectors';

@Injectable()
export class CamfilBasketEffects {
  constructor(
    private actions$: Actions,
    private ishActions$: Actions,
    private store: Store,
    private camfilBasketService: CamfilBasketService,
    private roleToggleService: RoleToggleService
  ) {}

  loadCamfilBasket$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadCamfilBasket),
      concatLatestFrom(() => this.store.pipe(select(getCurrentBasketId))),
      map(([, basketId]) =>
        loadCamfilBasketSuccess({ camfilBasket: { id: basketId, calculated: true, currentEditedBucketId: undefined } })
      )
    )
  );

  selectCamfilBasketOnLoadCamfilBasketSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadCamfilBasketSuccess),
      mapToPayloadProperty('camfilBasket'),
      mergeMap(camfilBasket => [selectCamfilBasket({ basketId: camfilBasket.id })])
    )
  );

  // TODO: TMP - remove it when earliestDeliveryDate will be fix
  loadCamfilBaskets$ = createEffect(() => this.ishActions$.pipe(ofType(loadBasket), map(getWarehouseCalendar)));

  loadCamfilBasketsAfterLoadBasketSuccess$ = createEffect(() =>
    this.ishActions$.pipe(ofType(loadBasketSuccess), mapToPayloadProperty('basket'), map(loadCamfilBasket))
  );

  reloadBasket$ = createEffect(() =>
    this.ishActions$.pipe(
      ofType(reloadBasket),
      mergeMap(() =>
        this.camfilBasketService.reloadBasket().pipe(
          map(basket => loadBasketSuccess({ basket })),
          mapErrorToAction(loadBasketFail)
        )
      )
    )
  );

  updateCamfilBasket$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateCamfilBasket),
      mapToPayloadProperty('update'),
      concatMap(update =>
        this.camfilBasketService.updateCamfilBasket(update).pipe(
          concatMap(camfilBasket => [loadCamfilBasketSuccess({ camfilBasket }), resetCamfilBasketErrors()]),
          mapErrorToAction(updateCamfilBasketFail)
        )
      )
    )
  );

  setBucketEditMode$ = createEffect(() =>
    this.ishActions$.pipe(
      ofType(setBucketEditMode),
      mapToPayloadProperty('id'),
      concatLatestFrom(() => [
        this.store.pipe(select(getBucketEditMode)),
        this.roleToggleService.hasRole('APP_B2B_REQUEST_QUOTATION'),
        this.roleToggleService.hasRole('APP_B2B_NEEDS_APPROVAL'),
        this.store.pipe(select(getSelectedCamfilBasketId)),
      ]),
      filter(([id, editMode]) => !id || id !== editMode),
      mergeMap(([id, , quote, req, selectedBasketId]) => [
        copyBasket({ id }),
        setBasketToEditMode({ id, camfilBasketId: selectedBasketId }),
        displaySuccessMessage({
          message: this.handleRoleCase('camfil.dynamic.checkout.edit_mode.turn_on', Boolean(quote), Boolean(req)),
          duration: 1000,
        }),
      ])
    )
  );

  cancelBasketEditMode$ = createEffect(() =>
    this.ishActions$.pipe(
      ofType(setBasketToEditMode),
      mapToPayloadProperty('id'),
      filter(id => !id),
      concatLatestFrom(() => [
        this.store.pipe(select(getCamfilEditBasketState)),
        this.roleToggleService.hasRole('APP_B2B_REQUEST_QUOTATION'),
        this.roleToggleService.hasRole('APP_B2B_NEEDS_APPROVAL'),
      ]),
      mergeMap(([, copy, quote, req]) => [
        cancelBucketEditMode({ camfilBuckets: copy.camfilBuckets }),
        cancelBasketEditMode(),
        displaySuccessMessage({
          message: this.handleRoleCase('camfil.dynamic.checkout.edit_mode.turn_off', quote, req),
          duration: 1000,
        }),
      ])
    )
  );

  saveOnlyEmptyBuckets$ = createEffect(() =>
    this.actions$.pipe(
      ofType(saveOnlyEmptyBuckets),
      mapToPayloadProperty('emptyBuckets'),
      concatLatestFrom(() => [
        this.roleToggleService.hasRole('APP_B2B_REQUEST_QUOTATION'),
        this.roleToggleService.hasRole('APP_B2B_NEEDS_APPROVAL'),
      ]),
      mergeMap(([camfilBuckets, quote, req]) => [
        cancelBucketEditMode({ camfilBuckets }),
        cancelBasketEditMode(),
        displaySuccessMessage({
          message: this.handleRoleCase('camfil.dynamic.checkout.edit_mode.turn_off', quote, req),
          duration: 1000,
        }),
      ])
    )
  );

  /**
   * create basket when dont have one which working effect.
   */
  reloadBasketOnFailUpdate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateBasketFail),
      concatMap(() => [createBasket(), resetCamfilBasketErrors()])
    )
  );

  loadBasketAfterBucketChangeSuccess$ = createEffect(() =>
    this.ishActions$.pipe(
      ofType(deleteCamfilBucketSuccess),
      map(() => reloadBasket())
    )
  );

  loadBasketAfterBucketsChangeSuccess$ = createEffect(() =>
    this.ishActions$.pipe(
      ofType(deleteCamfilBucketsSuccess),
      map(() => reloadBasket())
    )
  );

  resetCamfilBasketErrors$ = createEffect(() =>
    this.ishActions$.pipe(
      ofType(resetCamfilBasketErrors),
      map(() => resetBasketErrors())
    )
  );

  placeCamfilOrder$ = createEffect(() =>
    this.ishActions$.pipe(
      ofType(continueCamfilCheckout),
      mapToPayloadProperty('targetStep'),
      filter(targetStep => targetStep === CheckoutStepType.Receipt),
      map(() => placeCamfilOrder())
    )
  );

  placeCamfilOrderSuccess$ = createEffect(() =>
    this.ishActions$.pipe(
      ofType(submitBasketSuccess),
      concatMap(() => [placeCamfilOrderSuccess(), showSubmittedInfo()])
    )
  );

  continueCamfilCheckoutWithIssues$ = createEffect(() =>
    this.ishActions$.pipe(
      ofType(continueCamfilCheckoutWithIssues),
      map(() =>
        continueCamfilCheckoutWithIssuesAsFail({
          error: {
            name: 'HttpErrorResponse',
            message: 'continueCamfilCheckoutWithIssues as Fail',
          },
        })
      )
    )
  );

  placeCamfilOrderFail$ = createEffect(() =>
    this.ishActions$.pipe(
      ofType(submitBasketFail, continueCamfilCheckoutWithIssuesAsFail, continueCamfilCheckoutFail),
      mapToPayload(),
      map(placeCamfilOrderFail)
    )
  );

  //TODO: confirm why it was
  // loadBasketsAfterlogin$ = createEffect(() => this.actions$.pipe(ofType(loginUserSuccess), map(loadCamfilBaskets)));

  protected handleRoleCase(str: string, quote: boolean, req: boolean): string {
    return str + ((quote && '_quote') || (req && '_requisition') || '');
  }
}
