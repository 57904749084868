import { Action, ActionReducer, MetaReducer } from '@ngrx/store';

export function camfilResetWithoutSubStatesOnActionsMetaReducer<S>(
  subStates: (keyof S)[],
  actions: Action[]
): MetaReducer<S, Action> {
  return (reducer: ActionReducer<S, Action>): ActionReducer<S, Action> =>
    (state: S | undefined, action: Action): S => {
      if (actions.some(a => a.type === action.type)) {
        const preservedState: Partial<S> = {};
        subStates.forEach(key => {
          // eslint-disable-next-line @typescript-eslint/prefer-optional-chain
          if (state && state.hasOwnProperty(key)) {
            preservedState[key] = state[key];
          }
        });
        // eslint-disable-next-line ish-custom-rules/no-object-literal-type-assertion
        return reducer({ ...preservedState } as S, action);
      }
      return reducer(state, action);
    };
}
