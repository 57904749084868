import { Injectable } from '@angular/core';
import { CamfilOrderHelper } from 'camfil-models/camfil-order/camfil-order.helper';

import { OrderData } from 'ish-core/models/order/order.interface';
import { OrderMapper as IshOrderMapper } from 'ish-core/models/order/order.mapper';
import { Order } from 'ish-core/models/order/order.model';

import { CamfilOrderDeliveryAddressMapper } from '../camfil-order-delivery-address/camfil-order-delivery-address.mapper';
import { CamfilSurchargeMapper } from '../camfil-surcharge/camfil-surcharge.mapper';

import { CamfilOrderData, CamfilOredrRebates } from './camfil-order.interface';
import { CamfilOrder, CamfilOrderStatus } from './camfil-order.model';

@Injectable({ providedIn: 'root' })
export class CamfilOrderMapper {
  static fromData(data: CamfilOrderData): CamfilOrder {
    if (data) {
      const camfilNo = data.customerOrderNumber;
      const orderStatus = data?.orderStatus || CamfilOrderStatus.Created;
      const lineItems = data?.camfilOrderLines;
      const deliveryAddress = CamfilOrderDeliveryAddressMapper.fromData(data);
      const commonDeliveryAddress = CamfilOrderDeliveryAddressMapper.addressFromDeliveryAddress(deliveryAddress);
      const itemSurchargeTotalsByType = CamfilSurchargeMapper.fromListData(data?.surcharges?.itemSurcharges);
      const bucketSurchargeTotalsByType = CamfilSurchargeMapper.fromListData(data?.surcharges?.bucketSurcharges);
      const totalDeliveredQty = CamfilOrderHelper.getTotalDeliveredQty(lineItems);
      const totalOrderedQty = CamfilOrderHelper.getTotalOrderedQty(lineItems);
      const isPartialDelivery = CamfilOrderHelper.isPartialDelivery(data, lineItems);
      const deliveryDates = CamfilOrderHelper.getDeliveryDates(lineItems);
      const rebates = CamfilOrderMapper.getRebates(data.rebates);

      return {
        additionalTotalCost: data.additionalTotalCosts,
        bucketSurchargeTotalsByType,
        camfilNo,
        commonDeliveryAddress,
        contactPerson: data.contactPerson,
        currency: data.currency,
        customerDepartment: data.customerDepartment,
        customerName: data.customerName,
        customerNo: data.customerNo,
        customerOrderNumber: data.customerOrderNumber,
        deliveryAddress,
        deliveryDate: data.deliveryDate,
        goodsAcceptanceNote: data.goodsAcceptanceNote,
        id: data.id,
        ishOrderUUID: data.ishOrderUUID,
        itemSurchargeTotalsByType,
        lineItems,
        orderChannel: data.orderChannel,
        orderComment: data.orderComment,
        orderDate: data.orderDate,
        orderGoodsMark: data.orderGoodsMark,
        orderNumber: data.orderNumber,
        orderStatus, // If there is no order status then use default status
        phoneNotification: data.phoneNotification,
        taxAmount: data.taxAmount,
        totalCustomerPriceSum: data.totalCustomerPriceSum,
        totalPriceAfterDiscountExVAT: data.totalPriceAfterDiscountExVAT,
        volumeDiscount: data.volumeDiscount,
        totalDeliveredQty,
        totalOrderedQty,
        isPartialDelivery,
        deliveryDates,
        rebates,
      };
    }
  }

  static fromOrderCreateData(payload: OrderData): Order[] {
    const ishOrders = IshOrderMapper.fromListData(payload);
    return [...ishOrders];
  }

  static getRebates(rebates: CamfilOredrRebates[]): CamfilOredrRebates[] {
    return (
      rebates?.map(rebate => {
        const value = rebate.amount.value * -1;
        return {
          ...rebate,
          amount: {
            ...rebate.amount,
            value,
          },
        };
      }) || undefined
    );
  }
}
