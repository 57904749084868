import { Injectable } from '@angular/core';
import { concatLatestFrom } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import {
  cleanUpCompletedCart,
  continueCamfilCheckout,
  doubleCamfilBucketItemsQuantity,
  setBasketOrderType,
  setBucketEditMode,
} from 'camfil-core/store/camfil-basket/camfil-basket.actions';
import {
  getBucketEditMode,
  getCalculatedCamfilBasket,
  getCamfilBasketLoading,
  getCamfilBasketValidationResults,
  getSelectedCamfilBasket,
  getSelectedCamfilBasketId,
  getSubmitedBasket,
  showSubmitedBasket,
} from 'camfil-core/store/camfil-basket/camfil-basket.selectors';
import {
  addEmptyCamfilBucket,
  camfilDeleteBucketItem,
  camfilDragLineItem,
  camfilUpdateBucketItem,
  deleteCamfilBucket,
  deleteCamfilBuckets,
  deleteEmptyCamfilBucket,
  prepareCamfilAddToCart,
  updateBucketItemAttributes,
  updateBuckets,
  updateEmptyCamfilBucket,
} from 'camfil-core/store/camfil-bucket/camfil-bucket.actions';
import {
  getBucketGoodsAcceptanceNote,
  getCamfilBucketByAddressId,
  getCamfilBucketsLength,
  getCamfilBucketsLoading,
  getCamfilBucketsVolumeDiscounts,
  getCurrentCamfilBuckets,
  getEmptyCamfilBuckets,
  getFreightCostInvalid,
  getSubmittedBuckets,
} from 'camfil-core/store/camfil-bucket/camfil-bucket.selectors';
import { getWarehouseCalendar } from 'camfil-core/store/camfil-calendar-exceptions/camfil-calendar-exceptions.actions';
import { getCamfilCalendarExceptions } from 'camfil-core/store/camfil-calendar-exceptions/camfil-calendar-exceptions.selectors';
import { loadCustomerDeliveryTerm } from 'camfil-core/store/camfil-customer-delivery-terms/camfil-customer-delivery-terms.actions';
import { getCamfilCustomerDeliveryTerm } from 'camfil-core/store/camfil-customer-delivery-terms/camfil-customer-delivery-terms.selectors';
import {
  applyChangesFromEditMode,
  updateBucketEditMode,
} from 'camfil-core/store/camfil-edit-basket/camfil-edit-basket.actions';
import { getOrdersLoading } from 'camfil-core/store/camfil-orders/camfil-orders.selectors';
import { interpolateParams } from 'camfil-core/utils/functions';
import { CamfilAddress } from 'camfil-models/camfil-address/camfil-address.model';
import { CamfilBasketFeedbackHelper } from 'camfil-models/camfil-basket-feedback/camfil-basket-feedback.helper';
import { CamfilBasketFeedbackViewModel } from 'camfil-models/camfil-basket-feedback/camfil-basket-feedback.model';
import { CamfilAddItem, CamfilBasket } from 'camfil-models/camfil-basket/camfil-basket.model';
import { CamfilBucketHelper } from 'camfil-models/camfil-bucket/camfil-bucket.helper';
import { CamfilBucket } from 'camfil-models/camfil-bucket/camfil-bucket.model';
import { isEqual, uniq } from 'lodash-es';
import { Observable, combineLatest, map, of, shareReplay, switchMap } from 'rxjs';
import { distinctUntilChanged, filter } from 'rxjs/operators';

import { AppFacade } from 'ish-core/facades/app.facade';
import { CheckoutFacade } from 'ish-core/facades/checkout.facade';
import { Attribute } from 'ish-core/models/attribute/attribute.model';
import { CheckoutStepType } from 'ish-core/models/checkout/checkout-step.type';
import { LineItemUpdate } from 'ish-core/models/line-item-update/line-item-update.model';
import { LineItem } from 'ish-core/models/line-item/line-item.model';
import { PriceHelper } from 'ish-core/models/price/price.helper';
import { getCurrentBasket } from 'ish-core/store/customer/basket';
import { mapToProperty, whenTruthy } from 'ish-core/utils/operators';

import { CamCardsFacade } from './camfil-cam-cards.facade';

@Injectable({ providedIn: 'root' })
export class CamfilCheckoutFacade {
  constructor(
    private store: Store,
    private translateService: TranslateService,
    private appFacade: AppFacade,
    private checkoutFacade: CheckoutFacade,
    private camCardsFacade: CamCardsFacade
  ) {}

  isEditMode$ = this.store.pipe(
    select(getBucketEditMode),
    map(edit => !!edit),
    shareReplay(1)
  );

  calculatedCamfilBasket$ = this.store.pipe(select(getCalculatedCamfilBasket));
  calendarExceptions$ = this.store.pipe(select(getCamfilCalendarExceptions));
  camfilBasket$ = this.store.pipe(select(getSelectedCamfilBasket));
  camfilBasketId$ = this.store.pipe(select(getSelectedCamfilBasketId));
  camfilBasketLoading$ = this.store.pipe(select(getCamfilBasketLoading));
  camfilBasketValidationResults$ = this.store.pipe(select(getCamfilBasketValidationResults));
  camfilBuckets$ = this.store.pipe(select(getCurrentCamfilBuckets));
  camfilBucketsLoading$ = this.store.pipe(select(getCamfilBucketsLoading));
  camfilBucketsLength$ = this.store.pipe(select(getCamfilBucketsLength));
  camfilBucketsVolumeDiscounts$ = this.store.pipe(select(getCamfilBucketsVolumeDiscounts));
  emptyCamfilBuckets$ = this.store.pipe(select(getEmptyCamfilBuckets));
  isFreightCostInvalid$ = this.store.pipe(select(getFreightCostInvalid));
  ordersLoading$ = this.store.pipe(select(getOrdersLoading));
  showSubmitedBasket$ = this.store.pipe(select(showSubmitedBasket));
  getSubmitedBasket$ = this.store.pipe(select(getSubmitedBasket));
  getSubmitedBuckets$ = this.store.pipe(select(getSubmittedBuckets));

  bucketsNotCreatedFromCamCard$ = this.getSubmitedBuckets$.pipe(
    whenTruthy(),
    map(camfilBuckets => camfilBuckets?.filter(b => !b.createdFromCamCardId))
  );

  suggestCamCardCreationAfterOrderSubmission$ = this.bucketsNotCreatedFromCamCard$.pipe(
    map(buckets => buckets?.length > 0)
  );

  isLoading$: Observable<boolean> = combineLatest([
    this.camfilBucketsLoading$,
    this.ordersLoading$,
    this.checkoutFacade.basketLoading$,
    this.camfilBasketLoading$,
    this.camCardsFacade.camCardAdding$,
  ]).pipe(map(statuses => statuses.some(status => status)));

  camfilBucketsInfoMessages$ = this.getBucketMessages$(this.camfilBuckets$, bucket =>
    this.getCamfilBucketInfoMessages$(bucket.deliveryAddressId)
  );

  camfilBucketsErrorMessages$ = this.getBucketMessages$(this.camfilBuckets$, bucket =>
    this.getCamfilBucketErrorMessages$(bucket.deliveryAddressId)
  );

  deliveryDateErrorMessages$ = this.camfilBasketValidationResults$.pipe(
    mapToProperty('errors'),
    map(errors => errors?.filter(CamfilBasketFeedbackHelper.isDeliveryDateErrorMessage))
  );

  hasDeliveryDateErrorMessage$ = this.deliveryDateErrorMessages$.pipe(map(errors => errors?.length > 0));

  showDeliveryDateValidationDialog$ = this.hasDeliveryDateErrorMessage$.pipe(
    concatLatestFrom(() => this.isEditMode$),
    map(([hasDeliveryDateErrorMessage, isEditMode]) => !isEditMode && hasDeliveryDateErrorMessage)
  );

  camfilBucketsWithInvalidDeliveryDate$ = this.deliveryDateErrorMessages$.pipe(
    switchMap(errors =>
      errors && errors.length > 0
        ? combineLatest(errors.map(error => this.getCamfilBucketByAddressId(error.parameters.addressId)))
        : of([])
    )
  );

  commonDateForExpiredBuckets$: Observable<Date> = this.camfilBucketsWithInvalidDeliveryDate$.pipe(
    map(buckets => buckets.map(bucket => this.getCamfilBucketDeliveryDatesRange(bucket))),
    map(datesData => {
      if (datesData.length === 0) {
        // eslint-disable-next-line unicorn/no-null
        return null;
      }
      if (datesData.length === 1) {
        // eslint-disable-next-line unicorn/no-null
        return datesData[0][0] || null;
      }

      const dateSets: Set<string>[] = datesData.map(dateArray => new Set(dateArray.map(date => date.toISOString())));

      // eslint-disable-next-line unicorn/no-null
      let commonDate: string | null = null;

      for (const date of dateSets[0]) {
        if (dateSets.every(set => set.has(date))) {
          commonDate = date;
          break;
        }
      }

      // eslint-disable-next-line unicorn/no-null
      return commonDate ? new Date(commonDate) : null;
    }),
    distinctUntilChanged(isEqual)
  );

  getInfoMessages$ = this.camfilBasketValidationResults$.pipe(
    mapToProperty('infos'),
    map(infos => infos?.filter(i => !CamfilBasketFeedbackHelper.isCamfilThresholdMessage(i))),
    map(infos => infos?.filter(i => !CamfilBasketFeedbackHelper.isCamfilCreditLimitReachedMessage(i))),
    map(infos => infos?.filter(i => !!i?.message)),
    map(infos =>
      infos
        ?.map(info => {
          // eslint-disable-next-line no-unused-vars
          const { scopes, ...params } = info.parameters;
          return interpolateParams(info.message, { ...params });
        })
        ?.filter(message => !!message)
    )
  );

  getErrorMessages$ = this.camfilBasketValidationResults$.pipe(
    mapToProperty('errors'),
    map(errors => uniq<CamfilBasketFeedbackViewModel>(errors)),
    map(errors => errors?.filter(error => !this.filteredOutError(error))),
    concatLatestFrom(() =>
      this.store.pipe(
        select(getCurrentBasket),
        map(basket => basket?.lineItems)
      )
    ),
    map(
      ([errors, lineItems]) =>
        errors
          ?.map(error => {
            const params = error.parameters ? { ...error.parameters } : {};
            const { productSku, lineItemId } = params;

            if (!error?.message && error?.code) {
              return this.translateService.instant(error?.message || error?.code, params) as string;
            }

            let message = interpolateParams(error?.message, params);

            if (productSku || lineItemId) {
              const sku = productSku || lineItems?.find(({ id }) => id === lineItemId).productSKU;
              message = `${message} (${sku})`;
            }

            return message;
          })
          ?.filter(message => !!message) || []
    )
  );

  private getBucketMessages$<BucketType extends CamfilBucket, MessageType extends string>(
    buckets$: Observable<BucketType[]>,
    messageFetcher: (bucket: BucketType) => Observable<MessageType[]>
  ): Observable<{ id: string; messages: MessageType[]; index: number }[]> {
    return buckets$.pipe(
      filter(buckets => !!buckets?.length),
      switchMap(buckets =>
        combineLatest(
          buckets.map((bucket, i) =>
            messageFetcher(bucket).pipe(map(messages => ({ id: bucket?.id, messages, index: i + 1 })))
          )
        ).pipe(map(bucketMessages => bucketMessages.filter(bucket => bucket?.messages?.length > 0)))
      )
    );
  }

  commonDatesForSelectedBuckets$(buckets: Observable<CamfilBucket[]>): Observable<Date[]> {
    return buckets.pipe(
      map(buckets => buckets.map(bucket => this.getCamfilBucketDeliveryDatesRange(bucket))),
      map(datesData => {
        if (datesData?.length === 0) {
          return [];
        }

        const { a, b } = datesData.reduce(
          (acc, range) => {
            if (!range.length) {
              return acc;
            }

            const a = [...acc.a, range[0].getTime()];
            const b = [...acc.b];

            if (range.length > 1) {
              b.push(range[range.length - 1].getTime());
            }

            return { a, b };
          },
          { a: [], b: [] }
        );

        const start = a.sort()[a.length - 1];
        const end = b.sort()[0];
        const commonDates = [new Date(start)];

        if (end > start && !CamfilBucketHelper.isSameDay(start, end)) {
          commonDates.push(new Date(end));
        }

        return commonDates;
      }),
      distinctUntilChanged(isEqual)
    );
  }

  private filteredOutError(error: CamfilBasketFeedbackViewModel) {
    const specificErrorChecks = [
      CamfilBasketFeedbackHelper.isMissingFieldErrorMessage,
      CamfilBasketFeedbackHelper.isLineItemErrorMessage,
      CamfilBasketFeedbackHelper.isDeliveryDateErrorMessage,
    ];

    const errorCodesToExclude = [
      'basket.validation.line_item_shipping_restrictions.error',
      'basket.validation.basket_not_covered.error',
    ];

    return specificErrorChecks.some(check => check.call(this, error)) || errorCodesToExclude.includes(error.code);
  }

  private createFeedbackMessage(feedback: CamfilBasketFeedbackViewModel): string {
    const params = feedback.parameters ? { ...feedback.parameters } : {};

    if (params?.missingField) {
      params.missingField = this.translateService.instant(params.missingField);
    }

    if (!feedback?.message && feedback?.code) {
      return this.translateService.instant(feedback?.message || feedback?.code, params);
    }

    return interpolateParams(feedback?.message, params);
  }

  private feedbackPredicate(feedback: CamfilBasketFeedbackViewModel, shipToAddress: string) {
    return feedback?.parameters?.shipToAddress === shipToAddress || feedback?.parameters?.addressId === shipToAddress;
  }

  getCamfilBucketByAddressId(addressId: string) {
    return this.store.pipe(select(getCamfilBucketByAddressId(addressId)));
  }

  getCamfilBucketFirstAvailableDeliveryDate(bucket: CamfilBucket) {
    const uniqueDeliveryDates = CamfilBucketHelper.getUniqueDeliveryDates(bucket);
    return uniqueDeliveryDates?.[0];
  }

  getCamfilBucketLastAvailableDeliveryDate(bucket: CamfilBucket) {
    const uniqueDeliveryDates = CamfilBucketHelper.getUniqueDeliveryDates(bucket);
    return uniqueDeliveryDates?.[uniqueDeliveryDates.length - 1];
  }

  getCamfilBucketDeliveryDatesRange(bucket: CamfilBucket) {
    return CamfilBucketHelper.getDeliveryDateRange(
      this.getCamfilBucketFirstAvailableDeliveryDate(bucket),
      this.getCamfilBucketLastAvailableDeliveryDate(bucket)
    );
  }

  getCamfilBucketInfoMessages$(bucketShipToAddress: string): Observable<string[]> {
    return this.camfilBasketValidationResults$.pipe(
      mapToProperty('infos'),
      map(infos => infos?.filter(i => !!i?.message)),
      map(infos => infos?.filter(feedback => this.feedbackPredicate(feedback, bucketShipToAddress))),
      map(infos => infos?.map(info => this.createFeedbackMessage(info)).filter(message => !!message))
    );
  }

  getCamfilBucketErrorMessages$(bucketShipToAddress: string): Observable<string[]> {
    return this.camfilBasketValidationResults$.pipe(
      mapToProperty('errors'),
      map(errors => errors?.filter(error => this.feedbackPredicate(error, bucketShipToAddress))),
      map(errors => errors?.map(info => this.createFeedbackMessage(info)).filter(message => !!message))
    );
  }

  continue(targetStep: CheckoutStepType) {
    this.store.dispatch(continueCamfilCheckout({ targetStep }));
  }

  addEmptyCamfilBucket(emptyBucket: CamfilBucket) {
    this.store.dispatch(addEmptyCamfilBucket({ bucket: emptyBucket }));
  }

  updateEmptyBucket(emptyBucket: CamfilBucket) {
    this.store.dispatch(
      updateEmptyCamfilBucket({
        bucket: emptyBucket,
      })
    );
  }

  updateBucketEditMode(
    basketId: string,
    addressId: string,
    camfilBucket: CamfilBucket,
    address?: Partial<CamfilAddress>
  ) {
    this.store.dispatch(
      updateBucketEditMode({
        basketId,
        addressId,
        camfilBucket,
        address,
      })
    );
  }
  deleteEmptyBucket(bucketId: string) {
    this.store.dispatch(deleteEmptyCamfilBucket({ bucketId }));
  }

  camfilDragLineItem(updatedLineItems: LineItem[], bucketId: string) {
    this.store.dispatch(camfilDragLineItem({ updatedLineItems, bucketId }));
  }

  updateBucketItemAttributes(basketId: string, lineItemId: string, bucketId: string, lineItemAttribute: Attribute) {
    this.store.dispatch(updateBucketItemAttributes({ basketId, lineItemId, bucketId, lineItemAttribute }));
  }

  deleteOrder(basketId: string, bucketId: string, deliveryAddressId: string) {
    this.store.dispatch(deleteCamfilBucket({ basketId, bucketId, deliveryAddressId }));
  }

  deleteCamfilBuckets(orders: CamfilBucket[]) {
    this.store.dispatch(deleteCamfilBuckets({ orders }));
  }

  loadCustomerDeliveryTerm(customerId: string) {
    this.store.dispatch(loadCustomerDeliveryTerm({ customerId }));
  }

  getCustomersDeliveryTerm$(customerId: string) {
    return this.store.pipe(select(getCamfilCustomerDeliveryTerm(customerId)));
  }

  getCamfilBucketDeliveryTerm$(bucket: CamfilBucket) {
    return this.getCustomersDeliveryTerm$(bucket?.customer?.id).pipe(
      map(deliveryTerm => ({
        ...deliveryTerm,
        freeShippingAllowed: bucket?.totals?.itemTotal?.net > deliveryTerm?.threshold,
      }))
    );
  }

  getCamfilBucketDeliveryPrice$(bucket: CamfilBucket) {
    return combineLatest([this.getCamfilBucketDeliveryTerm$(bucket), this.appFacade.currentCurrency$]).pipe(
      map(([deliveryTerm, currency]) => {
        const threshold = deliveryTerm?.threshold || 0;
        const totalNetValue = bucket?.totals?.itemTotal?.net || 0;

        let price = deliveryTerm.freeShippingAllowed || threshold === 0 ? 0 : Math.max(0, threshold - totalNetValue);

        price = Number(price.toFixed(2));

        return PriceHelper.getPrice(currency, price);
      })
    );
  }

  getWarehouseCalendar() {
    this.store.dispatch(getWarehouseCalendar());
  }

  doubleCamfilBucketItemsQuantity(basketId: string, bucketId: string) {
    this.store.dispatch(doubleCamfilBucketItemsQuantity({ basketId, bucketId }));
  }

  setBasketOrderType(id: string, orderType: string) {
    this.store.dispatch(setBasketOrderType({ id, orderType }));
  }

  setBucketEditMode(id?: string) {
    this.store.dispatch(setBucketEditMode({ id }));
  }

  applyChangesFromEditMode() {
    this.store.dispatch(applyChangesFromEditMode());
  }

  deleteBucketItem(itemId: string) {
    this.store.dispatch(camfilDeleteBucketItem({ itemId }));
  }

  updateBasketItem(update: LineItemUpdate) {
    if (update.quantity) {
      this.store.dispatch(camfilUpdateBucketItem({ lineItemUpdate: update }));
    } else {
      this.store.dispatch(camfilDeleteBucketItem({ itemId: update.itemId }));
    }
  }

  getBucketGoodsAcceptanceNote$(urn: string) {
    return this.store.pipe(select(getBucketGoodsAcceptanceNote(urn)));
  }

  prepareCamfilAddToCart(
    products: CamfilAddItem[],
    urn?: string,
    address?: CamfilAddress,
    bucket?: Partial<CamfilBucket>
  ) {
    this.store.dispatch(prepareCamfilAddToCart({ products, urn, address, bucket }));
  }

  updateBuckets(buckets: CamfilBucket[], basket: CamfilBasket) {
    this.store.dispatch(updateBuckets({ buckets, basket }));
  }

  cleanUpCompletedCart() {
    this.store.dispatch(cleanUpCompletedCart());
  }
}
