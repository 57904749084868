export const STANDARD_SHIPPING_METHOD = 'STD_GROUND';

export const EMPTY_BUCKET_PREFIX = 'emptyBucket';

export const DELETED_LOCALLY_ATTR_NAME = 'deletedLocally';

export const NEW_LOCALLY_ITEM_ID_PREFIX = 'new_item_';

export const NEW_LOCALLY_ITEM_ADDRESS_ID_PREFIX = 'new_item_address_';

export const BUCKET_LIST_HEIGHT = 600;

export const BUCKET_LINE_HEIGHT = 100;
