import { createSelector } from '@ngrx/store';
import { getCamfilFeatureState } from 'camfil-core/store/camfil-store';

import { B2bContactsState, camfilB2bContactsFeatureKey, contactAdapter } from './b2b-contacts.reducer';

export const getB2bContactsState = getCamfilFeatureState<B2bContactsState>(camfilB2bContactsFeatureKey);

export const getContactsLoading = createSelector(getB2bContactsState, state => state.loading);

export const getContactsError = createSelector(getB2bContactsState, state => state.error);

const { selectAll, selectTotal } = contactAdapter.getSelectors(getB2bContactsState);

export const getContacts = selectAll;

export const getContactsCount = selectTotal;

export const isContactInitialized = createSelector(getB2bContactsState, state => state.initialized);
