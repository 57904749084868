import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Attribute } from '@angular/compiler';
import { Injectable } from '@angular/core';
import { CamfilLoginOnBehalfQueryParams } from 'camfil-core/identity-provider/camfil-login-on-behalf-identity-provider';
import { CamfilAddress } from 'camfil-models/camfil-address/camfil-address.model';
import { CamCardItemComment, CamCardMeasurement } from 'camfil-models/camfil-cam-card/cam-card.model';
import { CamfilRequisitionData } from 'camfil-models/camfil-requisition/camfil-requisition.interface';
import { CamfilRequisitionMapper } from 'camfil-models/camfil-requisition/camfil-requisition.mapper';
import {
  CamfilRequisition,
  CamfilRequisitionDeliveryDate,
  CamfilRequisitionLineItemUpdate,
  CamfilRequisitionStatus,
  CamfilRequisitionViewer,
} from 'camfil-models/camfil-requisition/camfil-requisition.model';
import { Observable, concatMap, map, throwError } from 'rxjs';

import { Address } from 'ish-core/models/address/address.model';
import { ApiService } from 'ish-core/services/api/api.service';

type RequisitionIncludeType =
  | 'invoiceToAddress'
  | 'commonShipToAddress'
  | 'commonShippingMethod'
  | 'discounts'
  | 'lineItems_discounts'
  | 'lineItems'
  | 'payments'
  | 'payments_paymentMethod'
  | 'payments_paymentInstrument';

@Injectable({ providedIn: 'root' })
export class CamfilRequisitionsService {
  constructor(private apiService: ApiService) {}

  private allIncludes: RequisitionIncludeType[] = [
    'invoiceToAddress',
    'commonShipToAddress',
    'commonShippingMethod',
    'discounts',
    'lineItems_discounts',
    'lineItems',
    'payments',
    'payments_paymentMethod',
    'payments_paymentInstrument',
  ];

  private requisitionsHeaders = new HttpHeaders({
    'content-type': 'application/json',
    Accept: 'application/vnd.intershop.basket.v1+json',
  });

  /**
   * Get all customer requisitions of a certain status and view. The current user is expected to have the approver permission.
   * @param  view    Defines whether the 'buyer' or 'approver' view is returned. Default: 'buyer'
   * @param  status  Approval status filter. Default: All requisitions are returned
   * @returns        Requisitions of the customer with their main attributes. To get all properties the getCamfilRequisition call is needed.
   */
  getCamfilRequisitions(view?: CamfilRequisitionViewer): Observable<CamfilRequisition[]> {
    let params = new HttpParams();
    if (view) {
      params = params.set('view', view);
    }
    return this.apiService
      .get(`camfilrequisitions`, { params })
      .pipe(map(CamfilRequisitionMapper.fromElementsToListData), map(CamfilRequisitionMapper.fromListData));
  }

  /**
   * Get a customer requisition of a certain id. The current user is expected to have the approver permission.
   * @param  id      Requisition id.
   * @returns        Requisition with all attributes. If the requisition is approved and the order is placed, also order data are returned as part of the requisition.
   */
  getCamfilRequisition(requisitionId: string): Observable<CamfilRequisition> {
    if (!requisitionId) {
      return throwError(() => new Error('getCamfilRequisition() called without required id'));
    }

    const params = new HttpParams().set('include', this.allIncludes.join());

    return this.apiService
      .get<CamfilRequisitionData>(`camfilrequisitions/${requisitionId}`, {
        params,
      })
      .pipe(map(payload => CamfilRequisitionMapper.fromData(payload)));
  }

  /**
   * @param  id      Requisition id.
   * @returns        current delivery date from Requisition and earliest possible delivery date
   */
  checkCamfilRequisitionDeliveryDate(requisitionId: string): Observable<CamfilRequisitionDeliveryDate> {
    if (!requisitionId) {
      return throwError(() => new Error('checkCamfilRequisitionDeliveryDate() called without required id'));
    }

    return this.apiService.get<CamfilRequisitionDeliveryDate>(`camfilrequisitions/${requisitionId}/deliverydate`);
  }

  /**
   * Updates the requisition status. The current user is expected to have the approver permission.
   * @param id          Requisition id.
   * @param statusCode  The requisition approval status
   * @param comment     The approval comment
   * @returns           The updated requisition with all attributes. If the requisition is approved and the order is placed, also order data are returned as part of the requisition.
   */
  updateCamfilRequisitionStatus(
    requisitionId: string,
    statusCode: CamfilRequisitionStatus,
    approvalComment?: string
  ): Observable<CamfilRequisition> {
    if (!requisitionId) {
      return throwError(() => new Error('updateCamfilRequisitionStatus() called without required id'));
    }
    if (!statusCode) {
      return throwError(() => new Error('updateCamfilRequisitionStatus() called without required requisition status'));
    }

    const params = new HttpParams().set('include', this.allIncludes.join());
    const body = {
      name: 'string',
      type: 'ApprovalStatusChange',
      statusCode,
      approvalComment,
    };

    const type = statusCode === 'APPROVED' ? 'approve' : 'reject';

    return this.apiService
      .patch<CamfilRequisitionData>(`camfilrequisitions/${requisitionId}/${type}`, body, {
        params,
      })
      .pipe(map(payload => CamfilRequisitionMapper.fromData(payload)));
  }

  /**
   * Updates the requisition status. The current user is expected to have the approver permission.
   * @param id          Requisition id.
   * @returns           The updated requisition with all attributes. If the requisition is approved and the order is placed, also order data are returned as part of the requisition.
   */
  createOrderFromApprovedRequisition(requisitionId: string, lineItemIds?: string[]): Observable<CamfilRequisition> {
    if (!requisitionId) {
      return throwError(() => new Error('createOrderFromApprovedRequisition() called without required id'));
    }

    const externalOrderReference =
      window?.localStorage?.getItem(CamfilLoginOnBehalfQueryParams.ERPEmployeeID) || undefined;

    const params = new HttpParams().set('include', this.allIncludes.join());
    const body = {
      termsAndConditionsAccepted: true,
      lineItemIds,
      externalOrderReference,
    };

    return this.apiService
      .post<CamfilRequisitionData>(`camfilrequisitions/${requisitionId}/create-order`, body, {
        params,
      })
      .pipe(concatMap(payload => CamfilRequisitionMapper.fromListData(payload)));
  }

  // Add product to requisition

  addProductToCamfilRequisition(
    requisitionId: string,
    item: { sku: string; quantity: number; boxLabel?: CamCardItemComment; measurements?: CamCardMeasurement }
  ): Observable<string> {
    if (!requisitionId) {
      return throwError(() => new Error('addProductToCamfilRequisition() called without required id'));
    }

    if (!item) {
      return throwError(() => new Error('removeProductsFromCamfilRequisition() called without required item'));
    }

    const measurementsArray = Object.keys(item.measurements).map(key => ({
      name: key,
      type: 'Double',
      value: (item.measurements as Record<string, number | boolean>)[key],
    }));

    const itemToSend = [
      {
        product: item.sku,
        quantity: { value: item.quantity, unit: '' },
        attributes: [{ name: 'boxLabel', type: 'String', value: item.boxLabel?.label }, ...measurementsArray],
      },
    ];

    return this.apiService
      .post<string>(`camfilrequisitions/${requisitionId}/items`, itemToSend)
      .pipe(map(() => requisitionId));
  }

  // Remove product from requisition
  removeProductsFromCamfilRequisition(lineItemId: string, requisitionId?: string): Observable<string> {
    if (!requisitionId) {
      return throwError(() => new Error('removeProductsFromCamfilRequisition() called without required requisitionId'));
    }

    if (!lineItemId) {
      return throwError(() => new Error('removeProductsFromCamfilRequisition() called without required lineItemId'));
    }

    return this.apiService
      .delete<string>(`camfilrequisitions/${requisitionId}/items/${lineItemId}`)
      .pipe(map(() => requisitionId));
  }

  addLineItemAttribute(requisitionId: string, lineItemId: string, attribute: Attribute) {
    if (!requisitionId) {
      return throwError(() => new Error('addLineItemAttribute() called without required id'));
    }

    if (!lineItemId) {
      return throwError(() => new Error('addLineItemAttribute() called without required line item id'));
    }

    const params = new HttpParams().set('include', this.allIncludes.join());
    const body = {
      lineItemId,
      attribute,
    };
    return this.apiService
      .patch(`camfilrequisitions/${requisitionId}/items/attributes/`, body, {
        params,
      })
      .pipe(map(() => ({ requisitionId, lineItemId, attribute })));
  }

  updateLineItemAttribute(requisitionId: string, lineItemId: string, attribute: Attribute) {
    if (!requisitionId) {
      return throwError(() => new Error('updateLineItemAttribute() called without required id'));
    }

    const params = new HttpParams().set('include', this.allIncludes.join());
    const body = {
      lineItemId,
      attribute,
    };
    return this.apiService
      .patch(`camfilrequisitions/${requisitionId}/items/attributes/`, body, {
        params,
      })
      .pipe(map(() => ({ requisitionId, lineItemId, attribute })));
  }

  deleteLineItemAttribute(requisitionId: string, lineItemId: string, lineItemAttribute: Attribute) {
    if (!requisitionId) {
      return throwError(() => new Error('updateLineItemAttribute() called without required id'));
    }

    if (!lineItemId) {
      return throwError(() => new Error('updateLineItemAttribute() called without required lineItemId'));
    }

    if (!lineItemAttribute) {
      return throwError(() => new Error('updateLineItemAttribute() called without required attribute'));
    }

    const attributeName = lineItemAttribute.name;

    return this.apiService
      .delete(`camfilrequisitions/${requisitionId}/items/${lineItemId}/attributes/${attributeName}`, {
        headers: this.requisitionsHeaders,
      })
      .pipe(map(() => ({ requisitionId, lineItemId, attributeName })));
  }

  updateLineItem(requisitionId: string, lineItemUpdate: CamfilRequisitionLineItemUpdate) {
    if (!requisitionId) {
      return throwError(() => new Error('updateLineItem() called without required requisition id'));
    }

    const params = new HttpParams().set('include', this.allIncludes.join());
    const body = {
      quantity: { value: lineItemUpdate.quantity },
      attributes: [{ name: 'boxLabel', type: 'String', value: lineItemUpdate.boxLabel }],
    };
    if (!lineItemUpdate.quantity) {
      delete body.quantity;
    }
    if (!lineItemUpdate.boxLabel) {
      delete body.attributes;
    }
    return this.apiService
      .patch(`camfilrequisitions/${requisitionId}/items/${lineItemUpdate.lineItemId}`, body, {
        params,
      })
      .pipe(map(() => ({ requisitionId, lineItemUpdate })));
  }

  updateCamfilRequisition(requisition: CamfilRequisition): Observable<CamfilRequisition> {
    const params = new HttpParams().set('include', this.allIncludes.join());
    const body = {
      ...requisition,
    };

    return this.apiService
      .patch(`camfilrequisitions/${requisition.id}`, body, {
        params,
      })
      .pipe(map(() => body));
  }

  updateCamfilRequisitionAddress(
    requisitionId: string,
    address: Partial<CamfilAddress>
  ): Observable<Partial<CamfilAddress>> {
    const params = new HttpParams().set('include', this.allIncludes.join());
    const body = {
      ...address,
    };

    return this.apiService
      .patch<Address>(`camfilrequisitions/${requisitionId}/addresses/${address.id}`, body, {
        params,
      })
      .pipe(map(() => address));
  }

  createCamfilRequisition(basketId: string): Observable<CamfilRequisition[]> {
    const params = new HttpParams().set('include', this.allIncludes.join());

    if (!basketId) {
      return throwError(() => new Error('createCamfilRequisition() called without basketId'));
    }

    const body = {
      basketID: basketId,
    };
    return this.apiService
      .post<CamfilRequisitionData>(`camfilrequisitions`, body, {
        params,
      })
      .pipe(map(payload => CamfilRequisitionMapper.fromListData(payload)));
  }

  approveSelectedLineItems(
    requisitionId: string,
    lineItemIds: string[],
    requisition: CamfilRequisition
  ): Observable<CamfilRequisition> {
    if (!requisitionId) {
      return throwError(() => new Error('updateLineItem() called without required requisition id'));
    }

    const params = new HttpParams().set('include', this.allIncludes.join());
    const body = {
      ...requisition,
      lineItemIds,
    };
    return this.apiService
      .patch(`camfilrequisitions/${requisitionId}/approve`, body, {
        params,
      })
      .pipe(map(() => requisition));
  }

  /**
   * Updates the requisition status. The current user is expected to have the approver permission.
   * @param requisitionId          Requisitions ids.
   * @param statusCode  The requisition approval status
   * @param comment     The approval comment
   * @returns           The updated requisition with all attributes. If the requisition is approved and the order is placed, also order data are returned as part of the requisition.
   */
  updateMultipleCamfilRequisitionStatus(
    requisitionIds: string[],
    statusCode: CamfilRequisitionStatus,
    approvalComment?: string
  ): Observable<CamfilRequisition> {
    if (!requisitionIds.length) {
      return throwError(() => new Error('updateCamfilRequisitionStatus() called without required ids'));
    }
    if (!statusCode) {
      return throwError(() => new Error('updateCamfilRequisitionStatus() called without required requisition status'));
    }

    const params = new HttpParams().set('include', this.allIncludes.join());
    const body = {
      name: 'string',
      type: 'ApprovalStatusChange',
      statusCode,
      approvalComment,
    };

    const type = statusCode === 'APPROVED' ? 'approve' : 'reject';

    // TODO: Change endpoint to one provided by BE that can change multiple requisition statuses
    const requisitionId = requisitionIds[0];

    return this.apiService
      .patch<CamfilRequisitionData>(`camfilrequisitions/${requisitionId}/${type}`, body, {
        params,
      })
      .pipe(map(payload => CamfilRequisitionMapper.fromData(payload)));
  }
}
