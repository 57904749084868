import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CamfilDialogFacade } from 'camfil-core/facades/camfil-dialog.facade';

import { ContentPageletView } from 'ish-core/models/content-view/content-view.model';
import { CMSComponent } from 'ish-shared/cms/models/cms-component/cms-component.model';

import { CamfilCmsLightboxArticleComponent } from '../camfil-cms-lightbox-article/camfil-cms-lightbox-article.component';

@Component({
  selector: 'camfil-cms-lightbox-link',
  templateUrl: './camfil-cms-lightbox-link.component.html',
  styleUrls: ['./camfil-cms-lightbox-link.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CamfilCmsLightboxLinkComponent implements CMSComponent {
  @Input() pagelet: ContentPageletView;

  constructor(private camfilDialogFacade: CamfilDialogFacade) {}

  open() {
    this.camfilDialogFacade.open(CamfilCmsLightboxArticleComponent, {
      data: this.pagelet.stringParam('Article'),
    });
  }
}
