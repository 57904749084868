import { Injectable } from '@angular/core';
import { concatLatestFrom } from '@ngrx/effects';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { CamfilCustomersFacade } from 'camfil-core/facades/camfil-customers.facade';
import { CamfilAddress } from 'camfil-models/camfil-address/camfil-address.model';
import { CamfilCustomer } from 'camfil-models/camfil-customer/camfil-customer.model';
import {
  createDestroy$,
  findOptionByValueProp,
  getOptions$,
  updateFieldGroupValues,
  valueChanges$,
} from 'camfil-shared/formly/utils/camfil-form-utils';
import { CamfilFormsService } from 'camfil-shared/formly/utils/forms.service';
import { takeUntil } from 'rxjs';
import { map } from 'rxjs/operators';

import { whenTruthy } from 'ish-core/utils/operators';
import { FieldLibraryConfiguration } from 'ish-shared/formly/field-library/configurations/field-library-configuration';

@Injectable()
export class DeliveryAddressIdConfiguration extends FieldLibraryConfiguration {
  constructor(private customersFacade: CamfilCustomersFacade, private translate: TranslateService) {
    super();
  }

  id = 'deliveryAddressIdFLC';

  getFieldConfig(): FormlyFieldConfig {
    const props = {
      label: 'camfil.modal.createOrder.order-form.select.address',
      valueProp: 'id',
      labelProp: CamfilFormsService.getAddresLabel,
    };
    return {
      props,
      key: 'deliveryAddress.id',
      type: 'camfil-select-field',
      expressions: {
        'props.disabled': (field: FormlyFieldConfig) => !field?.model?.customer,
        'props.hintStart': (field: FormlyFieldConfig) =>
          field?.model?.customer
            ? undefined
            : this.translate.instant('camfil.modal.createOrder.order-form.select.address.hint'),
      },
      hooks: {
        onInit: (field: FormlyFieldConfig) => {
          const destroy$ = createDestroy$();

          field.hooks.onDestroy = destroy$.onDestroy;

          const options$ = getOptions$<CamfilAddress, [CamfilCustomer]>({
            field,
            addEmptyOption: true,
            paths: ['customer'],
            observableFn: customer =>
              this.customersFacade.getDeliveryAddressesForCustomer$(customer?.id).pipe(
                whenTruthy(),
                map(s => CamfilFormsService.sortAddresOptions(s))
              ),
          });

          field.props.options = options$;

          valueChanges$(field)
            .pipe(
              concatLatestFrom(() => options$),
              takeUntil(destroy$.subject)
            )
            .subscribe(([newValue, options]) => {
              const currentOption = findOptionByValueProp(options, newValue, field.props.valueProp);

              updateFieldGroupValues({
                field,
                option: currentOption,
                path: 'deliveryAddress',
              });
            });
        },
      },
    };
  }
}
