import { createAction } from '@ngrx/store';
import { CamfilAddress } from 'camfil-models/camfil-address/camfil-address.model';
import { CamfilBasket } from 'camfil-models/camfil-basket/camfil-basket.model';
import { BucketFromEditMode, CamfilBucket } from 'camfil-models/camfil-bucket/camfil-bucket.model';

import { Attribute } from 'ish-core/models/attribute/attribute.model';
import { Basket } from 'ish-core/models/basket/basket.model';
import { httpError, payload } from 'ish-core/utils/ngrx-creators';

export const updateBucketEditMode = createAction(
  '[Camfil Edit Basket] Update Bucket in Edit Mode',
  payload<{
    basketId: string;
    addressId: string;
    camfilBucket: CamfilBucket;
    address?: Partial<CamfilAddress>;
  }>()
);

export const addProductToBasketEditMode = createAction(
  '[Camfil Edit Basket] Add Product To Basket in Edit Mode',
  payload<{
    sku: string;
    quantity: number;
    lineItemAttributes: Attribute[];
    camfilBucketId: string;
  }>()
);

export const deleteBasketItemInEditMode = createAction(
  '[Camfil Edit Basket]  Delete Basket Item in Edit Mode',
  payload<{ itemId: string; bucketId: string }>()
);

export const copyBasket = createAction('[Camfil Edit Basket] copy Basket In Edit Mode', payload<{ id: string }>());

export const copyBasketSuccess = createAction(
  '[Camfil Edit Basket] copy Basket In Edit Mode Success',
  payload<{ id: string; basket: Basket; camfilBuckets: CamfilBucket[]; addresses?: Partial<CamfilAddress[]> }>()
);

export const doubleCamfilBucketItemsQuantityInEditMode = createAction(
  '[Camfil Edit Basket] double Bucket Items Quantity in Edit Mode',
  payload<{ bucketId: string }>()
);

export const saveOnlyEmptyBuckets = createAction(
  '[Camfil Edit Basket] Save Only Empty Buckets',
  payload<{ basketId: string; emptyBuckets: CamfilBucket[] }>()
);

export const saveBuckets = createAction(
  '[Camfil Edit Basket] Save Buckets after apply',
  payload<{
    buckets: BucketFromEditMode[];
    basket: CamfilBasket;
  }>()
);

export const saveBucketsSuccess = createAction(
  '[Camfil Edit Basket] Save Buckets after apply Success',
  payload<{ basket: Basket }>()
);

export const saveBucketsFail = createAction('[Camfil Edit Basket] Save Buckets after apply Fail', httpError());

export const applyChangesFromEditMode = createAction('[Camfil Edit Basket] Apply Changes From Basket Edit Mode');
