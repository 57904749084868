import { registerLocaleData } from '@angular/common';
import localeDa from '@angular/common/locales/da';
import localeDe from '@angular/common/locales/de';
import localeEnGb from '@angular/common/locales/en-GB';
import localeFi from '@angular/common/locales/fi';
import localeFr from '@angular/common/locales/fr';
import localeIt from '@angular/common/locales/it';
import localeNb from '@angular/common/locales/nb';
import localeNl from '@angular/common/locales/nl';
import localeSv from '@angular/common/locales/sv';
import { CAMFIL_FALLBACK_LANG } from 'camfil-shared/camfil-constants';

import { Translations } from 'ish-core/utils/translate/translations.type';

/**
 * Register all locales data for the application.
 */
export function registerLocalesData() {
  [localeEnGb, localeFi, localeFr, localeSv, localeDe, localeIt, localeDa, localeNl, localeNb].forEach(
    registerLocaleData
  );
}

/**
 * Load translations for the given language code.
 * @param languageCode
 */
// eslint-disable-next-line complexity
export async function loadTranslations(languageCode = CAMFIL_FALLBACK_LANG) {
  const ishEnglishTranslations = await import('src/assets/i18n/en_US.json');
  const camfilEnglishTranslations = await import('projects/camfil-pwa/src/assets/i18n/en_GB.json');
  const fallbackTranslations = { ...ishEnglishTranslations, ...camfilEnglishTranslations };

  function mergeWithFallbackTranslationsInProductionMode(translations: Translations): Translations {
    return PRODUCTION_MODE ? { ...fallbackTranslations, ...translations } : { ...translations };
  }

  function mergeWithIshTranslations(ishTranslations: Translations, camfilTranslations: Translations): Translations {
    return { ...ishTranslations, ...camfilTranslations };
  }

  function useFallbackTranslationsIfNotAvailable(translations: Translations): Translations {
    if (!PRODUCTION_MODE) {
      console.warn(
        `Translations for language code "${languageCode}" are not available. We fallback to the default "en_GB" translations.`
      );
    }

    return translations || fallbackTranslations;
  }

  switch (languageCode) {
    case 'en_GB':
      return Promise.resolve(fallbackTranslations);
    case 'en_US':
      return Promise.resolve(fallbackTranslations);
    case 'de_DE':
      const ishGermanTranslations = await import('src/assets/i18n/de_DE.json');
      return import('projects/camfil-pwa/src/assets/i18n/de_DE.json')
        .then(camfilTranslations => mergeWithIshTranslations(ishGermanTranslations, camfilTranslations))
        .then(mergeWithFallbackTranslationsInProductionMode)
        .catch(useFallbackTranslationsIfNotAvailable);
    case 'fi_FI':
      return import('projects/camfil-pwa/src/assets/i18n/fi_FI.json')
        .then(mergeWithFallbackTranslationsInProductionMode)
        .catch(useFallbackTranslationsIfNotAvailable);
    case 'fr_FR':
      const ishFrenchTranslations = await import('src/assets/i18n/fr_FR.json');
      return import('projects/camfil-pwa/src/assets/i18n/fr_FR.json')
        .then(camfilTranslations => mergeWithIshTranslations(ishFrenchTranslations, camfilTranslations))
        .then(mergeWithFallbackTranslationsInProductionMode)
        .catch(useFallbackTranslationsIfNotAvailable);
    case 'it_IT':
      return import('projects/camfil-pwa/src/assets/i18n/it_IT.json')
        .then(mergeWithFallbackTranslationsInProductionMode)
        .catch(useFallbackTranslationsIfNotAvailable);
    case 'sv_SE':
      return import('projects/camfil-pwa/src/assets/i18n/sv_SE.json')
        .then(mergeWithFallbackTranslationsInProductionMode)
        .catch(useFallbackTranslationsIfNotAvailable);
    case 'da_DK':
      return import('projects/camfil-pwa/src/assets/i18n/da_DK.json')
        .then(mergeWithFallbackTranslationsInProductionMode)
        .catch(useFallbackTranslationsIfNotAvailable);
    case 'nl_NL':
      return import('projects/camfil-pwa/src/assets/i18n/nl_NL.json')
        .then(mergeWithFallbackTranslationsInProductionMode)
        .catch(useFallbackTranslationsIfNotAvailable);
    case 'nb_NO':
      return import('projects/camfil-pwa/src/assets/i18n/nb_NO.json')
        .then(mergeWithFallbackTranslationsInProductionMode)
        .catch(useFallbackTranslationsIfNotAvailable);
    default:
      return Promise.resolve(fallbackTranslations);
  }
}
