import {
  ChangeDetectionStrategy, Component, createNgModule, OnInit, ViewChild, ViewContainerRef,
  Injector,
  ComponentRef,
  
  
  
} from '@angular/core';



import type { BudgetWidgetComponent as OriginalComponent } from '../../components/budget-widget/budget-widget.component';

@Component({
  selector: 'ish-lazy-budget-widget',
  templateUrl: './lazy-budget-widget.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LazyBudgetWidgetComponent implements OnInit {
  /*
   * WARNING!
   *
   * This file was automatically generated!
   * It should be updated using:
   *
   * ng g lazy-component components/budget-widget/budget-widget.component.ts
   *
   */

  @ViewChild('anchor', { read: ViewContainerRef, static: true }) anchor: ViewContainerRef;



  private component: ComponentRef<OriginalComponent>;
  

  constructor(
    
    private injector: Injector
  ) {}

  async ngOnInit() {
    

      await this.renderComponent();

    
  }

  private async renderComponent() {
    const module = await import(`../../organization-management.module`).then(m => m.OrganizationManagementModule);

    const { BudgetWidgetComponent: originalComponent } = await import('../../components/budget-widget/budget-widget.component');

    const ngModuleRef = createNgModule(module, this.injector);

    this.component = this.anchor.createComponent(originalComponent, { ngModuleRef });
  
    this.component.changeDetectorRef.markForCheck();
  }


}
