import { PriceItemHelper } from 'ish-core/models/price-item/price-item.helper';
import { PriceItem } from 'ish-core/models/price-item/price-item.model';

import { CamfilPriceItem } from './camfil-price-item.model';

export class CamfilPriceItemHelper extends PriceItemHelper {
  static sumUp(priceItems: PriceItem[]): PriceItem {
    if (!priceItems?.length) {
      return;
    }

    const initialValue: PriceItem = {
      type: 'PriceItem',
      gross: 0,
      net: 0,
      currency: 'N/A',
    };

    return priceItems.filter(Boolean).reduce((acc, value) => {
      const gross = acc.gross + value.gross;
      const net = acc.net + value.net;
      const tax = gross - net;
      return { ...acc, ...value, gross, net, tax };
    }, initialValue);
  }

  static addTaxIfMissing(priceItem: CamfilPriceItem) {
    if (!priceItem) {
      return;
    }

    if (priceItem?.tax) {
      return priceItem;
    }

    const { gross, net } = priceItem;

    const tax = gross && net ? gross - net : undefined;

    return { tax, ...priceItem };
  }
}
