import { EntityState, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { CAMCARD_NEW_LOCALLY_ITEM_ID_PREFIX } from 'camfil-models/camfil-cam-card/cam-card.constants';
import { cloneDeep } from 'lodash-es';
import {
  CamCard,
  CamCardImportValidationResponse,
  CamCardItem,
  CamCardToBasketFeedback,
} from 'projects/camfil-pwa/src/app/models/camfil-cam-card/cam-card.model';

import { HttpError } from 'ish-core/models/http-error/http-error.model';
import { setLoadingOn, unsetLoadingAndErrorOn } from 'ish-core/utils/ngrx-creators';

import {
  addBasketToNewCamCard,
  addBasketToNewCamCardFail,
  addBasketToNewCamCardSuccess,
  addProductToCamCard,
  addProductToCamCardFail,
  addProductToCamCardSuccess,
  addProductToEditedCamCard,
  addProductsToCamCard,
  addProductsToCamCardFail,
  addProductsToCamCardSuccess,
  addToCartFromCamCards,
  addToCartFromCamCardsSuccess,
  checkCamCardsInBasketsForAllUsers,
  checkCamCardsInBasketsForAllUsersFail,
  checkCamCardsInBasketsForAllUsersSuccess,
  cleanUpCamCardsFeedback,
  clearVirtualCamCard,
  copyCamCard,
  copyCamCardFail,
  copyCamCardSuccess,
  createCamCard,
  createCamCardFail,
  createCamCardSuccess,
  createEditedSubCamCard,
  createVirtualCamCardSuccess,
  deleteCamCard,
  deleteCamCardFail,
  deleteCamCardSuccess,
  deleteSubCamCard,
  deleteSubCamCardFail,
  deleteSubCamCardSuccess,
  importCamCard,
  importCamCardFail,
  importCamCardSuccess,
  loadCamCard,
  loadCamCardFail,
  loadCamCardSuccess,
  loadCamCardsBasicList,
  loadCamCardsFail,
  loadCamCardsSuccess,
  moveCamCardSuccess,
  moveProductOnCamCardSuccess,
  removeItemFromCamCard,
  removeItemFromCamCardSuccess,
  selectCamCard,
  setEditModeSuccess,
  setStickyCamCardToolbar,
  turnOffEditMode,
  unselectCamCard,
  updateCamCard,
  updateCamCardAttribute,
  updateCamCardAttributeFail,
  updateCamCardAttributeSuccess,
  updateCamCardContactsSuccess,
  updateCamCardFail,
  updateCamCardProcessing,
  updateCamCardProduct,
  updateCamCardProductSuccess,
  updateCamCardSuccess,
  updateEditedCamCard,
  updateSubCamCard,
  updateSubCamCardFail,
  updateSubCamCardSuccess,
  validateCamCardImport,
  validateCamCardImportFail,
  validateCamCardImportSuccess,
} from './cam-cards.actions';

export interface CamCardState extends EntityState<CamCard> {
  initialized: boolean;
  adding: boolean;
  loading: boolean;
  processing: boolean;
  selected: string;
  error: HttpError;
  stickyToolbar: boolean;
  addProductSuccess: boolean;
  virtualCamCard: CamCard;
  camCardsInBasketsForAllUsers: {
    loading: boolean;
    list?: string[];
  };
  validationErrors: HttpError;
  validationResponse: CamCardImportValidationResponse[];
  editMode?: CamCard;
  allCustomerCamCardsFetched?: boolean;
  addToCartFeedback?: CamCardToBasketFeedback[];
  loadingSingleCamCards: string[];
}
export const camfilCamCardFeatureKey = 'camCards';
export const camCardAdapter = createEntityAdapter<CamCard>({
  selectId: camCard => camCard.id,
});

export const initialState: CamCardState = camCardAdapter.getInitialState({
  initialized: false,
  adding: false,
  loading: false,
  processing: false,
  selected: undefined,
  error: undefined,
  stickyToolbar: false,
  addProductSuccess: false,
  addresses: {},
  virtualCamCard: undefined,
  camCardsInBasketsForAllUsers: { loading: false },
  validationErrors: undefined,
  validationResponse: undefined,
  addToCartFeedback: [],
  loadingSingleCamCards: [],
});

/** Returns a new state with replaced camcard or subcamcard */
function setCCAfterInsertCamCard(
  state: CamCardState,
  rootCamCard: string,
  id: string,
  items: CamCard[] | CamCardItem[]
) {
  const prop = rootCamCard ? 'subCamCards' : 'camCardItems';
  const ccId = rootCamCard || id;
  return {
    ...state,
    entities: {
      ...state.entities,
      [ccId]: {
        ...state.entities[ccId],
        [prop]: items,
      },
    },
  };
}

export const camCardReducer = createReducer(
  initialState,
  setLoadingOn(
    addBasketToNewCamCard,
    addProductToCamCard,
    addProductsToCamCard,
    copyCamCard,
    createCamCard,
    deleteCamCard,
    importCamCard,
    loadCamCard,
    loadCamCardsBasicList,
    updateCamCard,
    updateCamCardAttribute,
    updateCamCardProduct,
    updateSubCamCard,
    validateCamCardImport
  ),
  unsetLoadingAndErrorOn(
    addBasketToNewCamCardSuccess,
    addProductToCamCardSuccess,
    addProductsToCamCardSuccess,
    copyCamCardSuccess,
    createCamCardSuccess,
    deleteCamCardSuccess,
    loadCamCardSuccess,
    loadCamCardsSuccess,
    updateCamCardAttributeSuccess,
    updateCamCardProductSuccess,
    updateCamCardSuccess,
    updateSubCamCardSuccess,
    validateCamCardImportSuccess
  ),
  on(
    addBasketToNewCamCardFail,
    addProductToCamCardFail,
    addProductsToCamCardFail,
    checkCamCardsInBasketsForAllUsersFail,
    copyCamCardFail,
    createCamCardFail,
    deleteCamCardFail,
    deleteSubCamCardFail,
    importCamCardFail,
    loadCamCardFail,
    loadCamCardsFail,
    updateCamCardAttributeFail,
    updateCamCardFail,
    updateSubCamCardFail,
    validateCamCardImportFail,
    (state, action): CamCardState => {
      const { error } = action.payload;
      return {
        ...state,
        adding: false,
        loading: false,
        processing: false,
        error,
      };
    }
  ),
  on(
    validateCamCardImport,
    importCamCardSuccess,
    (state): CamCardState => ({
      ...state,
      validationResponse: undefined,
    })
  ),
  on(loadCamCardsSuccess, (state, action): CamCardState => {
    const { camCards, includeAllCustomerCamCards } = action.payload;
    const allCustomerCamCardsFetched = state.allCustomerCamCardsFetched || includeAllCustomerCamCards;
    return camCardAdapter.upsertMany(camCards, {
      ...state,
      initialized: true,
      allCustomerCamCardsFetched,
    });
  }),
  on(
    loadCamCardSuccess,
    addBasketToNewCamCardSuccess,
    createCamCardSuccess,
    updateCamCardSuccess,
    deleteSubCamCardSuccess,
    moveCamCardSuccess,
    addProductToCamCardSuccess,
    addProductsToCamCardSuccess,
    removeItemFromCamCardSuccess,
    (state, action): CamCardState => {
      const { camCard } = action.payload;
      return camCardAdapter.upsertOne(camCard, {
        ...state,
        loading: false,
        processing: false,
        editMode: undefined,
      });
    }
  ),
  on(
    loadCamCard,
    (state, { payload }): CamCardState => ({
      ...state,
      loadingSingleCamCards: [...state.loadingSingleCamCards, payload.camCardId],
    })
  ),
  on(loadCamCardSuccess, (state, { payload }): CamCardState => {
    const { id } = payload.camCard;
    const loadingSingleCamCards = state?.loadingSingleCamCards.filter(camCardId => id !== camCardId);
    return {
      ...state,
      loadingSingleCamCards,
    };
  }),
  on(
    updateSubCamCardSuccess,
    (state, action): CamCardState =>
      camCardAdapter.upsertOne(action.payload.camCard, {
        ...state,
        loading: false,
      })
  ),
  on(updateCamCardAttributeSuccess, (state, action): CamCardState => {
    const { camCard } = action.payload;
    return camCardAdapter.updateOne(
      { id: camCard.id, changes: camCard },
      {
        ...state,
        loading: false,
      }
    );
  }),
  on(
    addToCartFromCamCards,
    (state): CamCardState => ({
      ...state,
      adding: true,
    })
  ),

  on(
    addProductToCamCard,
    addToCartFromCamCards,
    cleanUpCamCardsFeedback,
    (state): CamCardState => ({
      ...state,
      addProductSuccess: false,
      addToCartFeedback: [],
    })
  ),
  on(
    addProductToCamCardSuccess,
    addProductsToCamCardSuccess,
    (state): CamCardState => ({
      ...state,
      addProductSuccess: true,
    })
  ),
  on(addToCartFromCamCardsSuccess, (state, action): CamCardState => {
    const addToCartFeedback = [...state.addToCartFeedback, ...(action?.payload?.infos ?? [])];
    return {
      ...state,
      addProductSuccess: true,
      addToCartFeedback,
      adding: false,
    };
  }),
  on(
    addProductToCamCardFail,
    (state): CamCardState => ({
      ...state,
      addProductSuccess: false,
    })
  ),
  on(deleteCamCardSuccess, (state, action): CamCardState => {
    const { camCardId } = action.payload;
    return camCardAdapter.removeOne(camCardId, {
      ...state,
      loading: false,
    });
  }),
  on(updateCamCardProcessing, (state, action): CamCardState => {
    const { processing } = action.payload;
    return {
      ...state,
      processing,
    };
  }),
  on(moveProductOnCamCardSuccess, (state, action): CamCardState => {
    const { camCard } = action.payload;

    return camCardAdapter.upsertOne(camCard, {
      ...state,
      processing: false,
      loading: false,
    });
  }),
  on(updateCamCardProductSuccess, (state, action): CamCardState => {
    const { rootCamCard, camCardId, camCardItem } = action.payload;
    const entities = state.entities;
    const camCard = entities[rootCamCard || camCardId];

    let subCamCards = cloneDeep(camCard.subCamCards);
    let camCardItems = cloneDeep(camCard.camCardItems);

    const item = camCardItem?.measurement.hasOwnProperty('valid')
      ? camCardItem
      : {
          ...camCardItem,
          measurement: { ...camCardItem.measurement, valid: true },
        };

    if (rootCamCard) {
      subCamCards = camCard.subCamCards.map(sub =>
        sub.id === camCardId
          ? {
              ...sub,
              camCardItems: sub.camCardItems.map(it => (it.id === camCardItem.id ? item : it)),
            }
          : sub
      );
    } else {
      camCardItems = camCard.camCardItems.map(it => (it.id === camCardItem.id ? item : it));
    }

    const camCardChanged = { ...camCard, camCardItems, subCamCards };

    return camCardAdapter.upsertOne(camCardChanged, {
      ...state,
      processing: false,
      loading: false,
    });
  }),

  on(updateCamCardContactsSuccess, (state, action): CamCardState => {
    const { camCardId, contacts } = action.payload;
    return camCardAdapter.updateOne(
      {
        id: camCardId,
        changes: {
          contacts,
        },
      },
      state
    );
  }),
  on(
    unselectCamCard,
    (state): CamCardState => ({
      ...state,
      selected: undefined as string,
    })
  ),
  on(selectCamCard, (state, action): CamCardState => {
    const { camCardId } = action.payload;

    return {
      ...state,
      selected: camCardId,
    };
  }),
  on(
    setStickyCamCardToolbar,
    (state, action): CamCardState => ({
      ...state,
      stickyToolbar: action.payload.sticky,
    })
  ),
  on(
    createVirtualCamCardSuccess,
    (state, action): CamCardState => ({
      ...state,
      virtualCamCard: action.payload.camCard,
    })
  ),
  on(
    clearVirtualCamCard,
    (state): CamCardState => ({
      ...state,
      virtualCamCard: undefined as CamCard,
    })
  ),
  on(validateCamCardImportSuccess, (state, action): CamCardState => {
    const { validationResponse } = action.payload;
    return {
      ...state,
      loading: false,
      validationResponse,
    };
  }),
  on(
    checkCamCardsInBasketsForAllUsers,
    (state): CamCardState => ({
      ...state,
      adding: true,
      camCardsInBasketsForAllUsers: { loading: true },
    })
  ),
  on(checkCamCardsInBasketsForAllUsersSuccess, (state, action): CamCardState => {
    const { camCardsId } = action.payload;
    return {
      ...state,
      camCardsInBasketsForAllUsers: { loading: false, list: camCardsId },
    };
  }),
  // todo marcin
  // on(addItemsToBasketFromCamCard, (state): CamCardState => ({
  //   ...state,
  //   adding: true,
  // })),
  // on(addItemsToBasketFromCamCardSuccess, updateBucketFail, updateBasketFail, (state): CamCardState => ({
  //   ...state,
  //   adding: false,
  // })),
  on(
    setEditModeSuccess,
    (state, action): CamCardState => ({
      ...state,
      editMode: action.payload.camCard,
    })
  ),
  on(turnOffEditMode, (state): CamCardState => {
    const camCard = { ...state.editMode };
    return camCardAdapter.upsertOne(camCard, {
      ...state,
      loading: false,
      editMode: undefined,
    });
  }),
  on(
    updateEditedCamCard,
    (state, action): CamCardState =>
      camCardAdapter.upsertOne(action.payload.camCard, {
        ...state,
      })
  ),
  on(removeItemFromCamCard, (state: CamCardState, { payload }) => {
    const { camCardId, camCardItemId, rootCamCard } = payload;
    const cc = state.entities[rootCamCard || camCardId];
    let items: CamCardItem[] | CamCard[] = cc?.camCardItems?.filter(item => item.id !== camCardItemId);

    if (rootCamCard) {
      items = cc.subCamCards.map(sub =>
        sub.id === camCardId
          ? {
              ...sub,
              camCardItems: sub.camCardItems.filter(item => item.id !== camCardItemId),
              totalLineItemsCount: sub?.totalLineItemsCount || sub.totalLineItemsCount - 1,
            }
          : sub
      );
    }
    return setCCAfterInsertCamCard(state, rootCamCard, camCardId, items);
  }),
  on(addProductToEditedCamCard, (state, action): CamCardState => {
    const { camCardId, sku, quantity, comment, measurement } = action.payload;
    const position =
      (state.entities[camCardId].camCardItems?.reduce((acc, { position }) => (acc >= position ? acc : position), 0) ||
        0) + 1000;
    const newItem: CamCardItem = {
      comment,
      id: `${CAMCARD_NEW_LOCALLY_ITEM_ID_PREFIX}${Date.now()}`,
      measurement,
      position,
      product: {
        sku,
      },
      quantity,
      lastDeliveryDate: '--',
    };
    const camCardItems = [...state.entities[camCardId]?.camCardItems, newItem];

    return {
      ...state,
      entities: {
        ...state.entities,
        [camCardId]: { ...state.entities[camCardId], camCardItems },
      },
    };
  }),
  on(deleteSubCamCard, (state: CamCardState, { payload }) => {
    const camCard: CamCard = { ...state.entities[payload.rootId] };
    const subCamCards = camCard.subCamCards.filter(sub => sub.id !== payload.id);
    const changes: CamCard = { ...camCard, subCamCards };
    return camCardAdapter.updateOne({ id: camCard.id, changes }, { ...state });
  }),
  on(createEditedSubCamCard, (state, action): CamCardState => {
    const { subCamCard, rootCamCardId } = action.payload;
    const cc = {
      ...subCamCard,
      id: `newSub${Date.now()}`,
      rootCamCard: rootCamCardId,
      camCardItems: [] as CamCardItem[],
    };
    return {
      ...state,
      entities: {
        ...state.entities,
        [rootCamCardId]: {
          ...state.entities[rootCamCardId],
          subCamCards: [...state.entities[rootCamCardId].subCamCards, cc],
        },
      },
    };
  })
);
