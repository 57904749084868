<camfil-filter-collapsable
  *ngIf="currentMeasurementMode$ | async as currentMeasurementMode"
  title="{{ 'camfil.navigation.filter.measurements.title' | translate }}"
>
  <div *ngIf="measurementModes$ | async as measurementModes" class="px-4">
    <nav mat-tab-nav-bar [tabPanel]="tabPanel">
      <a
        mat-tab-link
        tabindex="0"
        *ngFor="let mode of measurementModes"
        (keyup)="applyMeasurementMode(mode)"
        (click)="applyMeasurementMode(mode)"
        [active]="currentMeasurementMode === mode"
      >
        <!-- keep-localization-pattern: ^camfil\.navigation\.filter\.measurementMode\..* -->
        {{ 'camfil.navigation.filter.measurementMode.' + mode | translate }}
      </a>
    </nav>
    <mat-tab-nav-panel #tabPanel>
      <form [formGroup]="measurementsForm.instance" (ngSubmit)="submitForm()" class="py-2">
        <formly-form
          [form]="measurementsForm.instance"
          [model]="measurementsForm.model$ | async"
          [fields]="measurementsForm.fields$ | async"
        />
      </form>
      <p class="w-100 text-center">
        <small class="text-muted">
          <ng-container [ngSwitch]="currentMeasurementMode">
            <ng-container *ngSwitchCase="'standard'">
              {{
                'camfil.navigation.filter.measurements.standard.info'
                  | translate : { '0': tolerance2D, '1': tolerance3D }
              }}
            </ng-container>
            <ng-container *ngSwitchCase="'diameter'">
              {{
                'camfil.navigation.filter.measurements.diameter.info'
                  | translate : { '0': tolerance2D, '1': tolerance3D }
              }}
            </ng-container>
          </ng-container>
        </small>
      </p>
      <p>
        <ish-content-include includeId="camfil.include.common.measurementsFilter.pagelet2-Include" />
      </p>
    </mat-tab-nav-panel>
  </div>
</camfil-filter-collapsable>
