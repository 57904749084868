import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { CamfilConfigurationService } from 'camfil-core/services/configuration/camfil-configuration.service';
import {
  getBasketSurchargeOrder,
  getBucketSurchargeOrder,
  getCamfilMaxLengthByName,
  getCamfilMinLengthByName,
  getContinueShoppingUrl,
  getCountryCodeRegExp,
  getMaxLength,
  getUiConfig,
  getZipCodeRegExp,
  selectAvailableCountryCodes,
  selectCountryCode,
  selectCountryCode3,
  selectExceptionWindows,
  selectIcmChannel,
  selectMaintenanceWindows,
  selectTimeZone,
} from 'camfil-core/store/camfil-configuration/camfil-configuration.selectors';
import {
  CamfilChannelSetting,
  CamfilMaxLengthKey,
  CamfilMinLengthKey,
} from 'camfil-models/camfil-configuration/camfil-configuration.model';

@Injectable({ providedIn: 'root' })
export class CamfilConfigurationFacade {
  constructor(private store: Store, private configurationService: CamfilConfigurationService) {}

  icmChannel$ = this.store.pipe(select(selectIcmChannel));

  availableCountryCodes$ = this.store.pipe(select(selectAvailableCountryCodes));

  timeZone$ = this.store.pipe(select(selectTimeZone));

  maintenanceWindows$ = this.store.pipe(select(selectMaintenanceWindows));

  exceptionWindows$ = this.store.pipe(select(selectExceptionWindows));

  countryCode$ = this.store.pipe(select(selectCountryCode));

  countryCode3$ = this.store.pipe(select(selectCountryCode3));

  continueShoppingUrl$ = this.store.pipe(select(getContinueShoppingUrl));

  zipCodeRegExp$ = this.store.pipe(select(getZipCodeRegExp));

  countryCodeRegExp$ = this.store.pipe(select(getCountryCodeRegExp));

  bucketSurchargeOrder$ = this.store.pipe(select(getBucketSurchargeOrder));

  basketSurchargeOrder$ = this.store.pipe(select(getBasketSurchargeOrder));

  uiConfig$ = this.store.pipe(select(getUiConfig));

  allMaxLengths$ = this.store.pipe(select(getMaxLength));

  isINT$ = this.configurationService.isINT$;

  isUAT$ = this.configurationService.isUAT$;

  isPRD$ = this.configurationService.isPRD$;

  isMaintenanceMode$ = this.configurationService.isMaintenanceMode$;

  isEnabled$(setting: CamfilChannelSetting) {
    return this.configurationService.isEnabled$(setting);
  }

  minLength$(name: CamfilMinLengthKey) {
    return this.store.pipe(select(getCamfilMinLengthByName(name)));
  }

  maxLength$(name: CamfilMaxLengthKey) {
    return this.store.pipe(select(getCamfilMaxLengthByName(name)));
  }
}
