import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { CamfilProductsService } from 'camfil-core/services/camfil-products/camfil-products.service';
import { of } from 'rxjs';
import { concatMap, filter, map, mergeMap } from 'rxjs/operators';

import { mapErrorToAction, mapToPayloadProperty } from 'ish-core/utils/operators';

import { loadCategoryLinks, loadCategoryLinksFail, loadCategoryLinksSuccess } from './categories.actions';
import { getCategoryLinks } from './categories.selectors';

@Injectable()
export class CamfilCategoriesEffects {
  constructor(private actions$: Actions, private store: Store, private camfilProductsService: CamfilProductsService) {}

  loadCategoryLinks$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadCategoryLinks),
      mapToPayloadProperty('key'),
      filter(key => Boolean(key)),
      mergeMap(id => of(id).pipe(concatLatestFrom(() => this.store.pipe(select(getCategoryLinks(id)))))),
      filter(([, list]) => !list),
      concatMap(([key]) =>
        this.camfilProductsService.loadCategoryLinks(key).pipe(
          map(links => loadCategoryLinksSuccess({ key, links })),
          mapErrorToAction(loadCategoryLinksFail)
        )
      )
    )
  );
}
