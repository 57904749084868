import { Injectable } from '@angular/core';
import { camelCaseKeys } from 'camfil-core/utils/functions';

import { UnitFilterData } from './unit-filter.interface';
import { UnitFilter, UnitFilterType, UnitSlotFilter } from './unit-filter.model';

@Injectable({ providedIn: 'root' })
export class UnitFilterMapper {
  static mapByManufacturerId(unitFilter: UnitFilter, manufacturerId: string): UnitFilter {
    return { ...unitFilter, manufacturerId };
  }

  static fromData(unitFilterData: UnitFilterData): UnitFilter {
    if (!unitFilterData) {
      throw new Error(`unitFilterData is required`);
    }

    const unitFilter = UnitFilterMapper.parseData(unitFilterData);

    if (unitFilter?.exhaustSlotFilters) {
      unitFilter.exhaustSlotFilters = UnitFilterMapper.lowerCaseUnitSlotFilterType(unitFilter.exhaustSlotFilters);
    }

    if (unitFilter?.supplySlotFilters) {
      unitFilter.supplySlotFilters = UnitFilterMapper.lowerCaseUnitSlotFilterType(unitFilter.supplySlotFilters);
    }

    return unitFilter;
  }

  static fromListData(unitFiltersData: UnitFilterData[], sortBy?: keyof UnitFilterData): UnitFilter[] {
    if (!unitFiltersData) {
      throw new Error('unitFiltersData is required');
    }

    let output = unitFiltersData;

    if (!Array.isArray(output)) {
      output = [output];
    }

    if (sortBy) {
      output.sort((a, b) => String(a[sortBy])?.localeCompare(String(b[sortBy]), undefined, { sensitivity: 'base' }));
    }

    return output.map(data => UnitFilterMapper.fromData(data));
  }

  private static lowerCaseUnitSlotFilterType(usf: UnitSlotFilter[]) {
    return usf.map(uf => ({ ...uf, type: uf.type.toString().toLowerCase() as UnitFilterType }));
  }

  private static parseData(unitFilterData: UnitFilterData) {
    return camelCaseKeys(unitFilterData, { deep: true }) as unknown as UnitFilter;
  }
}
