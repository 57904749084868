<ng-container>
  <div camfilDialogTitle class="d-flex align-items-center justify-content-center modal-header">
    <h2>{{ 'camfil.modal.place_reorder_fail.text' | translate }}</h2>

    <p *ngIf="possibleToOrder">{{ 'camfil.modal.place_reorder_fail.description' | translate }}</p>
  </div>
  <div class="form-content__container" camfilDialogContent separated>
    <div class="unavailable-products__container" *ngIf="dialogData">
      <mat-chip-listbox>
        <mat-chip *ngFor="let item of dialogData?.unavailableProducts">{{ item.name }}</mat-chip>
      </mat-chip-listbox>
    </div>
    <mat-divider *ngIf="dialogData?.unavailableProducts?.length && dialogData?.missingSkus?.length" />
    <div class="missing-skus__container" *ngIf="dialogData?.missingSkus?.length">
      <mat-chip-listbox>
        <mat-chip color="warn" *ngFor="let item of dialogData?.missingSkus">{{ item }}</mat-chip>
      </mat-chip-listbox>
    </div>
  </div>

  <div camfilDialogActions>
    <button
      mat-raised-button
      type="button"
      class="secondary-button create-order__button"
      (click)="matDialogRef.close()"
    >
      {{ 'camfil.modal.addNewProduct.button.cancel' | translate }}
    </button>
    <button *ngIf="possibleToOrder" mat-raised-button class="btn btn-link" (click)="confirmReOrder()">
      {{ 'camfil.modal.place_reorder_fail.cta' | translate }}
    </button>
  </div>
  <p class="d-flex align-items-center justify-content-center">
    {{ 'camfil.modal.place_reorder_fail.footer' | translate }}
  </p>
</ng-container>
