import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';

import { CategoryHelper } from 'ish-core/models/category/category.helper';
import { Link } from 'ish-core/models/link/link.model';
import { ProductLinksDictionary } from 'ish-core/models/product-links/product-links.model';
import { ProductMapper } from 'ish-core/models/product/product.mapper';
import { unpackEnvelope } from 'ish-core/services/api/api.service';
import { ProductsService } from 'ish-core/services/products/products.service';
import { encodeResourceID } from 'ish-core/utils/url-resource-ids';

/* eslint-disable @typescript-eslint/dot-notation */

@Injectable({ providedIn: 'root' })
export class CamfilProductsService extends ProductsService {
  // Note: Default sortKey is Deliverydays-asc.
  // @see #63003
  private readonly DEFAULT_SORT_KEY = 'Deliverydays-asc';
  private sanitizeSku(sku: string): string {
    // Note: The ICM API expects the SKU to be double encoded since in Italy the SKU contains a slash.
    // @see #63128
    try {
      return encodeResourceID(sku);
    } catch (e) {
      // Handle error
      return sku;
    }
  }

  private sanitizeSearchTerm(searchTerm: string): string {
    // Note: The ICM API does not find any results when searchTerm contains a slash so we cut it off.
    // @see #63128
    try {
      return searchTerm?.toLowerCase()?.split('/')?.[0];
    } catch (e) {
      // Handle error
      return searchTerm;
    }
  }

  private mapSortKey(sortKey: undefined | string): string {
    return sortKey === undefined || sortKey === 'default' ? this.DEFAULT_SORT_KEY : sortKey;
  }

  override getProduct(sku: string) {
    const sanitizedSku = this.sanitizeSku(sku);
    return super.getProduct(sanitizedSku);
  }

  override getProductVariations(sku: string) {
    const sanitizedSku = this.sanitizeSku(sku);
    return super.getProductVariations(sanitizedSku);
  }

  override getProductBundles(sku: string) {
    const sanitizedSku = this.sanitizeSku(sku);
    return super.getProductBundles(sanitizedSku);
  }

  override getRetailSetParts(sku: string) {
    const sanitizedSku = this.sanitizeSku(sku);
    return super.getRetailSetParts(sanitizedSku);
  }

  override getProductLinks(sku: string) {
    const sanitizedSku = this.sanitizeSku(sku);
    return super.getProductLinks(sanitizedSku);
  }

  override getCategoryProducts(categoryUniqueId: string, amount: number, sortKey?: string, offset = 0) {
    return super.getCategoryProducts(categoryUniqueId, amount, this.mapSortKey(sortKey), offset);
  }

  override searchProducts(searchTerm: string, amount: number, sortKey?: string, offset?: number) {
    const sanitizedSearchTerm = this.sanitizeSearchTerm(searchTerm);
    return super.searchProducts(sanitizedSearchTerm, amount, this.mapSortKey(sortKey), offset);
  }

  // by getProductLinks - ish-core\services\products\products.service
  loadCategoryLinks(key: string): Observable<ProductLinksDictionary> {
    return this['apiService'].get(`categories/${key}/links`, { sendSPGID: true }).pipe(
      unpackEnvelope<{ linkType: string; categoryLinks: Link[]; productLinks: Link[] }>(),
      map(links =>
        links.reduce(
          (acc, link) => ({
            ...acc,
            [link.linkType]: {
              products: !link.productLinks
                ? []
                : link.productLinks.map(pl => pl.uri).map(ProductMapper.parseSkuFromURI),
              categories: !link.categoryLinks
                ? []
                : link.categoryLinks.map(cl =>
                    cl.uri.split('/categories/')[1].replace('/', CategoryHelper.uniqueIdSeparator)
                  ),
            },
          }),
          {}
        )
      )
    );
  }
}
