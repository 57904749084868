import { Pipe, PipeTransform } from '@angular/core';
import slugify from 'slugify';

export function formatCamfilSlug(value: string, prefix: string): string {
  return `${slugify(prefix, '.')}.${slugify(value, '.')?.toLowerCase()}`;
}

@Pipe({ name: 'slugify', pure: true })
export class CamfilSlugifyPipe implements PipeTransform {
  transform(value: string, prefix: string): string {
    return formatCamfilSlug(value, prefix);
  }
}
