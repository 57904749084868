import { BasketSurchargeTypes } from 'camfil-models/camfil-basket-surcharge/camfil-basket-surcharge.types';
import { CamfilChannelConfiguration } from 'camfil-models/camfil-configuration/camfil-configuration.model';

const camfilConfiguration: CamfilChannelConfiguration = {
  allowQuotes: true,
  allowToSelectGoodsAcceptanceTimes: true,
  basketSurchargeOrder: [
    BasketSurchargeTypes.ExtraFreightCostRule,
    BasketSurchargeTypes.OrderTopUpShippingRule,
    BasketSurchargeTypes.BoxLabelShippingRule,
    BasketSurchargeTypes.SurchargeGasoil,
    BasketSurchargeTypes.TaxEnergy,
  ],
  bucketSurchargeOrder: [
    BasketSurchargeTypes.ExtraFreightCostRule,
    BasketSurchargeTypes.OrderTopUpShippingRule,
    BasketSurchargeTypes.BoxLabelShippingRule,
    BasketSurchargeTypes.SurchargeGasoil,
    BasketSurchargeTypes.TaxEnergy,
  ],
  continueShoppingUrl: '/home',
  timeZone: 'Europe/Paris',
  maintenanceWindows: [
    {
      startDateTimeUTC: '2023-12-15T14:00:00.000Z',
      endDateTimeUTC: '2024-01-03T23:00:00.000Z',
    },
  ],
  exceptionWindows: [
    {
      startDateTimeUTC: '2023-12-27T08:00:00.000Z',
      endDateTimeUTC: '2023-12-27T17:00:00.000Z',
    },
  ],
  countryCode: 'FR',
  countryCode3: 'FRA',
  countryCodeRegExp: '^\\w{2}$',
  displayFeesInSpecialOrderOnCheckoutSummary: true,
  filterDocsByLanguage: false,
  icmChannel: 'Camfil-CamfilFR-Site',
  includeAllCustomerCamCards: false,
  maxLength: {
    orderMark: 60,
    invoiceLabel: 60,
    company: 60,
    boxLabel: 60,
    name: 40,
    camCardTitle: 60,
    customerNote: 150,
    goodsAcceptanceNote: 60,
    addressLineOne: 50,
    addressLineTwo: 200,
    phoneNumber: 30,
    customerServiceNote: 500,
  },
  minLength: {
    searchTerm: 3,
  },
  preserveSearchFilters: false,
  preventCamCardERPIdValidation: true,
  showAHUComingSoon: true,
  showAddToCamCardButtonForNonLoggedInUser: false,
  showAddToCartButtonForNonLoggedInUser: true,
  showAllDocsType: true,
  showCountryFieldOnAddressForms: true,
  showDeliveryIntervalOnCCDetailPage: true,
  showDutiesAndSurchargesTotalInBasketSummary: true,
  showPricesForNonLoggedInUser: true,
  showPricesInCamCards: true,
  showPrintButtonOnQuotations: true,
  showSubTotalInBasketSummary: false,
  showTitleFieldInRegisterForm: true,
  showTotalWithoutTaxInBasketSummary: true,
  showTotalWithoutTaxInBucketSummary: true,
  showWarningMessageForPartialDelivery: true,
  showWarningWhenSystemCouldNotCalculateVolumeOrWeight: true,
  use2ndAddressLineInCamCardForm: true,
  use2ndAddressLineInOrderForm: true,
  useMaterialTypography: true,
  zipCodeRegExp: '^\\d{5}$',
};

export default camfilConfiguration;
