<select
  [formControl]="formControl"
  [compareWith]="props.compareWith ? props.compareWith : defaultOptions.props.compareWith"
  [formlyAttributes]="field"
  class="form-control"
  [ngClass]="props.inputClass"
  [attr.data-testing-id]="field.key"
  [attr.aria-label]="props.ariaLabel"
>
  <ng-container *ngIf="selectOptions && selectOptions | formlySelectOptions : field | async as opts">
    <ng-container *ngFor="let opt of opts">
      <option [value]="opt.value" [disabled]="opt.disabled">
        {{ opt.label }}
      </option>
    </ng-container>
  </ng-container>
</select>
