import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { CamfilViewType } from 'camfil-models/camfil-viewtype/camfil-viewtype.types';
import { Observable } from 'rxjs';

import { ShoppingFacade } from 'ish-core/facades/shopping.facade';
import { Category } from 'ish-core/models/category/category.model';

@Component({
  selector: 'camfil-product-list',
  templateUrl: './camfil-product-list.component.html',
  styleUrls: ['./camfil-product-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CamfilProductListComponent implements OnInit {
  @Input() products: undefined | string[];
  @Input() category: undefined | Category;
  @Input() viewType: undefined | CamfilViewType = 'simple';
  /* List with limit does not display loader!
    It's only used in one case of search box, where loader is provided externally.
    It's not the best solution but there is not time for that. */
  @Input() limit: undefined | number;

  loading$: Observable<boolean>;

  constructor(private shoppingFacade: ShoppingFacade) {}

  ngOnInit(): void {
    /* There is kind of bug, because this state selector only detects any loading.
       It's problem when we have search result in box and product listing on one page. */
    this.loading$ = this.shoppingFacade.productListingLoading$;
  }
}
