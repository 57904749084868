import {
  ChangeDetectionStrategy, Component, createNgModule, OnInit, ViewChild, ViewContainerRef,
  Injector,
  ComponentRef,
  Input, OnChanges, 
  inject, DestroyRef,
  
} from '@angular/core';


  import { FeatureToggleService } from 'ish-core/feature-toggle.module';
  import { takeUntilDestroyed } from '@angular/core/rxjs-interop';


import type { ProductAddToCompareComponent as OriginalComponent } from '../../shared/product-add-to-compare/product-add-to-compare.component';

@Component({
  selector: 'ish-lazy-product-add-to-compare',
  templateUrl: './lazy-product-add-to-compare.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LazyProductAddToCompareComponent implements OnInit , OnChanges {
  /*
   * WARNING!
   *
   * This file was automatically generated!
   * It should be updated using:
   *
   * ng g lazy-component extensions/compare/shared/product-add-to-compare/product-add-to-compare.component.ts
   *
   */

  @ViewChild('anchor', { read: ViewContainerRef, static: true }) anchor: ViewContainerRef;


  @Input() displayType: OriginalComponent['displayType'];

  @Input() cssClass: OriginalComponent['cssClass'];


  private component: ComponentRef<OriginalComponent>;
   private destroyRef = inject(DestroyRef);

  constructor(
    private featureToggleService: FeatureToggleService,
    private injector: Injector
  ) {}

   ngOnInit() {
     this.featureToggleService.enabled$('compare').pipe(takeUntilDestroyed(this.destroyRef)).subscribe(async enabled => {
      if (enabled) {
    

      await this.renderComponent();

    
        } else {
          this.anchor.clear();
        }
      })
    
  }

  private async renderComponent() {
    const module = await import(`../../compare.module`).then(m => m.CompareModule);

    const { ProductAddToCompareComponent: originalComponent } = await import('../../shared/product-add-to-compare/product-add-to-compare.component');

    const ngModuleRef = createNgModule(module, this.injector);

    this.component = this.anchor.createComponent(originalComponent, { ngModuleRef });
  
    this.ngOnChanges(
      
    );
  
    this.component.changeDetectorRef.markForCheck();
  }


  ngOnChanges() {
    if (this.component) {
      
        this.component.instance.displayType = this.displayType;
      
        this.component.instance.cssClass = this.cssClass;
      

      
    }
  }

}
