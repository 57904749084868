<ng-container *ngIf="isVisible$ | async">
  <form [formGroup]="deliveryIntervalForm">
    <mat-form-field appearance="fill" class="interval-form-field__container form-field ml-md-2">
      <input
        (click)="$event.stopPropagation()"
        (change)="applyDeliveryInterval()"
        (keyup)="validateDeliveryInterval()"
        (focus)="onFocus()"
        [formControl]="deliveryFormControl"
        [matAutocomplete]="filteredIntervals"
        matInput
        name="deliveryInterval"
        type="text"
        class="overflow-hidden"
      />
      <mat-icon
        class="mat-icon notranslate mat-icon-no-color"
        svgIcon="delivery-time"
        matSuffix
        [title]="'camfil.account.cam_card.add_to_form.delivery_interval' | translate"
      />

      <mat-autocomplete
        #filteredIntervals="matAutocomplete"
        (optionSelected)="applyDeliveryInterval()"
        autoActiveFirstOption
      >
        <mat-option *ngFor="let option of deliveryIntervalFilteredOptions$ | async" [value]="option">
          {{ option }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </form>
</ng-container>
