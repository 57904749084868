import { Injectable } from '@angular/core';
import { FormlyFieldConfig } from '@ngx-formly/core';

import { FieldLibraryConfiguration } from 'ish-shared/formly/field-library/configurations/field-library-configuration';

@Injectable()
export class MeasurementDepthConfiguration extends FieldLibraryConfiguration {
  id = 'measurementDepthFLC';

  getFieldConfig(): FormlyFieldConfig {
    return {
      key: 'depth',
      type: 'camfil-text-input-field',
      props: {
        label: 'camfil.modal.addToCamcard.depth.label',
        required: false,
        type: 'number',
      },
    };
  }
}
