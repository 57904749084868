<ng-container>
  <div class="d-flex align-items-center justify-content-center form-header">
    <mat-icon [svgIcon]="'cart'" />
    <h2 camfilDialogTitle>
      {{ formHeader$ | async | translate }}
    </h2>
  </div>
  <div class="form-content__container" camfilDialogContent separated>
    <form [formGroup]="form" (ngSubmit)="submitForm()">
      <formly-form [form]="form" [fields]="fields" />
    </form>
  </div>
  <div camfilDialogActions>
    <button
      mat-raised-button
      type="button"
      class="secondary-button create-order__button"
      (click)="matDialogRef.close()"
    >
      {{ 'camfil.modal.addNewProduct.button.cancel' | translate }}
    </button>
    <button
      mat-raised-button
      color="primary"
      type="button"
      class="create-order__button"
      [disabled]="buttonDisabled"
      (click)="submitForm()"
    >
      {{ formActionText$ | async | translate }}
    </button>
  </div>

  <camfil-loading *ngIf="zipCodesLoading$ | async" />
</ng-container>
