import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CamfilDialogFacade } from 'camfil-core/facades/camfil-dialog.facade';
import { CamfilQuoteHelper } from 'camfil-models/camfil-quotes/camfil-quote/camfil-quote.helper';
import { CamfilQuote } from 'camfil-models/camfil-quotes/camfil-quote/camfil-quote.model';
import {
  CamfilEditQuoteDialogComponent,
  CamfilEditQuoteDialogData,
  CamfilEditQuoteDialogResult,
} from 'camfil-pages/camfil-account-quotes/camfil-edit-quote-dialog/camfil-edit-quote-dialog.component';

@Component({
  selector: 'camfil-edit-quote',
  templateUrl: './camfil-edit-quote.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CamfilEditQuoteComponent {
  constructor(private camfilDialogFacade: CamfilDialogFacade) {}

  @Input() quote: CamfilQuote;

  get canEditQuote() {
    return CamfilQuoteHelper.canEditQuote(this.quote);
  }

  editQuote() {
    this.camfilDialogFacade.open<
      CamfilEditQuoteDialogComponent,
      CamfilEditQuoteDialogData,
      CamfilEditQuoteDialogResult
    >(CamfilEditQuoteDialogComponent, {
      data: this.quote,
    });
  }
}
