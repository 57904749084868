import { createAction } from '@ngrx/store';
import { CamfilAddress } from 'camfil-models/camfil-address/camfil-address.model';
import { CamfilBasketValidation } from 'camfil-models/camfil-basket-validation/camfil-basket-validation.model';
import { CamfilBasketUpdateType } from 'camfil-models/camfil-basket/camfil-basket.interface';
import { CamfilBasket } from 'camfil-models/camfil-basket/camfil-basket.model';
import { CamfilBucket } from 'camfil-models/camfil-bucket/camfil-bucket.model';

import { Attribute } from 'ish-core/models/attribute/attribute.model';
import { CheckoutStepType } from 'ish-core/models/checkout/checkout-step.type';
import { httpError, payload } from 'ish-core/utils/ngrx-creators';

export const resetCamfilBasketErrors = createAction(
  '[Camfil Basket Internal] Reset Basket and Basket Promotion Errors'
);

export const loadCamfilBasket = createAction('[Camfil Basket Internal] Load CamfilBaskets');

//TODO: confirm why it was
// export const loadCamfilBaskets = createAction('[Camfil Basket Internal] Load CamfilBaskets After Login');

export const loadCamfilBasketSuccess = createAction(
  '[Camfil Basket API] Load CamfilBaskets Success',
  payload<{ camfilBasket: CamfilBasket }>()
);

export const selectCamfilBasket = createAction(
  '[Camfil Basket Internal] Select CamfilBasket',
  payload<{ basketId: string }>()
);

export const updateCamfilBasket = createAction(
  '[Camfil Basket Internal] Update CamfilBasket',
  payload<{ update: CamfilBasketUpdateType }>()
);

export const updateCamfilBasketFail = createAction('[Camfil Basket API] Update CamfilBasket Fail', httpError());

export const doubleCamfilBucketItemsQuantity = createAction(
  '[Camfil Basket Internal] Double Bucket Items Quantity',
  payload<{ basketId: string; bucketId: string }>()
);

export const setBasketOrderType = createAction(
  '[Camfil Basket Internal] Set Basket Order Type',
  payload<{ id: string; orderType?: string }>()
);

export const setBucketEditMode = createAction(
  '[Camfil Basket Internal] Set Bucket Edit Mode',
  payload<{ id: string }>()
);

export const setBasketToEditMode = createAction(
  '[Camfil Basket Internal] Set Basket as Edit Mode',
  payload<{
    id: string;
    camfilBasketId: string;
  }>()
);

export const cancelBasketEditMode = createAction('[Camfil Basket Internal] Close Basket Edit Mode');

export const loadBasketAddresses = createAction('[Camfil Basket Internal] Load Camfil Basket Addresses');

export const loadBasketAddressesSuccess = createAction(
  '[Camfil Basket API] Load Camfil Basket Addresses Success',
  payload<{ camfilBasketId: string; basketAddresses: CamfilAddress[] }>()
);

export const reloadBasket = createAction('[Camfil Basket Internal] Reload Basket');

export const addProductToBasket = createAction(
  '[Camfil Basket Internal] Add Product To Basket',
  payload<{
    sku: string;
    quantity: number;
    shippingMethod?: string;
    camfilBucketExtension?: Partial<CamfilBucket>;
    addressId?: string;
    lineItemAttributes?: Attribute[];
    camfilBucketId?: string;
  }>()
);

export const startCamfilCheckout = createAction('[Camfil Basket Internal] Start the checkout process');

export const startCamfilCheckoutSuccess = createAction(
  '[Camfil Basket API] Start the checkout process success',
  payload<{ basketValidation: CamfilBasketValidation; targetRoute?: string }>()
);

export const startCamfilCheckoutFail = createAction('[Camfil Basket API] Start the checkout process fail', httpError());

export const continueCamfilCheckout = createAction(
  '[Camfil Basket Internal] Validate Camfil Basket and continue checkout',
  payload<{ targetStep: CheckoutStepType }>()
);

export const continueCamfilCheckoutFail = createAction(
  '[Camfil Basket API] Validate Camfil Basket and continue checkout Fail',
  httpError()
);

export const continueCamfilCheckoutSuccess = createAction(
  '[Camfil Basket API] Validate Camfil Basket and continue with success',
  payload<{ targetRoute: string; basketValidation: CamfilBasketValidation }>()
);

export const continueCamfilCheckoutWithIssues = createAction(
  '[Camfil Basket Internal] Validate Basket and continue with issues',
  payload<{ targetRoute: string; basketValidation: CamfilBasketValidation }>()
);

export const placeCamfilOrder = createAction('[Camfil Basket Internal] Place Camfil Order');

export const placeCamfilOrderSuccess = createAction('[Camfil Basket Internal] Place Camfil Order Success');

export const placeCamfilOrderFail = createAction('[Camfil Basket Internal] Place Camfil Order Fail', httpError());

export const continueCamfilCheckoutWithIssuesAsFail = createAction(
  '[Camfil Basket Internal] continueCamfilCheckoutWithIssues As Fail',
  httpError()
);

export const showSubmittedInfo = createAction('[Camfil Basket Internal] Show Submitted Basket Info');

export const cleanUpCompletedCart = createAction(
  '[Camfil Basket Internal] Remove Completed Cart Information (Camfil Basket/Bucket + Submitted Basket/Bucket)'
);
