import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';

@Injectable({
  providedIn: 'root',
})
export class CamfilDialogService {
  // TODO (extMLK): Make sure to clear dialog stack when user navigates or logs out;
  private dialogStack: { dialogRef: MatDialogRef<unknown>; isHidden: boolean }[] = [];

  constructor(private dialog: MatDialog) {}

  open<T, D = unknown, R = unknown>(component: ComponentType<T>, config?: MatDialogConfig<D>): MatDialogRef<T, R> {
    if (this.dialogStack.length > 0) {
      this.hideDialog(this.dialogStack[this.dialogStack.length - 1].dialogRef);
    }

    const panelClass = config?.panelClass ? ['camfil-dialog-panel', ...config.panelClass] : ['camfil-dialog-panel'];

    const dialogConfig: MatDialogConfig<D> = {
      ...config,
      panelClass,
    };

    const dialogRef = this.dialog.open<T, D, R>(component, dialogConfig);

    this.dialogStack.push({ dialogRef, isHidden: false });

    dialogRef.afterClosed().subscribe(() => {
      this.dialogStack.pop();
      if (this.dialogStack.length > 0) {
        this.showDialog(this.dialogStack[this.dialogStack.length - 1].dialogRef);
      }
    });

    return dialogRef;
  }

  close(): void {
    const dialogEntry = this.dialogStack.pop();
    if (dialogEntry) {
      dialogEntry.dialogRef.close();
    }
  }

  closeAll(): void {
    while (this.dialogStack.length > 0) {
      this.dialogStack.pop()?.dialogRef.close();
    }
  }

  onOpen(callback: (ref: MatDialogRef<unknown>) => void): void {
    if (this.dialogStack.length > 0) {
      callback(this.dialogStack[this.dialogStack.length - 1].dialogRef);
    }
  }

  onClose(callback: () => void): void {
    if (this.dialogStack.length > 0) {
      this.dialogStack[this.dialogStack.length - 1].dialogRef.afterClosed().subscribe(() => callback());
    }
  }

  private hideDialog(dialogRef: MatDialogRef<unknown>): void {
    dialogRef.addPanelClass('d-none');
    this.dialogStack[this.dialogStack.length - 1].isHidden = true;
  }

  private showDialog(dialogRef: MatDialogRef<unknown>): void {
    dialogRef.removePanelClass('d-none');
    this.dialogStack[this.dialogStack.length - 1].isHidden = false;
  }
}
