import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { maintenanceGuard } from 'camfil-core/guards/maintenance.guard';
import { AhuHelper } from 'camfil-models/camfil-ahu/ahu.helper';

import { authGuard } from 'ish-core/guards/auth.guard';
import { errorStatusGuard } from 'ish-core/guards/error-status.guard';
import { featureToggleGuard } from 'ish-core/guards/feature-toggle.guard';
import { identityProviderInviteGuard } from 'ish-core/guards/identity-provider-invite.guard';
import { identityProviderLoginGuard } from 'ish-core/guards/identity-provider-login.guard';
import { identityProviderLogoutGuard } from 'ish-core/guards/identity-provider-logout.guard';
import { identityProviderRegisterGuard } from 'ish-core/guards/identity-provider-register.guard';

import { loginOnBehalfGuard } from './camfil-login-on-behalf/camfil-login-on-behalf-page.gurd';

const camfilRoutes: Routes = [
  {
    path: 'loading',
    loadChildren: () => import('./camfil-loading/camfil-loading-page.module').then(m => m.CamfilLoadingPageModule),
  },
  {
    path: 'error',
    loadChildren: () => import('./camfil-error/camfil-error-page.module').then(m => m.CamfilErrorPageModule),
    data: {
      meta: {
        title: 'seo.title.error',
        robots: 'noindex, nofollow',
      },
    },
  },
  {
    path: 'home',
    loadChildren: () => import('./camfil-home/camfil-home-page.module').then(m => m.CamfilHomePageModule),
    data: {
      meta: {
        title: 'seo.title.home',
        description: 'seo.description.home',
      },
      wrapperClass: 'homepage',
      breadcrumbData: [],
    },
  },
  {
    path: 'account',
    loadChildren: () => import('./camfil-account/camfil-account-page.module').then(m => m.CamfilAccountPageModule),
    canActivate: [authGuard],
    data: {
      meta: {
        title: 'account.my_account.heading',
        robots: 'noindex, nofollow',
      },
      breadcrumbData: [{ key: 'account.my_account.heading' }],
    },
  },
  {
    path: 'forgotUsername',
    loadChildren: () =>
      import('./camfil-forgot-username/camfil-forgot-username-page.module').then(m => m.CamfilForgotUsernamePageModule),
    data: {
      meta: {
        title: 'account.forgotdata.login_retrieval.heading',
        robots: 'noindex, nofollow',
        breadcrumbData: [{ key: 'account.forgotdata.login_retrieval.heading' }],
      },
    },
  },
  {
    path: 'forgotPassword',
    loadChildren: () =>
      import('./camfil-forgot-password/camfil-forgot-password-page.module').then(m => m.CamfilForgotPasswordPageModule),
    data: {
      meta: {
        title: 'camfil.account.forgotdata.password_retrieval.heading',
        robots: 'noindex, nofollow',
        breadcrumbData: [{ key: 'camfil.account.forgotdata.password_retrieval.heading' }],
      },
    },
  },
  {
    path: 'login-on-behalf',
    loadChildren: () =>
      import('./camfil-login-on-behalf/camfil-login-on-behalf-page.module').then(m => m.CamfilLoginOnBehalfPageModule),
    canActivate: [loginOnBehalfGuard],
    data: {
      meta: {
        robots: 'noindex, nofollow',
      },
      breadcrumbData: [],
    },
  },
  {
    path: 'register',
    loadChildren: () => import('./camfil-register/camfil-register-page.module').then(m => m.CamfilRegisterPageModule),
    canActivate: [identityProviderRegisterGuard],
    data: {
      meta: {
        title: 'account.register.link',
        robots: 'noindex, nofollow',
      },
      breadcrumbData: [{ key: 'account.register.link' }],
    },
  },
  {
    path: 'login',
    loadChildren: () => import('./camfil-login/camfil-login-page.module').then(m => m.CamfilLoginPageModule),
    canActivate: [identityProviderLoginGuard],
    data: {
      breadcrumbData: [{ key: 'account.login.link' }],
    },
  },
  {
    path: 'logout',
    canActivate: [identityProviderLogoutGuard],
    children: [],
  },
  {
    path: 'invite',
    canActivate: [identityProviderInviteGuard],
    children: [],
  },
  {
    path: 'search',
    loadChildren: () => import('./camfil-search/camfil-search-page.module').then(m => m.CamfilSearchPageModule),
  },
  {
    path: 'checkout',
    loadChildren: () => import('./camfil-checkout/camfil-checkout-page.module').then(m => m.CamfilCheckoutPageModule),
    data: {
      headerType: 'checkout',
      breadcrumbData: [{ key: 'seo.title.checkout' }],
      meta: {
        title: 'seo.title.checkout',
        robots: 'noindex, nofollow',
      },
    },
  },
  {
    path: `${AhuHelper.rootPath}`,
    loadChildren: () => import('./camfil-ahu/camfil-ahu.module').then(m => m.CamfilAhuModule),
    canActivate: [authGuard],
  },
  {
    // route for handling confirmation of user data and account deletion requests
    path: 'gdpr-requests',
    loadChildren: () =>
      import('../../../../../src/app/pages/data-request/data-request-page.module').then(m => m.DataRequestPageModule),
    data: {
      meta: {
        title: 'personal.data.request.title',
        robots: 'noindex, nofollow',
      },
    },
  },
  {
    path: 'cookies',
    loadChildren: () => import('./camfil-cookies/camfil-cookies-page.module').then(m => m.CamfilCookiesPageModule),
  },
  {
    path: 'compare',
    loadChildren: () => import('./camfil-compare/camfil-compare-page.module').then(m => m.CamfilComparePageModule),
    canActivate: [authGuard, featureToggleGuard],
    data: {
      feature: 'compare',
      meta: {
        title: 'product.compare.link',
        robots: 'noindex, nofollow',
      },
      breadcrumbData: [{ key: 'product.compare.link' }],
    },
  },
];

@NgModule({
  imports: [
    RouterModule.forChild([
      {
        path: 'maintenance',
        loadChildren: () =>
          import('./camfil-maintenance/camfil-maintenance-page.module').then(m => m.CamfilMaintenancePageModule),
        canActivate: [errorStatusGuard],
        data: {
          meta: {
            title: 'seo.title.maintenance',
            robots: 'noindex, nofollow',
          },
        },
      },
      {
        path: '',
        children: camfilRoutes,
        canActivate: [maintenanceGuard],
        canActivateChild: [maintenanceGuard],
      },
    ]),
  ],
  exports: [RouterModule],
})
export class CamfilRoutingModule {}
