import { QueryParamsHandling } from '@angular/router';

import { URLFormParams } from 'ish-core/utils/url-form-params';

/**
 * Merge two URLFormParams arrays into one.
 * @param paramsArrayA
 * @param paramsArrayB
 * @param queryParamsHandling
 * How to handle query parameters? See https://angular.io/api/router/NavigationExtras#queryParamsHandling
 * - "merge": Merge new parameters with current parameters.
 * - "preserve": Preserve current parameters.
 * - "": Replace current parameters with new parameters. This is the default behavior.
 * @returns The merged URLFormParams
 *
 */
export function assembleURLParamsWithQueryParamControl(
  paramsArrayA: URLFormParams[],
  paramsArrayB: URLFormParams[] = [],
  queryParamsHandling: QueryParamsHandling = ''
): URLFormParams {
  function mergeParams(paramsArray: URLFormParams[]) {
    return paramsArray.reduce((acc, params) => {
      Object.keys(params).forEach(key => {
        if (acc[key]) {
          acc[key] = [...new Set([...acc[key], ...params[key]])];
        } else {
          acc[key] = params[key];
        }
      });
      return acc;
      // eslint-disable-next-line ish-custom-rules/no-object-literal-type-assertion
    }, {} as URLFormParams);
  }

  switch (queryParamsHandling) {
    case 'merge':
      return mergeParams([...paramsArrayA, ...paramsArrayB]);
    case 'preserve':
      return mergeParams(paramsArrayA);
    default:
      const paramsArrayBKeysSet = new Set(paramsArrayB.flatMap(params => Object.keys(params)));
      const paramsArrayAFiltered = paramsArrayA.map(params => {
        // eslint-disable-next-line ish-custom-rules/no-object-literal-type-assertion
        const filteredParams = {} as URLFormParams;

        Object.keys(params).forEach(key => {
          if (!paramsArrayBKeysSet.has(key)) {
            filteredParams[key] = params[key];
          }
        });

        return filteredParams;
      });

      return mergeParams([...paramsArrayAFiltered, ...paramsArrayB]);
  }
}
