import { createSelector } from '@ngrx/store';
import { getCamfilFeatureState } from 'camfil-core/store/camfil-store';
import { CamfilB2bCustomer } from 'camfil-models/camfil-organization/customer/camfil-b2b-customer.model';

import { B2bCustomersState, camfilB2bCustomersFeatureKey, customerAdapter } from './b2b-customers.reducer';

export const getCustomersState = getCamfilFeatureState<B2bCustomersState>(camfilB2bCustomersFeatureKey);

export const getCustomersLoading = createSelector(getCustomersState, state => state.loading);

export const getCustomersError = createSelector(getCustomersState, state => state.error);

const { selectAll, selectEntities, selectTotal } = customerAdapter.getSelectors(getCustomersState);

export const getCustomers = selectAll;

export const getCustomer = (id: string) => createSelector(getCustomers, customers => customers?.find(c => c.id === id));

export const getCustomersCount = selectTotal;

export const getSelectedCustomerId = createSelector(getCustomersState, state => state.selected);

export const getSelectedCustomer = createSelector(
  selectEntities,
  getSelectedCustomerId,
  (entities, id): CamfilB2bCustomer => id && entities[id]
);

export const isCustomerInitialized = createSelector(getCustomersState, state => state.initialized);
