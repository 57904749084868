<h2>{{ 'checkout.address.shipping.label' | translate }}</h2>
<ng-container *ngIf="shippingAddress$ | async as address">
  <div class="address-box">
    <ng-container *ngIf="(basketInvoiceAndShippingAddressEqual$ | async) === false">
      <div class="float-right">
        <!-- edit shipping address -->
        <button
          *ngIf="collapseChange | async"
          type="button"
          class="btn-tool btn-link"
          title="{{ 'checkout.address.update.shipping.label' | translate }}"
          (click)="showAddressForm(address)"
          data-testing-id="edit-shipping-address-link"
        >
          <fa-icon [icon]="['fas', 'pencil-alt']" />
        </button>

        <!-- delete shipping address -->
        <button
          *ngIf="(basketShippingAddressDeletable$ | async) && (collapseChange | async)"
          type="button"
          class="btn-tool btn-link"
          title="{{ 'checkout.address.delete.button.label' | translate }}"
          (click)="modalDialog.show(address)"
        >
          <fa-icon [icon]="['fas', 'trash-alt']" />
        </button>

        <ish-modal-dialog
          #modalDialog
          [options]="{
            titleText: 'checkout.address.delete.confirmation.heading' | translate,
            confirmText: 'checkout.address.button.delete' | translate,
            rejectText: 'checkout.address.button.cancel' | translate
          }"
          (confirmed)="deleteAddress($event)"
        >
          <p>{{ 'checkout.address.delete.confirmation.text' | translate }}</p>
          <small class="text-muted">{{ 'checkout.address.delete.confirmation.deletionhint' | translate }}</small>
        </ish-modal-dialog>
      </div>

      <!-- display shipping address -->
      <ish-address [address]="address" />
    </ng-container>

    <p *ngIf="basketInvoiceAndShippingAddressEqual$ | async" data-testing-id="sameAsInvoice" class="section">
      {{ 'checkout.same_as_billing_address.text' | translate }}
    </p>

    <p></p>
  </div>

  <p *ngIf="!address && showErrors" class="text-danger">
    {{ 'checkout.addresses.no_Selection.shipping.error' | translate }}
  </p>
</ng-container>

<!-- shipping address selection -->
<ng-container *ngIf="addresses$ | async as addresses">
  <form *ngIf="addresses.length" [formGroup]="form">
    <formly-form [form]="form" [fields]="fields" />
  </form>
</ng-container>

<!-- Add a new Shipping to address -->
<div *ngIf="displayAddAddressLink$ | async" class="row">
  <button
    data-testing-id="create-shipping-address-link"
    class="btn btn-link"
    (click)="showAddressForm()"
    [attr.aria-expanded]="(collapseChange | async) === false"
    aria-controls="shipping-address-panel"
  >
    {{ 'checkout.create_shipping_address.link' | translate }}
  </button>
</div>

<!-- shipping address form -->
<div id="shipping-address-panel" [ngbCollapse]="collapseChange | async" data-testing-id="shipping-address-form">
  <ish-formly-customer-address-form
    [address]="editAddress"
    [resetForm]="collapseChange | async"
    (save)="saveAddress($event)"
    (cancel)="cancelEditAddress()"
  />
</div>

<ng-container *ngIf="(collapseChange | async) === false">
  <ish-lazy-address-doctor />
</ng-container>
