<div class="infobox" [ngClass]="cssClass">
  <a
    *ngIf="editRouterLink"
    class="float-right btn-tool"
    [routerLink]="editRouterLink"
    [title]="'checkout.update.label' | translate : { '0': heading | translate }"
  >
    <fa-icon [icon]="['fas', 'pencil-alt']" />
  </a>
  <h3 *ngIf="heading">{{ heading | translate }}</h3>
  <ng-content></ng-content>
</div>
