import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { CamfilOrganizationManagementService } from 'camfil-core/services/organization/camfil-organization.service';
import { concatMap, filter, map, switchMap } from 'rxjs/operators';

import { displayErrorMessage, displaySuccessMessage } from 'ish-core/store/core/messages';
import {
  mapErrorToAction,
  mapToPayload,
  mapToPayloadProperty,
  mapToProperty,
  whenTruthy,
} from 'ish-core/utils/operators';

import {
  connectContactWithUserAndCustomer,
  connectContactWithUserAndCustomerFail,
  connectContactWithUserAndCustomerSuccess,
} from '../b2b-users/b2b-users.actions';

import {
  loadCustomerContact,
  loadCustomerContactFail,
  loadCustomerContactSuccess,
  loadCustomerContacts,
  loadCustomerContactsFail,
  loadCustomerContactsSuccess,
  loadCustomerUserContact,
  loadCustomerUserContactFail,
  loadCustomerUserContactSuccess,
} from './b2b-contacts.actions';

@Injectable()
export class B2bContactsEffects {
  constructor(private actions$: Actions, private managementService: CamfilOrganizationManagementService) {}

  loadCustomerContacts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadCustomerContacts),
      mapToPayloadProperty('customerId'),
      whenTruthy(),
      concatMap(customerId =>
        this.managementService.getCustomerContacts(customerId).pipe(
          map(contacts => loadCustomerContactsSuccess({ customerId, contacts })),
          mapErrorToAction(loadCustomerContactsFail, { customerId })
        )
      )
    )
  );

  loadCustomerContact$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadCustomerContact),
      mapToPayload(),
      switchMap(({ customerId, erpId }) =>
        this.managementService.getCustomerContact(customerId, erpId).pipe(
          map(contact => loadCustomerContactSuccess({ customerId, contact })),
          mapErrorToAction(loadCustomerContactFail, { customerId, erpId })
        )
      )
    )
  );

  loadCustomerUserContact$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadCustomerUserContact),
      mapToPayload(),
      concatMap(({ customerId, userId }) =>
        this.managementService.getCustomerUserContact(customerId, userId).pipe(
          map(contact => loadCustomerUserContactSuccess({ customerId, userId, contact })),
          mapErrorToAction(loadCustomerUserContactFail, { customerId, userId })
        )
      )
    )
  );

  connectContactWithUserAndCustomer$ = createEffect(() =>
    this.actions$.pipe(
      ofType(connectContactWithUserAndCustomer),
      mapToPayload(),
      switchMap(({ customerId, userId, contact }) =>
        this.managementService.connectUserFromCustomerAndContactPlusReload(customerId, userId, contact).pipe(
          map(user =>
            connectContactWithUserAndCustomerSuccess({
              customerId,
              userId,
              user,
              successMessage: 'camfil.account.organization.edit_user.connect_contact_with_user_and_customer.modal.text',
            })
          ),
          mapErrorToAction(connectContactWithUserAndCustomerFail, { customerId, userId, contact })
        )
      )
    )
  );

  displayUpdateCustomerUserContactSuccessMessage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(connectContactWithUserAndCustomerSuccess),
      mapToPayload(),
      mapToProperty('successMessage'),
      filter(successMessage => !!successMessage),
      map(successMessage =>
        displaySuccessMessage({
          message: successMessage,
        })
      )
    )
  );

  displayUpdateCustomerUserFailMessage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(connectContactWithUserAndCustomerFail),
      mapToPayloadProperty('error'),
      whenTruthy(),
      map(error =>
        displayErrorMessage({
          message: error?.message || error?.code,
        })
      )
    )
  );
}
