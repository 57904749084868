<div *ngIf="filterNavigation" class="filter-panel">
  <div *ngFor="let element of filterNavigation.filter" [ngSwitch]="element.displayType">
    <ish-filter-collapsible [title]="element.name" [(collapsed)]="collapsedElements[element.name]">
      <!-- default: text & text_clear -->
      <ish-filter-text
        *ngSwitchDefault
        [filterElement]="element"
        (applyFilter)="applyFilter.emit($event)"
        [(showAll)]="showAllElements[element.name]"
      />

      <!-- checkbox -->
      <ish-filter-checkbox
        *ngSwitchCase="'checkbox'"
        [filterElement]="element"
        (applyFilter)="applyFilter.emit($event)"
        [(showAll)]="showAllElements[element.name]"
      />

      <!-- swatch -->
      <ish-filter-swatch-images
        *ngSwitchCase="'swatch'"
        [filterElement]="element"
        (applyFilter)="applyFilter.emit($event)"
      />

      <!-- dropdown -->
      <ng-container *ngSwitchCase="'dropdown'">
        <div class="filter-dropdown">
          <ish-filter-dropdown
            [filterElement]="element"
            [placeholderType]="'selectedFacets'"
            (applyFilter)="applyFilter.emit($event)"
          />
        </div>
      </ng-container>
    </ish-filter-collapsible>
  </div>
</div>
