import {
  DELETED_LOCALLY_ATTR_NAME,
  NEW_LOCALLY_ITEM_ADDRESS_ID_PREFIX,
} from 'camfil-models/camfil-bucket/camfil-bucket.constants';
import {
  BucketFromEditMode,
  CamfilBucket,
  LineItemFromEditMode,
} from 'camfil-models/camfil-bucket/camfil-bucket.model';

export class CamflEditBasketHelper {
  // TODO (extMlk): Refactor this method. It is too long and complex.
  static updatedBucketFromEditMode(
    camfilBucket: CamfilBucket,

    recipients: string[]
  ): BucketFromEditMode {
    const {
      basket,
      contactPerson,
      customer,
      createdFromCamCardId,
      deliveryAddressId,
      deliveryDate,
      emailRecipients,
      id,
      info,
      invoiceLabel,
      orderMark,
      phoneNumber,
      shipToAddress,
      deliveryAddress,
    } = camfilBucket.deliveryAddressId?.includes(NEW_LOCALLY_ITEM_ADDRESS_ID_PREFIX)
      ? { ...camfilBucket, deliveryAddressId: undefined }
      : camfilBucket;

    const {
      addressLine1,
      addressLine2,
      addressLine3,
      addressName,
      city,
      companyName1,
      companyName2,
      countryCode,
      email,
      fax,
      firstName,
      lastName,
      phoneBusiness,
      phoneBusinessDirect,
      phoneHome,
      phoneMobile,
      postalCode,
      title,
      urn,
      country,
      invoiceToAddress,
      goodsAcceptanceNote,
    } = deliveryAddress || {};

    const lineItems: LineItemFromEditMode[] = camfilBucket.lineItems
      .map(({ attributes, position, productSKU, quantity, ...i }) => ({
        attributes,
        id: i.id,
        position,
        product: productSKU,
        quantity: {
          value: quantity.value,
        },
      }))
      // not tracking new item added and removed before save
      .filter(i => i.id || !i.attributes.find(a => a.name === DELETED_LOCALLY_ATTR_NAME)?.value)
      // fix position after drag&drop chnages
      .map((item, index) => ({ ...item, position: index + 1 }));

    return {
      basket,
      calculated: true,
      contactPerson,
      customer,
      createdFromCamCardId,
      deliveryAddressId,
      deliveryDate,
      emailRecipients: emailRecipients || recipients,
      id,
      info,
      invoiceLabel,
      lineItems,
      isPartialDelivery: camfilBucket?.isPartialDelivery,
      orderMark,
      phoneNumber,
      shipToAddress,
      deliveryAddress: {
        id: deliveryAddress.id,
        shipToAddress: deliveryAddress.shipToAddress,
        urn,
        country,
        invoiceToAddress,
        addressLine1,
        addressLine2,
        addressLine3,
        addressName,
        city,
        companyName1,
        companyName2,
        countryCode,
        email,
        fax,
        firstName,
        lastName,
        phoneBusiness,
        phoneBusinessDirect,
        phoneHome,
        phoneMobile,
        postalCode,
        title,
        goodsAcceptanceNote,
      },
    };
  }
}
