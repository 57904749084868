import {
  ChangeDetectionStrategy, Component, createNgModule, OnInit, ViewChild, ViewContainerRef,
  Injector,
  ComponentRef,
  
  inject, DestroyRef,
  
} from '@angular/core';


  import { FeatureToggleService } from 'ish-core/feature-toggle.module';
  import { takeUntilDestroyed } from '@angular/core/rxjs-interop';


import type { PunchoutTransferBasketComponent as OriginalComponent } from '../../shared/punchout-transfer-basket/punchout-transfer-basket.component';

@Component({
  selector: 'ish-lazy-punchout-transfer-basket',
  templateUrl: './lazy-punchout-transfer-basket.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LazyPunchoutTransferBasketComponent implements OnInit {
  /*
   * WARNING!
   *
   * This file was automatically generated!
   * It should be updated using:
   *
   * ng g lazy-component extensions/punchout/shared/punchout-transfer-basket/punchout-transfer-basket.component.ts
   *
   */

  @ViewChild('anchor', { read: ViewContainerRef, static: true }) anchor: ViewContainerRef;



  private component: ComponentRef<OriginalComponent>;
   private destroyRef = inject(DestroyRef);

  constructor(
    private featureToggleService: FeatureToggleService,
    private injector: Injector
  ) {}

   ngOnInit() {
     this.featureToggleService.enabled$('punchout').pipe(takeUntilDestroyed(this.destroyRef)).subscribe(async enabled => {
      if (enabled) {
    

      await this.renderComponent();

    
        } else {
          this.anchor.clear();
        }
      })
    
  }

  private async renderComponent() {
    const module = await import(`../../punchout.module`).then(m => m.PunchoutModule);

    const { PunchoutTransferBasketComponent: originalComponent } = await import('../../shared/punchout-transfer-basket/punchout-transfer-basket.component');

    const ngModuleRef = createNgModule(module, this.injector);

    this.component = this.anchor.createComponent(originalComponent, { ngModuleRef });
  
    this.component.changeDetectorRef.markForCheck();
  }


}
