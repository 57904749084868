import { ChangeDetectionStrategy, Component, DestroyRef, Input, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { CamfilCheckoutFacade } from 'camfil-core/facades/camfil-checkout.facade';
import { CamfilDialogFacade } from 'camfil-core/facades/camfil-dialog.facade';
import { EMPTY_BUCKET_PREFIX } from 'camfil-models/camfil-bucket/camfil-bucket.constants';
import { CamfilBucket } from 'camfil-models/camfil-bucket/camfil-bucket.model';
import {
  CamfilDeleteOrderModalData,
  CamfilDeleteOrderModalResult,
  CamfilDialogDeleteOrderComponent,
} from 'camfil-shared/components/basket/camfil-checkout-bucket/camfil-delete-order/camfil-dialog-delete-order/camfil-dialog-delete-order.component';
import { CamfilDialogConfirmResult } from 'camfil-shared/dialog/camfil-dialog-confirm/camfil-dialog-confirm.component';

@Component({
  selector: 'camfil-delete-order',
  styleUrls: ['./camfil-delete-order.component.scss'],
  templateUrl: './camfil-delete-order.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CamfilDeleteOrderComponent {
  @Input() orders: CamfilBucket[];
  @Input() visibility: boolean;
  private destroyRef = inject(DestroyRef);
  constructor(private camfilDialogFacade: CamfilDialogFacade, private camfilCheckoutFacade: CamfilCheckoutFacade) {}

  openModal() {
    if (this.visibility) {
      const dialogRef = this.camfilDialogFacade.open<
        CamfilDialogDeleteOrderComponent,
        CamfilDeleteOrderModalData,
        CamfilDeleteOrderModalResult
      >(CamfilDialogDeleteOrderComponent, {
        data: this.orders,
      });

      dialogRef
        .afterClosed()
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe(result => {
          if (result === CamfilDialogConfirmResult.CONFIRMED) {
            this.deleteOrders();
          }
        });
    }
  }

  deleteOrders() {
    if (this.orders?.length > 1) {
      this.camfilCheckoutFacade.deleteCamfilBuckets(this.orders);
    } else if (this.orders) {
      const type = this.orders[0].id.split('_')[0];
      type === EMPTY_BUCKET_PREFIX
        ? this.camfilCheckoutFacade.deleteEmptyBucket(this.orders[0].id)
        : this.camfilCheckoutFacade.deleteOrder(
            this.orders[0].basket,
            this.orders[0].id,
            this.orders[0].deliveryAddress.id
          );
    }
  }
}
