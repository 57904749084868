import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostBinding, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CamfilMaterialModule } from 'camfil-core/material/camfil-material.module';
import {
  CamfilDialogTranslatable,
  CamfilTranslatableContentComponent,
} from 'camfil-shared/components/common/camfil-translatable-content/camfil-translatable-content.component';
import { CamfilDialogActionsDirective } from 'camfil-shared/dialog/camfil-dialog-actions.directive';
import { CamfilDialogContentDirective } from 'camfil-shared/dialog/camfil-dialog-content.directive';
import { CamfilDialogIconDirective } from 'camfil-shared/dialog/camfil-dialog-icon.directive';
import { CamfilDialogTitleDirective } from 'camfil-shared/dialog/camfil-dialog-title.directive';

import {
  CamfilDialogIconName,
  camfilDialogIconNameIsFont,
  camfilDialogIconNameIsSVG,
} from '../camfil-dialog-icon-name.type';

export interface CamfilDialogAlertData {
  icon?: CamfilDialogIconName;
  title: CamfilDialogTranslatable;
  message?: CamfilDialogTranslatable;
  dismissButtonLabel: CamfilDialogTranslatable;
}

export enum CamfilDialogAlertResult {
  DISMISSED,
}

@Component({
  selector: 'camfil-dialog-alert',
  templateUrl: './camfil-dialog-alert.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CamfilMaterialModule,
    CamfilDialogTitleDirective,
    CamfilTranslatableContentComponent,
    NgIf,
    CamfilDialogContentDirective,
    CamfilDialogActionsDirective,
    CamfilDialogIconDirective,
  ],
})
export class CamfilDialogAlertComponent {
  iconNameIsFont = camfilDialogIconNameIsFont;
  iconNameIsSVG = camfilDialogIconNameIsSVG;

  @HostBinding('class.camfil-dialog') camfilDialogClass = true;

  constructor(
    protected matDialogRef: MatDialogRef<CamfilDialogAlertComponent, CamfilDialogAlertResult>,
    // eslint-disable-next-line ish-custom-rules/use-type-safe-injection-token
    @Inject(MAT_DIALOG_DATA) protected dialogData: CamfilDialogAlertData
  ) {}

  dismiss() {
    this.matDialogRef.close(CamfilDialogAlertResult.DISMISSED);
  }
}
