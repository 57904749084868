import { Pipe, PipeTransform } from '@angular/core';
import { AhuHelper } from 'camfil-models/camfil-ahu/ahu.helper';
import { Manufacturer } from 'camfil-models/camfil-ahu/manufacturer/manufacturer.model';

@Pipe({ name: 'ahuManufacturerRoute', pure: true })
export class AhuManufacturerRoutePipe implements PipeTransform {
  transform(manufacturer: Manufacturer): string {
    return AhuHelper.generateAhuUrl(manufacturer?.id);
  }
}
