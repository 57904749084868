import { EntityState, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { Attribute } from 'camfil-models/camfil-attribute/attribute.model';
import {
  DELETED_LOCALLY_ATTR_NAME,
  NEW_LOCALLY_ITEM_ID_PREFIX,
} from 'camfil-models/camfil-bucket/camfil-bucket.constants';
import { CamfilBucketHelper } from 'camfil-models/camfil-bucket/camfil-bucket.helper';
import { CamfilBucket } from 'camfil-models/camfil-bucket/camfil-bucket.model';
import { CamfilLineItem } from 'camfil-models/camfil-line-item/camfil-line-item.model';
import { cloneDeep } from 'lodash-es';

import { HttpError } from 'ish-core/models/http-error/http-error.model';
import { setErrorOn, setLoadingOn, unsetLoadingAndErrorOn } from 'ish-core/utils/ngrx-creators';

import { cleanUpCompletedCart, doubleCamfilBucketItemsQuantity } from '../camfil-basket/camfil-basket.actions';
import {
  addProductToBasketEditMode,
  saveBuckets,
  updateBucketEditMode,
} from '../camfil-edit-basket/camfil-edit-basket.actions';
import { createCamfilOrder } from '../camfil-orders/camfil-orders.actions';
import { createCamfilRequisition } from '../camfil-requisitions/camfil-requisitions.actions';

import {
  addBucketItemAttributeSuccess,
  addEmptyCamfilBucketSuccess,
  camfilDeleteBucketItemSuccess,
  camfilDragLineItem,
  camfilUpdateBucketItemSuccess,
  cancelBucketEditMode,
  deleteCamfilBucket,
  deleteCamfilBucketSuccess,
  deleteCamfilBuckets,
  deleteCamfilBucketsSuccess,
  deleteEmptyCamfilBucket,
  loadCamfilBuckets,
  loadCamfilBucketsExtensions,
  loadCamfilBucketsExtensionsFailure,
  loadCamfilBucketsExtensionsSuccess,
  loadCamfilBucketsFailure,
  loadCamfilBucketsSuccess,
  updateBucketItemAttributesSuccess,
  updateBuckets,
  updateEmptyCamfilBucket,
} from './camfil-bucket.actions';

export const camfilBucketFeatureKey = 'camfilBucket';

export const camfilBucketsAdapter = createEntityAdapter<CamfilBucket>({
  selectId: camfilBucket => camfilBucket?.id,
  sortComparer: (a, b) => a.position - b.position,
});

export interface CamfilBucketsState extends EntityState<CamfilBucket> {
  loading: boolean;
  selected?: string;
  error: HttpError;
  submitedBucket?: CamfilBucket[];
}

const initialState: CamfilBucketsState = camfilBucketsAdapter.getInitialState({
  loading: false,
  selected: undefined,
  error: undefined,
});

export const reducer = createReducer(
  initialState,
  setLoadingOn(
    loadCamfilBuckets,
    loadCamfilBucketsExtensions,
    deleteCamfilBucket,
    deleteCamfilBuckets,
    saveBuckets,
    updateBuckets
  ),
  unsetLoadingAndErrorOn(loadCamfilBucketsExtensionsSuccess, deleteCamfilBucketSuccess, deleteCamfilBucketsSuccess),
  setErrorOn(loadCamfilBucketsFailure, loadCamfilBucketsExtensionsFailure),
  on(loadCamfilBucketsSuccess, (state: CamfilBucketsState, action) => {
    const { buckets } = action.payload;

    // Necessary for removing duplicate buckets from state and prioritize ones from payload (in edit mode flow)
    const correctEntities = Object.values(state.entities).filter(entity => {
      const matchingBucket = buckets.find(bucket => bucket.shipToAddress === entity.shipToAddress);
      return matchingBucket ? false : !entity.lineItems.length && entity.isEmpty;
    });

    const updatedEntities = correctEntities.reduce((entities, entity) => ({ ...entities, [entity.id]: entity }), {});

    return camfilBucketsAdapter.upsertMany(buckets, {
      ...state,
      entities: updatedEntities,
      ids: correctEntities.map(e => e.id),
    });
  }),
  on(loadCamfilBucketsExtensionsSuccess, (state: CamfilBucketsState, action) => {
    const { camfilBucketUpdates } = action.payload;
    const updates = Object.keys(state.entities)?.map(id => {
      const entity = state.entities[id];
      const matchingUpdate = camfilBucketUpdates.find(
        update =>
          update.shipToAddress === entity.shipToAddress && update.shippingAddress?.urn === entity.shippingAddress?.urn
      );

      if (matchingUpdate) {
        return {
          id,
          changes: {
            // Apply the changes from the matching update
            ...matchingUpdate,
          },
        };
      } else {
        return { id, changes: {} }; // No changes for this ID
      }
    });
    return camfilBucketsAdapter.updateMany(updates, state);
  }),
  on(addEmptyCamfilBucketSuccess, (state: CamfilBucketsState, action) => {
    const { bucket } = action.payload;

    return camfilBucketsAdapter.upsertOne({ ...bucket, isEmpty: true }, state);
  }),
  on(updateEmptyCamfilBucket, (state: CamfilBucketsState, action) => {
    const { bucket } = action.payload;
    return camfilBucketsAdapter.updateOne(
      {
        id: bucket.id,
        changes: {
          ...bucket,
        },
      },
      state
    );
  }),
  on(deleteEmptyCamfilBucket, deleteCamfilBucket, (state: CamfilBucketsState, action) => {
    const { bucketId } = action.payload;

    return camfilBucketsAdapter.removeOne(bucketId, state);
  }),
  on(deleteCamfilBuckets, (state: CamfilBucketsState, action) => {
    const { orders } = action.payload;
    const ids = orders.map(o => o.id);

    return camfilBucketsAdapter.removeMany(ids, state);
  }),

  on(addProductToBasketEditMode, (state: CamfilBucketsState, action) => {
    const { sku, quantity, camfilBucketId, lineItemAttributes } = action.payload;

    const position = state.entities[camfilBucketId]?.lineItems?.length + 1 || 1;

    const lineItem: CamfilLineItem = {
      // real data
      quantity: {
        value: quantity,
      },
      productSKU: sku,
      attributes: lineItemAttributes,

      // mock data
      id: NEW_LOCALLY_ITEM_ID_PREFIX + new Date().getTime(),
      position,
      price: undefined,
      singleBasePrice: undefined,
      totals: {
        salesTaxTotal: undefined,
        shippingTaxTotal: undefined,
        shippingTotal: undefined,
        total: undefined,
        undiscountedTotal: undefined,
        valueRebatesTotal: undefined,
      },
      isHiddenGift: false,
      isFreeGift: false,
      editable: false,
    };

    const changes = {
      lineItems: [...state.entities[camfilBucketId].lineItems, lineItem],
    };

    return camfilBucketsAdapter.updateOne(
      {
        id: camfilBucketId,
        changes: {
          ...changes,
        },
      },
      state
    );
  }),

  on(updateBucketEditMode, (state: CamfilBucketsState, action) => {
    const { camfilBucket } = action.payload;

    return updateOneBucket(camfilBucket.id, camfilBucket, state);
  }),
  on(cancelBucketEditMode, (state: CamfilBucketsState, action) => {
    const { camfilBuckets } = action.payload;

    return camfilBucketsAdapter.setAll(camfilBuckets, state);
  }),
  on(doubleCamfilBucketItemsQuantity, (state: CamfilBucketsState, action) => {
    const { bucketId } = action.payload;

    const entity = state.entities[bucketId];
    const bucket = {
      ...entity,
      lineItems: entity.lineItems?.map(li => ({
        ...li,
        quantity: { ...li.quantity, value: li.quantity.value * 2 },
      })),
    };

    return updateOneBucket(bucketId, bucket, state);
  }),
  on(camfilDeleteBucketItemSuccess, (state: CamfilBucketsState, action) => {
    const { itemId, bucketId } = action.payload;
    let bucket = cloneDeep(state.entities[bucketId]);
    const deletedMark = { name: DELETED_LOCALLY_ATTR_NAME, value: true };
    bucket.lineItems.find(({ id }) => id === itemId).attributes.push(deletedMark);

    // Get Unique delivery dates from line items
    const uniqueDateTimestamps = CamfilBucketHelper.getUniqueEarliestDeliveryDates(
      bucket.lineItems.filter(li => li.id !== itemId && !li.attributes.find(a => a.name === DELETED_LOCALLY_ATTR_NAME))
    );
    // Removed item earliest delivery date
    const removedLineItemDeliveryDate = Date.parse(
      bucket.lineItems.find(({ id }) => id === itemId)?.earliestDeliveryDate
    );
    const selectedDeliveryDate = Date.parse(bucket.deliveryDate);

    const nextHighestDate = getNextHighestDate(uniqueDateTimestamps, removedLineItemDeliveryDate);
    // If removed item earliestDeliveryDate is higher than any other line items left in bucket and it is not partial delivery and higher or equal to selected delivery date

    if (
      nextHighestDate !== undefined &&
      !bucket.isPartialDelivery &&
      removedLineItemDeliveryDate >= selectedDeliveryDate
    ) {
      // Update bucket's delivery date to next highest value

      bucket = {
        ...bucket,
        deliveryDate: nextHighestDate,
      };
    }

    return updateOneBucket(bucketId, bucket, state);
  }),
  on(addBucketItemAttributeSuccess, (state: CamfilBucketsState, action) => {
    const { lineItemId, bucketId, lineItemAttribute } = action.payload;
    const entity = state.entities[bucketId];
    const bucket = {
      ...cloneDeep(entity),
      lineItems: addAttr(entity.lineItems, lineItemId, lineItemAttribute),
    };

    return updateOneBucket(bucketId, bucket, state);
  }),
  on(updateBucketItemAttributesSuccess, (state: CamfilBucketsState, action) => {
    const { lineItemId, bucketId, lineItemAttribute } = action.payload;
    const entity = state.entities[bucketId];
    const bucket = {
      ...cloneDeep(entity),
      lineItems: updateAttr(entity.lineItems, lineItemId, lineItemAttribute),
    };

    return updateOneBucket(bucketId, bucket, state);
  }),
  on(camfilUpdateBucketItemSuccess, (state: CamfilBucketsState, action) => {
    const { item, bucketId } = action.payload;
    const entity = state.entities[bucketId];
    const bucket = cloneDeep(entity);
    bucket.lineItems.find(i => i.id === item.itemId).quantity.value = item.quantity;

    return updateOneBucket(bucketId, bucket, state);
  }),
  on(camfilDragLineItem, (state: CamfilBucketsState, action) => {
    const { updatedLineItems, bucketId } = action.payload;

    const b = cloneDeep(state.entities[bucketId]);
    const lineItems = updatedLineItems;

    return updateOneBucket(bucketId, { ...b, lineItems }, state);
  }),
  on(createCamfilOrder, createCamfilRequisition, (state: CamfilBucketsState) => ({
    ...state,
    submitedBucket: Object.values(state.entities),
  })),
  on(cleanUpCompletedCart, (state: CamfilBucketsState) =>
    camfilBucketsAdapter.removeAll({
      ...state,
      submitedBucket: undefined,
    })
  )
);

function updateOneBucket(id: string, bucket: CamfilBucket, state: CamfilBucketsState) {
  return camfilBucketsAdapter.updateOne({ id, changes: { ...bucket } }, state);
}

function addAttr(lineItems: CamfilLineItem[], id: string, lineItemAttribute: Attribute) {
  return lineItems.map(i => (i.id === id ? { ...i, attributes: [...i.attributes, lineItemAttribute] } : i));
}

function updateAttr(lineItems: CamfilLineItem[], id: string, lineItemAttribute: Attribute) {
  return lineItems.map(item => {
    if (item.id !== id) {
      return item;
    }

    const existingAttribute = item.attributes.find(a => a.name === lineItemAttribute.name);
    const updatedAttributes = existingAttribute
      ? item.attributes.map(a => (a.name === lineItemAttribute.name ? lineItemAttribute : a))
      : [...item.attributes, lineItemAttribute];

    return { ...item, attributes: updatedAttributes };
  });
}

function getNextHighestDate(dates: number[], removedDate: number): string | undefined {
  if (isNaN(removedDate)) {
    return;
  }

  dates.sort((a, b) => a - b);

  for (const date of dates) {
    if (date >= removedDate) {
      return new Date(date).toISOString().split('T')[0];
    }
  }

  return dates.length ? new Date(dates[dates.length - 1]).toISOString().split('T')[0] : undefined;
}
