import { Injectable } from '@angular/core';

import { CamfilCustomerDeliveryTermData } from './camfil-customer-delivery-term.intercafe';
import { CamfilCustomerDeliveryTerm } from './camfil-customer-delivery-term.model';

@Injectable({ providedIn: 'root' })
export class CamfilCustomerDeliveryTermMapper {
  static readonly POSTPONED_FREIGHT_COST = ['FR8', 'ADDFREIGHT'];

  static fromData(deliveryTermData: CamfilCustomerDeliveryTermData, customerId: string): CamfilCustomerDeliveryTerm {
    if (deliveryTermData) {
      return {
        ...deliveryTermData,
        customerId,
        postponedFreightCost: CamfilCustomerDeliveryTermMapper.POSTPONED_FREIGHT_COST.includes(deliveryTermData?.id),
      };
    } else {
      throw new Error(`CamfilCustomerDeliveryTermData is required`);
    }
  }
}
