import { SlicePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { CamfilJoinPipe } from 'camfil-core/pipes/camfil-join.pipe';
import { CamfilNotInQueuePipe } from 'camfil-core/pipes/camfil-not-in-queue.pipe';
import { CamfilTruncatePipe } from 'camfil-core/pipes/camfil-truncate.pipe';

import { AttributeToStringPipe } from 'ish-core/models/attribute/attribute.pipe';

import { AhuManufacturerRoutePipe } from './pipes/ahu-manufacturer-route.pipe';
import { AhuUnitRoutePipe } from './pipes/ahu-unit-route.pipe';
import { CamfilAddressSortPipe } from './pipes/camfil-address-sort.pipe';
import { CamfilCustomerPipe } from './pipes/camfil-customer.pipe';
import { CamfilDatePipe } from './pipes/camfil-date.pipe';
import { CamfilDimensionPipe } from './pipes/camfil-dimension.pipe';
import { CamfilMeasurementPipe } from './pipes/camfil-measurement.pipe';
import { CamfilMoneyPipe } from './pipes/camfil-money.pipe';
import { CamfilOrderStatusTextPipe } from './pipes/camfil-order-status-text.pipe';
import { CamfilPriceSummaryPipe } from './pipes/camfil-price-summary.pipe';
import { CamfilProductAttributeValuePipe } from './pipes/camfil-product-attribute-value.pipe';
import { CamfilRemoveWhiteSpacesPipe } from './pipes/camfil-remove-white-space.pipe';
import { CamfilSlugifyPipe } from './pipes/camfil-slugify.pipe';

const camfilPipes = [
  AhuManufacturerRoutePipe,
  AhuUnitRoutePipe,
  CamfilAddressSortPipe,
  CamfilCustomerPipe,
  CamfilDatePipe,
  CamfilDimensionPipe,
  CamfilJoinPipe,
  CamfilMeasurementPipe,
  CamfilMoneyPipe,
  CamfilNotInQueuePipe,
  CamfilOrderStatusTextPipe,
  CamfilPriceSummaryPipe,
  CamfilProductAttributeValuePipe,
  CamfilRemoveWhiteSpacesPipe,
  CamfilSlugifyPipe,
  CamfilTruncatePipe,
];

@NgModule({
  declarations: [...camfilPipes],
  exports: [...camfilPipes],
  providers: [...camfilPipes, AttributeToStringPipe, SlicePipe],
})
export class CamfilPipesModule {}
