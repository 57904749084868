import { DialogModule } from '@angular/cdk/dialog';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { CamfilMaterialModule } from 'camfil-core/material/camfil-material.module';
import { CamfilDialogService } from 'camfil-core/services/camfil-dialog/camfil-dialog.service';
import { CamfilTranslatableContentComponent } from 'camfil-shared/components/common/camfil-translatable-content/camfil-translatable-content.component';
import { CamfilDialogDirective } from 'camfil-shared/dialog/camfil-dialog.directive';

import { CamfilDialogActionsDirective } from './camfil-dialog-actions.directive';
import { CamfilDialogAlertComponent } from './camfil-dialog-alert/camfil-dialog-alert.component';
import { CamfilDialogConfirmComponent } from './camfil-dialog-confirm/camfil-dialog-confirm.component';
import { CamfilDialogContentDirective } from './camfil-dialog-content.directive';
import { CamfilDialogFormlyDirective } from './camfil-dialog-formly.directive';
import { CamfilDialogFormlyComponent } from './camfil-dialog-formly/camfil-dialog-formly.component';
import { CamfilDialogIconDirective } from './camfil-dialog-icon.directive';
import { CamfilDialogTitleDirective } from './camfil-dialog-title.directive';

const standaloneComponents = [CamfilDialogAlertComponent, CamfilDialogConfirmComponent, CamfilDialogFormlyComponent];

const standaloneDirectives = [
  CamfilDialogActionsDirective,
  CamfilDialogContentDirective,
  CamfilDialogDirective,
  CamfilDialogFormlyDirective,
  CamfilDialogIconDirective,
  CamfilDialogTitleDirective,
];

@NgModule({
  imports: [
    ...standaloneComponents,
    ...standaloneDirectives,
    CamfilMaterialModule,
    CamfilTranslatableContentComponent,
    CommonModule,
    DialogModule,
    ReactiveFormsModule,
    TranslateModule,
  ],
  exports: [...standaloneComponents, ...standaloneDirectives],
  providers: [CamfilDialogService],
})
export class CamfilDialogModule {}
