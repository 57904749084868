import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { CamfilProductHelper } from 'camfil-models/camfil-product/camfil-product.helper';
import { Observable, map } from 'rxjs';

import { ProductContextFacade } from 'ish-core/facades/product-context.facade';

@Component({
  selector: 'camfil-product-lead-time',
  templateUrl: './camfil-product-lead-time.component.html',
  styleUrls: ['./camfil-product-lead-time.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
// lead-time is represented as delivery days in the product attributes
export class CamfilProductLeadTimeComponent implements OnInit {
  @Input() showText: boolean;
  @Input() onlyNumber: boolean;
  @Input() showZero = false;

  productHasLeadTime$: Observable<number>;

  constructor(private productContext: ProductContextFacade) {}

  ngOnInit(): void {
    this.productHasLeadTime$ = this.productContext.select('product').pipe(map(CamfilProductHelper.getLeadTime));
  }
}
