// eslint-disable ish-custom-rules/ban-imports-file-pattern
// noinspection DuplicatedCode

import { CommonModule } from '@angular/common';
import { Injector, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgbCollapseModule, NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';

import { AuthorizationToggleModule } from 'ish-core/authorization-toggle.module';
import { DirectivesModule } from 'ish-core/directives.module';
import { FeatureToggleModule } from 'ish-core/feature-toggle.module';
import { IconModule } from 'ish-core/icon.module';
import { PipesModule } from 'ish-core/pipes.module';
import { RoleToggleModule } from 'ish-core/role-toggle.module';
import { FeatureEventService } from 'ish-core/utils/feature-event/feature-event.service';
import { ModuleLoaderService } from 'ish-core/utils/module-loader/module-loader.service';
import { CompareExportsModule } from 'ish-extensions/compare/exports/compare-exports.module';
import { QuickorderExportsModule } from 'ish-extensions/quickorder/exports/quickorder-exports.module';
import { SentryExportsModule } from 'ish-extensions/sentry/exports/sentry-exports.module';
import { SeoExportsModule } from 'ish-extensions/seo/exports/seo-exports.module';
import { StoreLocatorExportsModule } from 'ish-extensions/store-locator/exports/store-locator-exports.module';
import { TrackingExportsModule } from 'ish-extensions/tracking/exports/tracking-exports.module';
import { WishlistsExportsModule } from 'ish-extensions/wishlists/exports/wishlists-exports.module';

import { CookiesBannerComponent } from './application/cookies-banner/cookies-banner.component';
import { FooterComponent } from './footer/footer/footer.component';
import { BackToTopComponent } from './header/back-to-top/back-to-top.component';
import { HeaderCheckoutComponent } from './header/header-checkout/header-checkout.component';
import { HeaderDefaultComponent } from './header/header-default/header-default.component';
import { HeaderErrorComponent } from './header/header-error/header-error.component';
import { HeaderNavigationComponent } from './header/header-navigation/header-navigation.component';
import { HeaderSimpleComponent } from './header/header-simple/header-simple.component';
import { HeaderComponent } from './header/header/header.component';
import { LanguageSwitchComponent } from './header/language-switch/language-switch.component';
import { LoginStatusComponent } from './header/login-status/login-status.component';
import { MiniBasketComponent } from './header/mini-basket/mini-basket.component';
import { SubCategoryNavigationComponent } from './header/sub-category-navigation/sub-category-navigation.component';
import { UserInformationMobileComponent } from './header/user-information-mobile/user-information-mobile.component';
import { LazyContentIncludeComponent } from './shared/lazy-content-include/lazy-content-include.component';
import { LazyMiniBasketContentComponent } from './shared/lazy-mini-basket-content/lazy-mini-basket-content.component';
import { LazySearchBoxComponent } from './shared/lazy-search-box/lazy-search-box.component';

const exportedComponents = [CookiesBannerComponent, FooterComponent, HeaderComponent];

const declaredComponents = [
  BackToTopComponent,
  CookiesBannerComponent,
  HeaderCheckoutComponent,
  HeaderDefaultComponent,
  HeaderErrorComponent,
  HeaderNavigationComponent,
  HeaderSimpleComponent,
  LanguageSwitchComponent,
  LazyContentIncludeComponent,
  LazyMiniBasketContentComponent,
  LazySearchBoxComponent,
  LoginStatusComponent,
  MiniBasketComponent,
  SubCategoryNavigationComponent,
  UserInformationMobileComponent,
];

@NgModule({
  imports: [
    AuthorizationToggleModule,
    CommonModule,
    CompareExportsModule,
    DirectivesModule,
    FeatureToggleModule,
    IconModule,
    NgbCollapseModule,
    NgbDropdownModule,
    PipesModule,
    QuickorderExportsModule,
    RoleToggleModule,
    RouterModule,
    SentryExportsModule,
    SeoExportsModule,
    StoreLocatorExportsModule,
    TrackingExportsModule,
    TranslateModule,
    WishlistsExportsModule,
  ],
  declarations: [...exportedComponents, ...declaredComponents],
  // NOTE: Exports declaredComponents to be used in other modules in camfil-pwa
  exports: [...exportedComponents, ...declaredComponents],
})
// eslint-disable-next-line ish-custom-rules/project-structure
export class ShellModule {
  constructor(moduleLoader: ModuleLoaderService, featureEventNotifier: FeatureEventService, injector: Injector) {
    moduleLoader.init(injector);
    featureEventNotifier.setupAvailableResultListener(injector);
  }
}
