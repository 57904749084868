import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CamfilConfigurationFacade } from 'camfil-core/facades/camfil-configuration.facade';
import { CamfilBucketResponse } from 'camfil-models/camfil-address/camfil-address.interface';
import { CamfilAddress } from 'camfil-models/camfil-address/camfil-address.model';
import { CamfilAddressMapper } from 'camfil-models/camfil-address/camfil-adress.mapper';
import { CamfilBasketValidationData } from 'camfil-models/camfil-basket-validation/camfil-basket-validation.interface';
import { CamfilBasketValidationMapper } from 'camfil-models/camfil-basket-validation/camfil-basket-validation.mapper';
import {
  CamfilBasketValidation,
  CamfilBasketValidationScopeType,
} from 'camfil-models/camfil-basket-validation/camfil-basket-validation.model';
import { CamfilBasketData, CamfilBasketUpdateType } from 'camfil-models/camfil-basket/camfil-basket.interface';
import { CamfilBasketMapper } from 'camfil-models/camfil-basket/camfil-basket.mapper';
import { CamfilAddItem, CamfilBasket } from 'camfil-models/camfil-basket/camfil-basket.model';
import { CamfilBucketMapper } from 'camfil-models/camfil-bucket/camfil-bucket.mapper';
import { BucketFromEditMode } from 'camfil-models/camfil-bucket/camfil-bucket.model';
import { CamfilCalendarExceptionsMapper } from 'camfil-models/camfil-caledar-exceptions/camfil-caledar-exceptions.mapper';
import { CamfilCalendarException } from 'camfil-models/camfil-caledar-exceptions/camfil-caledar-exceptions.model';
import { AddCamCardToCart, CamCardToBasketFeedback } from 'camfil-models/camfil-cam-card/cam-card.model';
import { CamfilCustomerDeliveryTermData } from 'camfil-models/camfil-customer-delivery-term/camfil-customer-delivery-term.intercafe';
import { CamfilCustomerDeliveryTermMapper } from 'camfil-models/camfil-customer-delivery-term/camfil-customer-delivery-term.mapper';
import { CamfilCustomerDeliveryTerm } from 'camfil-models/camfil-customer-delivery-term/camfil-customer-delivery-term.model';
import { Observable, map, switchMap, take, throwError } from 'rxjs';

import { BasketInfoMapper } from 'ish-core/models/basket-info/basket-info.mapper';
import { BasketInfo } from 'ish-core/models/basket-info/basket-info.model';
import { BasketData } from 'ish-core/models/basket/basket.interface';
import { BasketMapper } from 'ish-core/models/basket/basket.mapper';
import { Basket } from 'ish-core/models/basket/basket.model';
import { ApiService } from 'ish-core/services/api/api.service';
import { BasketService } from 'ish-core/services/basket/basket.service';
import { OrderService } from 'ish-core/services/order/order.service';

type IshBasketIncludeType =
  | 'invoiceToAddress'
  | 'commonShipToAddress'
  | 'commonShippingMethod'
  | 'discounts'
  | 'lineItems_discounts'
  | 'payments'
  | 'payments_paymentMethod'
  | 'payments_paymentInstrument'
  | 'camfilProductLineItems'
  | 'lineItems';

type CamfilValidationBasketIncludeType =
  | 'basket'
  | 'basket_invoiceToAddress'
  | 'basket_commonShipToAddress'
  | 'basket_commonShippingMethod'
  | 'basket_discounts'
  | 'basket_lineItems_discounts'
  | 'basket_lineItems'
  | 'basket_payments'
  | 'basket_payments_paymentMethod'
  | 'basket_payments_paymentInstrument';

@Injectable({
  providedIn: 'root',
})
export class CamfilBasketService extends BasketService {
  constructor(
    apiService: ApiService,
    ishOrderService: OrderService,
    private ishApiService: ApiService,
    private camfilConfigurationFacade: CamfilConfigurationFacade
  ) {
    super(apiService, ishOrderService);
  }

  private camfilBasketHeaders = new HttpHeaders({
    'content-type': 'application/json',
    Accept: 'application/vnd.intershop.basket.v1+json',
  });

  private reloadBasketIncludes: IshBasketIncludeType[] = ['lineItems'];

  private allCamfilBasketValidationIncludes: CamfilValidationBasketIncludeType[] = [
    'basket',
    'basket_invoiceToAddress',
    'basket_commonShipToAddress',
    'basket_commonShippingMethod',
    'basket_discounts',
    'basket_lineItems_discounts',
    'basket_lineItems',
    'basket_payments',
    'basket_payments_paymentMethod',
    'basket_payments_paymentInstrument',
  ];

  updateCamfilBasket(body: CamfilBasketUpdateType): Observable<CamfilBasket> {
    if (!body) {
      return throwError(() => new Error('updateCamfilBasket() called without body'));
    }

    return this.ishApiService
      .patch<CamfilBasketData>(`baskets/current`, body, {
        headers: this.camfilBasketHeaders,
      })
      .pipe(map(CamfilBasketMapper.fromCamfilBasketUpdateData));
  }

  /**
   * Get warehouse calendar.
   * @returns         The basket.
   */
  getWarehouseCalendar(): Observable<CamfilCalendarException[]> {
    const currentDate = new Date();
    const futureDate = new Date();

    futureDate.setDate(futureDate.getDate() + 1000);

    return this.camfilConfigurationFacade.countryCode$.pipe(
      take(1),
      switchMap(countryCode =>
        this.ishApiService
          .post('calendar', {
            startDate: currentDate,
            endDate: futureDate,
            state: 'Closed',
            countryCode,
          })
          .pipe(map(CamfilCalendarExceptionsMapper.fromListData))
      )
    );
  }

  getCamfilBasketAddresses(): Observable<CamfilAddress[]> {
    return this.ishApiService
      .get<{ data: CamfilAddress[] }>(`baskets/current/addresses`, {
        headers: this.camfilBasketHeaders,
      })
      .pipe(map(addresses => addresses.data));
  }

  /**
   * Create a basket address for the currently used basket of an anonymous user.
   *
   * @param address   The address which should be created
   * @returns         The new basket address.
   */
  createCamfilBasketAddress(address: CamfilAddress): Observable<CamfilAddress> {
    if (!address) {
      return throwError(() => new Error('createBasketAddress() called without address'));
    }
    return this.ishApiService
      .currentBasketEndpoint()
      .post<CamfilBucketResponse>(`addresses`, address, {
        headers: this.camfilBasketHeaders,
      })
      .pipe(
        map(res => res.data),
        map(CamfilAddressMapper.fromData)
      );
  }

  /**
   * Update partly or completely an address for the currently used basket of an anonymous user.
   *
   * @param address   The address data which should be updated
   * @returns         The new basket address.
   */
  updateCamfilBasketAddress(address: CamfilAddress): Observable<CamfilAddress> {
    if (!address) {
      return throwError(() => new Error('updateBasketAddress() called without address'));
    }
    if (!address.id) {
      return throwError(() => new Error('updateBasketAddress() called without addressId'));
    }
    return this.ishApiService
      .currentBasketEndpoint()
      .patch<CamfilBucketResponse>(`addresses/${address.id}`, address, {
        headers: this.camfilBasketHeaders,
      })
      .pipe(
        map(res => res.data),
        map(CamfilAddressMapper.fromData)
      );
  }

  updateBuckets(camfilBuckets: BucketFromEditMode[], basketId: string): Observable<Basket> {
    const params = new HttpParams().set('include', this.reloadBasketIncludes.join());
    const camfilBucketsData = camfilBuckets.map(bucket => CamfilBucketMapper.toEditBucketData(bucket));

    return (
      this.ishApiService
        // TODO provide type
        .put(`baskets/${basketId}/camfil`, camfilBucketsData, { params })
        .pipe(map(BasketMapper.fromData))
    );
  }

  /**
   * Get the basket for the current user.
   * @returns         The basket.
   */
  reloadBasket(): Observable<Basket> {
    const params = new HttpParams().set('include', 'lineItems');
    return this.ishApiService
      .get<BasketData>(`baskets/current`, {
        headers: this.camfilBasketHeaders,
        params,
      })
      .pipe(map(BasketMapper.fromData));
  }

  loadCustomerDeliveryTerm(customerId: string): Observable<CamfilCustomerDeliveryTerm> {
    if (!customerId) {
      return throwError(() => new Error('loadCustomerDeliveryTerm() called without customerId'));
    }
    return this.ishApiService
      .get<CamfilCustomerDeliveryTermData>(`camfilcustomers/${customerId}/deliveryterm`)
      .pipe(map(deliveryTerm => CamfilCustomerDeliveryTermMapper.fromData(deliveryTerm, customerId)));
  }

  /**
   * Validates the currently used basket.
   *
   * @param scopes    Basket scopes which should be validated ( see also BasketValidationScopeType ), default: minimal scope (max items limit, empty basket)
   * @returns         The (adjusted) basket and the validation results.
   */
  validateCamfilBasket(scopes: CamfilBasketValidationScopeType[] = ['']): Observable<CamfilBasketValidation> {
    const body = {
      basket: 'current',
      adjustmentsAllowed: !scopes.some(scope => scope === 'All'), // don't allow adjustments for 'All' validation steps, because you cannot show them to the user at once
      scopes,
    };

    const params = new HttpParams().set('include', this.allCamfilBasketValidationIncludes.join());
    return this.ishApiService
      .currentBasketEndpoint()
      .post<CamfilBasketValidationData>('validations', body, {
        headers: this.camfilBasketHeaders,
        params,
      })
      .pipe(map(CamfilBasketValidationMapper.fromData));
  }

  /**
   * Adds a list of items with the given sku and quantity to the given basket.
   * @param items     The list of product SKU and quantity pairs to be added to the basket.
   */
  camfilAddItemsToBasket(items: CamfilAddItem[], calculated?: boolean): Observable<BasketInfo[]> {
    if (!items) {
      return throwError(() => new Error('addItemsToBasket() called without items'));
    }

    const body = CamfilBasketMapper.getCamfilAddToCartItem(items, !!calculated);

    return this.ishApiService
      .currentBasketEndpoint()
      .post('items', body, {
        headers: this.camfilBasketHeaders,
      })
      .pipe(map(BasketInfoMapper.fromInfo));
  }

  /**
   * @param items     The list of camCards ID with itemsId if not whole camCard is adding to the basket.
   */
  addToCartFromCamCards(items: AddCamCardToCart[]): Observable<CamCardToBasketFeedback[]> {
    if (!items) {
      return throwError(() => new Error('addToCartFromCamCards() called without items'));
    }

    return this.ishApiService
      .currentBasketEndpoint()
      .post<{ infos: CamCardToBasketFeedback[] }>('camcards', items)
      .pipe(map(({ infos }) => infos));
  }
}
