import { Injectable } from '@angular/core';
import { camelCaseKeys } from 'camfil-core/utils/functions';

import { ManufacturerHelper } from '../manufacturer/manufacturer.helper';

import { UnitData } from './unit.interface';
import { Unit } from './unit.model';

@Injectable({ providedIn: 'root' })
export class UnitMapper {
  static fromData(unitData: UnitData): Unit {
    if (!unitData) {
      throw new Error(`unitData is required`);
    }

    const unit = UnitMapper.parseData(unitData);

    if (unit?.manufacturer) {
      unit.manufacturer = ManufacturerHelper.uuid(unit.manufacturer);
    }

    if (unit?.shortDescription === '') {
      unit.shortDescription = undefined;
    }

    if (unit?.longDescription === '') {
      unit.longDescription = undefined;
    }

    return unit;
  }

  static fromListData(unitsData: UnitData[], sortBy?: keyof UnitData): Unit[] {
    if (!unitsData) {
      throw new Error('unitsData is required');
    }

    let output = unitsData;

    if (!Array.isArray(output)) {
      output = [output];
    }

    if (sortBy) {
      output.sort((a, b) => String(a[sortBy])?.localeCompare(String(b[sortBy]), undefined, { sensitivity: 'base' }));
    }

    return output.map(data => UnitMapper.fromData(data));
  }

  static mapByManufacturerId(units: Unit[], manufacturerId: string) {
    return units.map(unit => ({ ...unit, manufacturerId }));
  }

  private static parseData(unitData: UnitData) {
    return camelCaseKeys(unitData, { deep: true }) as unknown as Unit;
  }
}
