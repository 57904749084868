import { ChangeDetectionStrategy, ChangeDetectorRef, Component, DestroyRef, OnInit, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NavigationEnd, Router } from '@angular/router';
import { CamfilConfigurationFacade } from 'camfil-core/facades/camfil-configuration.facade';
import { Observable, delay, filter } from 'rxjs';

import { AppFacade } from 'ish-core/facades/app.facade';
import { DeviceType } from 'ish-core/models/viewtype/viewtype.types';

type CollapsibleComponent = 'search' | 'navbar' | 'minibasket';

@Component({
  selector: 'camfil-header',
  templateUrl: './camfil-header.component.html',
  styleUrls: ['./camfil-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CamfilHeaderComponent implements OnInit {
  deviceType$: Observable<DeviceType>;
  isSticky$: Observable<boolean>;
  searchTermMinLength$: Observable<number>;

  activeComponent: CollapsibleComponent | undefined;

  private destroyRef = inject(DestroyRef);

  constructor(
    private route: Router,
    private appFacade: AppFacade,
    private camfilConfigurationFacade: CamfilConfigurationFacade,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.route.events
      .pipe(
        filter(event => event instanceof NavigationEnd && this.activeComponent === 'navbar'),
        delay(100), // for better animation effect
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(() => {
        this.toggle('search');
      });

    this.deviceType$ = this.appFacade.deviceType$;
    this.isSticky$ = this.appFacade.stickyHeader$;
    this.searchTermMinLength$ = this.camfilConfigurationFacade.minLength$('searchTerm');
  }

  toggle(component: CollapsibleComponent) {
    this.activeComponent = this.activeComponent === component ? 'search' : component;
    this.cdr.detectChanges();
  }
}
