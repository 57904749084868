import { createAction } from '@ngrx/store';
import { CamfilAddress } from 'camfil-models/camfil-address/camfil-address.model';
import { CamCardItemComment, CamCardMeasurement } from 'camfil-models/camfil-cam-card/cam-card.model';
import {
  CamfilRequisition,
  CamfilRequisitionLineItemUpdate,
  CamfilRequisitionStatus,
  CamfilRequisitionViewer,
} from 'camfil-models/camfil-requisition/camfil-requisition.model';

import { Attribute } from 'ish-core/models/attribute/attribute.model';
import { httpError, payload } from 'ish-core/utils/ngrx-creators';

export const loadCamfilRequisitions = createAction(
  '[Camfil Requisitions] Load Camfil Requisitions',
  payload<{ view?: CamfilRequisitionViewer }>()
);

export const loadCamfilRequisitionsFail = createAction(
  '[Camfil Requisitions API] Load Camfil Requisitions Fail',
  httpError()
);

export const loadCamfilRequisitionsSuccess = createAction(
  '[Camfil Requisitions API] Load Camfil Requisitions Success',
  payload<{
    requisitions: CamfilRequisition[];
    view?: CamfilRequisitionViewer;
    status?: CamfilRequisitionStatus;
  }>()
);

export const loadCamfilRequisition = createAction(
  '[Camfil Requisitions] Load Camfil Requisition',
  payload<{ requisitionId: string }>()
);

export const loadCamfilRequisitionFail = createAction(
  '[Camfil Requisitions API] Load Camfil Requisition Fail',
  httpError()
);

export const loadCamfilRequisitionSuccess = createAction(
  '[Camfil Requisitions API] Load Camfil Requisition Success',
  payload<{ requisition: CamfilRequisition }>()
);

export const getCamfilRequisitionData = createAction(
  '[Camfil Requisitions] Get Camfil Requisition',
  payload<{ requisitionId: string }>()
);

export const updateCamfilRequisitionStatus = createAction(
  '[Camfil Requisitions] Update Camfil Requisition Status',
  payload<{ requisitionId: string; status: CamfilRequisitionStatus; approvalComment?: string }>()
);

export const updateCamfilRequisitionStatusFail = createAction(
  '[Camfil Requisitions API] Update Camfil Requisition Status Fail',
  httpError()
);

export const updateCamfilRequisitionStatusSuccess = createAction(
  '[Camfil Requisitions API] Update Camfil Requisition Status Success',
  payload<{ requisition: CamfilRequisition; status: string }>()
);

export const createOrderFromApprovedRequisition = createAction(
  '[Camfil Requisitions API] Create Order From Approved Camfil Requisition',
  payload<{ requisitionId: string }>()
);

export const createOrderFromApprovedRequisitionSuccess = createAction(
  '[Camfil Requisitions API] Create Order From Approved Camfil Requisition Success',
  payload<{ requisition: CamfilRequisition }>()
);

export const createOrderFromApprovedRequisitionFail = createAction(
  '[Camfil Requisitions API] Create Order From Approved Camfil Requisition Fail',
  httpError()
);

export const createOrderFromApprovedRequisitionLineItems = createAction(
  '[Camfil Requisitions API] Create Order From Approved Camfil Requisition Line Items',
  payload<{ requisition: CamfilRequisition; lineItemIds: string[] }>()
);

export const createOrderFromApprovedRequisitionLineItemsSuccess = createAction(
  '[Camfil Requisitions API] Create Order From Approved Camfil Requisition Line Items Success',
  payload<{ requisition: CamfilRequisition; lineItemIds: string[] }>()
);

export const addProductToCamfilRequisition = createAction(
  '[Camfil Requisitions API] Add Product To Camfil Requisition',
  payload<{
    requisitionId: string;
    item: { sku: string; quantity: number; boxLabel?: CamCardItemComment; measurements?: CamCardMeasurement };
  }>()
);

export const addProductToCamfilRequisitionFail = createAction(
  '[Camfil Requisitions API] Add Product To Camfil Requisition Fail',
  httpError()
);

export const addProductToCamfilRequisitionSuccess = createAction(
  '[Camfil Requisitions API] Add Product To Camfil Requisition Success',
  payload<{
    requisitionId: string;
  }>()
);

// Deleting products from requisition

export const removeProductFromCamfilRequisition = createAction(
  '[Camfil Requisitions API] Remove Line Item From Camfil Requisition',
  payload<{
    lineItemId: string;
    requisitionId?: string;
  }>()
);

export const removeProductFromCamfilRequisitionSuccess = createAction(
  '[Camfil Requisitions API] Remove Line Item From Camfil Requisition Success',
  payload<{ requisitionId: string }>()
);

export const removeProductFromCamfilRequisitionFail = createAction(
  '[Camfil Requisitions API] Remove  Line Item Camfil Requisition Fail',
  httpError()
);

export const removeLastProductFromCamfilRequisition = createAction(
  '[Camfil Requisitions API] Remove Last Line Item From Camfil Requisition',
  payload<{
    lineItemId: string;
    requisitionId?: string;
  }>()
);

export const removeLastProductFromCamfilRequisitionSuccess = createAction(
  '[Camfil Requisitions API] Remove Last Line Item From Camfil Requisition Success',
  payload<{ requisitionId: string }>()
);

export const removeLastProductFromCamfilRequisitionFail = createAction(
  '[Camfil Requisitions API] Remove Last Line Item Camfil Requisition Fail',
  httpError()
);

export const removeMultipleProductsFromCamfilRequisition = createAction(
  '[Camfil Requisitions API] Remove Multiple Line Items From Camfil Requisition',
  payload<{
    lineItemsIds: string[];
    requisitionId?: string;
  }>()
);

// Requisition Update

export const updateCamfilRequisition = createAction(
  '[Camfil Requisitions API] Update Camfil Requisition',
  payload<{
    requisition: CamfilRequisition;
    addressId?: string;
    address?: Partial<CamfilAddress>;
  }>()
);

export const updateCamfilRequisitionSuccess = createAction(
  '[Camfil Requisitions API] Update Camfil Requisition Success',
  payload<{ requisition: CamfilRequisition }>()
);

export const updateCamfilRequisitionFail = createAction(
  '[Camfil Requisitions API] Update Camfil Requisition Fail',
  httpError()
);

// Address update
export const updateCamfilRequisitionAddress = createAction(
  '[Camfil Requisitions API] Update Camfil Requisition Address',
  payload<{ requisition: CamfilRequisition; address: Partial<CamfilAddress> }>()
);

export const updateCamfilRequisitionAddressSuccess = createAction(
  '[Camfil Requisitions API] Update Camfil Requisition Address Success',
  payload<{ requisition: CamfilRequisition; address: Partial<CamfilAddress> }>()
);

// Requisition Creation

export const createCamfilRequisition = createAction('[Camfil Requisitions API] Create Camfil Requisition');

export const createCamfilRequisitionFail = createAction(
  '[Camfil Requisitions API] Create Camfil Requisition Fail',
  httpError()
);

export const createCamfilRequisitionSuccess = createAction(
  '[Camfil Requisitions API] Create Camfil Requisition Success',
  payload<{ requisitions: CamfilRequisition[] }>()
);

export const checkProductAvailabilityFail = createAction('[Camfil Requisitions API] Check Product Availability Fail');

// --------- Line items attributes ---------
export const addCamfilRequisitionLineItemAttribute = createAction(
  '[Camfil Requisitions API]  Add Attributes for selected line item ',
  payload<{ requisitionId: string; lineItemId: string; lineItemAttribute: Attribute }>()
);

// Update Line Item Attribute
export const updateCamfilRequisitionLineItemAttribute = createAction(
  '[Camfil Requisitions API] Update Line Item Atrtibute',
  payload<{
    requisitionId?: string;
    lineItemId: string;
    lineItemAttribute: Attribute;
  }>()
);

// Delete Line Item Attribute
export const deleteCamfilRequisitionLineItemAttribute = createAction(
  '[Camfil Requisitions API] Delete Attributes for selected line item ',
  payload<{ requisitionId: string; lineItemId: string; lineItemAttribute: Attribute }>()
);

// Approve Line Items

export const approveCamfilRequisitionLineItems = createAction(
  '[Camfil Requisitions API] Approve Line Items Atrtibute',
  payload<{
    requisitionId?: string;
    lineItemIds: string[];
    requisition: CamfilRequisition;
  }>()
);

export const approveCamfilRequisitionLineItemsFail = createAction(
  '[Camfil Requisitions API] Approve Line Items Fail',
  httpError()
);

// Quantity update for Line items

export const updateCamfilRequisitionLineItem = createAction(
  '[Camfil Requisitions API] Update Line Item ',
  payload<{
    requisitionId: string;
    lineItemUpdate: CamfilRequisitionLineItemUpdate;
  }>()
);

export const updateCamfilRequisitionLineItemSuccess = createAction(
  '[Camfil Requisitions API] Update Line Item  Success',
  payload<{
    requisitionId: string;
    lineItemUpdate: CamfilRequisitionLineItemUpdate;
  }>()
);

export const updateCamfilRequisitionLineItemFail = createAction(
  '[Camfil Requisitions API] Update Line Item  Fail',
  httpError()
);

// Change status for multiple requisitions

export const updateMultipleCamfilRequisitionStatus = createAction(
  '[Camfil Requisitions] Update Multiple Camfil Requisition Status',
  payload<{ requisitionIds: string[]; status: CamfilRequisitionStatus; approvalComment?: string }>()
);

export const updateMultipleCamfileRequisitionStatusFail = createAction(
  '[Camfil Requisitions API] Update Multiple Camfil Requisition Status Fail',
  httpError()
);

export const updateMultipleCamfilRequisitionStatusSuccess = createAction(
  '[Camfil Requisitions API] Update Multiple Camfil Requisition Status Success',
  payload<{ requisition: CamfilRequisition; status: string }>()
);

export const updateRequisitionDeliveryDate = createAction(
  '[Camfil Requisitions API] Update Camfil Requisition Delivery Date',
  payload<{ requisition: CamfilRequisition }>()
);

export const checkCamfilRequisitionDeliveryDateSucces = createAction(
  '[Camfil Requisitions API] Check Camfil Requisition Delivery Date Success',
  payload<{ id: string; date: string }>()
);

export const checkCamfilRequisitionDeliveryDateFail = createAction(
  '[Camfil Requisitions API] Check Camfil Requisition Delivery Date Fail',
  httpError()
);
