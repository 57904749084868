import { createReducer, on } from '@ngrx/store';
import channelConfig from 'camfil-core/services/configuration/settings';
import {
  CamfilChannelConfiguration,
  CamfilCountryCode,
} from 'camfil-models/camfil-configuration/camfil-configuration.model';

import { HttpError } from 'ish-core/models/http-error/http-error.model';
import { setErrorOn, setLoadingOn, unsetLoadingAndErrorOn, unsetLoadingOn } from 'ish-core/utils/ngrx-creators';

import { loadConfiguration, loadConfigurationFailure, loadConfigurationSuccess } from './camfil-configuration.actions';

export const configurationFeatureKey = 'camfilConfiguration';

export type CamfilConfigurationState = CamfilChannelConfiguration & {
  initialized: boolean;
  loading: boolean;
  error: HttpError;
};

export const initialState: CamfilConfigurationState = {
  availableCountryCodes: Object.keys(channelConfig) as CamfilCountryCode[],
  basketSurchargeOrder: undefined,
  bucketSurchargeOrder: undefined,
  continueShoppingUrl: undefined,
  timeZone: undefined,
  countryCode3: undefined,
  countryCode: undefined,
  countryCodeRegExp: undefined,
  error: undefined,
  icmChannel: undefined,
  initialized: false,
  loading: false,
  maxLength: undefined,
  minLength: undefined,
  zipCodeRegExp: undefined,
};

export const reducer = createReducer(
  initialState,
  setLoadingOn(loadConfiguration),
  unsetLoadingOn(loadConfigurationSuccess, loadConfigurationFailure),
  unsetLoadingAndErrorOn(loadConfigurationSuccess),
  setErrorOn(loadConfigurationFailure),
  on(
    loadConfigurationSuccess,
    (state, action): CamfilConfigurationState => ({ ...state, ...action.payload.configuration })
  )
);
