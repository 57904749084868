import { AfterViewInit, ChangeDetectionStrategy, Component, Input, ViewChild } from '@angular/core';
import { MatCheckbox, MatCheckboxClickAction } from '@angular/material/checkbox';
import { ThemePalette } from '@angular/material/core';
import { FieldTypeConfig } from '@ngx-formly/core';
import { FieldType } from '@ngx-formly/material/form-field';

// eslint-disable-next-line ish-custom-rules/require-formly-code-documentation
@Component({
  selector: 'camfil-checkbox-field',
  templateUrl: './camfil-checkbox-field.component.html',
  styleUrls: ['./camfil-checkbox-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
// eslint-disable-next-line ish-custom-rules/require-formly-code-documentation
export class CamfilCheckboxFieldComponent extends FieldType<FieldTypeConfig> implements AfterViewInit {
  @ViewChild(MatCheckbox) matCheckbox: MatCheckbox;

  @Input() clickAction: MatCheckboxClickAction = 'check';

  override defaultOptions = {
    props: {
      clickAction: 'check' as MatCheckboxClickAction,
      hideFieldUnderline: true,
      indeterminate: true,
      floatLabel: 'always' as const,
      hideLabel: true,
      color: 'primary' as ThemePalette, // workaround for https://github.com/angular/components/issues/18465
    },
  };

  ngAfterViewInit() {
    // Nasty workaround for setting the clickAction on the checkbox per instance without DI;
    try {
      if (this.matCheckbox) {
        // @ts-expect-error: private property
        // eslint-disable-next-line no-underscore-dangle
        this.matCheckbox._options = { ...this.matCheckbox._options, clickAction: this.props.clickAction };
      }
    } catch (error) {
      console.error(`Error while setting clickAction on checkbox`, error);
    }
  }
}
