import { createAction } from '@ngrx/store';
import { CamfilProductView } from 'camfil-models/camfil-product/product.model';
import { CamfilQuoteServiceRequest } from 'camfil-models/camfil-quotes/camfil-quote-service-request/camfil-quote-service-request.model';
import { CamfilQuoteServiceResponse } from 'camfil-models/camfil-quotes/camfil-quote-service-response/camfil-quote-service-response.model';
import { CamfilQuoteData } from 'camfil-models/camfil-quotes/camfil-quote/camfil-quote.interface';
import { CamfilQuote } from 'camfil-models/camfil-quotes/camfil-quote/camfil-quote.model';

import { httpError, payload } from 'ish-core/utils/ngrx-creators';

export const loadCamfilQuotes = createAction('[Camfil Quotes] Load Camfil Quotes');

export const loadCamfilQuotesSuccess = createAction(
  '[Camfil Quotes] Load Camfil Quotes Success',
  payload<{ quotes: CamfilQuote[] }>()
);

export const loadCamfilQuote = createAction(
  '[Camfil Quotes] Load Camfil Quote Details',
  payload<{ quoteId: string }>()
);

export const loadCamfilQuoteIfNotLoaded = createAction(
  '[Camfil Quotes] Load Camfil Quote Details If Not Loaded',
  payload<{ quoteId: string }>()
);

export const loadCamfilQuoteSuccess = createAction(
  '[Camfil Quotes] Load Camfil Quote Details Success',
  payload<{ quote: CamfilQuote }>()
);

export const createCamfilQuoteSuccess = createAction('[Camfil Quotes] Create Quote Success');

export const approveCamfilQuote = createAction(
  '[Camfil Quotes] Approve Quote',
  payload<{ request: CamfilQuoteServiceRequest }>()
);

export const approveCamfilQuoteSuccess = createAction(
  '[Camfil Quotes] Approve Quote Success',
  payload<{ response: CamfilQuoteServiceResponse }>()
);

export const approveCamfilQuoteFail = createAction('[Camfil Quotes] Approve Quote Error', httpError());

export const approveCamfilQuotes = createAction(
  '[Camfil Quotes] Approve Quotes',
  payload<{ request: CamfilQuoteServiceRequest[] }>()
);

export const approveCamfilQuotesSuccess = createAction(
  '[Camfil Quotes] Approve Quotes Success',
  payload<{ response: CamfilQuoteServiceResponse[] }>()
);

export const approveCamfilQuotesFail = createAction('[Camfil Quotes] Approve Quotes Error', httpError());

export const rejectCamfilQuote = createAction(
  '[Camfil Quotes] Reject Quote',
  payload<{ request: CamfilQuoteServiceRequest; rejectionReason: string }>()
);

export const rejectCamfilQuoteSuccess = createAction(
  '[Camfil Quotes] Reject Quote Success',
  payload<{ response: CamfilQuoteServiceResponse }>()
);

export const rejectCamfilQuoteFail = createAction('[Camfil Quotes] Reject Quote Error', httpError());

export const rejectCamfilQuotes = createAction(
  '[Camfil Quotes] Reject Quotes',
  payload<{ request: CamfilQuoteServiceRequest[]; rejectionReason: string }>()
);

export const rejectCamfilQuotesSuccess = createAction(
  '[Camfil Quotes] Reject Quotes Success',
  payload<{ response: CamfilQuoteServiceResponse[] }>()
);

export const rejectCamfilQuotesFail = createAction('[Camfil Quotes] Reject Quotes Error', httpError());

export const updateCamfilQuote = createAction(
  '[Camfil Quotes] Update Quote',
  payload<{ quote: Pick<CamfilQuoteData, 'id' | 'deliveryAddress' | 'invoiceMark'> }>()
);

export const updateCamfilQuoteSuccess = createAction(
  '[Camfil Quotes] Update Quote Success',
  payload<{ quote: CamfilQuote }>()
);

export const updateCamfilQuoteFail = createAction(
  '[Camfil Quotes] Update Quote Error',
  httpError<{ quoteId: string }>()
);

export const selectCamfilQuote = createAction('[Camfil Quotes] Select Quote', payload<{ quoteId: string }>());

export const printCamfilQuote = createAction(
  '[Camfil Quotes] Print Quote',
  payload<{ quoteId: string; products: CamfilProductView[] }>()
);

export const tryPrintCamfilQuoteAgain = createAction(
  '[Camfil Quotes] try Print Quote Again',
  payload<{ quoteId: string; skus: string[] }>()
);

export const printCamfilQuoteLastStep = createAction(
  '[Camfil Quotes] Print Quote Last Step',
  payload<{ quote: CamfilQuote; products: CamfilProductView[] }>()
);
