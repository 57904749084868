import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';

@Component({
  selector: 'camfil-search-input-wrapper',
  templateUrl: './camfil-search-input-wrapper.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./camfil-search-input-wrapper.component.scss'],
})
// eslint-disable-next-line ish-custom-rules/require-formly-code-documentation
export class CamfilSearchInputWrapperComponent extends FieldWrapper {}
