import { ChangeDetectionStrategy, Component, HostBinding, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { CamfilCheckoutFacade } from 'camfil-core/facades/camfil-checkout.facade';
import { CamfilFormClass } from 'camfil-shared/formly/utils/camfil-form';
import { Observable, map } from 'rxjs';

export enum CamfilInvalidDeliveryDateAction {
  UpdateInvalidDeliveryDatesAndContinueCheckout = 1,
  UpdateAsEarliestPossibleDeliveryDateAndContinueCheckout = 2,
  CloseWithIssuesAndPreventCheckout = 3,
}

type CamfilValidationDeliveryDateForm = {
  invalidDeliveryDateAction: CamfilInvalidDeliveryDateAction;
};

@Component({
  selector: 'camfil-basket-validation-delivery-date-dialog',
  templateUrl: './camfil-basket-validation-delivery-date-dialog.component.html',
  styleUrls: ['./camfil-basket-validation-delivery-date-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CamfilBasketValidationDeliveryDateDialogComponent implements OnInit {
  constructor(
    private camfilCheckoutFacade: CamfilCheckoutFacade,
    private matDialogRef: MatDialogRef<CamfilBasketValidationDeliveryDateDialogComponent, number>
  ) {}

  @HostBinding('class.camfil-dialog') camfilDialogClass = true;

  form: CamfilFormClass<CamfilValidationDeliveryDateForm>;

  commonDeliveryDate$ = this.camfilCheckoutFacade.commonDateForExpiredBuckets$;
  invalidDeliveryDates$ = this.camfilCheckoutFacade.camfilBucketsWithInvalidDeliveryDate$;
  invalidOrderIndexes$ = this.invalidDeliveryDates$.pipe(map(buckets => buckets.map(b => b.index).sort()));

  private getFormFields$(): Observable<FormlyFieldConfig[]> {
    return this.commonDeliveryDate$.pipe(
      map(commonDeliveryDate => [
        {
          key: 'invalidDeliveryDateAction',
          type: 'radio',
          className: 'radio-group',
          props: {
            options: [
              {
                disabled: !commonDeliveryDate,
                value: 1,
                label:
                  'camfil.checkout.validationDeliveryDate.dialog.option.updateInvalidDeliveryDatesAndContinueCheckout',
              },
              {
                value: 2,
                label:
                  'camfil.checkout.validationDeliveryDate.dialog.option.updateAsEarliestPossibleDeliveryDateAndContinueCheckout',
              },
              {
                value: 3,
                label: 'camfil.checkout.validationDeliveryDate.dialog.option.closeWithIssuesAndPreventCheckout',
              },
            ],
            required: true,
          },
        },
      ])
    );
  }

  private getFormModel$() {
    return this.commonDeliveryDate$.pipe(
      map(commonDeliveryDate => ({
        invalidDeliveryDateAction: commonDeliveryDate
          ? CamfilInvalidDeliveryDateAction.UpdateInvalidDeliveryDatesAndContinueCheckout
          : undefined,
      }))
    );
  }

  private createForm() {
    return new CamfilFormClass<CamfilValidationDeliveryDateForm>({
      fields$: this.getFormFields$(),
      model$: this.getFormModel$(),
    });
  }

  ngOnInit(): void {
    this.form = this.createForm();
  }

  submitForm() {
    if (this.form?.instance?.valid) {
      const result = this.form?.formValue?.invalidDeliveryDateAction;
      this.matDialogRef.close(result);
    }
  }

  hide() {
    this.matDialogRef.close();
  }
}
