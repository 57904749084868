<div [class.active]="showBanner" class="cookie-banner-wrapper">
  <div
    *ngIf="showBanner"
    [@bottomOut]="transitionBanner"
    (@bottomOut.done)="acceptAllAnimationDone($event)"
    [ngClass]="pagelet.stringParam('CSSClass')"
    class="cookies-banner"
  >
    <p *ngIf="pagelet.hasParam('Description')" [ishServerHtml]="pagelet.stringParam('Description')" class="mb-4"></p>
    <div class="d-sm-flex justify-content-sm-center mb-2">
      <button
        type="button"
        mat-flat-button
        color="primary"
        (click)="acceptAll()"
        class="order-2 ml-sm-2"
        data-testing-id="acceptAllButton"
      >
        {{ 'cookies.banner.accept_all' | translate }}
      </button>
      <button
        type="button"
        mat-flat-button
        color="primary"
        (click)="rejectAll()"
        class="order-2 ml-sm-2"
        data-testing-id="rejectAllButton"
      >
        {{ 'cookies.banner.reject_all' | translate }}
      </button>
      <button type="button" mat-stroked-button color="primary" routerLink="/cookies" class="order-1 mr-sm-2">
        {{ 'cookies.banner.set_preferences' | translate }}
      </button>
    </div>
  </div>
</div>
