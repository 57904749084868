<div *ngIf="pagelet" [ngClass]="pagelet.stringParam('CSSClass')">
  <ish-content-slot [wrapper]="true" [slot]="'app_sf_base_cm:slot.carousel.items.pagelet2-Slot'" [pagelet]="pagelet">
    <div [ngClass]="pagelet.stringParam('CarouselCSSClass')">
      <ngb-carousel
        #ngbCarousel
        [interval]="0"
        [showNavigationArrows]="pageletSlides?.length > 1"
        [showNavigationIndicators]="pagelet.booleanParam('ShowIndicators')"
      >
        <ng-template ngbSlide *ngFor="let slidePagelets of pageletSlides">
          <div class="row">
            <ng-container *ngFor="let slidePagelet of slidePagelets">
              <div [ngClass]="'col-sm-' + itemGridSize">
                <ish-content-pagelet [pageletId]="slidePagelet" />
              </div>
            </ng-container>
          </div>
        </ng-template>
      </ngb-carousel>
    </div>
  </ish-content-slot>
</div>
