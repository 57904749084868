/* eslint-disable ish-custom-rules/ordered-imports */
/* eslint-disable ish-custom-rules/ban-imports-file-pattern */

import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CamfilOrganizationManagementFacade } from 'camfil-core/facades/camfil-organization.facade';
import { CamfilB2bContactData } from 'camfil-models/camfil-organization/contact/camfil-b2b-contact.interface';
import { CamfilB2bContactMapper } from 'camfil-models/camfil-organization/contact/camfil-b2b-contact.mapper';
import { CamfilB2bContact } from 'camfil-models/camfil-organization/contact/camfil-b2b-contact.model';
import { CamfilB2bCustomerData } from 'camfil-models/camfil-organization/customer/camfil-b2b-customer.interface';
import { CamfilB2bCustomerMapper } from 'camfil-models/camfil-organization/customer/camfil-b2b-customer.mapper';
import {
  CamfilB2bCustomer,
  CamfilB2bCustomerContact,
} from 'camfil-models/camfil-organization/customer/camfil-b2b-customer.model';
import { CamfilB2bRoleIDsData } from 'camfil-models/camfil-organization/role/camfil-b2b-role.interface';
import { CamfilB2bRole } from 'camfil-models/camfil-organization/role/camfil-b2b-role.model';
import { CamfilB2bUserData } from 'camfil-models/camfil-organization/user/camfil-b2b-user.interface';
import { CamfilB2bUserMapper } from 'camfil-models/camfil-organization/user/camfil-b2b-user.mapper';
import { CamfilB2bUser } from 'camfil-models/camfil-organization/user/camfil-b2b-user.model';
import { CAMFIL_FALLBACK_LANG } from 'camfil-shared/camfil-constants';
import { pick } from 'lodash-es';
import { EMPTY, Observable, forkJoin, of } from 'rxjs';
import { catchError, concatAll, concatMap, defaultIfEmpty, map, mergeMap, switchMap } from 'rxjs/operators';

import { AppFacade } from 'ish-core/facades/app.facade';
import { PasswordReminder } from 'ish-core/models/password-reminder/password-reminder.model';
import { ApiService, AvailableOptions, unpackEnvelope } from 'ish-core/services/api/api.service';
import { whenTruthy } from 'ish-core/utils/operators';

import { B2bRoleData } from '../../../../../../organization-management/src/app/models/b2b-role/b2b-role.interface';
import { B2bRoleMapper } from '../../../../../../organization-management/src/app/models/b2b-role/b2b-role.mapper';
import { concatLatestFrom } from '@ngrx/effects';

@Injectable({ providedIn: 'root' })
export class CamfilOrganizationManagementService {
  constructor(
    private apiService: ApiService,
    private b2bRoleMapper: B2bRoleMapper,
    private appFacade: AppFacade,
    private managementFacade: CamfilOrganizationManagementFacade
  ) {}

  getCustomers(): Observable<CamfilB2bCustomer[]> {
    return this.apiService
      .get<CamfilB2bCustomerData[]>(`camfilcustomers`)
      .pipe(unpackEnvelope(), map(CamfilB2bCustomerMapper.fromListData), defaultIfEmpty([]));
  }

  getCustomer(customerId: string): Observable<CamfilB2bCustomer> {
    return this.apiService
      .get<CamfilB2bCustomerData>(`camfilcustomers/${customerId}`)
      .pipe(map(CamfilB2bCustomerMapper.fromData));
  }

  getCustomerRoles(customerId: string): Observable<CamfilB2bRole[]> {
    return this.apiService.get(`camfilcustomers/${customerId}/roles`).pipe(
      unpackEnvelope<B2bRoleData>('userRoles'),
      map(data => this.b2bRoleMapper.fromData(data) || [])
    );
  }

  getCustomerContacts(customerId: string): Observable<CamfilB2bContact[]> {
    return this.apiService
      .get<CamfilB2bContactData[]>(`privatecamfilcustomers/${customerId}/contacts`)
      .pipe(unpackEnvelope(), map(CamfilB2bContactMapper.fromListData), defaultIfEmpty([]));
  }

  getCustomerContact(customerId: string, erpId: string): Observable<CamfilB2bContact> {
    return this.apiService
      .get<CamfilB2bContactData>(`privatecamfilcustomers/${customerId}/contacts/${erpId}`)
      .pipe(map(CamfilB2bContactMapper.fromData));
  }

  getCustomerUsers(customerId: string): Observable<CamfilB2bUser[]> {
    return this.apiService
      .get<CamfilB2bUserData>(`privatecamfilcustomers/${customerId}/users`)
      .pipe(unpackEnvelope(), map(CamfilB2bUserMapper.fromListData), defaultIfEmpty([]));
  }

  getCustomerUser(customerId: string, userId: string): Observable<CamfilB2bUser> {
    return this.apiService
      .get<CamfilB2bUserData>(`privatecamfilcustomers/${customerId}/users/${userId}`)
      .pipe(map(CamfilB2bUserMapper.fromData));
  }

  updateCustomerUser(customer: CamfilB2bCustomer, user: CamfilB2bUser) {
    if (!customer) {
      throw new Error('updateCustomerUser() called without required customer data');
    }
    if (!user) {
      throw new Error('updateCustomerUser() called without required user data');
    }

    // TODO (extMlk): See CAM-979
    const login = user?.currentLogin || user.login;
    const customerNo = customer?.parentCustomer?.customerNo || customer.customerNo;

    return this.apiService
      .put(`customers/${customerNo}/users/${login}`, {
        ...customer,
        ...user,
        preferredInvoiceToAddress: { urn: user.preferredInvoiceToAddressUrn },
        preferredShipToAddress: { urn: user.preferredShipToAddressUrn },
        preferredPaymentInstrument: { id: user.preferredPaymentInstrumentId },
        preferredInvoiceToAddressUrn: undefined,
        preferredShipToAddressUrn: undefined,
        preferredPaymentInstrumentId: undefined,
      })
      .pipe(map(CamfilB2bUserMapper.fromData));
  }

  createCustomerUser(
    customer: CamfilB2bCustomer,
    user: CamfilB2bUser,
    contacts: CamfilB2bCustomerContact[],
    roles: CamfilB2bRole[],
    approvers: string[]
  ) {
    const roleIDs = [].concat(roles).map(r => r?.id);

    if (!customer) {
      throw new Error('createCustomerUser() called without required customer data');
    }

    if (!user) {
      throw new Error('createCustomerUser() called without required user data');
    }

    return this.appFacade.currentLocale$.pipe(
      switchMap(currentLocale =>
        this.apiService
          .post<CamfilB2bUser>(`customers/${customer.customerNo}/users`, {
            elements: [
              {
                ...customer,
                ...user,
                preferredInvoiceToAddress: { urn: user.preferredInvoiceToAddressUrn },
                preferredShipToAddress: { urn: user.preferredShipToAddressUrn },
                preferredPaymentInstrument: { id: user.preferredPaymentInstrumentId },
                preferredInvoiceToAddressUrn: undefined,
                preferredShipToAddressUrn: undefined,
                preferredPaymentInstrumentId: undefined,
                preferredLanguage: currentLocale ?? CAMFIL_FALLBACK_LANG,
                userBudgets: undefined,
                roleIds: undefined,
              },
            ],
          })
          .pipe(
            unpackEnvelope<CamfilB2bUser>(),
            map(users => users[0])
          )
          .pipe(
            switchMap(createdUser =>
              forkJoin(
                contacts.map(item =>
                  this.connectUserWithCustomer(item.customer.id, createdUser.id).pipe(catchError(() => EMPTY))
                )
              ).pipe(
                defaultIfEmpty([]),
                map(() => createdUser)
              )
            ),
            switchMap(createdUser =>
              forkJoin(
                contacts.map(item =>
                  this.connectContactWithUserCustomer(item.customer.id, createdUser.id, item.contact).pipe(
                    catchError(() => EMPTY)
                  )
                )
              ).pipe(
                defaultIfEmpty([]),
                map(() => createdUser)
              )
            ),
            switchMap(createdUser =>
              this.updateCustomerUserRoles(customer.id, createdUser.id, roleIDs).pipe(
                map(() => createdUser),
                catchError(() => EMPTY)
              )
            ),
            switchMap(createdUser =>
              this.setCustomerUserApprovers(customer.id, createdUser.id, approvers).pipe(
                map(() => createdUser),
                catchError(() => EMPTY)
              )
            ),
            switchMap(createdUser => this.getCustomerUser(contacts[0].customer.id, createdUser.id))
          )
      )
    );
  }

  updateCustomerUserActiveFlag(customerId: string, userId: string, value: boolean): Observable<boolean> {
    const options: AvailableOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'text/plain',
        Accept: 'application/json',
      }),
    };

    // TODO (extMlk): This need to be fixed on Back-end. Endpoint does not return any response.

    return this.apiService
      .post<string>(`privatecamfilcustomers/${customerId}/users/${userId}/activeFlag`, value, options)
      .pipe(map(() => value));
  }

  getCustomerUserContact(customerId: string, userId: string): Observable<CamfilB2bContact> {
    return this.apiService
      .get<CamfilB2bContactData>(`privatecamfilcustomers/${customerId}/users/${userId}/contact`)
      .pipe(map(CamfilB2bContactMapper.fromData));
  }

  connectUserWithCustomer(customerId: string, userId: string): Observable<boolean> {
    const body = true;

    const options: AvailableOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'text/plain',
        Accept: 'application/json',
      }),
    };

    return this.apiService
      .post<void>(`camfilcustomers/${customerId}/users/${userId}/grantRevoke`, body, options)
      .pipe(map(() => body));
  }

  disconnectUserFromCustomer(customerId: string, userId: string): Observable<boolean> {
    const body = false;

    const options: AvailableOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'text/plain',
        Accept: 'application/json',
      }),
    };

    return this.apiService
      .post<void>(`camfilcustomers/${customerId}/users/${userId}/grantRevoke`, body, options)
      .pipe(map(() => body));
  }

  disconnectUserFromCustomerPlusReload(customerId: string, userId: string): Observable<CamfilB2bUser> {
    return this.disconnectUserFromCustomer(customerId, userId).pipe(
      concatLatestFrom(() => this.managementFacade.getUser$(userId).pipe(whenTruthy())),
      concatMap(([, user]) => {
        const id = user.customers.find(customer => customer.id !== customerId)?.id;
        return id ? this.getCustomerUser(id, userId) : of({ ...user, customers: [], customerId: '' });
      })
    );
  }

  connectContactWithUserCustomer(
    customerId: string,
    userId: string,
    contact: CamfilB2bContact
  ): Observable<CamfilB2bContact> {
    const body = contact;

    return this.apiService
      .post<CamfilB2bContactData>(`privatecamfilcustomers/${customerId}/users/${userId}/contact`, body)
      .pipe(map(() => body));
  }

  connectUserFromCustomerAndContactPlusReload(
    customerId: string,
    userId: string,
    contact: CamfilB2bContact
  ): Observable<CamfilB2bUser> {
    return this.connectUserWithCustomer(customerId, userId).pipe(
      mergeMap(() =>
        this.connectContactWithUserCustomer(customerId, userId, contact).pipe(
          mergeMap(() => this.getCustomerUser(customerId, userId))
        )
      )
    );
  }

  getCustomerUserRoles(customerId: string, userId: string): Observable<CamfilB2bRole[]> {
    return this.apiService.get<B2bRoleData>(`privatecamfilcustomers/${customerId}/users/${userId}/role`).pipe(
      unpackEnvelope<B2bRoleData>('userRoles'),
      map(data => this.b2bRoleMapper.fromData(data)),
      defaultIfEmpty([])
    );
  }

  updateCustomerUserRoles(customerId: string, userId: string, roleIDs: string[]): Observable<CamfilB2bRole[]> {
    const body: CamfilB2bRoleIDsData = { userRoles: roleIDs };

    return this.apiService
      .put<CamfilB2bRoleIDsData>(`privatecamfilcustomers/${customerId}/users/${userId}/role`, body)
      .pipe(
        unpackEnvelope<B2bRoleData>('userRoles'),
        map(data => this.b2bRoleMapper.fromData(data))
      );
  }

  getCustomerUserApprovers(customerId: string, userId: string): Observable<string[]> {
    return this.apiService
      .get<CamfilB2bUserData>(`camfilrequisitions/customers/${customerId}/users/${userId}/approvers`)
      .pipe(
        unpackEnvelope<CamfilB2bUserData>('data'),
        map(data => CamfilB2bUserMapper.fromListData(data).map(user => user.currentLogin)),
        defaultIfEmpty([])
      );
  }

  setCustomerUserApprovers(customerId: string, userId: string, approverIds: string[]): Observable<string[]> {
    return this.apiService
      .put<CamfilB2bUserData>(`camfilrequisitions/customers/${customerId}/users/${userId}/approvers`, { approverIds })
      .pipe(
        unpackEnvelope<CamfilB2bUserData>('data'),
        map(data => CamfilB2bUserMapper.fromListData(data).map(user => user.currentLogin)),
        defaultIfEmpty([])
      );
  }

  resetCustomerUserPassword(customerId: string, userId: string, login: string) {
    const data: PasswordReminder = {
      email: login, // Camfil uses `login` instead of email address to recognize user.
    };

    const options: AvailableOptions = {
      skipApiErrorHandling: true,
      captcha: pick(data, ['captcha', 'captchaAction']),
    };

    const body = { answer: '', ...data };

    return this.apiService.post('security/reminder', body, options).pipe(
      map(() => ({
        customerId,
        userId,
        login,
        data,
      }))
    );
  }

  getOrganizationUsers(customerIDs: string[]): Observable<CamfilB2bUser[]> {
    return forkJoin([
      ...customerIDs.map(customerId =>
        this.getCustomerUsers(customerId).pipe(
          map(users => users.map(user => ({ ...user, customerId }))),
          defaultIfEmpty([]),
          catchError(() => of([]))
        )
      ),
    ]).pipe(concatAll());
  }
}
