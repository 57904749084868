import { ChangeDetectionStrategy, Component, HostBinding, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { CamfilQuotesFacade } from 'camfil-core/facades/camfil-quotes.facade';
import { CamfilQuote } from 'camfil-models/camfil-quotes/camfil-quote/camfil-quote.model';
import { CamfilFormClass } from 'camfil-shared/formly/utils/camfil-form';
import { cloneDeep } from 'lodash-es';
import { Observable, of } from 'rxjs';

import { FieldLibrary } from 'ish-shared/formly/field-library/field-library';
import { markAsDirtyRecursive } from 'ish-shared/forms/utils/form-utils';

export type CamfilEditQuoteDialogData = CamfilQuote;

export type CamfilEditQuoteDialogResult = never;

@Component({
  selector: 'camfil-edit-quite-dialog',
  templateUrl: './camfil-edit-quote-dialog.component.html',
  styleUrls: ['./camfil-edit-quote-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CamfilEditQuoteDialogComponent implements OnInit {
  constructor(
    private camfilQuotesFacade: CamfilQuotesFacade,
    private fieldLibrary: FieldLibrary,
    private matDialogRef: MatDialogRef<CamfilEditQuoteDialogComponent, CamfilEditQuoteDialogResult>,
    // eslint-disable-next-line ish-custom-rules/use-type-safe-injection-token
    @Inject(MAT_DIALOG_DATA) private dialogData: CamfilEditQuoteDialogData
  ) {}

  @HostBinding('class.camfil-dialog') camfilDialogClass = true;

  form: CamfilFormClass<CamfilQuote>;

  private getFormFields$(): Observable<FormlyFieldConfig[]> {
    return of([
      this.fieldLibrary.getConfiguration('idFLC'),
      this.fieldLibrary.getConfiguration('orderMarkFLC', {
        key: 'quotationReference',
        props: {
          label: 'camfil.quotes.quote_detail.reference',
        },
      }),
      this.fieldLibrary.getConfiguration('invoiceMarkFLC', {
        key: 'invoiceMark',
        props: {
          label: 'camfil.quotes.quote_detail.invoice_mark',
        },
      }),
      this.fieldLibrary.getConfiguration('customerFLC'),
      ...this.fieldLibrary.getConfigurationGroup('deliveryAddressFLG'),
      {
        fieldGroupClassName: 'd-flex justify-content-between',
        fieldGroup: this.fieldLibrary.getConfigurationGroup('deliveryAddressPostalCodeAndCityFLG'),
      },
      this.fieldLibrary.getConfiguration('customerServiceNoteFLC'),
    ]);
  }

  private getFormModel$(): Observable<CamfilQuote> {
    return of(cloneDeep(this.dialogData));
  }

  private createForm() {
    return new CamfilFormClass<CamfilQuote>({
      fields$: this.getFormFields$(),
      model$: this.getFormModel$(),
    });
  }

  ngOnInit(): void {
    this.form = this.createForm();
    markAsDirtyRecursive(this.form.instance);
  }

  submitForm() {
    if (this.form.formValid) {
      const quote = this.form.formRawValue;
      this.camfilQuotesFacade.updateCamfilQuote(quote);
      this.hide();
    }
  }

  hide() {
    this.matDialogRef.close();
  }
}
