import { Inject, Injectable } from '@angular/core';
import { Actions as OriginalActions } from '@ngrx/effects';
import { ScannedActionsSubject } from '@ngrx/store';
import { CamfilQueueFacade } from 'camfil-core/facades/camfil-queue.facade';
import { CamfilAction } from 'camfil-models/camfil-action/camfil-action.model';
import { Observable } from 'rxjs';

/* eslint-disable no-console, rxjs/finnish, ish-custom-rules/use-type-safe-injection-token */

/**
 * CamfilActions is an extension of the original Actions observable that allows for queuing actions.
 * This is useful when you need to ensure that certain actions are processed in a specific order.
 * The actions that need to be queued should be defined in the QUEUED_ACTION injection token.
 * The QUEUED_ACTION injection token should be an array of objects with the following structure:
 *
 * ```
 * {
 *   provide: QUEUED_ACTION,
 *   useValue: <QueuedActionConfig<string, { sku: string } | { product: Partial<AllProductTypes> }, CamfilAction>>{
 *     next: loadProduct,
 *     resolve: loadProductSuccess,
 *     reject: loadProductFail,
 *   },
 *   multi: true,
 * },
 * ```
 * The QUEUED_ACTION injection token should be provided in the module where the CamfilActions observable is used.
 * The CamfilActions observable will automatically filter out the queued actions from the source observable and process them in the order they were received.
 * The CamfilActions observable will also keep track of the processed and failed actions, and allow for retrying failed actions.
 * The CamfilActions observable will emit the queued actions in the order they were received, after the previous queued action has been completed.
 *`
 */
@Injectable({ providedIn: 'root' })
export class CamfilActions<V extends CamfilAction = CamfilAction> extends OriginalActions<V> {
  constructor(@Inject(ScannedActionsSubject) source: Observable<V>, camfilQueueFacade: CamfilQueueFacade<V>) {
    super(camfilQueueFacade.init$(source));
  }
}
