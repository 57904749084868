/* eslint-disable ish-custom-rules/ban-imports-file-pattern */
import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  inject,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { CamfilDialogFacade } from 'camfil-core/facades/camfil-dialog.facade';
import { CamCard } from 'camfil-models/camfil-cam-card/cam-card.model';
import { CamfilFormClass } from 'camfil-shared/formly/utils/camfil-form';
import { cloneDeep, merge } from 'lodash-es';
import { BehaviorSubject, Observable, debounceTime, filter, of } from 'rxjs';

import { CookiesService } from 'ish-core/utils/cookies/cookies.service';
import { whenTruthy } from 'ish-core/utils/operators';
import { FieldLibrary } from 'ish-shared/formly/field-library/field-library';
import { markAsDirtyRecursive } from 'ish-shared/forms/utils/form-utils';

import { CamCardDeliveryIntervalCheckingDialogComponent } from '../cam-card-delivery-interval-checking-dialog/cam-card-delivery-interval-checking-dialog.component';

@Component({
  selector: 'camfil-cam-card-preferences',
  templateUrl: './camfil-cam-card-preferences.component.html',
  styleUrls: ['./camfil-cam-card-preferences.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CamCardPreferencesComponent implements OnInit {
  constructor(
    private fieldLibrary: FieldLibrary,
    private camfilDialogFacade: CamfilDialogFacade,
    private cookiesService: CookiesService,
    private document: ElementRef
  ) {}

  private camCardValue: CamCard;
  private camCardSubject = new BehaviorSubject<CamCard>({});
  private destroyRef = inject(DestroyRef);

  @Input() camCardEditMode: CamCard;

  get isEditable() {
    return !!this.camCardEditMode?.id;
  }

  @Input() set camCard(camCard: CamCard) {
    this.camCardValue = camCard;
    this.camCardSubject.next(cloneDeep(camCard));
  }

  get camCard() {
    return this.camCardValue;
  }

  @Output() submitCamCard = new EventEmitter<CamCard>();
  @Output() editCamCard = new EventEmitter<CamCard | undefined>();
  @Output() formWithErrors = new EventEmitter<boolean>();

  camCardForm: CamfilFormClass<Partial<CamCard>>;
  submitted = false;
  isCollapsed = false;

  ngOnInit() {
    this.camCardForm = this.createCamCardForm();
    this.handleFormChanges();

    const status = JSON.parse(this.cookiesService.get('stopCheckingDeliveryInterval') || 'null');
    if (this.camCard && !status && !this.camCard.deliveryInterval) {
      this.openModalForDeliveryIntervalChecking();
    }
  }

  openModalForDeliveryIntervalChecking() {
    const dialogRef = this.camfilDialogFacade.open<CamCardDeliveryIntervalCheckingDialogComponent, never, boolean>(
      CamCardDeliveryIntervalCheckingDialogComponent
    );

    dialogRef
      .afterClosed()
      .pipe(whenTruthy(), takeUntilDestroyed(this.destroyRef))
      .subscribe(focus => {
        if (focus) {
          this.initEditMode();
          this.document.nativeElement.querySelector('[id*="deliveryInterval"]')?.focus();
        }
      });
  }

  get formDisabled() {
    return this.camCardForm.instance.invalid && this.submitted;
  }

  get toggleFormLabel() {
    return this.isCollapsed
      ? 'camfil.account.cam_card_preferences.maximize'
      : 'camfil.account.cam_card_preferences.minimize';
  }

  private createCamCardForm() {
    return new CamfilFormClass({
      fields$: this.getCamCardFormFields$(),
      model$: this.camCardSubject.asObservable(),
    });
  }

  private getCamCardFormFields$(): Observable<FormlyFieldConfig[]> {
    return of([
      {
        fieldGroupClassName: 'row',
        fieldGroup: [
          {
            className: 'col-md-4',
            type: 'camfil-fieldset-field',
            props: {
              legend: 'camfil.account.cam_card.add_to_form.camcards_details',
              legendClass: 'h3',
            },
            fieldGroup: this.fieldLibrary.getConfigurationGroup('camCardDetailsFLG', {
              orderMarkFLC: {
                key: 'orderLabel',
              },
              invoiceMarkFLC: {
                key: 'invoiceLabel',
              },
            }),
          },
          {
            className: 'col-md-4',
            type: 'camfil-fieldset-field',
            props: {
              legend: 'camfil.account.cam_card.add_to_form.delivery_address',
              legendClass: 'h3',
            },
            fieldGroup: [
              ...this.fieldLibrary.getConfigurationGroup('deliveryAddressFLG'),
              // TODO (extMlk): Create simple field configuration for this and combine postal code and city together as one type;
              {
                fieldGroupClassName: 'd-flex justify-content-between',
                fieldGroup: this.fieldLibrary.getConfigurationGroup('deliveryAddressPostalCodeAndCityFLG'),
              },
              this.fieldLibrary.getConfiguration('deliveryAddressCountryCodeFLC'),
            ],
          },
          {
            className: 'col-md-4',
            type: 'camfil-fieldset-field',
            props: {
              legend: 'camfil.account.cam_card.add_to_form.delivery_time',
              legendClass: 'h3',
            },
            fieldGroup: this.fieldLibrary.getConfigurationGroup('camCardDeliveryDetailsFLG'),
          },
        ],
      },
    ]);
  }

  get formValue(): CamCard {
    return merge({}, this.camCardValue, this.camCardForm.instance.getRawValue());
  }

  private handleFormChanges() {
    this.camCardForm
      .valueChanges$()
      .pipe(
        filter(() => this.isEditable),
        debounceTime(300),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(newFormValue => {
        this.editForm(newFormValue);
      });
  }

  initEditMode() {
    if (!this.isEditable) {
      this.editCamCard.emit();
    }
    this.formWithErrors.emit(this.camCardForm.instance.invalid);
  }

  editForm(newformValue: CamCard) {
    const invalid = this.camCardForm.instance.invalid;
    this.formWithErrors.emit(invalid);
    if (invalid) {
      this.submitted = true;
      markAsDirtyRecursive(this.camCardForm.instance);
      return;
    }

    this.editCamCard.emit(merge({}, this.formValue, newformValue));
  }

  submitForm() {
    if (this.camCardForm.instance.invalid) {
      this.submitted = true;
      markAsDirtyRecursive(this.camCardForm.instance);
      return;
    }

    this.submitCamCard.emit(this.formValue);
  }
}
