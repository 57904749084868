import { CamfilLineItem, CamfilLineItemView } from 'camfil-models/camfil-line-item/camfil-line-item.model';

import { PriceItem } from 'ish-core/models/price-item/price-item.model';
import { Price } from 'ish-core/models/price/price.model';

export class CamfilPriceHelper {
  static diff(p1: Price, p2: Price): Price {
    CamfilPriceHelper.sanityChecks(p1, p2);
    return {
      type: p1.type,
      currency: p1.currency,
      value: Math.round((p1.value - p2.value) * 100) / 100,
    };
  }

  /**
   * Inverts the value of a price
   *
   * @param price The price
   * @returns inverted price
   */
  static invert<T extends Price | PriceItem>(price: T): T {
    if (price) {
      if (price.type === 'Money') {
        return { ...price, value: (price as Price).value * -1 };
      }
      return { ...price, gross: (price as PriceItem).gross * -1, net: (price as PriceItem).net * -1 };
    }
  }

  static min(p1: Price, p2: Price): Price {
    CamfilPriceHelper.sanityChecks(p1, p2);
    return {
      type: p1.type,
      currency: p1.currency,
      value: Math.round(Math.min(p1.value, p2.value) * 100) / 100,
    };
  }

  static sum(p1: Price, p2: Price): Price {
    CamfilPriceHelper.sanityChecks(p1, p2);
    return {
      type: p1.type,
      currency: p1.currency,
      value: Math.round((p1.value + p2.value) * 100) / 100,
    };
  }

  static empty(currency?: string): Price {
    return {
      type: 'Money',
      value: 0,
      currency,
    };
  }

  static totalPrice(items: CamfilLineItemView[], type: 'net' | 'gross' = 'net'): Price {
    const getCurrency = (element: CamfilLineItemView) => element.price?.currency;
    const getValue = (element: CamfilLineItemView) => element.totals?.total?.[type];

    return CamfilPriceHelper.getPrice(getCurrency, getValue, items);
  }

  static totalTax(items: CamfilLineItemView[]): Price {
    const getCurrency = (element: CamfilLineItemView) => element.totals?.salesTaxTotal?.currency;
    const getValue = (element: CamfilLineItemView) => element.totals?.salesTaxTotal?.value;

    return CamfilPriceHelper.getPrice(getCurrency, getValue, items);
  }

  // static savedAmount(items: LineItemView[]): Price {
  //   const getCurrency = element => element.price?.currency;
  //   const getValue = element => element.totals?.total.gross - element.price?.gross;

  //   return PriceHelper.getPrice(getCurrency, getValue, items);
  // }

  static discount(items: CamfilLineItemView[]): Price {
    const getCurrency = (element: CamfilLineItemView) => element.price?.currency;
    const getValue = (element: CamfilLineItemView) =>
      element.totals?.total.gross - element.totals?.undiscountedTotal?.gross;

    return CamfilPriceHelper.getPrice(getCurrency, getValue, items);
  }

  static checkIfZeroPrice(data: Price | PriceItem) {
    return (
      !data ||
      ('value' in data && data.value === 0) ||
      ('gross' in data && data.gross === 0 && 'net' in data && data.net === 0)
    );
  }

  static getPrice(
    getCurrency: (item: CamfilLineItem) => string,
    getValue: (item: CamfilLineItem) => number,
    items: CamfilLineItemView[] = []
  ): Price {
    const price: Price = {
      type: 'Money',
      currency: 'USD',
      value: 0,
    };

    items?.forEach(element => {
      price.currency = getCurrency(element);
      price.value = price.value + getValue(element);
    });

    return price;
  }

  private static sanityChecks(p1: Price, p2: Price) {
    if (!p1 || !p2) {
      throw new Error('cannot handle undefined inputs');
    }
    if (!Number.isFinite(p1.value) || !Number.isFinite(p2.value)) {
      throw new Error('cannot handle undefined values');
    }
    if (!p1.currency || !p2.currency) {
      throw new Error('cannot handle undefined currency');
    }
    if (p1.currency !== p2.currency) {
      throw new Error('currency mispatch');
    }
  }
}
