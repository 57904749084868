import { createReducer, on } from '@ngrx/store';
import {
  closeSearchResultsOverlay,
  openSearchResultsOverlay,
  setSearchTerm,
  setSearchTermValid,
  unsetSearchTerm,
} from 'camfil-core/store/shopping/camfil-search/camfil-search.actions';
import { CamfilSearchHelper } from 'camfil-models/camfil-search/camfil-search.helper';
import { CAMFIL_QUICK_SEARCH_ID } from 'camfil-shared/camfil-constants';

import { HttpError } from 'ish-core/models/http-error/http-error.model';
import { ProductListingID } from 'ish-core/models/product-listing/product-listing.model';
import { setProductListingPages } from 'ish-core/store/shopping/product-listing';
import { searchProducts, searchProductsFail, suggestSearchSuccess } from 'ish-core/store/shopping/search';
import { setLoadingOn, unsetLoadingAndErrorOn, unsetLoadingOn } from 'ish-core/utils/ngrx-creators';

export const camfilSearchFeatureKey = 'camfilSearch';

function camfiLSearchProductListingId(searchTerm: string): ProductListingID {
  if (!searchTerm) {
    return;
  }

  return { type: CAMFIL_QUICK_SEARCH_ID, value: CamfilSearchHelper.handleSearchTermWithSlash(searchTerm) };
}

export interface CamfilSearchState {
  loading: boolean;
  error: HttpError;
  overlayOpen: boolean;
  productListingId: ProductListingID;
  searchTerm: string;
  searchTermValid: boolean;
}

const initialState: CamfilSearchState = {
  loading: false,
  error: undefined,
  overlayOpen: false,
  productListingId: camfiLSearchProductListingId(undefined),
  searchTerm: undefined,
  searchTermValid: false,
};

export const reducer = createReducer(
  initialState,
  setLoadingOn(searchProducts),
  unsetLoadingAndErrorOn(suggestSearchSuccess, unsetSearchTerm, setProductListingPages),
  unsetLoadingOn(searchProductsFail),
  on(setSearchTerm, (state, action): CamfilSearchState => {
    const searchTerm = action.payload?.searchTerm;
    return {
      ...state,
      searchTerm,
      productListingId: camfiLSearchProductListingId(searchTerm),
    };
  }),
  on(
    setSearchTermValid,
    (state, action): CamfilSearchState => ({
      ...state,
      searchTermValid: action.payload?.searchTermValid,
    })
  ),
  on(
    unsetSearchTerm,
    (state): CamfilSearchState => ({
      ...state,
      searchTerm: undefined,
      searchTermValid: false,
      productListingId: camfiLSearchProductListingId(undefined),
    })
  ),
  on(openSearchResultsOverlay, (state): CamfilSearchState => ({ ...state, overlayOpen: true })),
  on(closeSearchResultsOverlay, (state): CamfilSearchState => ({ ...state, overlayOpen: false }))
);
