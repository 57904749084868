<mat-accordion>
  <mat-expansion-panel [expanded]="!isCollapsed" (opened)="opened()" (closed)="closed()">
    <mat-expansion-panel-header>
      <mat-panel-title *ngIf="title">
        <h3 class="m-0 p-0 text-no-transform">{{ title }}</h3>
      </mat-panel-title>
      <mat-panel-description *ngIf="description">
        {{ description }}
      </mat-panel-description>
    </mat-expansion-panel-header>
    <ng-content></ng-content>
  </mat-expansion-panel>
</mat-accordion>
