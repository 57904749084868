import { AbstractControl, FormArray, FormGroup, UntypedFormGroup, ValidationErrors } from '@angular/forms';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { formatPayload } from 'camfil-core/utils/functions';
import { isEqual } from 'lodash-es';
import { BehaviorSubject, Observable, combineLatest, merge, of } from 'rxjs';
import { distinctUntilChanged, map, shareReplay, skip, switchMap, tap } from 'rxjs/operators';

import { markAsDirtyRecursive } from 'ish-shared/forms/utils/form-utils';

type CamfilFormConfig<T> = Omit<Partial<CamfilFormClass<T>>, 'instance'>;

export class CamfilFormClass<T> {
  instance: UntypedFormGroup;

  // eslint-disable-next-line ish-custom-rules/no-object-literal-type-assertion
  private modelSubject = new BehaviorSubject<T | undefined>(undefined as T);
  model$: Observable<T | undefined> = this.modelSubject.asObservable();

  private fieldsSubject = new BehaviorSubject<FormlyFieldConfig[]>(undefined);
  fields$: Observable<FormlyFieldConfig[]> = this.fieldsSubject.asObservable();

  private optionsSubject = new BehaviorSubject<FormlyFormOptions>(undefined);
  options$: Observable<FormlyFormOptions> = this.optionsSubject.asObservable();

  private submittedValue = false;

  constructor(config?: CamfilFormConfig<T>) {
    this.instance = new UntypedFormGroup({});

    if (config?.model$) {
      this.setModel$(config.model$);
    }
    if (config?.fields$) {
      this.setFields$(config.fields$);
    }
    if (config?.options$) {
      this.setOptions$(config.options$);
    }
  }

  private formatValues<T>(payload: T) {
    return formatPayload<T>(payload);
  }

  private waitForControl(fieldName: keyof T): Observable<AbstractControl> {
    return new Observable(observer => {
      const checkControl = () => {
        const control = this.instance.get(fieldName as string);
        if (control) {
          observer.next(control);
          observer.complete();
        } else {
          setTimeout(checkControl, 100); // Check again after a short delay
        }
      };
      checkControl();
    });
  }

  private getFieldValueChange$ = (fieldName: keyof T) =>
    this.waitForControl(fieldName).pipe(
      switchMap(control =>
        control.valueChanges.pipe(map(value => ({ [fieldName]: this.formatValues({ [fieldName]: value })[fieldName] })))
      )
    );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private applyPipeOperators = (source$: Observable<any>) =>
    source$.pipe(
      distinctUntilChanged((prev, curr) => isEqual(prev, curr)),
      shareReplay(1)
    );

  private getFormErrors(formGroup: FormGroup): Record<string, ValidationErrors> {
    const errors: Record<string, ValidationErrors> = {};

    Object.keys(formGroup.controls).forEach(key => {
      const control = formGroup.get(key);
      if (control instanceof FormGroup) {
        const nestedErrors = this.getFormErrors(control);
        if (Object.keys(nestedErrors).length > 0) {
          errors[key] = nestedErrors;
        }
      } else if (control instanceof FormArray) {
        const arrayErrors = control.controls.map(ctrl => this.getFormErrors(ctrl as FormGroup));
        if (arrayErrors.some(e => Object.keys(e).length > 0)) {
          errors[key] = arrayErrors;
        }
      } else {
        if (control.errors) {
          errors[key] = control.errors;
        }
      }
    });
    return errors;
  }

  get formSubmitted(): boolean {
    return this.submittedValue;
  }

  get formDisabled(): boolean {
    return (this.instance.invalid && this.formSubmitted) || this.instance.disabled;
  }

  get formInvalid(): boolean {
    return this.instance.invalid;
  }

  get formValid(): boolean {
    return this.instance.valid;
  }

  get formErrors() {
    return this.getFormErrors(this.instance);
  }

  get formValue(): T {
    return { ...this.instance.value };
  }

  get formRawValue(): T {
    return { ...this.instance.getRawValue() };
  }

  asObservable(): Observable<CamfilFormClass<T>> {
    return of(this);
  }

  setFields$(fields$: Observable<FormlyFieldConfig[]>) {
    fields$
      ?.pipe(
        tap(fields => {
          this.fieldsSubject.next(fields);
        })
      )
      .subscribe({
        error: err => console.error('Could not set fields$: ', err),
      });
  }

  setOptions$(options$: Observable<FormlyFormOptions>) {
    options$
      ?.pipe(
        tap(options => {
          this.optionsSubject.next(options);
        })
      )
      .subscribe({
        error: err => console.error('Could not set options$: ', err),
      });
  }

  setModel$(model$: Observable<T>) {
    model$
      ?.pipe(
        tap(model => {
          this.modelSubject.next(model);
        })
      )
      .subscribe({
        error: err => console.error('Could not set model$: ', err),
      });
  }

  valueChanges$(fieldNames?: keyof T | (keyof T)[], anyFieldChange = true): Observable<Partial<T>> {
    let output;

    if (fieldNames) {
      const fields = Array.isArray(fieldNames) ? fieldNames : [fieldNames];

      const changes$ = anyFieldChange
        ? merge(...fields.map(this.getFieldValueChange$))
        : combineLatest(fields.map(this.getFieldValueChange$));

      output = this.applyPipeOperators(
        changes$.pipe(
          // @ts-expect-error: not sure what is going on here
          map(changes => {
            const changesArray = Array.isArray(changes) ? changes : [changes];
            return { ...changesArray };
          })
        )
      );
    } else {
      output = this.applyPipeOperators(this.instance.valueChanges.pipe(map(value => this.formatValues(value))));
    }

    return output.pipe(skip(1));
  }

  submitForm(onSubmit?: (instance: CamfilFormClass<T>) => void): void {
    this.submittedValue = true;

    if (this.instance.invalid) {
      markAsDirtyRecursive(this.instance);
      return;
    }

    if (typeof onSubmit === 'function') {
      onSubmit(this);
    }
  }

  resetForm(
    onReset?: (instance: CamfilFormClass<T>) => void,
    options?: {
      onlySelf?: boolean;
      emitEvent?: boolean;
    }
  ): void {
    // eslint-disable-next-line unicorn/no-null
    this.instance.setErrors(null);
    this.instance.reset(options);
    this.instance.updateValueAndValidity(options);
    this.submittedValue = false;

    if (typeof onReset === 'function') {
      onReset(this);
    }
  }
}
