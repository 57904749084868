<div class="product-list row">
  <div *ngIf="!products.length">
    <ng-container *ngIf="(listingLoading$ | async) !== true">
      {{ 'search.noresult.filtered_products.message' | translate }}
    </ng-container>
  </div>

  <div *ngIf="listingLoading$ | async" class="product-list-loading">
    <ish-loading [standalone]="true" />
  </div>

  <ng-container
    *ngFor="let sku of products"
    ishProductContext
    [sku]="sku"
    [categoryId]="categoryId"
    [configuration]="{ shipment: false }"
  >
    <div *ngIf="isGrid" class="col-6 col-lg-4 product-list-item grid-view">
      <ish-product-item displayType="tile" />
    </div>

    <div *ngIf="isList" class="col-lg-12 product-list-item list-view">
      <ish-product-item displayType="row" />
    </div>
  </ng-container>

  <div *ngIf="listingLoading$ | async" class="col-12">
    <ish-loading [standalone]="true" />
  </div>
</div>
