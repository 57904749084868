<address *ngIf="address">
  <!-- b2b Customer only -->
  <ng-container *ngIf="address.companyName1"> {{ address.companyName1 }}<br /> </ng-container>
  <ng-container *ngIf="address.companyName2"> {{ address.companyName2 }}<br /> </ng-container>

  <ng-container *ngIf="countryCode !== 'DE'; else displayAddressLineInverse">
    <ng-container *ngIf="address.addressLine1">{{ address.addressLine1 }}<br /></ng-container>
    <ng-container *camfilChannelToggle="'use2ndAddressLineInCamCardForm'">
      <ng-container *ngIf="address.addressLine2">{{ address.addressLine2 }}<br /></ng-container>
    </ng-container>
    <ng-container *ngIf="address.addressLine3">{{ address.addressLine3 }}<br /></ng-container>
  </ng-container>

  <ng-template #displayAddressLineInverse>
    <ng-container *camfilChannelToggle="'use2ndAddressLineInCamCardForm'">
      <ng-container *ngIf="address.addressLine2">{{ address.addressLine2 }}<br /></ng-container>
    </ng-container>
    <ng-container *ngIf="address.addressLine1">{{ address.addressLine1 }}<br /></ng-container>
    <ng-container *ngIf="address.addressLine3">{{ address.addressLine3 }}<br /></ng-container>
  </ng-template>

  <ng-container
    *ngIf="['DE', 'AT', 'FR', 'SE'].includes(address.countryCode || countryCode); else displayPostalCodeAfterCity"
  >
    <ng-container *ngIf="address.postalCode">{{ address.postalCode }}&nbsp;</ng-container>
    <ng-container *ngIf="address.city">{{ address.city }}</ng-container>
    <br />
  </ng-container>

  <ng-template #displayPostalCodeAfterCity>
    <ng-container *ngIf="address.city">{{ address.city }}&nbsp;</ng-container>
    <ng-container *ngIf="address.countryCode === 'GB'"><br /></ng-container>
    <ng-container *ngIf="address.countryCode === 'US'"
      >,
      <ng-container *ngIf="address.mainDivisionCode">{{ address.mainDivisionCode }}&nbsp;</ng-container>
    </ng-container>
    <ng-container *ngIf="address.postalCode">{{ address.postalCode }}</ng-container>
    <br />
  </ng-template>

  <ng-container *ngIf="address.countryCode !== 'US' && address.mainDivision">
    {{ address.mainDivision }}&nbsp;
  </ng-container>

  <ng-container *ngIf="address.country">{{ address.country }}<br /></ng-container>

  <ng-container *ngIf="address.phoneHome">{{ address.phoneHome }}<br /></ng-container>
  <ng-container *ngIf="displayEmail && address.email">{{ address.email }}<br /></ng-container>
</address>
