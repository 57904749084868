import { createAction } from '@ngrx/store';

import { payload } from 'ish-core/utils/ngrx-creators';

export const getWarehouseCalendar = createAction('[CamfilCalendarExceptions] Get warehouse calendar');

export const getWarehouseCalendarSuccess = createAction(
  '[CamfilCalendarExceptions] Get warehouse calendar Success',
  payload<{ dates: [] }>()
);
