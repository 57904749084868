import { ChangeDetectionStrategy, Component, DestroyRef, OnInit, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import { CamfilAccountFacade } from 'camfil-core/facades/camfil-account.facade';
import { CamfilZipCodeAddress } from 'camfil-models/camfil-zip-code-address/camfil-zip-code-address.model';
import { Observable, map, take } from 'rxjs';

import { whenTruthy } from 'ish-core/utils/operators';

@Component({
  selector: 'camfil-city-dynaimc-field',
  templateUrl: './camfil-city-dynamic-field.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
// eslint-disable-next-line ish-custom-rules/require-formly-code-documentation
export class CamfilCityDynamicFieldComponent extends FieldType<FieldTypeConfig> implements OnInit {
  citiesList$: Observable<CamfilZipCodeAddress[]>;

  private destroyRef = inject(DestroyRef);

  constructor(private accountFacade: CamfilAccountFacade) {
    super();
  }

  ngOnInit() {
    const zipcode = this.field.form.get('zipcode').value;
    if (zipcode) {
      this.citiesList$ = this.accountFacade.getAllZipCodeAddresses$().pipe(
        take(2),
        whenTruthy(),
        map((zipMap: { [x: string]: CamfilZipCodeAddress[] }) => {
          const zipcodeData = zipMap[zipcode];
          if (zipcodeData?.length === 1) {
            this.formControl.setValue(zipcodeData[0].city);
          }
          return zipcodeData;
        }),
        takeUntilDestroyed(this.destroyRef)
      );
    }

    this.field.form
      .get('zipcode')
      .valueChanges.pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(zip => {
        // eslint-disable-next-line unicorn/no-null
        this.formControl.setValue(null);
        // eslint-disable-next-line unicorn/no-null
        this.formControl.setErrors(null);
        this.citiesList$ = this.accountFacade.getAllZipCodeAddresses$().pipe(
          whenTruthy(),
          map((zipMap: { [x: string]: CamfilZipCodeAddress[] }) => {
            const zipcodeData = zipMap[zip];
            if (zipcodeData?.length === 1) {
              this.formControl.setValue(zipcodeData[0].city);
            }
            return zipcodeData;
          }),
          takeUntilDestroyed(this.destroyRef)
        );
      });
  }

  pickCity(city: string) {
    this.formControl.setValue(city);
  }
}
