import { createSelector } from '@ngrx/store';
import { getCamfilFeatureState } from 'camfil-core/store/camfil-store';
import { Manufacturer } from 'camfil-models/camfil-ahu/manufacturer/manufacturer.model';

import { AhuManufacturerState, ahuManufacturerFeatureKey, manufacturerAdapter } from './manufacturer.reducer';

const getManufacturerState = getCamfilFeatureState<AhuManufacturerState>(ahuManufacturerFeatureKey);

const { selectEntities, selectAll } = manufacturerAdapter.getSelectors(getManufacturerState);

export const getAllAhuManufacturers = selectAll;
export const areAhuManufacturersLoaded = createSelector(selectAll, (manufacturers): boolean => !!manufacturers?.length);

export const getAhuManufacturerLoading = createSelector(getManufacturerState, state => state.loading);

export const getAhuManufacturerError = createSelector(getManufacturerState, state => state.error);

export const getSelectedAhuManufacturerId = createSelector(getManufacturerState, state => state.selected);

export const getSelectedAhuManufacturer = createSelector(
  selectEntities,
  getSelectedAhuManufacturerId,
  (entities, id): Manufacturer => id && entities[id]
);

export const isManufacturerInitialized = createSelector(getManufacturerState, state => state.initialized);
