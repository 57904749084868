import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { CamfilOrderService } from 'camfil-core/services/order/camfil-order.service';
import { getOrdersEntities } from 'camfil-core/store/camfil-orders/camfil-orders.selectors';
import { EMPTY, from, iif, merge, of } from 'rxjs';
import { catchError, concatMap, filter, map, mergeMap, switchMap } from 'rxjs/operators';

import { CheckoutStepType } from 'ish-core/models/checkout/checkout-step.type';
import { ProductCompletenessLevel } from 'ish-core/models/product/product.model';
import { RoleToggleService } from 'ish-core/role-toggle.module';
import { displayErrorMessage, displaySuccessMessage } from 'ish-core/store/core/messages';
import { ofUrl, selectRouteParam } from 'ish-core/store/core/router';
import { submitBasketSuccess } from 'ish-core/store/customer/basket';
import { createOrderFail, createOrderSuccess } from 'ish-core/store/customer/orders';
import { getSelectedOrderId } from 'ish-core/store/customer/orders/orders.selectors';
import { loadProductIfNotLoaded } from 'ish-core/store/shopping/products';
import { AuthorizationToggleService } from 'ish-core/utils/authorization-toggle/authorization-toggle.service';
import { mapErrorToAction, mapToPayload, mapToPayloadProperty, whenTruthy } from 'ish-core/utils/operators';

import { updateCamCardsAfterCreateOrder } from '../cam-cards/cam-cards.actions';
import { continueCamfilCheckout, reloadBasket } from '../camfil-basket/camfil-basket.actions';
import { getCurrentCamfilBasketOrderType, getSelectedCamfilBasketId } from '../camfil-basket/camfil-basket.selectors';
import { getCurrentCamfilBucketsItems } from '../camfil-bucket/camfil-bucket.selectors';
import { trackCreateOrderCamfil } from '../camfil-tracking-events/camfil-tracking-events.actions';

import {
  cloneCamfilOrder,
  cloneCamfilOrderFail,
  cloneCamfilOrderSuccess,
  createCamfilOrder,
  loadCamfilOrder,
  loadCamfilOrderFail,
  loadCamfilOrderIfNotLoaded,
  loadCamfilOrderSuccess,
  loadCamfilOrders,
  loadCamfilOrdersFailure,
  loadCamfilOrdersIfNotLoaded,
  loadCamfilOrdersSuccess,
  selectOrder,
} from './camfil-orders.actions';

@Injectable()
export class CamfilOrdersEffects {
  loadCamfilOrders$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadCamfilOrders),
      switchMap(() =>
        this.camfilOrderService.getCamfilOrders().pipe(
          map(orders => loadCamfilOrdersSuccess({ orders })),
          catchError(error => of(loadCamfilOrdersFailure({ error })))
        )
      )
    )
  );

  loadCamfilOrdersIfNotLoaded$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadCamfilOrdersIfNotLoaded),
      concatLatestFrom(() => this.store.pipe(select(getOrdersEntities))),
      filter(([, entities]) => Object.keys(entities).length === 0),
      map(() => loadCamfilOrders())
    )
  );

  /**
   * Triggers a SelectOrder action if route contains orderId query or route parameter.
   */
  routeListenerForSelectingOrder$ = createEffect(() =>
    merge(this.store.pipe(ofUrl(/^\/account\/orders.*/), select(selectRouteParam('camfilOrderId')))).pipe(
      concatLatestFrom(() => this.store.pipe(select(getSelectedOrderId))),
      filter(([fromAction, selectedOrderId]) => fromAction && fromAction !== selectedOrderId),
      map(([orderId]) => orderId),
      map(camfilOrderId => selectOrder({ camfilOrderId }))
    )
  );

  /**
   * Selects and loads an order.
   */
  loadOrderForSelectedOrder$ = createEffect(() =>
    iif(
      () => !SSR,
      this.actions$.pipe(
        ofType(selectOrder),
        mapToPayloadProperty('camfilOrderId'),
        whenTruthy(),
        map(camfilOrderId => loadCamfilOrder({ camfilOrderId }))
      ),
      EMPTY
    )
  );

  loadCamfilOrder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadCamfilOrder),
      mapToPayloadProperty('camfilOrderId'),
      concatMap(orderId =>
        this.camfilOrderService.getCamfilOrder(orderId).pipe(
          map(order => loadCamfilOrderSuccess({ order })),
          mapErrorToAction(loadCamfilOrderFail)
        )
      )
    )
  );

  loadCamfilOrderIfNotLoaded$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadCamfilOrderIfNotLoaded),
      mapToPayload(),
      concatLatestFrom(() => this.store.pipe(select(getOrdersEntities))),
      filter(([{ camfilOrderId }, entities]) => camfilOrderId && !entities[camfilOrderId]),
      map(([{ camfilOrderId }]) => loadCamfilOrder({ camfilOrderId }))
    )
  );

  loadProductsForSelectedCamfilOrderLineItems$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadCamfilOrderSuccess),
      mapToPayloadProperty('order'),
      switchMap(({ lineItems }) => [
        ...lineItems.map(({ sku }) => loadProductIfNotLoaded({ sku, level: ProductCompletenessLevel.List })),
      ])
    )
  );

  cloneCamfilOrder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(cloneCamfilOrder),
      mapToPayloadProperty('orderId'),
      concatLatestFrom(() => this.roleToggleService.hasRole('APP_B2B_OCI_USER')),
      concatMap(([orderId, oci]) =>
        this.camfilOrderService
          .cloneCamfilOrder(orderId, oci)
          .pipe(map(cloneCamfilOrderSuccess), mapErrorToAction(cloneCamfilOrderFail))
      )
    )
  );

  redirectAfterCamfilOrderClone$ = createEffect(
    () =>
      iif(
        () => !SSR,
        this.actions$.pipe(
          ofType(cloneCamfilOrderSuccess),
          concatLatestFrom(() => [
            this.authorizationService.isAuthorizedTo('APP_B2B_MAKE_REQUISITION'),
            this.authorizationService.isAuthorizedTo('APP_B2B_MAKE_QUOTATION'),
          ]),
          concatMap(([, hasRequisitionRole, hasQuotationRole]) => {
            let creationMessage: string;
            if (hasRequisitionRole) {
              creationMessage = 'camfil.checkout.message.requisition_added';
            } else if (hasQuotationRole) {
              creationMessage = 'camfil.checkout.message.quotation_added';
            } else {
              creationMessage = 'camfil.checkout.message.order_created';
            }
            return from(this.router.navigateByUrl('/checkout')).pipe(
              concatMap(() => [
                displaySuccessMessage({
                  message: creationMessage,
                }),
                continueCamfilCheckout({ targetStep: CheckoutStepType.Addresses }),
                reloadBasket(),
              ])
            );
          })
        ),
        EMPTY
      ),
    { dispatch: true }
  );

  displayCreateOrderCloneFailMessage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(cloneCamfilOrderFail),
      mapToPayloadProperty('error'),
      whenTruthy(),
      map(error =>
        displayErrorMessage({
          message: error?.message || error?.code,
        })
      )
    )
  );

  /**
   * Creates an order based on the given basket.
   */
  createCamfilOrder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createCamfilOrder),
      concatLatestFrom(() => [
        this.store.pipe(select(getCurrentCamfilBasketOrderType)),
        this.store.pipe(select(getSelectedCamfilBasketId)),
        this.store.pipe(select(getCurrentCamfilBucketsItems)),
      ]),
      mergeMap(([, orderType, id, items]) =>
        this.camfilOrderService.createCamfilOrder(id, true, orderType).pipe(
          concatMap(orders => [
            submitBasketSuccess(),
            trackCreateOrderCamfil({ order: orders[0], items }),
            createOrderSuccess({ order: orders[0] }),
            updateCamCardsAfterCreateOrder({ orders }),
          ]),
          mapErrorToAction(createOrderFail)
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private camfilOrderService: CamfilOrderService,
    private store: Store,
    private router: Router,
    private roleToggleService: RoleToggleService,
    private authorizationService: AuthorizationToggleService
  ) {}
}
