import { FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';

export class CamfilSpecialValidators {
  static username(control: FormControl): { [error: string]: { valid: boolean } } {
    const usernamePattern = /^[a-z0-9]([._-](?![._-])|[a-z0-9]){3,36}[a-z0-9]$/;
    return usernamePattern.test(control.value) ? undefined : { username: { valid: false } };
  }

  static commaSeparatedEmailValidator = (control: FormControl): { [key: string]: unknown } | undefined => {
    const emails = control?.value?.split(',').map((e: string) => e.trim());
    const forbidden = emails.some((email: string) => Validators.email(new FormControl(email)));
    return forbidden ? { toAddress: { value: control.value } } : undefined;
  };

  static atLeastOneFilled(): ValidatorFn {
    // eslint-disable-next-line unicorn/no-null
    return (group: FormGroup): ValidationErrors | null => {
      // If the form group is dirty, don't apply this validation
      if (group.dirty) {
        // eslint-disable-next-line unicorn/no-null
        return null;
      }

      const controlsArray = Object.values(group.controls);

      const hasAtLeastOneTruthyValue = controlsArray.some(control => {
        const value = control.value;
        return value && value !== '';
      });

      // eslint-disable-next-line unicorn/no-null
      const error = !hasAtLeastOneTruthyValue ? { atLeastOneFilled: { valid: false } } : null;

      group.setErrors(error);

      return error;
    };
  }
}
