<nav class="breadcrumbs row" [attr.aria-label]="'breadcrumbs.aria_label' | translate">
  <ol class="breadcrumbs-list">
    <li *ngIf="showHome" class="breadcrumbs-list breadcrumbs-list-home">
      <a class="breadcrumbs-list-link breadcrumbs-list-link-home" rel="home" routerLink="/home">{{
        'common.home.link' | translate
      }}</a
      ><span class="breadcrumb-list-separator">{{ separator }}</span>
    </li>

    <li *ngIf="account" class="breadcrumbs-list">
      <ng-container *ngIf="(trail$ | async)?.length; else AccountOverview">
        <a class="breadcrumbs-list-link" routerLink="/account">{{ 'account.my_account.link' | translate }}</a
        ><span class="breadcrumb-list-separator">{{ separator }}</span>
      </ng-container>
      <ng-template #AccountOverview>
        {{ 'account.my_account.link' | translate }}
      </ng-template>
    </li>

    <li
      *ngFor="let item of trail$ | async; index as index; let last = last"
      class="breadcrumbs-list"
      [ngClass]="{ 'breadcrumbs-list-active': last }"
    >
      <a *ngIf="item.link; else noLink" [routerLink]="item.link" class="breadcrumbs-list-link">{{
        item.text || (item.key | translate)
      }}</a>
      <ng-template #noLink>
        <div aria-current="location">{{ item.text || (item.key | translate) }}</div>
      </ng-template>
      <span *ngIf="!last" class="breadcrumb-list-separator" aria-hidden="true">{{ separator }}</span>
    </li>
  </ol>
</nav>
