import { Environment } from './environment.model';

/* eslint-disable */

// running out of memory?
// NODE_OPTIONS=--max_old_space_size=8192

export const overrides: Partial<Environment> = {
  debugStream: 'stderr',
  iccToken: 'dff3ff6a7f9d4604ba1e703ba88d5a35',
};
