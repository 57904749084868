<div>
  <h3>{{ 'checkout.orderReferenceId.title' | translate }}</h3>

  <form [formGroup]="form" (ngSubmit)="submitForm()">
    <div class="row">
      <div *ngIf="showSuccessMessage" class="col-md-12">
        <ish-success-message message="checkout.orderReferenceId.success.message" [toast]="false" />
      </div>
      <div class="col-md-6">
        <formly-form [form]="form" [fields]="fields" [model]="model" />
      </div>
      <div class="col-md-2 pl-md-0">
        <button type="submit" class="btn btn-secondary" [disabled]="disabled">
          {{ 'checkout.orderReferenceId.apply.button.label' | translate }}
        </button>
      </div>
    </div>
  </form>
</div>
