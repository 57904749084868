import { Injectable } from '@angular/core';
import { Validators } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { CamfilMaxLengthTypes } from 'camfil-models/camfil-max-length-validator/camfil-max-length.types';

import { FieldLibraryConfiguration } from 'ish-shared/formly/field-library/configurations/field-library-configuration';

@Injectable()
export class PhoneNumberConfiguration extends FieldLibraryConfiguration {
  id = 'phoneNumberFLC';

  getFieldConfig(): FormlyFieldConfig {
    return {
      key: 'phoneNumber',
      type: 'camfil-phone-field',
      props: {
        label: 'camfil.account.apply_form.phoneNumber',
        required: false,
      },
      validators: {
        validation: [Validators.maxLength(CamfilMaxLengthTypes.PhoneNumber)],
      },
      validation: {
        messages: {
          phone: 'form.phone.error.invalid',
          maxlength: 'camfil.checkout.order_header.phone_number.error.maxLength',
          required: 'camfil.modal.createOrder.order-form.input.phone_number.error.pattern',
        },
      },
    };
  }
}
