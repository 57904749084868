import { CamfilBucket } from 'camfil-models/camfil-bucket/camfil-bucket.model';
import { CamCard, CamCardItem } from 'camfil-models/camfil-cam-card/cam-card.model';
import { CamfilLineItemHelper } from 'camfil-models/camfil-line-item/camfil-line-item.helper';

export class CamfilCreateNewCamcardMapper {
  static mapBucketToCamCard(bucket: CamfilBucket): CamCard {
    const camCardItems = bucket.lineItems.map(item => {
      const singleItem: CamCardItem = {
        quantity: item.quantity.value,
        product: {
          sku: item.productSKU,
        },
      };

      const boxLabel = CamfilLineItemHelper.getValFromAttrs<string>(item, 'boxLabel');

      if (boxLabel) {
        singleItem.comment = { label: boxLabel };
      }

      const measurement = {
        width: CamfilLineItemHelper.getValFromAttrs<number>(item, 'width'),
        height: CamfilLineItemHelper.getValFromAttrs<number>(item, 'height'),
        diameter: CamfilLineItemHelper.getValFromAttrs<number>(item, 'diameter'),
      };

      if (Object.values(measurement).filter(x => x).length) {
        singleItem.measurement = measurement;
      }

      return singleItem;
    });

    return {
      name: CamfilCreateNewCamcardMapper.generateCamCardName(bucket.orderMark),
      customer: bucket.customer,
      invoiceLabel: bucket.invoiceLabel,
      orderLabel: bucket.orderMark,
      reminderFlag: true,
      deliveryAddress: bucket.deliveryAddress,
      camCardItems,
    };
  }

  static generateCamCardName(orderMark: string): string {
    return `${orderMark || CamfilCreateNewCamcardMapper.getTimestamp()}`;
  }

  static getTimestamp(): string {
    return new Date().toLocaleString().replace(', ', '_');
  }
}
