import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  Input,
  OnInit,
  ViewChild,
  inject,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CamCardsFacade } from 'camfil-core/facades/camfil-cam-cards.facade';
import { CamCard, CamCardItem, CamCardItemComment } from 'camfil-models/camfil-cam-card/cam-card.model';
import { CamCardEditModeComponent } from 'camfil-shared/cam-cards/components/cam-card-edit-mode/cam-card-edit-mode.component';

@Component({
  selector: 'camfil-cam-card-product-comment',
  templateUrl: './cam-card-product-comment.component.html',
  styleUrls: ['./cam-card-product-comment.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CamCardProductCommentComponent extends CamCardEditModeComponent implements OnInit {
  @Input() camCard: CamCard;
  currCamCardItem: CamCardItem;
  @Input() set camCardItem(camCardItem: CamCardItem) {
    this.currCamCardItem = camCardItem;
    if (this.inputs.length) {
      this.inputs.forEach(input => {
        this.commentForm.patchValue(
          { [input]: this.currCamCardItem?.comment?.[input] },
          { onlySelf: false, emitEvent: true }
        );
      });
    }
  }

  @Input() mode: 'edit' | 'view';
  @ViewChild('autosize') autosize: CdkTextareaAutosize;

  commentForm: FormGroup;
  inputs: (keyof CamCardItemComment)[] = [];
  visibility = false;

  private destroyRef = inject(DestroyRef);
  constructor(private fb: FormBuilder, private cdr: ChangeDetectorRef, private camCardsFacade: CamCardsFacade) {
    super(camCardsFacade);
  }

  ngOnInit() {
    if (this.mode === 'edit') {
      this.initForm();

      this.camCardsFacade.getCamCardEditMode$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(edit => {
        this.visibility = this.currCamCardItem?.comment?.text && !!edit;
        this.cdr.detectChanges();
      });
    }
  }

  private initForm() {
    this.commentForm = this.fb.group({
      label: [this.currCamCardItem?.comment?.label],
      text: [this.currCamCardItem?.comment?.text],
    });
    this.inputs = Object.keys(this.commentForm.value) as unknown as (keyof CamCardItemComment)[];
  }

  keyupEnter(target: EventTarget) {
    (target as HTMLAreaElement).blur();
  }

  onBlur(target: EventTarget, type: keyof CamCardItemComment) {
    const oldValue = this.currCamCardItem.comment?.[type];
    const newValue = (target as EventTarget & { value: string }).value;

    if (newValue !== oldValue) {
      const newItem = { ...this.currCamCardItem, comment: { ...this.commentForm.value } };
      this.camCardsFacade.updateCamCardProduct(this.camCard.rootCamCard, this.camCard.id, newItem);
    }
  }

  toggleVisibility() {
    this.onFocus();
    this.visibility = true;
    this.cdr.detectChanges();
  }
}
