import { createAction } from '@ngrx/store';

import { ProductLinksDictionary } from 'ish-core/models/product-links/product-links.model';
import { httpError, payload } from 'ish-core/utils/ngrx-creators';

export const loadCategoryLinks = createAction('[Camfil Categories] Load Category Links', payload<{ key: string }>());

export const loadCategoryLinksFail = createAction('[Camfil Categories] Load Category Links Fail', httpError());

export const loadCategoryLinksSuccess = createAction(
  '[Camfil Categories] Load Category Links Success',
  payload<{ key: string; links: ProductLinksDictionary }>()
);
