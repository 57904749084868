import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { CategoryView } from 'ish-core/models/category-view/category-view.model';

@Component({
  selector: 'camfil-category-box',
  templateUrl: './camfil-category-box.component.html',
  styleUrls: ['./camfil-category-box.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CamfilCategoryBoxComponent {
  @Input() size: 'small' | 'normal' = 'normal';
  @Input() category: CategoryView;
  @Output() clickEvent = new EventEmitter();

  handleClick() {
    this.clickEvent.emit();
  }
}
