import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CamfilDialogFacade } from 'camfil-core/facades/camfil-dialog.facade';

import { MessageFacade } from 'ish-core/facades/message.facade';
import { HttpError } from 'ish-core/models/http-error/http-error.model';

type CamfilErrorMessageDisplayType = 'toast' | 'dialog' | 'inline';

@Component({
  selector: 'camfil-error-message',
  templateUrl: './camfil-error-message.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CamfilErrorMessageComponent implements OnChanges {
  @Input() error: HttpError;
  @Input() displayType: CamfilErrorMessageDisplayType = 'dialog';
  @Input() messageDuration = 3000;

  constructor(
    private messageFacade: MessageFacade,
    private camfilDialogFacade: CamfilDialogFacade,
    private translate: TranslateService
  ) {}

  get errorTitle() {
    return this.error?.status ? `camfil.error.${this.error?.status}.title` : 'camfil.error.unknown.title';
  }

  get errorMessage() {
    return this.extractErrorMessageFromString(this.error?.message);
  }

  get errorCode() {
    return (
      this.error?.code ||
      (this.error?.status ? `camfil.error.${this.error?.status}.message` : 'camfil.error.unknown.message')
    );
  }

  ngOnChanges() {
    this.displayErrorMessage();
  }

  private displayErrorMessage() {
    if (!this.error) {
      return;
    }

    switch (this.displayType) {
      case 'toast':
        this.displayToastMessage(this.error);
        break;
      case 'dialog':
        this.displayAlertDialogMessage(this.error);
        break;
      case 'inline':
        this.displayInlineMessage(this.error);
        break;
      default:
        break;
    }
  }

  private displayToastMessage(err: HttpError) {
    if (!err?.errors) {
      this.messageFacade.error({
        title: this.errorMessage,
        message: this.errorMessage,
        duration: this.messageDuration,
      });
    } else {
      err?.errors.map(cause => {
        this.messageFacade.error({
          title: cause?.code || cause?.message,
          message: cause?.code || cause?.message,
          duration: this.messageDuration,
        });
      });
    }
  }

  private displayAlertDialogMessage(err: HttpError) {
    if (!err?.errors) {
      this.camfilDialogFacade.alert({
        data: {
          title: this.translate.instant(this.errorTitle),
          message: this.translate.instant(this.errorMessage),
          dismissButtonLabel: 'camfil.modal.cta.button.ok',
          icon: {
            svg: 'error',
          },
        },
      });
    }
  }

  private displayInlineMessage(_err: HttpError) {
    // NOOP
  }

  private extractErrorMessageFromString(input: string) {
    if (!input) {
      return input;
    }

    const regex = /\((.*?)\)/;
    const match = input.match(regex);
    return match ? match[1] : input;
  }
}
