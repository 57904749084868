import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { CamfilAppModule } from './app/camfil-app.module';

if (PRODUCTION_MODE) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(CamfilAppModule)
  .catch(err => console.error(err));
