<h2 camfilDialogTitle>
  <ng-container *ngIf="isQuote$ | async; else headerByRole">
    <span>{{ 'checkout.receipt.confirmation.modal.header_quote' | translate }}</span>
  </ng-container>
  <ng-template #headerByRole>
    <camfil-content-by-role>
      <span class="role-default">{{ 'checkout.receipt.confirmation.modal.header' | translate }}</span>
      <span class="role-requisition">{{ 'checkout.receipt.confirmation.modal.header_requisition' | translate }}</span>
    </camfil-content-by-role>
  </ng-template>
</h2>
<div camfilDialogContent>
  <!-- for quote -->
  <ng-container *ngIf="isQuote$ | async">
    <span>{{ 'checkout.receipt.confirmation.modal.content_quote' | translate }}</span>
  </ng-container>

  <camfil-content-by-role>
    <div class="role-requisition">
      <p>{{ 'checkout.receipt.confirmation.modal.content_requisition' | translate }}</p>
      <p *ngIf="lastRequisitionId$ | async as lastRId">
        {{ 'checkout.receipt.confirmation.modal.extra.content_requisition' | translate }}
        <button
          color="primary"
          [disabled]="!lastRId"
          mat-flat-button
          class="ml-2"
          (click)="openRequisitionPage(lastRId)"
        >
          <ng-container *ngIf="lastRId">
            {{ 'checkout.receipt.confirmation.modal.extra.content.btn_requisition' | translate }}</ng-container
          >
        </button>
      </p>
    </div>
  </camfil-content-by-role>

  <ng-container *ngIf="suggestCamCardCreationAfterOrderSubmission$ | async">
    <mat-divider class="my-4" />
    <h3 class="my-0">
      {{ 'checkout.receipt.createCamCardsDialog.title' | translate }}
    </h3>
  </ng-container>
  <camfil-loading *ngIf="ordersLoading$ | async" />
</div>
<div camfilDialogActions>
  <ng-template #closeBtnTemplate>
    <button mat-flat-button color="primary" type="button" (click)="close()">
      {{ 'camfil.modal.cta.button.ok' | translate }}
    </button>
  </ng-template>
  <ng-container *ngIf="suggestCamCardCreationAfterOrderSubmission$ | async; else closeBtnTemplate">
    <button class="mx-2" mat-flat-button color="primary" type="button" (click)="createCamCardsFromBuckets()">
      {{ 'checkout.receipt.createCamCardsDialog.accept' | translate }}
    </button>
    <button class="mx-2" mat-flat-button color="primary" type="button" (click)="closeSuggestCamCardCreation()">
      {{ 'checkout.receipt.createCamCardsDialog.cancel' | translate }}
    </button>
  </ng-container>
</div>
