<!-- eslint-disable @angular-eslint/template/click-events-have-key-events -->
<ng-container *ngIf="product$ | async as product; else notLoaded">
  <p *ngIf="camCardItem?.measurement?.valid === false" class="mat-mdc-form-field-error">
    {{ 'camfil.cam_card.item.measurement.valid.error' | translate }}
  </p>

  <div class="d-flex align-items-center" [ngClass]="{ 'edit-view': isEditPage }">
    <mat-icon *ngIf="isEditPage" svgIcon="move" class="handle" color="primary" cdkDragHandle />
    <div class="line-item-cc line-item d-flex align-items-center align-self-start align-self-md-center text-left">
      <div class="line-item-cc__name d-flex align-items-center">
        <div class="my-2 my-md-0">
          <div class="d-sm-flex flex-row align-items-center">
            {{ index }}.&nbsp;
            <camfil-product-title [class]="'text-truncate'" [product]="product" />
            <camfil-product-lead-time [showText]="false" class="pl-2" />
          </div>
          <div class="attributes">
            <camfil-product-attribute [name]="'camfil.cam_card.product.id.label' | translate" [value]="product.sku" />
            <camfil-product-attribute
              [name]="'camfil.product.class_and_efficiency.label' | translate"
              [value]="product | camfilProductAttributeValue : 'IsoClassAndEfficiency'"
              identifier="iso_class"
            />
            <ng-container *ngIf="isViewPage">
              <camfil-product-attribute
                [name]="'camfil.cam_card.product.quantity' | translate"
                [value]="camCardItem.quantity"
                identifier="quantity"
              />
              <ng-container *ngTemplateOutlet="price" />
              <camfil-product-attribute
                *ngIf="camCardItem.comment.label"
                [name]="'camfil.cam_card.product.comment.label' | translate"
                [value]="camCardItem.comment.label"
                identifier="label"
              />
            </ng-container>
            <camfil-product-attribute
              *ngIf="requiresMeasurement$ | async"
              [name]="'camfil.cam_card.product.measurements.label' | translate"
              [value]="camCardItem.measurement | camfilMeasurement"
              identifier="measurement"
            />
            <a
              (click)="openQuickViewDialog(camCardItem); $event.stopPropagation()"
              class="quick-view text-nowrap"
              tabindex="0"
            >
              <mat-icon class="size-16" svgIcon="search" />
            </a>
          </div>
        </div>
      </div>
      <div class="line-item-cc__label d-flex align-items-center" *ngIf="isEditPage">
        <camfil-cam-card-last-delivery-date
          [camCard]="camCard"
          [lastDeliveryDateType]="'articleRow'"
          [camCardItem]="camCardItem"
        />
        <camfil-cam-card-delivery-interval
          [camCard]="camCard"
          [intervaltype]="'articleRow'"
          [mainDeliveryInterval]="camCard.deliveryInterval"
          [camCardItem]="camCardItem"
        />
        <camfil-cam-card-product-comment
          [camCardItem]="camCardItem"
          [camCard]="camCard"
          [mode]="mode"
          [ngClass]="{ 'align-self-end': isViewPage }"
        />
      </div>
      <ng-container *ngIf="isEditPage">
        <div
          class="line-item-cc__qty quantity-row d-flex justify-content-between align-items-center py-3 py-md-0"
          [ngClass]="{ 'line-item-cc__qty--no-price': !!(this.customerPrice$(product.sku) | async) === false }"
        >
          <div class="d-flex justify-content-between align-items-center">
            <a
              (click)="removeProductFromCamCard(camCardItem)"
              class="btn-tool"
              data-testing-id="delete-cam-card"
              tabindex="0"
            >
              <mat-icon svgIcon="delete" />
            </a>
            <form [formGroup]="form" class="qty">
              <camfil-product-quantity
                [allowZeroQuantity]="false"
                [ngClass]="{ 'ml-md-auto': isEditPage }"
                [parentForm]="form"
                [product]="product"
                [quantityLabel]="''"
                controlName="quantity"
                [isInLineItem]="true"
                [lineItemId]="camCardItem.id"
                [focusInputOnly]="false"
                (focusInput)="setEditMode()"
              />
            </form>
          </div>
          <ng-container *ngIf="!!(this.customerPrice$(product.sku) | async)">
            <div class="prices line-item-cc__prices d-flex justify-content-between">
              <ng-container *ngTemplateOutlet="price" />
            </div>
          </ng-container>
        </div>
      </ng-container>

      <mat-checkbox
        (change)="changeCheck($event)"
        *ngIf="showCheckbox && product.available"
        [checked]="checked"
        color="primary"
        class="text-right"
      />
    </div>
  </div>

  <ng-template #price>
    <ng-container *ngIf="!!(this.customerPrice$(product.sku) | async)">
      <!-- TODO: replace customerPrice value to new detective or pipe -->
      <camfil-product-attribute
        [name]="'camfil.cam_card.product.list_price.label' | translate"
        [overflowVisible]="true"
        [value]="
          (this.customerPrice$(product.sku) | async).listPrice | camfilPriceSummary : quantityFromForm | ishPrice
        "
      />
      <!-- TODO: replace customerPrice value to new detective or pipe -->
      <camfil-product-attribute
        [name]="'camfil.cam_card.product.your_price.label' | translate"
        [overflowVisible]="true"
        [value]="
          (this.customerPrice$(product.sku) | async).salePrice | camfilPriceSummary : quantityFromForm | ishPrice
        "
      />
    </ng-container>
  </ng-template>
</ng-container>

<ng-template #notLoaded>
  <ng-container *ngIf="isEditPage; else noEdit">
    <div class="d-flex align-items-center edit-view">
      <mat-icon svgIcon="move" class="handle" color="primary" cdkDragHandle />
      <div class="line-item-cc d-flex align-items-center">
        <fa-icon [icon]="['fas', 'spinner']" animation="spin" style="height: 24px; width: 24px" />
        &nbsp; {{ index }}. &nbsp; loading...
      </div>
    </div>
  </ng-container>
  <ng-template #noEdit>
    <fa-icon [icon]="['fas', 'spinner']" animation="spin" />
    &nbsp; loading...
  </ng-template>
</ng-template>
