import { createSelector } from '@ngrx/store';
import { getCamfilFeatureState } from 'camfil-core/store/camfil-store';
import { CamCardHelper } from 'camfil-models/camfil-cam-card/cam-card.helper';
import { CamCard } from 'projects/camfil-pwa/src/app/models/camfil-cam-card/cam-card.model';

import { CamCardState, camCardAdapter, camfilCamCardFeatureKey, initialState } from './cam-cards.reducer';

export const getCamCardState = getCamfilFeatureState<CamCardState>(camfilCamCardFeatureKey, initialState);

export const isCamCardsInitialized = createSelector(getCamCardState, state => state.initialized);

export const { selectEntities: getCamCardEntities, selectAll } = camCardAdapter.getSelectors(getCamCardState);

export const getAllCamCards = selectAll;

export const getCamCards = createSelector(selectAll, ccs => ccs.filter(cc => !cc.rootCamCard));

export const getRootCamCards = createSelector(selectAll, ccs => ccs.filter(cc => !!cc.rootCamCard));

export const getCamCardsLoading = createSelector(getCamCardState, state => state.loading);

export const getCamCardProcessing = createSelector(getCamCardState, state => state.processing);

export const getCamCardError = createSelector(getCamCardState, state => state.error);

export const getSelectedCamCardId = createSelector(getCamCardState, state => state.selected);

export const getSelectedCamCardDetails = createSelector(
  getCamCardEntities,
  getSelectedCamCardId,
  (entities, id): CamCard => id && entities[id]
);

export const getSelectedCamCardFullDetails = createSelector(
  getSelectedCamCardDetails,
  getSelectedCamCardId,
  (cc, id): CamCard => id && CamCardHelper.isSufficientlyLoaded(cc) && cc
);

export const getCamCardDetails = (props: { id: string }) =>
  createSelector(getCamCardEntities, entities => props.id && entities[props.id]);

export const isStickyCamCardToolbar = createSelector(getCamCardState, state => state.stickyToolbar);

export const getVirtualCamCard = createSelector(getCamCardState, state => state.virtualCamCard);

export const getValidationErrors = createSelector(getCamCardState, state => state.validationErrors);

export const getValidationResponse = createSelector(getCamCardState, state => state.validationResponse);

export const getAddProductSuccess = createSelector(getCamCardState, state => state.addProductSuccess);

export const getCamCardsInBasketsForAllUsersLoading = createSelector(
  getCamCardState,
  state => state.camCardsInBasketsForAllUsers.loading
);

export const getCamCardsInBasketsForAllUsers = createSelector(
  getCamCardState,
  state => state.camCardsInBasketsForAllUsers.list || []
);

export const getCamCardAdding = createSelector(getCamCardState, state => state.adding);

export const getCamCardEditMode = createSelector(getCamCardState, state => state.editMode);

export const getAllCustomerCamCardsFetched = createSelector(getCamCardState, state => state.allCustomerCamCardsFetched);

export const getCamCardsFeedback = createSelector(getCamCardState, state => state.addToCartFeedback);

export const getLoadingSingleCamCards = createSelector(getCamCardState, state => state.loadingSingleCamCards);
