<div class="empty-cart">
  <img
    class="empty-cart-icon"
    src="../../../../assets/img/empty-cart.png"
    alt="{{ 'shopping_cart.empty.alt.text' | translate }}"
  />

  <h2>{{ 'shopping_cart.empty.text' | translate }}</h2>

  <a color="primary" mat-flat-button routerLink="/home">
    {{ 'shopping_cart.detailed.continue_shopping.link' | translate }}</a
  >
</div>
