import { TranslateService } from '@ngx-translate/core';
import { formatCamfilMoney } from 'camfil-core/pipes/camfil-money.pipe';
import { formatCamfilSlug } from 'camfil-core/pipes/camfil-slugify.pipe';
import { CamfilAttributeHelper } from 'camfil-models/camfil-attribute/attribute.helper';
import { CamfilBucket } from 'camfil-models/camfil-bucket/camfil-bucket.model';
import { CamCard, CamCardProductItem } from 'camfil-models/camfil-cam-card/cam-card.model';
import { CamfilLineItem } from 'camfil-models/camfil-line-item/camfil-line-item.model';
import { CamfilPriceHelper } from 'camfil-models/camfil-price/price.helper';
import { CamfilProductHelper } from 'camfil-models/camfil-product/camfil-product.helper';
import { CamfilProductView } from 'camfil-models/camfil-product/product.model';
import { CamfilQuoteHelper } from 'camfil-models/camfil-quotes/camfil-quote/camfil-quote.helper';
import { CamfilQuote, CamfilQuoteLineItem } from 'camfil-models/camfil-quotes/camfil-quote/camfil-quote.model';
import {
  CamfilPdfInfoData,
  CamfilPdfTableData,
  CamfilPdfTableRowData,
  CamfilPdfTotalsData,
} from 'camfil-shared/cam-pdf/models/pdf.model';

import { AttributeHelper } from 'ish-core/models/attribute/attribute.helper';
import { Price } from 'ish-core/models/price/price.model';
import { formatPrice } from 'ish-core/models/price/price.pipe';
import { generateProductUrl } from 'ish-core/routing/product/product.route';

export class PdfMapper {
  static fromCamCardToPdfInfoData(camCard: CamCard): CamfilPdfInfoData {
    return {
      customer: camCard?.customer,
      customerId: camCard?.customerId,
      deliveryAddress: camCard?.deliveryAddress,
      deliveryDate: undefined,
      deliveryInterval: camCard?.deliveryInterval,
      goodsAcceptanceNote: camCard?.deliveryAddress?.goodsAcceptanceNote,
      info: undefined,
      invoiceLabel: camCard?.invoiceLabel,
      lastDeliveryDate: camCard?.lastDeliveryDate,
      nextDeliveryDate: camCard?.nextDeliveryDate,
      orderMark: camCard?.orderLabel,
      phoneNumber: undefined,
      phoneNotification: undefined,
      creationDate: camCard?.creationDate?.getTime()?.toString(),
      validToDate: undefined,
      requestedBy: undefined,
      title: camCard?.name,
      note: undefined,
      deliveryTerms: undefined,
      paymentTerms: undefined,
      contactPerson: undefined,
    };
  }

  static fromCamfilQuoteToPdfInfoData(camfilQuote: CamfilQuote): CamfilPdfInfoData {
    return {
      customer: camfilQuote.customer,
      customerId: camfilQuote.customerId,
      deliveryAddress: camfilQuote.deliveryAddress,
      deliveryDate: undefined,
      deliveryInterval: undefined,
      goodsAcceptanceNote: camfilQuote?.deliveryAddress?.goodsAcceptanceNote,
      info: camfilQuote?.customerServiceNote,
      invoiceLabel: camfilQuote.invoiceMark,
      lastDeliveryDate: undefined,
      nextDeliveryDate: undefined,
      orderMark: camfilQuote.orderMark,
      phoneNumber: camfilQuote?.phone,
      phoneNotification: camfilQuote?.phoneNotification,
      creationDate: camfilQuote?.creationDate,
      validToDate: camfilQuote?.validToDate,
      requestedBy: camfilQuote?.requestedBy,
      title: CamfilQuoteHelper.getQuotationTitle(camfilQuote),
      note: camfilQuote.customerServiceNote,
      deliveryTerms: camfilQuote.deliveryTerms,
      paymentTerms: camfilQuote.paymentTerms,
      contactPerson: `${camfilQuote.contact?.firstName} ${camfilQuote.contact?.lastName}`,
    };
  }

  static fromCamfilBucketToPdfInfoData(bucket: CamfilBucket): CamfilPdfInfoData {
    const customerDetails = [
      bucket?.customer?.customerNo,
      bucket?.customer?.companyName,
      bucket?.customer?.department,
    ].filter(Boolean);
    return {
      customer: bucket.customer,
      customerId: bucket.customer?.id,
      deliveryAddress: bucket.deliveryAddress,
      deliveryDate: bucket?.deliveryDate,
      deliveryInterval: undefined,
      goodsAcceptanceNote: bucket?.deliveryAddress?.goodsAcceptanceNote,
      info: bucket?.info,
      invoiceLabel: bucket.invoiceLabel,
      lastDeliveryDate: bucket?.deliveryDate,
      nextDeliveryDate: bucket?.nextDelivery,
      orderMark: bucket.orderMark,
      phoneNumber: bucket?.phoneNumber,
      phoneNotification: undefined,
      creationDate: undefined,
      validToDate: undefined,
      requestedBy: undefined,
      title: `Order ${bucket?.index} - ${customerDetails.join(', ')}`,
      note: bucket.info,
      deliveryTerms: undefined,
      paymentTerms: undefined,
      contactPerson: undefined,
    };
  }

  static fromCamCardToPdfTableData(camCard: CamCard, products: CamfilProductView[]): CamfilPdfTableData {
    const rows: CamfilPdfTableRowData[] = [];

    // eslint-disable-next-line unicorn/no-null
    PdfMapper.processCamCard(camCard, rows, products, null, 0);

    return { rows };
  }

  static fromCamfilQuoteToPdfTotalsData(translate: TranslateService, quote: CamfilQuote): CamfilPdfTotalsData {
    const currency = quote.total.currency;
    const surchargeItems: CamfilPdfTotalsData['surchargeItems'] = quote?.surchargeItems?.map(surcharge => ({
      ...surcharge,
      displayName: `${translate.instant(formatCamfilSlug(surcharge.displayName, 'camfil.dynamic'))?.toString()}:`,
      amount: {
        ...surcharge.amount,
        gross: formatPrice(formatCamfilMoney(surcharge.amount.gross, currency), translate.currentLang),
        net: formatPrice(formatCamfilMoney(surcharge.amount.net, currency), translate.currentLang),
      },
      taxes: surcharge?.taxes?.map(tax => ({
        ...tax,
        gross: formatPrice(formatCamfilMoney(tax.gross, currency), translate.currentLang),
        net: formatPrice(formatCamfilMoney(tax.net, currency), translate.currentLang),
      })),
    }));
    return {
      surchargeItems,
      total: formatPrice(formatCamfilMoney(quote?.total?.value, currency), translate.currentLang),
      subtotal: formatPrice(
        formatCamfilMoney(quote?.totalPriceAfterDiscountExVAT?.value, currency),
        translate.currentLang
      ),
      tax: formatPrice(formatCamfilMoney(quote?.taxAmount?.value, currency), translate.currentLang),
      totalQty: quote?.totalQty?.toString(),
    };
  }

  static fromCamfilQuoteToPdfTableData(quote: CamfilQuote, products: CamfilProductView[]): CamfilPdfTableData {
    const rows: CamfilPdfTableRowData[] = [];

    // eslint-disable-next-line unicorn/no-null
    PdfMapper.processCamfilQuote(quote, rows, products, null, 0);

    return { rows };
  }

  static fromCamfilBucketToPdfTableData(bucket: CamfilBucket, products: CamfilProductView[]): CamfilPdfTableData {
    const rows: CamfilPdfTableRowData[] = [];

    // eslint-disable-next-line unicorn/no-null
    PdfMapper.processCamfilBucket(bucket, rows, products, null, 0);

    return { rows };
  }

  private static fromCamCardItemToPdfTableRowData(
    camCard: CamCard,
    camCardItem: CamCardProductItem,
    index: number,
    products: CamfilProductView[],
    parentId: string | null,
    level: number
  ): CamfilPdfTableRowData {
    const levelName = level > 0 ? camCard.name : undefined;
    const sku = camCardItem?.product?.sku || '';
    const product = products.find(product => product.sku === sku);
    const url = generateProductUrl(product);
    const boxLabel = camCardItem?.comment?.label;
    const leadTime = CamfilProductHelper.getLeadTimeFormatted(product);
    const id = camCard?.id;
    const quantity = camCardItem.quantity.toString();
    const price = CamfilPriceHelper.checkIfZeroPrice(product?.salePrice) ? undefined : product?.salePrice;
    const name = product?.name || `Item ${index}`;
    const measurements = CamfilAttributeHelper.getMeasurementsText(camCardItem);
    const deliveryDays: string = undefined;

    return {
      boxLabel,
      deliveryDays,
      id,
      index,
      leadTime,
      level,
      levelName,
      measurements,
      name,
      parentId,
      price,
      url,
      quantity,
      sku,
    };
  }

  private static fromCamfilQuoteLineItemToPdfTableRowData(
    quote: CamfilQuote,
    quoteLineItem: CamfilQuoteLineItem,
    index: number,
    products: CamfilProductView[],
    parentId: string | null,
    level: number
  ): CamfilPdfTableRowData {
    const levelName = level > 0 ? CamfilQuoteHelper.getQuotationTitle(quote) : undefined;
    const sku = quoteLineItem?.product?.sku || '';
    const product = products.find(product => product.sku === sku);
    const url = generateProductUrl(product);
    const boxLabel = quoteLineItem?.boxLabel;
    const leadTime = CamfilProductHelper.getLeadTimeFormatted(product);
    const id = quote?.id;
    const quantity = quoteLineItem.quantity?.value.toString();
    const price = CamfilPriceHelper.checkIfZeroPrice(quoteLineItem.totalPrice) ? undefined : quoteLineItem.totalPrice;
    const name = product?.name || `Item ${index}`;
    const measurements: string = undefined;
    const deliveryDays = quoteLineItem?.deliveryDate;

    return {
      boxLabel,
      deliveryDays,
      id,
      index,
      leadTime,
      level,
      levelName,
      measurements,
      name,
      parentId,
      price,
      url,
      quantity,
      sku,
    };
  }

  private static fromCamfilLineItemToPdfTableRowData(
    bucket: CamfilBucket,
    lineItem: CamfilLineItem,
    index: number,
    products: CamfilProductView[],
    parentId: string | null,
    level: number
  ): CamfilPdfTableRowData {
    const levelName = level > 0 ? bucket.name : undefined;
    const sku = lineItem?.productSKU;
    const product = products.find(product => product.sku === sku);
    const url = generateProductUrl(product);
    const deliveryDays = lineItem?.earliestDeliveryDate || '';
    const boxLabel = AttributeHelper.getAttributeValueByAttributeName<string>(lineItem.attributes, 'boxLabel');
    const leadTime = ''; // Assuming lead time is not available in CamfilLineItem
    const quantity = lineItem?.quantity?.value.toString();
    const price = lineItem?.price as unknown as Price;
    const name = product?.name;
    const id = lineItem?.id;
    const measurements = CamfilAttributeHelper.getMeasurementsText(lineItem);

    return {
      boxLabel,
      deliveryDays,
      id,
      index,
      leadTime,
      level,
      levelName,
      measurements,
      name,
      parentId,
      price,
      url,
      quantity,
      sku,
    };
  }

  private static processCamCard(
    camCard: CamCard,
    rows: CamfilPdfTableRowData[],
    products: CamfilProductView[],
    parentId: string | null,
    level: number
  ) {
    camCard.camCardItems.forEach((camCardItem, index) => {
      rows.push(PdfMapper.fromCamCardItemToPdfTableRowData(camCard, camCardItem, index, products, parentId, level));
    });
    camCard.subCamCards?.forEach(subCamCard => {
      PdfMapper.processCamCard(subCamCard, rows, products, camCard.id, level + 1);
    });
  }

  private static processCamfilQuote(
    quote: CamfilQuote,
    rows: CamfilPdfTableRowData[],
    products: CamfilProductView[],
    parentId: string | null,
    level: number
  ) {
    quote.items.forEach(item => {
      rows.push(
        PdfMapper.fromCamfilQuoteLineItemToPdfTableRowData(quote, item, rows.length, products, parentId, level)
      );
    });
  }

  private static processCamfilBucket(
    bucket: CamfilBucket,
    rows: CamfilPdfTableRowData[],
    products: CamfilProductView[],
    parentId: string | null,
    level: number
  ) {
    bucket.lineItems?.forEach(lineItem => {
      rows.push(
        PdfMapper.fromCamfilLineItemToPdfTableRowData(bucket, lineItem, rows.length, products, parentId, level)
      );
    });
  }
}
