import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  inject,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute } from '@angular/router';
import { CamfilShoppingFacade } from 'camfil-core/facades/camfil-shopping.facade';
import { CamfilViewType } from 'camfil-models/camfil-viewtype/camfil-viewtype.types';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { ShoppingFacade } from 'ish-core/facades/shopping.facade';
import { Category } from 'ish-core/models/category/category.model';
import { ProductListingID, ProductListingView } from 'ish-core/models/product-listing/product-listing.model';
import { whenTruthy } from 'ish-core/utils/operators';

@Component({
  selector: 'camfil-product-listing',
  templateUrl: './camfil-product-listing.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CamfilProductListingComponent implements OnInit, OnChanges {
  @Input() category: Category;
  @Input() id: ProductListingID;
  @Input() mode: 'endless-scrolling' | 'paging' = 'endless-scrolling';
  @Input() fragmentOnRouting = 'product-list-top';
  @Input() limitItemsOnList: number;
  @Input() onlyList = false;

  searchTerm$: Observable<string>;
  productListingView$: Observable<ProductListingView>;
  viewType$: Observable<CamfilViewType>;
  currentPage$: Observable<number>;
  sortBy$: Observable<string>;

  private destroyRef = inject(DestroyRef);

  constructor(
    private shoppingFacade: ShoppingFacade,
    private camfilShoppingFacade: CamfilShoppingFacade,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit() {
    this.viewType$ = this.camfilShoppingFacade.productListingViewType$;
    this.currentPage$ = this.activatedRoute.queryParamMap.pipe(map(params => +params.get('page') || 1));
    this.sortBy$ = this.activatedRoute.queryParamMap.pipe(map(params => params.get('sorting')));
    this.searchTerm$ = this.shoppingFacade.searchTerm$;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.id && this.id?.value) {
      this.productListingView$ = this.shoppingFacade
        .productListingView$(this.id)
        .pipe(takeUntilDestroyed(this.destroyRef));
    }
  }

  /**
   * Emits the event for loading more products.
   */
  loadMoreProducts(direction: 'up' | 'down') {
    this.productListingView$
      .pipe(
        take(1),
        map(view => (direction === 'down' ? view.nextPage() : view.previousPage())),
        whenTruthy(),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(page => {
        this.shoppingFacade.loadMoreProducts(this.id, page);
      });
  }

  get isEndlessScrolling() {
    return this.mode === 'endless-scrolling';
  }

  get isPaging() {
    return !this.isEndlessScrolling;
  }
}
