export const CAMCARD_NEW_LOCALLY_ITEM_ID_PREFIX = 'newItem';

export const CAMCARD_LIST_HEIGHT = 900;

export const CAMCARD_LIST_LINE_HEIGHT = 80;

export const CAMCARD_LIST_LINE_HEIGHT_MOBILE = 80;

export const CAMCARD_LINE_HEIGHT = 100;

export const CAMCARD_LINE_HEIGHT_MOBILE = 150;

export const TOP_LEVEL_PROPERTY = 'topLevel';
