import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, DestroyRef, Input, OnInit, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { TranslateModule } from '@ngx-translate/core';
import { combineLatest } from 'rxjs';

import { RoleToggleService } from 'ish-core/role-toggle.module';

@Component({
  selector: 'camfil-content-by-role',
  templateUrl: './camfil-content-by-role.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, TranslateModule],
  providers: [RoleToggleService],
})
export class CamfilContentByRoleComponent implements OnInit {
  @Input() returnString: string;

  isRoleQuote = false;
  isRoleRequisition = false;
  ready = false;
  newString: string;

  constructor(private roleToggleService: RoleToggleService) {}

  private destroyRef = inject(DestroyRef);

  ngOnInit() {
    combineLatest([
      this.roleToggleService.hasRole('APP_B2B_REQUEST_QUOTATION'),
      this.roleToggleService.hasRole('APP_B2B_NEEDS_APPROVAL'),
    ])
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(([isRoleQuote, isRoleRequisition]) => {
        this.isRoleQuote = isRoleQuote;
        this.isRoleRequisition = isRoleRequisition;

        if (this.returnString) {
          this.newString = `${this.returnString}${
            (isRoleQuote && '_quote') || (isRoleRequisition && '_requisition') || ''
          }`;
        }

        this.ready = true;
      });
  }
}
