import { createSelector } from '@ngrx/store';

import { getCamfilFeatureState } from '../camfil-store';

import { State, camfilOrdersFeatureKey, orderAdapter } from './camfil-orders.reducer';

export const selectCamfilOrdersState = getCamfilFeatureState<State>(camfilOrdersFeatureKey);

export const { selectEntities: getOrdersEntities, selectAll: getOrders } =
  orderAdapter.getSelectors(selectCamfilOrdersState);

const getSelectedOrderId = createSelector(selectCamfilOrdersState, state => state.selected);

export const getSelectedOrder = createSelector(
  getOrdersEntities,
  getSelectedOrderId,
  (entities, id) => id && entities[id]
);

export const getOrdersLoading = createSelector(selectCamfilOrdersState, orders => orders.loading);
