import { CamfilSurcharge } from './camfil-surcharge.model';
import { CamfilSurchargeTypes } from './camfil-surcharge.types';

export class CamfilSurchargeHelper {
  static equal(sur1: CamfilSurcharge, sur2: CamfilSurcharge): boolean {
    if (!sur1 || !sur2) {
      return false;
    }

    if (sur1?.amount?.net && sur2?.amount?.net) {
      return Math.abs(sur1.amount.net) === Math.abs(sur2.amount.net);
    }
  }

  static select(data: CamfilSurcharge[], displayName: CamfilSurchargeTypes): CamfilSurcharge {
    if (!data || data?.length === 0) {
      return;
    }

    return data?.find(s => s.displayName === displayName);
  }
}
