import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { addProductToBasket, loadBasketAddresses } from 'camfil-core/store/camfil-basket/camfil-basket.actions';
import {
  getCamfilBasketAddresses,
  isProductsReadyToPlaceOrder,
} from 'camfil-core/store/camfil-basket/camfil-basket.selectors';
import { getAllProductsIds } from 'camfil-core/store/shopping/camfil-products/camfil-products.selectors';
import { loadCategoryLinks } from 'camfil-core/store/shopping/categories/categories.actions';
import { getCategoryLinks } from 'camfil-core/store/shopping/categories/categories.selectors';
import {
  getCustomerPriceBySku,
  getCustomerPrices,
  loadCustomerPrices,
} from 'camfil-core/store/shopping/customer-prices';
import { Attribute } from 'camfil-models/camfil-attribute/attribute.model';
import { ProductCompletenessLevel } from 'camfil-models/camfil-product/product.helper';
import { CamfilViewType } from 'camfil-models/camfil-viewtype/camfil-viewtype.types';
import { Observable, combineLatest, switchMap } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { ShoppingFacade } from 'ish-core/facades/shopping.facade';
import { CategoryView } from 'ish-core/models/category-view/category-view.model';
import { Pricing } from 'ish-core/models/price/price.model';
import { ProductLinksDictionary } from 'ish-core/models/product-links/product-links.model';
import { ProductView } from 'ish-core/models/product-view/product-view.model';
import { ViewType } from 'ish-core/models/viewtype/viewtype.types';
import { createBasket, getCurrentBasket } from 'ish-core/store/customer/basket';
import {
  getCategoryEntities,
  getNavigationCategories,
  loadTopLevelCategories,
} from 'ish-core/store/shopping/categories';
import { getProduct } from 'ish-core/store/shopping/products';
import { toObservable } from 'ish-core/utils/functions';
import { whenTruthy } from 'ish-core/utils/operators';

@Injectable({ providedIn: 'root' })
export class CamfilShoppingFacade {
  constructor(private store: Store, private shoppingFacade: ShoppingFacade) {}

  basketAddresses$ = this.store.pipe(select(getCamfilBasketAddresses));

  productsReadyToPlaceOrder$ = this.store.pipe(select(isProductsReadyToPlaceOrder));

  productListingViewType$ = this.shoppingFacade.productListingViewType$.pipe(
    map((viewType: ViewType) => (viewType || 'simple') as CamfilViewType)
  );

  navigationCategories$(uniqueId?: string) {
    if (!uniqueId) {
      this.store.dispatch(loadTopLevelCategories());
    }

    return this.store.pipe(select(getNavigationCategories(uniqueId)));
  }

  getAllCategoriesTree$(): Observable<CategoryView[]> {
    return this.store.pipe(
      select(getCategoryEntities),
      switchMap(categories =>
        combineLatest(Object.keys(categories).map(uniqueId => this.shoppingFacade.category$(uniqueId)))
      )
    );
  }

  loadBasketAddresses() {
    this.store.dispatch(loadBasketAddresses());
  }

  createBasket$() {
    this.store.dispatch(createBasket());
    return this.store.pipe(select(getCurrentBasket));
  }

  addProductToBasket(
    sku: string,
    quantity: number,
    camfilBucketId: string,
    shippingMethod?: string,
    lineItemAttributes?: Attribute[]
  ) {
    this.store.dispatch(addProductToBasket({ sku, quantity, camfilBucketId, shippingMethod, lineItemAttributes }));
  }

  loadCustomerPrices(customerId: string, skus: string[]) {
    this.store.dispatch(loadCustomerPrices({ customerId, skus }));
  }

  getCustomerPrices$(customerId: string) {
    return this.store.pipe(select(getCustomerPrices({ customerId })));
  }

  getCustomerPriceBySku$(customerId: string, sku: string) {
    return this.store.pipe(select(getCustomerPriceBySku({ customerId, sku })));
  }

  getProductsBySkus$(skus: string[]) {
    return combineLatest(skus.map(sku => this.shoppingFacade.product$(sku, ProductCompletenessLevel.Detail)));
  }

  getProductsWithPricesBySkus$(skus: string[]) {
    const combinedObservables = skus.map(sku =>
      combineLatest([
        this.shoppingFacade.product$(sku, ProductCompletenessLevel.Detail),
        this.shoppingFacade.productPrices$(sku),
      ]).pipe(map(([product, price]) => ({ product, price })))
    );

    return combineLatest(combinedObservables);
  }

  getProductsEvenIfNotExistBySkus$(skus: string[]): Observable<{ product: ProductView; price: Pricing }[]> {
    return combineLatest(
      skus.map(sku =>
        toObservable(sku).pipe(
          switchMap(plainSKU => this.store.pipe(select(getProduct(plainSKU)))),
          map(product => ({ product: product || { sku }, price: undefined }))
        )
      )
    );
  }

  getCategoryLinks$(id: string | Observable<string>): Observable<ProductLinksDictionary> {
    return toObservable(id).pipe(
      whenTruthy(),
      tap(key => {
        this.store.dispatch(loadCategoryLinks({ key }));
      }),
      switchMap(key => this.store.pipe(select(getCategoryLinks(key))))
    );
  }

  isProductsFetched$(skus: string[]) {
    return this.store.pipe(
      select(getAllProductsIds),
      map(ids => skus.filter(sku => ids.includes(sku)))
    );
  }
}
