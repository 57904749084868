<mat-card class="mb-1">
  <mat-card-content>
    <button (click)="isCollapsed = !isCollapsed" [attr.aria-expanded]="!isCollapsed" class="collapse-link">
      {{ toggleFormLabel | translate }}
      <mat-icon *ngIf="isCollapsed" class="align-bottom pr-2">arrow_drop_up</mat-icon>
      <mat-icon *ngIf="!isCollapsed" class="align-bottom pr-2">arrow_drop_down</mat-icon>
    </button>
    <div [ngbCollapse]="isCollapsed" id="camCardFormContainer" class="cam-card-form-container">
      <form [formGroup]="camCardForm.instance" (mouseup)="initEditMode()">
        <formly-form
          [form]="camCardForm.instance"
          [model]="camCardForm.model$ | async"
          [fields]="camCardForm.fields$ | async"
        />
      </form>
      <div *ngIf="!camCard" class="row">
        <div class="col-12 text-right">
          <button (click)="submitForm()" [disabled]="formDisabled" color="primary" mat-raised-button type="submit">
            {{ 'camfil.account.cam_card.new_from_order.button.create.label' | translate }}
          </button>
        </div>
      </div>
    </div>
  </mat-card-content>
</mat-card>
