import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  forwardRef,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { TranslateModule } from '@ngx-translate/core';

import { CamfilSearchInputConfigInterface } from './camfil-search-input-config.interface';

const searchInputConfigDefault: CamfilSearchInputConfigInterface = {
  placeholder: 'camfil.search.searchbox.instructional_text',
  btnText: 'camfil.search.searchbox.btn.text',
  promptHeight: '40px',
  showResetButton: false,
};

@Component({
  selector: 'camfil-search-input',
  templateUrl: './camfil-search-input.component.html',
  styleUrls: ['./camfil-search-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [MatIconModule, TranslateModule, MatInputModule, MatButtonModule],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => CamfilSearchInputComponent),
    },
  ],
})
export class CamfilSearchInputComponent implements ControlValueAccessor {
  @ViewChild('searchPrompt') searchPrompt: ElementRef<HTMLInputElement>;

  @Input() value = '';
  @Input() config: Partial<CamfilSearchInputConfigInterface> = searchInputConfigDefault;

  @Output() searchSuggest = new EventEmitter<string>();
  @Output() submitSearch = new EventEmitter<string>();
  @Output() focusChanged = new EventEmitter<boolean>();

  searchText: string;

  onChanged: Function;
  onTouched: Function;

  onFocusChange(isFocused: boolean) {
    this.focusChanged.emit(isFocused);
  }

  onInput($event: Event) {
    const target: HTMLInputElement = $event.target as HTMLInputElement;
    this.searchSuggest.emit(target.value);
  }

  onSearch(searchTerm: string) {
    if (!searchTerm) {
      return;
    }

    this.submitSearch.emit(searchTerm);
  }

  registerOnChange(fn: Function): void {
    this.onChanged = fn;
  }
  registerOnTouched(fn: Function): void {
    this.onTouched = fn;
  }

  writeValue(obj: string): void {
    this.searchText = obj;
  }
}
