import { createAction } from '@ngrx/store';
import { CamfilTrackAndTraceData } from 'camfil-models/camfil-order-track-and-trace/camfil-order-track-and-trace.interface';

import { httpError, payload } from 'ish-core/utils/ngrx-creators';

export const loadCamfilOrderTrackAndTrace = createAction(
  '[CamfilOrders] Load Order Track And Trace',
  payload<{ orderId: string }>()
);

export const loadCamfilOrderTrackAndTraceSuccess = createAction(
  '[CamfilOrders] Load Order Track And Trace Success',
  payload<{ trackAndTrace: CamfilTrackAndTraceData[] }>()
);

export const loadCamfilOrderTrackAndTraceFail = createAction(
  '[CamfilOrders] Load Order Track And Trace Fail',
  httpError()
);
