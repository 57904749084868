import { EntityState, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { Unit } from 'camfil-models/camfil-ahu/unit/unit.model';

import { HttpError } from 'ish-core/models/http-error/http-error.model';
import { setErrorOn, setLoadingOn, unsetLoadingAndErrorOn } from 'ish-core/utils/ngrx-creators';

import { deselectAhuUnit, loadAhuUnits, loadAhuUnitsFail, loadAhuUnitsSuccess, selectAhuUnit } from './unit.actions';

export const ahuUnitFeatureKey = 'ahuUnit';

export interface AhuUnitState extends EntityState<Unit> {
  loading: boolean;
  selected: number;
  error: HttpError;
}

export const unitAdapter = createEntityAdapter<Unit>({
  selectId: ahu => ahu.id,
});

const initialState = unitAdapter.getInitialState({
  loading: false,
  selected: undefined,
  error: undefined,
});

export const reducer = createReducer(
  initialState,
  setLoadingOn(loadAhuUnits),
  setErrorOn(loadAhuUnitsFail),
  unsetLoadingAndErrorOn(loadAhuUnitsSuccess, deselectAhuUnit),
  on(loadAhuUnitsSuccess, (state, action) => ({
    ...unitAdapter.upsertMany(action.payload.units, state),
  })),
  on(
    loadAhuUnitsFail,
    deselectAhuUnit,
    (state): AhuUnitState => ({
      ...state,
      selected: undefined,
    })
  ),
  on(
    selectAhuUnit,
    (state, action): AhuUnitState => ({
      ...state,
      selected: action.payload.unitId,
    })
  )
);
