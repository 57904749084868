import { createSelector } from '@ngrx/store';

import { getCamfilFeatureState } from '../camfil-store';

import { CamfilZipCodeAddressState, camfilZipcodesFeatureKey } from './camfil-zip-code-address.reducer';

export const getCamfilZipCodeAddressState = getCamfilFeatureState<CamfilZipCodeAddressState>(camfilZipcodesFeatureKey);

export const getAllCamfilZipCodeAddresses = createSelector(getCamfilZipCodeAddressState, state => state.zipCodes);

export const getCamfilZipCodeAddress = (postalCode: string) =>
  createSelector(getAllCamfilZipCodeAddresses, zipCodes => zipCodes?.[postalCode]);

export const getCamfilZipCodeAddressLoading = createSelector(getCamfilZipCodeAddressState, state => state.loading);

export const getCamfilZipCodeAddressError = createSelector(getCamfilZipCodeAddressState, state => state.error);
