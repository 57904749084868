import { DestroyRef, Directive, Input, TemplateRef, ViewContainerRef, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { CamfilConfigurationFacade } from 'camfil-core/facades/camfil-configuration.facade';
import { CamfilChannelSetting } from 'camfil-models/camfil-configuration/camfil-configuration.model';
import { ReplaySubject, Subscription } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

/**
 * Structural directive.
 * Used on an element, this element will only be rendered if the specified channel setting *is enabled*.
 *
 * @example
 * <div *camfilChannelToggle="'FR'">
 *   Only visible when viewing France channel.
 * </div>
 */
@Directive({
  selector: '[camfilChannelToggle]',
})
export class CamfilChannelToggleDirective {
  private subscription: Subscription;
  private enabled$ = new ReplaySubject<boolean>(1);
  private destroyRef = inject(DestroyRef);

  constructor(
    private templateRef: TemplateRef<unknown>,
    private viewContainer: ViewContainerRef,
    private camfilConfigurationFacade: CamfilConfigurationFacade
  ) {
    this.enabled$.pipe(distinctUntilChanged(), takeUntilDestroyed()).subscribe(enabled => {
      if (enabled) {
        this.viewContainer.createEmbeddedView(this.templateRef);
      } else {
        this.viewContainer.clear();
      }
    });
  }

  @Input() set camfilChannelToggle(channelSetting: CamfilChannelSetting | 'always' | 'never') {
    // end previous subscription and subscribe to new permission
    if (this.subscription) {
      // eslint-disable-next-line ban/ban
      this.subscription.unsubscribe();
    }
    if (channelSetting === 'always') {
      this.enabled$.next(true);
    } else if (channelSetting === 'never') {
      this.enabled$.next(false);
    } else {
      this.subscription = this.camfilConfigurationFacade
        .isEnabled$(channelSetting as CamfilChannelSetting)
        ?.pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe({ next: val => this.enabled$.next(val) });
    }
  }
}
