import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { camfilMatchProductRoute } from 'camfil-core/routing/camfil-product.route';

import { notFoundStatusGuard } from 'ish-core/guards/not-found-status.guard';
import { matchCategoryRoute } from 'ish-core/routing/category/category.route';
import { matchContentRoute } from 'ish-core/routing/content-page/content-page.route';

const routes: Routes = [
  {
    matcher: camfilMatchProductRoute,
    loadChildren: () => import('./camfil-product/camfil-product-page.module').then(m => m.CamfilProductPageModule),
  },
  {
    matcher: matchCategoryRoute,
    loadChildren: () => import('./camfil-category/camfil-category-page.module').then(m => m.CamfilCategoryPageModule),
  },
  {
    matcher: matchContentRoute,
    loadChildren: () => import('./camfil-content/camfil-content-page.module').then(m => m.CamfilContentPageModule),
  },
  {
    path: '**',
    canActivate: [notFoundStatusGuard],
    loadChildren: () => import('./camfil-error/camfil-error-page.module').then(m => m.CamfilErrorPageModule),
    data: {
      meta: {
        title: 'seo.title.error',
        robots: 'noindex, nofollow',
      },
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
})
export class CamfilLastRoutingModule {}
