import { NgModule } from '@angular/core';

import { CMS_COMPONENT } from 'ish-shared/cms/configurations/injection-keys';

import { CamfilCMSBigBannerWithButtonComponent } from './camfil-cms-big-banner-with-button/camfil-cms-big-banner-with-button.component';
import { CamfilCmsCookieDisclaimerComponent } from './camfil-cms-cookie-disclaimer/camfil-cms-cookie-disclaimer.component';
import { CamfilCMSFAQComponent } from './camfil-cms-faq/camfil-cms-faq.component';
import { CamfilCMSImageTextButtonComponent } from './camfil-cms-image-text-button/camfil-cms-image-text-button.component';
import { CamfilCmsLightboxLinkComponent } from './camfil-cms-lightbox-link/camfil-cms-lightbox-link.component';

@NgModule({
  providers: [
    {
      provide: CMS_COMPONENT,
      useValue: {
        definitionQualifiedName: 'camfil_app_sf_base_cm:camfil.component.bigBannerWithButton.pagelet2-Component',
        class: CamfilCMSBigBannerWithButtonComponent,
      },
      multi: true,
    },
    {
      provide: CMS_COMPONENT,
      useValue: {
        definitionQualifiedName: 'camfil_app_sf_base_cm:camfil.component.imageTextButton.pagelet2-Component',
        class: CamfilCMSImageTextButtonComponent,
      },
      multi: true,
    },
    {
      provide: CMS_COMPONENT,
      useValue: {
        definitionQualifiedName: 'camfil_app_sf_base_cm:camfil.component.faq.pagelet2-Component',
        class: CamfilCMSFAQComponent,
      },
      multi: true,
    },
    {
      provide: CMS_COMPONENT,
      useValue: {
        definitionQualifiedName: 'camfil_app_sf_base_cm:camfil.component.faq.pagelet2-Component',
        class: CamfilCMSFAQComponent,
      },
      multi: true,
    },
    {
      provide: CMS_COMPONENT,
      useValue: {
        definitionQualifiedName: 'camfil_app_sf_base_cm:camfil.component.lightboxLink.pagelet2-Component',
        class: CamfilCmsLightboxLinkComponent,
      },
      multi: true,
    },
    {
      provide: CMS_COMPONENT,
      useValue: {
        definitionQualifiedName: 'camfil_app_sf_base_cm:camfil.component.cookieDisclaimer.pagelet2-Component',
        class: CamfilCmsCookieDisclaimerComponent,
      },
      multi: true,
    },
  ],
})
export class CamfilCMSModule {}
