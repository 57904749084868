import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import {
  addCamfilRequisitionLineItemAttribute,
  createCamfilRequisition,
  deleteCamfilRequisitionLineItemAttribute,
  loadCamfilRequisition,
  loadCamfilRequisitions,
  updateCamfilRequisition,
  updateCamfilRequisitionLineItem,
  updateCamfilRequisitionLineItemAttribute,
  updateMultipleCamfilRequisitionStatus,
} from 'camfil-core/store/camfil-requisitions/camfil-requisitions.actions';
import {
  getCamfilRequisition,
  getCamfilRequisitions,
  getCamfilRequisitionsError,
  getCamfilRequisitionsLoading,
} from 'camfil-core/store/camfil-requisitions/camfil-requisitions.selectors';
import { CamfilAddress } from 'camfil-models/camfil-address/camfil-address.model';
import {
  CamfilRequisition,
  CamfilRequisitionLineItemUpdate,
  CamfilRequisitionViewer,
} from 'camfil-models/camfil-requisition/camfil-requisition.model';
import { combineLatest } from 'rxjs';
import { distinctUntilChanged, filter, map, sample, startWith, switchMap } from 'rxjs/operators';

import { Attribute } from 'ish-core/models/attribute/attribute.model';
import { selectRouteParam, selectUrl } from 'ish-core/store/core/router';
import { whenTruthy } from 'ish-core/utils/operators';

@Injectable({ providedIn: 'root' })
export class CamRequisitionManagementFacade {
  constructor(private store: Store, private router: Router) {}

  requisitionsError$ = this.store.pipe(select(getCamfilRequisitionsError));
  requisitionsLoading$ = this.store.pipe(select(getCamfilRequisitionsLoading));

  requisitionsStatus$ = this.store.pipe(
    select(selectRouteParam('status')),
    map(status => status || 'PENDING')
  );

  selectedRequisition$ = this.store.pipe(
    select(selectRouteParam('requisitionId')),
    whenTruthy(),
    switchMap(requisitionId => this.store.pipe(select(getCamfilRequisition(requisitionId))))
  );

  requisitionsByRoute$ = combineLatest([
    this.store.pipe(
      select(selectUrl),
      map(url => (url.includes('/buyer') ? 'buyer' : 'approver')),
      distinctUntilChanged()
    ),
    this.store.pipe(select(selectRouteParam('status')), distinctUntilChanged()),
  ]).pipe(
    sample(
      this.router.events.pipe(
        filter(e => e instanceof NavigationEnd),
        startWith({})
      )
    ),
    switchMap(([view]) => this.requisitions$(view as CamfilRequisitionViewer))
  );

  requisition$(requisitionId: string) {
    this.store.dispatch(loadCamfilRequisition({ requisitionId }));
    return this.store.pipe(select(getCamfilRequisition(requisitionId)));
  }

  requisitions$(view: CamfilRequisitionViewer) {
    this.store.dispatch(loadCamfilRequisitions({ view }));

    return this.store.pipe(select(getCamfilRequisitions));
  }

  createCamfilRequisition() {
    this.store.dispatch(createCamfilRequisition());
  }

  updateCamfilRequisition(requisition: CamfilRequisition, addressId?: string, address?: Partial<CamfilAddress>) {
    this.store.dispatch(updateCamfilRequisition({ requisition, addressId, address }));
  }

  // Line items quantity
  updateCamfilRequisitionLineItem(requisitionId: string, lineItemUpdate: CamfilRequisitionLineItemUpdate) {
    this.store.dispatch(
      updateCamfilRequisitionLineItem({
        requisitionId,
        lineItemUpdate,
      })
    );
  }

  // Line items attributes
  addCamfilRequisitionLineItemAttribute(requisitionId: string, lineItemId: string, lineItemAttribute: Attribute) {
    this.store.dispatch(addCamfilRequisitionLineItemAttribute({ requisitionId, lineItemId, lineItemAttribute }));
  }

  deleteCamfilRequisitionLineItemAttributes(requisitionId: string, lineItemId: string, lineItemAttribute: Attribute) {
    this.store.dispatch(deleteCamfilRequisitionLineItemAttribute({ requisitionId, lineItemId, lineItemAttribute }));
  }

  updateCamfilRequisitionLineItemAttribute(requisitionId: string, lineItemId: string, lineItemAttribute: Attribute) {
    this.store.dispatch(
      updateCamfilRequisitionLineItemAttribute({
        requisitionId,
        lineItemId,
        lineItemAttribute,
      })
    );
  }

  // Change status for multiple requisitions
  approveMultipleRequisitions$(requisitionIds: string[]) {
    this.store.dispatch(
      updateMultipleCamfilRequisitionStatus({
        requisitionIds,
        status: 'APPROVED',
      })
    );
  }

  rejectMultipleRequisitions$(requisitionIds: string[], comment?: string) {
    this.store.dispatch(
      updateMultipleCamfilRequisitionStatus({
        requisitionIds,
        status: 'REJECTED',
        approvalComment: comment,
      })
    );
  }
}
