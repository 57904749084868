<div class="quick-cart-link">
  <button class="quick-cart-link__button" color="primary" mat-flat-button routerLink="/checkout">
    <mat-icon class="color-light" svgIcon="spinner" *ngIf="basketLoading$ | async; else cartIcon" />
    <span class="quick-cart-link__text d-lg-none d-xl-inline">
      {{ 'camfil.shopping_cart.ministatus.items.label' | translate }}
    </span>
    <span class="counter">
      {{ bucketsCount$ | async }}
    </span>
  </button>
  <ng-template #cartIcon>
    <mat-icon class="color-light" svgIcon="cart" color="accent" />
  </ng-template>
</div>
