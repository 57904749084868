import { ChangeDetectionStrategy, Component, DestroyRef, EventEmitter, Input, Output, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CamfilCheckoutFacade } from 'camfil-core/facades/camfil-checkout.facade';
import { CamfilConfigurationFacade } from 'camfil-core/facades/camfil-configuration.facade';
import { CamfilDialogFacade } from 'camfil-core/facades/camfil-dialog.facade';
import { CamfilShoppingFacade } from 'camfil-core/facades/camfil-shopping.facade';
import { CamfilBasketSurcharge } from 'camfil-models/camfil-basket-surcharge/camfil-basket-surcharge.model';
import { CamfilBucket } from 'camfil-models/camfil-bucket/camfil-bucket.model';
import { CamfilPriceHelper } from 'camfil-models/camfil-price/price.helper';
import { Observable, catchError, combineLatest, map, of, startWith, switchMap, take, withLatestFrom } from 'rxjs';

import { AccountFacade } from 'ish-core/facades/account.facade';
import { BasketValidationResultType } from 'ish-core/models/basket-validation/basket-validation.model';
import { Price } from 'ish-core/models/price/price.model';
import { RoleToggleService } from 'ish-core/role-toggle.module';
import { AuthorizationToggleService } from 'ish-core/utils/authorization-toggle/authorization-toggle.service';
import { whenFalsy } from 'ish-core/utils/operators';

import { CamfilBasketCostSummaryComponent } from '../camfil-basket-cost-summary/camfil-basket-cost-summary.component';

@Component({
  selector: 'camfil-checkout-summary',
  templateUrl: './camfil-checkout-summary.component.html',
  styleUrls: ['./camfil-checkout-summary.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CamfilCheckoutSummaryComponent extends CamfilBasketCostSummaryComponent {
  @Input() purchaseCurrency: string;
  @Input() isEditable: boolean;
  @Output() submitBasket = new EventEmitter<string>();

  bucketsVolumeDiscounts$: Observable<Price>;
  validationResults$: Observable<BasketValidationResultType>;
  productsReadyToPlaceOrder$: Observable<boolean>;
  canSubmit$: Observable<boolean>;
  isLoggedIn$: Observable<boolean>;
  canRequestQuotations$: Observable<boolean>;
  isSubmitting$: Observable<boolean>;
  guestGdprForm: FormGroup;
  bucketSurchargeTotalsByType?: CamfilBasketSurcharge[];

  checkIfZeroPrice = CamfilPriceHelper.checkIfZeroPrice;

  private destroyRef = inject(DestroyRef);

  isGoodsAcceptanceTimeValid$: Observable<boolean> = this.camfilCheckoutFacade.camfilBuckets$.pipe(
    map(buckets => this.checkAllBucketsHaveGoodsAcceptanceTime(buckets)),
    withLatestFrom(this.camfilConfigurationFacade.isEnabled$('goodsAcceptanceTimeMandatory')),
    switchMap(([allBucketsHaveGoodsAcceptanceTime, isGoodsAcceptanceTimeMandatory]) =>
      of(this.finalValidation(allBucketsHaveGoodsAcceptanceTime, isGoodsAcceptanceTimeMandatory))
    ),
    catchError(error => {
      console.error('An error occurred:', error);
      return of(false);
    }),
    takeUntilDestroyed(this.destroyRef)
  );

  constructor(
    protected accountFacade: AccountFacade,
    private camfilCheckoutFacade: CamfilCheckoutFacade,
    private camfilShoppingFacade: CamfilShoppingFacade,
    private camfilConfigurationFacade: CamfilConfigurationFacade,
    private roleToggleService: RoleToggleService,
    private router: Router,
    private fb: FormBuilder,
    private camfilDialogFacade: CamfilDialogFacade,
    private authorizationToggle: AuthorizationToggleService
  ) {
    super(accountFacade);
  }

  init() {
    super.init();

    this.bucketsVolumeDiscounts$ = this.camfilCheckoutFacade.camfilBucketsVolumeDiscounts$;
    this.validationResults$ = this.camfilCheckoutFacade.camfilBasketValidationResults$;
    this.productsReadyToPlaceOrder$ = this.camfilShoppingFacade.productsReadyToPlaceOrder$;
    this.isSubmitting$ = this.accountFacade.ordersLoading$;

    this.canSubmit$ = combineLatest([
      this.roleToggleService.hasRole('APP_B2B_NO_CHECKOUT_USER'),
      this.productsReadyToPlaceOrder$,
      this.camfilCheckoutFacade.isLoading$,
    ]).pipe(map(([isNoCheckoutUser, isReady, isLoading]) => !isLoading && (isNoCheckoutUser ? false : isReady)));

    this.isLoggedIn$ = this.accountFacade.isLoggedIn$;
    this.isLoggedIn$.pipe(whenFalsy(), takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      this.initGDPRForm();
    });

    this.canRequestQuotations$ = combineLatest([
      this.authorizationToggle.isAuthorizedTo('APP_B2B_MAKE_QUOTATION'),
      this.camfilConfigurationFacade.isEnabled$('allowQuotes'),
    ]).pipe(map(([canMakeQuotation, allowQuotes]) => canMakeQuotation && allowQuotes));
  }

  private checkAllBucketsHaveGoodsAcceptanceTime(buckets: CamfilBucket[]): boolean {
    return !buckets.some(bucket => {
      const note = bucket?.deliveryAddress?.goodsAcceptanceNote;
      return !note || note.trim().length === 0;
    });
  }

  private finalValidation(
    allBucketsHaveGoodsAcceptanceTime: boolean,
    isGoodsAcceptanceTimeMandatory: boolean
  ): boolean {
    return isGoodsAcceptanceTimeMandatory ? allBucketsHaveGoodsAcceptanceTime : true;
  }

  submitOrder(orderType?: string) {
    combineLatest([this.isGoodsAcceptanceTimeValid$, this.isLoggedIn$])
      .pipe(take(1), takeUntilDestroyed(this.destroyRef))
      .subscribe(([isGoodsAcceptanceTimeValid, isLoggedIn]) => {
        if (!isGoodsAcceptanceTimeValid) {
          this.openGoodsAcceptanceTimeModal();
          return;
        }
        if (isLoggedIn) {
          this.submitBasket.emit(orderType);
        } else if (this.guestGdprForm?.valid) {
          this.submitBasket.emit(orderType);
        } else {
          this.openGDPRErrorModal();
        }
      });
  }

  continueShopping() {
    this.camfilConfigurationFacade.continueShoppingUrl$
      .pipe(take(1), takeUntilDestroyed(this.destroyRef))
      .subscribe(continueShoppingUrl => {
        this.router.navigate([continueShoppingUrl]);
      });
  }

  private initGDPRForm(): void {
    const gdprAcceptanceDefaultValue = false;

    this.guestGdprForm = this.fb.group({
      gdprAcceptance: [gdprAcceptanceDefaultValue, [Validators.requiredTrue]],
    });

    this.canSubmit$ = this.guestGdprForm.get('gdprAcceptance').valueChanges.pipe(
      startWith(gdprAcceptanceDefaultValue),
      withLatestFrom(this.productsReadyToPlaceOrder$),
      map(([gdprAcceptance, readyToOrder]) => gdprAcceptance && readyToOrder)
    );
  }

  private openGDPRErrorModal() {
    this.camfilDialogFacade.alert({
      data: {
        title: 'camfil.checkout.gdpr_form.gdpr.error.required',
        dismissButtonLabel: 'camfil.modal.cta.button.ok',
      },
    });
  }

  private openGoodsAcceptanceTimeModal() {
    this.camfilDialogFacade.alert({
      data: {
        title: 'camfil.checkout.goods_acceptance_time.error.required',
        dismissButtonLabel: 'camfil.modal.cta.button.ok',
      },
    });
  }
}
