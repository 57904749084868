import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

function normalizeLocale(locale: string): string {
  return locale.toLowerCase().replace(/_/g, '-');
}

export function formatCamfilDate(value: string | number | Date, lang: string): string {
  if (!value || !lang) {
    return '';
  }

  let date: Date;

  if (typeof value === 'number') {
    date = new Date(value);
  } else if (typeof value === 'string') {
    date = new Date(Date.parse(value));
  } else {
    date = value;
  }
  return new Intl.DateTimeFormat(normalizeLocale(lang)).format(date);
}

@Pipe({ name: 'camfilDate', pure: true })
export class CamfilDatePipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  transform(value: number | string | Date): string {
    return formatCamfilDate(value, this.translateService.currentLang);
  }
}
