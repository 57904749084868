import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CamfilBucketTotal } from 'camfil-models/camfil-bucket-total/camfil-bucket-total.model';

@Component({
  selector: 'camfil-checkout-bucket-summary',
  templateUrl: './camfil-checkout-bucket-summary.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CamfilCheckoutBucketSummaryComponent {
  @Input() totals: CamfilBucketTotal;
  @Input() cssClass: string;
}
