<ng-container *ngIf="isDesignViewMode && type; else cmsOutlet">
  <div class="design-view-wrapper" [ngClass]="type">
    <div class="design-view-wrapper-actions">
      <ng-container [ngSwitch]="type">
        <!-- pagelet -->
        <ng-template [ngSwitchCase]="'pagelet'">
          <ng-container *ngIf="pagelet$ | async as pagelet">
            <div class="name">{{ pagelet.displayName ? pagelet.displayName : '(Language missing)' }}</div>
            <button
              (click)="triggerAction(pagelet.id, 'pageletEdit')"
              class="btn"
              title="{{ 'designview.edit.link.title' | translate }}"
            >
              <fa-icon [icon]="['fas', 'pencil-alt']" />
            </button>
            <!--
            <button
              (click)="triggerAction(pagelet.id, 'pageletDelete')"
              class="btn"
              title="{{ 'designview.delete.link.title' | translate }}"
            >
              <fa-icon [icon]="['fas', 'trash-alt']"></fa-icon>
            </button>
            -->
          </ng-container>
        </ng-template>

        <!-- slot -->
        <ng-template [ngSwitchCase]="'slot'">
          <div class="name">{{ pagelet.slot(this.slotId).displayName }}</div>
          <button
            (click)="triggerAction(this.slotId, 'slotAdd')"
            class="btn"
            title="{{ 'designview.add.link.title' | translate }}"
          >
            <fa-icon [icon]="['fas', 'plus']" />
          </button>
        </ng-template>
        <!-- include -->
        <ng-template [ngSwitchCase]="'include'">
          <div class="name">{{ include.displayName }}</div>
          <button
            (click)="triggerAction(include.id, 'includeAdd')"
            class="btn"
            title="{{ 'designview.add.link.title' | translate }}"
          >
            <fa-icon [icon]="['fas', 'plus']" />
          </button>
        </ng-template>
      </ng-container>
    </div>
    <ng-container *ngTemplateOutlet="cmsOutlet" />
  </div>
</ng-container>

<ng-template #cmsOutlet>
  <ng-content></ng-content>
</ng-template>
