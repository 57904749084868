import { Pipe, PipeTransform } from '@angular/core';

import { Price } from 'ish-core/models/price/price.model';

export function formatCamfilMoney(value: number, currency: string): Price {
  return {
    value,
    currency,
    type: 'Money',
  };
}

@Pipe({
  name: 'camfilMoney',
})
export class CamfilMoneyPipe implements PipeTransform {
  transform(value: number, currency: string): Price {
    return formatCamfilMoney(value, currency);
  }
}
