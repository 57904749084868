import { Pipe, PipeTransform } from '@angular/core';
import { AhuHelper } from 'camfil-models/camfil-ahu/ahu.helper';
import { Unit } from 'camfil-models/camfil-ahu/unit/unit.model';

@Pipe({ name: 'ahuUnitRoute', pure: true })
export class AhuUnitRoutePipe implements PipeTransform {
  transform(unit: Unit): string {
    return AhuHelper.generateAhuUrl(unit?.manufacturerId, unit?.id);
  }
}
