import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { TranslateModule } from '@ngx-translate/core';
import { CamfilPipesModule } from 'camfil-core/camfil-pipes.module';
import { CAMFIL_QUEUE_ACTION_CONFIG } from 'camfil-core/configurations/camfil-injection-keys';
import { CamfilMaterialModule } from 'camfil-core/material/camfil-material.module';
import { CamfilActions } from 'camfil-core/ngrx/effects/camfil-actions';
import {
  addProductsToCamCard,
  addProductsToCamCardFail,
  addProductsToCamCardSuccess,
  addToCartFromCamCards,
  addToCartFromCamCardsFail,
  addToCartFromCamCardsSuccess,
} from 'camfil-core/store/cam-cards/cam-cards.actions';
import {
  placeCamfilOrder,
  placeCamfilOrderFail,
  placeCamfilOrderSuccess,
} from 'camfil-core/store/camfil-basket/camfil-basket.actions';
import {
  camfilAddItemsToBucket,
  camfilAddItemsToBucketFail,
  camfilAddItemsToBucketSuccess,
} from 'camfil-core/store/camfil-bucket/camfil-bucket.actions';
import { CamfilQueueActionConfig } from 'camfil-models/camfil-queue/camfil-queue.model';
import { CamfilContentByRoleComponent } from 'camfil-shared/components/common/camfil-content-by-role/camfil-content-by-role.component';
import { CamfilLoadingComponent } from 'camfil-shared/components/common/camfil-loading/camfil-loading.component';
import { CamfilDialogActionsDirective } from 'camfil-shared/dialog/camfil-dialog-actions.directive';
import { CamfilDialogContentDirective } from 'camfil-shared/dialog/camfil-dialog-content.directive';
import { CamfilDialogTitleDirective } from 'camfil-shared/dialog/camfil-dialog-title.directive';
import { CamfilAddCamCardFeedbackComponent } from 'camfil-shell/footer/camfil-add-cam-card-feedback/camfil-add-cam-card-feedback.component';
import { CamfilOrderFeedbackDialogComponent } from 'camfil-shell/footer/camfil-order-feedback-dialog/camfil-order-feedback-dialog.component';
import { CamfilOrderFeedbackComponent } from 'camfil-shell/footer/camfil-order-feedback/camfil-order-feedback.component';
import { CamfilQueueComponent } from 'camfil-shell/footer/camfil-queue/camfil-queue.component';

const exportedComponents = [
  CamfilAddCamCardFeedbackComponent,
  CamfilQueueComponent,
  CamfilOrderFeedbackComponent,
  CamfilOrderFeedbackDialogComponent,
];

@NgModule({
  imports: [
    CamfilContentByRoleComponent,
    CamfilDialogActionsDirective,
    CamfilDialogContentDirective,
    CamfilDialogTitleDirective,
    CamfilLoadingComponent,
    CamfilMaterialModule,
    CamfilPipesModule,
    CommonModule,
    TranslateModule,
  ],
  providers: [
    {
      provide: Actions,
      useClass: CamfilActions,
    },
    // #80114
    {
      provide: CAMFIL_QUEUE_ACTION_CONFIG,
      useValue: <CamfilQueueActionConfig>{
        label: 'camfil.queue.action.add_product_to_cc',
        actionType: addProductsToCamCard,
        successActionType: addProductsToCamCardSuccess,
        failureActionType: addProductsToCamCardFail,
      },
      multi: true,
    },
    // #79585
    {
      provide: CAMFIL_QUEUE_ACTION_CONFIG,
      useValue: <CamfilQueueActionConfig>{
        label: 'camfil.queue.action.create_camfil_order',
        actionType: placeCamfilOrder,
        successActionType: placeCamfilOrderSuccess,
        failureActionType: placeCamfilOrderFail,
      },
      multi: true,
    },
    // #79683
    {
      provide: CAMFIL_QUEUE_ACTION_CONFIG,
      useValue: <CamfilQueueActionConfig>{
        label: 'camfil.queue.action.add_to_cart_from_cc',
        actionType: addToCartFromCamCards,
        successActionType: addToCartFromCamCardsSuccess,
        failureActionType: addToCartFromCamCardsFail,
      },
      multi: true,
    },
    // #79683
    {
      provide: CAMFIL_QUEUE_ACTION_CONFIG,
      useValue: <CamfilQueueActionConfig>{
        label: 'camfil.queue.action.add_items_to_bucket',
        actionType: camfilAddItemsToBucket,
        successActionType: camfilAddItemsToBucketSuccess,
        failureActionType: camfilAddItemsToBucketFail,
      },
      multi: true,
    },
  ],
  declarations: [...exportedComponents],
  exports: [...exportedComponents],
})
export class CamfilQueueModule {}
