<ng-container *ngIf="code && (basket$ | async)">
  <span class="promotion-links-separator">|</span>
  <button
    type="button"
    data-testing-id="promo-remove-link"
    class="details-link promotion-remove-link btn btn-link btn-link-action"
    (click)="removePromotion()"
  >
    {{ 'promotion.removelink.text' | translate }}
  </button>
</ng-container>
