import { createSelectorFactory, defaultMemoize } from '@ngrx/store';
import { AhuHelper } from 'camfil-models/camfil-ahu/ahu.helper';
import { Manufacturer } from 'camfil-models/camfil-ahu/manufacturer/manufacturer.model';
import { Unit } from 'camfil-models/camfil-ahu/unit/unit.model';
import { isEqual } from 'lodash-es';

import { BreadcrumbItem } from 'ish-core/models/breadcrumb-item/breadcrumb-item.interface';

import { getSelectedAhuManufacturer } from './manufacturer/manufacturer.selectors';
import { getSelectedAhuUnit } from './unit/unit.selectors';

export const getAhuBreadcrumb = createSelectorFactory<object, BreadcrumbItem[]>(projector =>
  defaultMemoize(projector, undefined, isEqual)
)(getSelectedAhuManufacturer, getSelectedAhuUnit, (manufacturer: Manufacturer, unit: Unit): BreadcrumbItem[] => {
  const items: BreadcrumbItem[] = [{ key: 'camfil.ahu.link', link: AhuHelper.generateAhuUrl() }];

  if (manufacturer) {
    items.push({
      text: manufacturer?.name,
      link: AhuHelper.generateAhuUrl(manufacturer.id),
    });
  }

  if (manufacturer && unit) {
    items.push({
      text: unit?.name,
      link: AhuHelper.generateAhuUrl(manufacturer.id, unit.id),
    });
  }

  return items;
});
