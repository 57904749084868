import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { CamfilOrderService } from 'camfil-core/services/order/camfil-order.service';
import {
  loadCamfilOrderTrackAndTrace,
  loadCamfilOrderTrackAndTraceFail,
  loadCamfilOrderTrackAndTraceSuccess,
} from 'camfil-core/store/camfil-order-track-and-trace/camfil-order-track-and-trace.actions';
import { loadCamfilOrderSuccess } from 'camfil-core/store/camfil-orders/camfil-orders.actions';
import { map, switchMap } from 'rxjs/operators';

import { mapErrorToAction, mapToPayloadProperty, whenTruthy } from 'ish-core/utils/operators';

@Injectable()
export class CamfilOrderTrackAndTraceEffects {
  loadCamfilOrderTrackAndTrace$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadCamfilOrderTrackAndTrace),
      mapToPayloadProperty('orderId'),
      whenTruthy(),
      switchMap(orderId =>
        this.camfilOrderService.getCamfilOrderTrackAndTrace(orderId).pipe(
          map(trackAndTrace => loadCamfilOrderTrackAndTraceSuccess({ trackAndTrace })),
          mapErrorToAction(loadCamfilOrderTrackAndTraceFail)
        )
      )
    )
  );

  loadCamfilTrackAndTraceForSelectedCamfilOrder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadCamfilOrderSuccess),
      mapToPayloadProperty('order'),
      map(order => order?.id),
      whenTruthy(),
      map(orderId => loadCamfilOrderTrackAndTrace({ orderId }))
    )
  );

  constructor(private actions$: Actions, private camfilOrderService: CamfilOrderService) {}
}
