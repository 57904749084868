<mat-card [ngClass]="pagelet.stringParam('CSSClass')" class="p-2">
  <div class="row">
    <aside class="col-md-8">
      <img [src]="pagelet.stringParam('Image')" [attr.alt]="pagelet.stringParam('AlternateText')" />
      <div class="content pl-3">
        <h3 *ngIf="pagelet.hasParam('Heading1')">{{ pagelet.stringParam('Heading1') }}</h3>
        <div *ngIf="pagelet.hasParam('Description')" [ishServerHtml]="pagelet.stringParam('Description')"></div>
      </div>
    </aside>
    <section class="col-md-4">
      <h3 *ngIf="pagelet.hasParam('Heading2')">{{ pagelet.stringParam('Heading2') }}</h3>

      <ng-container *ngIf="pagelet.hasParam('Link')">
        <ng-container *ngIf="isRouterLink(pagelet, 'Link'); else externalLink">
          <button
            *ngIf="pagelet.hasParam('Link')"
            [routerLink]="routerLink(pagelet, 'Link')"
            mat-stroked-button
            color="primary"
          >
            {{ pagelet.stringParam('LinkText') }}
          </button>
        </ng-container>
        <ng-template #externalLink>
          <a
            [href]="pagelet.stringParam('Link')"
            [attr.title]="pagelet.stringParam('LinkText')"
            mat-raised-button
            class="px-5"
            color="primary"
          >
            {{ pagelet.stringParam('LinkText') }}
          </a>
        </ng-template>
      </ng-container>
    </section>
  </div>
</mat-card>
