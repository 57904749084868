import { Injectable } from '@angular/core';
import { PropType } from 'camfil-core/utils/utility-types';
import { CamfilBucketAddressData } from 'camfil-models/camfil-address/camfil-address.interface';
import { CamfilAddressMapper } from 'camfil-models/camfil-address/camfil-adress.mapper';
import { CamfilAttributeHelper } from 'camfil-models/camfil-attribute/attribute.helper';
import { CamfilBucketTotal } from 'camfil-models/camfil-bucket-total/camfil-bucket-total.model';
import { CamfilCalendarException } from 'camfil-models/camfil-caledar-exceptions/camfil-caledar-exceptions.model';
import { CamfilCustomer } from 'camfil-models/camfil-customer/camfil-customer.model';
import { CamfilLineItemData } from 'camfil-models/camfil-line-item/camfil-line-item.interface';
import { CamfilLineItem } from 'camfil-models/camfil-line-item/camfil-line-item.model';
import { CamfilPriceItemHelper } from 'camfil-models/camfil-price-item/camfil-price-item.helper';
import { CamfilPriceItemMapper } from 'camfil-models/camfil-price-item/camfil-price-item.mapper';
import { CamfilSurchargeMapper } from 'camfil-models/camfil-surcharge/camfil-surcharge.mapper';

import { BasketView } from 'ish-core/models/basket/basket.model';
import { LineItem } from 'ish-core/models/line-item/line-item.model';
import { PriceItemMapper } from 'ish-core/models/price-item/price-item.mapper';
import { PriceItem } from 'ish-core/models/price-item/price-item.model';
import { Price } from 'ish-core/models/price/price.model';

import { DELETED_LOCALLY_ATTR_NAME } from './camfil-bucket.constants';
import { CamfilBucketHelper } from './camfil-bucket.helper';
import {
  BucketFromEditModeData,
  CamfilBucketBaseData,
  CamfilBucketData,
  CamfilBucketDataFromExtension,
  CamfilBucketExtension,
} from './camfil-bucket.interface';
import { BucketFromEditMode, CamfilBucket } from './camfil-bucket.model';

@Injectable({ providedIn: 'root' })
export class CamfilBucketMapper {
  static fromListData(payload: CamfilBucketData, basket: BasketView): CamfilBucket[] {
    const { data, included } = payload;

    return data.map((camfilBucketsData: CamfilBucketBaseData, index) => {
      const shipToAddress =
        camfilBucketsData?.shipToAddress && included?.shipToAddress[camfilBucketsData?.shipToAddress];
      return CamfilBucketMapper.fromData(index + 1, camfilBucketsData, basket, shipToAddress);
    });
  }

  static fromData(
    index: number,
    camfilBucketsBaseData: CamfilBucketBaseData,
    basket: BasketView,
    shipToAddressData: CamfilBucketAddressData
  ): CamfilBucket {
    if (camfilBucketsBaseData) {
      const lineItems = CamfilBucketMapper.getCamfilBucketLineItems(camfilBucketsBaseData, basket);
      const shipToAddress = CamfilAddressMapper.fromData(shipToAddressData);
      const surcharges = CamfilSurchargeMapper.fromListData(camfilBucketsBaseData.surcharges);

      return {
        ...camfilBucketsBaseData,
        id: camfilBucketsBaseData?.id,
        basket: camfilBucketsBaseData?.basket,
        deliveryAddressId: shipToAddress?.id || '',
        deliveryAddress: shipToAddress,
        surcharges,
        lineItems,
        index,
      };
    } else {
      throw new Error(`camfilBucketData is required`);
    }
  }

  static getCamfilBucketLineItems(camfilBucketsBaseData: CamfilBucketBaseData, basket: BasketView): CamfilLineItem[] {
    return camfilBucketsBaseData.lineItems
      .map(elId => ({
        ...basket?.lineItems.find(({ id }) => id === elId),
      }))
      .filter(element => !!element)
      .sort((a, b) => (a.position < b.position ? -1 : 1));
  }

  static getMissingLineIteminfo(
    lineItems: CamfilLineItem[],
    camfilLineItems: { [id: string]: CamfilLineItemData },
    // TODO: TMP - remove when earliestDeliveryDate will be fix
    exceptions: CamfilCalendarException[]
  ): CamfilLineItem[] {
    return lineItems.map(item => {
      const camfilLineItem = camfilLineItems?.[item.id];
      if (!camfilLineItem) {
        return item;
      }
      const attributes = camfilLineItem?.attributes || [];
      const listPrice = PriceItemMapper.fromPriceItem(camfilLineItem?.pricing?.listPrice);
      const salePrice = PriceItemMapper.fromPriceItem(camfilLineItem?.pricing?.salePrice);

      let earliestDeliveryDate = camfilLineItem?.productInfo?.earliestDeliveryDate;

      // TODO: TMP - remove when earliestDeliveryDate will be fix
      if (exceptions?.length) {
        const deliveryDays = camfilLineItem?.productInfo?.deliveryDays;
        if (deliveryDays) {
          const exeptionsDay = exceptions?.map(({ date }) => new Date(date).toDateString());
          earliestDeliveryDate = CamfilBucketHelper.calculateEarliestDeliveryDate(
            exeptionsDay,
            deliveryDays,
            new Date()
          ).toDateString();
        }
      }
      // EOF TODO

      return {
        ...item,
        attributes,
        earliestDeliveryDate,
        listPrice,
        salePrice,
      };
    });
  }

  static getCustomer(customerData: Partial<CamfilCustomer>): CamfilCustomer {
    return {
      ...customerData,
      id: customerData?.id ? customerData.id : undefined,
      customerNo: customerData?.customerNo ? customerData.customerNo : undefined,
    };
  }
  static fromBucketExtensionListData(
    camfilBuckets: CamfilBucket[],
    payload: CamfilBucketDataFromExtension,
    // TODO: TMP - remove when earliestDeliveryDate will be fix
    exceptions: CamfilCalendarException[]
  ): Partial<CamfilBucket>[] {
    const { data, included } = payload;
    return camfilBuckets.map((b: CamfilBucket) => {
      const items = included?.camfilBucketLineItemsInclude[b?.deliveryAddressId]?.lineItems;
      const camfilBucketExtension: CamfilBucketExtension = data.find(
        ext => b.shipToAddress === ext.shippingAddress.urn
      );
      return CamfilBucketMapper.fromBucketExtensionData(b, camfilBucketExtension, items, exceptions);
    });
  }

  static fromBucketExtensionData(
    camfilBucket: CamfilBucket,
    camfilBucketsExtension: CamfilBucketExtension,
    items: { [id: string]: CamfilLineItemData },
    // TODO: TMP - remove when earliestDeliveryDate will be fix
    exceptions: CamfilCalendarException[]
  ): Partial<CamfilBucket> {
    if (camfilBucketsExtension) {
      const mappedCustomer = CamfilBucketMapper.getCustomer(camfilBucketsExtension.customer);
      const totals = CamfilBucketMapper.getCamfilBucketTotals(camfilBucket, camfilBucketsExtension);
      const lineItems = CamfilBucketMapper.getMissingLineIteminfo(camfilBucket.lineItems, items, exceptions);

      const isFullDeliveryDate = CamfilBucketMapper.getCamfilBucketDeliveryType(
        lineItems,
        camfilBucketsExtension?.deliveryDate
      );

      return {
        customer: mappedCustomer,
        contactPerson: camfilBucketsExtension.contactPerson,
        shipToAddress: camfilBucketsExtension?.shippingAddress.urn,
        orderMark: camfilBucketsExtension?.orderMark,
        info: camfilBucketsExtension?.info,
        invoiceLabel: camfilBucketsExtension?.invoiceLabel,
        phoneNumber: camfilBucketsExtension?.phoneNumber,
        deliveryDate: camfilBucketsExtension?.deliveryDate,
        isPartialDelivery: !isFullDeliveryDate,
        volumeDiscount: camfilBucketsExtension?.volumeDiscount,
        emailRecipients: camfilBucketsExtension?.emailRecipients?.filter(er => er.length),
        freightCostInvalid: camfilBucketsExtension?.freightCostInvalid,
        createdFromCamCardId: camfilBucketsExtension.createdFromCamCardId,
        position: camfilBucketsExtension.position,
        totals,
        lineItems,
      };
    } else {
      throw new Error(`camfilBucketData is required`);
    }
  }

  static getCamfilBucketTotals(
    camfilBucketData: CamfilBucket,
    bucketExtension: CamfilBucketExtension
  ): CamfilBucketTotal {
    const emptyPrice: Price = {
      type: 'Money',
      value: 0,
      currency: 'N/A',
    };

    const emptyPriceItem: PriceItem = {
      type: 'PriceItem',
      gross: 0,
      net: 0,
      currency: 'N/A',
    };

    const initialTotalsValue: CamfilBucketTotal = {
      dutiesAndSurchargesTotal: emptyPriceItem,
      itemTotal: emptyPriceItem,
      shippingTotal: emptyPriceItem,
      surcharges: [],
      taxTotal: emptyPrice,
      total: emptyPriceItem,
    };

    const { lineItems, surcharges } = camfilBucketData;

    const sumUpPrice = (a: Price, b: Price): Price => {
      if (!a && !b) {
        return;
      }

      const value = a?.value ? a?.value + b?.value : b?.value;

      return {
        ...a,
        ...b,
        value,
      };
    };

    const sumUpPriceItem = (a: PriceItem, b: PriceItem): PriceItem => {
      if (!a && !b) {
        return;
      }

      const gross = a?.gross ? a?.gross + b?.gross : b?.gross;
      const net = a?.net ? a?.net + b?.net : b?.net;
      return {
        ...a,
        ...b,
        gross,
        net,
      };
    };

    type TotalsType = PropType<LineItem, 'totals'>;

    const lineItemsTotals = lineItems
      ?.map(lineItem => ({ ...initialTotalsValue, ...lineItem?.totals }))
      ?.filter(Boolean)
      ?.reduce<TotalsType>((prevTotals, currentTotals) => {
        const keys = Object.keys({ ...prevTotals, ...currentTotals });
        const newTotals = keys.reduce((acc, name) => {
          const prevTotal: Price | PriceItem = prevTotals[name as keyof TotalsType];
          const currentTotal: Price | PriceItem = currentTotals[name as keyof TotalsType];
          const totalType = prevTotal?.type || currentTotal?.type;
          // eslint-disable-next-line ish-custom-rules/no-object-literal-type-assertion
          const newTotal = {
            [name]: undefined,
          } as { [name: string]: Price | PriceItem };

          switch (totalType) {
            case 'Money':
              newTotal[name as keyof Price] = sumUpPrice(prevTotal as Price, currentTotal as Price);
              break;
            case 'PriceItem':
              newTotal[name as keyof PriceItem] = sumUpPriceItem(prevTotal as PriceItem, currentTotal as PriceItem);
              break;
          }

          return {
            ...acc,
            ...newTotal,
          };
          // eslint-disable-next-line ish-custom-rules/no-object-literal-type-assertion
        }, {} as TotalsType);
        return { ...prevTotals, ...newTotals };
        // eslint-disable-next-line ish-custom-rules/no-object-literal-type-assertion
      }, {} as TotalsType);

    const dutiesAndSurchargesTotal = CamfilPriceItemHelper.sumUp(
      surcharges?.filter(s => !s?.strikethrough)?.map(s => s?.amount)
    );

    const itemTotal = CamfilPriceItemHelper.addTaxIfMissing(lineItemsTotals?.total);

    const grandTotal = CamfilPriceItemHelper.sumUp([itemTotal, dutiesAndSurchargesTotal]);

    const grandTotalData = CamfilPriceItemMapper.toPriceItem(grandTotal);

    const total = grandTotal;

    const taxTotal = PriceItemMapper.fromSpecificPriceItem(grandTotalData, 'tax');

    const volumeDiscount = bucketExtension?.volumeDiscount;

    return {
      ...lineItemsTotals,
      dutiesAndSurchargesTotal,
      itemTotal,
      total,
      taxTotal,
      surcharges,
      volumeDiscount,
    };
  }

  static getCamfilBucketDeliveryType(lineItems: CamfilLineItem[], deliveryDate: string) {
    const uniqueDateTimestamps = CamfilBucketHelper.getUniqueEarliestDeliveryDates(lineItems);
    const deliveryDateTimeStamp = Date.parse(deliveryDate);
    const highestTimestamp = Math.max(...uniqueDateTimestamps);

    return deliveryDateTimeStamp >= highestTimestamp;
  }

  static toEditBucketData(bucketFromEditMode: BucketFromEditMode): BucketFromEditModeData {
    const lineItems = bucketFromEditMode.lineItems.filter(
      l => !CamfilAttributeHelper.getAttributeValueByAttributePartialName(l.attributes, DELETED_LOCALLY_ATTR_NAME)
    );
    return { ...bucketFromEditMode, lineItems, shipToAddressFull: bucketFromEditMode?.deliveryAddress };
  }
}
