import { createReducer, on } from '@ngrx/store';
import { CamfilUser } from 'camfil-models/camfil-user/camfil-user.model';

import { HttpError } from 'ish-core/models/http-error/http-error.model';
import { setErrorOn, setLoadingOn } from 'ish-core/utils/ngrx-creators';

import {
  applyForAnAccountFail,
  requestUsernameReminder,
  requestUsernameReminderFail,
  requestUsernameReminderSuccess,
  resetUsernameReminder,
} from './camfil-user.actions';

export const camfilUserFeatureKey = 'camfilUser';

export interface CamfilUserState {
  loading: boolean;
  error: HttpError;
  usernameReminderSuccess: boolean;
  usernameReminderError: HttpError;
  user: CamfilUser;
}

export const initialState: CamfilUserState = {
  loading: false,
  error: undefined,
  usernameReminderSuccess: undefined,
  usernameReminderError: undefined,
  user: undefined,
};

export const reducer = createReducer(
  initialState,
  setLoadingOn(requestUsernameReminder),
  setErrorOn(requestUsernameReminderFail, applyForAnAccountFail),
  on(
    resetUsernameReminder,
    (state: CamfilUserState): CamfilUserState => ({
      ...state,
      usernameReminderSuccess: undefined,
      usernameReminderError: undefined,
    })
  ),
  on(
    requestUsernameReminder,
    (state: CamfilUserState): CamfilUserState => ({
      ...state,
      loading: true,
      usernameReminderSuccess: undefined,
      usernameReminderError: undefined,
    })
  ),
  on(
    requestUsernameReminderSuccess,
    (state: CamfilUserState): CamfilUserState => ({
      ...state,
      loading: false,
      usernameReminderSuccess: true,
      usernameReminderError: undefined,
    })
  ),
  on(
    requestUsernameReminderFail,
    (state: CamfilUserState, action): CamfilUserState => ({
      ...state,
      loading: false,
      usernameReminderSuccess: false,
      usernameReminderError: action.payload.error,
    })
  )
);
