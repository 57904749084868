<textarea
  [formControl]="formControl"
  [cols]="props.cols"
  [rows]="props.rows"
  [formlyAttributes]="field"
  class="form-control"
  [ngClass]="props.inputClass"
  [attr.data-testing-id]="field.key"
  [attr.aria-label]="props.ariaLabel"
></textarea>
