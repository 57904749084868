import { CamfilCustomer } from 'camfil-models/camfil-customer/camfil-customer.model';
import { CamfilLineItemData } from 'camfil-models/camfil-line-item/camfil-line-item.interface';

import { AddressData } from 'ish-core/models/address/address.interface';
import { Address } from 'ish-core/models/address/address.model';
import { BasketInfo } from 'ish-core/models/basket-info/basket-info.model';
import { BasketRebateData } from 'ish-core/models/basket-rebate/basket-rebate.interface';
import { BasketBaseData } from 'ish-core/models/basket/basket.interface';
import { PaymentInstrument } from 'ish-core/models/payment-instrument/payment-instrument.model';
import { PaymentMethodBaseData } from 'ish-core/models/payment-method/payment-method.interface';
import { PaymentData } from 'ish-core/models/payment/payment.interface';
import { ShippingMethodData } from 'ish-core/models/shipping-method/shipping-method.interface';
import { User } from 'ish-core/models/user/user.model';

import { CamfilRequisitionApproval, RequisitionUserBudget } from './camfil-requisition.model';

// TODO: Use when BE finish implementing
// export type CamfilRequisitionBaseData = components['schemas']['RequisitionRO'];

export interface CamfilRequisitionBaseData extends Omit<BasketBaseData, 'customer' | 'approval'> {
  requisitionNo?: string;
  basketId: string;
  orderNo?: string;
  order?: {
    itemId: string;
  };
  approvalCreationDate: number;
  lineItemCount: number;
  creator: User;
  orderMark: string;
  invoiceLabel: string;
  phoneNumber: string;
  userBudgets: RequisitionUserBudget;
  shippingAddress?: Address;
  status: string;
  info?: string;
  userComment: string;
  approvalStatus: CamfilRequisitionApproval;
  approval: CamfilRequisitionApproval;
  customer: CamfilCustomer;
  deliveryDate: string;
}

export interface CamfilRequisitionIncludedData {
  invoiceToAddress?: { [urn: string]: AddressData };
  lineItems?: { [id: string]: CamfilLineItemData };
  discounts?: { [id: string]: BasketRebateData };
  lineItems_discounts?: { [id: string]: BasketRebateData };
  commonShipToAddress?: { [urn: string]: AddressData };
  commonShippingMethod?: { [id: string]: ShippingMethodData };
  payments?: { [id: string]: PaymentData };
  payments_paymentMethod?: { [id: string]: PaymentMethodBaseData };
  payments_paymentInstrument?: { [id: string]: PaymentInstrument };
}

export interface CamfilRequisitionData {
  data: CamfilRequisitionBaseData | CamfilRequisitionBaseData[];
  included?: CamfilRequisitionIncludedData;
  infos?: BasketInfo[];
}

export enum CamfilRequisitionStatuses {
  Approved = 'Approved',
  Pending = 'Pending',
  Rejected = 'Rejected',
  Completed = 'Completed',
  Submitted = 'Submitted',
}

export enum CamfilRequisitionStatusCodes {
  Approved = 'APPROVED',
  Pending = 'PENDING',
  Rejected = 'REJECTED',
  Completed = 'COMPLETED',
  Submitted = 'SUBMITTED',
}
