<h2>{{ 'checkout.address.billing.label' | translate }}</h2>
<ng-container *ngIf="invoiceAddress$ | async as address">
  <div class="address-box">
    <!-- edit invoice address -->
    <button
      *ngIf="collapseChange | async"
      type="button"
      class="btn-tool btn-link float-right"
      [title]="'checkout.address.update.invoice.label' | translate"
      (click)="showAddressForm(address)"
      data-testing-id="edit-invoice-address-link"
    >
      <fa-icon [icon]="['fas', 'pencil-alt']" />
    </button>

    <!-- display invoice address -->
    <ish-address [address]="address" [displayEmail]="true" />
  </div>
  <p *ngIf="!address && showErrors" class="text-danger">
    {{ 'checkout.addresses.no_Selection.invoice.error' | translate }}
  </p>
</ng-container>

<!-- invoice address selection -->
<ng-container *ngIf="addresses$ | async as addresses">
  <form *ngIf="addresses.length" [formGroup]="form">
    <formly-form [form]="form" [fields]="fields" />
  </form>
</ng-container>

<!-- Add a new Invoice to address -->
<div class="row" *ngIf="(collapseChange | async) && (isLoggedIn$ | async)">
  <button
    data-testing-id="create-invoice-address-link"
    class="btn btn-link"
    (click)="showAddressForm()"
    [attr.aria-expanded]="(collapseChange | async) === false"
    aria-controls="invoice-address-panel"
  >
    {{ 'checkout.create_invoice_address.link' | translate }}
  </button>
</div>

<!-- invoice address form -->
<div id="invoice-address-panel" [ngbCollapse]="collapseChange | async" data-testing-id="invoice-address-form">
  <ish-formly-customer-address-form
    [address]="editAddress"
    [resetForm]="collapseChange | async"
    [extension]="(isLoggedIn$ | async) === false"
    (save)="saveAddress($event)"
    (cancel)="cancelEditAddress()"
  />
</div>

<ng-container *ngIf="(collapseChange | async) === false">
  <ish-lazy-address-doctor />
</ng-container>
