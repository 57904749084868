import { Injectable } from '@angular/core';
import { CamfilAddress } from 'camfil-models/camfil-address/camfil-address.model';
import { ContactMapper } from 'camfil-models/camfil-cam-card/contact.mapper';
import { CamfilCustomerData } from 'camfil-models/camfil-customer/camfil-customer.interface';
import { CamfilCustomerMapper } from 'camfil-models/camfil-customer/camfil-customer.mapper';
import { CamfilContact } from 'camfil-models/camfil-customer/camfil-customer.model';
import { Observable, defaultIfEmpty, map, throwError } from 'rxjs';

import { ApiService, unpackEnvelope } from 'ish-core/services/api/api.service';

@Injectable({
  providedIn: 'root',
})
export class CamfilCustomerService {
  constructor(private apiService: ApiService) {}

  /**
   * Get customers available for current user.
   *
   * @returns                 Camfil Customers for current user.
   */
  getCustomers(): Observable<CamfilCustomerData[]> {
    return this.apiService.get('camfilcustomers').pipe(map(CamfilCustomerMapper.fromListData));
  }

  /**
   * Get customer contacts.
   *
   * @param customerId   The customer ID.
   * @returns            The all customer contacts.
   */
  getContactsByCustomerId(customerId: string): Observable<CamfilContact[]> {
    return this.apiService
      .get(`privatecamfilcustomers/${customerId}/contacts`)
      .pipe(unpackEnvelope(), defaultIfEmpty([]), map(ContactMapper.fromData));
  }

  /**
   * Get customer contacts.
   *
   * @param customerId   The customer ID.
   * @param userKey      The userKey ID.
   * @returns            The all customer contacts.
   */
  getUserContactForCustomer(customerId: string, userKey: string = '-'): Observable<CamfilContact> {
    if (!customerId) {
      return throwError(() => 'getUserContactForCustomer() called without customerId');
    }
    return this.apiService
      .get(`privatecamfilcustomers/${customerId}/users/${userKey}/contact`)
      .pipe(map(ContactMapper.handleFullName));
  }

  /**
   * Get customers available for current user.
   *
   * @param customerId   The customer ID.
   * @returns            The created cam_cards.
   */
  getDeliveryAddresses(customerId: string): Observable<CamfilAddress[]> {
    if (!customerId) {
      return throwError(() => 'getDeliveryAddresses() called without customerId');
    }
    return this.apiService
      .get(`camfilcustomers/${customerId}/deliveryaddresses`)
      .pipe(unpackEnvelope(), defaultIfEmpty([])) as Observable<CamfilAddress[]>;
  }
}
