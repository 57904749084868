import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CamfilProduct } from 'camfil-models/camfil-product/product.model';
import { Observable, throwError } from 'rxjs';

import { ApiService, AvailableOptions } from 'ish-core/services/api/api.service';

@Injectable({ providedIn: 'root' })
export class CamfilPricesService {
  constructor(private apiService: ApiService) {}

  // TODO: Change to get currency from appFacade
  loadCustomerPrices(customerId: string, skus: string[], currency: string): Observable<CamfilProduct[]> {
    if (!customerId) {
      return throwError(() => 'loadCustomerPrices() called without a customerId');
    }

    if (!skus.length) {
      return throwError(() => 'loadCustomerPrices() skus is empty');
    }

    const params = new HttpParams().set('skus', skus.join(',')).set('currency', currency);
    const options: AvailableOptions = { params };

    return this.apiService.get(`camfilcustomers/${customerId}/prices`, options);
  }
}
