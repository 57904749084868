import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';
import { TranslateModule } from '@ngx-translate/core';

type CamfilDialogTranslatableToTranslate = string;

type CamfilDialogTranslatableRaw = { untranslated: string };

export type CamfilDialogTranslatable = CamfilDialogTranslatableRaw | CamfilDialogTranslatableToTranslate | SafeHtml;

@Component({
  selector: 'camfil-translatable-content',
  templateUrl: './camfil-translatable-content.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, TranslateModule],
})
export class CamfilTranslatableContentComponent {
  @Input() content: CamfilDialogTranslatable;

  get isRaw(): boolean {
    return this.content && typeof this.content === 'object' && 'untranslated' in this.content;
  }

  get isToTranslate(): boolean {
    return typeof this.content === 'string';
  }

  get untranslatedText(): string {
    return (this.content as CamfilDialogTranslatableRaw)?.untranslated || '';
  }
}
