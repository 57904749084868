import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { CamfilCheckoutFacade } from 'camfil-core/facades/camfil-checkout.facade';
import { CamfilDialogFacade } from 'camfil-core/facades/camfil-dialog.facade';
import { CamfilBucket } from 'camfil-models/camfil-bucket/camfil-bucket.model';
import { Observable, ReplaySubject, map, of, switchMap } from 'rxjs';

import { CheckoutFacade } from 'ish-core/facades/checkout.facade';
import { RoleToggleService } from 'ish-core/role-toggle.module';
import { whenTruthy } from 'ish-core/utils/operators';

import { CamfilBasketValidationResultsComponent } from '../camfil-basket-validation-results/camfil-basket-validation-results.component';

/* eslint-disable @typescript-eslint/dot-notation */

@Component({
  selector: 'camfil-bucket-validation-results',
  templateUrl: './camfil-bucket-validation-results.component.html',
  styleUrls: ['./camfil-bucket-validation-results.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CamfilBucketValidationResultsComponent
  extends CamfilBasketValidationResultsComponent
  implements OnInit, OnChanges
{
  camfilBucket$ = new ReplaySubject<CamfilBucket>(1);
  camfilInfoMessages$: Observable<string[]>;
  camfilErrorMessages$: Observable<string[]>;
  @Input() camfilBucket: CamfilBucket;

  constructor(
    checkoutFacade: CheckoutFacade,
    camfilCheckoutFacade: CamfilCheckoutFacade,
    camfilDialogFacade: CamfilDialogFacade,
    roleToggleService: RoleToggleService
  ) {
    super(checkoutFacade, camfilCheckoutFacade, camfilDialogFacade, roleToggleService);
  }

  ngOnInit() {
    this.init();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.camfilBucket) {
      this.camfilBucket$.next(this.camfilBucket);
    }
  }

  init() {
    this.camfilInfoMessages$ = this.camfilBucket$.pipe(
      map(bucket => bucket.shipToAddress),
      whenTruthy(),
      switchMap(shipToAddress => {
        if (shipToAddress) {
          return this['camfilCheckoutFacade'].getCamfilBucketInfoMessages$(shipToAddress);
        } else {
          return of([]);
        }
      })
    );

    this.camfilErrorMessages$ = this.camfilBucket$.pipe(
      map(bucket => bucket.deliveryAddressId),
      whenTruthy(),
      switchMap(shipToAddress => {
        if (shipToAddress) {
          return this['camfilCheckoutFacade'].getCamfilBucketErrorMessages$(shipToAddress);
        } else {
          return of([]);
        }
      })
    );
  }
}
