import { Injectable } from '@angular/core';

import { CamfilTrackAndTraceData } from './camfil-order-track-and-trace.interface';
import { CamfilOrderTrackAndTrace } from './camfil-order-track-and-trace.model';

@Injectable({ providedIn: 'root' })
export class CamfilOrderTrackAndTraceMapper {
  static fromData(data: CamfilTrackAndTraceData): CamfilOrderTrackAndTrace {
    if (data?.link && !/^(https?:\/\/)/i.test(data.link)) {
      data.link = `https://${data.link}`;
    }

    return data;
  }

  static fromListData(listData: CamfilTrackAndTraceData[]): CamfilOrderTrackAndTrace[] {
    return Array.isArray(listData) ? listData.map(CamfilOrderTrackAndTraceMapper.fromData) : [];
  }
}
