<ng-container *ngIf="visible$ | async">
  <ng-container *ngIf="variationOptions$ | async as variationOptions">
    <div *ngFor="let group of variationOptions" class="product-variation">
      <div class="form-group">
        <label [for]="uuid + group.id" [id]="uuid + group.id + 'label'" class="col-form-label">{{ group.label }}</label>

        <!-- multiple switch case expressions - https://stackoverflow.com/a/45950368 -->
        <ng-container [ngSwitch]="true">
          <ng-container *ngSwitchCase="group.attributeType === 'colorCode' || group.attributeType === 'swatchImage'">
            <ish-product-variation-select-swatch [group]="group" (changeOption)="optionChange($event)" />
          </ng-container>
          <ng-container
            *ngSwitchCase="
              group.attributeType === 'defaultAndColorCode' || group.attributeType === 'defaultAndSwatchImage'
            "
          >
            <ish-product-variation-select-enhanced
              [group]="group"
              [uuid]="uuid"
              [multipleOptions]="variationOptions.length > 1"
              (changeOption)="optionChange($event)"
            />
          </ng-container>
          <ng-container *ngSwitchDefault>
            <ish-product-variation-select-default
              [group]="group"
              [uuid]="uuid"
              [multipleOptions]="variationOptions.length > 1"
              (changeOption)="optionChange($event)"
            />
          </ng-container>
        </ng-container>
      </div>
    </div>
  </ng-container>
</ng-container>
