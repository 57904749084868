// eslint-disable ish-custom-rules/ban-imports-file-pattern
// noinspection DuplicatedCode
import { Inject, LOCALE_ID, NgModule, TransferState } from '@angular/core';
import {
  MissingTranslationHandler,
  TranslateCompiler,
  TranslateLoader,
  TranslateModule,
  TranslateService,
} from '@ngx-translate/core';
import { loadTranslations, registerLocalesData } from 'camfil-core/utils/internationalization';
import { CAMFIL_FALLBACK_LANG } from 'camfil-shared/camfil-constants';

import { SSR_LOCALE } from './configurations/state-keys';
import {
  FALLBACK_LANG,
  FallbackMissingTranslationHandler,
} from './utils/translate/fallback-missing-translation-handler';
import { ICMTranslateLoader, LOCAL_TRANSLATIONS } from './utils/translate/icm-translate-loader';
import { PWATranslateCompiler } from './utils/translate/pwa-translate-compiler';
import { TranslationGenerator } from './utils/translate/translations-generator';

@NgModule({
  imports: [
    TranslateModule.forRoot({
      loader: { provide: TranslateLoader, useClass: ICMTranslateLoader },
      missingTranslationHandler: { provide: MissingTranslationHandler, useClass: FallbackMissingTranslationHandler },
      useDefaultLang: false,
      compiler: { provide: TranslateCompiler, useClass: PWATranslateCompiler },
    }),
  ],
  providers: [
    { provide: LOCALE_ID, useValue: CAMFIL_FALLBACK_LANG.replace(/_/g, '-') },
    { provide: FALLBACK_LANG, useValue: CAMFIL_FALLBACK_LANG },
    {
      provide: LOCAL_TRANSLATIONS,
      useValue: {
        useFactory: loadTranslations,
      },
    },
    TranslationGenerator,
  ],
})
// eslint-disable-next-line ish-custom-rules/project-structure
export class InternationalizationModule {
  constructor(
    @Inject(LOCALE_ID) angularDefaultLocale: string,
    translateService: TranslateService,
    transferState: TransferState,
    generator: TranslationGenerator
  ) {
    registerLocalesData();

    let defaultLang = angularDefaultLocale.replace(/-/, '_');
    if (transferState.hasKey(SSR_LOCALE)) {
      defaultLang = transferState.get(SSR_LOCALE, defaultLang);
    }
    translateService.setDefaultLang(defaultLang);
    translateService.use(defaultLang);

    generator.init();
  }
}
