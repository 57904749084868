import { ChangeDetectionStrategy, Component, DestroyRef, Input, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { CamfilQuotesFacade } from 'camfil-core/facades/camfil-quotes.facade';
import { CamfilProductView } from 'camfil-models/camfil-product/product.model';
import { CamfilQuoteHelper } from 'camfil-models/camfil-quotes/camfil-quote/camfil-quote.helper';
import { CamfilQuote } from 'camfil-models/camfil-quotes/camfil-quote/camfil-quote.model';
import { take } from 'rxjs/operators';

import { PricePipe } from 'ish-core/models/price/price.pipe';

@Component({
  selector: 'camfil-print-quote',
  templateUrl: './camfil-print-quote.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [PricePipe],
})
export class CamfilPrintQuoteComponent {
  constructor(private camfilQuotesFacade: CamfilQuotesFacade) {}

  private destroyRef = inject(DestroyRef);

  @Input() quote: CamfilQuote;

  get canPrintQuote() {
    return CamfilQuoteHelper.isPrintable(this.quote);
  }

  printQuote() {
    this.camfilQuotesFacade
      .quoteProducts$(this.quote.id)
      .pipe(take(1), takeUntilDestroyed(this.destroyRef))
      .subscribe((products: CamfilProductView[]) => {
        this.camfilQuotesFacade.printCamfilQuote(this.quote.id, products);
      });
  }
}
