import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  Input,
  OnChanges,
  OnInit,
  inject,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CamfilMeasurementFilterFacade } from 'camfil-core/facades/camfil-measurement-filter.facade';
import { CamfilViewType } from 'camfil-models/camfil-viewtype/camfil-viewtype.types';
import { Observable } from 'rxjs';

import { ShoppingFacade } from 'ish-core/facades/shopping.facade';
import { FilterNavigation } from 'ish-core/models/filter-navigation/filter-navigation.model';
import { SortableAttributesType } from 'ish-core/models/product-listing/product-listing.model';
import { SelectOption } from 'ish-core/models/select-option/select-option.model';
import { whenTruthy } from 'ish-core/utils/operators';

@Component({
  selector: 'camfil-product-list-toolbar',
  templateUrl: './camfil-product-list-toolbar.component.html',
  styleUrls: ['./camfil-product-list-toolbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CamfilProductListToolbarComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() itemCount: number;
  @Input() viewType: CamfilViewType = 'simple';
  @Input() sortBy = 'default';
  @Input() sortableAttributes: SortableAttributesType[];
  @Input() currentPage: number;
  @Input() pageIndices: { value: number; display: string }[];
  @Input() fragmentOnRouting: string;
  @Input() isPaging = false;
  @Input() categoryName: string;
  @Input() showCategoryFilter = false;

  sortingParam: string;
  appliedFilters: string;
  categoryParam: string;
  sortDropdown = new FormControl('');
  sortOptions: SelectOption[] = [];
  filter$: Observable<FilterNavigation>;
  viewTypeControl = new FormControl('simple');

  private destroyRef = inject(DestroyRef);

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private shoppingFacade: ShoppingFacade,
    private translate: TranslateService,
    private measurementFilterFacade: CamfilMeasurementFilterFacade
  ) {}

  applyFilter = this.measurementFilterFacade.applyFilter.bind(this.measurementFilterFacade);

  clearFilters = this.measurementFilterFacade.clearFilters;

  ngOnInit() {
    this.filter$ = this.shoppingFacade.currentFilter$(this.showCategoryFilter);

    this.activatedRoute.queryParamMap.pipe(whenTruthy(), takeUntilDestroyed(this.destroyRef)).subscribe(params => {
      if (params) {
        this.appliedFilters = params.get('filters')?.substring(0, params.get('filters')?.indexOf('productFilter'));
        this.sortingParam = params.get('sorting');
      }
    });

    this.shoppingFacade.selectedCategory$?.pipe(whenTruthy(), takeUntilDestroyed(this.destroyRef)).subscribe(value => {
      this.categoryParam = value?.uniqueId?.replace(/\./g, '/');
    });

    this.viewTypeControl.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(value => {
      this.router.navigate([], {
        relativeTo: this.activatedRoute,
        queryParams: { view: value },
        queryParamsHandling: 'merge',
      });
    });
  }

  ngAfterViewInit() {
    if (this.sortingParam) {
      this.sortDropdown.setValue(this.sortingParam);
    }
    if (this.viewType) {
      this.viewTypeControl.setValue(this.viewType, {
        emitEvent: false,
      });
    }
  }

  ngOnChanges() {
    this.sortOptions = this.mapSortableAttributesToSelectOptions(this.sortableAttributes);
  }

  changeSortBy(option: MatSelectChange) {
    const sorting = option.value;
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParamsHandling: 'merge',
      queryParams: this.isPaging ? { sorting, page: 1 } : { sorting },
      fragment: this.fragmentOnRouting,
    });
  }

  private mapSortableAttributesToSelectOptions(sortableAttributes: SortableAttributesType[] = []): SelectOption[] {
    const options = sortableAttributes
      .filter(x => !!x)
      .map(sk => ({ value: sk.name, label: sk.displayName || sk.name }))
      .sort((a, b) => a.label?.localeCompare(b.label));
    options.unshift({ value: 'default', label: this.translate.instant('product.items.sorting.default.label') });
    return options;
  }
}
