import { EntityState, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import {
  loadCamfilOrderTrackAndTrace,
  loadCamfilOrderTrackAndTraceSuccess,
} from 'camfil-core/store/camfil-order-track-and-trace/camfil-order-track-and-trace.actions';
import { CamfilOrderTrackAndTrace } from 'camfil-models/camfil-order-track-and-trace/camfil-order-track-and-trace.model';

import { HttpError } from 'ish-core/models/http-error/http-error.model';
import { setLoadingOn, unsetLoadingAndErrorOn } from 'ish-core/utils/ngrx-creators';

export const camfilOrderTrackAndTraceFeatureKey = 'camfilOrderTrackAndTrace';

export interface State extends EntityState<CamfilOrderTrackAndTrace> {
  loading: boolean;
  selected?: string;
  error: HttpError;
}

export const camfilOrderTrackAndTraceAdapter = createEntityAdapter<CamfilOrderTrackAndTrace>({
  selectId: trackAndTrace => trackAndTrace.id,
});

const initialState: State = camfilOrderTrackAndTraceAdapter.getInitialState({
  loading: false,
  selected: undefined,
  error: undefined,
});

export const reducer = createReducer(
  initialState,
  setLoadingOn(loadCamfilOrderTrackAndTrace),
  unsetLoadingAndErrorOn(loadCamfilOrderTrackAndTraceSuccess),
  on(
    loadCamfilOrderTrackAndTraceSuccess,
    (state, action): State => camfilOrderTrackAndTraceAdapter.upsertMany(action.payload.trackAndTrace, state)
  )
);
