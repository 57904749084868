import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { routerNavigatedAction } from '@ngrx/router-store';
import { Store, select } from '@ngrx/store';
import { CamfilOrganizationManagementService } from 'camfil-core/services/organization/camfil-organization.service';
import { EMPTY, iif } from 'rxjs';
import { concatMap, filter, map, mergeMap, switchMap, take } from 'rxjs/operators';

import { ofUrl, selectPath, selectRouteParam } from 'ish-core/store/core/router';
import { getUserPermissions } from 'ish-core/store/customer/authorization';
import { getLoggedInCustomer } from 'ish-core/store/customer/user';
import { mapErrorToAction, mapToPayloadProperty, whenTruthy } from 'ish-core/utils/operators';

import { loadCustomerRoles } from '../b2b-roles/b2b-roles.actions';
import { loadOrganizationUsers } from '../b2b-users/b2b-users.actions';

import {
  loadCustomer,
  loadCustomerFail,
  loadCustomerSuccess,
  loadCustomers,
  loadCustomersFail,
  loadCustomersSuccess,
  selectCustomer,
} from './b2b-customers.actions';
import { getSelectedCustomerId } from './b2b-customers.selectors';

@Injectable()
export class B2bCustomersEffects {
  constructor(
    private actions$: Actions,
    private managementService: CamfilOrganizationManagementService,
    private store: Store
  ) {}

  routeListenerForCustomers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(routerNavigatedAction),
      mapToPayloadProperty('routerState'),
      filter(routerState => /^\/(account\/organization)/.test(routerState.url)),
      take(1),
      map(() => loadCustomers())
    )
  );

  loadCustomers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadCustomers),
      mergeMap(() =>
        this.managementService.getCustomers().pipe(
          concatLatestFrom(() => [
            this.store.pipe(select(selectPath)),
            this.store.pipe(select(getLoggedInCustomer), whenTruthy()),
            this.store.pipe(select(getUserPermissions)),
          ]),
          mergeMap(([customers, path, currentCustomer, permissions]) => {
            const actions = [];
            const userAdmin = permissions?.find(p => p === 'APP_B2B_MANAGE_USERS');
            let currentCustomerId = customers.find(c => c.customerNo === currentCustomer.customerNo)?.id;

            if (path.endsWith('account/organization/create') && userAdmin) {
              if (!currentCustomerId) {
                currentCustomerId = customers[0].parentCustomer.id;
                actions.push(loadCustomer({ customerId: currentCustomerId }));
              } else {
                actions.push(loadCustomerRoles({ customerId: currentCustomerId }));
              }
            }
            actions.push(loadCustomersSuccess({ customers }));

            if (customers?.length > 0 && path.includes('account/organization')) {
              actions.push(loadOrganizationUsers({ customerIDs: customers.map(c => c.id) }));
              actions.push(loadCustomerRoles({ customerId: currentCustomerId }));
            }

            return actions;
          }),
          mapErrorToAction(loadCustomersFail)
        )
      )
    )
  );

  routeListenerForSelectingCustomer$ = createEffect(() =>
    this.actions$.pipe(
      ofType(routerNavigatedAction),
      switchMap(() =>
        this.store.pipe(
          ofUrl(/^\/(account\/organization\/customers\/.*)/),
          select(selectRouteParam('CamfilB2BCustomerId')),
          concatLatestFrom(() => this.store.pipe(select(getSelectedCustomerId))),
          filter(([fromAction, selectedCustomerId]) => fromAction && fromAction !== selectedCustomerId),
          map(([customerId]) => customerId),
          map(customerId => selectCustomer({ customerId }))
        )
      )
    )
  );

  loadCustomerForSelectedCustomerId$ = createEffect(() =>
    iif(
      () => !SSR,
      this.actions$.pipe(
        ofType(selectCustomer),
        mapToPayloadProperty('customerId'),
        whenTruthy(),
        map(customerId => loadCustomer({ customerId }))
      ),
      EMPTY
    )
  );

  loadCustomer$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadCustomer),
      mapToPayloadProperty('customerId'),
      concatMap(customerId =>
        this.managementService.getCustomer(customerId).pipe(
          map(customer => loadCustomerSuccess({ customer })),
          mapErrorToAction(loadCustomerFail, { customerId })
        )
      )
    )
  );
}
