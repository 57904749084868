import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  inject,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { CamCardsFacade } from 'camfil-core/facades/camfil-cam-cards.facade';
import { CamfilCustomersFacade } from 'camfil-core/facades/camfil-customers.facade';
import { CamfilDialogFacade } from 'camfil-core/facades/camfil-dialog.facade';
import { CamfilShoppingFacade } from 'camfil-core/facades/camfil-shopping.facade';
import { CamCard, CamCardItem } from 'camfil-models/camfil-cam-card/cam-card.model';
import { CamfilCustomer } from 'camfil-models/camfil-customer/camfil-customer.model';
import { CamfilProduct, CamfilProductHelper } from 'camfil-models/camfil-product/product.model';
import {
  CamfilProductQuickViewDialogComponent,
  CamfilProductQuickViewDialogData,
} from 'camfil-shared/product/camfil-product-quick-view-dialog/camfil-product-quick-view-dialog.component';
import { isEqual } from 'lodash-es';
import { Observable, map } from 'rxjs';
import { distinctUntilChanged, pairwise, take } from 'rxjs/operators';

import { ProductContextFacade } from 'ish-core/facades/product-context.facade';
import { whenTruthy } from 'ish-core/utils/operators';

@Component({
  selector: 'camfil-account-cam-card-detail-line-item',
  templateUrl: './camfil-account-cam-card-detail-line-item.component.html',
  styleUrls: ['./camfil-account-cam-card-detail-line-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccountCamCardDetailLineItemComponent implements OnInit, OnChanges {
  constructor(
    private camCardsFacade: CamCardsFacade,
    private camfilDialogFacade: CamfilDialogFacade,
    private customersFacade: CamfilCustomersFacade,
    private camfilShoppingFacade: CamfilShoppingFacade,
    private context: ProductContextFacade,
    private changeDetectorRefs: ChangeDetectorRef
  ) {}

  get isEditPage() {
    return this.mode === 'edit';
  }

  get isViewPage() {
    return this.mode === 'view';
  }

  private destroyRef = inject(DestroyRef);

  @Input() camCard: CamCard;

  private camCardItemValue: CamCardItem;

  get camCardItem(): CamCardItem {
    return this.camCardItemValue;
  }

  @Input() set camCardItem(camCardItem: CamCardItem) {
    this.camCardItemValue = camCardItem;
  }

  @Input() selectedItemsForm: FormArray;
  @Input() mode: 'edit' | 'view';
  @Input() index: number;
  @Input() isIntervalVisible = false;
  @Input() showCheckbox: boolean;
  @Input() checked: boolean;
  @Input() shouldFetchProduct: boolean;

  @Output() changeCheckbox = new EventEmitter<MatCheckboxChange>();
  @Output() delete = new EventEmitter<CamCardItem>();
  @Output() qtyWithError = new EventEmitter<boolean>();

  form: FormGroup;
  selectItemForm: FormGroup;
  product$: Observable<CamfilProduct>;
  customers: CamfilCustomer[];
  requiresMeasurement$: Observable<boolean>;
  maxFromContext: number;

  private quantityValue = 0;

  get quantityFromForm() {
    return this.form?.get('quantity')?.value || this.camCardItem?.quantity || this.quantityValue;
  }

  afterDragAndDrop = false;

  ngOnInit() {
    this.customersFacade.customers$
      .pipe(whenTruthy(), takeUntilDestroyed(this.destroyRef))
      .subscribe(c => (this.customers = c));

    this.context
      ?.select('maxQuantity')
      ?.pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(max => {
        this.maxFromContext = max;
      });

    this.form?.valueChanges.pipe(take(1), takeUntilDestroyed(this.destroyRef)).subscribe(({ quantity }) => {
      this.updateQuantity(0, quantity);
    });
  }

  customerPrice$(sku: string): Observable<CamfilProduct> {
    return this.camfilShoppingFacade.getCustomerPriceBySku$(this.camCard?.customerId || this.camCard?.customer.id, sku);
  }

  ngOnChanges({ camCardItem, shouldFetchProduct }: SimpleChanges) {
    if (camCardItem) {
      const { previousValue, currentValue } = camCardItem;
      if (previousValue?.quantity !== currentValue?.quantity) {
        this.form?.get('quantity')?.patchValue(currentValue.quantity);
        const invalid = this.form?.invalid;
        this.qtyWithError.emit(invalid);
      }
    }

    if (shouldFetchProduct?.previousValue !== shouldFetchProduct?.currentValue) {
      this.initProduct();
    }
  }

  initProduct() {
    if (this.shouldFetchProduct && !this.product$ && !this.form) {
      this.product$ = this.context.select('product') as Observable<CamfilProduct>;
      this.requiresMeasurement$ = this.product$?.pipe(map(CamfilProductHelper.getRequiresMeasurement));

      this.initForm();

      this.form
        .get('quantity')
        .valueChanges.pipe(distinctUntilChanged(isEqual), pairwise(), takeUntilDestroyed(this.destroyRef))
        .subscribe(([old, newVal]) => {
          this.updateQuantity(old, newVal);
        });

      this.changeDetectorRefs.detectChanges();
    }
  }

  updateQuantity(old: number, newVal: number) {
    if (this.afterDragAndDrop) {
      this.afterDragAndDrop = false;
    } else {
      this.updateProductQuantity(old, newVal);
    }
  }

  setEditMode() {
    this.camCardsFacade.setCamCardEditMode(true);
  }

  changeCheck(event: MatCheckboxChange) {
    this.changeCheckbox.emit(event);
  }

  updateProductQuantity(old: number, newVal: number): void {
    this.product$
      .pipe(take(1), takeUntilDestroyed(this.destroyRef))
      .subscribe(({ maxOrderQuantity, minOrderQuantity }) => {
        if (newVal < minOrderQuantity || this.camCardItem.quantity === newVal) {
          return;
        }

        const max = maxOrderQuantity || this.maxFromContext;
        const quantity = old > max && newVal > max ? max : newVal;
        const newItem = { ...this.camCardItem, quantity };
        this.camCardsFacade.updateCamCardProduct(this.camCard.rootCamCard, this.camCard.id, newItem);
      });
  }

  removeProductFromCamCard(camCardItem: CamCardItem) {
    this.delete.emit(camCardItem);
  }

  /** Determine the heading of the delete modal and opens the modal. */
  openQuickViewDialog(camCardItem: CamCardItem): void {
    this.camfilDialogFacade.open<CamfilProductQuickViewDialogComponent, CamfilProductQuickViewDialogData>(
      CamfilProductQuickViewDialogComponent,
      {
        data: camCardItem.product.sku,
      }
    );
  }

  /** init form in the beginning */
  private initForm() {
    this.form = new FormGroup({
      quantity: new FormControl(this.camCardItem?.quantity || 1, { updateOn: 'blur' }),
    });
    if (this.selectedItemsForm) {
      this.selectItemForm = new FormGroup({
        productCheckbox: new FormControl(true),
        sku: new FormControl(this.camCardItem?.product?.sku),
      });

      this.selectedItemsForm.push(this.selectItemForm);
    }
  }
}
