import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { routerNavigatedAction } from '@ngrx/router-store';
import { Store, select } from '@ngrx/store';
import { CamfilBucketService } from 'camfil-core/services/bucket/camfil-bucket.service';
import { CamfilBasketService } from 'camfil-core/services/camfil-basket/camfil-basket.service';
import { startCamfilCheckout } from 'camfil-core/store/camfil-basket/camfil-basket.actions';
import { CamflEditBasketHelper } from 'camfil-core/store/camfil-edit-basket/camfil-edit-basket.helper';
import { BucketExtension } from 'camfil-models/bucket-extension/bucket-extension.model';
import { CamfilAddress } from 'camfil-models/camfil-address/camfil-address.model';
import { CamfilAddItem } from 'camfil-models/camfil-basket/camfil-basket.model';
import { EMPTY_BUCKET_PREFIX } from 'camfil-models/camfil-bucket/camfil-bucket.constants';
import { CamfilBucket } from 'camfil-models/camfil-bucket/camfil-bucket.model';
import { catchError, from, of } from 'rxjs';
import { concatAll, concatMap, filter, map, mergeMap, reduce } from 'rxjs/operators';

import { BasketService } from 'ish-core/services/basket/basket.service';
import { displayErrorMessage, displaySuccessMessage } from 'ish-core/store/core/messages';
import { selectUrl } from 'ish-core/store/core/router';
import { createCustomerAddressFail } from 'ish-core/store/customer/addresses';
import {
  createBasketFail,
  createBasketSuccess,
  getCurrentBasket,
  getCurrentBasketId,
  loadBasket,
  loadBasketSuccess,
} from 'ish-core/store/customer/basket';
import { mapErrorToAction, mapToPayload, mapToPayloadProperty } from 'ish-core/utils/operators';

import { getBucketEditMode, getSelectedCamfilBasketId } from '../camfil-basket/camfil-basket.selectors';
import { getCamfilCalendarExceptions } from '../camfil-calendar-exceptions/camfil-calendar-exceptions.selectors';
import { saveBucketsSuccess } from '../camfil-edit-basket/camfil-edit-basket.actions';

import {
  addBucketItemAttribute,
  addBucketItemAttributeSuccess,
  addEmptyCamfilBucket,
  addEmptyCamfilBucketSuccess,
  camfilAddItemsToBucket,
  camfilAddItemsToBucketFail,
  camfilAddItemsToBucketSuccess,
  camfilAddToCart,
  camfilAddToCartSuccess,
  camfilCreateBucket,
  camfilCreateBucketSuccess,
  camfilDeleteBucketItem,
  camfilDeleteBucketItemSuccess,
  camfilUpdateBucket,
  camfilUpdateBucketFail,
  camfilUpdateBucketItem,
  camfilUpdateBucketItemSuccess,
  camfilUpdateBucketSuccess,
  deleteCamfilBucket,
  deleteCamfilBucketFail,
  deleteCamfilBucketSuccess,
  deleteCamfilBuckets,
  deleteCamfilBucketsFail,
  deleteCamfilBucketsSuccess,
  deleteEmptyCamfilBucket,
  loadCamfilBuckets,
  loadCamfilBucketsExtensions,
  loadCamfilBucketsExtensionsSuccess,
  loadCamfilBucketsFailure,
  loadCamfilBucketsSuccess,
  prepareCamfilAddToCart,
  updateBucketItemAttributes,
  updateBucketItemAttributesSuccess,
  updateBuckets,
} from './camfil-bucket.actions';
import {
  getCamfilBucketsLength,
  getCurrentCamfilBuckets,
  selectCamfilEmailRecipients,
} from './camfil-bucket.selectors';

@Injectable()
export class CamfilBucketEffects {
  constructor(
    private store: Store,
    private actions$: Actions,
    private ishActions$: Actions,
    private camfilBucketService: CamfilBucketService,
    private camfilBasketService: CamfilBasketService,
    private basketService: BasketService
  ) {}

  routeListenerForOnestepCheckout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(routerNavigatedAction),
      mapToPayloadProperty('routerState'),
      concatLatestFrom(() => this.store.pipe(select(getCamfilBucketsLength))),
      filter(([routerState, bucketsLength]) => !!bucketsLength && /^\/(checkout\/onestep)/.test(routerState.url)),
      map(() => startCamfilCheckout())
    )
  );

  loadCamfilBuckets$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadCamfilBuckets),
      concatLatestFrom(() => this.store.pipe(select(getCurrentBasket))),
      mergeMap(([, basket]) =>
        this.camfilBucketService.getCamfilBuckets(basket).pipe(
          map((buckets: CamfilBucket[]) => loadCamfilBucketsSuccess({ buckets })),
          catchError(error => of(loadCamfilBucketsFailure({ error })))
        )
      )
    )
  );

  loadCamfilBucketsAfterLoadBasketSuccess$ = createEffect(() =>
    this.ishActions$.pipe(ofType(loadBasketSuccess), map(loadCamfilBuckets))
  );

  loadCamfilBucketsExtensions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadCamfilBucketsExtensions),
      mapToPayloadProperty('buckets'),
      concatLatestFrom(() => [
        this.store.pipe(select(getSelectedCamfilBasketId)),
        // TODO: TMP - remove when earliestDeliveryDate will be fix
        this.store.pipe(select(getCamfilCalendarExceptions)),
      ]),
      mergeMap(([camfilBuckets, basketId, exceptionsDates]) =>
        this.camfilBucketService
          .getCamfilBucketsExtension(camfilBuckets, basketId, exceptionsDates)
          .pipe(map(camfilBucketUpdates => loadCamfilBucketsExtensionsSuccess({ camfilBucketUpdates })))
      )
    )
  );

  loadCamfilBucketExtensionsAfterLoadCamfilBucketsSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadCamfilBucketsSuccess),
      mapToPayloadProperty('buckets'),
      map(buckets => loadCamfilBucketsExtensions({ buckets }))
    )
  );

  deleteCamfilBucket$ = createEffect(() =>
    this.ishActions$.pipe(
      ofType(deleteCamfilBucket),
      mapToPayload(),
      concatMap(payload =>
        this.camfilBucketService
          .deleteCamfilBucket(payload.basketId, payload.deliveryAddressId)
          .pipe(map(deleteCamfilBucketSuccess), mapErrorToAction(deleteCamfilBucketFail))
      )
    )
  );

  deleteCamfilBucketSuccess$ = createEffect(() =>
    this.ishActions$.pipe(
      ofType(deleteCamfilBucketSuccess),
      map(() =>
        displaySuccessMessage({
          message: 'camfil.order_delete.confirmation',
        })
      )
    )
  );

  deleteCamfilBuckets$ = createEffect(() =>
    this.ishActions$.pipe(
      ofType(deleteCamfilBuckets),
      mapToPayload(),
      concatMap(payload =>
        from([
          ...payload.orders
            .filter(order => order.deliveryAddress.id)
            .map(order => this.camfilBucketService.deleteCamfilBucket(order.basket, order.deliveryAddress.id)),
        ]).pipe(
          concatAll(),
          reduce((acc, data) => acc.concat(data), []),
          map(deleteCamfilBucketsSuccess),
          mapErrorToAction(deleteCamfilBucketsFail)
        )
      )
    )
  );

  deleteCamfilBucketsSuccess$ = createEffect(() =>
    this.ishActions$.pipe(
      ofType(deleteCamfilBucketsSuccess),
      map(() =>
        displaySuccessMessage({
          message: 'camfil.orders_delete.confirmation',
        })
      )
    )
  );

  camfilDeleteBucketItem$ = createEffect(() =>
    this.ishActions$.pipe(
      ofType(camfilDeleteBucketItem),
      mapToPayloadProperty('itemId'),
      concatLatestFrom(() => this.store.pipe(select(getBucketEditMode))),
      map(([itemId, bucketId]) => camfilDeleteBucketItemSuccess({ itemId, bucketId }))
    )
  );

  addBucketItemAttribute$ = createEffect(() =>
    this.ishActions$.pipe(ofType(addBucketItemAttribute), mapToPayload(), map(addBucketItemAttributeSuccess))
  );

  updateBucketItemAttributes$ = createEffect(() =>
    this.ishActions$.pipe(ofType(updateBucketItemAttributes), mapToPayload(), map(updateBucketItemAttributesSuccess))
  );

  camfilUpdateBucketItem$ = createEffect(() =>
    this.ishActions$.pipe(
      ofType(camfilUpdateBucketItem),
      mapToPayloadProperty('lineItemUpdate'),
      concatLatestFrom(() => this.store.pipe(select(getBucketEditMode))),
      map(([item, bucketId]) => camfilUpdateBucketItemSuccess({ item, bucketId }))
    )
  );

  addEmptyCamfilBucket$ = createEffect(() =>
    this.ishActions$.pipe(
      ofType(addEmptyCamfilBucket),
      mapToPayloadProperty('bucket'),
      concatLatestFrom(() => this.store.pipe(select(getCurrentBasket))),
      concatMap(([bucket, bs]) => {
        if (bs) {
          return [addEmptyCamfilBucketSuccess({ bucket })];
        } else {
          return this.basketService
            .createBasket()
            .pipe(mergeMap(basket => [createBasketSuccess({ basket }), addEmptyCamfilBucket({ bucket })]));
        }
      })
    )
  );

  createBasketSuccess$ = createEffect(() =>
    this.ishActions$.pipe(ofType(createBasketSuccess), mapToPayload(), map(loadBasketSuccess))
  );

  /**
   * Add to Cart effects flow
   */

  prepareCamfilAddToCart$ = createEffect(() =>
    this.ishActions$.pipe(
      ofType(prepareCamfilAddToCart),
      mapToPayload(),
      concatLatestFrom(() => this.store.pipe(select(getCurrentCamfilBuckets))),
      map(([data, b]) => {
        const currentBucket = b?.find(u => u.shipToAddress === data.urn);
        const { isEmpty, deliveryAddress } = currentBucket || {};

        // eslint-disable-next-line ish-custom-rules/no-object-literal-type-assertion
        const extension = data.bucket || ((isEmpty ? currentBucket : {}) as CamfilBucket);
        const address = data.address || ((isEmpty ? deliveryAddress : undefined) as CamfilAddress);

        const { customer, contactPerson, info, invoiceLabel, orderMark, phoneNumber } = extension;
        const bucketExtension: BucketExtension = {
          customer,
          contactPerson,
          info,
          invoiceLabel,
          orderMark,
          phoneNumber,
        };
        return camfilAddToCart({ items: data.products, address, bucketExtension });
      })
    )
  );

  camfilAddToCart$ = createEffect(() =>
    this.ishActions$.pipe(
      ofType(camfilAddToCart),
      mapToPayload(),
      concatLatestFrom(() => this.store.pipe(select(getCurrentBasket))),
      concatMap(([data, b]) =>
        b
          ? [camfilAddToCartSuccess(data)]
          : this.basketService.createBasket().pipe(
              mergeMap(basket => [createBasketSuccess({ basket }), camfilAddToCart(data)]),
              mapErrorToAction(createBasketFail)
            )
      )
    )
  );

  camfilAddToCartSuccess$ = createEffect(() =>
    this.ishActions$.pipe(
      ofType(camfilAddToCartSuccess),
      mapToPayload(),
      map(({ items, address, bucketExtension }) =>
        address ? camfilCreateBucket({ items, address, bucketExtension }) : camfilAddItemsToBucket({ items })
      )
    )
  );

  removeDuplicateEmptyBucketInAddToCartFlow$ = createEffect(() =>
    this.ishActions$.pipe(
      ofType(camfilCreateBucket),
      mapToPayload(),
      filter(({ items }) => items.some(i => i.shipToAddress?.includes(EMPTY_BUCKET_PREFIX))),
      map(({ items }) => deleteEmptyCamfilBucket({ bucketId: items[0].shipToAddress }))
    )
  );

  camfilCreateBucket$ = createEffect(() =>
    this.ishActions$.pipe(
      ofType(camfilCreateBucket),
      mapToPayload(),
      mergeMap(({ items, address, bucketExtension }) =>
        this.camfilBasketService.createCamfilBasketAddress(address).pipe(
          map(newAddr => {
            const its = items.map((i: CamfilAddItem) => ({
              ...i,
              shipToAddress: i.shipToAddress?.includes(EMPTY_BUCKET_PREFIX) ? '' : i.shipToAddress,
            }));
            return camfilCreateBucketSuccess({ items: its, addressId: newAddr.id, bucketExtension });
          }),
          mapErrorToAction(createCustomerAddressFail)
        )
      )
    )
  );

  camfilCreateBucketSuccess$ = createEffect(() =>
    this.ishActions$.pipe(ofType(camfilCreateBucketSuccess), mapToPayload(), map(camfilUpdateBucket))
  );

  camfilUpdateBucket$ = createEffect(() =>
    this.ishActions$.pipe(
      ofType(camfilUpdateBucket),
      mapToPayload(),
      concatLatestFrom(() => this.store.pipe(select(getCurrentBasketId))),
      mergeMap(([{ items, addressId, bucketExtension }, basketId]) =>
        this.camfilBucketService.updateCamfilBucket(basketId, addressId, bucketExtension).pipe(
          map(({ shippingAddress }) => camfilUpdateBucketSuccess({ items, urn: shippingAddress.urn })),
          mapErrorToAction(camfilUpdateBucketFail)
        )
      )
    )
  );

  camfilUpdateBucketSuccess$ = createEffect(() =>
    this.ishActions$.pipe(
      ofType(camfilUpdateBucketSuccess),
      mapToPayload(),
      map(data => {
        const items = data.items.map(i => ({
          ...i,
          shipToAddress: i.shipToAddress || data.urn,
        }));
        return camfilAddItemsToBucket({ items });
      })
    )
  );

  camfilAddItemsToBucket$ = createEffect(() =>
    this.ishActions$.pipe(
      ofType(camfilAddItemsToBucket),
      mapToPayload(),
      concatLatestFrom(() => this.store.pipe(select(selectUrl))),
      concatMap(([{ items }, url]) => {
        const isOnestepCheckout = url.startsWith('/checkout/onestep');

        return this.camfilBasketService.camfilAddItemsToBasket(items, isOnestepCheckout).pipe(
          mergeMap(() => [
            camfilAddItemsToBucketSuccess(),
            displaySuccessMessage({
              message: 'camfil.add_items_to_basket.camfil.message.success',
            }),
          ]),
          mapErrorToAction(camfilAddItemsToBucketFail)
        );
      })
    )
  );

  camfilAddItemsToBucketSuccess$ = createEffect(() =>
    this.ishActions$.pipe(ofType(camfilAddItemsToBucketSuccess), map(loadBasket))
  );

  camfilUpdateBuckets$ = createEffect(() =>
    this.ishActions$.pipe(
      ofType(updateBuckets),
      mapToPayload(),
      concatLatestFrom(() => this.store.pipe(select(selectCamfilEmailRecipients))),
      mergeMap(([{ buckets, basket }, recipients]) => {
        const bucketsFromEditMode = buckets
          ?.filter(b => b.lineItems.length)
          .map((b: CamfilBucket) => {
            const emailRecipients = recipients?.find(
              (r: { urn: string; emailRecipients: string[] }) => r.urn === b?.deliveryAddressId
            )?.emailRecipients;
            return CamflEditBasketHelper.updatedBucketFromEditMode(b, emailRecipients);
          });

        return this.camfilBasketService.updateBuckets(bucketsFromEditMode, basket.id).pipe(
          map(basket => saveBucketsSuccess({ basket })),
          mapErrorToAction(() =>
            displayErrorMessage({
              message: 'camfil.checkout.edit_mode.apply_changes.error.message',
            })
          )
        );
      })
    )
  );
}
