import { ChangeDetectionStrategy, Component, DestroyRef, Input, OnInit, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { CamfilConfigurationFacade } from 'camfil-core/facades/camfil-configuration.facade';
import { CamfilProductHelper, ProductCompletenessLevel } from 'camfil-models/camfil-product/product.helper';
import { Observable, combineLatest, map } from 'rxjs';

import { ProductContextFacade } from 'ish-core/facades/product-context.facade';
import { AttributeHelper } from 'ish-core/models/attribute/attribute.helper';
import { ProductView } from 'ish-core/models/product-view/product-view.model';

type ProductFormGroup = FormGroup<{
  quantity: FormControl<number>;
  boxLabel: FormControl<string>;
  width: FormControl<number>;
  height: FormControl<number>;
  depth: FormControl<number>;
}>;

@Component({
  selector: 'camfil-product-form-dialog-product',
  templateUrl: './camfil-product-form-dialog-product.component.html',
  styleUrls: ['./camfil-product-form-dialog-product.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{ provide: ProductContextFacade, useClass: ProductContextFacade }],
})
export class CamfilProductFormDialogProductComponent implements OnInit {
  @Input() name: string;

  @Input()
  set sku(sku: string) {
    this.productContext.set('sku', () => sku);
  }

  @Input() control: ProductFormGroup;

  requiresMeasurement$: Observable<boolean>;
  showDepthField$: Observable<boolean>;
  boxLabelMaxLength: number;

  private destroyRef = inject(DestroyRef);

  constructor(
    private productContext: ProductContextFacade,
    private camfilConfigurationFacade: CamfilConfigurationFacade
  ) {}

  private createValidators(attributeName: string, product: ProductView): ValidatorFn | ValidatorFn[] | null {
    const maxValue = AttributeHelper.getAttributeValueByAttributeName<number>(product.attributes, attributeName);
    const maxValueValid = typeof maxValue === 'number' && maxValue > 0;

    return [Validators.required, Validators.min(0), ...(maxValueValid ? [Validators.max(maxValue)] : [])];
  }

  ngOnInit(): void {
    this.productContext.set('sku', () => this.sku);
    this.productContext.set('requiredCompletenessLevel', () => ProductCompletenessLevel.List);

    const product$ = this.productContext.select('product');

    this.requiresMeasurement$ = product$.pipe(map(CamfilProductHelper.getRequiresMeasurement));

    const { width, height, boxLabel } = this.control.controls;

    combineLatest([product$, this.requiresMeasurement$])
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(([product, requiresMeasurement]) => {
        if (!requiresMeasurement) {
          width.clearValidators();
          height.clearValidators();
          return;
        }
        width.setValidators(this.createValidators('Width', product));
        height.setValidators(this.createValidators('Height', product));
      });

    combineLatest([product$, this.requiresMeasurement$])
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(([product, requiresMeasurement]) => {
        if (!requiresMeasurement) {
          this.control.clearValidators();
          return;
        }
        const maxArea = CamfilProductHelper.getFilterArea(product);
        const maxAreaValid = maxArea > 0;
        if (!maxAreaValid) {
          return;
        }
        const areaValidator = (_: FormControl): ValidationErrors | null => {
          const width = this.control.controls.width.value;
          const height = this.control.controls.height.value;
          const area =
            width && height ? !CamfilProductHelper.validateFilterAreaFromValues(product, width, height) : false;

          // eslint-disable-next-line unicorn/no-null
          return area ? { area } : null;
        };

        this.control.setValidators(areaValidator);
      });

    this.camfilConfigurationFacade
      .maxLength$('boxLabel')
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((value: number) => {
        this.boxLabelMaxLength = value;
        boxLabel.setValidators(Validators.maxLength(value));
      });

    this.control.controls.depth.disable();

    const defaultDepth$ = product$.pipe(
      map(product => AttributeHelper.getAttributeValueByAttributeName<number>(product.attributes, 'Depth'))
    );

    this.showDepthField$ = defaultDepth$.pipe(map(value => value !== undefined));

    defaultDepth$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(depth => {
      this.control.controls.depth.setValue(depth);
    });
  }
}
