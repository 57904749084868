import { FormGroup } from '@angular/forms';
import { CamfilErrorValidator } from 'camfil-models/camfil-error-valdiator/camfil-error-valdiator.model';
import { CamfilProductTechnicalDocument } from 'camfil-models/camfil-technical-document/camfil-technical-document.model';

import { AttributeHelper } from 'ish-core/models/attribute/attribute.helper';
import { PriceHelper } from 'ish-core/models/price/price.helper';
import { ProductView } from 'ish-core/models/product-view/product-view.model';
import { Product, ProductHelper } from 'ish-core/models/product/product.model';

import { CamfilAttributeGroupTypes } from '../camfil-attribute-group/camfil-attribute-group.types';
import { CamfilImageTypes } from '../camfil-image/camfil-image.types';

import { CamfilProductRetailSet } from './product-retail-set.model';
import { CamfilVariationProductMaster } from './product-variation-master.model';
import { CamfilProduct } from './product.model';

export enum ProductCompletenessLevel {
  Detail = 3,
  List = 2,
}

type ProductPrices =
  | Partial<Pick<CamfilProductRetailSet, 'minListPrice' | 'minSalePrice' | 'summedUpListPrice' | 'summedUpSalePrice'>>
  | Partial<Pick<CamfilVariationProductMaster, 'minListPrice' | 'minSalePrice' | 'maxListPrice' | 'maxSalePrice'>>
  | Partial<Pick<CamfilProduct, 'salePrice' | 'listPrice'>>;

export class CamfilProductHelper extends ProductHelper {
  /**
   * Get all product ImageView ids excluding the primary product image
   *
   * @param product   The Product for which to get the image types
   * @param imageType The wanted ImageType
   * @returns         Array of available ImageView ids
   */
  static getImageViewIDsExcludePrimary(product: Product, imageType: string): string[] {
    if (!product?.images) {
      return [];
    }
    return product.images.filter(image => image.typeID === imageType && !image.primaryImage).map(image => image.viewID);
  }

  static hasVariations(product: Product) {
    return (
      (ProductHelper.isVariationProduct(product) || ProductHelper.isMasterProduct(product)) &&
      !!product.variations.length
    );
  }

  static calculatePriceRange(products: CamfilProduct[]): ProductPrices {
    if (!products?.length) {
      return {};
    } else if (products.length === 1) {
      return products[0];
    } else {
      return {
        minListPrice: products.map(p => p.listPrice).reduce(PriceHelper.min),
        minSalePrice: products.map(p => p.salePrice).reduce(PriceHelper.min),
        summedUpListPrice: products.map(p => p.listPrice).reduce(PriceHelper.sum),
        summedUpSalePrice: products.map(p => p.salePrice).reduce(PriceHelper.sum),
      };
    }
  }

  /** CAMFIL CUSTOMIZATION: */
  /** Get all product ImageView ids
   *
   * @param product   The Product for which to get the image types
   * @param imageType The wanted ImageType
   * @returns         Array of available ImageView ids
   */

  static getImageViewIDs(product: Product, imageType: string): string[] {
    return product?.images?.filter(image => image.typeID === imageType).map(image => image.viewID);
  }

  /**
   * Get product image URL based on image type and image view
   *
   * @param product   The Product for which to get the image
   * @param imageType The wanted ImageType
   * @param imageView The wanted ImageView
   * @returns         The matching product image
   */

  static getImageCdnUrl(product: Product, imageType: string, imageView: string): string {
    return product?.images?.find(image => image.typeID === imageType && image.viewID === imageView)?.effectiveUrl;
  }

  /**
   * Get product technical documents
   *
   * @param product   The Product for which to get the technical documents
   * @param showAllDocsType
   * @param filterDocsByLanguage
   * @param langPrefix
   * @returns         The matching product technical documents
   */

  static getTechnicalDocuments(
    product: Product,
    showAllDocsType: boolean,
    filterDocsByLanguage: boolean,
    langPrefix: string
  ): CamfilProductTechnicalDocument[] {
    const productDocumentTypes = [
      {
        name: 'camfil.product.brochures.text',
        type: CamfilImageTypes.Brochures,
      },
      {
        name: 'camfil.product.pdf_web.text',
        type: CamfilImageTypes.ProductPdf,
      },
      {
        name: 'camfil.product.handling_maintenance.text',
        type: CamfilImageTypes.HamdlingAndMaintenance,
      },
    ];

    if (!product?.images) {
      return;
    }

    return product.images
      ?.filter(
        image =>
          (image.typeID === CamfilImageTypes.Brochures ||
            image.typeID === CamfilImageTypes.ProductPdf ||
            image.typeID === CamfilImageTypes.HamdlingAndMaintenance) &&
          (showAllDocsType || image.viewID === 'default')
      )
      .map(document => ({
        name: productDocumentTypes.find(prodDoc => prodDoc.type === document.typeID)?.name,
        effectiveUrl: CamfilProductHelper.getImageCdnUrl(product, document.typeID, document.viewID),
      }))
      .filter(doc => (filterDocsByLanguage ? doc.effectiveUrl.includes(langPrefix) : Boolean));
  }

  /**
   * Get product basges urls
   *
   * @param product   The Product for which to get the technical documents
   * @returns         The matching product badges urls
   */

  static getProductBadges(product: Product): string[] {
    if (!product?.images) {
      return;
    }
    return product.images
      ?.filter(image => image.typeID === CamfilImageTypes.Badge)
      .map(badge => CamfilProductHelper.getImageCdnUrl(product, badge.typeID, badge.viewID));
  }

  static getRequiresMeasurement(data: Product | ProductView): boolean {
    const label = CamfilAttributeGroupTypes.ProductsListLabelAttributes;
    const attrs = data?.attributeGroups?.[label]?.attributes;
    return AttributeHelper.getAttributeValueByAttributeName<boolean>(attrs, 'Requiresmeasures') || false;
  }

  static isNotZero(property: string | number) {
    if (!property) {
      return false;
    }

    return String(property) !== '0';
  }

  static disableIfNoMeasurements(product: Product, form: FormGroup) {
    const requiresMeasurement = CamfilProductHelper.getRequiresMeasurement(product);
    const measurements = form?.get('width')?.value && form?.get('height')?.value;
    return requiresMeasurement && !measurements;
  }

  static getFilterArea(data: Product | ProductView): number {
    const label = CamfilAttributeGroupTypes.ProductsListLabelAttributes;
    const attrs = data?.attributeGroups?.[label]?.attributes;
    const mainAttrs = data?.attributes;
    return (
      AttributeHelper.getAttributeValueByAttributeName<number>(attrs, 'FilterArea') ||
      AttributeHelper.getAttributeValueByAttributeName<number>(mainAttrs, 'FilterArea') ||
      0
    );
  }

  static validateFilterArea(product: Product, form: FormGroup): boolean {
    const filterArea = CamfilProductHelper.getFilterArea(product);
    const width = form?.get('width')?.value;
    const height = form?.get('height')?.value;
    if (!filterArea) {
      return true;
    } else {
      return (width * height) / 1000000 <= filterArea;
    }
  }

  static validateFilterAreaFromValues(product: Product | ProductView, width: number, height: number): boolean {
    const filterArea = CamfilProductHelper.getFilterArea(product);
    if (!filterArea) {
      return true;
    } else {
      return (width * height) / 1000000 <= filterArea;
    }
  }

  static setMaxLengthValidation(num: number, fieldName: string, validator: { [x: string]: CamfilErrorValidator[] }) {
    return fieldName in validator
      ? [
          ...validator[fieldName],
          {
            error: 'maxlength',
            message: 'camfil.form.error.maxLength',
            messageVariables: [`${num}`],
          },
        ]
      : validator[fieldName];
  }

  static setMaxLengthErrorForTableValidator(validator: CamfilErrorValidator[], num?: number) {
    return num
      ? validator.map(item => (item.error === 'maxlength' ? { ...item, messageVariables: [`${num}`] } : item))
      : validator;
  }

  static disableActionButton(product: Product, form: FormGroup) {
    return (
      CamfilProductHelper.disableIfNoMeasurements(product, form) ||
      !CamfilProductHelper.validateFilterArea(product, form)
    );
  }
}
