import { EntityState, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { CamfilCustomer } from 'camfil-models/camfil-customer/camfil-customer.model';

import { HttpError } from 'ish-core/models/http-error/http-error.model';
import { setErrorOn, setLoadingOn } from 'ish-core/utils/ngrx-creators';

import {
  loadCamfilCustomers,
  loadCamfilCustomersFail,
  loadCamfilCustomersSuccess,
  loadContactsByCustomer,
  loadContactsByCustomerFail,
  loadContactsByCustomerSuccess,
  loadDeliveryAddresses,
  loadDeliveryAddressesFail,
  loadDeliveryAddressesSuccess,
  loadUserContactForCustomer,
  loadUserContactForCustomerFail,
  loadUserContactForCustomerSuccess,
} from './camfil-customer.actions';

export const camfilCustomerFeatureKey = 'camfilCustomer';

export const camfilCustomersAdapter = createEntityAdapter<CamfilCustomer>({
  selectId: camfilCustomer => camfilCustomer?.id,
});

export interface CamfilCustomersState extends EntityState<CamfilCustomer> {
  loading: boolean;
  selected?: string;
  error: HttpError;
}

const initialState: CamfilCustomersState = camfilCustomersAdapter.getInitialState({
  loading: false,
  selected: undefined,
  error: undefined,
});

export const reducer = createReducer(
  initialState,
  setLoadingOn(loadCamfilCustomers, loadContactsByCustomer, loadUserContactForCustomer, loadDeliveryAddresses),
  setErrorOn(
    loadCamfilCustomersFail,
    loadContactsByCustomerFail,
    loadUserContactForCustomerFail,
    loadDeliveryAddressesFail
  ),
  on(loadCamfilCustomersSuccess, (state: CamfilCustomersState, action) => {
    const { customers } = action.payload;

    return camfilCustomersAdapter.upsertMany(customers, state);
  }),
  on(loadContactsByCustomerSuccess, (state: CamfilCustomersState, action) => {
    const { customerId, contacts } = action.payload;

    return camfilCustomersAdapter.updateOne(
      {
        id: customerId,
        changes: {
          contacts,
        },
      },
      state
    );
  }),
  on(loadUserContactForCustomerSuccess, (state: CamfilCustomersState, action) => {
    const { customerId, contact } = action.payload;

    return camfilCustomersAdapter.updateOne(
      {
        id: customerId,
        changes: {
          userContact: contact,
        },
      },
      state
    );
  }),
  on(loadDeliveryAddressesSuccess, (state: CamfilCustomersState, action) => {
    const { customerId, addresses } = action.payload;

    return camfilCustomersAdapter.updateOne(
      {
        id: customerId,
        changes: {
          deliveryAddresses: addresses,
        },
      },
      state
    );
  })
);
