/* eslint-disable ish-custom-rules/ban-imports-file-pattern */
import { ChangeDetectionStrategy, Component, DestroyRef, HostBinding, Inject, OnInit, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CamfilAttributeHelper } from 'camfil-models/camfil-attribute/attribute.helper';
import { CamfilQuickAddProduct } from 'camfil-models/camfil-bucket/camfil-bucket.model';
import { CamCardItemComment, CamCardMeasurement } from 'camfil-models/camfil-cam-card/cam-card.model';
import { CamfilMaxLengthTypes } from 'camfil-models/camfil-max-length-validator/camfil-max-length.types';
import { CamfilProductHelper } from 'camfil-models/camfil-product/product.helper';
import { CamfilProduct } from 'camfil-models/camfil-product/product.model';
import { CamfilDialogFormlyData } from 'camfil-shared/dialog/camfil-dialog-formly/camfil-dialog-formly.component';
import {
  camfilDialogIconNameIsFont,
  camfilDialogIconNameIsSVG,
} from 'camfil-shared/dialog/camfil-dialog-icon-name.type';
import { ADD_NEW_PRODUCT_VALIDATORS } from 'camfil-shared/product/camfil-product-quantity/validators';
import { isEmpty } from 'lodash-es';
import { Observable, ReplaySubject, of, shareReplay, withLatestFrom } from 'rxjs';
import { catchError, debounceTime, map, switchMap, tap } from 'rxjs/operators';

import { ShoppingFacade } from 'ish-core/facades/shopping.facade';
import { ProductView, createProductView } from 'ish-core/models/product-view/product-view.model';
import { Product, ProductCompletenessLevel } from 'ish-core/models/product/product.model';
import { markAsDirtyRecursive } from 'ish-shared/forms/utils/form-utils';

const FAKE_SKU = '144c9defac04969c7bfad8efaa8ea194';

const createFakeProduct = (product?: Product) =>
  createProductView({
    minOrderQuantity: 0,
    maxOrderQuantity: 0,
    sku: FAKE_SKU,
    available: true,
    attributes: [],
    failed: true,
    ...product,
  });

export type CamfilDialogAddNewProductDialogData = Pick<CamfilDialogFormlyData, 'title' | 'icon'>;

export type CamfilDialogAddNewProductDialogResult = CamfilQuickAddProduct;

@Component({
  selector: 'camfil-modal-add-new-product',
  templateUrl: './camfil-dialog-add-new-product.component.html',
  styleUrls: ['./camfil-dialog-add-new-product.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CamfilDialogAddNewProductComponent implements OnInit {
  constructor(
    private shoppingFacade: ShoppingFacade,
    private matDialogRef: MatDialogRef<CamfilDialogAddNewProductComponent, CamfilDialogAddNewProductDialogResult>,
    // eslint-disable-next-line ish-custom-rules/use-type-safe-injection-token
    @Inject(MAT_DIALOG_DATA) protected dialogData: CamfilDialogAddNewProductDialogData
  ) {}

  private static REQUIRED_COMPLETENESS_LEVEL = ProductCompletenessLevel.List;
  private destroyRef = inject(DestroyRef);

  iconNameIsFont = camfilDialogIconNameIsFont;
  iconNameIsSVG = camfilDialogIconNameIsSVG;

  @HostBinding('class.camfil-dialog') camfilDialogClass = true;

  sku$ = new ReplaySubject<string>(1);
  product$: Observable<CamfilProduct>;
  productIsLoading$: Observable<boolean>;
  productHasValidSku$: Observable<boolean>;
  productFormIsDisabled$: Observable<boolean>;

  productForm: FormGroup;
  isSubmitted = false;
  validators = ADD_NEW_PRODUCT_VALIDATORS;

  ngOnInit() {
    this.productForm = new FormGroup({
      sku: new FormControl('', {
        validators: [Validators.required],
        updateOn: 'change',
      }),
      quantity: new FormControl<number>(0),
      boxLabel: new FormControl<string>(undefined, [Validators.maxLength(CamfilMaxLengthTypes.BoxLabel)]),
      width: new FormControl<number>(undefined),
      height: new FormControl<number>(undefined),
      depth: new FormControl<number>(undefined),
      diameter: new FormControl<number>(undefined),
    });

    this.productForm
      .get('sku')
      .valueChanges?.pipe(debounceTime(500), shareReplay(1), takeUntilDestroyed(this.destroyRef))
      .subscribe(sku => {
        this.sku$.next(sku || FAKE_SKU);
      });

    this.product$ = this.sku$
      .pipe(
        switchMap(sku =>
          sku === FAKE_SKU
            ? of(createFakeProduct() as unknown as CamfilProduct)
            : this.shoppingFacade.product$(sku, CamfilDialogAddNewProductComponent.REQUIRED_COMPLETENESS_LEVEL).pipe(
                catchError(() => of(createFakeProduct())),
                map(product =>
                  product?.failed
                    ? (createFakeProduct(product as Product) as unknown as CamfilProduct)
                    : (product as CamfilProduct)
                )
              )
        )
      )
      .pipe(
        tap(({ minOrderQuantity, maxOrderQuantity }) => {
          const quantityControl = this.productForm?.get('quantity');
          quantityControl?.setValidators([Validators.min(minOrderQuantity), Validators.max(maxOrderQuantity)]);
          quantityControl?.setValue(0);
          quantityControl?.updateValueAndValidity();
        })
      );

    this.productIsLoading$ = this.sku$.pipe(switchMap(sku => (sku === FAKE_SKU ? of(false) : of(false))));

    this.productHasValidSku$ = this.product$.pipe(
      map((product: ProductView) => this.isSubmitted || (!product?.failed && product?.available)),
      tap(hasValidSku => {
        const skuControl = this.productForm?.get('sku');
        const required = isEmpty(skuControl?.value) || skuControl?.value === FAKE_SKU;
        const validSku = !hasValidSku && !isEmpty(skuControl?.value);

        skuControl.setErrors(!required && !validSku ? undefined : { required, validSku });
      })
    );

    this.productFormIsDisabled$ = this.productForm?.valueChanges?.pipe(
      withLatestFrom(this.product$, this.sku$, this.productHasValidSku$),
      map(([, product, sku, productWithValidSku]) =>
        sku === FAKE_SKU
          ? true
          : CamfilProductHelper.disableIfNoMeasurements(product as Product, this.productForm) ||
            !productWithValidSku ||
            !CamfilProductHelper.validateFilterArea(product as Product, this.productForm)
      )
    );
    this.sku$.next(FAKE_SKU);
  }

  close(): void {
    this.matDialogRef.close();
  }

  private getProductFormDimension(): CamCardMeasurement {
    return (({ width, height, depth }) => ({ width: Number(width), height: Number(height), depth: Number(depth) }))(
      this.productForm.getRawValue()
    );
  }

  submitForm() {
    /* TODO: Replace with event emitters and submit in parent components
        To be replaced in:
        1. camfil-checkout-bucket ---- done
        2. account-cam-card-detail-toolbar ---- done
        3. camfil-requisition-detail-toolbar
    */
    if (this.productForm.valid) {
      const skuValue = this.productForm?.get('sku')?.value ? String(this.productForm?.get('sku').value) : undefined;
      const quantityValue = this.productForm?.get('quantity')?.value
        ? Number(this.productForm?.get('quantity')?.value)
        : 1;
      const boxLabelValue = this.productForm?.get('boxLabel')?.value
        ? String(this.productForm?.get('boxLabel').value)
        : undefined;
      const boxLabel: CamCardItemComment = boxLabelValue ? { label: boxLabelValue } : undefined;
      const lineItemAttributes = CamfilAttributeHelper.calculateAttrsToAddFromForm(this.productForm);
      const measurements = this.getProductFormDimension();

      this.isSubmitted = true;
      this.matDialogRef.close({
        sku: skuValue,
        quantity: quantityValue,
        lineItemAttributes,
        boxLabel,
        measurements,
      });
    } else {
      markAsDirtyRecursive(this.productForm);
    }
  }

  reset() {
    this.sku$.next(FAKE_SKU);
    this.productForm?.reset();
    this.isSubmitted = false;
  }

  /** close modal */
  hide() {
    this.matDialogRef.close();
  }
}
