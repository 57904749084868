import { CamfilBasketFeedbackModel } from 'camfil-models/camfil-basket-feedback/camfil-basket-feedback.model';

export class CamfilBasketFeedbackHelper {
  static isMissingFieldErrorMessage(error: CamfilBasketFeedbackModel): boolean {
    return error?.parameters?.missingField && error?.code === 'basket.validation.camfil.missing_field.error';
  }

  static isCamfilThresholdMessage(info: CamfilBasketFeedbackModel) {
    return info?.code === 'basket.validation.camfil.threshold_not_met.info';
  }

  static isCamfilCreditLimitReachedMessage(info: CamfilBasketFeedbackModel) {
    return info?.code === 'basket.validation.camfil.credit_limit_reached.info';
  }

  static isDeliveryDateErrorMessage(error: CamfilBasketFeedbackModel): boolean {
    return error?.parameters?.addressId && error?.code === 'basket.validation.camfil.illegalvalue.deliverydate.error';
  }

  static isLineItemErrorMessage(error: CamfilBasketFeedbackModel): boolean {
    return !!(
      error.parameters &&
      error.code !== 'basket.validation.line_item_shipping_restrictions.error' &&
      error.parameters.scopes &&
      (error.parameters.scopes.includes('Addresses') || error.parameters.scopes.includes('Products')) &&
      error.parameters.lineItemId
    );
  }
}
