import { ChangeDetectionStrategy, Component, DestroyRef, HostBinding, Inject, OnInit, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { CamCardsFacade } from 'camfil-core/facades/camfil-cam-cards.facade';
import { CamfilCustomersFacade } from 'camfil-core/facades/camfil-customers.facade';
import { CamCard } from 'camfil-models/camfil-cam-card/cam-card.model';
import { CamfilContact, CamfilCustomer } from 'camfil-models/camfil-customer/camfil-customer.model';
import { Observable } from 'rxjs';

import { markAsDirtyRecursive } from 'ish-shared/forms/utils/form-utils';

export type CamfilMoveCamCardDialogData = CamCard[];

export type CamfilMoveCamCardDialogResult = never;

@Component({
  selector: 'camfil-move-cam-card-dialog',
  templateUrl: './camfil-move-cam-card-dialog.component.html',
  styleUrls: ['./camfil-move-cam-card-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CamfilMoveCamCardDialogComponent implements OnInit {
  @HostBinding('class.camfil-dialog') camfilDialogClass = true;

  moveForm: FormGroup;
  customers$: Observable<CamfilCustomer[]>;
  contacts: CamfilContact[];
  selectCustomer = '';
  selectAllContacts: boolean;

  private destroyRef = inject(DestroyRef);

  constructor(
    private camfilCustomersFacade: CamfilCustomersFacade,
    private camCardsFacade: CamCardsFacade,
    private fb: FormBuilder,
    private matDialogRef: MatDialogRef<CamfilMoveCamCardDialogComponent, CamfilMoveCamCardDialogResult>,
    // eslint-disable-next-line ish-custom-rules/use-type-safe-injection-token
    @Inject(MAT_DIALOG_DATA) private dialogData: CamfilMoveCamCardDialogData
  ) {}

  ngOnInit() {
    this.customers$ = this.camfilCustomersFacade.customers$;
    this.initForm();
  }

  private initForm() {
    // TODO Convert to formly
    this.moveForm = this.fb.group({
      customers: ['', [Validators.required]],
      contacts: ['', [Validators.required]],
    });
  }

  submitMoveCamCardForm() {
    if (this.moveForm.valid) {
      const newCustomer = this.moveForm.get('customers').value;
      const newContacts = this.selectAllContacts
        ? []
        : this.moveForm.get('contacts').value.map((item: string) => ({ profileId: item }));
      this.dialogData.forEach(({ id }: CamCard) => {
        this.camCardsFacade.moveCamCard(id, newCustomer, newContacts);
      });
      this.matDialogRef.close();
    } else {
      markAsDirtyRecursive(this.moveForm);
    }
  }

  handleCustomers(event: MatSelectChange) {
    this.camfilCustomersFacade.loadContactsByCustomer(event.value);
    this.camfilCustomersFacade
      .getContactsByCustomer$(event.value)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((contacts: CamfilContact[]) => (this.contacts = contacts));
  }

  handleContacts(event: MatSelectChange) {
    this.selectAllContacts = event.value[0] === 'all';
  }

  closeDialog(): void {
    this.matDialogRef.close();
  }
}
