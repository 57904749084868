import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  inject,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute } from '@angular/router';

import { FilterNavigation } from 'ish-core/models/filter-navigation/filter-navigation.model';
import { whenTruthy } from 'ish-core/utils/operators';
import { URLFormParams, stringToFormParams } from 'ish-core/utils/url-form-params';

@Component({
  selector: 'camfil-filter-navigation-badges',
  templateUrl: './camfil-filter-navigation-badges.component.html',
  styleUrls: ['./camfil-filter-navigation-badges.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CamfilFilterNavigationBadgesComponent implements OnChanges {
  @Input() filterNavigation: FilterNavigation;
  @Input() appliedFilters: string;
  @Output() applyFilter = new EventEmitter<{ searchParameter: URLFormParams }>();
  @Output() clearFilters = new EventEmitter<void>();

  selected: { searchParameter: URLFormParams; displayName: string; filterName: string; filterId: string }[];

  width: undefined | string;
  height: undefined | string;
  depth: undefined | string;
  diameter: undefined | string;

  filters: URLSearchParams;

  private destroyRef = inject(DestroyRef);

  constructor(private activatedRoute: ActivatedRoute) {}

  ngOnChanges() {
    this.selected = this.filterNavigation?.filter?.reduce(
      (acc, filterElement) => [
        ...acc,
        ...filterElement.facets
          .filter(facet => facet.selected)
          .map(({ searchParameter, displayName }) => ({
            filterId: filterElement.id,
            filterName: filterElement.name,
            displayName,
            searchParameter,
          })),
      ],
      []
    );

    this.activatedRoute.queryParams.pipe(whenTruthy(), takeUntilDestroyed(this.destroyRef)).subscribe(params => {
      this.filters = new URLSearchParams(decodeURIComponent(params.filters));
      this.width = this.calculateFilterValue('Width');
      this.height = this.calculateFilterValue('Height');
      this.depth = this.calculateFilterValue('Depth');
      this.diameter = this.calculateFilterValue('Diameter');
    });
  }

  calculateFilterValue(name: string) {
    const value = parseInt(this.filters.get(name), 10);
    const lowerBound = parseInt(this.filters.get(`${name}[gte]`), 10);
    const upperBound = parseInt(this.filters.get(`${name}[lte]`), 10);
    if (!isNaN(lowerBound) && !isNaN(upperBound)) {
      return `[${lowerBound} - ${upperBound}]`;
    } else if (!isNaN(value)) {
      return `${value}`;
    } else {
      return;
    }
  }

  apply(searchParameter: URLFormParams) {
    this.applyFilter.emit({ searchParameter });
  }

  clear() {
    this.clearFilters.emit();
  }

  /**
   *  Remove a filter from search parameters
   * */
  removeFilter(targetFilter: string) {
    const currentFilterParams = this.stripFilterParameter(stringToFormParams(this.filters.toString()), targetFilter);
    this.applyFilter.emit({ searchParameter: currentFilterParams });
  }

  /**
   *  Remove a specific URL parameter
   * */
  stripFilterParameter(params: URLFormParams, filter: string) {
    if (!params) {
      return;
    }

    return Object.keys(params)
      .filter(key => !key.startsWith(filter))
      .reduce((obj, key) => {
        obj[key] = params[key];
        return obj;
        // eslint-disable-next-line ish-custom-rules/no-object-literal-type-assertion
      }, {} as Record<string, string[]>);
  }

  countNotNull(array: (undefined | string)[]) {
    let i = array.length;
    let count = 0;
    while (i--) {
      if (array[i]) {
        count++;
      }
    }
    return count;
  }
}
