import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IntervalDataType } from 'camfil-models/camfil-cam-card/cam-card.interface';
import { CamCard, CamCardItem } from 'camfil-models/camfil-cam-card/cam-card.model';

@Component({
  selector: 'camfil-cam-card-last-delivery-date',
  templateUrl: './cam-card-last-delivery-date.component.html',
  styleUrls: ['./cam-card-last-delivery-date.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CamCardLastDeliveryDateComponent {
  @Input() lastDeliveryDateType: IntervalDataType = 'subCamCard';
  @Input() camCard: CamCard;
  @Input() camCardItem: CamCardItem;

  get lastDeliveryDate(): string {
    switch (this.lastDeliveryDateType) {
      case 'subCamCard':
        return this.camCard?.lastDeliveryDate || '';
      case 'articleRow':
        return this.camCardItem?.lastDeliveryDate ?? this.camCard?.lastDeliveryDate ?? '';
      default:
        return;
    }
  }
}
