import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CamfilAhuFacade } from 'camfil-core/facades/camfil-ahu.facade';
import { CamfilShoppingFacade } from 'camfil-core/facades/camfil-shopping.facade';
import { NextOpenLevelOnMobileNavType } from 'camfil-models/camfil-viewtype/camfil-viewtype.types';
import { Observable, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

import { AccountFacade } from 'ish-core/facades/account.facade';
import { NavigationCategory } from 'ish-core/models/navigation-category/navigation-category.model';
import { DeviceType } from 'ish-core/models/viewtype/viewtype.types';
import { AuthorizationToggleService } from 'ish-core/utils/authorization-toggle/authorization-toggle.service';

@Component({
  selector: 'camfil-header-navigation',
  templateUrl: './camfil-header-navigation.component.html',
  styleUrls: ['./camfil-header-navigation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CamfilHeaderNavigationComponent implements OnInit {
  @Input() deviceType: DeviceType;
  @Output() isClosedCat = new EventEmitter<NextOpenLevelOnMobileNavType>();

  ahuUrl$: Observable<string>;
  categories$: Observable<NavigationCategory[]>;
  isAuthorizedToViewOrderHistory$: Observable<boolean>;
  isAuthorizedToViewPunchout$: Observable<boolean>;
  isAuthorizedToViewQuotes$: Observable<boolean>;
  isLoggedIn$: Observable<boolean>;

  isMobileView = false;
  isDropDownOpened = false;

  orderHistoryRequiredPermissions = [
    'APP_B2B_MANAGE_ALL_ORDERS',
    'APP_B2B_MANAGE_OWN_ORDERS',
    'APP_B2B_CXML_USER',
    'APP_B2B_OCI_USER',
  ];
  punchoutRequiredPermissions = ['APP_B2B_MANAGE_PUNCHOUT'];
  quotesRquiredPermissions = ['APP_B2B_MANAGE_ALL_ORDERS'];

  private openedCategories: string[] = [];

  constructor(
    private camfilShoppingFacade: CamfilShoppingFacade,
    private accountFacade: AccountFacade,
    private ahuFacade: CamfilAhuFacade,
    private authorizationToggle: AuthorizationToggleService
  ) {}

  ngOnInit() {
    this.ahuUrl$ = this.ahuFacade.ahuUrl$;
    this.categories$ = this.camfilShoppingFacade.navigationCategories$();
    this.isLoggedIn$ = this.accountFacade.isLoggedIn$;
    this.isMobileView = this.deviceType === 'tablet' || this.deviceType === 'mobile';
    this.isAuthorizedToViewPunchout$ = combineLatest([
      this.isLoggedIn$,
      this.authorizationToggle.isAuthorizedTo(this.punchoutRequiredPermissions),
    ]).pipe(map(([isLoggedIn, isAuthorized]) => isLoggedIn && isAuthorized));
    this.isAuthorizedToViewOrderHistory$ = combineLatest([
      this.isLoggedIn$,
      this.authorizationToggle.isAuthorizedTo(this.orderHistoryRequiredPermissions),
    ]).pipe(map(([isLoggedIn, isAuthorized]) => isLoggedIn && isAuthorized));
    this.isAuthorizedToViewQuotes$ = combineLatest([
      this.isLoggedIn$,
      this.authorizationToggle.isAuthorizedTo(this.quotesRquiredPermissions),
    ]).pipe(map(([isLoggedIn, isAuthorized]) => isLoggedIn && isAuthorized));
  }

  subMenuShow(submenu: HTMLElement): void {
    submenu.classList.add('hover');
  }

  subMenuHide(submenu: HTMLElement): void {
    submenu.classList.remove('hover');
  }

  isOpened(uniqueId: string): boolean {
    return this.openedCategories.includes(uniqueId);
  }

  toggleOpen(uniqueId: string) {
    const index = this.openedCategories.findIndex(id => id === uniqueId);

    if (index > -1) {
      this.openedCategories.splice(index, 1);
    } else {
      this.openedCategories.push(uniqueId);
    }

    const opened: NextOpenLevelOnMobileNavType = this.isOpened(uniqueId) ? 'category' : '';
    this.isClosedCat.emit(opened);
  }

  toggleSubMenu($event: Event, submenu: HTMLElement): void {
    $event.preventDefault();
    $event.stopPropagation();

    if (!this.isMobileView || this.isDropDownOpened) {
      this.subMenuHide(submenu);
      this.isDropDownOpened = false;
    } else {
      this.subMenuShow(submenu);
      this.isDropDownOpened = true;
    }
  }
}
