import { Injectable } from '@angular/core';
import { Dictionary } from '@ngrx/entity';
import { CamfilAddress } from 'camfil-models/camfil-address/camfil-address.model';
import { CamfilCustomer } from 'camfil-models/camfil-customer/camfil-customer.model';

import { CamCardHelper } from './cam-card.helper';
import { CamCardData } from './cam-card.interface';
import { BasicCamCard, CamCard, CamCardToBasketFeedback } from './cam-card.model';

@Injectable({ providedIn: 'root' })
export class CamCardMapper {
  /**
   * Cleanup Delivery Address
   * Just to make sure that we get rid of deprecated property from the payload since the ICM API still supports it.
   *
   * @param deprecatedDeliveryAddress
   */
  static cleanupDeliveryAddress(
    deprecatedDeliveryAddress: CamfilAddress & { company?: string; street2?: string }
  ): CamfilAddress {
    if (!deprecatedDeliveryAddress) {
      return;
    }
    const { company: _, street2: __, ...deliveryAddress } = deprecatedDeliveryAddress;
    return deliveryAddress as CamfilAddress;
  }

  static supplementCamCardsFeedback(infos: CamCardToBasketFeedback[], camCards: CamCard[]): CamCardToBasketFeedback[] {
    return infos.map(d => {
      const { name, id } = camCards.find(({ id }) => d.parameters.camCardId === id) || {};
      return {
        ...d,
        parameters: {
          ...d.parameters,
          camCardName: name || id,
        },
      };
    });
  }

  fromData(camCardData: CamCardData): CamCard {
    if (camCardData) {
      let totalLineItemsCount = camCardData?.totalLineItemsCount;

      if (!totalLineItemsCount) {
        totalLineItemsCount = camCardData.camCardItems ? camCardData.camCardItems.length : 0;
        const itemsFromSubCamCard: number = camCardData.subCamCards
          ? camCardData.subCamCards.reduce((result, data) => result + data.camCardItems.length, 0)
          : 0;
        totalLineItemsCount += itemsFromSubCamCard;
      }

      const subCamCards = camCardData.subCamCards
        ? CamCardHelper.ccSorter(
            camCardData.subCamCards.map(sub => ({
              ...sub,
              name: sub.name || 'Building',
              totalLineItemsCount: sub.totalLineItemsCount || (sub.camCardItems ? sub.camCardItems.length : 0),
            }))
          )
        : [];

      const deliveryAddress = CamCardMapper.cleanupDeliveryAddress(camCardData.deliveryAddress);
      const camCard = {
        ...camCardData,
        name: camCardData.name,
        customerId: camCardData.customer?.id,
        totalLineItemsCount,
        deliveryAddress,
      };

      return subCamCards.length
        ? {
            ...camCard,
            subCamCards,
          }
        : camCard;
    } else {
      throw new Error(`camCardData is required`);
    }
  }

  fromListData(camCards: CamCardData[]): CamCard[] {
    return camCards.map(this.fromData);
  }

  fromUpdate(camCard: CamCard, id: string): CamCard {
    if (camCard && id) {
      const {
        name,
        customerId,
        orderLabel,
        invoiceLabel,
        nextDeliveryDate,
        lastDeliveryDate,
        deliveryInterval,
        reminderFlag,
        erpId,
      } = camCard;

      const deliveryAddress = CamCardMapper.cleanupDeliveryAddress(camCard.deliveryAddress);

      return {
        id,
        name,
        orderLabel,
        invoiceLabel,
        customerId,
        deliveryAddress,
        nextDeliveryDate,
        lastDeliveryDate,
        deliveryInterval,
        reminderFlag,
        erpId,
      };
    }
  }

  fromBasicListData(camCards: BasicCamCard[], customers: Dictionary<CamfilCustomer>): CamCard[] {
    return camCards.map((cc: BasicCamCard) => {
      const contacts = customers?.[cc.customerId]?.contacts?.filter(({ profileId }) =>
        cc.contactProfileIds.includes(profileId)
      );

      const {
        id,
        name,
        transient,
        maintenanceStatus,
        totalLineItemsCount,
        currentUserHasNormalAccessRights,
        deliveryInterval,
        customerId,
        parentId,
        erpId,
        lastDeliveryDate,
        nextDeliveryDate,
        subCamCards,
      } = cc;

      return {
        id,
        name,
        transient,
        maintenanceStatus,
        totalLineItemsCount,
        currentUserHasNormalAccessRights,
        deliveryInterval,
        parentId,
        erpId,
        lastDeliveryDate,
        nextDeliveryDate,
        subCamCards,
        contacts,
        customerId,
        customer: customers[cc.customerId],
      };
    });
  }
}
