<dl *ngIf="totals" [ngClass]="cssClass">
  <!-- ItemTotal -->
  <dt class="col-8">
    <camfil-content-by-role [returnString]="'camfil.dynamic.checkout.single_total_price_net'" />
  </dt>
  <dd class="col-4">{{ totals.itemTotal | ishPrice }}</dd>
  <!-- VolumeDiscount -->
  <ng-container *ngIf="totals?.volumeDiscount">
    <dt class="col-6">{{ 'camfil.checkout.volume_discount' | translate }}</dt>
    <dd class="col-6">{{ totals.volumeDiscount }}</dd>
  </ng-container>
  <!-- Surcharges -->
  <ng-container *ngFor="let surcharge of totals.surcharges">
    <dt class="col-6" [class.font-weight-bold]="surcharge?.bold">
      {{ surcharge.displayName | slugify : 'camfil.dynamic' | translate }}
      <mat-icon *ngIf="surcharge.description" [matTooltip]="surcharge.description" svgIcon="question-mark" />
    </dt>
    <dd class="col-6" [class.text-line-through]="surcharge?.strikethrough" [class.font-weight-bold]="surcharge?.bold">
      {{ surcharge.amount | ishPrice }}
    </dd>
  </ng-container>
  <!-- Total (w/o taxes) -->
  <ng-container *camfilChannelToggle="'showTotalWithoutTaxInBucketSummary'">
    <dt class="col-6 font-weight-bold">
      <camfil-content-by-role [returnString]="'camfil.dynamic.checkout.order_total_price_net'" />
    </dt>
    <dd class="col-6 font-weight-bold">{{ totals.total | ishPrice : 'net' }}</dd>
  </ng-container>
  <!-- Tax -->
  <dt class="col-6">{{ 'camfil.checkout.tax_amount' | translate }}</dt>
  <dd class="col-6">{{ totals.taxTotal | ishPrice }}</dd>
  <!-- Total -->
  <dt class="col-6 font-weight-bold">
    <camfil-content-by-role [returnString]="'camfil.dynamic.checkout.order_total_price'" />
  </dt>
  <dd class="col-6 font-weight-bold">{{ totals.total | ishPrice : 'gross' }}</dd>
</dl>
