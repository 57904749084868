<h2 class="title">{{ 'camfil.product.details.guides.headline' | translate }}</h2>
<ul class="list">
  <li class="list-item" *ngFor="let productTechnicalDocument of productTechnicalDocuments$ | async">
    <a href="{{ productTechnicalDocument.effectiveUrl }}" download target="_blank" class="list-item-link"
      ><mat-icon svgIcon="pdf" />
      <span [ngSwitch]="productTechnicalDocument.typeID">
        <ng-container *ngSwitchCase="TECHNICAL_DOCUMENT_TYPES.Brochures">{{
          'camfil.product.brochures.text' | translate
        }}</ng-container>
        <ng-container *ngSwitchCase="TECHNICAL_DOCUMENT_TYPES.ProductPdf">{{
          'camfil.product.pdf_web.text' | translate
        }}</ng-container>
        <ng-container *ngSwitchCase="TECHNICAL_DOCUMENT_TYPES.HamdlingAndMaintenance">{{
          'camfil.product.handling_maintenance.text' | translate
        }}</ng-container>
      </span></a
    >
  </li>
  <ish-content-viewcontext
    *ngIf="productSKU$ | async as productSKU"
    viewContextId="vc_camfil_product_guides"
    [callParameters]="{ Product: productSKU }"
  />
</ul>
