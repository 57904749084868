import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { CamfilConfigurationFacade } from 'camfil-core/facades/camfil-configuration.facade';
import { map } from 'rxjs';

export function maintenanceGuard() {
  const router = inject(Router);
  const camfilConfigurationFacade = inject(CamfilConfigurationFacade);

  return camfilConfigurationFacade.isMaintenanceMode$.pipe(
    map(isMaintenanceMode => (isMaintenanceMode ? router.parseUrl('/maintenance') : true))
  );
}
