import { CamfilBasketData } from 'camfil-models/camfil-basket/camfil-basket.interface';
import { CamfilBasketMapper } from 'camfil-models/camfil-basket/camfil-basket.mapper';

import { CamfilBasketValidationData } from './camfil-basket-validation.interface';
import { CamfilBasketValidation } from './camfil-basket-validation.model';

export class CamfilBasketValidationMapper {
  static fromData(data: CamfilBasketValidationData): CamfilBasketValidation {
    if (data) {
      const basket = data?.data?.scopes?.includes('CamfilInfo')
        ? undefined
        : CamfilBasketMapper.fromCamfilBasketUpdateData(CamfilBasketValidationMapper.transform(data));

      return {
        basket,
        basketId: data.data.basket,
        results: {
          valid: data.data.results?.valid,
          adjusted: data.data.results?.adjusted,
          errors: data.data.results?.errors,
          infos: data.data.results?.infos,
        },
        scopes: data.data.scopes,
      };
    }
  }

  // eslint-disable-next-line complexity
  private static transform(basketValidationData: CamfilBasketValidationData): CamfilBasketData {
    const { included, data } = basketValidationData;
    return {
      data: included?.basket[data.basket] || undefined,
      included: included
        ? {
            invoiceToAddress: included.basket_invoiceToAddress || undefined,
            lineItems: included.basket_lineItems || undefined,
            discounts: included.basket_discounts || undefined,
            lineItems_discounts: included.basket_lineItems_discounts || undefined,
            commonShipToAddress: included.basket_commonShipToAddress || undefined,
            commonShippingMethod: included.basket_commonShippingMethod || undefined,
            payments: included.basket_payments || undefined,
            payments_paymentMethod: included.basket_payments_paymentMethod || undefined,
            payments_paymentInstrument: included.basket_payments_paymentInstrument || undefined,
            camfilProductLineItems: included.basket_camfilProductLineItems || undefined,
          }
        : undefined,
    };
  }
}
