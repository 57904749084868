import { createAction } from '@ngrx/store';
import { AhuSupportMessage } from 'camfil-models/camfil-ahu/ahu.model';
import { UnitFilterSlotItemParams } from 'camfil-models/camfil-ahu/unit-filter/unit-filter.model';
import { Unit } from 'camfil-models/camfil-ahu/unit/unit.model';

import { httpError, payload } from 'ish-core/utils/ngrx-creators';

export const loadAhuUnits = createAction('[AHU Unit Internal] Load AHU Units', payload<{ manufacturerId: string }>());

export const loadAhuUnitsSuccess = createAction('[AHU Unit API] Load AHU Units Success', payload<{ units: Unit[] }>());

export const loadAhuUnitsFail = createAction('[AHU Unit API] Load AHU Units Fail', httpError());

export const selectAhuUnit = createAction('[AHU Unit Internal] Select Unit', payload<{ unitId: number }>());

export const deselectAhuUnit = createAction('[AHU Unit Internal] Deselect Unit');

export const addAhuSlotItemToList = createAction(
  '[AHU Unit Internal] Add AHU Unit Slot Item To List',
  payload<UnitFilterSlotItemParams & { quantity: number }>()
);

export const removeAhuSlotItemFromList = createAction(
  '[AHU Unit Internal] Remove AHU Unit Slot Item From List',
  payload<UnitFilterSlotItemParams>()
);

export const sendAhuRequestComment = createAction(
  '[AHU Unit Internal] Send request to Support',
  payload<{ data: AhuSupportMessage }>()
);
