<div class="d-flex justify-content-between pb-3">
  <div>
    <ng-content select="[slot=back]"></ng-content>
    <h1 #title [hidden]="!showTitleContent" class="align-middle d-none d-md-inline">
      <ng-content select="[slot=title]"></ng-content>
    </h1>
  </div>
  <div #extra [hidden]="!showExtraContent">
    <ng-content select="[slot=extra]"></ng-content>
  </div>
</div>

<h1 class="d-block d-md-none">
  <ng-content select="[slot=title]"></ng-content>
</h1>
