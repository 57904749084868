/* eslint-disable ish-custom-rules/ban-imports-file-pattern */
import { createAction } from '@ngrx/store';
import { CamfilQueueTask } from 'camfil-models/camfil-queue/camfil-queue.model';

import { HttpError } from 'ish-core/models/http-error/http-error.model';
import { payload } from 'ish-core/utils/ngrx-creators';

export const enqueueTask = createAction('[Camfil Queue] Enqueue', payload<{ task: CamfilQueueTask }>());
export const dequeueTask = createAction('[Camfil Queue] Dequeue', payload<{ id: string }>());
export const startTask = createAction('[Camfil Queue] Start', payload<{ id: string }>());
export const completeTask = createAction('[Camfil Queue] Complete', payload<{ id: string }>());
// TODO: confirm error prop is necessary
export const failTask = createAction('[Camfil Queue] Fail', payload<{ id: string; error?: HttpError }>());
export const retryTask = createAction('[Camfil Queue] Retry', payload<{ id: string }>());
export const clearQueue = createAction('[Camfil Queue] Clear Queue');
