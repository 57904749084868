<header id="header" class="header">
  <div [ngClass]="{ 'd-none': (deviceType$ | async) === 'mobile' }">
    <ng-container *ngTemplateOutlet="tabletDesktopHeader" />
  </div>
  <div [ngClass]="{ 'd-none': (deviceType$ | async) !== 'mobile' }">
    <ng-container *ngTemplateOutlet="mobileHeader" />
  </div>
</header>

<ng-template #tabletDesktopHeader>
  <div class="container" [ngClass]="{ 'd-none': (isSticky$ | async) }">
    <div class="d-flex">
      <nav class="header__links user-links">
        <div class="header__links__dropdown" role="listbox" [matMenuTriggerFor]="menu">
          {{ 'camfil.header.dropdown.title' | translate }}
        </div>
        <mat-menu #menu="matMenu">
          <ish-lazy-content-include includeId="camfil.include.header.dropdownLinks.pagelet2-Include" />
        </mat-menu>
        <div class="no-separator header__top-links">
          <ish-lazy-content-include includeId="camfil.include.header.topLinks.pagelet2-Include" />
        </div>
      </nav>
      <div class="header-utility" aria-label="Header utility">
        <camfil-language-switch />
        <camfil-login-status />
      </div>
    </div>

    <div class="row align-items-center">
      <camfil-logo logoVersion="full" class="col-md-6 col-lg-4 col-xl-3 logo-col d-flex" />
      <camfil-lazy-search
        *ngIf="(deviceType$ | async) !== 'mobile'"
        [device]="deviceType$ | async"
        class="col-12 col-md-6"
      />
      <camfil-mini-basket
        aria-label="Shopping cart"
        class="col-md-12 col-lg-2 col-xl-3 d-flex align-items-center justify-content-end mini-basket"
      />
    </div>
    <camfil-header-navigation class="main-navigation navbar-expand-md" />
  </div>
  <div class="container d-none" [ngClass]="{ 'd-flex': (isSticky$ | async) }">
    <camfil-logo />
    <camfil-header-navigation class="main-navigation navbar-expand-md" />
  </div>
</ng-template>

<ng-template #mobileHeader>
  <div class="header__first header-utility">
    <camfil-login-status />
  </div>
  <div class="logo-col d-flex justify-content-between header__second">
    <button
      mat-icon-button
      color="primary"
      class="navbar-toggler"
      attr.aria-label="{{ 'camfil.common.button.navbarCollapsed.menu.aria_label' | translate }}"
      aria.controls="navbar"
      (click)="toggle('navbar')"
    >
      <mat-icon [svgIcon]="activeComponent === 'navbar' ? 'inactive' : 'menu-burger'" />
    </button>
    <camfil-logo />
    <camfil-mini-basket class="d-flex align-items-center justify-content-end" />
  </div>
  <div class="header__third" *ngIf="(deviceType$ | async) === 'mobile'">
    <camfil-lazy-search [device]="deviceType$ | async" />
  </div>
  <div class="global-nav-container">
    <nav class="global-nav navbar-expand-md" [ngbCollapse]="activeComponent !== 'navbar'">
      <div class="main-navigation">
        <camfil-header-navigation />
        <ul class="header__links">
          <li>
            <ish-lazy-content-include includeId="camfil.include.header.dropdownLinks.pagelet2-Include" />
          </li>
          <li class="no-separator">
            <camfil-language-switch />
          </li>
          <li class="no-separator">
            <ish-lazy-content-include includeId="camfil.include.header.topLinks.pagelet2-Include" />
          </li>
        </ul>
      </div>
    </nav>
  </div>
</ng-template>
