import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import {
  approveCamfilQuote,
  approveCamfilQuotes,
  loadCamfilQuoteIfNotLoaded,
  loadCamfilQuotes,
  printCamfilQuote,
  rejectCamfilQuote,
  rejectCamfilQuotes,
  updateCamfilQuote,
} from 'camfil-core/store/camfil-quotes/camfil-quotes.actions';
import {
  getCamfilQuote,
  getCamfilQuotes,
  getCamfilQuotesApprovedSuccess,
  getCamfilQuotesLoading,
  getCamfilQuotesRejectedSuccess,
  getSelectedCamfilQuote,
} from 'camfil-core/store/camfil-quotes/camfil-quotes.selectors';
import { CamfilProductView, ProductCompletenessLevel } from 'camfil-models/camfil-product/product.model';
import { CamfilQuoteServiceRequest } from 'camfil-models/camfil-quotes/camfil-quote-service-request/camfil-quote-service-request.model';
import { CamfilQuoteHelper } from 'camfil-models/camfil-quotes/camfil-quote/camfil-quote.helper';
import { CamfilQuote } from 'camfil-models/camfil-quotes/camfil-quote/camfil-quote.model';
import { combineLatest, map, of, switchMap } from 'rxjs';

import { ShoppingFacade } from 'ish-core/facades/shopping.facade';
import { ProductView } from 'ish-core/models/product-view/product-view.model';

@Injectable({ providedIn: 'root' })
export class CamfilQuotesFacade {
  quotes$ = this.store.pipe(select(getCamfilQuotes));
  quotesLoading$ = this.store.pipe(select(getCamfilQuotesLoading));
  selectedQuote$ = this.store.pipe(select(getSelectedCamfilQuote));
  approvedQuotesSuccess$ = this.store.pipe(select(getCamfilQuotesApprovedSuccess));
  rejectedQuotesSuccess$ = this.store.pipe(select(getCamfilQuotesRejectedSuccess));

  constructor(private store: Store, private shoppingFacade: ShoppingFacade) {}

  quote$(quoteId: string) {
    return this.store.pipe(select(getCamfilQuote(quoteId)));
  }

  quoteProducts$(quoteId: string) {
    return this.quote$(quoteId).pipe(
      map(quote => CamfilQuoteHelper.getQuotationSkus(quote)),
      switchMap(skus =>
        skus.length > 0
          ? combineLatest(skus.map(sku => this.shoppingFacade.product$(sku, ProductCompletenessLevel.Detail)))
          : of([] as ProductView[])
      )
    );
  }

  loadCamfilQuotes() {
    this.store.dispatch(loadCamfilQuotes());
  }

  loadCamfilQuote(quoteId: string) {
    this.store.dispatch(loadCamfilQuoteIfNotLoaded({ quoteId }));
  }

  approveCamfilQuote(request: CamfilQuoteServiceRequest) {
    this.store.dispatch(approveCamfilQuote({ request }));
  }

  approveCamfilQuotes(request: CamfilQuoteServiceRequest[]) {
    this.store.dispatch(approveCamfilQuotes({ request }));
  }

  rejectCamfilQuote(request: CamfilQuoteServiceRequest, rejectionReason: string) {
    this.store.dispatch(rejectCamfilQuote({ request, rejectionReason }));
  }

  rejectCamfilQuotes(request: CamfilQuoteServiceRequest[], rejectionReason: string) {
    this.store.dispatch(rejectCamfilQuotes({ request, rejectionReason }));
  }

  updateCamfilQuote(quote: CamfilQuote) {
    this.store.dispatch(updateCamfilQuote({ quote }));
  }

  printCamfilQuote(quoteId: string, products: CamfilProductView[]) {
    this.store.dispatch(printCamfilQuote({ quoteId, products }));
  }
}
