import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import Swiper from 'swiper';

import { Image } from 'ish-core/models/image/image.model';

/* There are no good way to provide descriptive alt attributes.
   TODO: 
    - Video support.
*/
@Component({
  selector: 'camfil-product-gallery',
  templateUrl: './camfil-product-gallery.component.html',
  styleUrls: ['./camfil-product-gallery.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CamfilProductGalleryComponent implements OnChanges {
  @Input()
  images: Image[];

  resizedImages: Image[];
  thumbnailsSwiper: Swiper;

  onThumbnailsSwiperCreated(swiper: Swiper): void {
    this.thumbnailsSwiper = swiper;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('images' in changes) {
      this.resizedImages = this.images.map(image => {
        let url: undefined | URL = undefined;
        try {
          url = new URL(image.effectiveUrl);
        } catch (error) {
          return image;
        }
        url.searchParams.set('width', String(280));
        return {
          ...image,
          effectiveUrl: url.toString(),
        };
      });
    }
  }
}
