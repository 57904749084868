import { ChangeDetectionStrategy, Component, OnInit, ViewChild } from '@angular/core';
import { CamfilConfigurationFacade } from 'camfil-core/facades/camfil-configuration.facade';
import { StatePropertiesService } from 'camfil-core/utils/state-transfer/state-properties.service';
import { Observable } from 'rxjs';

import { AppFacade } from 'ish-core/facades/app.facade';
import { DeviceType } from 'ish-core/models/viewtype/viewtype.types';

import { Environment } from '../environments/environment.model';

@Component({
  selector: 'camfil-root',
  templateUrl: './camfil-app.component.html',
  styleUrls: ['./camfil-app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CamfilAppComponent implements OnInit {
  @ViewChild('cookie', { static: true })
  wrapperClasses$: Observable<string[]>;
  deviceType$: Observable<DeviceType>;

  constructor(
    private appFacade: AppFacade,
    private camfilChannelConfiguration: CamfilConfigurationFacade,
    private stateProperties: StatePropertiesService
  ) {}

  isMaintenanceMode$ = this.camfilChannelConfiguration.isMaintenanceMode$;

  livechatLicense$ = this.stateProperties.getStateOrEnvOrDefault<Environment['livechatLicense']>(
    'LIVECHAT_LICENSE',
    'livechatLicense'
  );

  livechatVisibility$ = this.stateProperties.getStateOrEnvOrDefault<Environment['livechatVisibility']>(
    'LIVECHAT_VISIBILITY',
    'livechatVisibility'
  );

  ngOnInit() {
    this.deviceType$ = this.appFacade.deviceType$;
    this.wrapperClasses$ = this.appFacade.appWrapperClasses$;
  }
}
