/* eslint-disable ish-custom-rules/no-intelligence-in-artifacts */
import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  ElementRef,
  HostBinding,
  Inject,
  OnInit,
  ViewChild,
  inject,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CamCardsFacade } from 'camfil-core/facades/camfil-cam-cards.facade';
import { CamfilDialogFacade } from 'camfil-core/facades/camfil-dialog.facade';
import { CamCardHelper } from 'camfil-models/camfil-cam-card/cam-card.helper';
import { AddToCamCardItem, CamCard } from 'camfil-models/camfil-cam-card/cam-card.model';
import { CamfilMaxLengthTypes } from 'camfil-models/camfil-max-length-validator/camfil-max-length.types';
import {
  CamCardCreateDialogComponent,
  CamCardCreateDialogComponentData,
  CamCardCreateDialogComponentResult,
} from 'camfil-shared/cam-cards/components/cam-card-create-dialog/cam-card-create-dialog.component';
import { Observable, combineLatest, map, startWith, take, withLatestFrom } from 'rxjs';

import { AppFacade } from 'ish-core/facades/app.facade';
import { RoleToggleService } from 'ish-core/role-toggle.module';

export type CamfilProductFormDialogCamCardComponentData = {
  name: string;
  sku: string;
  quantity: number;
}[];

export type CamfilProductFormDialogCamCardComponentResult = void;

function mapRoleToLabel(base: string) {
  return ([roleB2BRequestQuotation, roleB2BNeedsApproval]: [boolean, boolean]): string => {
    if (roleB2BRequestQuotation) {
      return `${base}_quote`;
    }

    if (roleB2BNeedsApproval) {
      return `${base}_requisition`;
    }

    return base;
  };
}

@Component({
  selector: 'camfil-product-form-dialog-cam-card',
  templateUrl: './camfil-product-form-dialog-cam-card.component.html',
  styleUrls: ['./camfil-product-form-dialog-cam-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CamfilProductFormDialogCamCardComponent implements OnInit {
  @HostBinding('class.camfil-dialog') camfilDialogClass = true;

  camCard: FormControl<string>;
  subCamCard: FormControl<string>;
  search: FormControl<string>;
  subCamCardName: FormControl<string>;
  bucket: FormControl<string>;
  form: FormGroup<{
    camCard: FormControl<string>;
    subCamCard: FormControl<string>;
    subCamCardName: FormControl<string>;
    products: FormArray<
      FormGroup<{
        quantity: FormControl<number>;
        boxLabel: FormControl<string>;
        width: FormControl<number>;
        height: FormControl<number>;
        depth: FormControl<number>;
      }>
    >;
  }>;

  camCards$: Observable<CamCard[]>;
  camCardOrderLabelLabel$: Observable<string>;

  private destroyRef = inject(DestroyRef);

  @ViewChild('subCamCardNameInput') subCamCardNameInput: ElementRef;

  constructor(
    private matDialogRef: MatDialogRef<
      CamfilProductFormDialogCamCardComponentResult,
      CamfilProductFormDialogCamCardComponent
    >,
    // eslint-disable-next-line ish-custom-rules/use-type-safe-injection-token
    @Inject(MAT_DIALOG_DATA) public dialogData: CamfilProductFormDialogCamCardComponentData,
    private roleToggleService: RoleToggleService,
    private camCardsFacade: CamCardsFacade,
    private camfilDialogFacade: CamfilDialogFacade,
    private appFacade: AppFacade
  ) {}

  ngOnInit(): void {
    this.camCard = new FormControl<string>(undefined);
    this.subCamCard = new FormControl<string>(undefined);
    this.subCamCardName = new FormControl<string>('');
    this.subCamCardName.disable();
    this.camCard.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      this.subCamCard.setValue(undefined);
      this.subCamCardName.setValue('');
    });
    this.subCamCard.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(subCamCard => {
      if (subCamCard === 'new') {
        this.subCamCardName.setValidators([
          Validators.required,
          Validators.maxLength(CamfilMaxLengthTypes.CamCardTitle),
        ]);
        this.subCamCardName.enable();
      } else {
        this.subCamCardName.clearValidators();
        this.subCamCardName.disable();
      }
    });
    this.search = new FormControl<string>('');
    this.form = new FormGroup({
      camCard: this.camCard,
      subCamCard: this.subCamCard,
      subCamCardName: this.subCamCardName,
      products: new FormArray(
        this.dialogData.map(
          product =>
            new FormGroup({
              quantity: new FormControl<number>(product.quantity),
              boxLabel: new FormControl<string>('', [Validators.maxLength(CamfilMaxLengthTypes.BoxLabel)]),
              width: new FormControl<number>(undefined),
              height: new FormControl<number>(undefined),
              depth: new FormControl<number>(undefined),
            })
        )
      ),
    });
    this.camCards$ = combineLatest([
      this.camCardsFacade.camCards$,
      this.search.valueChanges.pipe(
        startWith(this.search.value),
        map(search => search.trim().toLocaleLowerCase())
      ),
    ]).pipe(
      map(([camcards, search]) => {
        if (search.length === 0) {
          return camcards;
        }

        function match(search: string) {
          return function self(card: CamCard): boolean {
            const labels = card?.camCardItems?.map(item => item.comment?.label).filter(Boolean) || [];
            const fields = [
              card.name,
              card.orderLabel,
              card.invoiceLabel,
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              ((card as any).customer as any)?.companyName,
              card.deliveryAddress?.addressLine2,
              card.deliveryAddress?.addressLine1,
              card.deliveryAddress?.postalCode,
              card.deliveryAddress?.city,
              ...labels,
            ]
              .filter(Boolean)
              .map(field => field.toLocaleLowerCase());

            const foundInFields = fields.find(field => field.includes(search)) !== undefined;
            if (foundInFields) {
              return true;
            }
            return card.subCamCards?.find(self) !== undefined;
          };
        }

        return camcards.filter(match(search));
      }),
      withLatestFrom(this.appFacade.currentLocale$.pipe(map(l => l.replace('_', '-')))),
      map(([camCards, locale]) =>
        CamCardHelper.getRealCamCards(camCards).sort((l, r) =>
          l.name?.localeCompare(r.name, locale, { caseFirst: 'false' })
        )
      )
    );
    const roles$ = combineLatest([
      this.roleToggleService.hasRole('APP_B2B_REQUEST_QUOTATION'),
      this.roleToggleService.hasRole('APP_B2B_NEEDS_APPROVAL'),
    ]);
    this.camCardOrderLabelLabel$ = roles$.pipe(
      map(mapRoleToLabel('camfil.dynamic.modal.addToCamcard.camcard.order_mark'))
    );
  }

  get productsAddToCartData() {
    const { products } = this.form.getRawValue();
    return this.dialogData.map((product, index): AddToCamCardItem => {
      const { quantity, boxLabel, width, height, depth } = products[index];
      return {
        product: {
          sku: product.sku,
        },
        quantity,
        measurement: {
          width,
          height,
          depth,
        },
        comment: {
          label: boxLabel,
        },
      };
    });
  }

  onSubmit(): void {
    if (this.form.invalid || !this.camCard.value) {
      return;
    }
    const { camCard, subCamCard, subCamCardName } = this.form.value;
    const productsAsToAddToCamCartItems = this.productsAddToCartData;

    this.form.disable();
    if (subCamCard === 'new') {
      this.camCards$.pipe(take(1), takeUntilDestroyed(this.destroyRef)).subscribe(camCards => {
        const selectedCamCard: CamCard = camCards.find(({ id }) => id === camCard);
        const newCC: CamCard = {
          name: subCamCardName,
          deliveryAddress: selectedCamCard.deliveryAddress,
          customer: selectedCamCard.customer,
        };
        this.camCardsFacade.addProductsToNewSubCamCard(newCC, camCard, productsAsToAddToCamCartItems);
      });
    } else if (subCamCard !== undefined) {
      this.camCardsFacade.addProductsToCamCard(subCamCard, productsAsToAddToCamCartItems, camCard);
    } else {
      this.camCardsFacade.addProductsToCamCard(camCard, productsAsToAddToCamCartItems);
    }
    this.matDialogRef.close();
  }

  createNewCamCard(): void {
    this.camfilDialogFacade.open<
      CamCardCreateDialogComponent,
      CamCardCreateDialogComponentData,
      CamCardCreateDialogComponentResult
    >(CamCardCreateDialogComponent, { data: this.productsAddToCartData });
  }
}
