import { Inject, Injectable, Injector, NgZone } from '@angular/core';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { ActiveToast, Overlay, TOAST_CONFIG, ToastrService } from 'ngx-toastr';
import { IndividualConfig } from 'ngx-toastr/toastr/toastr-config';

import { InjectSingle } from 'ish-core/utils/injection';

/* eslint-disable @typescript-eslint/no-explicit-any */

@Injectable({ providedIn: 'root' })
export class CamfilToastrService extends ToastrService {
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'bottom';

  constructor(
    @Inject(TOAST_CONFIG)
    token: InjectSingle<typeof TOAST_CONFIG>,
    overlay: Overlay,
    _injector: Injector,
    sanitizer: DomSanitizer,
    ngZone: NgZone,
    private snackBar: MatSnackBar,
    private translate: TranslateService
  ) {
    super(token, overlay, _injector, sanitizer, ngZone);
  }

  private openSnackBar<ConfigPayload = any>(
    message: string,
    action?: string,
    override?: Partial<IndividualConfig<ConfigPayload>>
  ): ActiveToast<any> {
    const cta = action || this.translate.instant('camfil.modal.cta.button.ok');
    return this.snackBar.open(message, cta, {
      panelClass: override?.toastClass,
      duration: override?.timeOut,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
    }) as unknown as ActiveToast<any>;
  }

  override info<ConfigPayload = any>(
    message?: string,
    action?: string,
    override?: Partial<IndividualConfig<ConfigPayload>>
  ): ActiveToast<any> {
    return this.openSnackBar<ConfigPayload>(message, action, {
      ...override,
      toastClass: 'info-snackbar',
    });
  }

  override error<ConfigPayload = any>(
    message?: string,
    action?: string,
    override?: Partial<IndividualConfig<ConfigPayload>>
  ): ActiveToast<any> {
    return this.openSnackBar<ConfigPayload>(message, action, {
      ...override,
      toastClass: 'error-snackbar',
    });
  }

  override success<ConfigPayload = any>(
    message?: string,
    action?: string,
    override?: Partial<IndividualConfig<ConfigPayload>>
  ): ActiveToast<any> {
    return this.openSnackBar<ConfigPayload>(message, action, {
      ...override,
      toastClass: 'success-snackbar',
    });
  }

  override warning<ConfigPayload = any>(
    message?: string,
    action?: string,
    override?: Partial<IndividualConfig<ConfigPayload>>
  ): ActiveToast<any> {
    return this.openSnackBar<ConfigPayload>(message, action, {
      ...override,
      toastClass: 'warning-snackbar',
    });
  }

  override show<ConfigPayload = any>(
    message?: string,
    action?: string,
    override?: Partial<IndividualConfig<ConfigPayload>>
  ): ActiveToast<any> {
    return this.openSnackBar<ConfigPayload>(message, action, override);
  }

  override clear(_toastId?: number): void {
    this.snackBar.dismiss();
  }

  override remove(_toastId: number): boolean {
    this.snackBar.dismiss();
    return true;
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
}
