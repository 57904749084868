import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { AhuHelper } from 'camfil-models/camfil-ahu/ahu.helper';
import { map } from 'rxjs/operators';

import { ofUrl } from 'ish-core/store/core/router';
import { setBreadcrumbData } from 'ish-core/store/core/viewconf';
import { whenTruthy } from 'ish-core/utils/operators';

import { getAhuBreadcrumb } from './ahu.selectors';

@Injectable()
export class AhuEffects {
  constructor(protected actions$: Actions, protected router: Router, protected store: Store) {}

  setAhuBreadcrumb$ = createEffect(() =>
    this.store.pipe(
      ofUrl(AhuHelper.urlRegExp),
      select(getAhuBreadcrumb),
      whenTruthy(),
      map(breadcrumbData => setBreadcrumbData({ breadcrumbData }))
    )
  );
}
