<div camfilDialogContent>
  <button (click)="hide()" aria-label="Close" class="close">
    <mat-icon aria-hidden="true" svgIcon="inactive" />
  </button>

  <h2 camfilDialogTitle class="text-no-transform">
    {{ 'camfil.account.cam_card.deliveryInterval.checking.dialog.header' | translate }}
  </h2>

  <form [formGroup]="deliveryRemindForm.instance" (ngSubmit)="submitForm()">
    <formly-form
      [form]="deliveryRemindForm.instance"
      [model]="deliveryRemindForm.model$ | async"
      [fields]="deliveryRemindForm.fields$ | async"
    />
  </form>

  <div camfilDialogActions class="text-center mt-4">
    <button
      mat-button
      mat-flat-button
      color="primary"
      [disabled]="!deliveryRemindForm?.instance?.valid"
      type="button"
      (click)="submitForm()"
    >
      {{ 'camfil.modal.edit_order.button.save' | translate }}
    </button>
  </div>
  <br />
</div>
