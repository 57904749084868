import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CamfilLoginOnBehalfQueryParams } from 'camfil-core/identity-provider/camfil-login-on-behalf-identity-provider';
import { CamfilPdfService } from 'camfil-core/services/camfil-pdf/camfil-pdf.service';
import { CamfilProductView } from 'camfil-models/camfil-product/product.model';
import {
  CamfilQuoteLinkData,
  CamfilQuoteLinkStringProps,
} from 'camfil-models/camfil-quotes/camfil-quote-link/camfil-quote-link.interface';
import { CamfilQuoteLinkMapper } from 'camfil-models/camfil-quotes/camfil-quote-link/camfil-quote-link.mapper';
import { CamfilQuoteServiceRequest } from 'camfil-models/camfil-quotes/camfil-quote-service-request/camfil-quote-service-request.model';
import { CamfilQuoteServiceResponse } from 'camfil-models/camfil-quotes/camfil-quote-service-response/camfil-quote-service-response.model';
import { CamfilQuoteData } from 'camfil-models/camfil-quotes/camfil-quote/camfil-quote.interface';
import { CamfilQuoteMapper } from 'camfil-models/camfil-quotes/camfil-quote/camfil-quote.mapper';
import { CamfilQuote } from 'camfil-models/camfil-quotes/camfil-quote/camfil-quote.model';
import { PdfHelper } from 'camfil-shared/cam-pdf/models/pdf.helper';
import { CamfilPdfDefinitions } from 'camfil-shared/cam-pdf/models/pdf.interface';
import { PdfMapper } from 'camfil-shared/cam-pdf/models/pdf.mapper';
import { CamfilPdfLabels, CamfilPdfMetaData } from 'camfil-shared/cam-pdf/models/pdf.model';
import { Observable } from 'rxjs';
import { defaultIfEmpty, map } from 'rxjs/operators';

import { ApiService, unpackEnvelope } from 'ish-core/services/api/api.service';

@Injectable({
  providedIn: 'root',
})
export class CamfilQuotesService {
  private quotesListAttr: CamfilQuoteLinkStringProps[] = [
    'ERPnumber',
    'creationDate',
    'customerDepartment',
    'customerName',
    'customerNumber',
    'customerQuotationNumber',
    'orderChannel',
    'quotationDate',
    'quotationType',
    'requestedBy',
    'status',
  ];

  constructor(
    private apiService: ApiService,
    private camfilPdfService: CamfilPdfService,
    private translate: TranslateService
  ) {}

  getQuotes(): Observable<CamfilQuote[]> {
    const params = {
      attrs: this.quotesListAttr.join(','),
    };
    return this.apiService
      .b2bUserEndpoint()
      .get('camfilquotation', { params: new HttpParams({ fromObject: params }) })
      .pipe(
        unpackEnvelope<CamfilQuoteLinkData>(),
        map(quotes => quotes.map(quoteData => CamfilQuoteLinkMapper.fromData(quoteData))),
        defaultIfEmpty([])
      );
  }

  getQuote(quoteId: string): Observable<CamfilQuote> {
    return this.apiService
      .b2bUserEndpoint()
      .get<CamfilQuoteData>(`camfilquotation/${quoteId}`)
      .pipe(map((data: CamfilQuoteData) => CamfilQuoteMapper.fromData(data)));
  }

  approveQuote(request: CamfilQuoteServiceRequest): Observable<CamfilQuoteServiceResponse> {
    const externalOrderReference = this.externalOrderReference();
    return this.apiService
      .b2bUserEndpoint()
      .post('camfilquotation', { ...request, submitted: true, externalOrderReference });
  }

  rejectQuote(request: CamfilQuoteServiceRequest, rejectionReason: string): Observable<CamfilQuoteServiceResponse> {
    const externalOrderReference = this.externalOrderReference();
    return this.apiService
      .b2bUserEndpoint()
      .post('camfilquotation', { ...request, rejected: true, rejectionReason, externalOrderReference });
  }

  updateQuote(payload: CamfilQuoteData): Observable<CamfilQuote> {
    return this.apiService
      .b2bUserEndpoint()
      .put<{ data: CamfilQuoteData }>(`camfilquotation/${payload.id}`, payload)
      .pipe(map(({ data }) => CamfilQuoteMapper.fromData(data)));
  }

  quoteDataToPdf(quoteData: CamfilQuote, products: CamfilProductView[], showPrices = true): CamfilPdfDefinitions {
    const pdfMeta: CamfilPdfMetaData = { pageNumber: 0, showPrices };
    const pdfLabels: CamfilPdfLabels = {
      ...this.camfilPdfService.pdfLabels,
      ...this.camfilPdfService.addSuffix<Partial<CamfilPdfLabels>>({
        orderMark: this.translate.instant('camfil.quotes.quote_detail.reference'),
      }),
    };
    const pdfInfoData = PdfMapper.fromCamfilQuoteToPdfInfoData(quoteData);
    const pdfHeader = this.camfilPdfService.pdfHeaderContent(pdfMeta);
    const pdfInfoContent = this.camfilPdfService.pdfInfoContent(pdfInfoData, pdfLabels);
    const pdfTableData = PdfMapper.fromCamfilQuoteToPdfTableData(quoteData, products);
    const pdfTotalsData = PdfMapper.fromCamfilQuoteToPdfTotalsData(this.translate, quoteData);
    const pdfTableContent = this.camfilPdfService.pdfTableContent(pdfMeta, pdfTableData);
    const pdfComplexTotals = showPrices ? PdfHelper.pdfComplexTotal(pdfLabels, pdfTotalsData) : '';

    return {
      content: [pdfHeader, pdfInfoContent, pdfTableContent, pdfComplexTotals],
      styles: PdfHelper.pdfStyles(),
      images: PdfHelper.pdfImages(),
      showFooter: true,
    };
  }

  externalOrderReference(): string | undefined {
    return window?.localStorage?.getItem(CamfilLoginOnBehalfQueryParams.ERPEmployeeID) || undefined;
  }
}
