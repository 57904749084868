import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';

import { TrackingEventsEffects } from './store/camfil-tracking-events/camfil-tracking-events.effects';

const camfilTrackingEffects = [TrackingEventsEffects];

@NgModule({
  imports: [EffectsModule.forFeature(camfilTrackingEffects)],
  declarations: [],
  exports: [],
})
export class CamfilTrackingModule {}
