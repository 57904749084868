<ng-container *ngIf="isRaw">
  {{ untranslatedText }}
</ng-container>

<ng-container *ngIf="isToTranslate">
  {{ content?.toString() | translate }}
</ng-container>

<ng-container *ngIf="!isRaw && !isToTranslate">
  <div [innerHTML]="content"></div>
</ng-container>
