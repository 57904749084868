<ng-container>
  <div class="d-flex align-items-center justify-content-center form-header">
    <h2 camfilDialogTitle>{{ 'approval.detailspage.unavailable_products.modal.header' | translate }}</h2>
  </div>
  <div class="form-content__container" camfilDialogContent separated>
    <ng-container *ngIf="dialogData">
      <ng-container *ngIf="dialogData.length">
        <b *ngFor="let product of dialogData; last as isLast">
          {{ product.sku }}<ng-container *ngIf="!isLast">, </ng-container>
        </b>
      </ng-container>
    </ng-container>
  </div>
  <div camfilDialogActions>
    <button mat-raised-button type="button" class="create-order__button" (click)="matDialogRef.close()">
      {{ 'approval.detailspage.unavailable_products.modal.btn.confirm_text' | translate }}
    </button>
  </div>
</ng-container>
