import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CamCardCreate } from 'camfil-models/camfil-cam-card/cam-card-create.interface';
import { CamCardData } from 'camfil-models/camfil-cam-card/cam-card.interface';
import { CamCardMapper } from 'camfil-models/camfil-cam-card/cam-card.mapper';
import {
  AddToCamCardItem,
  BasicCamCard,
  CamCard,
  CamCardImportValidationResponse,
  CamCardItem,
  CamCardItemComment,
  CamCardMeasurement,
} from 'camfil-models/camfil-cam-card/cam-card.model';
import { CamfilContact } from 'camfil-models/camfil-customer/camfil-customer.model';
import { Observable, forkJoin, throwError } from 'rxjs';
import { concatMap, defaultIfEmpty, map, switchMap } from 'rxjs/operators';

import { ApiService, unpackEnvelope } from 'ish-core/services/api/api.service';

@Injectable({ providedIn: 'root' })
export class CamCardService {
  constructor(private apiService: ApiService, private camCardMapper: CamCardMapper) {}

  /**
   * Gets a list of cam cards for the current user.
   *
   * @returns           The customer's cam_cards.
   */
  getCamCards(includeAllCustomerCamCards: boolean): Observable<BasicCamCard[]> {
    return this.getCamCardsBasicList(includeAllCustomerCamCards);
  }

  /**
   * Gets a list of basic cam cards for the current user.
   *
   * @returns           The customer's cam_cards.
   */
  getCamCardsBasicList(includeAllCustomerCamCards = false): Observable<BasicCamCard[]> {
    const params = new HttpParams().set('includeAllCustomerCamCards', String(includeAllCustomerCamCards));

    return this.apiService.get('simplecamcards', { params }).pipe(unpackEnvelope<BasicCamCard>(), defaultIfEmpty([]));
  }

  /**
   * Gets a cam cards of the given id for the current user.
   *
   * @param camCardId  The cam cards id.
   * @returns           The cam_cards.
   */
  getCamCard(camCardId: string): Observable<CamCard> {
    if (!camCardId) {
      return throwError(() => 'getCamCard() called without camCardId');
    }
    return this.apiService
      .get<CamCardData>(`camcards/${camCardId}`)
      .pipe(map(camCardData => this.camCardMapper.fromData(camCardData)));
  }
  /**
   * Creates a cam cards for the current user.
   *
   * @param camCardData
   * @returns                 The created cam_cards.
   */
  createCamCard(camCardData: CamCard): Observable<CamCard> {
    return this.apiService
      .post('camcards', camCardData)
      .pipe(concatMap((response: CamCardCreate) => this.getCamCard(response.itemId)));
  }

  /**
   * Creates a sub cam card
   *
   * @param rootCamCardId     Parent for sub cam card
   * @param camCardData
   * @returns                 The created cam_card.
   */
  createSubCamCard(camCardData: CamCard, rootCamCardId: string): Observable<CamCard> {
    return this.apiService
      .post(`camcards/${rootCamCardId}/childcamcards`, camCardData)
      .pipe(concatMap((response: CamCardCreate) => this.getCamCard(response.itemId)));
  }

  //

  /**
   * Deletes a cam cards of the given id.
   *
   * @param camCardId   The cam cards id.
   * @returns           The cam_card.
   */
  deleteCamCard(camCardId: string): Observable<void> {
    if (!camCardId) {
      return throwError(() => 'deleteCamCard() called without camCardId');
    }
    return this.apiService.delete(`camcards/${camCardId}`);
  }

  /**
   * Clone a cam card of the given id.
   *
   * @param camCardId   The cam cards id.
   * @returns           The cam_card.
   */
  cloneCamCard(camCardId: string): Observable<CamCardData> {
    if (!camCardId) {
      return throwError(() => 'cloneCamCard() called without camCardId');
    }
    return this.apiService.post(`camcards/${camCardId}/clone`);
  }

  /**
   * Copy a cam card of the given id.
   *
   * @param camCardId   The cam cards id.
   * @returns           The cam_card.
   */
  copyCamCard(camCardId: string, name: string): Observable<CamCardData> {
    if (!camCardId) {
      return throwError(() => 'copyCamCard() called without camCardId');
    }
    const data = { name };
    return this.apiService.post(`camcards/${camCardId}/copy`, data);
  }

  /**
   * Updates a cam cards of the given id.
   *
   * @param camCard
   * @returns          The updated cam_card.
   */
  updateCamCard(camCard: CamCard): Observable<CamCard> {
    return this.apiService
      .put(`camcards/${camCard.id}?updateSubCamCards=true&updateLineItems=true`, camCard)
      .pipe(map((response: CamCardData) => this.camCardMapper.fromData(response)));
  }

  updateSubCamCard(sub: CamCard): Observable<CamCard> {
    return this.apiService
      .put(`camcards/${sub.rootCamCard}/childcamcards/${sub.id}`, sub)
      .pipe(concatMap(() => this.getCamCard(sub.rootCamCard)));
  }

  /**
   * Move a cam cards of the given id.
   *
   * @param camCardId   The cam cards to be moved.
   * @param customerId  The new customer ID of cam card to be updated.
   * @returns           The moved cam_card.
   */
  moveCamCard(camCardId: string, customerId: string): Observable<CamCard> {
    const data = { id: customerId };
    return this.apiService.put(`camcards/${camCardId}/move`, data);
  }

  /**
   * Gets a sub cam cards list from cam card.
   *
   * @returns           The sub cam_cards.
   * @param camCardId   The cam card id.
   */
  getSubCamCards(camCardId: string): Observable<CamCard[]> {
    return this.apiService.get(`camcards/${camCardId}/childcamcards`).pipe(
      unpackEnvelope(),
      map((subCamCards: CamCardData[]) => subCamCards.map(subCamCard => this.camCardMapper.fromData(subCamCard))),
      switchMap(obsArray => forkJoin([obsArray])),
      defaultIfEmpty([])
    );
  }

  /**
   * Delete all sub cam cards from cam card.
   *
   * @returns           The cam_card.
   * @param camCardId   The cam card id.
   */
  deleteAllSubCamCards(camCardId: string): Observable<CamCard> {
    if (!camCardId) {
      return throwError(() => 'removeAllSubCamCards() called without camCardId');
    }
    return this.apiService
      .delete(`camcards/${camCardId}/childcamcards`)
      .pipe(concatMap(() => this.getCamCard(camCardId)));
  }

  /**
   * Deletes a sub cam card from cam card.
   *
   * @returns             The cam_cards.
   * @param camCardId     The cam card id.
   * @param subCamCardId  The sub cam card id.
   */
  deleteSubCamCard(rootcamCardId: string, subCamCardId: string): Observable<CamCard> {
    if (!rootcamCardId || !subCamCardId) {
      return throwError(() => 'deleteSubCamCard() called without correct parameters');
    }
    return this.apiService
      .delete(`camcards/${rootcamCardId}/childcamcards/${subCamCardId}`)
      .pipe(concatMap(() => this.getCamCard(rootcamCardId)));
  }

  /**
   * Gets a cam card products of the given id.
   *
   * @returns           The cam_card items.
   * @param camCardId  The cam card id.
   */
  getProductsFromCamCard(camCardId: string): Observable<CamCardItem[]> {
    if (!camCardId) {
      return throwError(() => 'getCamCardProduct() called without camCardId');
    }
    return this.apiService.get<CamCardItem[]>(`camcards/${camCardId}/products`);
  }

  /**
   * Gets a cam card product of the given id.
   *
   * @returns           The cam_card item.
   * @param camCardId  The cam card id.
   * @param camCardItemId  The cam card item id.
   */
  getCamCardProduct(camCardId: string, camCardItemId: string): Observable<CamCardItem> {
    if (!camCardId) {
      return throwError(() => 'getCamCardProduct() called without camCardId');
    }
    if (!camCardItemId) {
      return throwError(() => 'getCamCardProduct() called without camCardItemId');
    }
    return this.apiService.get<CamCardItem>(`camcards/${camCardId}/products/${camCardItemId}`);
  }

  // TODO X move to contact
  /**
   * Update a contacts from the cam card..
   *
   * @param camCardId   The cam card ID.
   * @param camCardContacts   The new contacts for cam card.
   * @returns                 The updated contacts on cam_card.
   */
  updateCamCardContacts(camCardId: string, camCardContacts: CamfilContact[]): Observable<CamfilContact[]> {
    const contacts = { elements: camCardContacts };
    return this.apiService.put(`privatecamcards/${camCardId}/contacts`, contacts);
  }

  /**
   * Adds a product to the cam cards with the given id and reloads the cam_cards.
   *
   * @param camCardId
   * @param sku           The product sku.
   * @param quantity      The products quantity
   * @param comment       Comment label
   * @param measurement   Measurement values
   * @param position      Position on list
   * @returns             The changed cam_cards.
   */
  addProductToCamCard(
    camCardId: string,
    sku: string,
    quantity: number,
    comment?: CamCardItemComment,
    measurement?: CamCardMeasurement,
    position?: number
  ): Observable<CamCard> {
    return this.apiService
      .post(`camcards/${camCardId}/product`, {
        quantity,
        position,
        product: { sku },
        comment,
        measurement,
      })
      .pipe(concatMap(() => this.getCamCard(camCardId)));
  }

  /**
   * Add product to subCamCard and reload root CamCard
   *
   * @param rootCamCardId Parent for sub cam card
   * @param camCardId     SubCamCard id
   * @param sku           The product sku.
   * @param quantity      The products quantity
   * @param boxLabel      Comment label
   * @param measurement   Measurement values
   * @returns             The created cam_card.
   */
  addProductToSubCamCard(
    camCardId: string,
    rootCamCardId: string,
    sku: string,
    quantity: number,
    boxLabel?: string,
    measurement?: CamCardMeasurement
  ): Observable<CamCard> {
    return this.apiService
      .post(`camcards/${rootCamCardId}/childcamcards/${camCardId}/product`, {
        quantity,
        product: { sku },
        comment: {
          label: boxLabel,
        },
        measurement,
      })
      .pipe(concatMap(() => this.getCamCard(rootCamCardId)));
  }

  /**
   * Adds a products to the cam cards with the given id and reloads the cam_cards.
   *
   * @param camCardId
   * @param items           The products list.
   * @param rootId          Parent if sub cam card.
   * @returns             The changed cam_cards.
   */
  addProductsToCamCard(camCardId: string, items: AddToCamCardItem[], rootId?: string): Observable<CamCard> {
    const id = rootId || camCardId;
    return this.apiService
      .post(`camcards/${camCardId}/products`, {
        elements: items,
      })
      .pipe(concatMap(() => this.getCamCard(id)));
  }

  /**
   * Update a product from the cam card with the given id. Returns an error observable if parameters are falsy.
   *
   * @returns             The changed cam card item.
   * @param camCardId
   * @param camCardItem
   */
  updateCamCardProduct(camCardId: string, camCardItem: CamCardItem): Observable<CamCardItem> {
    return this.apiService.put(`camcards/${camCardId}/products/${camCardItem.id}`, camCardItem);
  }

  /**
   * Removes a product from the cam cards with the given id. Returns an error observable if parameters are falsy.
   *
   * @returns             The changed cam_cards.
   * @param camCardId
   * @param camCardItemId
   * @param rootCamCard
   */
  removeProductFromCamCard(camCardId: string, camCardItemId: string, rootCamCard?: string): Observable<CamCard> {
    if (!camCardId) {
      return throwError(() => 'removeProductFromCamCard() called without camCardId');
    }
    if (!camCardItemId) {
      return throwError(() => 'removeProductFromCamCard() called without camCard Item Id');
    }
    return this.apiService
      .delete(`camcards/${camCardId}/products/${camCardItemId}`)
      .pipe(concatMap(() => this.getCamCard(rootCamCard || camCardId)));
  }

  /**
   * Removes all products from the cam cards with the given id. Returns an error observable if parameters are falsy.
   *
   * @returns             The changed cam_cards.
   * @param camCardId
   */
  removeAllProductsFromCamCard(camCardId: string): Observable<CamCard> {
    if (!camCardId) {
      return throwError(() => 'removeAllProductsFromCamCard() called without camCardId');
    }
    return this.apiService.delete(`camcards/${camCardId}/products`).pipe(concatMap(() => this.getCamCard(camCardId)));
  }

  validateCamCardImport(camCardData: {
    camCardData?: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      [fromImportSheetName: string]: any[];
    };
  }): Observable<CamCardImportValidationResponse[]> {
    const camCardArr = Object.entries(camCardData.camCardData)[0][1];
    return this.apiService
      .put('camcardsbulk', camCardArr)
      .pipe(map((response: { data: CamCardImportValidationResponse[] }) => response.data));
  }

  importCamCard(camCardData: { [x: string]: {}; camCardData?: {} }): Observable<CamCard> {
    return this.apiService.post('camcardsbulk', camCardData[Object.keys(camCardData)[0]]);
  }

  /**
   * Updates a cam card attribute with given Id
   *
   * @param camCard
   * @returns          The updated cam_card.
   */
  updateCamCardAttribute(camCardId: string, camCardAttribute: {}): Observable<CamCard> {
    if (!camCardId) {
      return throwError(() => 'updateCamCardAttribute() called without camCardId');
    }
    return this.apiService
      .patch(`camcards/${camCardId}`, camCardAttribute)
      .pipe(map((response: CamCard) => this.camCardMapper.fromUpdate(response, camCardId)));
  }

  /**
   * For checking a baskets for the presence of cam_cards ids
   *
   * @param   camCardsId
   * @returns The cam_cards Ids existing in baskets.
   */
  checkCamCardsInBasketsForAllUsers(camCardsId: string[]): Observable<string[]> {
    return this.apiService.post<string[]>('camcardusage', camCardsId).pipe(map(response => response));
  }
}
