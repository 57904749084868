import { ChangeDetectionStrategy, Component, HostBinding } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'camfil-freightcost-modal',
  templateUrl: './camfil-freightcost-modal.component.html',
  styleUrls: ['./camfil-freightcost-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CamfilFreightcostModalComponent {
  @HostBinding('class.camfil-dialog') camfilDialogClass = true;

  constructor(private matDialogRef: MatDialogRef<void, void>) {}

  close(): void {
    this.matDialogRef.close();
  }
}
