import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostBinding, Inject, OnInit } from '@angular/core';
import { ReactiveFormsModule, UntypedFormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormlyFieldConfig, FormlyModule } from '@ngx-formly/core';
import { CamfilMaterialModule } from 'camfil-core/material/camfil-material.module';
import {
  CamfilDialogTranslatable,
  CamfilTranslatableContentComponent,
} from 'camfil-shared/components/common/camfil-translatable-content/camfil-translatable-content.component';
import { CamfilDialogActionsDirective } from 'camfil-shared/dialog/camfil-dialog-actions.directive';
import { CamfilDialogFormlyDirective } from 'camfil-shared/dialog/camfil-dialog-formly.directive';
import { CamfilDialogIconDirective } from 'camfil-shared/dialog/camfil-dialog-icon.directive';
import { CamfilDialogTitleDirective } from 'camfil-shared/dialog/camfil-dialog-title.directive';

import { CamfilDialogConfirmResult } from '../camfil-dialog-confirm/camfil-dialog-confirm.component';
import {
  CamfilDialogIconName,
  camfilDialogIconNameIsFont,
  camfilDialogIconNameIsSVG,
} from '../camfil-dialog-icon-name.type';

export interface CamfilDialogFormlyData {
  icon?: CamfilDialogIconName;
  title: CamfilDialogTranslatable;
  form: UntypedFormGroup;
  fields: FormlyFieldConfig[];
  dismissButtonLabel: CamfilDialogTranslatable;
  confirmButtonLabel: CamfilDialogTranslatable;
}

export interface CamfilDialogFormlyResult {
  result: CamfilDialogConfirmResult;
  form: UntypedFormGroup;
}

@Component({
  selector: 'camfil-dialog-formly',
  templateUrl: './camfil-dialog-formly.component.html',
  styleUrls: ['./camfil-dialog-formly.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CamfilMaterialModule,
    NgIf,
    CamfilDialogTitleDirective,
    CamfilTranslatableContentComponent,
    CamfilDialogIconDirective,
    CamfilDialogFormlyDirective,
    CamfilDialogActionsDirective,
    ReactiveFormsModule,
    FormlyModule,
  ],
})
export class CamfilDialogFormlyComponent implements OnInit {
  iconNameIsFont = camfilDialogIconNameIsFont;
  iconNameIsSVG = camfilDialogIconNameIsSVG;

  @HostBinding('class.camfil-dialog') camfilDialogClass = true;

  form = new UntypedFormGroup({});
  fields: FormlyFieldConfig[];

  constructor(
    protected matDialogRef: MatDialogRef<CamfilDialogFormlyComponent, CamfilDialogFormlyResult>,
    // eslint-disable-next-line ish-custom-rules/use-type-safe-injection-token
    @Inject(MAT_DIALOG_DATA) protected dialogData: CamfilDialogFormlyData
  ) {}

  ngOnInit(): void {
    this.form = this.dialogData.form;
    this.fields = this.dialogData.fields;
  }

  dismiss() {
    this.matDialogRef.close({ result: CamfilDialogConfirmResult.DISMISSED, form: this.form });
  }

  confirm() {
    this.matDialogRef.close({ result: CamfilDialogConfirmResult.CONFIRMED, form: this.form });
  }
}
