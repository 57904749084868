<div class="form-group" [ngClass]="{ row: inputClass }" [formGroup]="form">
  <label *ngIf="displayLabel" [for]="uuid" class="col-form-label" [ngClass]="labelClass"
    >{{ label | translate }}
  </label>
  <div [ngClass]="inputClass">
    <div class="d-flex flex-row align-items-center">
      <button
        mat-icon-button
        (click)="decrease()"
        [disabled]="cannotDecrease$ | async"
        [attr.data-testing-id]="'decrease-' + controlName"
        [attr.id]="isInLineItem ? 'decrease-' + lineItemId : null"
      >
        <mat-icon svgIcon="minus" />
      </button>
      <input
        type="number"
        class="counter_value"
        [attr.data-testing-id]="controlName"
        [class.mat-elevation-z2]="true"
        [max]="max"
        name="value"
        placeholder="0"
        [formControlName]="getControlName()"
        [attr.id]="isInLineItem ? 'counter_value-' + lineItemId : null"
        (focus)="setFocusedElement($event.target, true)"
        (keydown)="handleInputKeyboardEvent($event)"
        (keyup)="handleInputKeyboardEvent($event)"
      />
      <button
        mat-icon-button
        class="size-10"
        (click)="increase()"
        [attr.data-testing-id]="'increase-' + controlName"
        [attr.id]="isInLineItem ? 'increase-' + lineItemId : null"
        [disabled]="cannotIncrease$ | async"
      >
        <mat-icon svgIcon="plus" />
      </button>
    </div>
    <mat-error class="text-center" *ngIf="formControl.errors?.max"> max: {{ max }} </mat-error>
  </div>
</div>
