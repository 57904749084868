import { EntityState, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import {
  CamfilBasketValidation,
  CamfilBasketValidationResultType,
} from 'camfil-models/camfil-basket-validation/camfil-basket-validation.model';
import { CamfilBasket } from 'camfil-models/camfil-basket/camfil-basket.model';

import { HttpError } from 'ish-core/models/http-error/http-error.model';
import {
  continueCheckoutSuccess,
  continueCheckoutWithIssues,
  createBasketPaymentSuccess,
  deleteBasketAttributeSuccess,
  deleteBasketItemSuccess,
  deleteBasketPaymentSuccess,
  removePromotionCodeFromBasketSuccess,
  setBasketAttributeSuccess,
  setBasketPaymentSuccess,
  submitBasketSuccess,
  updateBasketItemSuccess,
  updateBasketPaymentSuccess,
} from 'ish-core/store/customer/basket';
import { setErrorOn, setLoadingOn, unsetLoadingAndErrorOn } from 'ish-core/utils/ngrx-creators';

import { saveOnlyEmptyBuckets } from '../camfil-edit-basket/camfil-edit-basket.actions';
import { createCamfilOrder } from '../camfil-orders/camfil-orders.actions';
import { createCamfilRequisition } from '../camfil-requisitions/camfil-requisitions.actions';

import {
  cleanUpCompletedCart,
  continueCamfilCheckout,
  continueCamfilCheckoutFail,
  continueCamfilCheckoutSuccess,
  continueCamfilCheckoutWithIssues,
  loadBasketAddressesSuccess,
  loadCamfilBasket,
  loadCamfilBasketSuccess,
  resetCamfilBasketErrors,
  selectCamfilBasket,
  setBasketOrderType,
  setBasketToEditMode,
  showSubmittedInfo,
  startCamfilCheckout,
  startCamfilCheckoutFail,
  startCamfilCheckoutSuccess,
} from './camfil-basket.actions';

const initialCamfilValidationResults: CamfilBasketValidationResultType = {
  valid: undefined,
  adjusted: undefined,
  errors: [],
  infos: [],
};

export const camfilBasketFeatureKey = 'camfilBasket';

export const camfilBasketAdapter = createEntityAdapter<CamfilBasket>({
  selectId: camfilBasket => camfilBasket?.id,
});

export interface CamfilBasketState extends EntityState<CamfilBasket> {
  loading: boolean;
  selected?: string;
  error: HttpError;
  submitedBasket?: CamfilBasket[];
  showSubmitedBasket?: boolean;
}

const initialState: CamfilBasketState = camfilBasketAdapter.getInitialState({
  loading: false,
  selected: undefined,
  error: undefined,
});

export const reducer = createReducer(
  initialState,
  setLoadingOn(loadCamfilBasket, startCamfilCheckout, continueCamfilCheckout),
  unsetLoadingAndErrorOn(
    loadCamfilBasketSuccess,
    startCamfilCheckoutSuccess,
    continueCamfilCheckoutSuccess,
    continueCamfilCheckoutWithIssues
  ),
  setErrorOn(startCamfilCheckoutFail, continueCamfilCheckoutFail),
  on(
    selectCamfilBasket,
    (state: CamfilBasketState, action): CamfilBasketState => ({
      ...state,
      selected: action.payload.basketId,
    })
  ),
  on(loadCamfilBasketSuccess, (state: CamfilBasketState, action) => {
    const { camfilBasket } = action.payload;
    // TODO: Calculated value should come fromm /baskets endpoint?? Ask Pawel Butra

    return camfilBasketAdapter.upsertOne(
      { ...camfilBasket, calculated: camfilBasket.calculated, currentEditedBucketId: undefined, basketAddresses: [] },
      state
    );
  }),
  on(setBasketToEditMode, (state: CamfilBasketState, action) => {
    const { id, camfilBasketId } = action.payload;
    return camfilBasketAdapter.updateOne({ id: camfilBasketId, changes: { currentEditedBucketId: id } }, state);
  }),
  on(loadBasketAddressesSuccess, (state: CamfilBasketState, action): CamfilBasketState => {
    const { camfilBasketId, basketAddresses } = action.payload;

    return camfilBasketAdapter.updateOne({ id: camfilBasketId, changes: { basketAddresses } }, state);
  }),
  on(saveOnlyEmptyBuckets, (state: CamfilBasketState, action) => {
    const { basketId } = action.payload;
    return camfilBasketAdapter.updateOne({ id: basketId, changes: { currentEditedBucketId: undefined } }, state);
  }),
  on(setBasketOrderType, (state: CamfilBasketState, action) => {
    const { id, orderType } = action.payload;
    return camfilBasketAdapter.updateOne({ id, changes: { orderType } }, state);
  }),
  on(continueCamfilCheckoutSuccess, continueCamfilCheckoutWithIssues, (state, action): CamfilBasketState => {
    const validation = action.payload.basketValidation;
    const { basketId } = validation;
    const validationResults = validation?.results;

    return camfilBasketAdapter.updateOne({ id: basketId, changes: { validationResults } }, state);
  }),
  on(
    startCamfilCheckoutSuccess,
    continueCheckoutSuccess,
    continueCheckoutWithIssues,
    (state, action): CamfilBasketState => {
      const validation = action.payload.basketValidation as unknown as CamfilBasketValidation;
      const { basketId } = validation;
      const validationResults = validation?.results;

      return camfilBasketAdapter.updateOne({ id: basketId, changes: { validationResults } }, state);
    }
  ),
  on(
    updateBasketItemSuccess,
    deleteBasketItemSuccess,
    setBasketPaymentSuccess,
    createBasketPaymentSuccess,
    updateBasketPaymentSuccess,
    deleteBasketPaymentSuccess,
    removePromotionCodeFromBasketSuccess,
    setBasketAttributeSuccess,
    deleteBasketAttributeSuccess,
    submitBasketSuccess,
    // eslint-disable-next-line @ngrx/on-function-explicit-return-type
    (state): CamfilBasketState => {
      const basketId = state.selected;
      const validationResults = initialCamfilValidationResults;

      return camfilBasketAdapter.updateOne({ id: basketId, changes: { validationResults } }, state);
    }
  ),
  on(resetCamfilBasketErrors, (state): CamfilBasketState => {
    const basketId = state.selected;
    const validationResults = initialCamfilValidationResults;

    return camfilBasketAdapter.updateOne({ id: basketId, changes: { validationResults } }, state);
  }),
  on(createCamfilOrder, createCamfilRequisition, (state: CamfilBasketState) => ({
    ...state,
    submitedBasket: Object.values(state.entities),
  })),
  on(cleanUpCompletedCart, (state: CamfilBasketState) =>
    camfilBasketAdapter.removeAll({
      ...state,
      submitedBasket: undefined,
      showSubmitedBasket: false,
    })
  ),
  on(
    showSubmittedInfo,
    (state): CamfilBasketState => ({
      ...state,
      showSubmitedBasket: true,
    })
  )
);
