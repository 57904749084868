import { Injectable } from '@angular/core';
import { camelCaseKeys } from 'camfil-core/utils/functions';

import { ManufacturerData } from './manufacturer.interface';
import { Manufacturer } from './manufacturer.model';

@Injectable({ providedIn: 'root' })
export class ManufacturerMapper {
  static fromData(manufacturerData: ManufacturerData): Manufacturer {
    if (!manufacturerData) {
      throw new Error(`manufacturerData is required`);
    }

    const manufacturer = ManufacturerMapper.parseData(manufacturerData);

    if (manufacturer?.id) {
      manufacturer.id = manufacturer.id.toString();
    }

    return manufacturer;
  }

  static fromListData(manufacturersData: ManufacturerData[], sortBy?: keyof ManufacturerData): Manufacturer[] {
    if (!manufacturersData) {
      throw new Error(`manufacturersData is required`);
    }

    let output = manufacturersData;

    if (!Array.isArray(output)) {
      output = [output];
    }

    if (sortBy) {
      output.sort((a, b) => String(a[sortBy])?.localeCompare(String(b[sortBy]), undefined, { sensitivity: 'base' }));
    }

    return output.map(data => ManufacturerMapper.fromData(data));
  }

  private static parseData(manufacturerData: ManufacturerData) {
    return camelCaseKeys(manufacturerData, { deep: true }) as unknown as Manufacturer;
  }
}
