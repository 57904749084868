import { ChangeDetectionStrategy, Component, DestroyRef, HostBinding, OnInit, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { CamfilConfigurationFacade } from 'camfil-core/facades/camfil-configuration.facade';
import { markAsTouchedRecursive } from 'camfil-shared/formly/utils/camfil-form-utils';

export type CamfilRequisitionRejectFormData = { comment: string; confirmed: boolean };

export type CamfilRequisitionRejectFormResult = string;

@Component({
  selector: 'camfil-requisition-reject-form',
  templateUrl: './camfil-requisition-reject-form.component.html',
  styleUrls: ['./camfil-requisition-reject-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CamfilRequisitionRejectFormComponent implements OnInit {
  @HostBinding('class.camfil-dialog') camfilDialogClass = true;

  form = new UntypedFormGroup({});
  fields: FormlyFieldConfig[];

  private rejectReasonMaxLength: number;
  private destroyRef = inject(DestroyRef);

  constructor(
    public matDialogRef: MatDialogRef<string>,
    private camfilConfigurationFacade: CamfilConfigurationFacade
  ) {}

  ngOnInit() {
    this.fields = this.getFields();

    this.camfilConfigurationFacade
      .maxLength$('customerNote')
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((value: number) => {
        this.rejectReasonMaxLength = value;
      });
  }

  private getFields() {
    return [
      {
        fieldGroup: [
          {
            key: 'comment',
            type: 'camfil-textarea-field',
            defaultValue: '',
            props: {
              label: 'camfil.account.requisitions.approvals.rejected.comment',
              rows: 2,
              autosize: true,
              required: true,
            },
            validators: {
              validation: [Validators.maxLength(this.rejectReasonMaxLength)],
            },
            validation: {
              messages: {
                maxlength: 'camfil.checkout.order_header.note.error.maxLength',
                required: 'Please provide reason for rejecting requisition',
              },
            },
          },
        ],
        fieldGroupClassName: 'formly-wrapper',
      },
    ];
  }

  submitForm() {
    if (this.form.invalid) {
      markAsTouchedRecursive(this.form);
      return;
    }

    const data: CamfilRequisitionRejectFormData = { comment: this.form?.get('comment')?.value || '', confirmed: true };
    const dataString = JSON.stringify(data);
    this.matDialogRef.close(dataString);
    this.form.reset();
    this.form.markAsPristine();
  }
}
