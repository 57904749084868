import { Injectable } from '@angular/core';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { map } from 'rxjs';

import { AppFacade } from 'ish-core/facades/app.facade';
import { FieldLibraryConfiguration } from 'ish-shared/formly/field-library/configurations/field-library-configuration';

@Injectable()
export class LastDeliveryDateConfiguration extends FieldLibraryConfiguration {
  constructor(private appFacade: AppFacade) {
    super();
  }

  id = 'lastDeliveryDateFLC';

  getFieldConfig(): FormlyFieldConfig {
    return {
      key: 'lastDeliveryDate',
      type: 'camfil-datepicker-input-field',
      props: {
        label: 'camfil.account.cam_card.add_to_form.last_delivery',
        required: false,
      },
      expressions: {
        'props.datepickerOptions.touchUI': () =>
          this.appFacade.deviceType$.pipe(map(deviceType => deviceType === 'tablet' || deviceType === 'mobile')),
      },
    };
  }
}
