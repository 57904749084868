<input
  *ngIf="type !== 'number'; else numberTmp"
  matInput
  [id]="id"
  [name]="field.name"
  [type]="type || 'text'"
  [readonly]="props.readonly"
  [required]="required"
  [errorStateMatcher]="errorStateMatcher"
  [formControl]="formControl"
  [formlyAttributes]="field"
  [tabIndex]="props.tabindex"
  [placeholder]="props.placeholder"
/>

<ng-template #numberTmp>
  <input
    matInput
    [id]="id"
    [name]="field.name"
    type="number"
    [readonly]="props.readonly"
    [required]="required"
    [errorStateMatcher]="errorStateMatcher"
    [formControl]="formControl"
    [formlyAttributes]="field"
    [tabIndex]="props.tabindex"
    [placeholder]="props.placeholder"
  />
</ng-template>
