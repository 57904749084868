import { createReducer, on } from '@ngrx/store';
import { CamfilZipCodeAddress } from 'camfil-models/camfil-zip-code-address/camfil-zip-code-address.model';

import { HttpError } from 'ish-core/models/http-error/http-error.model';
import { setErrorOn, setLoadingOn, unsetLoadingOn } from 'ish-core/utils/ngrx-creators';

import {
  loadZipCodeAddress,
  loadZipCodeAddressFail,
  loadZipCodeAddressSuccess,
} from './camfil-zip-code-address.actions';

export const camfilZipcodesFeatureKey = 'camfilZipCodes';

export interface CamfilZipCodeAddressState {
  error: HttpError;
  zipCodes: {
    [code: string]: CamfilZipCodeAddress[];
  };
  loading: boolean;
}

export const initialState: CamfilZipCodeAddressState = {
  error: undefined,
  zipCodes: undefined,
  loading: false,
};

export const reducer = createReducer(
  initialState,
  setLoadingOn(loadZipCodeAddress),
  setErrorOn(loadZipCodeAddressFail),
  unsetLoadingOn(loadZipCodeAddressSuccess),
  on(
    loadZipCodeAddressSuccess,
    (state: CamfilZipCodeAddressState, { payload }): CamfilZipCodeAddressState => ({
      ...state,
      zipCodes: {
        ...state.zipCodes,
        [payload.code]: payload.address,
      },
    })
  )
);
