import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { CamfilQueueTaskStatus } from 'camfil-models/camfil-queue/camfil-queue.model';
import { EMPTY, of } from 'rxjs';
import { catchError, concatMap, filter, map, switchMap } from 'rxjs/operators';

import { mapToPayloadProperty } from 'ish-core/utils/operators';

import { completeTask, enqueueTask, failTask, retryTask, startTask } from './camfil-queue.actions';
import { selectAllTasks, selectCurrentProcessingTask } from './camfil-queue.selectors';

@Injectable()
export class CamfilQueueEffects {
  constructor(private actions$: Actions, private store: Store) {}

  taskProcessing$ = createEffect(() =>
    this.actions$.pipe(
      ofType(enqueueTask),
      mapToPayloadProperty('task'),
      concatLatestFrom(() => this.store.pipe(select(selectAllTasks))),
      filter(([task, tasks]) => {
        const tasksByType = tasks.filter(t => t.actionType === task.actionType);
        return (
          tasksByType.filter(
            t =>
              t.status === CamfilQueueTaskStatus.Queued &&
              !tasksByType.some(task => task.status === CamfilQueueTaskStatus.Processing)
          )?.length === 1
        );
      }),
      map(([task]) => startTask({ id: task.id }))
    )
  );

  watchTaskCompletion$ = createEffect(() =>
    this.actions$.pipe(
      ofType(startTask, retryTask),
      switchMap(() =>
        this.store.pipe(
          select(selectCurrentProcessingTask),
          switchMap(task => {
            if (!task) {
              return EMPTY;
            }
            return this.actions$.pipe(
              filter(
                resultAction =>
                  resultAction.type === task.successActionType || resultAction.type === task.failureActionType
              ),
              map(resultAction =>
                resultAction.type === task.successActionType
                  ? completeTask({ id: task.id })
                  : failTask({
                      id: task.id,
                      error: {
                        name: 'HttpErrorResponse',
                        message: '',
                      },
                    })
              ),
              // eslint-disable-next-line rxjs/no-unsafe-catch
              catchError(err => of(failTask({ id: task.id, ...err })))
            );
          })
        )
      )
    )
  );

  continueProcessing$ = createEffect(() =>
    this.actions$.pipe(
      ofType(completeTask, failTask),
      mapToPayloadProperty('id'),
      concatLatestFrom(() => this.store.pipe(select(selectAllTasks))),
      concatMap(([id, tasks]) => {
        const processedTask = tasks.find(task => task.id === id);
        const nextTask = tasks.find(
          task => task.status === CamfilQueueTaskStatus.Queued && task.actionType === processedTask.actionType
        );
        if (nextTask) {
          return of(startTask({ id: nextTask.id }));
        }
        return EMPTY;
      })
    )
  );

  retryTask$ = createEffect(() =>
    this.actions$.pipe(
      ofType(retryTask),
      mapToPayloadProperty('id'),
      map(id => startTask({ id }))
    )
  );
}
