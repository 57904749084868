<form [formGroup]="form" (ngSubmit)="onSubmit(ACTION_TYPE.Default)">
  <ng-container *ngIf="!addToCartOnly; else addToCart">
    <mat-form-field class="quantity" appearance="outline" floatLabel="always">
      <mat-label>{{ 'camfil.cam_card.product.quantity' | translate }}</mat-label>
      <button
        mat-icon-button
        matPrefix
        class="quantity-btn"
        type="button"
        [attr.aria-label]="'product.quantity.decrease.text' | translate"
        (click)="onDecrease()"
        [disabled]="form.disabled"
      >
        <mat-icon svgIcon="minus" />
      </button>
      <input class="quantity-input" matInput formControlName="quantity" type="number" />

      <mat-error *ngIf="quantityControl.errors?.max" class="text-center">
        <ng-container *ngIf="density === 'compact'; else defaultSize"> max: {{ maxQty$ | async }}</ng-container>
        <ng-template #defaultSize>
          {{ 'camfil.modal.addNewProduct.error.max.quantity' | translate : { '0': maxQty$ | async } }}</ng-template
        >
      </mat-error>

      <button
        mat-icon-button
        matSuffix
        class="quantity-btn"
        type="button"
        [attr.aria-label]="'product.quantity.increase.text' | translate"
        (click)="onIncrease()"
        [disabled]="(cantIncrease$ | async) || form.disabled"
      >
        <mat-icon svgIcon="plus" />
      </button>
    </mat-form-field>
    <ng-container *ngIf="!customActions; else customActionsTemplate">
      <camfil-product-add-to-compare [density]="density" class="add-to-compare" />
      <ng-container [ngSwitch]="addToCamCardButtonType$ | async">
        <ng-container *ngSwitchCase="BUTTON_TYPE.Normal">
          <button
            *ngIf="density === 'compact'; else addToCamCardButtonNormalCompactDensity"
            class="add-to-camcard"
            mat-icon-button
            type="button"
            (click)="onSubmit(ACTION_TYPE.AddToCamCard)"
            [disabled]="(addToCamCardButtonDisabled$ | async) || form.invalid"
            [matTooltip]="'camfil.account.cam_card.add_to_template.button.add_to_template.label' | translate"
          >
            <mat-icon svgIcon="add-camcard" />
          </button>
          <ng-template #addToCamCardButtonNormalCompactDensity>
            <button
              class="add-to-camcard"
              mat-stroked-button
              type="button"
              (click)="onSubmit(ACTION_TYPE.AddToCamCard)"
              [disabled]="(addToCamCardButtonDisabled$ | async) || form.invalid"
            >
              <mat-icon svgIcon="add-camcard" />{{
                'camfil.account.cam_card.add_to_template.button.add_to_template.label' | translate
              }}
            </button>
          </ng-template>
        </ng-container>
        <ng-container *ngSwitchCase="BUTTON_TYPE.Fake">
          <a
            *ngIf="density !== 'compact'; else addToCamCardButtonFakeCompactDensity"
            class="add-to-camcard"
            mat-stroked-button
            [disabled]="form.invalid"
            [routerLink]="['/login']"
            [queryParams]="fakeButtonReturnParams"
            ><mat-icon svgIcon="add-camcard" />{{ 'camfil.product.add_to_camcard.not_logged.label' | translate }}</a
          >
          <ng-template #addToCamCardButtonFakeCompactDensity>
            <a
              class="add-to-camcard"
              mat-icon-button
              [disabled]="form.invalid"
              [routerLink]="['/login']"
              [queryParams]="fakeButtonReturnParams"
              [matTooltip]="'camfil.product.add_to_camcard.not_logged.label' | translate"
              ><mat-icon svgIcon="add-camcard"
            /></a>
          </ng-template>
        </ng-container>
      </ng-container>
      <ng-template *ngTemplateOutlet="addToCart"></ng-template>
    </ng-container>
  </ng-container>

  <ng-template #addToCart>
    <ng-container [ngSwitch]="addToCartButtonType$ | async">
      <ng-container *ngSwitchCase="BUTTON_TYPE.Normal">
        <button
          class="add-to-cart"
          mat-flat-button
          color="primary"
          type="button"
          [disabled]="!addToCartOnly && ((addToCartButtonDisabled$ | async) || form.invalid)"
          (click)="onSubmit(ACTION_TYPE.AddToBasket)"
          *ngIf="density !== 'compact'; else addToCardButtonNormalCompactDensity"
        >
          <mat-icon svgIcon="cart" />{{ 'product.add_to_cart.link' | translate }}
        </button>
        <ng-template #addToCardButtonNormalCompactDensity>
          <button
            class="add-to-cart"
            mat-icon-button
            color="primary"
            type="button"
            [disabled]="!addToCartOnly && ((addToCartButtonDisabled$ | async) || form.invalid)"
            (click)="onSubmit(ACTION_TYPE.AddToBasket)"
            [matTooltip]="'product.add_to_cart.link' | translate"
          >
            <mat-icon svgIcon="cart" />
          </button>
        </ng-template>
      </ng-container>
      <ng-container *ngSwitchCase="BUTTON_TYPE.Fake">
        <a
          *ngIf="density !== 'compact'; else addToCardButtonFakeCompactDensity"
          class="add-to-cart"
          mat-flat-button
          color="primary"
          [disabled]="!addToCartOnly && ((addToCamCardButtonDisabled$ | async) || form.invalid)"
          [routerLink]="['/login']"
          [queryParams]="fakeButtonReturnParams"
          ><mat-icon svgIcon="cart" />{{ 'camfil.product.add_to_cart.not_logged.label' | translate }}</a
        >
        <ng-template #addToCardButtonFakeCompactDensity>
          <a
            class="add-to-cart"
            mat-icon-button
            color="primary"
            [disabled]="!addToCartOnly && form.invalid"
            [routerLink]="['/login']"
            [matTooltip]="'camfil.product.add_to_cart.not_logged.label' | translate"
            [queryParams]="fakeButtonReturnParams"
            ><mat-icon svgIcon="cart"
          /></a>
        </ng-template>
      </ng-container>
    </ng-container>
  </ng-template>

  <ng-template
    #customActionsTemplate
    [ngTemplateOutlet]="customActions"
    [ngTemplateOutletContext]="{ quantity: quantityControl.value, valid: quantityControl.valid }"
  ></ng-template>
</form>
