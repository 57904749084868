<ng-container *ngIf="productSKUs$ | async as productSKUs">
  <div *ngIf="productSKUs.length" class="product-list-container">
    <h2 (click)="expand()" (keydown)="expand()" tabindex="0">
      {{ productLinkTitle }}
      <mat-icon class="float-right arrow_down" [class.arrow_down--expanded]="expanded"> arrow_left </mat-icon>
    </h2>
    <div class="product-list d-flex" *ngIf="expanded">
      <div
        *ngFor="let sku of filter(productSKUs)"
        class="product-list-item product-list-item--{{ itemInRow }} list-view"
      >
        <camfil-product-item ishProductContext [sku]="sku" [viewType]="'grid'" />
        <div *ngIf="showCTAButton" class="text-center">
          <camfil-product-form
            ishProductContext
            [sku]="sku"
            [addToCartOnly]="true"
            class="form"
            [initialQuantity]="1"
            [initialMinQuantity]="1"
            [initialMaxQuantity]="1"
          />
        </div>
      </div>
    </div>
  </div>
</ng-container>
