<div class="input-group date-picker-custom-class">
  <input
    [formControl]="formControl"
    [formlyAttributes]="field"
    [minDate]="minDate$ | async"
    [maxDate]="maxDate$ | async"
    [markDisabled]="markDateDisable$ | async"
    outsideDays="collapsed"
    navigation="arrows"
    class="form-control"
    [ngClass]="props.inputClass"
    name="dp"
    ngbDatepicker
    #d="ngbDatepicker"
    data-testing-id="date-picker"
  />

  <div class="input-group-append">
    <div
      class="btn btn-secondary"
      (click)="d.toggle()"
      (keydown.enter)="d.toggle()"
      tabindex="0"
      type="button"
      data-testing-id="btn-date-picker"
    >
      <fa-icon class="text-primary" icon="calendar-day" />
    </div>
  </div>
</div>
