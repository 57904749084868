import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { CamfilMeasurementFilterFacade } from 'camfil-core/facades/camfil-measurement-filter.facade';

import { FilterNavigation } from 'ish-core/models/filter-navigation/filter-navigation.model';
import { URLFormParams } from 'ish-core/utils/url-form-params';

@Component({
  selector: 'camfil-filter-navigation-sidebar',
  templateUrl: './camfil-filter-navigation-sidebar.component.html',
  styleUrls: ['./camfil-filter-navigation-sidebar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CamfilFilterNavigationSidebarComponent {
  constructor(private measurementFilterFacade: CamfilMeasurementFilterFacade) {}

  @Input() filterNavigation: FilterNavigation;
  @Output() applyFilter = new EventEmitter<{ searchParameter: URLFormParams }>();

  get filters() {
    return this.filterNavigation?.filter.filter(filter => this.measurementFilterFacade.hideFilter(filter));
  }

  collapsedElements: { [key: string]: boolean } = {};

  showAllElements: { [key: string]: boolean } = {};
}
