<ng-template [ngIf]="dialogData.icon">
  <ng-template [ngIf]="iconNameIsSVG(dialogData.icon)">
    <mat-icon [svgIcon]="dialogData.icon.svg" color="primary" camfilDialogIcon />
  </ng-template>
  <ng-template [ngIf]="iconNameIsFont(dialogData.icon)">
    <mat-icon [fontIcon]="dialogData.icon.font" color="primary" camfilDialogIcon />
  </ng-template>
</ng-template>

<h2 camfilDialogTitle>
  <camfil-translatable-content [content]="dialogData.title" />
</h2>

<div camfilDialogContent>
  <camfil-loading *ngIf="productIsLoading$ | async" />
  <form [formGroup]="productForm">
    <div>
      <!-- TODO (extMlk): transform below into formly by using CamfilForm -->
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>{{ 'camfil.modal.addNewProduct.input.product_sku' | translate }}</mat-label>
        <input (focus)="isSubmitted = false" formControlName="sku" matInput maxlength="35" />
        <mat-icon *ngIf="productHasValidSku$ | async" matSuffix svgIcon="checkmark" />
        <mat-error *ngIf="productForm.controls.sku.hasError('required')">
          {{ 'camfil.modal.addNewProduct.error.required.sku' | translate }}
        </mat-error>
        <mat-error
          *ngIf="productForm.controls.sku.hasError('validSku')"
          [matTooltip]="'camfil.modal.addNewProduct.error' | translate"
        >
          {{ 'camfil.modal.addNewProduct.error' | translate }}
        </mat-error>
      </mat-form-field>
      <ng-container *ngIf="product$ | async as product">
        <camfil-product-form-dialog-product
          class="product"
          [name]="product.name"
          [sku]="product.sku"
          [control]="productForm"
        />
      </ng-container>
    </div>
    <div class="add-new-product__buttons text-center" camfilDialogActions>
      <button (mousedown)="hide()" class="btn btn-link add-new-product__buttons--link">
        {{ 'camfil.modal.addNewProduct.button.cancel' | translate }}
      </button>
      <button
        (click)="submitForm()"
        [disabled]="(productFormIsDisabled$ | async) || productForm?.invalid"
        color="primary"
        type="submit"
        mat-button
        mat-flat-button
      >
        {{ 'camfil.modal.addNewProduct.button.add_product' | translate }}
      </button>
    </div>
  </form>
</div>
