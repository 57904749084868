import { ChangeDetectionStrategy, Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FieldTypeConfig } from '@ngx-formly/core';
import { FieldType } from '@ngx-formly/material/form-field';
import { CamfilSearchFacade } from 'camfil-core/facades/camfil-search.facade';
import { TypeOfSearchInput } from 'camfil-models/camfil-search/camfil-search.helper';

@Component({
  selector: 'camfil-search-input-field',
  templateUrl: './camfil-search-input-field.component.html',
  styleUrls: ['./camfil-search-input-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
// eslint-disable-next-line ish-custom-rules/require-formly-code-documentation
export class CamfilSearchInputFieldComponent extends FieldType<FieldTypeConfig> implements OnInit {
  constructor(private camfilSearchFacade: CamfilSearchFacade) {
    super();
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @ViewChild('prefix', { static: true }) prefixTemplate!: TemplateRef<unknown>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @ViewChild('suffix', { static: true }) suffixTemplate!: TemplateRef<unknown>;

  get type() {
    return this.props.type || 'text';
  }

  override defaultOptions = {
    props: {
      type: 'search',
      color: 'primary',
      appearance: 'fill',
      label: 'camfil.form.search.label',
      placeholder: 'camfil.form.search.placeholder',
      clearLabel: 'camfil.form.search.button.label.clear',
      button: 'camfil.form.search.button.label.submit',
      showSpinner: false,
      typeSearchInput: TypeOfSearchInput.Other,
    },
  };

  ngOnInit() {
    if (!this.props?.prefix) {
      this.props.prefix = this.prefixTemplate;
    }
    if (!this.props?.suffix) {
      this.props.suffix = this.suffixTemplate;
    }
  }

  clearValue() {
    this.formControl.reset('');
    this.camfilSearchFacade.redirectAfterClearSearchValue(this.props.typeSearchInput);
  }
}
