import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
  inject,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NavigationEnd, Router } from '@angular/router';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { CamfilSearchFacade } from 'camfil-core/facades/camfil-search.facade';
import { TypeOfSearchInput } from 'camfil-models/camfil-search/camfil-search.helper';
import { CAMFIL_SEARCH_VARIABLES } from 'camfil-shared/camfil-constants';
import { CamfilFormClass } from 'camfil-shared/formly/utils/camfil-form';
import { ActiveToast, IndividualConfig, ToastrService } from 'ngx-toastr';
import { Observable, map, of, switchMap, withLatestFrom } from 'rxjs';
import { debounceTime, delay, filter, take } from 'rxjs/operators';

import { DeviceType } from 'ish-core/models/viewtype/viewtype.types';
import { GenerateLazyComponent } from 'ish-core/utils/module-loader/generate-lazy-component.decorator';
import { whenTruthy } from 'ish-core/utils/operators';

interface SearchFormModel {
  searchTerm: string;
}

export const searchPath = '/search';

@Component({
  selector: 'camfil-search',
  templateUrl: './camfil-search.component.html',
  styleUrls: ['./camfil-search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
@GenerateLazyComponent()
export class CamfilSearchComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() device: DeviceType;

  @ViewChild('searchBoxElement') searchBoxElement: ElementRef<unknown>;
  @ViewChild('formElement') formElement: ElementRef<HTMLElement>;
  @ViewChild('overlayTemplate') overlayTemplate: TemplateRef<unknown>;

  private destroyRef = inject(DestroyRef);
  private overlayRef: OverlayRef;
  private activeToast: ActiveToast<unknown>;

  maxVisibleProducts = CAMFIL_SEARCH_VARIABLES.maxVisibleProducts;
  maxVisibleCategories = CAMFIL_SEARCH_VARIABLES.maxVisibleCategories;
  debounceTime = CAMFIL_SEARCH_VARIABLES.debounceTime;

  overlayOpen$ = this.camfilSearchFacade.overlayOpen$;
  productListingId$ = this.camfilSearchFacade.productListingId$;
  productsCount$ = this.camfilSearchFacade.productsCount$.pipe(delay(1));
  categoriesResults$ = this.camfilSearchFacade.categoriesResults$;
  categoriesCount$ = this.camfilSearchFacade.categoriesCount$;
  searchTerm$ = this.camfilSearchFacade.searchTerm$;
  searchTermValid$ = this.camfilSearchFacade.searchTermValid$;
  searchLoading$ = this.camfilSearchFacade.searchLoading$;
  hasProducts$ = this.productsCount$.pipe(map(count => count > 0));
  hasCategories$ = this.categoriesCount$.pipe(map(count => count > 0));

  searchForm: CamfilFormClass<SearchFormModel>;
  searchFormWidth: number;
  searchFormHeight: number;

  constructor(
    private camfilSearchFacade: CamfilSearchFacade,
    private router: Router,
    private translate: TranslateService,
    private overlay: Overlay,
    private viewContainerRef: ViewContainerRef,
    private toastr: ToastrService
  ) {}

  @HostListener('window:resize')
  onResize() {
    this.updateSearchFormDimensions();
  }

  private updateSearchFormDimensions() {
    const formElement = this.formElement.nativeElement;
    this.searchFormWidth = formElement?.clientWidth || 0;
    this.searchFormHeight = formElement?.clientHeight || 0;
  }

  private getSearchFormFields$(): Observable<FormlyFieldConfig[]> {
    return of([
      {
        key: 'searchTerm',
        type: 'camfil-search-input-field',
        props: {
          appearance: 'fill',
          attributes: {
            autocomplete: 'off',
          },
          button: 'camfil.search.searchbox.btn.text',
          hideRequiredMarker: true,
          label: 'camfil.search.searchbox.instructional_text',
          placeholder: '...',
          postWrappers: [{ wrapper: 'camfil-search-input-wrapper', index: -1 }],
          click: () => {
            if (this.searchForm.instance.get('searchTerm').value) {
              this.openOverlay();
            }
          },
          keydown: (_field: FormlyFieldConfig, event: KeyboardEvent) => {
            if (event.key === 'Escape') {
              event.preventDefault();
              this.closeOverlay();
            }
          },
          typeSearchInput: TypeOfSearchInput.Search,
        },
        expressions: {
          'props.showSpinner': this.searchLoading$,
        },
        validation: {
          show: true,
        },
      },
    ]);
  }

  private getSearchFormModel$(): Observable<SearchFormModel> {
    return this.searchTerm$.pipe(map(searchTerm => ({ searchTerm })));
  }

  private createSearchForm() {
    return new CamfilFormClass<SearchFormModel>({
      fields$: this.getSearchFormFields$(),
      model$: this.getSearchFormModel$(),
    });
  }

  private handleOverlayClosingWhenNavigatingAway() {
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        withLatestFrom(this.searchTermValid$),
        filter(([, searchTermValid]) => searchTermValid),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(([event]) => {
        if (event instanceof NavigationEnd && !event?.url?.startsWith('/search')) {
          this.camfilSearchFacade.unsetSearchTerm();
        }

        this.closeOverlay();
      });
  }

  private handleSearchFormInputChanges() {
    this.searchForm
      .valueChanges$()
      .pipe(
        debounceTime(this.debounceTime),
        map(({ searchTerm }) => searchTerm?.trim() || undefined),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(trimmedSearchTerm => {
        if (trimmedSearchTerm) {
          this.camfilSearchFacade.setSearchTerm(trimmedSearchTerm);
        } else {
          this.camfilSearchFacade.unsetSearchTerm();
        }
      });
  }

  private showToast(message: string, title?: string, override?: Partial<IndividualConfig>) {
    if (!this.activeToast) {
      this.activeToast = this.toastr.info(message, title, override);
    }
  }

  hideToast() {
    if (this.activeToast) {
      this.toastr.clear();
      this.activeToast = undefined;
      this.focusFirstOverlayInput();
    }
  }

  private handleOverlayOpening() {
    this.router.events
      .pipe(
        filter<NavigationEnd>(e => e instanceof NavigationEnd),
        take(1),
        switchMap(() =>
          this.searchTermValid$.pipe(
            whenTruthy(),
            filter(() => !this.router.url.startsWith(searchPath) && !!this.searchForm.formRawValue.searchTerm)
          )
        ),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(() => {
        this.openOverlay();
      });
  }

  private handleNoSearchResults() {
    this.hasProducts$
      .pipe(
        debounceTime(5),
        filter(() => this.searchForm.instance.touched),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(hasProducts => {
        const message = this.translate.instant('camfil.search.searchbox.no.results');

        if (hasProducts) {
          this.hideToast();
        } else {
          if (this.searchForm.formRawValue.searchTerm) {
            this.showToast(message);
          }
        }
      });
  }

  private handleEmptySearchTerm() {
    this.searchTerm$
      .pipe(
        filter(searchTerm => searchTerm?.trim() === ''),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(() => {
        this.hideToast();
      });
  }

  private focusFirstOverlayInput() {
    if (this.overlayRef) {
      const overlayContainer = this.overlayRef.overlayElement;
      const inputELement = overlayContainer.querySelector<HTMLInputElement>('input, textarea, select, button');

      if (inputELement) {
        setTimeout(() => inputELement.focus(), 0);
      }
    }
  }

  private handleOverlayBackdropClick() {
    if (this.overlayRef) {
      this.overlayRef
        .backdropClick()
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe(() => {
          this.closeOverlay();
        });
    }
  }

  private syncFormValueWithSearchTerm() {
    this.searchTerm$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(searchTerm => {
      this.searchForm.instance.patchValue(
        { searchTerm },
        {
          onlySelf: true,
          emitEvent: false,
        }
      );
    });
  }

  private openOverlay() {
    if (!this.overlayRef) {
      this.overlayRef = this.overlay.create({
        panelClass: 'camfil-search-overlay',
        hasBackdrop: true,
        scrollStrategy: this.overlayScrollStrategy,
        positionStrategy: this.positionStrategy,
        width: this.overlayWidth,
      });

      const templatePortal = new TemplatePortal(this.overlayTemplate, this.viewContainerRef);
      this.overlayRef.attach(templatePortal);

      this.focusFirstOverlayInput();
      this.handleOverlayBackdropClick();
      this.syncFormValueWithSearchTerm();
      this.camfilSearchFacade.openOverlay();
    }
  }

  private closeOverlay() {
    if (this.overlayRef) {
      this.hideToast();
      this.overlayRef.detach();
      // eslint-disable-next-line unicorn/no-null
      this.overlayRef = null;
      this.camfilSearchFacade.closeOverlay();
    }
  }
  get isMobile() {
    return this.device === 'mobile';
  }

  get overlayOffsetX() {
    return -(this.searchFormWidth / 4);
  }

  get overlayOffsetY() {
    return -this.searchFormHeight;
  }

  get overlayWidth() {
    return this.searchFormWidth + (this.isMobile ? 0 : 2 * Math.abs(this.overlayOffsetX));
  }

  get overlayScrollStrategy() {
    return this.overlay.scrollStrategies.block();
  }

  get positionStrategy() {
    return this.overlay
      .position()
      .flexibleConnectedTo(this.searchBoxElement)
      .withPositions([
        {
          originX: 'start',
          originY: 'bottom',
          overlayX: 'start',
          overlayY: 'top',
          offsetX: this.isMobile ? 0 : this.overlayOffsetX,
          offsetY: this.overlayOffsetY,
        },
        // Add more positions here if needed
      ]);
  }

  get gridForCategories() {
    return this.isMobile ? 1 : this.device === 'tablet' ? 2 : 3;
  }

  ngOnInit(): void {
    this.searchForm = this.createSearchForm();
    this.handleSearchFormInputChanges();
    this.handleOverlayOpening();
    this.handleOverlayClosingWhenNavigatingAway();
    this.handleNoSearchResults();
    this.handleEmptySearchTerm();

    this.overlayOpen$.pipe(whenTruthy(), takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      this.openOverlay();
    });
  }

  ngAfterViewInit() {
    if (!SSR) {
      setTimeout(() => {
        this.updateSearchFormDimensions();
      });
    }
  }

  ngOnDestroy() {
    if (this.overlayRef) {
      this.overlayRef.dispose();
    }
  }

  submitForm() {
    this.searchForm.submitForm(() => {
      const { searchTerm } = this.searchForm.formRawValue;
      if (searchTerm) {
        this.router.navigate([searchPath, searchTerm]);
        this.closeOverlay();
      }
    });
  }
}
