import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import {
  addBasketToNewCamCard,
  addProductToCamCard,
  addProductToEditedCamCard,
  addProductToNewCamCard,
  addProductToNewCamCardAndEdit,
  addProductToNewSubCamCard,
  addProductToSubCamCard,
  addProductsToCamCard,
  addProductsToNewCamCard,
  addProductsToNewSubCamCard,
  addToCartFromCamCards,
  addToNewCamCardWithNewSubCamCard,
  checkCamCardsInBasketsForAllUsers,
  cleanUpCamCardsFeedback,
  clearVirtualCamCard,
  cloneAndEditCamCard,
  copyCamCard,
  createCamCard,
  createEditedSubCamCard,
  createSubCamCard,
  createVirtualCamCard,
  deleteCamCard,
  deleteSubCamCard,
  detectCamCardToolbar,
  importCamCard,
  loadCamCardIfNotLoaded,
  loadCamCardsIfNotLoaded,
  moveCamCard,
  moveProductOnCamCardSuccess,
  refreshCamCards,
  removeItemFromCamCard,
  setEditMode,
  unselectCamCard,
  updateCamCard,
  updateCamCardAttribute,
  updateCamCardContacts,
  updateCamCardProcessing,
  updateCamCardProduct,
  updateEditedCamCard,
  updateSubCamCard,
  validateCamCardImport,
} from 'camfil-core/store/cam-cards/cam-cards.actions';
import {
  getAddProductSuccess,
  getAllCamCards,
  getCamCardAdding,
  getCamCardEditMode,
  getCamCardError,
  getCamCardProcessing,
  getCamCards,
  getCamCardsFeedback,
  getCamCardsInBasketsForAllUsers,
  getCamCardsInBasketsForAllUsersLoading,
  getCamCardsLoading,
  getRootCamCards,
  getSelectedCamCardDetails,
  getValidationErrors,
  getValidationResponse,
  getVirtualCamCard,
  isCamCardsInitialized,
  isStickyCamCardToolbar,
} from 'camfil-core/store/cam-cards/cam-cards.selectors';
import { selectAllTasksByType } from 'camfil-core/store/camfil-queue/camfil-queue.selectors';
import { Attribute } from 'camfil-models/camfil-attribute/attribute.model';
import {
  AddCamCardToCart,
  AddToCamCardItem,
  CamCard,
  CamCardImportValidationResponse,
  CamCardItem,
  CamCardItemComment,
  CamCardMeasurement,
  CamCardToBasketFeedback,
} from 'camfil-models/camfil-cam-card/cam-card.model';
import { CamfilContact } from 'camfil-models/camfil-customer/camfil-customer.model';
import { CamfilQueueTaskStatus } from 'camfil-models/camfil-queue/camfil-queue.model';
import { Observable, map } from 'rxjs';

import { HttpError } from 'ish-core/models/http-error/http-error.model';
import { CookiesService } from 'ish-core/utils/cookies/cookies.service';

@Injectable({ providedIn: 'root' })
export class CamCardsFacade {
  constructor(private store: Store, private cookiesService: CookiesService) {}

  camCardsInitialized$: Observable<boolean> = this.store.pipe(select(isCamCardsInitialized));
  allCamCards$: Observable<CamCard[]> = this.store.pipe(select(getAllCamCards));
  camCards$: Observable<CamCard[]> = this.store.pipe(select(getCamCards));
  rootCamCards$: Observable<CamCard[]> = this.store.pipe(select(getRootCamCards));
  currentCamCard$: Observable<CamCard> = this.store.pipe(select(getSelectedCamCardDetails));
  camCardsLoading$: Observable<boolean> = this.store.pipe(select(getCamCardsLoading));
  camCardProcessing$: Observable<boolean> = this.store.pipe(select(getCamCardProcessing));
  camCardAdding$: Observable<boolean> = this.store.pipe(select(getCamCardAdding));
  camCardError$: Observable<HttpError> = this.store.pipe(select(getCamCardError));
  isStickyCamCardToolbar$: Observable<boolean> = this.store.pipe(select(isStickyCamCardToolbar));
  virtualCamCard$: Observable<CamCard> = this.store.pipe(select(getVirtualCamCard));
  validationErrors$: Observable<HttpError> = this.store.pipe(select(getValidationErrors));
  validationResponse$: Observable<CamCardImportValidationResponse[]> = this.store.pipe(select(getValidationResponse));
  getAddProductSuccess$: Observable<boolean> = this.store.pipe(select(getAddProductSuccess));
  getCamCardEditMode$: Observable<CamCard> = this.store.pipe(select(getCamCardEditMode));
  getCamCardsInBasketsForAllUsers$: Observable<string[]> = this.store.pipe(select(getCamCardsInBasketsForAllUsers));
  getCamCardsFeedback$: Observable<CamCardToBasketFeedback[]> = this.store.pipe(select(getCamCardsFeedback));
  getCamCardsInBasketsForAllUsersLoading$: Observable<boolean> = this.store.pipe(
    select(getCamCardsInBasketsForAllUsersLoading)
  );
  getAllCustomerCamCards$(value: boolean) {
    return this.camCards$.pipe(
      map(camCards => (value ? camCards : camCards.filter(cc => cc.currentUserHasNormalAccessRights)))
    );
  }

  loadCamCardsIfNotLoaded(includeAllCustomerCamCards = false) {
    this.store.dispatch(loadCamCardsIfNotLoaded({ includeAllCustomerCamCards }));
  }

  loadCamCardIfNotLoaded(camCardId: string) {
    this.store.dispatch(loadCamCardIfNotLoaded({ camCardId }));
  }

  refreshCamCards() {
    this.store.dispatch(refreshCamCards());
  }

  copyCamCard(camCardId: string, name: string): void | HttpError {
    this.store.dispatch(copyCamCard({ camCardId, name }));
  }

  moveCamCard(camCardId: string, newCustomerId: string, newContacts: CamfilContact[]): void {
    this.store.dispatch(moveCamCard({ camCardId, newCustomerId, newContacts }));
  }

  addCamCard(camCard: CamCard, newItems?: AddToCamCardItem[]): void | HttpError {
    this.store.dispatch(createCamCard({ camCard, newItems }));
  }

  createSubCamCard(subCamCard: CamCard, rootCamCardId: string) {
    this.store.dispatch(createSubCamCard({ subCamCard, rootCamCardId }));
  }

  createVirtualCamCard(virtualCamCard: CamCard) {
    this.store.dispatch(createVirtualCamCard({ camCard: virtualCamCard }));
  }

  clearVirtualCamCard() {
    this.store.dispatch(clearVirtualCamCard());
  }

  addBasketToNewCamCard(camCard: CamCard): void | HttpError {
    this.store.dispatch(addBasketToNewCamCard({ camCard }));
  }

  deleteCamCard(id: string): void {
    this.store.dispatch(deleteCamCard({ camCardId: id }));
  }

  deleteSubCamCard(rootId: string, id: string): void {
    this.store.dispatch(deleteSubCamCard({ rootId, id }));
  }

  cloneAndEditPermanentCamCard(camCardId: string, camCardName: string) {
    this.store.dispatch(cloneAndEditCamCard({ camCardId, camCardName }));
  }

  updateCamCard(camCard: CamCard): void {
    this.setProcessing(true);
    this.store.dispatch(updateCamCard({ camCard }));
  }

  updateSubCamCard(sub: CamCard): void {
    this.store.dispatch(updateSubCamCard({ sub }));
  }

  addToNewCamCardWithNewSubCamCard(
    newCamCard: CamCard,
    newSubCamCard: CamCard,
    sku: string,
    quantity?: number,
    boxLabel?: string,
    measurement?: CamCardMeasurement,
    edit?: boolean
  ): void {
    this.store.dispatch(
      addToNewCamCardWithNewSubCamCard({ newCamCard, newSubCamCard, sku, quantity, boxLabel, measurement, edit })
    );
  }

  addProductToNewCamCard(name: string, sku: string, quantity?: number): void {
    this.store.dispatch(addProductToNewCamCard({ name, sku, quantity }));
  }

  addProductToCamCard(
    camCardId: string,
    sku: string,
    quantity?: number,
    comment?: CamCardItemComment,
    measurement?: CamCardMeasurement,
    position?: number,
    showSuccessToast?: boolean
  ): void {
    this.store.dispatch(
      addProductToCamCard({ camCardId, sku, quantity, position, comment, measurement, showSuccessToast })
    );
  }

  addProductToSubCamCard(
    camCardId: string,
    refreshCamCardId: string,
    sku: string,
    quantity?: number,
    boxLabel?: string,
    measurement?: CamCardMeasurement
  ): void {
    this.store.dispatch(addProductToSubCamCard({ camCardId, refreshCamCardId, sku, quantity, boxLabel, measurement }));
  }

  addProductToNewSubCamCard(
    subCamCard: CamCard,
    rootCamCard: CamCard,
    sku: string,
    quantity?: number,
    boxLabel?: string,
    measurement?: CamCardMeasurement,
    edit?: boolean
  ): void {
    this.store.dispatch(
      addProductToNewSubCamCard({ subCamCard, rootCamCard, sku, quantity, boxLabel, measurement, edit })
    );
  }

  addProductToNewCamCardAndEdit(
    camCard: CamCard,
    sku: string,
    quantity?: number,
    boxLabel?: string,
    measurement?: CamCardMeasurement,
    edit?: boolean
  ): void {
    this.store.dispatch(addProductToNewCamCardAndEdit({ camCard, sku, quantity, boxLabel, measurement, edit }));
  }

  updateCamCardProduct(
    rootCamCard: string,
    camCardId: string,
    camCardItem: CamCardItem,
    forceUpdateCamCard?: boolean
  ): void {
    this.store.dispatch(updateCamCardProduct({ rootCamCard, camCardId, camCardItem, forceUpdateCamCard }));
  }

  moveProductOnCamCardSuccess(camCard: CamCard, camCardId?: string, rootCamCard?: string): void {
    this.store.dispatch(moveProductOnCamCardSuccess({ camCard, camCardId, rootCamCard }));
  }

  setProcessing(processing: boolean) {
    this.store.dispatch(updateCamCardProcessing({ processing }));
  }

  updateCamCardContacts(camCardId: string, camCardContacts: CamfilContact[]): void {
    this.store.dispatch(updateCamCardContacts({ camCardId, camCardContacts }));
  }

  removeProductFromCamCard(camCardId: string, camCardItemId: string, rootCamCard?: string): void {
    this.store.dispatch(removeItemFromCamCard({ camCardId, camCardItemId, rootCamCard }));
  }

  detectCamCardToolbar() {
    this.store.dispatch(detectCamCardToolbar());
  }

  unSelectCamCard() {
    this.store.dispatch(unselectCamCard());
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  validateCamCardImport(camCardData: any): void | HttpError {
    this.store.dispatch(validateCamCardImport({ camCardData }));
  }

  importCamCard(camCardData: unknown): void | HttpError {
    this.store.dispatch(importCamCard({ camCardData }));
  }

  updateCamCardAttribute(camCardId: string, camCardAttribute: Attribute) {
    this.store.dispatch(updateCamCardAttribute({ camCardId, camCardAttribute }));
  }

  checkCamCardsInBasketsForAllUsers(camCardsId: string[]): void | HttpError {
    this.store.dispatch(checkCamCardsInBasketsForAllUsers({ camCardsId }));
  }

  addProductsToCamCard(
    camCardId: string,
    items: AddToCamCardItem[],
    rootId?: string,
    showSuccessToast?: boolean
  ): void {
    this.store.dispatch(addProductsToCamCard({ camCardId, items, rootId, showSuccessToast }));
  }

  addProductsToNewSubCamCard(
    subCamCard: CamCard,
    rootCamCard: string,
    items: AddToCamCardItem[],
    edit?: boolean
  ): void {
    this.store.dispatch(addProductsToNewSubCamCard({ subCamCard, rootCamCard, items, edit }));
  }

  addProductsToNewCamCard(camCard: CamCard, items: AddToCamCardItem[], edit?: boolean, subCamCard?: CamCard): void {
    this.store.dispatch(addProductsToNewCamCard({ camCard, items, edit, subCamCard }));
  }

  addProductToEditedCamCard(
    camCardId: string,
    sku: string,
    quantity?: number,
    comment?: CamCardItemComment,
    measurement?: CamCardMeasurement
  ): void {
    this.store.dispatch(addProductToEditedCamCard({ camCardId, sku, quantity, comment, measurement }));
  }

  setCamCardEditMode(edit: boolean): void {
    this.store.dispatch(setEditMode({ edit }));
  }

  updateEditedCamCard(camCard: CamCard): void {
    this.store.dispatch(updateEditedCamCard({ camCard }));
  }

  createEditedSubCamCard(subCamCard: CamCard, rootCamCardId: string) {
    this.store.dispatch(createEditedSubCamCard({ subCamCard, rootCamCardId }));
  }

  addToCartFromCamCards(camCards: AddCamCardToCart[]) {
    this.store.dispatch(addToCartFromCamCards({ camCards }));
  }

  setDeliveryIntervalCheckingStatus(status: boolean) {
    if (status) {
      this.cookiesService.put('stopCheckingDeliveryInterval', JSON.stringify(status));
    }
  }

  cleanUpCamCardsFeedback() {
    this.store.dispatch(cleanUpCamCardsFeedback());
  }

  //CamfilQueueTaskStatus
  getQueuedCamCardsIdFromAddToCamCard$(): Observable<string[]> {
    const type = addProductsToCamCard.type;
    const statuses = [CamfilQueueTaskStatus.Queued, CamfilQueueTaskStatus.Processing];
    return this.store.pipe(
      select(selectAllTasksByType(type)),
      map(tasks =>
        tasks
          .filter(({ status }) => statuses.includes(status))
          .map(({ actionPayload }) => actionPayload as { camCardId: string })
      ),
      map(payload => payload.reduce((acc, { camCardId }) => [...new Set([...acc, camCardId])], []))
    );
  }
}
