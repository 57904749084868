<ng-container *ngIf="productHasLeadTime$ | async as leadTime; else noLeadTime">
  <ng-container *ngIf="!onlyNumber; else no">
    <span *ngIf="showText; else onlyIcon">
      <mat-icon svgIcon="delivery" />
      {{ 'camfil.product.leadTime.text' | translate : { '0': leadTime } }}
    </span>
    <ng-template #onlyIcon>
      <mat-icon
        svgIcon="delivery"
        #tooltip="matTooltip"
        (click)="tooltip.show()"
        [matTooltip]="'camfil.product.leadTime.text' | translate : { '0': leadTime }"
      />
    </ng-template>
  </ng-container>
  <ng-template #no>{{ leadTime }}</ng-template>
</ng-container>
<ng-template #noLeadTime>
  <ng-container *ngIf="showZero"> -- </ng-container>
</ng-template>
