import { ChangeDetectionStrategy, Component, DestroyRef, HostBinding, Inject, OnInit, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CamfilCheckoutFacade } from 'camfil-core/facades/camfil-checkout.facade';
import { CamfilConfigurationFacade } from 'camfil-core/facades/camfil-configuration.facade';
import { CamfilDialogFacade } from 'camfil-core/facades/camfil-dialog.facade';
import { BucketExtension } from 'camfil-models/bucket-extension/bucket-extension.model';
import { CamfilAddress } from 'camfil-models/camfil-address/camfil-address.model';
import { Attribute } from 'camfil-models/camfil-attribute/attribute.model';
import { CamfilAddItem } from 'camfil-models/camfil-basket/camfil-basket.model';
import { CamfilBucketHelper } from 'camfil-models/camfil-bucket/camfil-bucket.helper';
import { CamfilBucket } from 'camfil-models/camfil-bucket/camfil-bucket.model';
import { CamfilMaxLengthTypes } from 'camfil-models/camfil-max-length-validator/camfil-max-length.types';
import {
  CamfilOrderFormDialogComponent,
  CamfilOrderFormDialogData,
  CamfilOrderFormDialogResult,
} from 'camfil-shared/components/basket/camfil-order-form-dialog/camfil-order-form-dialog.component';
import { Observable, combineLatest, map } from 'rxjs';

import { RoleToggleService } from 'ish-core/role-toggle.module';

export type CamfilProductFormDialogComponentData = {
  name: string;
  sku: string;
  quantity: number;
}[];

export type CamfilProductFormDialogComponentResult = void;

function mapRoleToLabel(base: string) {
  return ([roleB2BRequestQuotation, roleB2BNeedsApproval]: [boolean, boolean]): string => {
    if (roleB2BRequestQuotation) {
      return `${base}_quote`;
    }

    if (roleB2BNeedsApproval) {
      return `${base}_requisition`;
    }

    return base;
  };
}

@Component({
  selector: 'camfil-product-form-dialog',
  templateUrl: './camfil-product-form-dialog.component.html',
  styleUrls: ['./camfil-product-form-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CamfilProductFormDialogComponent implements OnInit {
  @HostBinding('class.camfil-dialog') camfilDialogClass = true;

  countryCode: string;
  bucket: FormControl<string>;
  form: FormGroup<{
    bucket: FormControl<string>;
    products: FormArray<
      FormGroup<{
        quantity: FormControl<number>;
        boxLabel: FormControl<string>;
        width: FormControl<number>;
        height: FormControl<number>;
        depth: FormControl<number>;
      }>
    >;
  }>;

  buckets$: Observable<CamfilBucket[]>;
  addToExistingOrderLabel$: Observable<string>;
  createNewOrderLabel$: Observable<string>;
  bucketsHeaderLabel$: Observable<string>;
  bucketLabel$: Observable<string>;
  bucketOrderMarkLabel$: Observable<string>;
  showNewOrderBtn$: Observable<boolean>;
  currentBucket$: Observable<CamfilBucket>;

  private destroyRef = inject(DestroyRef);

  constructor(
    private matDialogRef: MatDialogRef<CamfilProductFormDialogComponent, CamfilProductFormDialogComponentResult>,
    private camfilCheckoutFacade: CamfilCheckoutFacade,
    private roleToggleService: RoleToggleService,
    private camfilDialogFacade: CamfilDialogFacade,
    private configFacade: CamfilConfigurationFacade,
    // eslint-disable-next-line ish-custom-rules/use-type-safe-injection-token
    @Inject(MAT_DIALOG_DATA) public dialogData: CamfilProductFormDialogComponentData
  ) {}

  ngOnInit(): void {
    this.bucket = new FormControl<string>(undefined);
    this.form = new FormGroup({
      bucket: this.bucket,
      products: new FormArray(
        this.dialogData.map(
          product =>
            new FormGroup({
              quantity: new FormControl<number>(product.quantity),
              boxLabel: new FormControl<string>('', [Validators.maxLength(CamfilMaxLengthTypes.BoxLabel)]),
              width: new FormControl<number>(undefined),
              height: new FormControl<number>(undefined),
              depth: new FormControl<number>(undefined),
            })
        )
      ),
    });

    this.buckets$ = this.camfilCheckoutFacade.camfilBuckets$;
    this.currentBucket$ = combineLatest([this.buckets$, this.bucket.valueChanges]).pipe(
      map(([buckets, value]) => buckets?.find(u => u.shipToAddress === value))
    );

    const roles$ = combineLatest([
      this.roleToggleService.hasRole('APP_B2B_REQUEST_QUOTATION'),
      this.roleToggleService.hasRole('APP_B2B_NEEDS_APPROVAL'),
    ]);
    this.bucketsHeaderLabel$ = roles$.pipe(map(mapRoleToLabel('camfil.dynamic.modal.addToCart.order-list.title')));
    this.bucketLabel$ = roles$.pipe(map(mapRoleToLabel('camfil.dynamic.checkout.order')));
    this.addToExistingOrderLabel$ = roles$.pipe(
      map(mapRoleToLabel('camfil.modal.addToCart.button.add_to_existing_order'))
    );
    this.createNewOrderLabel$ = roles$.pipe(map(mapRoleToLabel('camfil.modal.addToCart.button.create_new_order')));
    this.bucketOrderMarkLabel$ = roles$.pipe(
      map(mapRoleToLabel('camfil.dynamic.modal.addToCamcard.camcard.order_mark'))
    );

    this.showNewOrderBtn$ = combineLatest([
      this.roleToggleService.hasRole(['APP_B2B_OCI_USER', 'APP_B2B_CXML_USER']),
      this.buckets$,
    ]).pipe(map(([punch, buckets]) => !(punch && !!buckets.length)));

    this.configFacade.countryCode$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(code => (this.countryCode = code));
  }

  private addToCart(bucket?: Partial<CamfilBucket>, address?: CamfilAddress): void {
    const urn = this.bucket.value;
    const form = this.form.getRawValue();
    const products = this.dialogData.map((product, index): CamfilAddItem => {
      const { quantity, boxLabel, width, height, depth } = form.products[index];
      return {
        sku: product.sku,
        quantity,
        lineItemAttributes: [
          ...(boxLabel?.trim().length > 0
            ? // eslint-disable-next-line ish-custom-rules/no-object-literal-type-assertion
              [{ name: 'boxLabel', value: boxLabel, type: 'String' } as Attribute<string>]
            : []),
          // eslint-disable-next-line ish-custom-rules/no-object-literal-type-assertion
          ...(width ? [{ name: 'width', value: width, type: 'Double' } as Attribute<number>] : []),

          // eslint-disable-next-line ish-custom-rules/no-object-literal-type-assertion
          ...(height ? [{ name: 'height', value: height, type: 'Double' } as Attribute<number>] : []),
          // eslint-disable-next-line ish-custom-rules/no-object-literal-type-assertion
          ...(depth ? [{ name: 'depth', value: depth, type: 'Double' } as Attribute<number>] : []),
        ],
        goodsAcceptanceNote: address?.goodsAcceptanceNote || '',
        shipToAddress: urn,
      };
    });
    this.camfilCheckoutFacade.prepareCamfilAddToCart(products, urn, address, bucket);
    this.matDialogRef.close();
  }

  onSubmit(): void {
    if (this.form.invalid) {
      return;
    }
    this.addToCart();
  }

  createNewOrder(): void {
    const dialogRef = this.camfilDialogFacade.open<
      CamfilOrderFormDialogComponent,
      CamfilOrderFormDialogData,
      CamfilOrderFormDialogResult
    >(CamfilOrderFormDialogComponent);

    dialogRef
      .afterClosed()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(result => {
        if (!result) {
          return;
        }
        const parsedResult = CamfilBucketHelper.parseEditFormData(result as string);
        const formData = parsedResult.formData;
        const { selectedCustomer, selectedContact } = parsedResult.additionalOrderData;

        const address: CamfilAddress = {
          companyName1: formData.company,
          addressLine1: formData.addressLine1,
          addressLine2: formData.addressLine2,
          postalCode: formData.zipcode,
          city: formData.city,
          countryCode: this.countryCode,
          goodsAcceptanceNote: formData.goodsAcceptanceNote,
        };

        const bucket: BucketExtension = {
          customer: selectedCustomer,
          contactPerson: selectedContact,
          orderMark: formData.orderMark,
          invoiceLabel: formData.invoiceLabel,
          phoneNumber: formData.phoneNumber,
          info: formData.info,
        };

        this.addToCart(bucket, address);
      });
  }
}
