import { ChangeDetectionStrategy, Component, DestroyRef, Input, OnInit, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { CamfilShoppingFacade } from 'camfil-core/facades/camfil-shopping.facade';
import { Observable, filter, map } from 'rxjs';

import { AppFacade } from 'ish-core/facades/app.facade';
import { ProductContextFacade } from 'ish-core/facades/product-context.facade';
import { ProductLinks, ProductLinksDictionary } from 'ish-core/models/product-links/product-links.model';

/**
 * The Camfil Product Links Component
 *
 * @example
 * <camfil-product-links></camfil-product-links>
 */
@Component({
  selector: 'camfil-product-links',
  templateUrl: './camfil-product-links.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CamfilProductLinksComponent implements OnInit {
  @Input() category: string;
  @Input() showCTAButton = false;
  @Input() showAllButton = true;
  @Input() showForTypes: string[];

  expandedList: boolean;
  links$: Observable<ProductLinksDictionary>;
  fullList: { [id: string]: boolean };
  itemsInRow = 2;

  deviceType$ = this.appFacade.deviceType$;
  private destroyRef = inject(DestroyRef);

  constructor(
    private context: ProductContextFacade,
    private camfilShoppingFacade: CamfilShoppingFacade,
    private appFacade: AppFacade
  ) {}

  ngOnInit() {
    if (this.category) {
      this.links$ = this.camfilShoppingFacade
        .getCategoryLinks$(this.category)
        .pipe(map(links => this.cleanUpLinks(links)));
    } else {
      this.links$ = this.context.select('links');
    }

    this.links$ = this.links$?.pipe(
      map(links =>
        Object.entries(links).reduce((acc, [key, lists]) => ({ ...acc, [key]: this.shuffleList(lists) }), {})
      )
    );

    this.deviceType$
      .pipe(
        filter(type => type === 'desktop'),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(() => {
        this.itemsInRow = 4;
      });
  }

  shuffleList(links: ProductLinks): ProductLinks {
    if (!this.showAllButton) {
      const { products, categories } = links;
      return {
        products: [...products].sort(() => 0.5 - Math.random()),
        categories: [...categories].sort(() => 0.5 - Math.random()),
      };
    }
    return links;
  }

  cleanUpLinks(links: ProductLinksDictionary): ProductLinksDictionary {
    if (this.showForTypes?.length) {
      return this.showForTypes.reduce((acc, type) => (links?.[type] ? { ...acc, [type]: links[type] } : acc), {});
    }
    return links;
  }

  showAllLink(link: ProductLinksDictionary, id: string) {
    return (
      this.showAllButton && this.expandedList && !this.fullList?.[id] && link?.[id].products?.length > this.itemsInRow
    );
  }

  showAllProducts(id: string) {
    this.fullList = {
      ...this.fullList,
      [id]: true,
    };
  }

  detectExpanded(expanded: boolean) {
    this.expandedList = expanded;
  }

  getTitle(type: string) {
    return this.category ? 'product.product_links.category.title' : `product.product_links.${type}.title`;
  }
}
