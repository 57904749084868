<div [ngClass]="{ 'form-group': field.type === 'ish-checkbox-field' }" class="row">
  <div *ngIf="props.title" class="col-form-label" [ngClass]="props.titleClass ? props.titleClass : dprops.titleClass">
    {{ props.title | translate }}<span class="required" *ngIf="props.required && !props.hideRequiredMarker">*</span>
  </div>
  <div
    class="col-form-label"
    [class.has-error]="showError"
    [ngClass]="[
      props.fieldClass ? props.fieldClass : dprops.fieldClass,
      props.title || props.fieldClass ? '' : dprops.noTitleClass
    ]"
  >
    <div class="form-check" [attr.data-testing-id]="keyString + '-wrapper'">
      <ng-template #fieldComponent></ng-template>
      <label
        [attr.for]="id"
        class="form-check-label"
        [ngClass]="props.labelClass ? props.labelClass : dprops.labelClass"
      >
        <span *ngIf="props.label">{{ props.label | translate }}</span>
        <ish-field-tooltip *ngIf="props.tooltip" [tooltipInfo]="props.tooltip" />
      </label>
    </div>
    <ng-container *ngIf="showError" class="invalid-feedback d-block">
      <ish-validation-message [field]="field" class="validation-message" />
    </ng-container>
  </div>
</div>
