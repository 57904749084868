/* eslint-disable @typescript-eslint/no-duplicate-enum-values */
export enum CamfilMaxLengthTypes {
  OrderMark = 60,
  InvoiceLabel = 60,
  Company = 60,
  BoxLabel = 40,
  Name = 40,
  CamCardTitle = 60,
  PhoneNumber = 30,
  GoodsAcceptanceNote = 60,
}
