<ng-container *ngIf="productSKUs$ | async as products">
  <ng-container *ngIf="products.length">
    <ng-container *ngIf="listStyle === 'carousel'; else plainList">
      <div class="product-list">
        <swiper [config]="swiperConfig">
          <ng-template *ngFor="let sku of products" swiperSlide let-data>
            <div [ngClass]="listItemCSSClass">
              <ish-product-item
                *ngIf="lazyFetch(data.isVisible, sku)"
                ishProductContext
                [sku]="sku"
                [configuration]="listItemConfiguration"
                [displayType]="listItemStyle"
              />
            </div>
          </ng-template>
        </swiper>
      </div>
    </ng-container>

    <ng-template #plainList>
      <div class="product-list row">
        <div *ngFor="let sku of products" class="product-list-item" [ngClass]="listItemCSSClass">
          <ish-product-item
            ishProductContext
            [sku]="sku"
            [configuration]="listItemConfiguration"
            [displayType]="listItemStyle"
          />
        </div>
      </div>
    </ng-template>
  </ng-container>
</ng-container>
