import { ChangeDetectionStrategy, Component, DestroyRef, HostBinding, OnDestroy, OnInit, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CamfilAccountFacade } from 'camfil-core/facades/camfil-account.facade';
import { CamCardsFacade } from 'camfil-core/facades/camfil-cam-cards.facade';
import { CamfilCheckoutFacade } from 'camfil-core/facades/camfil-checkout.facade';
import { CamfilCreateNewCamcardMapper } from 'camfil-pages/camfil-checkout-onestep/camfil-checkout-toolbar/camfil-create-new-camcard/camfil-create-new-camcard.mapper';
import { BehaviorSubject, Observable, map, mergeMap, skip, take } from 'rxjs';

@Component({
  selector: 'camfil-order-feedback-dialog',
  templateUrl: './camfil-order-feedback-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CamfilOrderFeedbackDialogComponent implements OnInit, OnDestroy {
  @HostBinding('class.camfil-dialog') camfilDialogClass = true;

  private destroyRef = inject(DestroyRef);

  lastRequisitionId$: Observable<string>;
  suggestCamCardCreation$ = new BehaviorSubject(false);
  ordersLoading$ = this.camfilCheckoutFacade.ordersLoading$;
  suggestCamCardCreationAfterOrderSubmission$ = this.camfilCheckoutFacade.suggestCamCardCreationAfterOrderSubmission$;
  numberOfBucket$ = this.camfilCheckoutFacade.getSubmitedBuckets$.pipe(map(b => b.length));
  isQuote$ = this.camfilCheckoutFacade.getSubmitedBasket$.pipe(map(baskets => baskets[0]?.orderType === 'RFQ'));

  constructor(
    private camfilAccountFacade: CamfilAccountFacade,
    private router: Router,
    private camfilCheckoutFacade: CamfilCheckoutFacade,
    private camCardsFacade: CamCardsFacade,
    private matDialogRef: MatDialogRef<void, CamfilOrderFeedbackDialogComponent>
  ) {}

  closeSuggestCamCardCreation() {
    this.suggestCamCardCreation$.next(false);
    this.close();
  }

  ngOnInit() {
    this.camfilCheckoutFacade.suggestCamCardCreationAfterOrderSubmission$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(suggest => {
        this.suggestCamCardCreation$.next(suggest);
      });

    this.lastRequisitionId$ = this.camfilAccountFacade.requisitions$('buyer').pipe(
      skip(1),
      map(requisitions =>
        requisitions?.find(
          req =>
            `${req.creationDate}` ===
            `${Math.max(...requisitions.map(o => o.creationDate).filter(value => !Number.isNaN(value)))}`
        )
      ),
      map(req => req?.id),
      takeUntilDestroyed(this.destroyRef)
    );
  }

  ngOnDestroy() {
    this.camfilCheckoutFacade.cleanUpCompletedCart();
  }

  close(): void {
    this.matDialogRef.close();
  }

  createCamCardsFromBuckets() {
    this.camfilCheckoutFacade.bucketsNotCreatedFromCamCard$
      .pipe(
        mergeMap(buckets => buckets),
        map(bucket => CamfilCreateNewCamcardMapper.mapBucketToCamCard(bucket)),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(camCard => this.camCardsFacade.addBasketToNewCamCard(camCard));

    this.closeSuggestCamCardCreation();
    this.close();
  }

  openRequisitionPage(requisitionId: string) {
    // eslint-disable-next-line rxjs-angular/prefer-takeuntil
    this.numberOfBucket$.pipe(take(1)).subscribe(no => {
      this.router.navigateByUrl(`account/requisitions/buyer${no > 1 ? '' : `/${requisitionId}`}`);
    });
  }
}
