import { overrides } from './environment.development';
import { ENVIRONMENT_DEFAULTS, Environment } from './environment.model';

export const environment: Environment = {
  ...ENVIRONMENT_DEFAULTS,

  icmBaseURL: 'https://api.shop.camfil.com',

  icmChannel: 'Camfil-CamfilSE-Site',

  multiSiteLocaleMap: {},

  themeColor: '#00673E',

  fallbackLocales: ['en_GB', 'de_DE', 'fr_FR', 'sv_SE', 'fi_FI', 'it_IT', 'da_DK', 'nb_NO', 'nl_NL'],

  localeCurrencyOverride: {},

  features: [
    'businessCustomerRegistration',
    'compare',
    'contactUs',
    'punchout',
    'quoting',
    'sentry',
    'tracking',
    // 'saveLanguageSelection', // We need to disable it is in conflict with CamfilLoginOnBehalf
  ],

  identityProviders: {
    ICM: {
      type: 'ICM',
    },
    Punchout: {
      type: 'PUNCHOUT',
    },
    CamfilLoginOnBehalf: {
      type: 'CAMFIL_LOGIN_ON_BEHALF',
    },
  },

  identityProvider: 'ICM',

  gtmToken: 'GTM-T5MGCK9',

  sentryDSN: 'https://fab71a992cb946c784af873ded085039@o420418.ingest.sentry.io/5338583',

  ...overrides,
};
