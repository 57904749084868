import { Injectable } from '@angular/core';
import { CamfilBasketData } from 'camfil-models/camfil-basket/camfil-basket.interface';
import { STANDARD_SHIPPING_METHOD } from 'camfil-models/camfil-bucket/camfil-bucket.constants';

import { CamfilAddItem, CamfilBasket } from './camfil-basket.model';

@Injectable({ providedIn: 'root' })
export class CamfilBasketMapper {
  static fromCamfilBasketUpdateData(payload: CamfilBasketData): CamfilBasket {
    const { data } = payload;
    if (data) {
      return {
        id: data.id,
        calculated: data.calculated,
        currentEditedBucketId: undefined,
      };
    } else {
      throw new Error(`camfilBasketUpdateData is required`);
    }
  }

  // eslint-disable-next-line no-unused-vars
  static getCamfilAddToCartItem(items: CamfilAddItem[], _calculated: boolean) {
    return items.map(item => {
      const attributes = item.lineItemAttributes || [];
      return {
        product: item.sku,
        quantity: {
          value: item.quantity,
          unit: item.unit,
        },
        shipToAddress: item.shipToAddress,
        shippingMethod: STANDARD_SHIPPING_METHOD,
        attributes,
        // TODO: TMP solution, as long as everything about addToCart is pending, calculated should be as TRUE
        calculated: true, // !!calculated,
      };
    });
  }
}
