import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { CamfilBasketService } from 'camfil-core/services/camfil-basket/camfil-basket.service';
import { filter, map, mergeMap } from 'rxjs';

import { loadBasketFail } from 'ish-core/store/customer/basket';
import { getUserAuthorized } from 'ish-core/store/customer/user';
import { mapErrorToAction } from 'ish-core/utils/operators';

import { getWarehouseCalendar, getWarehouseCalendarSuccess } from './camfil-calendar-exceptions.actions';
import { getCamfilCalendarExceptions } from './camfil-calendar-exceptions.selectors';

@Injectable()
export class CamfilCalendarExceptionsEffects {
  constructor(private actions$: Actions, private camfilBasketService: CamfilBasketService, private store: Store) {}

  getWarehouseCalendar$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getWarehouseCalendar),
      concatLatestFrom(() => [
        this.store.pipe(select(getCamfilCalendarExceptions)),
        this.store.pipe(select(getUserAuthorized)),
      ]),
      filter(([, dates, isLoggedIn]) => !dates && isLoggedIn),
      mergeMap(() =>
        this.camfilBasketService.getWarehouseCalendar().pipe(
          map((dates: []) => getWarehouseCalendarSuccess({ dates })),
          mapErrorToAction(loadBasketFail)
        )
      )
    )
  );
}
