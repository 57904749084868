import { ChangeDetectionStrategy, Component, HostBinding, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { CamCardsFacade } from 'camfil-core/facades/camfil-cam-cards.facade';
import { CamfilFormClass } from 'camfil-shared/formly/utils/camfil-form';
import { of } from 'rxjs';

type CamfilDeliveryRemindFormModel = {
  deliveryRemind: number;
};

@Component({
  selector: 'camfil-cam-card-delivery-interval-checking-dialog',
  templateUrl: './cam-card-delivery-interval-checking-dialog.component.html',
  styleUrls: ['./cam-card-delivery-interval-checking-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CamCardDeliveryIntervalCheckingDialogComponent implements OnInit {
  constructor(
    private camCardsFacade: CamCardsFacade,
    private matDialogRef: MatDialogRef<CamCardDeliveryIntervalCheckingDialogComponent, boolean>
  ) {}

  @HostBinding('class.camfil-dialog') camfilDialogClass = true;

  private focus: boolean;
  private stopRemind: boolean;

  deliveryRemindForm: CamfilFormClass<CamfilDeliveryRemindFormModel>;

  private getDeliveryRemindFormFields$() {
    return of([
      {
        key: 'deliveryRemind',
        type: 'radio',
        className: 'delivery-interval-checking-radio-group',
        templateOptions: {
          options: [
            { value: 1, label: 'camfil.account.cam_card.deliveryInterval.checking.dialog.yes' },
            { value: 2, label: 'camfil.account.cam_card.deliveryInterval.checking.dialog.no' },
            {
              value: 3,
              label: 'camfil.account.cam_card.deliveryInterval.checking.dialog.no_at_all',
            },
          ],
          required: true,
        },
      },
    ]);
  }

  private getDeliveryRemindFormModel$() {
    return of({
      deliveryRemind: undefined,
    });
  }

  private createDeliveryRemindForm() {
    return new CamfilFormClass<CamfilDeliveryRemindFormModel>({
      fields$: this.getDeliveryRemindFormFields$(),
      model$: this.getDeliveryRemindFormModel$(),
    });
  }

  private readResult() {
    const value = this.deliveryRemindForm?.instance?.get('deliveryRemind')?.value;
    this.focus = value === 1;
    this.stopRemind = value === 3;
  }

  ngOnInit(): void {
    this.deliveryRemindForm = this.createDeliveryRemindForm();
  }

  submitForm() {
    if (this.deliveryRemindForm?.instance?.valid) {
      this.readResult();
      this.camCardsFacade.setDeliveryIntervalCheckingStatus(this.stopRemind);
      this.matDialogRef.close(this.focus);
    }
  }

  hide() {
    this.matDialogRef.close();
  }
}
