import {
  CamfilQuote,
  CamfilQuoteOrderChannel,
  CamfilQuoteStatus,
  CamfilQuoteType,
} from 'camfil-models/camfil-quotes/camfil-quote/camfil-quote.model';

import { CamfilQuoteLinkData, CamfilQuoteLinkProps, CamfilQuoteLinkStringProps } from './camfil-quote-link.interface';

export class CamfilQuoteLinkMapper {
  static fromData(quoteData: CamfilQuoteLinkData): CamfilQuote {
    if (quoteData) {
      return {
        id: quoteData.title,
        quotationType: CamfilQuoteLinkMapper.getPropFromData(
          quoteData,
          'quotationType'
        )?.toLowerCase() as CamfilQuoteType,
        customerName: CamfilQuoteLinkMapper.getPropFromData(quoteData, 'customerName'),
        customerNo: CamfilQuoteLinkMapper.getPropFromData(quoteData, 'customerNumber'),
        customerDepartment: CamfilQuoteLinkMapper.getPropFromData(quoteData, 'customerDepartment'),
        ERPnumber: CamfilQuoteLinkMapper.getPropFromData(quoteData, 'ERPnumber'),
        customerQuoteNumber: CamfilQuoteLinkMapper.getPropFromData(quoteData, 'customerQuotationNumber'),
        requestedBy: CamfilQuoteLinkMapper.getPropFromData(quoteData, 'requestedBy'),
        requestedDate: CamfilQuoteLinkMapper.getPropFromData(quoteData, 'creationDate'),
        quotationDate: CamfilQuoteLinkMapper.getPropFromData(quoteData, 'quotationDate'),
        status: CamfilQuoteLinkMapper.getPropFromData(quoteData, 'status') as unknown as CamfilQuoteStatus,
        orderChannel: CamfilQuoteLinkMapper.getPropFromData(quoteData, 'orderChannel'),
      };
    } else {
      throw new Error(`quoteData is required`);
    }
  }

  static getPropFromData(data: CamfilQuoteLinkData, prop: 'orderChannel'): CamfilQuoteOrderChannel | undefined;
  static getPropFromData(data: CamfilQuoteLinkData, prop: 'quotationType'): CamfilQuoteType | undefined;
  static getPropFromData(data: CamfilQuoteLinkData, prop: CamfilQuoteLinkStringProps): string | undefined;
  static getPropFromData(data: CamfilQuoteLinkData, prop: CamfilQuoteLinkProps): string | number | undefined {
    const attr = data?.attributes?.find(attribute => attribute.name === prop);
    return attr?.value;
  }
}
