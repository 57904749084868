<ng-container>
  <div class="d-flex align-items-center justify-content-center form-header">
    <h2 camfilDialogTitle>Please type in a reason in order to reject</h2>
  </div>
  <div class="form-content__container w-100" camfilDialogContent separated>
    <form [formGroup]="form" (ngSubmit)="submitForm()">
      <formly-form [form]="form" [fields]="fields" />
    </form>
  </div>
  <div camfilDialogActions>
    <button
      mat-raised-button
      type="button"
      class="secondary-button create-order__button"
      (click)="matDialogRef.close()"
    >
      {{ 'approval.rejectform.button.cancel.label' | translate }}
    </button>
    <button
      mat-raised-button
      color="primary"
      type="button"
      class="create-order__button"
      (click)="submitForm()"
      [disabled]="form.invalid || form.disabled"
    >
      {{ 'approval.rejectform.button.reject.label' | translate }}
    </button>
  </div>
</ng-container>
