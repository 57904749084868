import { CamfilZipCodeAddressData } from './camfil-zip-code-address.interface';
import { CamfilZipCodeAddress } from './camfil-zip-code-address.model';

export class CamfilZipCodeAddressMapper {
  static fromData(data: CamfilZipCodeAddressData[]): CamfilZipCodeAddress[] {
    if (!data.length) {
      return [{}] as CamfilZipCodeAddress[];
    }

    return data.map(item => ({
      id: item.cityAlias || item.cityId,
      city: item.cityId,
      country: item.countryRegionId,
      zipCode: item.zipCode,
      language: item.language,
    }));
  }
}
