import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { ProductCompletenessLevel } from 'camfil-models/camfil-product/product.helper';
import { Observable, combineLatest, map, of } from 'rxjs';

import { ShoppingFacade } from 'ish-core/facades/shopping.facade';
import { ProductLinks } from 'ish-core/models/product-links/product-links.model';
import { CookiesService } from 'ish-core/utils/cookies/cookies.service';

/**
 * The Camfil Product Link List Component
 *
 * @example
 * <camfil-product-links-list [links]="links.upselling" [productLinkTitle]="'product.product_links.upselling.title' | translate"></camfil-product-links-list>
 */
@Component({
  selector: 'camfil-product-links-list',
  templateUrl: './camfil-product-links-list.component.html',
  styleUrls: ['./camfil-product-links-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CamfilProductLinksListComponent implements OnInit, OnChanges {
  @Input() links: ProductLinks;
  @Input() productLinkTitle: string;
  @Input() displayOnlyAvailableProducts = false;
  @Input() showAll: boolean;
  @Input() showCTAButton = false;
  @Input() itemInRow: number;

  @Output() emitExpanded = new EventEmitter<boolean>();

  constructor(private shoppingFacade: ShoppingFacade, private cookiesService: CookiesService) {}

  productSKUs$: Observable<string[]>;
  expanded = true;

  expand() {
    this.expanded = !this.expanded;
    const { pathname } = window.location;
    const fresh = this.expanded
      ? this.linksCollapsedCookies.filter(l => l !== pathname)
      : [...new Set([...this.linksCollapsedCookies, pathname])];

    this.cookiesService.put('linksCollapsed', JSON.stringify(fresh));
    this.emitExpanded.emit(this.expanded);
  }

  get linksCollapsedCookies() {
    return (JSON.parse(this.cookiesService.get('linksCollapsed') || 'null') as string[]) || [];
  }

  ngOnInit() {
    if (!SSR && this.linksCollapsedCookies?.includes(window.location.pathname)) {
      this.expanded = false;
    }
    this.emitExpanded.emit(this.expanded);
  }

  ngOnChanges() {
    this.productSKUs$ = this.displayOnlyAvailableProducts
      ? combineLatest(
          this.links.products.map(sku => this.shoppingFacade.product$(sku, ProductCompletenessLevel.List))
        ).pipe(map(products => products.filter(p => p.available).map(p => p.sku)))
      : of(this.links.products);
  }

  filter(skus: string[]) {
    return (this.showAll && skus) || skus.slice(0, this.itemInRow);
  }
}
