import { ChangeDetectionStrategy, Component, DestroyRef, Input, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { CamfilCheckoutFacade } from 'camfil-core/facades/camfil-checkout.facade';
import { CamfilDialogFacade } from 'camfil-core/facades/camfil-dialog.facade';
import { DELETED_LOCALLY_ATTR_NAME } from 'camfil-models/camfil-bucket/camfil-bucket.constants';
import { CamfilBucketHelper } from 'camfil-models/camfil-bucket/camfil-bucket.helper';
import { CamfilBucket } from 'camfil-models/camfil-bucket/camfil-bucket.model';
import { formatISO } from 'date-fns';
import { take } from 'rxjs';

import {
  CamfilBulkDeliveryDateConfirmModalComponent,
  CamfilBulkDeliveryDateConfirmModalData,
  CamfilBulkDeliveryDateConfirmModalResult,
} from './camfil-bulk-delivery-date-confirm-modal/camfil-bulk-delivery-date-confirm-modal.component';
import {
  CamfilBulkDeliveryDateModalComponent,
  CamfilBulkDeliveryDateModalData,
  CamfilBulkDeliveryDateModalResult,
} from './camfil-bulk-delivery-date-modal/camfil-bulk-delivery-date-modal.component';

@Component({
  selector: 'camfil-bulk-delivery-date',
  styleUrls: ['./camfil-bulk-delivery-date.component.scss'],
  templateUrl: './camfil-bulk-delivery-date.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CamfilBulkDeliveryDateComponent {
  @Input() orders: CamfilBucket[];
  @Input() visibility: boolean;
  private destroyRef = inject(DestroyRef);
  constructor(private camfilDialogFacade: CamfilDialogFacade, private camfilCheckoutFacade: CamfilCheckoutFacade) {}

  openModal() {
    if (this.visibility) {
      const dialogRef = this.camfilDialogFacade.open<
        CamfilBulkDeliveryDateModalComponent,
        CamfilBulkDeliveryDateModalData,
        CamfilBulkDeliveryDateModalResult
      >(CamfilBulkDeliveryDateModalComponent, {
        data: this.orders,
      });

      dialogRef
        .afterClosed()
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe(result => {
          if (result) {
            this.updateDeliveryDate(result);
          }
        });
    }
  }

  updateDeliveryDate(date: Date) {
    if (date) {
      this.camfilCheckoutFacade.camfilBasket$.pipe(take(1), takeUntilDestroyed(this.destroyRef)).subscribe(basket => {
        const changedBuckets: CamfilBucket[] = this.orders.map(bucket => {
          const deliveryDateISO = formatISO(date, { representation: 'date' });
          return {
            ...bucket,
            deliveryDate: deliveryDateISO,
          };
        });

        const { joint, partial } = this.showPartialOrJointInfo(this.orders);
        const newDates = this.showPartialOrJointInfo(changedBuckets);

        const showModal =
          joint.length !== newDates.joint.length ||
          partial.length !== newDates.partial.length ||
          joint.some(({ orderMark }, i) => newDates.joint[i]?.orderMark !== orderMark) ||
          partial.some(({ orderMark }, i) => newDates.partial[i]?.orderMark !== orderMark);

        if (showModal) {
          this.camfilDialogFacade.open<
            CamfilBulkDeliveryDateConfirmModalComponent,
            CamfilBulkDeliveryDateConfirmModalData,
            CamfilBulkDeliveryDateConfirmModalResult
          >(CamfilBulkDeliveryDateConfirmModalComponent, {
            data: newDates,
          });
        }
        this.camfilCheckoutFacade.updateBuckets(changedBuckets, basket);
      });
    }
  }

  showPartialOrJointInfo(buckets: CamfilBucket[]) {
    return buckets.reduce(
      (acc, b) => {
        const uniques = CamfilBucketHelper.getUniqueDeliveryDates(b);
        const last = uniques?.[uniques.length - 1];
        const isFull = CamfilBucketHelper.isFullDelivery(last, b);
        const min2Items =
          b?.lineItems?.filter(item => !item.attributes.find(a => a.name === DELETED_LOCALLY_ATTR_NAME))?.length > 1;
        if (min2Items) {
          const { orderMark, position } = b;
          const container = isFull ? acc.joint : acc.partial;
          container.push({ orderMark, position });
        }
        return acc;
      },
      {
        partial: [],
        joint: [],
      }
    );
  }
}
