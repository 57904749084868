<ng-container>
  <div class="d-flex flex-column align-items-center justify-content-center p-4">
    <mat-icon [svgIcon]="'delete'" />
    <h2 camfilDialogTitle>{{ 'camfil.checkout.order.delete_order.modal.subheading' | translate }}</h2>
  </div>

  <div *ngIf="ordersToDelete?.length > 1" camfilDialogContent separated>
    <camfil-content-by-role>
      <div class="role-default">{{ 'camfil.checkout.order.delete_order.modal.subheadings' | translate }}</div>
    </camfil-content-by-role>
    {{ 'camfil.checkout.order.delete_order.modal.subheadings_description' | translate }}
    <ng-container *ngFor="let order of ordersToDelete; index as i">
      <div>
        {{ 'camfil.dynamic.checkout.order' | translate }} {{ i + 1 }} -
        {{ order.orderMark }}
      </div>
    </ng-container>
  </div>

  <div camfilDialogActions>
    <button mat-raised-button type="button" class="secondary-button" (click)="matDialogRef.close()">
      {{ 'camfil.checkout.order.delete_order.modal.no' | translate }}
    </button>
    <button
      mat-raised-button
      color="primary"
      type="button"
      class="create-order__button"
      (click)="confirmOrderRemoval()"
    >
      {{ 'camfil.checkout.order.delete_order.modal.yes' | translate }}
    </button>
  </div>
</ng-container>
