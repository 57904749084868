import { Injectable } from '@angular/core';
import { CamfilZipCodeAddressData } from 'camfil-models/camfil-zip-code-address/camfil-zip-code-address.interface';
import { CamfilZipCodeAddressMapper } from 'camfil-models/camfil-zip-code-address/camfil-zip-code-address.mapper';
import { CamfilZipCodeAddress } from 'camfil-models/camfil-zip-code-address/camfil-zip-code-address.model';
import { Observable, throwError } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiService } from 'ish-core/services/api/api.service';

@Injectable({ providedIn: 'root' })
export class CamfilZipCodeAddressService {
  constructor(private apiService: ApiService) {}

  loadZipCodeAddress(zipCode: string, countryCode: string): Observable<CamfilZipCodeAddress[]> {
    if (!zipCode || !countryCode) {
      return throwError(() => new Error('loadZipCodeAddress() called without code or countryCode'));
    }

    return this.apiService
      .post<CamfilZipCodeAddressData[]>(`zipcodequery`, {
        country: countryCode,
        zipCode,
      })
      .pipe(map(info => CamfilZipCodeAddressMapper.fromData(info)));
  }
}
