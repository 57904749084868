<button mat-button tabindex="0" (click)="openModal()" (keydown.enter)="openModal()" [disabled]="!visibility">
  <mat-icon svgIcon="delete" />
  <camfil-content-by-role>
    <span class="role-default">{{
      orders?.length > 1
        ? ('camfil.checkout.cta.delete_orders' | translate)
        : ('camfil.checkout.cta.delete_order' | translate)
    }}</span>
    <span class="role-quote">{{ 'camfil.checkout.cta.delete_order_quote' | translate }}</span>
    <span class="role-requisition">{{ 'camfil.checkout.cta.delete_order_requisition' | translate }}</span>
  </camfil-content-by-role>
</button>
