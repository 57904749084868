import { createSelector } from '@ngrx/store';
import { CamfilRequisition } from 'camfil-models/camfil-requisition/camfil-requisition.model';

import { getCamfilFeatureState } from '../camfil-store';

import {
  CamfilRequisitionsState,
  camfilRequisitionsAdapter,
  camfilRequisitionsFeatureKey,
} from './camfil-requisitions.reducer';

const getCamfilRequisitionsState = getCamfilFeatureState<CamfilRequisitionsState>(camfilRequisitionsFeatureKey);

export const getCamfilRequisitionsLoading = createSelector(getCamfilRequisitionsState, state => state?.loading);

export const getCamfilRequisitionsError = createSelector(getCamfilRequisitionsState, state => state?.error);

export const { selectEntities, selectAll } = camfilRequisitionsAdapter.getSelectors(getCamfilRequisitionsState);

export const getCamfilRequisitions = selectAll;

export const getCamfilRequisition = (id: string) => createSelector(selectEntities, requisitions => requisitions[id]);

export const getSelectedCamfilRequisitionId = createSelector(
  getCamfilRequisitionsState,
  state => state.selectedCamfilRequisition
);

const getSelectedCamfilRequisition = createSelector(
  selectEntities,
  getSelectedCamfilRequisitionId,
  (entities, id): CamfilRequisition => id && entities[id]
);

const getMinDeliveryDates = createSelector(getCamfilRequisitionsState, state => state.minDeliveryDates);

export const getMinDeliveryDate = createSelector(
  getMinDeliveryDates,
  getSelectedCamfilRequisitionId,
  (entities, id): string => id && entities[id]
);

export const isCamfilRequisitionDeliveryDateFail = createSelector(
  getMinDeliveryDate,
  getSelectedCamfilRequisition,
  (date, requisition): boolean => {
    const minDate = new Date(date).getTime();
    const fromRequisition = new Date(requisition?.deliveryDate).getTime();
    return fromRequisition < minDate;
  }
);
