<ng-container *camfilChannelToggle="'showDeliveryIntervalOnCCDetailPage'">
  <div
    class="last-delivery-date__container"
    [ngClass]="{ 'sub-cam-card__date--container': lastDeliveryDateType === 'subCamCard' }"
  >
    <p class="text-center last-delivery-date__header">
      {{ 'camfil.account.cam_card.add_to_form.last_delivery' | translate }}
    </p>
    <!--    <p class="font-weight-bold text-center last-delivery-date__value">{{ (lastDeliveryDate | ishDate) || '-&#45;&#45;' }}</p>-->
    <p class="font-weight-bold text-center last-delivery-date__value">{{ (lastDeliveryDate | camfilDate) || '---' }}</p>
  </div>
</ng-container>
