/* eslint-disable ish-custom-rules/ordered-imports, ish-custom-rules/ban-imports-file-pattern, no-restricted-imports */
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';

import { FormlyModule as FormlyBaseModule } from '@ngx-formly/core';
import { FormlyFieldCheckbox, FormlyMatCheckboxModule } from '@ngx-formly/material/checkbox';
import { FormlyFieldDatepicker, FormlyMatDatepickerModule } from '@ngx-formly/material/datepicker';
import { FormlyMatFormFieldModule } from '@ngx-formly/material/form-field';
import { FormlyFieldInput, FormlyMatInputModule } from '@ngx-formly/material/input';
import { FormlyFieldRadio } from '@ngx-formly/material/radio';
import { FormlyFieldSelect } from '@ngx-formly/material/select';
import { FormlyFieldTextArea } from '@ngx-formly/material/textarea';
import { FormlyMatNativeSelectModule } from '@ngx-formly/material/native-select';
import { FormlyMatToggleModule } from '@ngx-formly/material/toggle';
import { FormlyMatSliderModule } from '@ngx-formly/material/slider';

import { LangChangeEvent, TranslateModule, TranslateService } from '@ngx-translate/core';
import { CamfilDateAdapter } from 'camfil-core/adapters/camfil-date-adapter';
import { CAMFIL_DATE_FORMATS, CAMFIL_FALLBACK_LANG } from 'camfil-shared/camfil-constants';
import { CamfilAutocompleteInputFieldComponent } from 'camfil-shared/formly/types/autocomplete-input-field/camfil-autocomplete-input-field.component';
import { CamfilFieldsetFieldComponent } from 'camfil-shared/formly/types/fieldset-field/camfil-fieldset-field.component';
import { CamfilSearchInputFieldComponent } from 'camfil-shared/formly/types/search-input-field/camfil-search-input-field.component';
import { formatISO, parseISO } from 'date-fns';

import { SpecialValidators, formlyValidation } from 'ish-shared/forms/validators/special-validators';

import { CamfilSpecialValidators } from '../validators/special-validators';

import { CamfilCheckboxFieldComponent } from './checkbox-field/camfil-checkbox-field.component';
import { CamfilHtmlTextFieldComponent } from './html-text-field/camfil-html-text-field.component';
import { CamfilPlainTextFieldComponent } from './plain-text-field/camfil-plain-text-field.component';
import { CamfilPostalCodeInputFieldComponent } from 'camfil-shared/formly/types/postal-code-input-field/camfil-postal-code-input-field.component';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
  MAT_NATIVE_DATE_FORMATS,
  MatNativeDateModule,
} from '@angular/material/core';
import { CamfilCityDynamicFieldComponent } from './city-dynamic-field/camfil-city-dynamic-field.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';

const fieldComponents = [
  CamfilAutocompleteInputFieldComponent,
  CamfilCheckboxFieldComponent,
  CamfilCityDynamicFieldComponent,
  CamfilFieldsetFieldComponent,
  CamfilHtmlTextFieldComponent,
  CamfilPlainTextFieldComponent,
  CamfilPostalCodeInputFieldComponent,
  CamfilSearchInputFieldComponent,
];

@NgModule({
  imports: [
    CommonModule,
    FormlyMatCheckboxModule,
    FormlyMatDatepickerModule,
    FormlyMatFormFieldModule,
    FormlyMatInputModule,
    FormlyMatNativeSelectModule,
    FormlyMatSliderModule,
    FormlyMatToggleModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatNativeDateModule,
    MatSelectModule,
    ReactiveFormsModule,
    TranslateModule,
    FormlyBaseModule.forChild({
      types: [
        {
          name: 'ish-text-input-field',
          component: FormlyFieldInput,
          wrappers: ['form-field'],
        },
        {
          name: 'ish-plain-text-field',
          component: CamfilPlainTextFieldComponent,
          wrappers: ['form-field'],
        },
        {
          name: 'ish-html-text-field',
          component: CamfilHtmlTextFieldComponent,
          wrappers: ['form-field'],
        },
        {
          name: 'ish-select-field',
          component: FormlyFieldSelect,
          wrappers: ['form-field'],
        },
        {
          name: 'ish-textarea-field',
          component: FormlyFieldTextArea,
          wrappers: ['form-field'],
        },
        {
          name: 'ish-checkbox-field',
          component: FormlyFieldCheckbox,
          wrappers: ['form-field'],
        },
        {
          name: 'ish-radio-field',
          component: FormlyFieldRadio,
          wrappers: ['form-field'],
        },
        {
          name: 'ish-date-picker-field',
          component: FormlyFieldDatepicker,
          wrappers: ['form-field'],
        },
        {
          name: 'camfil-text-input-field',
          component: FormlyFieldInput,
          wrappers: ['form-field'],
        },
        {
          name: 'camfil-plain-text-field',
          component: CamfilPlainTextFieldComponent,
        },
        {
          name: 'camfil-email-field',
          extends: 'camfil-text-input-field',
          defaultOptions: {
            props: {
              type: 'email',
            },
            validators: {
              email: formlyValidation('email', SpecialValidators.email),
            },
          },
        },
        {
          name: 'camfil-phone-field',
          extends: 'camfil-text-input-field',
          defaultOptions: {
            props: {
              attributes: { maxlength: 20 },
              type: 'tel',
            },
            validators: {
              phone: formlyValidation('phone', SpecialValidators.phone),
            },
          },
        },
        {
          name: 'camfil-username-field',
          extends: 'camfil-text-input-field',
          defaultOptions: {
            validators: {
              username: formlyValidation('username', CamfilSpecialValidators.username),
            },
          },
        },
        {
          name: 'camfil-password-field',
          extends: 'camfil-text-input-field',
          defaultOptions: {
            props: {
              type: 'password',
            },
          },
        },
        {
          name: 'camfil-checkbox-field',
          component: CamfilCheckboxFieldComponent,
          wrappers: ['form-field'],
        },
        {
          name: 'camfil-textarea-field',
          component: FormlyFieldTextArea,
          wrappers: ['form-field'],
        },
        {
          name: 'camfil-select-field',
          component: FormlyFieldSelect,
          wrappers: ['form-field'],
        },
        {
          name: 'camfil-postal-code-input-field',
          component: CamfilPostalCodeInputFieldComponent,
          wrappers: ['form-field'],
        },
        {
          name: 'camfil-city-dynamic-field',
          component: CamfilCityDynamicFieldComponent,
        },
        {
          name: 'camfil-search-input-field',
          component: CamfilSearchInputFieldComponent,
          wrappers: ['form-field'],
        },
        {
          name: 'camfil-autocomplete-input-field',
          component: CamfilAutocompleteInputFieldComponent,
          wrappers: ['form-field'],
        },
        {
          name: 'camfil-fieldset-field',
          component: CamfilFieldsetFieldComponent,
        },
        {
          name: 'camfil-datepicker-input-field',
          component: FormlyFieldDatepicker,
          wrappers: ['form-field'],
          defaultOptions: {
            parsers: [
              value => {
                try {
                  return formatISO(parseISO(value), { representation: 'date' });
                } catch (error) {
                  return value;
                }
              },
            ],
            props: {
              readonly: true,
            },
          },
        },
      ],
    }),
  ],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: CAMFIL_FALLBACK_LANG },
    {
      provide: DateAdapter,
      useClass: CamfilDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: CAMFIL_DATE_FORMATS },
    { provide: MAT_NATIVE_DATE_FORMATS, useValue: CAMFIL_DATE_FORMATS },
  ],
  declarations: [...fieldComponents],
  exports: [...fieldComponents],
})
export class CamfilFormlyTypesModule {
  constructor(private dateAdapter: DateAdapter<unknown>, private translateService: TranslateService) {
    const currentLang = this.translateService.currentLang?.replace(/_/, '-');

    if (currentLang) {
      this.dateAdapter.setLocale(currentLang);
    }

    this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
      const { lang } = event;
      this.dateAdapter.setLocale(lang.replace(/_/, '-'));
    });
  }
}
