/* eslint-disable ish-custom-rules/no-intelligence-in-artifacts,unicorn/no-null */

import { DestroyRef, Injectable, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute, Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { CamfilShoppingFacade } from 'camfil-core/facades/camfil-shopping.facade';
import { isEqual } from 'lodash-es';
import { Observable, combineLatest, distinctUntilChanged, of } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { ShoppingFacade } from 'ish-core/facades/shopping.facade';
import { Filter } from 'ish-core/models/filter/filter.model';
import { selectQueryParam } from 'ish-core/store/core/router';
import { whenTruthy } from 'ish-core/utils/operators';
import { URLFormParams, formParamsToString, stringToFormParams } from 'ish-core/utils/url-form-params';

enum MeasurementMode {
  'Standard' = 'standard',
  'Advanced' = 'advanced',
  'Diameter' = 'diameter',
}

const originalMeasurementFilterKeys = ['Width', 'Height', 'Diameter', 'Depth'] as const;

type MeasurementFilterComparison = 'lte' | 'gte';

type MeasurementKey<T extends string, C extends MeasurementFilterComparison> = `${T}[${C}]`;

type MeasurementModelKey<T extends string, C extends MeasurementFilterComparison> = `${T}-${C}`;

type ExtendedMeasurementFilterKeys<T extends string> = {
  [K in T | MeasurementKey<T, 'lte'> | MeasurementKey<T, 'gte'>]: K;
};

type ExtendedMeasurementModelKeys<T extends string> = {
  [K in T | MeasurementModelKey<T, 'lte'> | MeasurementModelKey<T, 'gte'>]: K;
};

const lteSegment: MeasurementKey<string, 'lte'> = '[lte]';

const lteSuffix: MeasurementModelKey<string, 'lte'> = '-lte';

const gteSegment: MeasurementKey<string, 'gte'> = '[gte]';

const gteSuffix: MeasurementModelKey<string, 'gte'> = '-gte';

const createExtendedMeasurementFilterKeys = <T extends string>(
  keys: readonly T[]
): ExtendedMeasurementFilterKeys<T> => {
  const result: Record<string, T> = {};

  keys.forEach(key => {
    result[key] = key;
    result[`${key}${gteSegment}`] = key;
    result[`${key}${lteSegment}`] = key;
  });

  return result as ExtendedMeasurementFilterKeys<T>;
};

const createExtendedMeasurementModelKeys = <T extends string>(keys: readonly T[]): ExtendedMeasurementModelKeys<T> => {
  const result: Record<string, T> = {};

  keys.forEach(key => {
    result[key] = key;
    result[`${key}${gteSuffix}`] = key;
    result[`${key}${lteSuffix}`] = key;
  });

  return result as ExtendedMeasurementModelKeys<T>;
};

const __measurementFilterKeys__ = createExtendedMeasurementFilterKeys(originalMeasurementFilterKeys);

const __measurementModelKeys__ = createExtendedMeasurementModelKeys(originalMeasurementFilterKeys);

const __measurementFilterKeysArray__ = [...Object.values(__measurementFilterKeys__)] as const;

const __measurementModelKeysArray__ = [...Object.values(__measurementModelKeys__)] as const;

export type MeasurementQueryParam = (typeof __measurementFilterKeysArray__)[number];

type ExactMeasurementQueryParam = Exclude<
  MeasurementQueryParam,
  MeasurementKey<string, 'lte'> | MeasurementKey<string, 'gte'>
>;

type GteMeasurementQueryParam = Extract<MeasurementQueryParam, MeasurementKey<string, 'gte'>>;

type LteMeasurementQueryParam = Extract<MeasurementQueryParam, MeasurementKey<string, 'lte'>>;

type MeasurementModelName = (typeof __measurementModelKeysArray__)[number];

type ExactMeasurementModelName = Exclude<
  MeasurementModelName,
  MeasurementModelKey<string, 'lte'> | MeasurementModelKey<string, 'gte'>
>;

type GteMeasurementModelName = Extract<MeasurementModelName, MeasurementModelKey<string, 'gte'>>;

type LteMeasurementModelName = Extract<MeasurementModelName, MeasurementModelKey<string, 'lte'>>;

export const measurementFilterKeys = [...Object.keys(__measurementFilterKeys__)] as MeasurementQueryParam[];

// eslint-disable-next-line etc/no-commented-out-code
// const measurementModelKeys = [...Object.keys(__measurementModelKeys__)] as MeasurementModelName[];

// eslint-disable-next-line etc/no-commented-out-code
// type MeasurementsFilter = Partial<{
//   [key in MeasurementQueryParam]: number;
// }>;

export type MeasurementsModel = Partial<{
  [key in MeasurementModelName]: number;
}>;

function getQueryParamExactKey(key: MeasurementQueryParam) {
  if (key.endsWith(gteSegment)) {
    return key.replace(gteSegment, '') as ExactMeasurementQueryParam;
  }

  if (key.endsWith(lteSegment)) {
    return key.replace(lteSegment, '') as ExactMeasurementQueryParam;
  }

  return key as ExactMeasurementQueryParam;
}

function getModelExactKey(key: MeasurementModelName) {
  if (key.endsWith(gteSuffix)) {
    return key.replace(gteSuffix, '') as ExactMeasurementModelName;
  }

  if (key.endsWith(lteSuffix)) {
    return key.replace(lteSuffix, '') as ExactMeasurementModelName;
  }

  return key as ExactMeasurementModelName;
}

function getQueryParamGteKey(key: MeasurementQueryParam) {
  const exactKey = getQueryParamExactKey(key);

  return `${exactKey}${gteSegment}` as GteMeasurementQueryParam;
}

function getModelGteKey(key: MeasurementModelName) {
  const exactKey = getModelExactKey(key);

  return `${exactKey}${gteSuffix}` as GteMeasurementModelName;
}

function getQueryParamLteKey(key: MeasurementQueryParam) {
  const exactKey = getQueryParamExactKey(key);

  return `${exactKey}${lteSegment}` as LteMeasurementQueryParam;
}

function getModelLteKey(key: MeasurementModelName) {
  const exactKey = getModelExactKey(key);

  return `${exactKey}${lteSuffix}` as LteMeasurementModelName;
}

@Injectable({ providedIn: 'root' })
export class CamfilMeasurementFilterFacade {
  private destroyRef = inject(DestroyRef);

  constructor(
    private store: Store,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private shoppingFacade: ShoppingFacade,
    private camfilShoppingFacade: CamfilShoppingFacade,
    private translate: TranslateService
  ) {
    this.shoppingFacade.selectedCategory$?.pipe(whenTruthy(), takeUntilDestroyed(this.destroyRef)).subscribe(value => {
      this.categoryParam = value?.uniqueId?.replace(/\./g, '/');
    });
  }

  private readonly predefinedOrder = {
    [MeasurementMode.Standard]: ['Width', 'Height', 'Depth'] as MeasurementQueryParam[],
    [MeasurementMode.Advanced]: [
      'Width[gte]',
      'Height[gte]',
      'Depth[gte]',
      'Width[lte]',
      'Height[lte]',
      'Depth[lte]',
    ] as MeasurementQueryParam[],
    [MeasurementMode.Diameter]: ['Diameter', 'Depth'] as MeasurementQueryParam[],
  };

  private measurementModeQueryParamKey = 'measurementsMode';
  private filtersQueryParamKey = 'filters';
  private categoryParam: string;
  private fragmentOnRouting: string;

  transformFilterNavigation = false;

  minRangeValue = 0;

  tolerance2D = 10; // Width, Height and Diameter

  tolerance3D = 50; // Depth

  measurementModes$ = of([MeasurementMode.Standard, MeasurementMode.Advanced, MeasurementMode.Diameter]);

  currentMeasurementMode$ = this.store.pipe(
    select(selectQueryParam(this.measurementModeQueryParamKey)),
    map(mode =>
      Object.values(MeasurementMode).includes(mode as MeasurementMode)
        ? (mode as MeasurementMode)
        : MeasurementMode.Standard
    )
  );

  currentFilterParams$ = this.store.pipe(
    select(selectQueryParam(this.filtersQueryParamKey)),
    map((filterString: string) => stringToFormParams(filterString))
  );

  private isNotANumber(input?: number) {
    return isNaN(input) || input === undefined || input === null;
  }

  private sortKeysByPredefinedOrder(keys: MeasurementQueryParam[], mode: MeasurementMode): MeasurementQueryParam[] {
    const predefinedOrder = this.predefinedOrder[mode];
    const matchingKeys = keys.filter(key => predefinedOrder.includes(key));
    const remainingKeys = keys.filter(key => !predefinedOrder.includes(key));

    matchingKeys.sort((a, b) => {
      const indexA = predefinedOrder.indexOf(a);
      const indexB = predefinedOrder.indexOf(b);
      return indexA - indexB;
    });

    return matchingKeys.concat(remainingKeys);
  }

  private transformQueryParamsKey(queryParamsKey: MeasurementQueryParam) {
    return queryParamsKey.replace(/\[(gte|lte)]/g, '-$1') as MeasurementModelName;
  }

  private transformFormModelKey(formModelKey: string) {
    return formModelKey.replace(/-(gte|lte)/g, '[$1]') as MeasurementQueryParam;
  }

  private determineMeasurementFilterKeys(mode: MeasurementMode) {
    return this.predefinedOrder[mode];
  }

  private determineMeasurementModelKeys(mode: MeasurementMode) {
    return this.determineMeasurementFilterKeys(mode).map(this.transformQueryParamsKey);
  }

  private serializeFormModel(formModel: MeasurementsModel, mode: MeasurementMode): URLFormParams {
    // eslint-disable-next-line complexity
    return Object.keys(formModel).reduce((searchParams: URLFormParams, key: MeasurementModelName) => {
      const queryParamKey = this.transformFormModelKey(key);
      const queryParamValue = formModel[key];
      const exactQueryParamKey = getQueryParamExactKey(queryParamKey);
      const lteQueryParamKey = getQueryParamLteKey(queryParamKey);
      const gteQueryParamKey = getQueryParamGteKey(queryParamKey);
      const exactModelKey = getModelExactKey(key);
      const lteModelKey = getModelLteKey(key);
      const gteModelKey = getModelGteKey(key);
      const exactModelValue = formModel?.[exactModelKey];
      const lteModelValue = formModel?.[lteModelKey];
      const gteModelValue = formModel?.[gteModelKey];
      const isEmpty = !queryParamValue && !exactModelValue && !lteModelValue && !gteModelValue;
      const tolerance = this.getFilterTolerance(queryParamKey);

      let params: URLFormParams = {};

      if (isEmpty) {
        params = this.createFilterRangeParams(queryParamKey, undefined);
      }

      switch (mode) {
        case MeasurementMode.Standard:
        case MeasurementMode.Diameter:
          if (!this.isNotANumber(exactModelValue) && this.isNotANumber(gteModelValue)) {
            params = this.createFilterRangeParams(queryParamKey, exactModelValue);
          } else if (!this.isNotANumber(gteModelValue) && this.isNotANumber(lteModelValue)) {
            params = this.createFilterRangeParams(queryParamKey, gteModelValue + tolerance);
          } else if (!this.isNotANumber(gteModelValue) && !this.isNotANumber(lteModelValue)) {
            params = this.createFilterRangeParams(queryParamKey, { minValue: gteModelValue, maxValue: lteModelValue });
          }
          break;
        case MeasurementMode.Advanced:
          if (!this.isNotANumber(gteModelValue) && this.isNotANumber(lteModelValue)) {
            params = {
              [exactQueryParamKey]: [`${gteModelValue}`],
              [gteQueryParamKey]: undefined,
              [lteQueryParamKey]: undefined,
            };
          } else if (!this.isNotANumber(gteModelValue) && !this.isNotANumber(lteModelValue)) {
            params = this.createFilterRangeParams(queryParamKey, { minValue: gteModelValue, maxValue: lteModelValue });
          } else {
            params = this.createFilterRangeParams(queryParamKey, queryParamValue);
          }
          break;
      }

      return { ...searchParams, ...params };
    }, {});
  }

  private deserializeQueryParams(queryParams: URLFormParams, mode: MeasurementMode): MeasurementsModel {
    return (
      Object.entries(queryParams)
        .filter(([key]) => measurementFilterKeys.includes(key as MeasurementQueryParam))
        // eslint-disable-next-line complexity
        .reduce((formModel, [key, value]) => {
          const queryParamKey = key as MeasurementQueryParam;
          const queryParamValue = value?.[0] ? parseInt(value?.[0], 10) : undefined;
          const gteQueryParamValue = this.getGteValueFromQueryParams(queryParamKey, queryParams);
          const lteQueryParamValue = this.getLteValueFromQueryParams(queryParamKey, queryParams);
          const exactQueryParamValue = this.exactValueFromQueryParams(queryParamKey, queryParams);
          const modelKey = this.transformQueryParamsKey(queryParamKey);
          const exactModelKey = getModelExactKey(modelKey);
          const gteModelKey = getModelGteKey(modelKey);
          const lteModelKey = getModelLteKey(modelKey);
          const tolerance = this.getFilterTolerance(queryParamKey);
          const isEmpty = !queryParamValue && !exactQueryParamValue && !gteQueryParamValue && !lteQueryParamValue;

          if (isEmpty) {
            formModel[exactModelKey] = undefined;
            formModel[gteModelKey] = undefined;
            formModel[lteModelKey] = undefined;
          }

          switch (mode) {
            case MeasurementMode.Standard:
            case MeasurementMode.Diameter:
              if (!this.isNotANumber(exactQueryParamValue)) {
                formModel[exactModelKey] = Math.round(exactQueryParamValue);
              } else if (!this.isNotANumber(gteQueryParamValue) && this.isNotANumber(lteQueryParamValue)) {
                formModel[exactModelKey] = Math.round(gteQueryParamValue + tolerance);
              } else if (!this.isNotANumber(gteQueryParamValue) && !this.isNotANumber(lteQueryParamValue)) {
                formModel[exactModelKey] = Math.round(lteQueryParamValue - tolerance);
              } else {
                formModel[exactModelKey] = undefined;
              }
              formModel[gteModelKey] = undefined;
              formModel[lteModelKey] = undefined;
              break;
            case MeasurementMode.Advanced:
              if (
                this.isNotANumber(gteQueryParamValue) &&
                this.isNotANumber(lteQueryParamValue) &&
                !this.isNotANumber(queryParamValue)
              ) {
                formModel[gteModelKey] = queryParamValue;
              } else {
                formModel[gteModelKey] = gteQueryParamValue;
                formModel[lteModelKey] = lteQueryParamValue;
                formModel[exactModelKey] = undefined;
              }
              break;
          }

          return formModel;
          // eslint-disable-next-line ish-custom-rules/no-object-literal-type-assertion
        }, {} as MeasurementsModel)
    );
  }

  private getGteValueFromQueryParams(key: MeasurementQueryParam, queryParams: URLFormParams) {
    return queryParams?.[getQueryParamGteKey(key)] ? parseInt(queryParams[getQueryParamGteKey(key)][0], 10) : undefined;
  }

  private getLteValueFromQueryParams(key: MeasurementQueryParam, queryParams: URLFormParams) {
    return queryParams?.[getQueryParamLteKey(key)] ? parseInt(queryParams[getQueryParamLteKey(key)][0], 10) : undefined;
  }

  private exactValueFromQueryParams(key: MeasurementQueryParam, queryParams: URLFormParams) {
    return queryParams?.[getQueryParamExactKey(key)]
      ? parseInt(queryParams[getQueryParamExactKey(key)][0], 10)
      : undefined;
  }

  private determineVisibility(key: MeasurementQueryParam, mode: MeasurementMode) {
    const keys = this.determineMeasurementFilterKeys(mode);

    return !keys.includes(key);
  }

  private determineClassName(key: MeasurementQueryParam) {
    const classNames = ['col-4'];
    const firstBorderRadiusFilters: MeasurementQueryParam[] = ['Width', 'Width[lte]', 'Width[gte]', 'Diameter'];
    const lastBorderRadiusFilters: MeasurementQueryParam[] = ['Depth', 'Depth[lte]', 'Depth[gte]'];

    if (firstBorderRadiusFilters.includes(key)) {
      classNames.push('no-border-radius-first');
    } else if (lastBorderRadiusFilters.includes(key)) {
      classNames.push('no-border-radius-last');
    } else {
      classNames.push('no-border-radius-middle');
    }

    return classNames.join(' ');
  }

  getFilterTolerance(key: MeasurementQueryParam) {
    return getQueryParamExactKey(key) === 'Width' ||
      getQueryParamExactKey(key) === 'Height' ||
      getQueryParamExactKey(key) === 'Diameter'
      ? this.tolerance2D
      : getQueryParamExactKey(key) === 'Depth'
      ? this.tolerance3D
      : 0;
  }

  getFilterRange(key: MeasurementQueryParam, value: number) {
    if (!value) {
      return { minValue: 0, maxValue: 0 };
    }

    const tolerance = this.getFilterTolerance(key);
    const minValue = Math.max(this.minRangeValue, Math.floor(value - tolerance));
    const maxValue = Math.ceil(value + tolerance);

    return { minValue, maxValue };
  }

  createFilterRangeParams(
    key: MeasurementQueryParam,
    value: number | { minValue: number; maxValue: number }
  ): URLFormParams {
    const isNumber = typeof value === 'number';
    const tolerance = this.getFilterTolerance(key);

    let minValue: number, maxValue: number;

    if (isNumber) {
      const range = this.getFilterRange(key, value);
      minValue = range.minValue;
      maxValue = range.maxValue;
    } else if (!this.isNotANumber(value?.minValue) && !this.isNotANumber(value?.maxValue)) {
      minValue = value.minValue;
      maxValue = value.maxValue;
    } else {
      minValue = undefined;
      maxValue = undefined;
    }

    if (tolerance === 0) {
      return { [`${key}`]: isNumber && value ? [`${value}`] : undefined };
    } else {
      return {
        [`${getQueryParamGteKey(key)}`]: !this.isNotANumber(minValue) ? [`${minValue}`] : undefined,
        [`${getQueryParamLteKey(key)}`]: !this.isNotANumber(maxValue) ? [`${maxValue}`] : undefined,
        [`${getQueryParamExactKey(key)}`]: undefined,
      };
    }
  }

  applyMeasurementMode(mode: MeasurementMode) {
    this.camfilShoppingFacade.productListingViewType$.pipe(take(1)).subscribe(viewType => {
      this.router.navigate([], {
        queryParamsHandling: 'merge',
        relativeTo: this.activatedRoute,
        queryParams: { measurementsMode: mode, view: viewType },
        fragment: this.fragmentOnRouting,
      });
    });
  }

  // TODO (extMlk): This method should be abstracted since it is generic and not related to measurements
  applyFilter(event: { searchParameter: URLFormParams }) {
    const category = this.categoryParam ? [`${this.categoryParam}`] : undefined;
    const params = formParamsToString({ ...event?.searchParameter, category });

    return this.router.navigate([], {
      queryParamsHandling: 'merge',
      relativeTo: this.activatedRoute,
      queryParams: { filters: params, page: 1 },
      fragment: this.fragmentOnRouting,
    });
  }

  clearFilters() {
    this.router.navigate([], {
      queryParamsHandling: 'merge',
      relativeTo: this.activatedRoute,
      queryParams: { filters: undefined, page: 1 },
      fragment: this.fragmentOnRouting,
    });
  }

  hideFilter(filter: Filter) {
    return (
      !measurementFilterKeys.includes(filter.id as MeasurementQueryParam) &&
      filter?.facets?.some(facet => facet?.count > 0)
    );
  }

  getMeasurementQueryParams$<T extends MeasurementsModel>(formModel: T) {
    return combineLatest([this.currentMeasurementMode$, this.currentFilterParams$]).pipe(
      map(([mode, currentParams]) => {
        const params = this.serializeFormModel(formModel, mode);
        return { ...currentParams, ...params };
      })
    );
  }

  getMeasurementsFormModel$() {
    return combineLatest([this.currentMeasurementMode$, this.currentFilterParams$]).pipe(
      distinctUntilChanged(isEqual),
      map(([mode, params]) => {
        const formModel = this.deserializeQueryParams(params, mode);
        return this.determineMeasurementModelKeys(mode).reduce((acc, key) => {
          acc[key] = formModel[key];
          return acc;
          // eslint-disable-next-line ish-custom-rules/no-object-literal-type-assertion
        }, {} as MeasurementsModel);
      })
    );
  }

  getMeasurementsFormFields$(): Observable<FormlyFieldConfig[]> {
    return this.currentMeasurementMode$.pipe(
      map(mode => [
        {
          fieldGroupClassName: 'row no-gutters',
          fieldGroup: this.sortKeysByPredefinedOrder(measurementFilterKeys, mode).map(key => ({
            type: 'camfil-text-input-field',
            key: this.transformQueryParamsKey(key),
            className: this.determineClassName(key),
            props: {
              min: this.minRangeValue,
              appearance: 'outline',
              label: this.translate.instant(`camfil.modal.measurement.${key}.label`),
              required: false,
              type: 'number',
            },
            hide: this.determineVisibility(key, mode),
            validation: {
              messages: {
                min: this.translate.instant('camfil.modal.measurement.error.min', {
                  0: this.minRangeValue,
                }),
              },
            },
            modelOptions: {
              updateOn: 'change',
            },
          })),
        },
      ])
    );
  }
}
