import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChange,
  SimpleChanges,
  inject,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AbstractControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { range } from 'lodash-es';

import { ProductContextFacade } from 'ish-core/facades/product-context.facade';
import { ProductView } from 'ish-core/models/product-view/product-view.model';
import { SelectOption } from 'ish-core/models/select-option/select-option.model';
import { SpecialValidators } from 'ish-shared/forms/validators/special-validators';

import { ADD_NEW_PRODUCT_VALIDATORS } from './validators';

function generateSelectOptionsForRange(min: number, max: number): SelectOption[] {
  return range(min, max)
    .map(num => num.toString())
    .map(num => ({ label: num, value: num }));
}

type CamfilProductQuantityType = 'input' | 'select' | 'counter';

@Component({
  selector: 'camfil-product-quantity',
  templateUrl: './camfil-product-quantity.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CamfilProductQuantityComponent implements OnInit, OnChanges {
  get quantity() {
    return this.parentForm.get(this.controlName)?.value;
  }

  get labelClass() {
    return this.quantityLabel.trim() === '' ? 'col-0' : 'label-quantity col-6';
  }

  get inputClass() {
    return this.quantityLabel.trim() === ''
      ? `col-12${this.class ? this.class : ''}`
      : `col-6${this.class ? this.class : ''}`;
  }

  constructor(public dialog: MatDialog, private context: ProductContextFacade) {}
  @Input() showErrors = true;
  @Input() readOnly = false;
  @Input() allowZeroQuantity = false;
  @Input() defaultMinQuantity = 0;
  @Input() quantityLabel = 'product.quantity.label';
  @Input() product: ProductView;
  @Input() parentForm: FormGroup;
  @Input() controlName: string;
  @Input() type: CamfilProductQuantityType = 'counter';
  @Input() class: string;
  @Input() isInLineItem = false;
  @Input() focusInputOnly = false;
  @Input() lineItemId: string;

  @Output() focusInput = new EventEmitter();

  quantityOptions: SelectOption[];
  maxFromContext: number;

  validators = ADD_NEW_PRODUCT_VALIDATORS;
  private destroyRef = inject(DestroyRef);
  static validateValueWithQuantityStep(stepQuantity = 1) {
    return (control: AbstractControl): ValidationErrors | undefined => {
      const value = control.value;

      if (!value) {
        return;
      }

      const isValueMultipliedCorrectly = value % stepQuantity === 0;

      return !isValueMultipliedCorrectly ? { stepQuantityValue: true } : undefined;
    };
  }

  getValidators(): ValidatorFn {
    if (this.type === 'input' || this.type === 'counter') {
      return Validators.compose([
        Validators.required,
        Validators.min(this.allowZeroQuantity ? this.defaultMinQuantity : this.product.minOrderQuantity),
        Validators.max(this.maxFromContext),
        SpecialValidators.integer,
        CamfilProductQuantityComponent.validateValueWithQuantityStep(this.product.stepQuantity),
      ]);
    }
  }

  ngOnInit() {
    this.context
      ?.select('maxQuantity')
      ?.pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(max => {
        this.maxFromContext = max;
      });
  }

  ngOnChanges(change: SimpleChanges) {
    if (this.type === 'select') {
      this.createSelectOptions(change.product);
    }
    if (change.product) {
      const quantityValidator = this.validators.quantity
        ?.map(validator =>
          validator.error === 'stepQuantityValue'
            ? {
                ...validator,
                messageVariables: [`${this.product.stepQuantity}`],
              }
            : validator
        )
        .filter(({ error }) => error !== 'max' || this.maxFromContext);
      this.validators = {
        ...this.validators,
        quantity: [...quantityValidator],
      };

      this.parentForm.get(this.controlName).setValidators(this.getValidators());
    }
  }

  private createSelectOptions(change: SimpleChange) {
    if (change?.currentValue) {
      this.quantityOptions = generateSelectOptionsForRange(this.product.minOrderQuantity, this.maxFromContext);
    }
  }

  placeholderQuantity(): string {
    return this.allowZeroQuantity ? '0' : `${this.product.minOrderQuantity}`;
  }

  focus() {
    this.focusInput.emit();
  }
}
