import { createSelector } from '@ngrx/store';
import { getCamfilFeatureState } from 'camfil-core/store/camfil-store';

import { AhuUnitFilterState, ahuUnitFilterFeatureKey, unitFilterAdapter } from './unit-filter.reducer';

const getUnitFilterState = getCamfilFeatureState<AhuUnitFilterState>(ahuUnitFilterFeatureKey);

const { selectAll, selectEntities } = unitFilterAdapter.getSelectors(getUnitFilterState);

export const getAhuUnitFilterEntities = selectEntities;

export const getAllAhuUnitFilters = selectAll;

export const getAhuUnitFiltersLoading = createSelector(getUnitFilterState, state => state.loading);

export const getAhuUnitFiltersError = createSelector(getUnitFilterState, state => state.error);

export const getSelectedAhuUnitFilterId = createSelector(getUnitFilterState, state => state.selected);

export const getSelectedAhuUnitFilter = createSelector(
  selectEntities,
  getSelectedAhuUnitFilterId,
  (entities, id) => id && entities[id]
);
