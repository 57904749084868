import { Injectable } from '@angular/core';
import { Validators } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { CamfilConfigurationFacade } from 'camfil-core/facades/camfil-configuration.facade';
import { getOptions$ } from 'camfil-shared/formly/utils/camfil-form-utils';
import { takeWhile } from 'rxjs';

import { FieldLibraryConfiguration } from 'ish-shared/formly/field-library/configurations/field-library-configuration';

@Injectable()
export class DeliveryAddressCountryCodeConfiguration extends FieldLibraryConfiguration {
  constructor(private camfilConfigurationFacade: CamfilConfigurationFacade) {
    super();
  }

  id = 'deliveryAddressCountryCodeFLC';

  getFieldConfig(): FormlyFieldConfig {
    return {
      key: 'deliveryAddress.countryCode',
      type: 'camfil-autocomplete-input-field',
      props: {
        label: 'camfil.modal.createOrder.order-form.input.country_code',
        required: true,
        disabled: true,
      },
      validation: {
        messages: {
          pattern: 'camfil.modal.createOrder.order-form.input.country_code.error.pattern',
          required: 'camfil.modal.createOrder.order-form.input.country_code.error.required',
        },
      },
      hooks: {
        onInit: (field: FormlyFieldConfig) => {
          this.camfilConfigurationFacade.countryCode$
            .pipe(takeWhile(() => !field?.formControl.value))
            .subscribe(countryCode => {
              field?.formControl.setValue(countryCode);
            });

          this.camfilConfigurationFacade.countryCodeRegExp$.subscribe(countryCodePattern => {
            field.formControl.setValidators([Validators.pattern(countryCodePattern)]);
          });

          const options$ = getOptions$({
            field,
            addEmptyOption: true,
            observableFn: () => this.camfilConfigurationFacade.availableCountryCodes$,
          });

          field.props.options = options$;
        },
      },
    };
  }
}
