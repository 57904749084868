export enum CamfilImageTypes {
  Badge = 'badges',
  Brochures = 'RTYP2',
  ProductPdf = 'RTYP12',
  HamdlingAndMaintenance = 'RTYP15',
  Image = 'images',
  YtVideo = 'youTubeVideos',
}

export type CamfilImageTypeIds = 'S' | 'M' | 'images';
