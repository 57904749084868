import { Injectable } from '@angular/core';
import { createEffect, ofType } from '@ngrx/effects';
import { quickSearchProducts } from 'camfil-core/store/shopping/camfil-search/camfil-search.actions';
import { CAMFIL_QUICK_SEARCH_ID } from 'camfil-shared/camfil-constants';
import { isEqual } from 'lodash-es';
import { distinctUntilChanged, map } from 'rxjs/operators';

import { loadProductsForFilter } from 'ish-core/store/shopping/filter';
import { loadMoreProductsForParams } from 'ish-core/store/shopping/product-listing';
import { ProductListingEffects } from 'ish-core/store/shopping/product-listing/product-listing.effects';
import { loadProductsForCategory, loadProductsForMaster } from 'ish-core/store/shopping/products';
import { searchProducts } from 'ish-core/store/shopping/search';
import { mapToPayload, whenTruthy } from 'ish-core/utils/operators';

/* eslint-disable @typescript-eslint/dot-notation */

@Injectable()
export class CamfilProductListingEffects extends ProductListingEffects {
  override loadMoreProducts$ = createEffect(() =>
    this['actions$'].pipe(
      ofType(loadMoreProductsForParams),
      mapToPayload(),
      distinctUntilChanged(isEqual),
      map(({ id, sorting, page, filters }) => {
        if (filters && id?.type !== CAMFIL_QUICK_SEARCH_ID) {
          const searchParameter = filters;
          return loadProductsForFilter({ id: { ...id, filters }, searchParameter, page, sorting });
        } else {
          switch (id.type) {
            case 'category':
              return loadProductsForCategory({ categoryId: id.value, page, sorting });
            case 'search':
              return searchProducts({ searchTerm: id.value, page, sorting });
            case CAMFIL_QUICK_SEARCH_ID:
              return quickSearchProducts({ searchTerm: id.value, page, sorting });
            case 'master':
              return loadProductsForMaster({ masterSKU: id.value, page, sorting });
            default:
              return;
          }
        }
      }),
      whenTruthy(),
      distinctUntilChanged(isEqual)
    )
  );
}
