import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';

// eslint-disable-next-line ish-custom-rules/require-formly-code-documentation
@Component({
  selector: 'camfil-html-text-field',
  templateUrl: './camfil-html-text-field.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CamfilHtmlTextFieldComponent extends FieldType<FieldTypeConfig> {
  get textValue() {
    return this.form.get(this.field.key as string)?.value;
  }
}
