import { CdkDragDrop, CdkDragEnd, CdkDragMove } from '@angular/cdk/drag-drop';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormArray } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { CamCardsFacade } from 'camfil-core/facades/camfil-cam-cards.facade';
import { CamfilConfigurationFacade } from 'camfil-core/facades/camfil-configuration.facade';
import { CamfilBucketHelper } from 'camfil-models/camfil-bucket/camfil-bucket.helper';
import {
  CAMCARD_LINE_HEIGHT,
  CAMCARD_LINE_HEIGHT_MOBILE,
  CAMCARD_LIST_HEIGHT,
} from 'camfil-models/camfil-cam-card/cam-card.constants';
import { CamCard, CamCardItem } from 'camfil-models/camfil-cam-card/cam-card.model';
import { Observable, debounceTime, fromEvent, map, startWith } from 'rxjs';

import { AppFacade } from 'ish-core/facades/app.facade';
import { DeviceType } from 'ish-core/models/viewtype/viewtype.types';

@Component({
  selector: 'camfil-account-cam-card-scroll-list',
  templateUrl: './camfil-account-cam-card-scroll-list.component.html',
  styleUrls: ['./camfil-account-cam-card-scroll-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccountCamCardScrollListComponent implements OnInit, AfterViewInit {
  constructor(
    private camCardsFacade: CamCardsFacade,
    private camfilConfigurationFacade: CamfilConfigurationFacade,
    public dialog: MatDialog,
    private appFacade: AppFacade
  ) {}

  @Output() openDeleteProductDialog = new EventEmitter<CamCardItem>();
  @Output() dropElement = new EventEmitter<{ fromEvent: CdkDragDrop<CamCardItem[]>; targetCamCard: CamCard }>();
  @Output() saveBtnStatus = new EventEmitter<boolean>();

  @ViewChild('scrollContainer') scrollContainer: ElementRef<HTMLDivElement>;
  @Input() camCard: CamCard;
  @Input() selectedItemsForm: FormArray;
  @Input() dropListClass: string;

  deviceType$ = this.appFacade.deviceType$;
  dndOn = true;
  isIntervalVisible$: Observable<boolean>;

  itemSize = CAMCARD_LINE_HEIGHT;
  itemSizeMobile = CAMCARD_LINE_HEIGHT_MOBILE;
  numberOfVisibleLineItems = CAMCARD_LIST_HEIGHT / this.itemSize;

  currentScrollPosition = 0;
  currentScrollPosition$: Observable<{ scroll: number }>;

  get dndText() {
    return `camfil.dynamic.cam_card.dnd.switcher.${this.dndOn ? 'off' : 'on'}.label`;
  }
  ngOnInit() {
    this.isIntervalVisible$ = this.camfilConfigurationFacade?.isEnabled$('showDeliveryIntervalOnCCDetailPage');
  }

  ngAfterViewInit(): void {
    this.handleScrollContainer();
  }

  containerSize(qty: number, deviceType: DeviceType = 'desktop') {
    const quntity = qty > this.numberOfVisibleLineItems ? this.numberOfVisibleLineItems : qty;

    let result = `${quntity * (deviceType === 'mobile' ? this.itemSizeMobile : this.itemSize)}px`;

    if (deviceType === 'mobile' && qty < this.numberOfVisibleLineItems) {
      result = 'auto';
    }

    return result;
  }

  setCCEditMode() {
    this.camCardsFacade.setCamCardEditMode(true);
  }

  dragMoved(event: CdkDragMove) {
    const dragElement = event.source.getRootElement();
    dragElement.classList.add('cdk-drag-changed');
  }

  dragEnded(event: CdkDragEnd) {
    const dragElement = event.source.getRootElement();
    setTimeout(() => {
      dragElement.classList.remove('cdk-drag-changed');
    }, 500);
  }

  drop(fromEvent: CdkDragDrop<CamCardItem[]>, targetCamCard?: CamCard) {
    this.dropElement.emit({ fromEvent, targetCamCard });
  }

  openDeleteProductConfirmationDialog(camCardItem: CamCardItem) {
    this.openDeleteProductDialog.emit(camCardItem);
  }

  getMoreItemsCalc(index: number) {
    return CamfilBucketHelper.isMoreItems(this.currentScrollPosition, index, CAMCARD_LIST_HEIGHT);
  }

  handleScrollContainer() {
    if (!this.currentScrollPosition$ && this.scrollContainer) {
      this.currentScrollPosition$ = fromEvent(this.scrollContainer?.nativeElement, 'scroll').pipe(
        debounceTime(100),
        map((e: Event) => {
          const scroll = (e?.target as HTMLDivElement).scrollTop;
          this.currentScrollPosition = scroll;
          return { scroll };
        }),
        startWith({ scroll: 0 })
      );
    }
  }

  trackBy(_: unknown, camCardItem: CamCardItem) {
    return camCardItem.id;
  }

  disabledEditSaveBtn(event: boolean) {
    this.saveBtnStatus.emit(event);
  }
}
