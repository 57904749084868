import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  Input,
  OnInit,
  ViewChild,
  inject,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { CamRequisitionManagementFacade } from 'camfil-core/facades/cam-requisition-management.facade';
import { CamfilConfigurationFacade } from 'camfil-core/facades/camfil-configuration.facade';
import { CamfilLineItemHelper } from 'camfil-models/camfil-line-item/camfil-line-item.helper';
import { CamfilLineItem } from 'camfil-models/camfil-line-item/camfil-line-item.model';
import { CamfilRequisition } from 'camfil-models/camfil-requisition/camfil-requisition.model';

import { Attribute } from 'ish-core/models/attribute/attribute.model';
import { markAsDirtyRecursive } from 'ish-shared/forms/utils/form-utils';

@Component({
  selector: 'camfil-requisition-line-item-box-label',
  templateUrl: './camfil-requisition-line-item-box-label.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CamfilRequisitionLineItemBoxLabelComponent implements OnInit, AfterViewInit {
  private destroyRef = inject(DestroyRef);
  private boxLabelMaxLength: number;
  @ViewChild('autosize') autosize: CdkTextareaAutosize;
  @Input() lineItem: CamfilLineItem;
  @Input() requisition: CamfilRequisition;
  @Input() isEditable = false;
  boxLabel: string;
  boxLabelForm = new UntypedFormGroup({});
  boxLabelField: FormlyFieldConfig[];

  constructor(
    private camRequisitionManagementFacade: CamRequisitionManagementFacade,
    private cdr: ChangeDetectorRef,
    private camfilConfigurationFacade: CamfilConfigurationFacade,
    private translateService: TranslateService
  ) {}
  ngOnInit() {
    this.boxLabelField = this.getBoxLabelField();
    this.camfilConfigurationFacade
      .maxLength$('boxLabel')
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((value: number) => {
        this.boxLabelMaxLength = value;
      });
  }

  ngAfterViewInit(): void {
    this.applyLineItemParameters(this.lineItem);
    this.cdr.detectChanges();
  }

  private applyLineItemParameters(lineItem: CamfilLineItem) {
    const boxLabel = (CamfilLineItemHelper.getValFromAttrs<string>(lineItem, 'boxLabel') as string) || '';

    this.boxLabel = boxLabel;

    this.boxLabelForm?.get('boxLabel')?.setValue(boxLabel);
  }

  private getBoxLabelField() {
    const maxLengthErrorText = this.translateService.instant('camfil.form.error.maxLength', {
      0: this.boxLabelMaxLength,
    });
    return [
      {
        fieldGroup: [
          {
            key: 'boxLabel',
            type: 'camfil-textarea-field',
            defaultValue: CamfilLineItemHelper.getValFromAttrs<string>(this.lineItem, 'boxLabel') || '---',
            className: 'overflow-hidden',
            props: {
              required: false,
              disabled: this.lineItem?.requisitionLineItemStatus === 'APPROVED',
              readonly: this.lineItem?.requisitionLineItemStatus === 'APPROVED',
              label: 'camfil.cam_card.product.comment.label',
              autosizeMinRows: 1,
              autosizeMaxRows: 3,
              autosize: true,
              blur: () => {
                this.onBlur('boxLabel', this.boxLabelForm);
              },
            },

            validators: {
              validation: [Validators.maxLength(this.boxLabelMaxLength)],
            },
            validation: {
              messages: {
                maxlength: maxLengthErrorText,
              },
            },
          },
        ],
        fieldGroupClassName: 'formly-wrapper',
      },
    ];
  }

  onBlur(field: string, form: UntypedFormGroup) {
    if (this.lineItem?.requisitionLineItemStatus === 'SUBMITTED') {
      if (form.invalid) {
        markAsDirtyRecursive(form);
        return;
      }

      const formField = form.get(field);
      const value = formField.value;
      const oldValue = this.boxLabel;
      const boxLabelAttribute: Attribute = { name: 'boxLabel', type: 'String', value };

      if (oldValue && !value) {
        this.camRequisitionManagementFacade.deleteCamfilRequisitionLineItemAttributes(
          this.requisition.id,
          this.lineItem.id,
          boxLabelAttribute
        );
      } else if (value) {
        this.camRequisitionManagementFacade.updateCamfilRequisitionLineItem(this.requisition.id, {
          lineItemId: this.lineItem.id,
          boxLabel: value,
        });
      }
    }
  }
}
