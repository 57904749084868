import { ChangeDetectionStrategy, Component, DestroyRef, OnInit, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { CamfilCheckoutFacade } from 'camfil-core/facades/camfil-checkout.facade';
import { CamfilDialogFacade } from 'camfil-core/facades/camfil-dialog.facade';

import { whenTruthy } from 'ish-core/utils/operators';

import { CamfilOrderFeedbackDialogComponent } from '../camfil-order-feedback-dialog/camfil-order-feedback-dialog.component';

@Component({
  selector: 'camfil-order-feedback',
  templateUrl: './camfil-order-feedback.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CamfilOrderFeedbackComponent implements OnInit {
  private destroyRef = inject(DestroyRef);

  constructor(private camfilCheckoutFacade: CamfilCheckoutFacade, private camfilDialogFacade: CamfilDialogFacade) {}

  ngOnInit(): void {
    this.camfilCheckoutFacade.showSubmitedBasket$
      .pipe(whenTruthy(), takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.camfilDialogFacade.open(CamfilOrderFeedbackDialogComponent);
      });
  }
}
