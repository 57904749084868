import { Injectable } from '@angular/core';

import { ProductData } from 'ish-core/models/product/product.interface';
import { ProductMapper } from 'ish-core/models/product/product.mapper';
import { AllProductTypes } from 'ish-core/models/product/product.model';

@Injectable({ providedIn: 'root' })
export class CamfilProductsMapper extends ProductMapper {
  override fromData(data: ProductData): AllProductTypes {
    const ishData = super.fromData(data);
    /**
     * to calculation or show the correct value, the system must use the untranslated attr.name
     * assume, that the main attributes have untranslated property name
     */
    ishData.attributes = data.attributes || [];
    return ishData;
  }
}
