import { FormGroup } from '@angular/forms';
import { CamCardItem, CamCardMeasurement } from 'camfil-models/camfil-cam-card/cam-card.model';
import { CamfilLineItem, CamfilLineItemView } from 'camfil-models/camfil-line-item/camfil-line-item.model';

import { CamfilOrderLineItem } from '../camfil-order-line-item/camfil-order-line-item.model';

import { Attribute } from './attribute.model';

export class CamfilAttributeHelper {
  static formatDeliveryDate(value: Date) {
    const month = `0 ${value.getMonth() + 1}`.slice(-3).replace(/\s/g, '');
    const day = `0 ${value.getDate()}`.slice(-3);
    const year = value.getFullYear();
    return [year, month, day].join('-').replace(/\s/g, '');
  }

  static getAttrsBeforeAddToCart(measurements: CamCardMeasurement, boxLabel: string, sourceCCLineItemId?: string) {
    const measurementsObj = measurements
      ? Object.entries(measurements)
          .map(([key, value]) => ({
            name: key,
            type: 'Double',
            value,
          }))
          .filter(({ value }) => value && typeof value === 'number')
      : [];

    const lineItemAttributes = [...measurementsObj] as Attribute[];
    if (boxLabel) {
      lineItemAttributes.push({ name: 'boxLabel', type: 'String', value: boxLabel });
    }

    if (sourceCCLineItemId) {
      lineItemAttributes.push({ name: 'SOURCE_CAMCARD_LINE_ITEM_ID', type: 'String', value: sourceCCLineItemId });
    }

    return lineItemAttributes;
  }

  static calculateAttrsToAddFromForm(form: FormGroup) {
    const boxLabel = form.get('boxLabel').value;
    const measurements = {
      width: form.get('width')?.value,
      height: form.get('height')?.value,
      depth: form.get('depth')?.value,
    };
    return CamfilAttributeHelper.getAttrsBeforeAddToCart(measurements, boxLabel);
  }

  static determineCamfilLineItemViewType(
    lineItem: CamfilOrderLineItem | CamfilLineItem | CamCardItem
  ): lineItem is CamfilLineItem {
    return 'attributes' in lineItem;
  }

  static determineIfCamCardItemType(
    lineItem: CamfilOrderLineItem | CamfilLineItem | CamCardItem
  ): lineItem is CamCardItem {
    return 'measurement' in lineItem;
  }

  static getMeasurementsText(lineItem: CamfilOrderLineItem | CamfilLineItemView | CamCardItem) {
    let width, height, depth;

    if (CamfilAttributeHelper.determineCamfilLineItemViewType(lineItem)) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const measurements: { [key: string]: any } = {
        width: undefined,
        height: undefined,
        depth: undefined,
      };

      lineItem?.attributes.forEach(att => {
        if (att.value && ['width', 'height', 'depth'].includes(att.name)) {
          measurements[att.name] = Number(att.value);
        }
      });

      ({ width, height, depth } = measurements);
    } else if (CamfilAttributeHelper.determineIfCamCardItemType(lineItem)) {
      ({ width, height, depth } = lineItem?.measurement);
    } else {
      ({ width, height, depth } = lineItem);
    }

    return CamfilAttributeHelper.formatMeasurements([width, height, depth]);
  }

  static formatMeasurements(measurements: number[], separator = 'x') {
    if (!measurements || measurements.some(measurement => typeof measurement !== 'number' || isNaN(measurement))) {
      return;
    }

    return measurements.filter(Boolean).map(String).join(separator);
  }

  static isZero(attribute: undefined | Attribute): undefined | boolean {
    switch (attribute.type) {
      case 'Integer':
        return attribute.value === 0;
      default:
        return;
    }
  }

  static getAttributeValueByAttributePartialName<T>(attributes: Attribute[], attributePartialName: string) {
    return (attributes?.find(a => a.name.includes(attributePartialName))?.value as T) || undefined;
  }
}
