import { EntityState, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { CamfilB2bContact } from 'camfil-models/camfil-organization/contact/camfil-b2b-contact.model';

import { HttpError } from 'ish-core/models/http-error/http-error.model';
import { setErrorOn, setLoadingOn, unsetLoadingAndErrorOn } from 'ish-core/utils/ngrx-creators';

import {
  loadCustomerContact,
  loadCustomerContactFail,
  loadCustomerContactSuccess,
  loadCustomerContacts,
  loadCustomerContactsFail,
  loadCustomerContactsSuccess,
  loadCustomerUserContact,
  loadCustomerUserContactFail,
  loadCustomerUserContactSuccess,
} from './b2b-contacts.actions';

export const camfilB2bContactsFeatureKey = 'b2bContacts';

export const contactAdapter = createEntityAdapter<CamfilB2bContact>({
  selectId: contact => contact.erpId,
});

export interface B2bContactsState extends EntityState<CamfilB2bContact> {
  loading: boolean;
  error: HttpError;
  initialized: boolean;
}

export const initialState: B2bContactsState = contactAdapter.getInitialState({
  loading: false,
  error: undefined,
  initialized: false,
});

export const reducer = createReducer(
  initialState,
  setLoadingOn(loadCustomerContacts, loadCustomerContact, loadCustomerUserContact),
  setErrorOn(loadCustomerContactsFail, loadCustomerContactFail, loadCustomerUserContactFail),
  unsetLoadingAndErrorOn(loadCustomerContactsSuccess, loadCustomerContactSuccess, loadCustomerUserContactSuccess),
  on(loadCustomerContactsSuccess, (state: B2bContactsState, action) => {
    const { contacts, customerId } = action.payload;

    const contactsWithCustomerIDs = contacts.map(contact => {
      const entityId = contact?.erpId;

      const contactCustomerIDs = state?.entities?.[entityId]?.customerIDs || [];
      const customerIDs = [...new Set([...contactCustomerIDs, customerId])];

      return { ...state, initialized: true, ...contact, customerIDs };
    });

    return contactAdapter.upsertMany(contactsWithCustomerIDs, state);
  }),
  on(loadCustomerContactSuccess, loadCustomerUserContactSuccess, (state: B2bContactsState, action) => {
    const { contact, customerId } = action.payload;

    const entityId = contact?.erpId;

    const contactCustomerIDs = state?.entities?.[entityId]?.customerIDs || [];
    const customerIDs = [...new Set([...contactCustomerIDs, customerId])];
    const entity = { ...contact, customerIDs };

    return contactAdapter.upsertOne(entity, { ...state, initialized: true });
  }),
  on(loadCustomerUserContactSuccess, (state: B2bContactsState, action) => {
    const { contact, userId } = action.payload;

    const entityId = contact?.erpId;

    const contactUserIDs = state?.entities?.[entityId]?.userIDs || [];
    const userIDs = [...new Set([...contactUserIDs, userId])];
    const entity = { ...contact, userIDs };

    return contactAdapter.upsertOne(entity, state);
  })
);
