<div class="container line-item-list">
  <!-- Line Items Overview -->
  <div class="row list-header d-none d-md-flex">
    <div class="col-md-8 col-xl-6 list-header-item list-header-item-descr">
      {{ 'shopping_cart.product_description.heading' | translate }}
    </div>
    <div class="col-xl-2 d-none d-xl-flex list-header-item" [ngClass]="{ 'justify-content-end': editable }">
      {{ 'shopping_cart.qty.heading' | translate }}
    </div>
    <div class="col-md-2 list-header-item column-price">{{ 'shopping_cart.price.heading' | translate }}</div>
    <div class="col-md-2 list-header-item column-price">{{ 'shopping_cart.total.heading' | translate }}</div>
  </div>
  <div class="list-body">
    <ish-line-item-list-element
      *ngFor="let pli of displayItems; index as i; trackBy: trackByFn"
      ishProductContext
      [sku]="pli.productSKU"
      [quantity]="pli.quantity.value"
      [allowZeroQuantity]="true"
      [pli]="pli"
      [lineItemViewType]="lineItemViewType"
      [editable]="editable && pli.editable"
    />
  </div>

  <div *ngIf="showPagingBar" class="row">
    <div class="d-flex w-100 grey-panel align-items-center flex-wrap justify-content-between px-3 py-2 mb-3">
      <span class="text-nowrap">{{ 'shopping_cart.paging.items.label' | translate : { '0': lineItems.length } }}</span>
      <ish-paging [currentPage]="currentPage" [lastPage]="lastPage" (goToPage)="goToPage($event)" />
    </div>
  </div>

  <div *ngIf="total?.value && lineItems.length > 0" class="clearfix section">
    <div class="row justify-content-end list-body">
      <div class="col-sm-4 col-md-3 col-lg-2 text-right pr-0">{{ 'quote.items.total.label' | translate }}</div>
      <div class="col-sm-4 col-md-2 pr-0">
        <div class="total-price text-right">{{ total | ishPrice }}</div>
      </div>
    </div>
  </div>
</div>
