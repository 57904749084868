<div class="logo-wrapper">
  <ish-lazy-content-include
    *ngIf="logoVersion === 'mobile'"
    includeId="camfil.include.header.mobileLogo.content.pagelet2-Include"
  />
  <ish-lazy-content-include
    *ngIf="logoVersion === 'full'"
    includeId="camfil.include.header.logo.content.pagelet2-Include"
  />
</div>
