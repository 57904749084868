import {
  ChangeDetectionStrategy, Component, createNgModule, OnInit, ViewChild, ViewContainerRef,
  Injector,
  ComponentRef,
  Input, OnChanges, 
  inject, DestroyRef,
  
} from '@angular/core';


  import { FeatureToggleService } from 'ish-core/feature-toggle.module';
  import { takeUntilDestroyed } from '@angular/core/rxjs-interop';


import type { TactonConfigureProductComponent as OriginalComponent } from '../../shared/tacton-configure-product/tacton-configure-product.component';

@Component({
  selector: 'ish-lazy-tacton-configure-product',
  templateUrl: './lazy-tacton-configure-product.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LazyTactonConfigureProductComponent implements OnInit , OnChanges {
  /*
   * WARNING!
   *
   * This file was automatically generated!
   * It should be updated using:
   *
   * ng g lazy-component extensions/tacton/shared/tacton-configure-product/tacton-configure-product.component.ts
   *
   */

  @ViewChild('anchor', { read: ViewContainerRef, static: true }) anchor: ViewContainerRef;


  @Input() displayType: OriginalComponent['displayType'];


  private component: ComponentRef<OriginalComponent>;
   private destroyRef = inject(DestroyRef);

  constructor(
    private featureToggleService: FeatureToggleService,
    private injector: Injector
  ) {}

   ngOnInit() {
     this.featureToggleService.enabled$('tacton').pipe(takeUntilDestroyed(this.destroyRef)).subscribe(async enabled => {
      if (enabled) {
    

      await this.renderComponent();

    
        } else {
          this.anchor.clear();
        }
      })
    
  }

  private async renderComponent() {
    const module = await import(`../../tacton.module`).then(m => m.TactonModule);

    const { TactonConfigureProductComponent: originalComponent } = await import('../../shared/tacton-configure-product/tacton-configure-product.component');

    const ngModuleRef = createNgModule(module, this.injector);

    this.component = this.anchor.createComponent(originalComponent, { ngModuleRef });
  
    this.ngOnChanges(
      
    );
  
    this.component.changeDetectorRef.markForCheck();
  }


  ngOnChanges() {
    if (this.component) {
      
        this.component.instance.displayType = this.displayType;
      

      
    }
  }

}
