/* eslint-disable no-unused-vars */
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CamfilCheckoutFacade } from 'camfil-core/facades/camfil-checkout.facade';

/* eslint-disable @typescript-eslint/dot-notation */

@Component({
  selector: 'camfil-buckets-validation-summary',
  templateUrl: './camfil-buckets-validation-summary.component.html',
  styleUrls: ['./camfil-buckets-validation-summary.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CamfilBucketsValidationSummaryComponent {
  constructor(private camfilCheckoutFacade: CamfilCheckoutFacade) {}

  camfilBuckets$ = this.camfilCheckoutFacade.camfilBuckets$;
  camfilInfoMessages$ = this.camfilCheckoutFacade.camfilBucketsInfoMessages$;
  camfilErrorMessages$ = this.camfilCheckoutFacade.camfilBucketsErrorMessages$;

  scrollTo(bucketId: string) {
    const element = document.getElementById(`bucket-${bucketId}`);

    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }
}
