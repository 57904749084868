import { AbstractControl, FormArray, FormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { isObject } from 'camfil-core/utils/functions';
import { addMonths } from 'date-fns';
import {
  Observable,
  ReplaySubject,
  Subject,
  combineLatest,
  debounceTime,
  distinctUntilChanged,
  merge,
  of,
  shareReplay,
  switchMap,
} from 'rxjs';
import { filter, tap } from 'rxjs/operators';

/**
 * Marks all fields in a form group as dirty recursively (i.e. for nested form groups also)
 *
 * @param formGroup The form group
 */
export function markAsTouchedRecursive(formGroup: FormGroup) {
  Object.keys(formGroup.controls).forEach(key => {
    if (formGroup.controls[key] instanceof FormGroup) {
      markAsTouchedRecursive(formGroup.controls[key] as FormGroup);
    } else {
      formGroup.controls[key].markAsTouched();
      formGroup.controls[key].updateValueAndValidity();
    }
  });
}

/**
 * Returns the top parent form of a field.
 * @example
 * const field = { form: new FormGroup({}) };
 * const topForm = findTopParentForm(field);
 * // topForm = FormGroup = {}
 */
export function findTopParentForm(field: FormlyFieldConfig): FormGroup | FormArray {
  let topForm = field.form;

  while (field.parent) {
    // eslint-disable-next-line no-param-reassign
    field = field.parent;
    if (field.form) {
      topForm = field.form;
    }
  }

  return topForm;
}

function addEmptyOptionIfNecessary<T>(field: FormlyFieldConfig, options: T[], emptyOptionLabel = '--') {
  let newOptions = [...options];
  let emptyOption: T;

  if (newOptions.length >= 1) {
    const firstOption = newOptions[0];
    if (isObject(firstOption)) {
      const keys = Object.keys(firstOption) as (keyof T)[];

      emptyOption = keys.reduce((acc, key) => {
        if (typeof field.props.labelProp === 'function') {
          acc[key] = field.props.labelProp(firstOption) ? (emptyOptionLabel as unknown as T[keyof T]) : undefined;
        } else {
          acc[key] = key === field.props.labelProp ? (emptyOptionLabel as unknown as T[keyof T]) : undefined;
        }
        return acc;
        // eslint-disable-next-line ish-custom-rules/no-object-literal-type-assertion
      }, {} as T);
    }

    if (typeof newOptions[0] === 'string' || typeof newOptions[0] === 'number') {
      emptyOption = emptyOptionLabel as unknown as T;
    }

    if (!findOptionByValueProp(newOptions, undefined, field.props.valueProp)) {
      newOptions = [emptyOption, ...newOptions];
    }
  }

  return newOptions;
}

export function findOptionByValueProp<T>(
  options: T[],
  value: string,
  valueProp: keyof T | ((option: T) => string)
): T | undefined {
  return options.find(option => {
    if (typeof valueProp === 'function') {
      return valueProp(option) === value;
    }
    return option[valueProp] === value;
  });
}

/**
 * Returns an observable of the value changes of a field.
 * The observable emits only when the value changes and not on initialization.
 * @param field
 * @param path
 * @example
 * const field = { formControl: new FormControl('foo') };
 * const valueChanges$ = valueChanges$(field);
 * // valueChanges$ = Observable<string> = { 'foo' } as Observable
 */
export function valueChanges$(field: FormlyFieldConfig, path?: string) {
  const form = findTopParentForm(field);
  const control = path ? form.get(path) : field.formControl;
  const initialValue = control?.value?.toString();

  return control.valueChanges.pipe(
    filter(v => v !== initialValue),
    distinctUntilChanged(distinctOptions)
  );
}

function handleBooleanOrStringArrayConfig(
  value: boolean | string[],
  field: FormlyFieldConfig,
  action: (path: AbstractControl | null) => void
) {
  if (!value) {
    return;
  }
  if (typeof value === 'boolean') {
    action(field.formControl);
  }
  if (Array.isArray(value)) {
    const form = findTopParentForm(field);
    value.forEach(path => {
      action(form.get(path));
    });
  }
}

function getSingleOptionValue<O>(field: FormlyFieldConfig, option: O): string {
  if (typeof field.props.valueProp === 'function') {
    return field.props.valueProp(option);
  }
  return option[field.props.valueProp as keyof O] as string;
}

function distinctOptions<R>(a: R[], b: R[]) {
  return JSON.stringify(a) === JSON.stringify(b);
}

interface GetOptionsConfig<R = unknown, T extends unknown[] = unknown[]> {
  field: FormlyFieldConfig;
  paths?: string[];
  observableFn(...values: T): Observable<R[]>;
  addEmptyOption?: boolean;
  autoSelectSingleOption?: boolean | string[];
  autoDisableSingleOption?: boolean | string[];
  autoDisableWhenInvalidOption?: boolean | string[];
  autoResetOnChanges?: boolean | string[];
  debounceTimeMs?: number;
}

export function getOptions$<R = unknown, T extends unknown[] = unknown[]>(
  config: GetOptionsConfig<R, T>
): Observable<R[]> {
  const { observableFn, debounceTimeMs = 500 } = config;
  const paths = config?.paths || [config?.field?.key as string];
  const form = config?.field?.form || findTopParentForm(config.field);
  const formControls: AbstractControl[] = paths.map(path => form.get(`${path.toString()}`)); // Replace with your method to find the top-most form
  const fetchedOptions$ = new ReplaySubject<R[]>(1);
  // Fetch options based on the initial model and update fetchedOptions$
  const initial$ = of(formControls.map(control => control.value)).pipe(
    switchMap(values => observableFn(...(values as T))),
    distinctUntilChanged(distinctOptions),
    tap(options => fetchedOptions$.next(transformOptions(options, config))),
    shareReplay(1)
  );

  const changes$ = combineLatest(formControls.map(control => control.valueChanges)).pipe(
    debounceTime(debounceTimeMs),
    switchMap(values => observableFn(...(values as T))),
    distinctUntilChanged(distinctOptions),
    tap(options => fetchedOptions$.next(transformOptions(options, config))),
    shareReplay(1)
  );

  return merge(initial$, changes$).pipe(
    switchMap(() => fetchedOptions$.asObservable()),
    shareReplay(1)
  );
}

function transformOptions<R>(options: R[], config: GetOptionsConfig<R>): R[] {
  let newOptions: R[] = [];

  if (!options) {
    return newOptions;
  }

  newOptions = [...options];

  if (config.addEmptyOption) {
    newOptions = addEmptyOptionIfNecessary(config.field, options);
  }

  handleOptionsSideEffects(newOptions, config);

  return newOptions;
}

function handleOptionsSideEffects<R>(options: R[], config: GetOptionsConfig<R>) {
  const {
    addEmptyOption,
    autoResetOnChanges,
    autoDisableSingleOption,
    autoSelectSingleOption,
    autoDisableWhenInvalidOption,
    field,
  } = config;

  const singleOptionIndex = addEmptyOption ? 1 : 0;
  const singleOptionLength = addEmptyOption ? 2 : 1;
  const isSingleOption = options.length === singleOptionLength;

  handleBooleanOrStringArrayConfig(autoResetOnChanges, field, control => control?.reset());

  handleBooleanOrStringArrayConfig(isSingleOption && autoSelectSingleOption, field, control => {
    const singleOptionValue = getSingleOptionValue(field, options[singleOptionIndex]);
    control?.setValue(singleOptionValue);
  });

  handleBooleanOrStringArrayConfig(autoDisableSingleOption, field, control => {
    if (isSingleOption) {
      control?.disable();
    } else {
      control?.enable();
    }
  });

  handleBooleanOrStringArrayConfig(autoDisableWhenInvalidOption, field, control => {
    if (control?.invalid) {
      control?.disable();
    } else {
      control?.enable();
    }
  });

  config.field.formControl.updateValueAndValidity();
}

/**
 * Updates the values of a field group based on the selected option, e.g. when a select field is changed
 * and the selected option has additional properties that should be set on the form group fields.
 * We skip the valueProp key, because this is already set by the select field, to avoid infinite loops.
 * @param config
 * @example
 * const options = [{ value: '1', label: 'One', additionalProperty: 'foo' }, { value: '2', label: 'Two' }];
 * const field = { props: { valueProp: 'value' } };
 * const option = { value: '1', label: 'One', additionalProperty: 'foo' };
 * updateFieldGroupValues({ field, option });
 * // field.value = '1'
 * // field.additionalProperty = 'foo'
 * // field.label = 'One'
 */
export function updateFieldGroupValues<T>(config: { field: FormlyFieldConfig; option: T; path?: string }) {
  if (!config.option) {
    return;
  }

  const form = findTopParentForm(config.field);
  const formFields = Object.keys(config?.option).filter(key => ![config.field.props.valueProp].includes(key));

  formFields.forEach(formField => {
    const name = [config?.path, formField].filter(Boolean).join('.');
    const newValue = config.option[formField as keyof T];

    if (form.get(name)) {
      form.get(name)?.setValue(newValue);
    }
  });
}

/**
 * Calculates the next delivery date based on the last delivery date and the delivery interval.
 * @param date
 * @param months
 * @returns The next delivery date string in ISO format
 * @example
 * const date = new Date('2021-01-31');
 * const months = 1;
 * const nextDeliveryDate = calculateNextDeliveryDate(date, months);
 * // nextDeliveryDate = '2021-02-28'
 */
// TODO (extMlk): the calculation interval unit should be configurable, ie month, week, day etc.
export function calculateNextDeliveryDate(date: string | number | Date, months: number): Date {
  try {
    const startDate = new Date(date);
    const nextDeliveryDate = addMonths(startDate, months);

    // If the day of the month isn't the same, set it to the last day of the previous month
    if (nextDeliveryDate.getDate() !== startDate.getDate()) {
      nextDeliveryDate.setDate(0);
    }

    return nextDeliveryDate;
  } catch (error) {
    console.error('Error while calculating next delivery date', error);
  }
}

export function createDestroy$() {
  const destroy$ = new Subject<void>();

  return {
    subject: destroy$,
    onDestroy: () => {
      destroy$.next();
      destroy$.complete();
    },
  };
}
