import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { routerNavigatedAction } from '@ngrx/router-store';
import { Store, select } from '@ngrx/store';
import { CamfilOrganizationManagementService } from 'camfil-core/services/organization/camfil-organization.service';
import { EMPTY, from, iif } from 'rxjs';
import { concatMap, filter, first, map, mergeMap, switchMap } from 'rxjs/operators';

import { displayErrorMessage, displaySuccessMessage } from 'ish-core/store/core/messages';
import { ofUrl, selectRouteParam } from 'ish-core/store/core/router';
import { mapErrorToAction, mapToPayload, mapToPayloadProperty, whenTruthy } from 'ish-core/utils/operators';

import {
  activateCustomerUser,
  activateCustomerUserFail,
  activateCustomerUserSuccess,
  connectContactWithUserAndCustomerFail,
  connectContactWithUserAndCustomerSuccess,
  createCustomerUser,
  createCustomerUserFail,
  createCustomerUserSuccess,
  deactivateCustomerUser,
  deactivateCustomerUserFail,
  deactivateCustomerUserSuccess,
  disconnectUserFromCustomer,
  disconnectUserFromCustomerFail,
  disconnectUserFromCustomerSuccess,
  loadCustomerUser,
  loadCustomerUserApprovers,
  loadCustomerUserApproversFail,
  loadCustomerUserApproversSuccess,
  loadCustomerUserFail,
  loadCustomerUserSuccess,
  loadCustomerUsers,
  loadCustomerUsersFail,
  loadCustomerUsersSuccess,
  loadOrganizationUsers,
  loadOrganizationUsersFail,
  loadOrganizationUsersSuccess,
  resetCustomerUserPassword,
  resetCustomerUserPasswordFail,
  resetCustomerUserPasswordSuccess,
  selectUser,
  updateCustomerUser,
  updateCustomerUserApprovers,
  updateCustomerUserApproversFail,
  updateCustomerUserApproversSuccess,
  updateCustomerUserFail,
  updateCustomerUserSuccess,
} from './b2b-users.actions';
import { getSelectedUserId, getUser } from './b2b-users.selectors';

@Injectable()
export class B2bUsersEffects {
  constructor(
    private actions$: Actions,
    private managementService: CamfilOrganizationManagementService,
    private store: Store,
    private router: Router
  ) {}

  loadCustomerUsers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadCustomerUsers),
      mapToPayloadProperty('customerId'),
      whenTruthy(),
      mergeMap(customerId =>
        this.managementService.getCustomerUsers(customerId).pipe(
          map(users => loadCustomerUsersSuccess({ customerId, users })),
          mapErrorToAction(loadCustomerUsersFail)
        )
      )
    )
  );

  routeListenerForSelectingUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(routerNavigatedAction),
      switchMap(() =>
        this.store.pipe(
          ofUrl(/^\/(account\/organization\/customers\/.*\/users\/.*)/),
          select(selectRouteParam('CamfilB2BUserId')),
          concatLatestFrom(() => this.store.pipe(select(getSelectedUserId))),
          filter(([fromAction, selectedUserId]) => fromAction && fromAction !== selectedUserId),
          map(([userId]) => userId),
          map(userId => selectUser({ userId }))
        )
      )
    )
  );

  loadCustomerUserForSelectedCustomerIdAndSelectedUserId$ = createEffect(() =>
    iif(
      () => !SSR,
      this.actions$.pipe(
        ofType(selectUser),
        mapToPayloadProperty('userId'),
        whenTruthy(),
        switchMap(userId => this.store.pipe(select(getUser(userId)), whenTruthy(), first())),
        map(user => loadCustomerUser({ customerId: user.customers[0].id, userId: user.id }))
      ),
      EMPTY
    )
  );

  loadCustomerUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadCustomerUser),
      mapToPayload(),
      concatMap(({ customerId, userId }) =>
        this.managementService.getCustomerUser(customerId, userId).pipe(
          map(user => loadCustomerUserSuccess({ customerId, user })),
          mapErrorToAction(loadCustomerUserFail, { customerId, userId })
        )
      )
    )
  );

  activateCustomerUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(activateCustomerUser),
      mapToPayload(),
      switchMap(({ customerId, userId }) =>
        this.managementService.updateCustomerUserActiveFlag(customerId, userId, true).pipe(
          map(active =>
            activateCustomerUserSuccess({
              customerId,
              userId,
              active,
              successMessage: 'camfil.account.organization.edit_user.unlock_user.modal.text',
            })
          ),
          mapErrorToAction(activateCustomerUserFail, { customerId, userId, value: true })
        )
      )
    )
  );

  deactivateCustomerUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deactivateCustomerUser),
      mapToPayload(),
      switchMap(({ customerId, userId }) =>
        this.managementService.updateCustomerUserActiveFlag(customerId, userId, false).pipe(
          map(active =>
            deactivateCustomerUserSuccess({
              customerId,
              userId,
              active,
              successMessage: 'camfil.account.organization.edit_user.lock_user.modal.text',
            })
          ),
          mapErrorToAction(deactivateCustomerUserFail, { customerId, userId, value: false })
        )
      )
    )
  );

  updateCustomerUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateCustomerUser),
      mapToPayload(),
      concatMap(({ customer, user }) =>
        this.managementService.updateCustomerUser(customer, user).pipe(
          map(changedUser =>
            updateCustomerUserSuccess({
              customer,
              user: changedUser,
              successMessage: 'camfil.account.organization.user_details.form.update.success.message',
            })
          ),
          mapErrorToAction(updateCustomerUserFail)
        )
      )
    )
  );

  createCustomerUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createCustomerUser),
      mapToPayload(),
      mergeMap(({ customer, user, contacts, roles, approvers }) =>
        this.managementService.createCustomerUser(customer, user, contacts, roles, approvers).pipe(
          map(createdUser =>
            createCustomerUserSuccess({
              customer,
              user: createdUser,
              customerId: contacts[0].customer?.parentCustomer?.id || contacts[0].customer.id,
              successMessage: 'camfil.account.organization.user_details.form.update.success.message',
            })
          ),
          mapErrorToAction(createCustomerUserFail)
        )
      )
    )
  );

  redirectAfterCreateCustomerUser$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(createCustomerUserSuccess),
        mapToPayload(),
        concatMap(({ customerId, user }) => this.navigateTo(`../customers/${customerId}/users/${user.id}`))
      ),
    { dispatch: false }
  );

  resetCustomerUserPassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(resetCustomerUserPassword),
      mapToPayload(),
      concatMap(({ customerId, userId, login }) =>
        this.managementService.resetCustomerUserPassword(customerId, userId, login).pipe(
          map(() =>
            resetCustomerUserPasswordSuccess({
              customerId,
              userId,
              login,
              successMessage: 'camfil.account.organization.user_details.form.change_password.success.message',
            })
          ),
          mapErrorToAction(resetCustomerUserPasswordFail)
        )
      )
    )
  );

  displayUpdateCustomerUserSuccessMessage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        activateCustomerUserSuccess,
        deactivateCustomerUserSuccess,
        updateCustomerUserSuccess,
        resetCustomerUserPasswordSuccess,
        createCustomerUserSuccess,
        disconnectUserFromCustomerSuccess,
        connectContactWithUserAndCustomerSuccess
      ),
      mapToPayloadProperty('successMessage'),
      filter(successMessage => !!successMessage),
      map(successMessage =>
        displaySuccessMessage({
          message: successMessage,
        })
      )
    )
  );

  displayUpdateCustomerUserFailMessage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        activateCustomerUserFail,
        deactivateCustomerUserFail,
        updateCustomerUserFail,
        resetCustomerUserPasswordFail,
        createCustomerUserFail,
        disconnectUserFromCustomerFail,
        connectContactWithUserAndCustomerFail
      ),
      mapToPayloadProperty('error'),
      whenTruthy(),
      map(error =>
        displayErrorMessage({
          message: error?.message || error?.code,
        })
      )
    )
  );

  disconnectUserFromCustomer$ = createEffect(() =>
    this.actions$.pipe(
      ofType(disconnectUserFromCustomer),
      mapToPayload(),
      switchMap(({ customerId, userId }) =>
        this.managementService.disconnectUserFromCustomerPlusReload(customerId, userId).pipe(
          map(user =>
            disconnectUserFromCustomerSuccess({
              customerId,
              userId,
              user,
              successMessage: 'camfil.account.organization.edit_user.disconnect_user_from_customer.modal.text',
            })
          ),
          mapErrorToAction(disconnectUserFromCustomerFail, { customerId, userId })
        )
      )
    )
  );

  loadOrganizationUsers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadOrganizationUsers),
      mapToPayload(),
      switchMap(({ customerIDs }) =>
        this.managementService.getOrganizationUsers(customerIDs).pipe(
          map(users =>
            loadOrganizationUsersSuccess({
              customerIDs,
              users,
            })
          ),
          mapErrorToAction(loadOrganizationUsersFail, { customerIDs })
        )
      )
    )
  );

  loadCustomerUserApprovers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadCustomerUserApprovers),
      mapToPayload(),
      concatMap(({ customerId, userId }) =>
        this.managementService.getCustomerUserApprovers(customerId, userId).pipe(
          map(approvers => loadCustomerUserApproversSuccess({ customerId, userId, approvers })),
          mapErrorToAction(loadCustomerUserApproversFail, { customerId, userId })
        )
      )
    )
  );

  updateCustomerUserApprovers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateCustomerUserApprovers),
      mapToPayload(),
      concatMap(({ customerId, userId, approversIds }) =>
        this.managementService.setCustomerUserApprovers(customerId, userId, approversIds).pipe(
          map(approvers => updateCustomerUserApproversSuccess({ customerId, userId, approvers })),
          mapErrorToAction(updateCustomerUserApproversFail, { customerId, userId })
        )
      )
    )
  );

  private navigateTo(path: string) {
    let currentRoute = this.router.routerState.root;

    while (currentRoute.firstChild) {
      currentRoute = currentRoute.firstChild;
    }

    return from(
      this.router.navigate([path], { relativeTo: currentRoute }).then(() => {
        // noop
      })
    );
  }
}
