import { ChangeDetectionStrategy, Component, DestroyRef, HostBinding, Inject, OnInit, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { CamfilAccountFacade } from 'camfil-core/facades/camfil-account.facade';
import { CamfilConfigurationFacade } from 'camfil-core/facades/camfil-configuration.facade';
import { CamfilCustomersFacade } from 'camfil-core/facades/camfil-customers.facade';
import { CamfilAddress } from 'camfil-models/camfil-address/camfil-address.model';
import { EditCamfilBucket } from 'camfil-models/camfil-bucket/camfil-bucket.model';
import { CamfilContact, CamfilCustomer } from 'camfil-models/camfil-customer/camfil-customer.model';
import { CamfilLangHelper } from 'camfil-models/camfil-lang/camfil-lang.helper';
import { CamfilMaxLengthTypes } from 'camfil-models/camfil-max-length-validator/camfil-max-length.types';
import { CamfilFormsService } from 'camfil-shared/formly/utils/forms.service';
import { Observable, combineLatest, map, of, switchMap, take } from 'rxjs';

import { RoleToggleService } from 'ish-core/role-toggle.module';

export type CamfilOrderFormDialogData = EditCamfilBucket;

export type CamfilOrderFormDialogResult = string;

export interface CamfilBucketEditFormData {
  formData: CamfilOrderFormData;
  additionalOrderData: NonEditableOrderData;
}

export interface CamfilOrderFormData {
  goodsAcceptanceNote: string;
  customer: string;
  contact: string;
  orderMark?: string;
  invoiceLabel?: string;
  phoneNumber?: string;
  company?: string;
  addressLine1: string;
  addressLine2?: string;
  zipcode: string;
  city: string;
  info?: string;
}

export interface NonEditableOrderData {
  selectedContact: CamfilContact;
  selectedCustomer: CamfilCustomer;
  addressFull: CamfilAddress;
  selectedAddress?: CamfilAddress;
}

@Component({
  selector: 'camfil-order-form',
  templateUrl: './camfil-order-form-dialog.component.html',
  styleUrls: ['./camfil-order-form-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CamfilOrderFormDialogComponent implements OnInit {
  @HostBinding('class.camfil-dialog') camfilDialogClass = true;

  form = new UntypedFormGroup({});
  fields: FormlyFieldConfig[];
  submitted = false;

  customers$: Observable<CamfilCustomer[]>;
  contacts$: Observable<CamfilContact[]>;
  addresses$: Observable<CamfilAddress[]>;

  additionalOrderData: NonEditableOrderData;
  formHeader$: Observable<string>;
  formActionText$: Observable<string>;
  formOrderMarkLabel$: Observable<string>;

  zipCodesLoading$: Observable<boolean>;

  private maxLengthValues = CamfilMaxLengthTypes;

  private destroyRef = inject(DestroyRef);

  constructor(
    private accountFacade: CamfilAccountFacade,
    private customersFacade: CamfilCustomersFacade,
    private roleToggleService: RoleToggleService,
    private translate: TranslateService,
    private camfilConfigurationFacade: CamfilConfigurationFacade,
    public matDialogRef: MatDialogRef<CamfilOrderFormDialogComponent, CamfilOrderFormDialogResult>,
    // eslint-disable-next-line ish-custom-rules/use-type-safe-injection-token
    @Inject(MAT_DIALOG_DATA) public dialogData: CamfilOrderFormDialogData
  ) {}

  ngOnInit() {
    this.customers$ = this.customersFacade.customers$;

    this.loadCustomerData(this.dialogData?.customerId);

    this.additionalOrderData = {
      selectedContact: undefined,
      selectedCustomer: undefined,
      addressFull: undefined,
    };

    this.fields = this.getFields();

    const roles$ = combineLatest([
      this.roleToggleService.hasRole('APP_B2B_REQUEST_QUOTATION'),
      this.roleToggleService.hasRole('APP_B2B_NEEDS_APPROVAL'),
    ]);

    const baseText = this.dialogData
      ? 'camfil.dynamic.checkout.cta.edit_details'
      : 'camfil.modal.addToCart.button.create_new_order';

    this.formHeader$ = roles$.pipe(map(CamfilLangHelper.mapRoleToText(baseText)));
    // Translation exception on order edit button
    this.formActionText$ = roles$
      .pipe(map(CamfilLangHelper.mapRoleToText(baseText)))
      .pipe(
        map(mappedText =>
          mappedText === 'camfil.dynamic.checkout.cta.edit_details'
            ? 'account.order_templates.edit_form.save_button.text'
            : mappedText
        )
      );

    this.formOrderMarkLabel$ = roles$.pipe(
      map(CamfilLangHelper.mapRoleToText('camfil.dynamic.modal.addToCamcard.camcard.order_mark')),
      takeUntilDestroyed(this.destroyRef)
    );

    this.zipCodesLoading$ = this.accountFacade.zipCodesLoading$;
  }

  pickCustomerAndContact(customerId: string) {
    this.customers$.pipe(take(1), takeUntilDestroyed(this.destroyRef)).subscribe(customers => {
      const selectedCustomer = customers.find(customer => customer.id === customerId);
      const selectedContact = selectedCustomer.userContact;

      this.form?.patchValue({
        contact: selectedContact?.erpId,
      });

      this.additionalOrderData.selectedCustomer = selectedCustomer;
      this.additionalOrderData.selectedContact = selectedContact;
      this.additionalOrderData.addressFull = undefined;
    });
  }

  loadCustomerData(customerId: string) {
    if (customerId) {
      this.contacts$ = this.customersFacade.getContactsByCustomer$(customerId);
      this.addresses$ = this.customersFacade.getDeliveryAddressesForCustomer$(customerId);
    } else {
      this.contacts$ = of([]);
      this.addresses$ = of([]);
    }
  }

  pickContact(contactId: string) {
    this.contacts$.pipe(take(1), takeUntilDestroyed(this.destroyRef)).subscribe(contacts => {
      this.additionalOrderData.selectedContact = contacts.find(contact => contact.erpId === contactId);
    });
  }

  pickAddress(address: CamfilAddress | undefined) {
    this.customers$.pipe(take(1), takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      this.form?.patchValue({
        company: address?.companyName1,
        addressLine1: address?.addressLine1,
        addressLine2: address?.addressLine2,
        zipcode: address?.postalCode,
        goodsAcceptanceNote: address?.goodsAcceptanceNote,
      });

      this.additionalOrderData.addressFull = address;
    });
  }

  private getFields(): FormlyFieldConfig[] {
    return [
      {
        fieldGroup: [
          {
            key: 'customer',
            type: 'camfil-select-field',
            props: {
              label: 'camfil.modal.createOrder.order-form.select.customer.label',
              placeholder: 'camfil.modal.createOrder.order-form.select.customer',
              options: CamfilFormsService.getCamfilCustomersOptions(this.customers$),
              required: true,
              hideRequiredMarker: true,
            },
            validation: {
              messages: {
                required: 'camfil.modal.createOrder.order-form.select.customer.error.required',
              },
            },
            hooks: {
              onInit: (field: FormlyFieldConfig) => {
                field.form
                  .get('customer')
                  .valueChanges.pipe(takeUntilDestroyed(this.destroyRef))
                  .subscribe((value: string) => {
                    if (value) {
                      this.loadCustomerData(value);
                      this.pickCustomerAndContact(value);
                    }
                  });

                if (this.dialogData?.customerId) {
                  field.formControl.setValue(this.dialogData.customerId);
                } else {
                  this.customers$.pipe(take(1), takeUntilDestroyed(this.destroyRef)).subscribe(customers => {
                    if (customers?.length === 1) {
                      field.formControl.setValue(customers[0].id);
                    }
                  });
                }
              },
            },
          },
          {
            key: 'contact',
            type: 'camfil-select-field',
            props: {
              label: 'camfil.modal.createOrder.order-form.input.contact',
              required: true,
              hideRequiredMarker: true,
            },
            hooks: {
              onInit: (field: FormlyFieldConfig) => {
                field.props.options = CamfilFormsService.getCamfilContactsOptions(this.contacts$);

                field.form
                  .get('customer')
                  .valueChanges.pipe(takeUntilDestroyed(this.destroyRef))
                  .subscribe((value: string) => {
                    if (value) {
                      field.props.options = CamfilFormsService.getCamfilContactsOptions(this.contacts$);
                      this.pickCustomerAndContact(value);
                    }
                  });

                if (this.dialogData) {
                  field.formControl.setValue(this.dialogData.contactPerson);
                }

                field.form
                  .get('contact')
                  .valueChanges.pipe(takeUntilDestroyed(this.destroyRef))
                  .subscribe((value: string) => {
                    if (value) {
                      this.pickContact(value);
                    }
                  });
              },
            },
            validation: {
              messages: {
                required: 'camfil.modal.createOrder.order-form.select.contact_person.error.required',
              },
            },
          },
          {
            key: 'orderMark',
            type: 'camfil-text-input-field',
            defaultValue: this.dialogData?.orderMark,
            validators: {
              validation: [Validators.maxLength(this.maxLengthValues.OrderMark)],
            },
            validation: {
              messages: {
                maxlength: 'camfil.checkout.order_header.order_mark.error.maxLength',
              },
            },
            hooks: {
              onInit: (field: FormlyFieldConfig) => {
                this.formOrderMarkLabel$
                  .pipe(
                    switchMap(labelKey => this.translate.get(labelKey)),
                    takeUntilDestroyed(this.destroyRef)
                  )
                  .subscribe(label => {
                    field.props.label = label;
                  });
              },
            },
          },
          {
            key: 'invoiceLabel',
            type: 'camfil-text-input-field',
            defaultValue: this.dialogData?.invoiceLabel,
            props: {
              label: 'camfil.modal.createOrder.order-form.input.invoice_mark',
            },
            validators: {
              validation: [Validators.maxLength(this.maxLengthValues.InvoiceLabel)],
            },
            validation: {
              messages: {
                maxlength: 'camfil.checkout.order_header.invoice_label.error.maxLength',
              },
            },
          },
          {
            key: 'phoneNumber',
            type: 'camfil-phone-field',
            defaultValue: this.dialogData?.phoneNumber,
            props: {
              label: 'camfil.account.apply_form.phoneNumber',
            },
            validators: {
              validation: [Validators.maxLength(this.maxLengthValues.PhoneNumber)],
            },
            validation: {
              messages: {
                phone: 'camfil.modal.createOrder.order-form.input.phone_number.error.pattern',
                required: 'camfil.modal.createOrder.order-form.input.phone_number.error.pattern',
                maxlength: 'camfil.modal.createOrder.order-form.input.phone_number.error.pattern',
              },
            },
          },
          {
            key: 'deliveryAddressSelect',
            type: 'camfil-select-field',
            defaultValue: this.dialogData?.deliveryAddressId,
            props: {
              label: 'camfil.modal.createOrder.order-form.select.address',
              options: [],
            },
            hooks: {
              onInit: (field: FormlyFieldConfig) => {
                // First, fetch the addresses and options and process them
                this.addresses$
                  .pipe(
                    switchMap(addresses => {
                      const selectedAddress = addresses.find(
                        address => address.id === this.dialogData?.deliveryAddressId
                      );

                      return CamfilFormsService.getDeliveryAddressOptions(this.addresses$).pipe(
                        take(1),
                        map(options => {
                          const updatedOptions = [...options];

                          if (selectedAddress) {
                            updatedOptions.unshift({
                              label: selectedAddress.addressLine1,
                              value: selectedAddress.id,
                            });
                          } else if (this.dialogData?.addressLine1) {
                            updatedOptions.unshift({
                              label: this.dialogData.addressLine1,
                              value: this.dialogData.deliveryAddressId || 'temp-address',
                            });
                          }

                          return updatedOptions;
                        })
                      );
                    }),
                    takeUntilDestroyed(this.destroyRef)
                  )
                  .subscribe(options => {
                    field.props.options = options;

                    if (this.dialogData) {
                      field.formControl.setValue(this.dialogData.deliveryAddressId || 'temp-address');
                    }
                  });

                // Handle customer changes
                field.form
                  .get('customer')
                  .valueChanges.pipe(takeUntilDestroyed(this.destroyRef))
                  .subscribe(value => {
                    field.form.get('deliveryAddressSelect').setValue(undefined);
                    if (value) {
                      CamfilFormsService.getDeliveryAddressOptions(this.addresses$)
                        .pipe(takeUntilDestroyed(this.destroyRef))
                        // eslint-disable-next-line rxjs/no-nested-subscribe
                        .subscribe(options => {
                          field.props.options = options;
                        });
                    }
                  });

                // Handle address selection changes
                field.form
                  .get('deliveryAddressSelect')
                  .valueChanges.pipe(
                    switchMap(value =>
                      this.addresses$.pipe(
                        take(1),
                        map(addresses => ({ value, addresses })),
                        takeUntilDestroyed(this.destroyRef)
                      )
                    ),
                    takeUntilDestroyed(this.destroyRef)
                  )
                  .subscribe(({ value, addresses }) => {
                    if (value === '') {
                      this.pickAddress(undefined);
                    } else if (value) {
                      const selectedAddress = addresses.find(element => element.id === value);
                      this.pickAddress(selectedAddress);
                    }
                  });
              },
            },
          },
          {
            key: 'company',
            type: 'camfil-text-input-field',
            defaultValue: this.dialogData?.company,
            props: {
              label: 'camfil.modal.createOrder.order-form.input.recipient',
              required: true,
            },
            validators: {
              validation: [Validators.maxLength(this.maxLengthValues.Company)],
            },
            validation: {
              messages: {
                required: 'camfil.modal.createCamcard.input.company.error.required',
              },
            },
          },
          {
            key: 'addressLine1',
            type: 'camfil-textarea-field',
            defaultValue: this.dialogData?.addressLine1,
            props: {
              label: 'camfil.modal.createOrder.order-form.input.addressLine1',
              required: true,
              autosize: false,
            },
            validation: {
              messages: {
                required: 'camfil.modal.createOrder.order-form.input.addressLine1.error.required',
              },
            },
            expressions: {
              'props.rows': this.camfilConfigurationFacade
                .isEnabled$('use2ndAddressLineInOrderForm')
                .pipe(map(v => (v ? 3 : 5))),
            },
          },
          {
            key: 'addressLine2',
            type: 'camfil-textarea-field',
            defaultValue: this.dialogData?.addressLine2,
            props: {
              label: 'account.default_address.street2.label',
              rows: 3,
              autosize: false,
            },
            expressions: {
              hide: this.camfilConfigurationFacade.isEnabled$('use2ndAddressLineInOrderForm').pipe(map(v => !v)),
            },
          },
          {
            fieldGroupClassName: 'd-flex justify-content-between',
            fieldGroup: [
              {
                key: 'zipcode',
                type: 'camfil-postal-code-input-field',
                defaultValue: this.dialogData?.zipCode,
                className: 'w-50 asdf',
                props: {
                  label: 'camfil.account.cam_card.add_to_form.postal_code',
                  required: true,
                },
                validation: {
                  messages: {
                    required: 'camfil.account.apply_form.zipCode.error.required',
                    zipCodePattern: 'camfil.account.apply_form.zipCode.error.pattern',
                    incorrectZipCode: 'camfil.account.apply_form.zipCode.error.invalid',
                  },
                },
              },
              {
                key: 'city',
                type: 'camfil-city-dynamic-field',
                defaultValue: this.dialogData?.city,
                className: 'w-50 asdf',
                props: {
                  label: 'camfil.form.pick_city',
                  required: true,
                  hideRequiredMarker: true,
                },
                validation: {
                  messages: {
                    required: 'camfil.modal.createOrder.order-form.input.city.error.required',
                  },
                },
              },
            ],
          },
          {
            key: 'info',
            type: 'camfil-textarea-field',
            defaultValue: this.dialogData?.info,
            props: {
              label: 'camfil.modal.createOrder.order-form.info',
              rows: 2,
              autosize: true,
            },
            validators: {
              validation: [Validators.maxLength(150)],
            },
            validation: {
              messages: {
                maxlength: 'camfil.checkout.order_header.note.error.maxLength',
              },
            },
          },
          {
            key: 'goodsAcceptanceNote',
            type: 'camfil-text-input-field',
            defaultValue: this.dialogData?.goodsAcceptanceNote,
            props: {
              label: 'camfil.checkout.cta.select_goods_acceptace.header',
            },
            validators: {
              validation: [Validators.maxLength(this.maxLengthValues.GoodsAcceptanceNote)],
            },
            validation: {
              messages: {
                maxlength: 'camfil.checkout.goods_acceptance.error.maxLength',
              },
            },
            expressions: {
              hide: this.camfilConfigurationFacade.isEnabled$('allowToSelectGoodsAcceptanceTimes').pipe(map(v => !v)),
            },
          },
        ],
        fieldGroupClassName: 'formly-wrapper',
      },
    ];
  }

  submitForm() {
    if (this.form.invalid) {
      this.submitted = true;
      Object.keys(this.form.controls).forEach(fieldKey => {
        const control = this.form.get(fieldKey);
        // TODO fix duplication if possible (Agustin)
        control.markAsTouched();
        control.markAsDirty();
      });
      return;
    }

    const data = {
      formData: this.form.value,
      additionalOrderData: this.additionalOrderData,
    };

    // TODO refactor data object: open is generic so there's no need to pass string and JSON.parse
    const dataString = JSON.stringify(data);
    this.additionalOrderData = undefined;
    this.matDialogRef.close(dataString);
    this.form.reset();
    this.form.markAsPristine();
  }

  get buttonDisabled() {
    return this.form.invalid && this.submitted;
  }
}
