import { createAction } from '@ngrx/store';
import { UnitFilter } from 'camfil-models/camfil-ahu/unit-filter/unit-filter.model';

import { httpError, payload } from 'ish-core/utils/ngrx-creators';

export const loadAhuUnitFilter = createAction(
  '[AHU Unit Internal] Load AHU Unit Filter',
  payload<{ manufacturerId: string; unitId: number }>()
);

export const loadAhuUnitFilterIfNotLoaded = createAction(
  '[AHU Unit Internal] Load AHU Unit Filter If Not Loaded',
  payload<{ manufacturerId: string; unitId: number }>()
);

export const loadAhuUnitFilterSuccess = createAction(
  '[AHU Unit API] Load AHU Unit Filter Success',
  payload<{ unitFilter: UnitFilter }>()
);

export const loadAhuUnitFilterFail = createAction('[AHU Unit API] Load AHU Unit Filter Fail', httpError());
