import { ChangeDetectionStrategy, Component, DestroyRef, Input, OnInit, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { CamRequisitionManagementFacade } from 'camfil-core/facades/cam-requisition-management.facade';
import { CamfilAccountFacade } from 'camfil-core/facades/camfil-account.facade';
import { Observable } from 'rxjs';

import { whenTruthy } from 'ish-core/utils/operators';

@Component({
  selector: 'camfil-requisition-checkout-button',
  templateUrl: './camfil-requisition-checkout-button.component.html',
  styleUrls: ['./camfil-requisition-checkout-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CamfilRequisitionCheckoutButtonComponent implements OnInit {
  @Input() disabledButton$: Observable<boolean>;
  permissions: string[];
  approvalRequired = false;

  private destroyRef = inject(DestroyRef);

  constructor(
    private accountFacade: CamfilAccountFacade,
    private camRequisitionManagementFacade: CamRequisitionManagementFacade
  ) {}
  ngOnInit() {
    this.accountFacade.userPermissions$
      ?.pipe(whenTruthy(), takeUntilDestroyed(this.destroyRef))
      .subscribe(permissions => {
        this.permissions = permissions;
        if (permissions?.includes('APP_B2B_MAKE_REQUISITION')) {
          this.approvalRequired = true;
        }
      });
  }

  submit() {
    if (this.approvalRequired) {
      this.camRequisitionManagementFacade.createCamfilRequisition();
    }
  }
}
