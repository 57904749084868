<mat-form-field class="w-100">
  <mat-select
    [value]="selectedFacets"
    [placeholder]="placeholder"
    (selectionChange)="apply($event.value)"
    [multiple]="multipleSelection"
  >
    <mat-select-trigger>
      {{ placeholder }}
    </mat-select-trigger>
    <mat-option *ngFor="let facet of filterElement.facets; trackBy: trackByFn" [value]="facet">
      {{ facet.displayName }}
      <span *ngIf="facet.count">({{ facet.count }})</span>
    </mat-option>
  </mat-select>
</mat-form-field>
