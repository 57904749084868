import { createSelector } from '@ngrx/store';

import { getCamfilFeatureState } from '../camfil-store';

import {
  State,
  camfilOrderTrackAndTraceAdapter,
  camfilOrderTrackAndTraceFeatureKey,
} from './camfil-order-track-and-trace.reducer';

const selectCamfilOrderTrackAndTraceState = getCamfilFeatureState<State>(camfilOrderTrackAndTraceFeatureKey);

export const { selectAll: getAllCamfilOrderTrackAndTrace } = camfilOrderTrackAndTraceAdapter.getSelectors(
  selectCamfilOrderTrackAndTraceState
);

export const getCamfilOrderTrackAndTraceByOwnerId = (ownerId: string) =>
  createSelector(getAllCamfilOrderTrackAndTrace, trackAndTrace => trackAndTrace.filter(t => t.ownerId === ownerId));
