import { Pipe, PipeTransform } from '@angular/core';
import { CamfilAttributeHelper } from 'camfil-models/camfil-attribute/attribute.helper';
import { CamCardMeasurement } from 'camfil-models/camfil-cam-card/cam-card.model';

@Pipe({
  name: 'camfilMeasurement',
})
export class CamfilMeasurementPipe implements PipeTransform {
  transform(measurementObj: CamCardMeasurement, separator = 'x'): string {
    if (!measurementObj) {
      return;
    }

    const { width, height, depth } = measurementObj;

    const measurementValues = [width, height, depth].map(v => v?.toString()).filter(Boolean);

    const hasDepthOnly = measurementValues?.length === 1 && !!depth?.toString();
    const hasNoValues = measurementValues?.length === 0;

    if (hasDepthOnly || hasNoValues) {
      return;
    }

    return CamfilAttributeHelper.formatMeasurements(measurementValues.map(Number), separator);
  }
}
