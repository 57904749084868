import { Injectable } from '@angular/core';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { getOptions$ } from 'camfil-shared/formly/utils/camfil-form-utils';
import { of } from 'rxjs';

import { FieldLibraryConfiguration } from 'ish-shared/formly/field-library/configurations/field-library-configuration';

@Injectable()
export class DeliveryIntervalConfiguration extends FieldLibraryConfiguration {
  id = 'deliveryIntervalFLC';

  getFieldConfig(): FormlyFieldConfig {
    return {
      key: 'deliveryInterval',
      type: 'camfil-autocomplete-input-field',
      props: {
        label: 'camfil.account.cam_card.add_to_form.delivery_interval',
        required: false,
        type: 'number',
        max: 1000,
        min: 0,
        intervalUnit: 'month', // TODO (extMlk): not implemented,  make an effect in translation and in calculation w/ calculateNextDeliveryDate
      },
      validation: {
        messages: {
          min: 'camfil.account.cam_card.form.delivery_interval.error',
          max: 'camfil.account.cam_card.form.delivery_interval.error',
        },
      },
      hooks: {
        onInit: (field: FormlyFieldConfig) => {
          field.props.options = getOptions$({
            field,
            addEmptyOption: true,
            observableFn: () => of(Array.from(Array(field.props.max).keys()).map(i => (i + 1).toString())),
          });
        },
      },
    };
  }
}
