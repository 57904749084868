import { CommonModule, NgOptimizedImage } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbCollapseModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { CamfilDirectivesModule } from 'camfil-core/camfil-directives.module';
import { CamfilPipesModule } from 'camfil-core/camfil-pipes.module';
import { CamfilTrackingModule } from 'camfil-core/camfil-tracking.module';
import { CamfilMaterialModule } from 'camfil-core/material/camfil-material.module';
import { CamfilContentByRoleComponent } from 'camfil-shared/components/common/camfil-content-by-role/camfil-content-by-role.component';
import { CamfilLoadingComponent } from 'camfil-shared/components/common/camfil-loading/camfil-loading.component';
import { CamfilAddCamCardFeedbackDialogComponent } from 'camfil-shell/footer/camfil-add-cam-card-feedback-dialog/camfil-add-cam-card-feedback-dialog.component';
import { CamfilFooterComponent } from 'camfil-shell/footer/camfil-footer/camfil-footer.component';
import { CamfilBreadcrumbComponent } from 'camfil-shell/header/camfil-breadcrumb/camfil-breadcrumb.component';
import { CamfilHeaderNavigationComponent } from 'camfil-shell/header/camfil-header-navigation/camfil-header-navigation.component';
import { CamfilHeaderComponent } from 'camfil-shell/header/camfil-header/camfil-header.component';
import { CamfilLanguageSwitchComponent } from 'camfil-shell/header/camfil-language-switch/camfil-language-switch.component';
import { CamfilLoginStatusComponent } from 'camfil-shell/header/camfil-login-status/camfil-login-status.component';
import { CamfilLogoComponent } from 'camfil-shell/header/camfil-logo/camfil-logo.component';
import { CamfilMiniBasketComponent } from 'camfil-shell/header/camfil-mini-basket/camfil-mini-basket.component';
import { CamfilProductCompareStatusComponent } from 'camfil-shell/header/camfil-product-compare-status/camfil-product-compare-status.component';
import { CamfilSubcategoryNavigationComponent } from 'camfil-shell/header/camfil-subcategory-navigation/camfil-subcategory-navigation.component';

import { FeatureToggleModule } from 'ish-core/feature-toggle.module';
import { IconModule } from 'ish-core/icon.module';
import { PipesModule } from 'ish-core/pipes.module';
import { RoleToggleModule } from 'ish-core/role-toggle.module';
import { FormlyModule } from 'ish-shared/formly/formly.module';
import { ShellModule } from 'ish-shell/shell.module.camfil';

import { CamfilPwaExportsModule } from '../exports/camfil-pwa-exports.module';

const standaloneComponents = [CamfilLoadingComponent, CamfilContentByRoleComponent];

const exportedComponents = [
  CamfilAddCamCardFeedbackDialogComponent,
  CamfilBreadcrumbComponent,
  CamfilFooterComponent,
  CamfilHeaderComponent,
  CamfilHeaderNavigationComponent,
  CamfilLanguageSwitchComponent,
  CamfilLoginStatusComponent,
  CamfilLogoComponent,
  CamfilMiniBasketComponent,
  CamfilSubcategoryNavigationComponent,
  CamfilProductCompareStatusComponent,
];

@NgModule({
  imports: [
    ...standaloneComponents,
    CamfilDirectivesModule,
    CamfilMaterialModule,
    CamfilPipesModule,
    CamfilPwaExportsModule,
    CamfilTrackingModule,
    CommonModule,
    FeatureToggleModule,
    FormlyModule,
    IconModule,
    NgbCollapseModule,
    NgOptimizedImage,
    PipesModule,
    ReactiveFormsModule,
    RoleToggleModule,
    RouterModule,
    ShellModule,
    TranslateModule,
  ],
  declarations: [...exportedComponents],
  exports: [...exportedComponents, ...standaloneComponents],
})
export class CamfilShellModule {}
