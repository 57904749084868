import { NgModule } from '@angular/core';

import { IDENTITY_PROVIDER_IMPLEMENTOR } from 'ish-core/identity-provider/identity-provider.factory';

import { CamfilLoginOnBehalfIdentityProvider } from './camfil-login-on-behalf-identity-provider';

@NgModule({
  providers: [
    {
      provide: IDENTITY_PROVIDER_IMPLEMENTOR,
      multi: true,
      useValue: {
        type: 'CAMFIL_LOGIN_ON_BEHALF',
        implementor: CamfilLoginOnBehalfIdentityProvider,
      },
    },
  ],
})
export class CamfilLoginOnBehalfIdentityProviderModule {}
