import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { FieldType, FormlyModule as FormlyBaseModule } from '@ngx-formly/core';
import { FormlyPresetModule } from '@ngx-formly/core/preset';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { TranslateModule } from '@ngx-translate/core';
import { CamfilDirectivesModule } from 'camfil-core/camfil-directives.module';
import { CamfilPipesModule } from 'camfil-core/camfil-pipes.module';
import { CamfilFieldLibraryModule } from 'camfil-shared/formly/field-library/camfil-field-library.module';
import { CamfilFormlyWrappersModule } from 'camfil-shared/formly/wrappers/camfil-formly-wrappers.modules';

import { DirectivesModule } from 'ish-core/directives.module';
import { FeatureToggleModule } from 'ish-core/feature-toggle.module';
import { IconModule } from 'ish-core/icon.module';
import { FieldLibraryModule } from 'ish-shared/formly/field-library/field-library.module';

import { CamfilFormlyExtensionsModule } from './extensions/camfil-formly-extensions.module';
import { CamfilFormlyTypesModule } from './types/camfil-formly-types.module';

/* eslint-disable @angular-eslint/sort-ngmodule-metadata-arrays */

@NgModule({
  imports: [
    CommonModule,
    DirectivesModule,
    FeatureToggleModule,
    IconModule,
    ReactiveFormsModule,
    RouterModule,
    TranslateModule,
    CamfilDirectivesModule,
    CamfilPipesModule,
    FormlyMaterialModule,
    FormlyPresetModule,
    FormlyBaseModule.forChild({
      extras: {
        lazyRender: true,
        showError: (field: FieldType) =>
          field.formControl?.invalid &&
          (field.formControl.dirty || field.options.parentForm?.submitted || !!field.field.validation?.show),
      },
    }),
    FieldLibraryModule,
    CamfilFieldLibraryModule,
    // eslint-disable-next-line @angular-eslint/sort-ngmodule-metadata-arrays
    CamfilFormlyExtensionsModule,
    CamfilFormlyTypesModule,
    CamfilFormlyWrappersModule,
  ],
})
export class CamfilFormlyModule {}
