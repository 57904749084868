import { createSelector } from '@ngrx/store';
import { getCamfilCustomers } from 'camfil-core/store/camfil-customer/camfil-customer.selectors';
import { CamfilCustomer } from 'camfil-models/camfil-customer/camfil-customer.model';
import { CamfilQuote } from 'camfil-models/camfil-quotes/camfil-quote/camfil-quote.model';

import { getCamfilFeatureState } from '../camfil-store';

import { CamfilQuotesState, camfilQuotesFeatureKey, quotesAdapter } from './camfil-quotes.reducer';

const mapCustomerAndAddress = <Q extends CamfilQuote, C extends CamfilCustomer[]>(quote: Q, customers: C) => {
  const customer = customers?.find(customer => customer?.customerNo === quote?.customerNo);
  const addressFromCustomer =
    customer?.deliveryAddresses?.find(
      address =>
        address?.addressLine1 === quote.deliveryAddress?.addressLine1 &&
        address?.addressLine2 === quote.deliveryAddress?.addressLine2 &&
        address?.city === quote.deliveryAddress?.city &&
        address?.postalCode === quote.deliveryAddress?.postalCode
    ) || {};
  const deliveryAddress = { ...addressFromCustomer, ...quote?.deliveryAddress };

  return { ...quote, customer, deliveryAddress };
};

const getCamfilQuotesState = getCamfilFeatureState<CamfilQuotesState>(camfilQuotesFeatureKey);

const { selectEntities, selectAll } = quotesAdapter.getSelectors(getCamfilQuotesState);

const getCamfilQuotesEntities = createSelector(selectEntities, getCamfilCustomers, (entities, customers) =>
  Object.keys(entities).reduce((acc, key) => {
    acc[key] = mapCustomerAndAddress(entities[key], customers);
    return acc;
    // eslint-disable-next-line ish-custom-rules/no-object-literal-type-assertion
  }, {} as typeof entities)
);

export const getCamfilQuotes = createSelector(selectAll, getCamfilCustomers, (quotes, customers) =>
  quotes.map(quote => mapCustomerAndAddress(quote, customers))
);

export const areCamfilQuotesLoaded = createSelector(getCamfilQuotes, (quotes): boolean => !!quotes?.length);

export const getCamfilQuotesLoading = createSelector(getCamfilQuotesState, (state: CamfilQuotesState) => state.loading);

export const getCamfilQuotesApprovedSuccess = createSelector(
  getCamfilQuotesState,
  (state: CamfilQuotesState) => state.approvedQuotesSuccess
);

export const getCamfilQuotesRejectedSuccess = createSelector(
  getCamfilQuotesState,
  (state: CamfilQuotesState) => state.rejectedQuotesSuccess
);

export const getCamfilQuote = (quoteId: string) =>
  createSelector(getCamfilQuotesEntities, getCamfilCustomers, (entities, customers) => {
    const quote = entities[quoteId];
    return mapCustomerAndAddress(quote, customers);
  });

export const getSelectedCamfilQuoteId = createSelector(getCamfilQuotesState, state => state.selected);

export const getSelectedCamfilQuote = createSelector(
  getCamfilQuotesEntities,
  getSelectedCamfilQuoteId,
  getCamfilCustomers,
  (entities, id, customers): CamfilQuote => {
    const quote = id && entities[id];
    return mapCustomerAndAddress(quote, customers);
  }
);
