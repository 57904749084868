<ng-container *ngIf="loading$ | async; else loaded">
  <div camfilDialogContent>
    <camfil-loading class="loader" />
  </div>
</ng-container>
<ng-template #loaded>
  <h2 class="title" camfilDialogTitle>
    <span class="name">{{ productName$ | async }}</span>
    <camfil-product-lead-time [showText]="false" />
  </h2>
  <div camfilDialogContent class="content">
    <div *ngIf="productCategoryName$ | async as productCategoryName" class="category">{{ productCategoryName }}</div>
    <camfil-product-gallery class="gallery" [images]="productImages$ | async" />
    <p
      class="description"
      *ngIf="productLongDescription$ | async as productLongDescription"
      [innerHTML]="productLongDescription"
    ></p>
    <div class="attributes">
      <h3 class="attributes-title">{{ 'camfil.product.quickview.details.headline' | translate }}</h3>
      <dl class="attributes-list">
        <ng-container *ngIf="productAttributeFrameProduct$ | async as productAttributeFrameProduct">
          <dt>{{ 'camfil.product.quickView.frame.label' | translate }}</dt>
          <dd>{{ productAttributeFrameProduct | ishAttribute }}</dd>
        </ng-container>
        <ng-container *ngIf="productAttributePressureDrop$ | async as productAttributePressureDrop">
          <dt>{{ 'camfil.product.quickView.pressureDrop.label' | translate }}</dt>
          <dd>{{ productAttributePressureDrop | ishAttribute }}</dd>
        </ng-container>
        <ng-container *ngIf="productAttributeIsoClassAndEfficiency$ | async as productAttributeIsoClassAndEfficiency">
          <dt>{{ 'camfil.product.class_and_efficiency.label' | translate }}</dt>
          <dd>{{ productAttributeIsoClassAndEfficiency | ishAttribute }}</dd>
        </ng-container>
        <ng-container *ngIf="productAttributeEuroventEnergyClass$ | async as productAttributeEuroventEnergyClass">
          <dt>{{ 'camfil.product.quickView.energyClass.label' | translate }}</dt>
          <dd>{{ productAttributeEuroventEnergyClass | ishAttribute }}</dd>
        </ng-container>
        <ng-container *ngIf="productAttributeDimensions$ | async as productAttributeDimensions">
          <dt>{{ 'camfil.product.quickView.dimensions.label' | translate }}</dt>
          <dd>
            {{ productAttributeDimensions.width | ishAttribute }}×{{
              productAttributeDimensions.height | ishAttribute
            }}×{{ productAttributeDimensions.depth | ishAttribute }}
          </dd>
        </ng-container>
        <dt>{{ 'camfil.product.quickView.sku.label' | translate }}</dt>
        <dd>{{ dialogData }}</dd>
        <ng-container *ngIf="productAttributeFilterBags$ | async as productAttributeFilterbags">
          <dt>{{ 'camfil.product.quickView.bags.label' | translate }}</dt>
          <dd>{{ productAttributeFilterbags | ishAttribute }}</dd>
        </ng-container>
      </dl>
    </div>
    <camfil-product-documents class="documents" />
    <div class="main">
      <camfil-product-price class="price" />
      <camfil-product-form class="form" />
    </div>
  </div>
</ng-template>
<div camfilDialogActions>
  <button mat-button color="primary" type="button" (click)="dismiss()">
    {{ 'camfil.modal.cta.button.cancel' | translate }}
  </button>
</div>
