/* eslint-disable ish-custom-rules/no-intelligence-in-artifacts, @typescript-eslint/dot-notation */
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { CamfilCheckoutFacade } from 'camfil-core/facades/camfil-checkout.facade';
import { CamfilDialogFacade } from 'camfil-core/facades/camfil-dialog.facade';
import { CamfilBucket } from 'camfil-models/camfil-bucket/camfil-bucket.model';
import {
  CamfilBasketValidationDeliveryDateDialogComponent,
  CamfilInvalidDeliveryDateAction,
} from 'camfil-shared/components/basket/camfil-basket-validation-delivery-date-dialog/camfil-basket-validation-delivery-date-dialog.component';
import { formatISO } from 'date-fns';
import { combineLatest, withLatestFrom } from 'rxjs';
import { filter, take } from 'rxjs/operators';

import { CheckoutFacade } from 'ish-core/facades/checkout.facade';
import { RoleToggleService } from 'ish-core/role-toggle.module';
import { BasketValidationResultsComponent } from 'ish-shared/components/basket/basket-validation-results/basket-validation-results.component';

@Component({
  selector: 'camfil-basket-validation-results',
  templateUrl: './camfil-basket-validation-results.component.html',
  styleUrls: ['./camfil-basket-validation-results.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
// eslint-disable-next-line rxjs-angular/prefer-takeuntil
export class CamfilBasketValidationResultsComponent extends BasketValidationResultsComponent implements OnInit {
  constructor(
    checkoutFacade: CheckoutFacade,
    private camfilCheckoutFacade: CamfilCheckoutFacade,
    private camfilDialogFacade: CamfilDialogFacade,
    private roleToggleService: RoleToggleService
  ) {
    super(checkoutFacade);
  }

  private updateBuckets(choice: number) {
    this.camfilCheckoutFacade.commonDateForExpiredBuckets$
      .pipe(
        withLatestFrom(
          this.camfilCheckoutFacade.camfilBucketsWithInvalidDeliveryDate$,
          this.camfilCheckoutFacade.camfilBasket$
        ),
        take(1),
        takeUntilDestroyed(this['destroyRef'])
      )
      .subscribe(([deliveryDate, bucketsWithInvalidDeliveryDate, basket]) => {
        const changedBuckets: CamfilBucket[] = bucketsWithInvalidDeliveryDate.map(bucket => {
          let deliveryDateISO: Date | string = deliveryDate || undefined;

          if (choice === CamfilInvalidDeliveryDateAction.UpdateAsEarliestPossibleDeliveryDateAndContinueCheckout) {
            deliveryDateISO = this.camfilCheckoutFacade.getCamfilBucketFirstAvailableDeliveryDate(bucket);
          }

          deliveryDateISO = deliveryDate && formatISO(deliveryDateISO, { representation: 'date' });

          return {
            ...bucket,
            deliveryDate: deliveryDateISO,
          };
        });
        this.camfilCheckoutFacade.updateBuckets(changedBuckets, basket);
      });
  }

  openDeliveryDateValidationDialog() {
    const dialogRef = this.camfilDialogFacade.open<CamfilBasketValidationDeliveryDateDialogComponent, never, number>(
      CamfilBasketValidationDeliveryDateDialogComponent
    );

    dialogRef
      .afterClosed()
      .pipe(takeUntilDestroyed(this['destroyRef']))
      .subscribe(choice => {
        if (choice && choice !== CamfilInvalidDeliveryDateAction.CloseWithIssuesAndPreventCheckout) {
          this.updateBuckets(choice);
        }
      });
  }

  ngOnInit() {
    // eslint-disable-next-line ban/ban
    super.ngOnInit();

    combineLatest([
      this.roleToggleService.hasRole(['APP_B2B_CXML_USER', 'APP_B2B_OCI_USER']),
      this.camfilCheckoutFacade.showDeliveryDateValidationDialog$,
    ])
      .pipe(
        filter(([punchOut, showDeliveryDateValidationDialog]) => !punchOut && showDeliveryDateValidationDialog),
        takeUntilDestroyed(this['destroyRef'])
      )
      .subscribe(() => {
        this.openDeliveryDateValidationDialog();
      });

    this.errorMessages$ = this.camfilCheckoutFacade.getErrorMessages$;
    this.infoMessages$ = this.camfilCheckoutFacade.getInfoMessages$;
  }
}
