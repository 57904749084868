<h2 camfilDialogTitle>
  {{ 'camfil.modal.add.cam_card.to.cart.feedback.title' | translate }}
</h2>
<div camfilDialogContent *ngIf="camCardsFeedback$ | async as camCardsFeedback">
  <p class="font-weight-bold">
    {{ 'camfil.modal.add.cam_card.to.cart.feedback.subtitle' | translate }}
  </p>
  <div *ngFor="let item of camCardsFeedback">
    <h5>CamCard - {{ item.parameters.camCardName }}</h5>
    {{ 'camfil.modal.add.cam_card.to.cart.feedback.sku.label' | translate }} {{ item.parameters.sku }}
    <strong>({{ item.message }})</strong>
  </div>
  <!-- TODO: could be added a spinner when other adding action is in progress in a queue -->
</div>
<div camfilDialogActions>
  <button mat-button color="primary" type="button" (click)="close()">
    {{ 'camfil.modal.cta.button.ok' | translate }}
  </button>
</div>
