import { NgModule } from '@angular/core';
import { CamfilPlatformClassDirective } from 'camfil-core/directives/camfil-platform-class.directive';

import { CamfilChannelToggleDirective } from './directives/camfil-channel-toggle.directive';
import { CamfilMaxLengthDirective } from './directives/camfil-max-length.directive';
import { CamfilNotAuthorizationToggleDirective } from './directives/not-authorization-toggle-directive.directive';

const camfilDirectives = [
  CamfilChannelToggleDirective,
  CamfilMaxLengthDirective,
  CamfilNotAuthorizationToggleDirective,
  CamfilPlatformClassDirective,
];

@NgModule({
  declarations: [...camfilDirectives],
  exports: [...camfilDirectives],
})
export class CamfilDirectivesModule {}
