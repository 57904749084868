export class CamfilLangHelper {
  static mapRoleToText(base: string) {
    return ([roleB2BRequestQuotation, roleB2BNeedsApproval]: [boolean, boolean]): string => {
      if (roleB2BRequestQuotation) {
        return `${base}_quote`;
      }

      if (roleB2BNeedsApproval) {
        return `${base}_requisition`;
      }

      return base;
    };
  }
}
