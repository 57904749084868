import { ChangeDetectionStrategy, Component, HostBinding, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CamfilBucket } from 'camfil-models/camfil-bucket/camfil-bucket.model';
import { CamfilDialogConfirmResult } from 'camfil-shared/dialog/camfil-dialog-confirm/camfil-dialog-confirm.component';

export type CamfilDeleteOrderModalData = CamfilBucket[];

export type CamfilDeleteOrderModalResult = CamfilDialogConfirmResult.CONFIRMED | CamfilDialogConfirmResult.DISMISSED;

@Component({
  selector: 'camfil-delete-order-modal',
  templateUrl: './camfil-dialog-delete-order.component.html',
  styleUrls: ['./camfil-dialog-delete-order.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CamfilDialogDeleteOrderComponent {
  @HostBinding('class.camfil-dialog') camfilDialogClass = true;

  constructor(
    public matDialogRef: MatDialogRef<CamfilDialogDeleteOrderComponent, CamfilDeleteOrderModalResult>,
    // eslint-disable-next-line ish-custom-rules/use-type-safe-injection-token
    @Inject(MAT_DIALOG_DATA) public ordersToDelete: CamfilDeleteOrderModalData
  ) {}

  confirmOrderRemoval() {
    this.matDialogRef.close(CamfilDialogConfirmResult.CONFIRMED);
  }
}
