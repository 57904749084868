<ng-container *camfilChannelToggle="'showPrintButtonOnQuotations'">
  <button
    mat-stroked-button
    color="primary"
    (click)="editQuote()"
    [disabled]="canEditQuote === false"
    class="secondary-button"
  >
    <mat-icon svgIcon="edit" class="size-15" />
    {{ 'camfil.quotes.quoteslist.cta.edit.label' | translate }}
  </button>
</ng-container>
