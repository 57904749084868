import { Directive, HostBinding, Input } from '@angular/core';

@Directive({
  selector: '[camfilDialogContent]',
  standalone: true,
})
export class CamfilDialogContentDirective {
  @HostBinding('class.camfil-dialog-content') camfilDialogContentClass = true;
  @HostBinding('class.camfil-dialog-content--separated') separatedClass = false;
  @Input() set separated(attribute: boolean | true | false | '') {
    this.separatedClass = attribute === '' || Boolean(attribute);
  }
}
