import { CamCard } from '../camfil-cam-card/cam-card.model';

import { CamfilBucketAddressData } from './camfil-address.interface';
import { CamfilAddress } from './camfil-address.model';

export class CamfilAddressMapper {
  static fromCamCard(camCard: CamCard): CamfilAddress {
    if (camCard) {
      const addr = camCard.deliveryAddress;
      return {
        addressName: `CamCard_${camCard.name}`,
        companyName1: addr?.companyName1, //|| camCard?.customer?.companyName,
        addressLine1: addr.addressLine1,
        addressLine2: addr.addressLine2,
        postalCode: addr.postalCode,
        city: addr.city,
        countryCode: addr.countryCode,
        country: addr.country,
        id: '',
        invoiceToAddress: true,
        firstName: '',
        lastName: '',
        phoneHome: '',
        shipToAddress: true,
        urn: '',
        eligibleShipToAddress: true,
        goodsAcceptanceNote: addr.goodsAcceptanceNote,
      };
    } else {
      throw new Error(`'camCard' is required for the mapping`);
    }
  }

  static fromData(data: CamfilBucketAddressData): CamfilAddress {
    if (data) {
      return {
        id: data.id,
        urn: data.urn,
        type: data.type,
        addressName: data.addressName,
        companyName1: data.companyName1,
        companyName2: data.companyName2,
        title: data.title,
        firstName: data.firstName,
        lastName: data.lastName,
        addressLine1: data.addressLine1,
        addressLine2: data.addressLine2,
        addressLine3: data.addressLine3,
        postalCode: data.postalCode,
        city: data.city,
        mainDivision: data.mainDivisionName || data.mainDivision,
        mainDivisionCode: data.mainDivision,
        country: data.country,
        countryCode: data.countryCode,
        phoneHome: data.phoneHome,
        phoneMobile: data.phoneMobile,
        phoneBusiness: data.phoneBusiness,
        phoneBusinessDirect: data.phoneBusinessDirect,
        fax: data.fax,
        email: data.email,
        goodsAcceptanceNote: data.goodsAcceptanceNote,
      };
    } else {
      throw new Error(`'CamfilBucketAddressData' is required for the mapping`);
    }
  }
}
