import { createAction } from '@ngrx/store';
import { Manufacturer } from 'camfil-models/camfil-ahu/manufacturer/manufacturer.model';

import { httpError, payload } from 'ish-core/utils/ngrx-creators';

export const loadAhuManufacturers = createAction('[AHU Manufacturers Internal] Load AHU Manufacturers');

export const loadAhuManufacturersSuccess = createAction(
  '[AHU Manufacturers API] Load AHU Manufacturers Success',
  payload<{ manufacturers: Manufacturer[] }>()
);

export const loadAhuManufacturersFail = createAction(
  '[AHU Manufacturers API] Load AHU Manufacturers Fail',
  httpError()
);

export const selectAhuManufacturer = createAction(
  '[AHU Manufacturers Internal] Select Manufacturer',
  payload<{ manufacturerId: string }>()
);

export const deselectAhuManufacturer = createAction('[AHU Manufacturers Internal] Deselect Manufacturer');
