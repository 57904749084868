import { EntityState, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { CamfilProduct } from 'camfil-models/camfil-product/product.model';

import { AllProductTypes } from 'ish-core/models/product/product.model';

import {
  getCustomerPricesForProductsSuccess,
  loadCustomerPricesFail,
  loadCustomerPricesSuccess,
} from './customer-prices.actions';

export const camfilCustomerPriceKey = 'camfilCustomerPrices';

const productAdapter = createEntityAdapter<AllProductTypes>({
  selectId: product => product.sku,
});

export interface CustomerPricesState extends EntityState<AllProductTypes> {
  customerPrices?: {
    [key: string]: CamfilProduct[];
  };
}

const initialState: CustomerPricesState = productAdapter.getInitialState({});

function addCustomerPrices(
  state: CustomerPricesState,
  customerId: string,
  products: CamfilProduct[]
): CustomerPricesState {
  const prices = state?.customerPrices?.[customerId] ? [...state.customerPrices[customerId], ...products] : products;

  return {
    ...state,
    customerPrices: {
      ...state.customerPrices,
      [customerId]: prices,
    },
  };
}

export const reducer = createReducer(
  initialState,
  on(loadCustomerPricesSuccess, (state: CustomerPricesState, action) => {
    const { customerId, products } = action.payload;
    return addCustomerPrices(state, customerId, products);
  }),
  on(loadCustomerPricesFail, (state: CustomerPricesState, action) => {
    const { customerId } = action.payload;
    return addCustomerPrices(state, customerId, []);
  }),

  on(getCustomerPricesForProductsSuccess, (state: CustomerPricesState, action) => {
    const { products } = action.payload;
    const entities = products.reduce((acc, { sku, salePrice }) => {
      if (salePrice) {
        const { currency, value } = salePrice;
        return { ...acc, [sku]: { ...state.entities[sku], salePrice: { type: 'Money', currency, value } } };
      } else {
        return acc;
      }
    }, {});

    return {
      ...state,
      entities,
    };
  })
);
