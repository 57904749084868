type CamfilDialogIconNameSVGEnum = string;

type CamfilDialogIconNameFont = { font: string };

type CamfilDialogIconNameSVG = { svg: CamfilDialogIconNameSVGEnum };

export type CamfilDialogIconName = CamfilDialogIconNameFont | CamfilDialogIconNameSVG;

export function camfilDialogIconNameIsFont(value: CamfilDialogIconName): value is CamfilDialogIconNameFont {
  return 'font' in value;
}

export function camfilDialogIconNameIsSVG(value: CamfilDialogIconName): value is CamfilDialogIconNameSVG {
  return 'svg' in value;
}
