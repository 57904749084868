import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { CamCardService } from 'camfil-core/services/cam-card/camfil-cam-card.service';
import { CamfilBasketService } from 'camfil-core/services/camfil-basket/camfil-basket.service';
import { CamfilConfigurationService } from 'camfil-core/services/configuration/camfil-configuration.service';
import { camfilAddItemsToBucketSuccess } from 'camfil-core/store/camfil-bucket/camfil-bucket.actions';
import {
  getCamfilCustomer,
  getCamfilCustomersEntities,
  getUserContact,
  getUserContactByCustomerNo,
} from 'camfil-core/store/camfil-customer/camfil-customer.selectors';
import { loadCustomerPrices } from 'camfil-core/store/shopping/customer-prices';
import { CamfilAuthorizationToggleService } from 'camfil-core/utils/auth/authorization-toggle.service';
import { CamfilAttributeHelper } from 'camfil-models/camfil-attribute/attribute.helper';
import { CamCardHelper } from 'camfil-models/camfil-cam-card/cam-card.helper';
import { CamCardMapper } from 'camfil-models/camfil-cam-card/cam-card.mapper';
import { CamCard, CamCardItemComment } from 'camfil-models/camfil-cam-card/cam-card.model';
import { CamfilContact } from 'camfil-models/camfil-customer/camfil-customer.model';
import { EMPTY, combineLatest, from, fromEvent, identity, iif, switchMap, window as windowRxOperator } from 'rxjs';
import {
  concatMap,
  debounceTime,
  distinctUntilChanged,
  filter,
  groupBy,
  map,
  mergeMap,
  throttleTime,
  toArray,
} from 'rxjs/operators';

import { BasketService } from 'ish-core/services/basket/basket.service';
import { getDeviceType } from 'ish-core/store/core/configuration';
import { displayErrorMessage, displaySuccessMessage } from 'ish-core/store/core/messages';
import { ofUrl, selectPath, selectRouteParam } from 'ish-core/store/core/router';
import { setBreadcrumbData } from 'ish-core/store/core/viewconf';
import { createBasketFail, createBasketSuccess, getCurrentBasketId } from 'ish-core/store/customer/basket';
import { getUserAuthorized, loginUserSuccess, personalizationStatusDetermined } from 'ish-core/store/customer/user';
import {
  delayUntil,
  distinctCompareWith,
  mapErrorToAction,
  mapToPayload,
  mapToPayloadProperty,
  mapToProperty,
  whenTruthy,
} from 'ish-core/utils/operators';

import {
  addBasketToNewCamCard,
  addBasketToNewCamCardFail,
  addBasketToNewCamCardSuccess,
  addProductToCamCard,
  addProductToCamCardFail,
  addProductToCamCardSuccess,
  addProductToNewCamCard,
  addProductToNewCamCardAndEdit,
  addProductToNewSubCamCard,
  addProductToSubCamCard,
  addProductsToCamCard,
  addProductsToCamCardAfterCreateCamCard,
  addProductsToCamCardFail,
  addProductsToCamCardSuccess,
  addProductsToNewCamCard,
  addProductsToNewSubCamCard,
  addToCartFromCamCardNextStep,
  addToCartFromCamCards,
  addToCartFromCamCardsFail,
  addToCartFromCamCardsSuccess,
  addToNewCamCardWithNewSubCamCard,
  checkCamCardsInBasketsForAllUsers,
  checkCamCardsInBasketsForAllUsersFail,
  checkCamCardsInBasketsForAllUsersSuccess,
  copyCamCard,
  copyCamCardFail,
  copyCamCardSuccess,
  createCamCard,
  createCamCardFail,
  createCamCardSuccess,
  createSubCamCard,
  createVirtualCamCard,
  createVirtualCamCardFail,
  createVirtualCamCardSuccess,
  deleteCamCard,
  deleteCamCardFail,
  deleteCamCardSuccess,
  detectCamCardToolbar,
  editCamCard,
  importCamCard,
  importCamCardFail,
  importCamCardSuccess,
  loadCamCard,
  loadCamCardFail,
  loadCamCardIfNotLoaded,
  loadCamCardSuccess,
  loadCamCardsBasicList,
  loadCamCardsBasicListSuccess,
  loadCamCardsEdit,
  loadCamCardsFail,
  loadCamCardsIfNotLoaded,
  loadCamCardsSuccess,
  moveCamCard,
  moveCamCardFail,
  moveCamCardSuccess,
  refreshCamCards,
  selectCamCard,
  setEditMode,
  setEditModeSuccess,
  setStickyCamCardToolbar,
  turnOffEditMode,
  updateCamCard,
  updateCamCardAttribute,
  updateCamCardAttributeSuccess,
  updateCamCardContacts,
  updateCamCardContactsFail,
  updateCamCardContactsSuccess,
  updateCamCardFail,
  updateCamCardProcessing,
  updateCamCardProduct,
  updateCamCardProductSuccess,
  updateCamCardSuccess,
  updateCamCardsAfterCreateOrder,
  updateContactsWhileMoveCamCardFail,
  updateSubCamCard,
  updateSubCamCardSuccess,
  validateCamCardImport,
  validateCamCardImportFail,
  validateCamCardImportSuccess,
} from './cam-cards.actions';
import {
  getAllCamCards,
  getAllCustomerCamCardsFetched,
  getCamCardDetails,
  getCamCardEditMode,
  getCamCardEntities,
  getLoadingSingleCamCards,
  getSelectedCamCardDetails,
  getSelectedCamCardId,
  isCamCardsInitialized,
} from './cam-cards.selectors';

@Injectable()
export class CamCardEffects {
  constructor(
    private actions$: Actions,
    private camCardService: CamCardService,
    private camfilBasketService: CamfilBasketService,
    private camfilAuthorizationToggleService: CamfilAuthorizationToggleService,
    private camfilConfigurationService: CamfilConfigurationService,
    private basketService: BasketService,
    private store: Store,
    private router: Router,
    private camCardMapper: CamCardMapper,
    @Inject(DOCUMENT) private document: Document // @Inject(PLATFORM_ID) private platformId: string
  ) {}

  static readonly PRICE_PERMISSIONS = ['APP_B2B_VIEW_PRICES'];

  private canLoadCustomerPrices$ = combineLatest([
    this.camfilAuthorizationToggleService.isAuthorizedToCheckArrAll(CamCardEffects.PRICE_PERMISSIONS),
    this.camfilConfigurationService.isEnabled$('showPricesInCamCards'),
  ]).pipe(map(([isAuthorized, isEnabled]) => isAuthorized && isEnabled));

  loadCamCardsAfterLogin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loginUserSuccess),
      delayUntil(this.actions$.pipe(ofType(personalizationStatusDetermined))),
      map(() => loadCamCardsIfNotLoaded({ includeAllCustomerCamCards: false }))
    )
  );

  /**
   * Reload CamCards after a creation or update to ensure integrity with server
   */
  reloadCamCard$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createCamCardSuccess),
      mapToPayloadProperty('camCard'),
      filter(camCard => camCard && !!camCard.id),
      map(camCard => loadCamCard({ camCardId: camCard.id }))
    )
  );

  loadCamCardsBasicList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadCamCardsBasicList),
      mapToPayload(),
      switchMap(({ includeAllCustomerCamCards }) =>
        this.camCardService.getCamCardsBasicList(includeAllCustomerCamCards).pipe(
          map(camCards => loadCamCardsBasicListSuccess({ camCards, includeAllCustomerCamCards })),
          mapErrorToAction(loadCamCardsFail)
        )
      )
    )
  );

  loadCamCardsBasicListSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadCamCardsBasicListSuccess),
      mapToPayload(),
      concatLatestFrom(() => [
        this.store.pipe(select(getCamfilCustomersEntities)),
        this.store.pipe(select(getAllCamCards)),
      ]),
      map(([{ camCards, includeAllCustomerCamCards }, customers, allCC]) => {
        const ccs: CamCard[] = this.camCardMapper.fromBasicListData(camCards, customers);

        // keep fetched items in subCamCards
        const ccsWithSubsWithItems = allCC.filter(cc => cc.subCamCards?.some(sub => sub.camCardItems?.length));
        const subsVerified: CamCard[] = CamCardHelper.verifyBasicCamCardsHaveSubsWithItems(ccsWithSubsWithItems, ccs);

        return loadCamCardsSuccess({ camCards: subsVerified, includeAllCustomerCamCards });
      })
    )
  );

  loadSelectedCamCardAfterBasicListSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadCamCardsSuccess),
      mapToPayload(),
      concatLatestFrom(() => this.store.pipe(select(getSelectedCamCardId))),
      filter(([, camCardId]) => !!camCardId),
      map(([, camCardId]) => loadCamCardIfNotLoaded({ camCardId }))
    )
  );

  refreshCamCards$ = createEffect(() =>
    this.actions$.pipe(
      ofType(refreshCamCards),
      concatLatestFrom(() => this.store.pipe(select(getAllCustomerCamCardsFetched))),
      map(([, includeAllCustomerCamCards]) => loadCamCardsBasicList({ includeAllCustomerCamCards }))
    )
  );

  loadCamCardsIfNotLoaded$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadCamCardsIfNotLoaded),
      mapToPayload(),
      concatLatestFrom(() => [
        this.store.pipe(select(getCamCardEntities)),
        this.store.pipe(select(getAllCustomerCamCardsFetched)),
        this.store.pipe(select(isCamCardsInitialized)),
      ]),
      filter(([{ includeAllCustomerCamCards }, entities, allCustomerCamCardsFetched, initialized]) => {
        let shouldFetch = false;

        if (includeAllCustomerCamCards && !allCustomerCamCardsFetched) {
          shouldFetch = true;
        }

        if (!initialized || Object.keys(entities).length === 0) {
          shouldFetch = true;
        }

        return shouldFetch;
      }),
      map(([{ includeAllCustomerCamCards }]) => loadCamCardsBasicList({ includeAllCustomerCamCards }))
    )
  );

  loadCamCard$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadCamCard),
      delayUntil(this.actions$.pipe(ofType(personalizationStatusDetermined))),
      mapToPayloadProperty('camCardId'),
      groupBy(identity),
      mergeMap(group$ =>
        group$.pipe(
          this.throttleOnBrowser(),
          switchMap(camCardId =>
            this.camCardService.getCamCard(camCardId).pipe(
              map(camCard => loadCamCardSuccess({ camCard })),
              mapErrorToAction(loadCamCardFail, { camCardId })
            )
          )
        )
      )
    )
  );

  loadCamCardCustomerPrices$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadCamCardSuccess),
      concatLatestFrom(() => this.canLoadCustomerPrices$),
      filter(([_, canLoad]) => canLoad),
      map(([action]) => action),
      mapToPayloadProperty('camCard'),
      map(camCard => ({ customerId: camCard?.customerId, skus: CamCardHelper.getCamCardSkus(camCard) })),
      map(({ customerId, skus }) => loadCustomerPrices({ customerId, skus }))
    )
  );

  loadCamCardIfNotLoaded$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadCamCardIfNotLoaded),
      mapToPayload(),
      concatLatestFrom(() => [
        this.store.pipe(select(getCamCardEntities)),
        this.store.pipe(select(getLoadingSingleCamCards)),
        this.store.pipe(select(getUserAuthorized)),
      ]),
      filter(
        ([{ camCardId }, entities, loadingSingleCamCards, auth]) =>
          auth &&
          !loadingSingleCamCards?.includes(camCardId) &&
          !CamCardHelper.isSufficientlyLoaded(entities[camCardId])
      ),
      map(([{ camCardId }]) => loadCamCard({ camCardId }))
    )
  );

  createVirtualCamCard$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createVirtualCamCard),
      mapToPayloadProperty('camCard'),
      mergeMap((camCardData: CamCard) =>
        this.camCardService.createCamCard(camCardData).pipe(
          mergeMap(camCard => [
            createVirtualCamCardSuccess({ camCard }),
            displaySuccessMessage({
              message: 'camfil.account.cam_card.new_cam_card.confirmation',
              messageParams: { 0: camCard.name },
            }),
          ]),
          mapErrorToAction(createVirtualCamCardFail)
        )
      )
    )
  );

  createCamCard$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createCamCard),
      mapToPayload(),

      mergeMap(({ camCard, newItems }) =>
        this.camCardService.createCamCard(camCard).pipe(
          mergeMap(camCard => [
            createCamCardSuccess({ camCard }),
            displaySuccessMessage({
              message: 'camfil.account.cam_card.new_cam_card.confirmation',
              messageParams: { 0: camCard.name },
            }),
            addProductsToCamCardAfterCreateCamCard({
              camCardId: camCard.id,
              rootId: camCard.rootCamCard,
              items: newItems,
            }),
          ]),
          mapErrorToAction(createCamCardFail)
        )
      )
    )
  );

  addProductsToCamCardAfterCreateCamCard$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addProductsToCamCardAfterCreateCamCard),
      mapToPayload(),
      filter(({ items }) => Boolean(items)),
      map(addProductsToCamCard)
    )
  );

  redirectAfterCreateCamCard$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(createCamCardSuccess),
        mapToPayloadProperty('camCard'),
        mapToProperty('id'),
        concatLatestFrom(() => this.store.pipe(select(selectPath))),
        filter(([, path]) => path.includes('camcards')),
        concatMap(([id]) => from(this.router.navigateByUrl(`/account/camcards/${id}`)))
      ),
    { dispatch: false }
  );

  createSubCamCard$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createSubCamCard),
      mapToPayload(),
      mergeMap(payload =>
        this.camCardService.createSubCamCard(payload.subCamCard, payload.rootCamCardId).pipe(
          mergeMap(subCamCard =>
            this.camCardService
              .getCamCard(subCamCard.rootCamCard)
              .pipe(mergeMap(camCard => [createCamCardSuccess({ camCard })]))
          ),
          mapErrorToAction(addProductToCamCardFail)
        )
      )
    )
  );

  moveCamCard$ = createEffect(() =>
    this.actions$.pipe(
      ofType(moveCamCard),
      mapToPayload(),
      mergeMap(({ camCardId, newCustomerId, newContacts }) =>
        this.camCardService.moveCamCard(camCardId, newCustomerId).pipe(
          mergeMap((camCard: CamCard) => [moveCamCardSuccess({ camCard, newContacts })]),
          mapErrorToAction(moveCamCardFail)
        )
      )
    )
  );

  moveCamCardSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(moveCamCardSuccess),
      mapToPayload(),
      mergeMap(({ camCard, newContacts }) =>
        this.camCardService.updateCamCardContacts(camCard.id, newContacts).pipe(
          concatLatestFrom(() => this.store.pipe(select(getCamfilCustomer(camCard.customerId)))),
          mergeMap(() => this.store.pipe(select(getUserContact))),
          mergeMap(contact => [
            this.handleCamCardContactsSuccess(camCard.id, newContacts, contact),
            displaySuccessMessage({
              message: 'camfil.account.cam_card.move.confirmation',
              messageParams: { 0: camCard.id },
            }),
          ]),
          mapErrorToAction(updateContactsWhileMoveCamCardFail)
        )
      )
    )
  );

  addBasketToNewCamCard$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addBasketToNewCamCard),
      mapToPayloadProperty('camCard'),
      mergeMap(camCards =>
        this.camCardService.createCamCard(camCards).pipe(
          map(camCard => addBasketToNewCamCardSuccess({ camCard })),
          mapErrorToAction(addBasketToNewCamCardFail)
        )
      ),
      mapErrorToAction(createCamCardFail)
    )
  );

  collectMsgsOnNewCamCardsSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addBasketToNewCamCardSuccess),
      mapToPayloadProperty('camCard'),
      windowRxOperator(this.actions$.pipe(ofType(addBasketToNewCamCardSuccess), debounceTime(500))),
      concatMap(window$ => window$.pipe(toArray())),
      map(ccs => {
        const names = ccs.length > 1 ? ccs.map(({ name }) => name).join(', ') : ccs[0]?.name;
        return displaySuccessMessage({
          message: `camfil.account.cam_card.new_from_basket_confirm.heading${ccs.length > 1 ? '.more_then_one' : ''}`,
          messageParams: { 0: names },
        });
      })
    )
  );

  deleteCamCard$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteCamCard),
      mapToPayloadProperty('camCardId'),
      mergeMap(camCardId => this.store.pipe(select(getCamCardDetails({ id: camCardId })))),
      whenTruthy(),
      map(camCard => ({ camCardId: camCard.id, name: camCard.name })),
      mergeMap(({ camCardId, name }) =>
        this.camCardService.deleteCamCard(camCardId).pipe(
          mergeMap(() => [
            deleteCamCardSuccess({ camCardId }),
            displaySuccessMessage({
              message: 'camfil.account.cam_card.delete_cam_card.confirmation',
              messageParams: { 0: name },
            }),
          ]),
          mapErrorToAction(deleteCamCardFail)
        )
      )
    )
  );

  copyCamCard$ = createEffect(() =>
    this.actions$.pipe(
      ofType(copyCamCard),
      mapToPayload(),
      mergeMap(({ camCardId, name }) =>
        this.camCardService.copyCamCard(camCardId, name).pipe(
          map(camCard => {
            this.router.navigate([`/account/camcards/${camCard.id}`], { queryParams: { copy: true } });
            return camCard;
          }),
          mergeMap(camCard => [
            copyCamCardSuccess({ camCard }),
            createCamCardSuccess({ camCard }),
            displaySuccessMessage({
              message: 'camfil.account.cam_card.copy_cam_card.confirmation',
              messageParams: { 0: camCard.name },
            }),
          ]),
          mapErrorToAction(copyCamCardFail)
        )
      )
    )
  );

  updateCamCard$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateCamCard),
      mapToPayloadProperty('camCard'),
      mergeMap((newCamCard: CamCard) => {
        const cc = CamCardHelper.resetPositions(newCamCard);
        const ccWithoutDeprecations = CamCardHelper.removeDeprecations(cc);
        return this.camCardService.updateCamCard(ccWithoutDeprecations).pipe(
          mergeMap(camCard => [
            updateCamCardSuccess({ camCard }),
            displaySuccessMessage({
              message: 'camfil.account.cam_card.edit.confirmation',
              messageParams: { 0: camCard.name },
            }),
          ]),
          mapErrorToAction(updateCamCardFail)
        );
      })
    )
  );

  updateSubCamCard$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateSubCamCard),
      mapToPayloadProperty('sub'),
      concatLatestFrom(() => [
        this.store.pipe(select(getSelectedCamCardDetails)),
        this.store.pipe(select(getCamCardEditMode)),
      ]),
      mergeMap(([sub, selected, edit]) => {
        const camCard: CamCard = {
          ...selected,
          subCamCards: selected.subCamCards.map(s => (s.id === sub.id ? sub : s)),
        };
        return [!edit && setEditMode({ edit: true }), updateSubCamCardSuccess({ camCard })].filter(a => !!a);
      })
    )
  );

  addProductToSubCamCard$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addProductToSubCamCard),
      mapToPayload(),
      mergeMap(payload =>
        this.camCardService
          .addProductToSubCamCard(
            payload.camCardId,
            payload.refreshCamCardId,
            payload.sku,
            payload.quantity,
            payload.boxLabel,
            payload.measurement
          )
          .pipe(
            mergeMap(camCard => [
              addProductToCamCardSuccess({ camCard }),
              selectCamCard({ camCardId: payload.refreshCamCardId }),
            ]),
            mapErrorToAction(addProductToCamCardFail)
          )
      )
    )
  );

  addProductToCamCard$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addProductToCamCard),
      mapToPayload(),
      mergeMap(({ camCardId, sku, quantity, comment, measurement, position, showSuccessToast }) =>
        this.camCardService.addProductToCamCard(camCardId, sku, quantity, comment, measurement, position).pipe(
          mergeMap(camCard =>
            showSuccessToast
              ? [
                  addProductToCamCardSuccess({ camCard }),
                  displaySuccessMessage({
                    message: 'camfil.modal.addNewProduct.confirmation',
                    messageParams: { 0: sku },
                  }),
                  selectCamCard({ camCardId: camCard.id }),
                ]
              : [addProductToCamCardSuccess({ camCard }), selectCamCard({ camCardId: camCard.id })]
          ),
          mapErrorToAction(addProductToCamCardFail)
        )
      )
    )
  );

  addToNewCamCardWithNewSubCamCard$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addToNewCamCardWithNewSubCamCard),
      mapToPayload(),
      mergeMap(payload =>
        this.camCardService.createCamCard(payload.newCamCard).pipe(
          map(camCard =>
            addProductToNewSubCamCard({
              subCamCard: payload.newSubCamCard,
              rootCamCard: camCard,
              sku: payload.sku,
              quantity: payload.quantity,
              boxLabel: payload.boxLabel,
              measurement: payload.measurement,
              edit: payload.edit,
            })
          ),
          mapErrorToAction(addProductToCamCardFail)
        )
      )
    )
  );

  addProductToNewCamCardAndEdit$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addProductToNewCamCardAndEdit),
      mapToPayload(),
      mergeMap(payload =>
        this.camCardService.createCamCard(payload.camCard).pipe(
          mergeMap(camCard => {
            const comment: CamCardItemComment = {
              label: payload.boxLabel,
            };
            const addProductPayload = {
              camCardId: camCard.id,
              sku: payload.sku,
              quantity: payload.quantity,
              comment,
              measurement: payload.measurement,
            };

            return payload.edit
              ? [
                  addProductToCamCard(addProductPayload),
                  createCamCardSuccess({ camCard }),
                  selectCamCard({ camCardId: camCard.id }),
                  editCamCard({ camCardId: camCard.id }),
                ]
              : [
                  addProductToCamCard(addProductPayload),
                  createCamCardSuccess({ camCard }),
                  selectCamCard({ camCardId: camCard.id }),
                ];
          }),
          mapErrorToAction(addProductToCamCardFail)
        )
      )
    )
  );

  addProductsToCamCard$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addProductsToCamCard),
      mapToPayload(),
      mergeMap(({ camCardId, items, showSuccessToast, rootId }) =>
        this.camCardService.addProductsToCamCard(camCardId, items, rootId).pipe(
          mergeMap(camCard => {
            const actions = [addProductsToCamCardSuccess({ camCard }), selectCamCard({ camCardId: camCard.id })];
            return showSuccessToast
              ? [
                  ...actions,
                  displaySuccessMessage({
                    message: 'camfil.modal.addNewProducts.confirmation',
                  }),
                ]
              : actions;
          }),
          mapErrorToAction(addProductToCamCardFail)
        )
      )
    )
  );

  addProductsToNewCamCard$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addProductsToNewCamCard),
      mapToPayload(),
      mergeMap(({ camCard, items, edit, subCamCard }) =>
        this.camCardService.createCamCard(camCard).pipe(
          mergeMap(cc => {
            const camCardId = cc.id;
            const actions = subCamCard
              ? [addProductsToNewSubCamCard({ subCamCard, rootCamCard: camCardId, items, edit })]
              : [
                  addProductsToCamCard({ camCardId, items }),
                  createCamCardSuccess({ camCard: cc }),
                  selectCamCard({ camCardId }),
                ];
            return edit ? [...actions, editCamCard({ camCardId })] : actions;
          }),
          mapErrorToAction(addProductsToCamCardFail, { camCardId: camCard.id, items })
        )
      )
    )
  );

  addProductsToNewSubCamCard$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addProductsToNewSubCamCard),
      mapToPayload(),
      mergeMap(({ subCamCard, rootCamCard, items, edit }) =>
        this.camCardService.createSubCamCard(subCamCard, rootCamCard).pipe(
          mergeMap(camCard => {
            const actions = [
              addProductsToCamCard({
                camCardId: camCard.id,
                rootId: rootCamCard,
                items,
              }),
            ];

            return edit ? [...actions, editCamCard({ camCardId: rootCamCard })] : actions;
          }),
          mapErrorToAction(addProductToCamCardFail)
        )
      )
    )
  );

  addProductToNewSubCamCard$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addProductToNewSubCamCard),
      mapToPayload(),
      mergeMap(payload =>
        this.camCardService.createSubCamCard(payload.subCamCard, payload.rootCamCard.id).pipe(
          mergeMap(camCard => {
            const addProductPayload = {
              camCardId: camCard.id,
              refreshCamCardId: payload.rootCamCard.id,
              sku: payload.sku,
              quantity: payload.quantity,
              boxLabel: payload.boxLabel,
              measurement: payload.measurement,
            };

            return payload.edit
              ? [addProductToSubCamCard(addProductPayload), editCamCard({ camCardId: payload.rootCamCard.id })]
              : [addProductToSubCamCard(addProductPayload)];
          }),
          mapErrorToAction(addProductToCamCardFail)
        )
      )
    )
  );

  addProductToNewCamCard$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addProductToNewCamCard),
      mapToPayload(),
      mergeMap(payload =>
        this.camCardService
          .createCamCard({
            name: payload.name,
          })
          .pipe(
            // use created cam cards data to dispatch addProduct action
            mergeMap(camCard => [
              createCamCardSuccess({ camCard }),
              addProductToCamCard({
                camCardId: camCard.id,
                sku: payload.sku,
                quantity: payload.quantity,
              }),
              selectCamCard({ camCardId: camCard.id }),
            ]),
            mapErrorToAction(createCamCardFail)
          )
      )
    )
  );

  updateCamCardProduct$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateCamCardProduct),
      mapToPayload(),
      concatLatestFrom(() => this.store.pipe(select(getCamCardEditMode))),
      mergeMap(([{ rootCamCard, camCardId, camCardItem }, edit]) =>
        [
          updateCamCardProcessing({ processing: true }),
          !edit && setEditMode({ edit: true }),
          updateCamCardProductSuccess({ rootCamCard, camCardId, camCardItem }),
        ].filter(Boolean)
      )
    )
  );

  navigateToEdit$ = createEffect(() =>
    this.actions$.pipe(
      ofType(editCamCard),
      mapToPayloadProperty('camCardId'),
      map(camCardId => {
        this.router.navigateByUrl(`/account/camcards/${camCardId}`);
        return camCardId;
      }),
      map(() => loadCamCardsEdit())
    )
  );

  updateCamCardContacts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateCamCardContacts),
      mapToPayload(),
      mergeMap(({ camCardId, camCardContacts }) =>
        this.camCardService.updateCamCardContacts(camCardId, camCardContacts).pipe(
          concatLatestFrom(() => this.store.pipe(select(getCamCardDetails({ id: camCardId })))),
          mergeMap(([, camCard]) => this.store.pipe(select(getUserContactByCustomerNo(camCard.customer.customerNo)))),
          mergeMap(contact => [
            this.handleCamCardContactsSuccess(camCardId, camCardContacts, contact),
            displaySuccessMessage({
              message: 'camfil.account.cam_card.update.contacts.confirmation',
            }),
          ]),
          mapErrorToAction(updateCamCardContactsFail)
        )
      )
    )
  );

  loadOrderForSelectedCamCard$ = createEffect(() =>
    this.actions$.pipe(
      ofType(selectCamCard),
      mapToPayloadProperty('camCardId'),
      whenTruthy(),
      map(camCardId => loadCamCardIfNotLoaded({ camCardId }))
    )
  );

  routeListenerForSelectedCamCard$ = createEffect(() =>
    this.store.pipe(
      select(selectRouteParam('camCardName')),
      whenTruthy(),
      distinctCompareWith(this.store.pipe(select(getSelectedCamCardId))),
      map(camCardId => selectCamCard({ camCardId }))
    )
  );

  setCamCardBreadcrumb$ = createEffect(() =>
    this.store.pipe(
      ofUrl(/^\/account\/camcards\/.*/),
      select(getSelectedCamCardDetails),
      whenTruthy(),
      map(camCards =>
        setBreadcrumbData({
          breadcrumbData: [{ key: 'camfil.account.cam_card.link', link: '/account/camcards' }, { text: camCards.name }],
        })
      )
    )
  );

  detectCamCardToolbar$ = createEffect(() =>
    iif(
      () => !SSR,
      this.actions$.pipe(
        ofType(detectCamCardToolbar),
        concatLatestFrom(() => this.store.pipe(select(getDeviceType))),
        filter(([, device]) => device === 'mobile'),
        map(() => setStickyCamCardToolbar({ sticky: true }))
      ),
      EMPTY
    )
  );

  detectCamCardToolbarWhenScrolling$ = createEffect(() =>
    iif(
      () => !SSR,
      this.actions$.pipe(
        ofType(detectCamCardToolbar),
        mergeMap(() =>
          this.store.pipe(
            select(getDeviceType),
            mergeMap(device =>
              fromEvent(window, 'scroll').pipe(
                map(() => {
                  const bar = this.document.getElementsByTagName('camfil-account-cam-card-toolbar')?.[0] as HTMLElement;
                  if (bar) {
                    const barBounding = bar.getBoundingClientRect();
                    if (device === 'mobile') {
                      return window.innerHeight > barBounding.top + barBounding.height;
                    } else {
                      return barBounding.top < barBounding.height + bar.offsetTop;
                    }
                  } else {
                    return false;
                  }
                }),
                distinctUntilChanged(),
                map(sticky => setStickyCamCardToolbar({ sticky }))
              )
            )
          )
        )
      ),
      EMPTY
    )
  );

  // CamCard Import validation

  validateCamCardImport$ = createEffect(() =>
    this.actions$.pipe(
      ofType(validateCamCardImport),
      mapToPayload(),
      mergeMap(camCardData =>
        this.camCardService.validateCamCardImport(camCardData).pipe(
          map(validationResponse => validateCamCardImportSuccess({ validationResponse })),
          mapErrorToAction(validateCamCardImportFail)
        )
      )
    )
  );

  // CamCard Import

  importCamCard$ = createEffect(() =>
    this.actions$.pipe(
      ofType(importCamCard),
      mapToPayload(),
      mergeMap(camCardData =>
        this.camCardService.importCamCard(camCardData).pipe(
          mergeMap(payload => [
            importCamCardSuccess({ camCardData: payload }),
            displaySuccessMessage({
              message: 'CamCard has been imported',
            }),
          ]),
          mapErrorToAction(importCamCardFail)
        )
      )
    )
  );

  importCamCardSuccess$ = createEffect(() =>
    this.actions$.pipe(ofType(importCamCardSuccess), mapToPayload(), map(refreshCamCards))
  );

  displayImportCamCardFailMessage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(importCamCardFail),
      mapToPayloadProperty('error'),
      whenTruthy(),
      map(error =>
        displayErrorMessage({
          message: error?.message || error?.code,
        })
      )
    )
  );

  updateCamCardAttribute$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateCamCardAttribute),
      mapToPayload(),
      mergeMap(({ camCardId, camCardAttribute }) =>
        this.camCardService.updateCamCardAttribute(camCardId, camCardAttribute).pipe(
          map(camCard => updateCamCardAttributeSuccess({ camCard })),
          mapErrorToAction(updateCamCardFail)
        )
      )
    )
  );

  checkCamCardsInBasketsForAllUsers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(checkCamCardsInBasketsForAllUsers),
      mapToPayload(),
      mergeMap(({ camCardsId }) =>
        this.camCardService.checkCamCardsInBasketsForAllUsers(camCardsId).pipe(
          map(ids => checkCamCardsInBasketsForAllUsersSuccess({ camCardsId: ids })),
          mapErrorToAction(checkCamCardsInBasketsForAllUsersFail)
        )
      )
    )
  );

  loadCamCardsAfterOrderCreation = createEffect(() =>
    this.actions$.pipe(
      // because need to update last/nextDeliveryDate
      ofType(updateCamCardsAfterCreateOrder),
      mapToPayloadProperty('orders'),
      filter(orders => !orders[0]?.orderCreation || orders[0].orderCreation.status !== 'ROLLED_BACK'),
      map(orders =>
        orders
          .map(o =>
            CamfilAttributeHelper.getAttributeValueByAttributePartialName(o.attributes, 'CAMFIL_SOURCE_CAMCARD_ID_KEY')
          )
          .filter(Boolean)
      ),
      filter((ids: string[]) => ids.length > 0),
      map(refreshCamCards)
    )
  );

  setEditMode$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setEditMode),
      mapToPayload(),
      concatLatestFrom(() => [
        this.store.pipe(select(getSelectedCamCardDetails)),
        this.store.pipe(select(getCamCardEditMode)),
      ]),
      filter(([{ edit }, cc, currentEdit]) => currentEdit?.id !== cc?.id || !currentEdit || !edit),
      mergeMap(
        ([{ edit }, camCard]) =>
          (edit && [
            setEditModeSuccess({ camCard }),
            displaySuccessMessage({
              message: 'camfil.account.cam_card.edit_mode.on',
            }),
          ]) || [turnOffEditMode()]
      )
    )
  );

  turnOffEditMode$ = createEffect(() =>
    this.actions$.pipe(
      ofType(turnOffEditMode),
      mergeMap(() => [
        // TODO marcin
        // removeCheckoutFocusedElement(),
        setEditModeSuccess({}),
        displaySuccessMessage({
          message: 'camfil.account.cam_card.edit_mode.off',
        }),
      ])
    )
  );

  addToCartFromCamCards$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addToCartFromCamCards),
      mapToPayloadProperty('camCards'),
      concatLatestFrom(() => this.store.pipe(select(getCurrentBasketId))),
      concatMap(([camCards, id]) =>
        id
          ? [addToCartFromCamCardNextStep({ camCards })]
          : this.basketService.createBasket().pipe(
              mergeMap(basket => [createBasketSuccess({ basket }), addToCartFromCamCardNextStep({ camCards })]),
              mapErrorToAction(createBasketFail)
            )
      )
    )
  );

  addToCartFromCamCardNextStep$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addToCartFromCamCardNextStep),
      mapToPayloadProperty('camCards'),
      mergeMap(camCards =>
        this.camfilBasketService.addToCartFromCamCards(camCards).pipe(
          mergeMap(infos => [
            addToCartFromCamCardsSuccess({ infos }),
            displaySuccessMessage({
              message: 'camfil.add_items_to_basket.camfil.message.success',
            }),
          ]),
          mapErrorToAction(addToCartFromCamCardsFail)
        )
      )
    )
  );

  addToCartFromCamCardsSuccess$ = createEffect(() =>
    this.actions$.pipe(ofType(addToCartFromCamCardsSuccess), map(camfilAddItemsToBucketSuccess))
  );

  /** Action after update CamCard Contacts
   *
   * @param camCardId
   * @param contacts
   * @param contact
   */
  private handleCamCardContactsSuccess(camCardId: string, contacts: CamfilContact[], contact: CamfilContact) {
    const isInclude =
      !contacts.length || contacts.findIndex(newContact => newContact.profileId === contact?.profileId) > -1;
    return isInclude ? updateCamCardContactsSuccess({ camCardId, contacts }) : deleteCamCardSuccess({ camCardId });
  }

  private throttleOnBrowser<T>() {
    return !SSR && this.router.navigated ? throttleTime<T>(100) : map(identity);
  }
}
