import {
  ChangeDetectionStrategy, Component, createNgModule, OnInit, ViewChild, ViewContainerRef,
  Injector,
  ComponentRef,
  Input, OnChanges, 
  
  
} from '@angular/core';



import type { ContentIncludeComponent as OriginalComponent } from '../../../shared/cms/components/content-include/content-include.component';

@Component({
  selector: 'ish-lazy-content-include',
  templateUrl: './lazy-content-include.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LazyContentIncludeComponent implements OnInit , OnChanges {
  /*
   * WARNING!
   *
   * This file was automatically generated!
   * It should be updated using:
   *
   * ng g lazy-component shared/cms/components/content-include/content-include.component.ts
   *
   */

  @ViewChild('anchor', { read: ViewContainerRef, static: true }) anchor: ViewContainerRef;


  @Input() includeId: OriginalComponent['includeId'];


  private component: ComponentRef<OriginalComponent>;
  

  constructor(
    
    private injector: Injector
  ) {}

  async ngOnInit() {
    

      await this.renderComponent();

    
  }

  private async renderComponent() {
    const module = await import(`../../../shared/shared.module`).then(m => m.SharedModule);

    const { ContentIncludeComponent: originalComponent } = await import('../../../shared/cms/components/content-include/content-include.component');

    const ngModuleRef = createNgModule(module, this.injector);

    this.component = this.anchor.createComponent(originalComponent, { ngModuleRef });
  
    this.ngOnChanges(
      
    );
  
    this.component.changeDetectorRef.markForCheck();
  }


  ngOnChanges() {
    if (this.component) {
      
        this.component.instance.includeId = this.includeId;
      

      
        this.component.instance.ngOnChanges();
      
    }
  }

}
