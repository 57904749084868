/* eslint-disable ish-custom-rules/ban-imports-file-pattern */
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CAMFIL_NAMESPACE } from 'camfil-shared/camfil-constants';

import { ReducerStates } from './camfil-store.module';

export type CamfilState = {
  [K in keyof ReducerStates]: ReducerStates[K];
};

const getCamfilState = createFeatureSelector<CamfilState>(CAMFIL_NAMESPACE);

export const getCamfilFeatureState = <T>(featureName: unknown, initialState?: T) =>
  createSelector(getCamfilState, (state: CamfilState) => {
    // @ts-expect-error: test
    const feature = state[featureName];
    if (!feature) {
      if (initialState) {
        return initialState as T;
      } else {
        throw new Error(`Feature "${featureName}" not found in state.`);
      }
    }
    return feature as T;
  });
