import { createReducer, on } from '@ngrx/store';
import { CamfilAddress } from 'camfil-models/camfil-address/camfil-address.model';
import { CamfilBucket } from 'camfil-models/camfil-bucket/camfil-bucket.model';

import { Basket } from 'ish-core/models/basket/basket.model';
import { setLoadingOn } from 'ish-core/utils/ngrx-creators';

import { cancelBasketEditMode, setBasketToEditMode } from '../camfil-basket/camfil-basket.actions';

import { copyBasketSuccess } from './camfil-edit-basket.actions';

export const camfilEditBasketFeatureKey = 'camfilEditBasket';

export interface CamfilEditBasketState {
  basket: Basket;
  camfilBuckets: CamfilBucket[];
  basketAddresses: CamfilAddress[];
}

const initialState: CamfilEditBasketState = {
  basket: undefined,
  camfilBuckets: undefined,
  basketAddresses: undefined,
};

export const reducer = createReducer(
  initialState,
  setLoadingOn(setBasketToEditMode),
  on(copyBasketSuccess, (state: CamfilEditBasketState, action): CamfilEditBasketState => {
    // TODO: Check if basket and address data is still needed in edit mode reducer
    const { basket, camfilBuckets, addresses } = action.payload;

    const news = { basket, camfilBuckets, basketAddresses: addresses };
    return { ...state, ...news };
  }),
  on(cancelBasketEditMode, (): CamfilEditBasketState => ({ ...initialState }))
);
