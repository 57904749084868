import { CamfilQuoteServiceResponse } from 'camfil-models/camfil-quotes/camfil-quote-service-response/camfil-quote-service-response.model';
import {
  CamfilQuote,
  CamfilQuoteStatus,
  CamfilQuoteStatus as QuoteStatusEnum,
} from 'camfil-models/camfil-quotes/camfil-quote/camfil-quote.model';

const manufacturerParam = 'manufacturer';

export class CamfilQuoteHelper {
  static QUOTE_ID_PARAM_NAME = 'camfilQuoteId';

  static rootPath = '/account/quotes';

  static quotePath = `${manufacturerParam}/:${CamfilQuoteHelper.QUOTE_ID_PARAM_NAME}`;

  static urlRegExp = /^\/account\/quotes.*/;

  static generateQuoteUrl(quoteId?: string): string {
    let route = `/${CamfilQuoteHelper.rootPath}`;

    if (quoteId) {
      route += `/${manufacturerParam}/${quoteId}`;
    }

    return route;
  }

  static equal(quote1: CamfilQuote, quote2: CamfilQuote): boolean {
    return !!quote1 && !!quote2 && quote1.id === quote2.id;
  }

  static isSufficientlyLoaded(q: CamfilQuote): boolean {
    if (!!q && 'deliveryAddress' in q && 'items' in q) {
      return !!q.deliveryAddress && !!q.items.length && !!q.customer?.id;
    }

    return false;
  }

  static isQuotation(quote: CamfilQuote | CamfilQuoteServiceResponse): boolean {
    return quote?.quotationType?.toLowerCase() === 'quotation';
  }

  static isPrintable(quote: CamfilQuote) {
    return quote.status === CamfilQuoteStatus.Approved || quote.status === CamfilQuoteStatus.ReadyToApprove;
  }

  static getQuotationTitle(quote: CamfilQuote) {
    return `${CamfilQuoteHelper.isQuotation(quote) ? 'Quote' : 'Proposal'} - ${
      quote?.erpnumber || quote?.customerQuoteNumber
    }`;
  }

  static getQuotationSkus(quote: CamfilQuote) {
    return [
      ...new Set(
        quote?.items?.reduce((acc, item) => {
          acc.push(item.productSKU);
          return acc;
        }, [] as string[])
      ),
    ];
  }

  static isProposal(quote: CamfilQuote | CamfilQuoteServiceResponse): boolean {
    return quote?.quotationType?.toLowerCase() === 'proposal';
  }

  static canEditQuote(quote: CamfilQuote): boolean {
    return [QuoteStatusEnum.Requested, QuoteStatusEnum.Received, QuoteStatusEnum.ReadyToApprove].includes(
      quote?.status
    );
  }

  static isApprovable(quote: CamfilQuote): boolean {
    const isStatusValid = CamfilQuoteHelper.canEditQuote(quote);
    const isCamfilQuoteNumberValid = quote?.quotationType === 'quotation' ? !!quote?.ERPnumber : true;

    return isStatusValid && isCamfilQuoteNumberValid;
  }

  static getStatusByLogic(status: QuoteStatusEnum, erpId?: string) {
    // keep-localization-pattern: ^camfil\.quotes\.status\..*
    switch (status) {
      case 0:
        return 'camfil.quotes.status.requested';
      case 1:
        if (erpId) {
          return 'camfil.quotes.status.ready_to_approve';
        }
        return 'camfil.quotes.status.received';
      case 2:
        return 'camfil.quotes.status.approved';
      case 3:
        return 'camfil.quotes.status.rejected';
      case 4:
        return 'camfil.quotes.status.expired';
      case 5:
        return 'camfil.quotes.status.ready_to_approve'; // artificial status
      default:
        return 'camfil.quotes.status.unknown';
    }
  }

  static correctionForStatusNumber(quote: CamfilQuote) {
    return quote.status === 1 && quote.ERPnumber ? 5 : quote.status;
  }
}
