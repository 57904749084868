export class CamfilSearchHelper {
  static handleSearchTermWithSlash(searchTerm: string) {
    return searchTerm.replace(/\//g, ' ');
  }
}

export enum TypeOfSearchInput {
  Search = 1,
  Other = 2,
}
