import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { FormlyModule as FormlyBaseModule } from '@ngx-formly/core';
import { TranslateModule } from '@ngx-translate/core';
import { CamfilSearchInputWrapperComponent } from 'camfil-shared/formly/wrappers/camfil-search-input-wrapper/camfil-search-input-wrapper.component';

import { ComponentsModule } from 'ish-shared/formly/components/components.module';

const wrapperComponents = [CamfilSearchInputWrapperComponent];

@NgModule({
  imports: [
    CommonModule,
    ComponentsModule,
    MatFormFieldModule,
    MatIconModule,
    FormlyBaseModule.forChild({
      wrappers: [{ name: 'camfil-search-input-wrapper', component: CamfilSearchInputWrapperComponent }],
    }),
    ReactiveFormsModule,
    TranslateModule,
  ],
  declarations: [...wrapperComponents],
  exports: [...wrapperComponents],
})
export class CamfilFormlyWrappersModule {}
