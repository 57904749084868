import { CamfilChannelConfiguration } from './camfil-configuration.model';

export class CamfilConfigurationHelper {
  static getSettingsByChannelName(
    settings: { [key: string]: CamfilChannelConfiguration } | CamfilChannelConfiguration,
    channelName: string,
    channelKey: keyof CamfilChannelConfiguration = 'icmChannel'
  ): CamfilChannelConfiguration | undefined {
    if (typeof settings === 'object' && settings[channelKey] === channelName) {
      return settings as CamfilChannelConfiguration;
    }

    let result: CamfilChannelConfiguration | undefined = undefined;
    let p: keyof typeof settings;

    for (p in settings) {
      if (settings.hasOwnProperty(p) && typeof settings[p] === 'object') {
        const s = settings[p] as { [key: string]: CamfilChannelConfiguration } | CamfilChannelConfiguration;
        result = CamfilConfigurationHelper.getSettingsByChannelName(s, channelName, channelKey);
        if (result) {
          return result;
        }
      }
    }

    return result;
  }
}
