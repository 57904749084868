import { Inject, Injectable, Optional } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { CAMFIL_QUEUE_ACTION_CONFIG } from 'camfil-core/configurations/camfil-injection-keys';
import { placeCamfilOrder } from 'camfil-core/store/camfil-basket/camfil-basket.actions';
import { clearQueue, enqueueTask, retryTask } from 'camfil-core/store/camfil-queue/camfil-queue.actions';
import {
  selectAllTaskGroups,
  selectAllTasksByStatus,
  selectCurrentProcessingTask,
} from 'camfil-core/store/camfil-queue/camfil-queue.selectors';
import { CamfilAction } from 'camfil-models/camfil-action/camfil-action.model';
import { CamfilQueueStats, CamfilQueueTaskStatus } from 'camfil-models/camfil-queue/camfil-queue.model';
import { BehaviorSubject, Observable, map, merge } from 'rxjs';
import { debounceTime, filter, take, tap } from 'rxjs/operators';
import { v4 as uuid } from 'uuid';

import { InjectMultiple } from 'ish-core/utils/injection';
import { whenTruthy } from 'ish-core/utils/operators';

@Injectable({ providedIn: 'root' })
export class CamfilQueueFacade<V extends CamfilAction = CamfilAction> {
  private source$: Observable<V>;
  private queuedSource$: Observable<V> = this.store.pipe(
    debounceTime(20),
    select(selectCurrentProcessingTask),
    whenTruthy(),
    map(
      task =>
        // eslint-disable-next-line ish-custom-rules/no-object-literal-type-assertion
        ({
          payload: task.actionPayload,
          type: task.actionType,
          uuid: task.id,
        } as V)
    )
  );

  private isProcessingSubject = new BehaviorSubject<boolean>(false);

  isProcessing = false;

  isProcessing$ = this.isProcessingSubject.asObservable();

  taskGroups$: Observable<CamfilQueueStats[]> = this.store.pipe(select(selectAllTaskGroups)).pipe(
    map(statsArray =>
      statsArray.map(taskGroup => {
        const label = this.getQueuedActionConfigByType(taskGroup.type)?.label || taskGroup.type;
        return { ...taskGroup, label };
      })
    )
  );

  hasRemainingTasks$ = this.store.pipe(
    select(selectAllTasksByStatus(CamfilQueueTaskStatus.Queued)),
    map(tasks => !!tasks?.length)
  );

  isCreatingOrder$ = this.store.pipe(
    select(selectCurrentProcessingTask),
    map(task => task?.actionType === placeCamfilOrder.type)
  );

  constructor(
    @Optional()
    @Inject(CAMFIL_QUEUE_ACTION_CONFIG)
    private readonly queuedActions: InjectMultiple<typeof CAMFIL_QUEUE_ACTION_CONFIG>,
    private store: Store
  ) {
    this.store
      .pipe(debounceTime(20), select(selectAllTasksByStatus(CamfilQueueTaskStatus.Processing)))
      .subscribe(processingTasks => {
        this.isProcessing = !!processingTasks?.length;
        this.isProcessingSubject.next(this.isProcessing);
      });
  }

  private isQueuedAction(action: V): boolean {
    return this.queuedActions?.some(q => q?.actionType.type === action.type);
  }

  private getQueuedActionConfigByType(type: string) {
    return this.queuedActions?.find(q => q.actionType.type === type);
  }

  private enqueueAction(queuedAction: V) {
    const queuedActionConfig = this.getQueuedActionConfigByType(queuedAction?.type);

    if (!queuedActionConfig) {
      return;
    }

    this.store.dispatch(
      enqueueTask({
        task: {
          id: queuedAction.uuid,
          actionType: queuedAction.type,
          actionPayload: queuedAction?.payload,
          successActionType: queuedActionConfig.successActionType.type,
          failureActionType: queuedActionConfig.failureActionType.type,
          status: CamfilQueueTaskStatus.Queued,
          enqueueTime: Date.now(),
        },
      })
    );
  }

  init$(source: Observable<V>): Observable<V> {
    this.source$ = source.pipe(
      map(action => ({
        ...action,
        uuid: uuid(),
      }))
    );

    this.source$
      .pipe(
        filter(this.isQueuedAction.bind(this)), // Only continue with actions that should be queued
        tap(this.enqueueAction.bind(this)) // Enqueue actions that pass the filter
      )
      .subscribe({
        error: error => {
          console.error('Error in queue facade:', error);
        },
      });

    return merge(this.source$.pipe(filter(action => !this.isQueuedAction(action))), this.queuedSource$);
  }

  retryFailedTasksByType(type: string) {
    this.store
      .pipe(
        select(selectAllTasksByStatus(CamfilQueueTaskStatus.Failed)),
        map(tasks => tasks.filter(t => t.actionType === type)),
        take(1)
      )
      .subscribe(failedTasks => {
        failedTasks.forEach(failedTask => {
          this.store.dispatch(retryTask({ id: failedTask.id }));
        });
      });
  }

  clearQueues() {
    this.store.dispatch(clearQueue());
  }
}
