import { NgModule } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
  CAMFIL_PDF_DEFAULT_STYLE,
  CAMFIL_PDF_FONTS,
  CAMFIL_PDF_LABELS_FACTORY,
  CAMFIL_PDF_MAKER,
} from 'camfil-core/configurations/camfil-injection-keys';
import { CamfilPdfLabels } from 'camfil-shared/cam-pdf/models/pdf.model';
import { TDocumentDefinitions, TFontDictionary } from 'pdfmake/interfaces';

@NgModule({
  providers: [
    {
      provide: CAMFIL_PDF_MAKER,
      useFactory: async () => await import('pdfmake/build/pdfmake'),
    },
    {
      provide: CAMFIL_PDF_DEFAULT_STYLE,
      useValue: <Partial<TDocumentDefinitions['defaultStyle']>>{
        font: 'Roboto',
      },
    },
    {
      provide: CAMFIL_PDF_FONTS,
      useValue: <TFontDictionary>{
        Roboto: {
          normal: 'https://cdn.jsdelivr.net/fontsource/fonts/roboto@latest/latin-400-normal.ttf',
          bold: 'https://cdn.jsdelivr.net/fontsource/fonts/roboto@latest/latin-700-normal.ttf',
          italics: 'https://cdn.jsdelivr.net/fontsource/fonts/roboto@latest/latin-400-italic.ttf',
          bolditalics: 'https://cdn.jsdelivr.net/fontsource/fonts/roboto@latest/latin-700-italic.ttf',
        },
        DejaVuSans: {
          normal: 'https://cdn.jsdelivr.net/fontsource/fonts/dejavu-sans@latest/latin-400-normal.ttf',
          bold: 'https://cdn.jsdelivr.net/fontsource/fonts/dejavu-sans@latest/latin-700-normal.ttf',
          italics: 'https://cdn.jsdelivr.net/fontsource/fonts/dejavu-sans@latest/latin-400-italic.ttf',
          bolditalics: 'https://cdn.jsdelivr.net/fontsource/fonts/dejavu-sans@latest/latin-700-italic.ttf',
        },
      },
    },
    {
      provide: CAMFIL_PDF_LABELS_FACTORY,
      useFactory: (translate: TranslateService) => (): CamfilPdfLabels => ({
        artNr: translate.instant('camfil.account.cam_card.pdf.art_nr'),
        boxLabel: translate.instant('camfil.account.cam_card.pdf.box_label'),
        contactPerson: translate.instant('camfil.account.pdf.contact_person'),
        customerAccount: translate.instant('camfil.account.cam_card.pdf.customer_account'),
        deliveryAddress: translate.instant('camfil.account.cam_card.pdf.delivery_address'),
        deliveryDate: translate.instant('camfil.account.pdf.delivery_date'),
        deliveryDays: translate.instant('camfil.account.pdf.deliver_days'),
        goodsAcceptanceNote: translate.instant('camfil.checkout.cta.goods_acceptance'),
        info: translate.instant('camfil.account.pdf.information'),
        invoiceLabel: translate.instant('camfil.account.pdf.invoice_label'),
        invoiceMark: translate.instant('camfil.account.cam_card.pdf.invoice_mark'),
        label: translate.instant('camfil.account.cam_card.pdf.box_label'),
        lastOrder: translate.instant('camfil.account.cam_card.pdf.last_order'),
        leadTime: translate.instant('camfil.account.cam_card.pdf.lead_time'),
        measurements: translate.instant('camfil.account.cam_card.pdf.measurements'),
        nextOrder: translate.instant('camfil.account.cam_card.pdf.next_order'),
        orderHeader: [
          translate.instant('camfil.dynamic.checkout.order'),
          translate.instant('camfil.dynamic.checkout.order_quote'),
          translate.instant('camfil.dynamic.checkout.order_requisition'),
        ],
        orderInterval: translate.instant('camfil.account.cam_card.pdf.order_interval'),
        orderMark: translate.instant('camfil.account.cam_card.pdf.order_mark'),
        phoneNumber: translate.instant('camfil.account.pdf.phone_number'),
        phoneNotification: translate.instant('camfil.account.orderdetails.phone_notification.label'),
        price: translate.instant('camfil.account.cam_card.pdf.price'),
        printDate: translate.instant('camfil.account.cam_card.pdf.print_date'),
        printedBy: translate.instant('camfil.account.cam_card.pdf.printed_by'),
        requestedBy: translate.instant('camfil.quotes.quote_detail.requested_by'),
        quantity: translate.instant('camfil.account.cam_card.pdf.quantity'),
        yourTotal: translate.instant('camfil.account.cam_card.pdf.your_total'),
        creationDate: translate.instant('camfil.quotes.quoteslist.columns.requested_date'),
        validToDate: translate.instant('camfil.quotes.quote_detail.expiration_date'),
        total: translate.instant('camfil.account.orderdetails.your.total_price.text'),
        subtotal: translate.instant('camfil.account.orderdetails.your.total_price_exl_vat.text'),
        freightCost: translate.instant('camfil.quotes.quote_detail.freightCost'),
        tax: translate.instant('camfil.account.orderdetails.your.tax_amount.text'),
        note: translate.instant('camfil.quotes.quote_detail.note'),
        totalQuantity: translate.instant('camfil.quotes.quote_details.summary.qty'),
        deliveryTerms: translate.instant('camfil.quotes.quote_detail.delivery.terms'),
        paymentTerms: translate.instant('camfil.quotes.quote_details.payment.terms'),
      }),
      deps: [TranslateService],
    },
  ],
})
export class CamfilPrintModule {}
