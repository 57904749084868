import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  inject,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Observable, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { v4 as uuid } from 'uuid';

@Component({
  selector: 'camfil-counter',
  templateUrl: './camfil-counter.component.html',
  styleUrls: ['./camfil-counter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CamfilCounterComponent implements OnInit, OnChanges {
  @Input() min: number;
  @Input() max: number;
  @Input() isInLineItem = false;
  @Input() lineItemId: string;
  @Input() stepQuantityValue = 1;
  @Input() focusInputOnly = false;

  @Output() focusInput = new EventEmitter();

  @Input() form: FormGroup;
  /**
   * control name or an array of control names, corresponding controls with this name(s) should exist in the form group (required)
   */
  @Input() controlName: string | string[];
  /**
   * error messages for each validator of the control(s)
   */

  /**
   * Label for the input/select field(s)
   */
  @Input() label: string;
  /**
   css-class for the label (default: 'col-md-4')
   */
  @Input() labelClass = 'col-md-4';
  /**
   css-class for the input/select field (default: 'col-md-8')
   */
  @Input() inputClass = 'col-md-8';

  uuid: string;

  get formControl(): AbstractControl {
    return this.form.get(this.getControlName());
  }

  /**
   * get the form control according to the controlName or first element of the controlName array
   */

  value$ = new ReplaySubject<number>(1);
  cannotDecrease$: Observable<boolean>;
  cannotIncrease$: Observable<boolean>;

  private destroyRef = inject(DestroyRef);

  constructor(protected translate: TranslateService) {}

  /**
   * get the form control according to the controlName or first element of the controlName array
   */
  getControlName(): string {
    return Array.isArray(this.controlName)
      ? this.controlName.length
        ? this.controlName[0]
        : undefined
      : this.controlName;
  }

  init() {
    if (!this.form) {
      throw new Error('required input parameter <form> is missing for FormElementComponent');
    }
    if (!this.controlName) {
      throw new Error('required input parameter <controlName> is missing for FormElementComponent');
    }
    if (!this.formControl) {
      throw new Error(
        `input parameter <controlName> with value '${this.getControlName()}' does not exist in the given form for FormElementComponent`
      );
    }

    this.uuid = uuid();
  }

  handleInputKeyboardEvent(event: KeyboardEvent) {
    if (event.code === 'Enter' || event.code === 'NumpadEnter') {
      event.preventDefault();
    }
  }

  private get value(): number {
    return +this.formControl.value;
  }

  ngOnChanges() {
    this.value$.next(this.value);
  }

  ngOnInit() {
    this.init();
    this.cannotDecrease$ = this.value$.pipe(map(value => this.min !== undefined && value <= this.min));
    this.cannotIncrease$ = this.value$.pipe(map(value => this.max !== undefined && value >= this.max));

    this.formControl.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(this.value$);
  }

  increase() {
    (this.formControl as FormControl).setValue(this.calculateIncreaseValue(this.value, this.stepQuantityValue), {
      emitEvent: true,
    });
  }

  decrease() {
    (this.formControl as FormControl).setValue(this.calculateDecreaseValue(this.value, this.stepQuantityValue), {
      emitEvent: true,
    });
  }

  get displayLabel(): boolean {
    return !!this.label && !!this.label.trim();
  }

  setFocusedElement(target: EventTarget, isInput = false) {
    const element = target as HTMLElement;
    if (element.id && (isInput || !this.focusInputOnly)) {
      this.focusInput.emit();
    }
  }

  private calculateDecreaseValue(value: number, stepQuantityValue: number) {
    return value - stepQuantityValue >= 0 ? value - stepQuantityValue : 0;
  }

  private calculateIncreaseValue(value: number, stepQuantityValue: number) {
    return (value + stepQuantityValue) % stepQuantityValue === 0
      ? value + stepQuantityValue
      : Math.ceil(value / stepQuantityValue) * stepQuantityValue;
  }
}
