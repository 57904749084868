<mat-form-field class="w-100" *ngIf="(citiesList$ | async)?.length === 1; else citiesSelect">
  <mat-label>{{ 'camfil.account.cam_card.add_to_form.city' | translate }}</mat-label>
  <input
    matInput
    [formControl]="formControl"
    [formlyAttributes]="field"
    [type]="to.type"
    required
    markRequiredLabel="off"
    readonly
  />
  <mat-error>
    <formly-validation-message [field]="field" />
  </mat-error>
</mat-form-field>

<ng-template #citiesSelect>
  <mat-form-field class="w-100">
    <mat-label>{{ 'camfil.form.pick_city' | translate }}*</mat-label>
    <mat-select [formControl]="formControl" (selectionChange)="pickCity($event.value)">
      <mat-option *ngFor="let city of citiesList$ | async" [value]="city.city">
        {{ city.city }}
      </mat-option>
    </mat-select>
    <mat-error>
      <formly-validation-message [field]="field" />
    </mat-error>
  </mat-form-field>
</ng-template>
