import { ChangeDetectionStrategy, Component, DestroyRef, OnInit, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { CamCardsFacade } from 'camfil-core/facades/camfil-cam-cards.facade';
import { CamfilDialogFacade } from 'camfil-core/facades/camfil-dialog.facade';
import { filter } from 'rxjs';

import { CamfilAddCamCardFeedbackDialogComponent } from '../camfil-add-cam-card-feedback-dialog/camfil-add-cam-card-feedback-dialog.component';

@Component({
  selector: 'camfil-add-cam-card-feedback',
  templateUrl: './camfil-add-cam-card-feedback.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CamfilAddCamCardFeedbackComponent implements OnInit {
  private destroyRef = inject(DestroyRef);

  constructor(private camCardsFacade: CamCardsFacade, private camfilDialogFacade: CamfilDialogFacade) {}

  ngOnInit(): void {
    this.camCardsFacade.getCamCardsFeedback$
      .pipe(
        filter(infos => !!infos?.length),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(() => {
        this.camfilDialogFacade.open(CamfilAddCamCardFeedbackDialogComponent);
      });
  }
}
