<div id="search-input" class="search-input" [style.--prompt-height]="config.promptHeight">
  <input
    #searchInput
    matInput
    type="search"
    autocomplete="off"
    class="search-input__prompt"
    [value]="value"
    [placeholder]="config.placeholder | translate"
    (input)="onInput($event)"
    (focus)="onFocusChange(true)"
    (blur)="onFocusChange(false)"
    (keydown.enter)="onSearch(searchInput.value)"
  />
  <mat-icon class="search-input__icon" svgIcon="search" />
  <button class="search-input__btn" mat-flat-button color="primary" (click)="onSearch(searchInput.value)">
    {{ config.btnText | translate }}
  </button>
</div>
