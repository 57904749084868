<div class="camfil-queue {{ pageClassName$ | async }}" *ngIf="showQueue$ | async">
  <mat-accordion>
    <mat-expansion-panel [expanded]="expandQueue$ | async" (closed)="collapseQueue()" (opened)="expandQueue()">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <strong>{{ 'camfil.queue.title' | translate }}</strong>
        </mat-panel-title>
        <mat-icon *ngIf="(expandQueue$ | async) === false" class="custom-expansion-indicator expanded"
          >arrow_drop_up</mat-icon
        >
        <mat-icon *ngIf="(expandQueue$ | async) === true" class="custom-expansion-indicator collapsed"
          >arrow_drop_down</mat-icon
        >
        <button
          mat-icon-button
          [disabled]="hasRemainingTasks$ | async"
          (click)="hideQueue()"
          (keydown.enter)="hideQueue()"
          class="camfil-queue-close"
        >
          <mat-icon>close</mat-icon>
        </button>
      </mat-expansion-panel-header>
      <mat-divider />
      <ul role="list" *ngFor="let taskGroup of taskGroups$ | async">
        <li role="listitem" *ngIf="taskGroup.total !== 0">
          <div class="camfil-queue-item">
            <span class="d-block" [matTooltip]="taskGroup.label | translate">
              <small class="badge badge-secondary px-2 py-1">
                {{ 'camfil.queue.counter' | translate : { '0': taskGroup.processed, '1': taskGroup.total } }}
              </small>
              {{ taskGroup.label | translate | camfilTruncate : 40 }}
              <small class="text-muted camfil-queue-task-id align-baseline d-inline-block">
                &nbsp;{{ taskGroup.processingId | camfilTruncate : 10 }}
              </small>
            </span>

            <ng-container *ngIf="taskGroup.failed > 0; else statusIconTemplate">
              <button
                mat-icon-button
                class="camfil-queue-status-icon"
                [disabled]="!!taskGroup.processing"
                [matTooltip]="'camfil.queue.button.retry_failed_tasks' | translate"
                (click)="retryFailedTasksByType(taskGroup.type)"
                (keydown.enter)="retryFailedTasksByType(taskGroup.type)"
              >
                <mat-icon>refresh</mat-icon>
              </button>
            </ng-container>
            <ng-template #statusIconTemplate>
              <button
                mat-icon-button
                class="camfil-queue-status-icon"
                [matTooltip]="
                  '📈: ' +
                  taskGroup.progress +
                  '%' +
                  ', ⏳: ' +
                  taskGroup.remaining +
                  ', ❌: ' +
                  taskGroup.failed +
                  ', ✔: ' +
                  taskGroup.completed +
                  ', T: ' +
                  taskGroup.total
                "
              >
                <mat-icon>
                  {{
                    taskGroup.failed
                      ? 'error'
                      : taskGroup.progress === 100
                      ? taskGroup.failed > 0
                        ? 'unpublished'
                        : 'check_circle'
                      : 'radio_button_unchecked'
                  }}
                </mat-icon>
              </button>
            </ng-template>
          </div>
          <mat-progress-bar mode="determinate" [value]="taskGroup.progress" />
        </li>
      </ul>
    </mat-expansion-panel>
  </mat-accordion>
</div>

<camfil-add-cam-card-feedback />
<camfil-order-feedback />
