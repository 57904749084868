import { InjectionToken } from '@angular/core';
import { CamfilQueueActionConfig } from 'camfil-models/camfil-queue/camfil-queue.model';
import { CamfilPdfLabels } from 'camfil-shared/cam-pdf/models/pdf.model';
import { TDocumentDefinitions, TFontDictionary } from 'pdfmake/interfaces';

export const CAMFIL_QUEUE_ACTION_CONFIG = new InjectionToken<CamfilQueueActionConfig>('CAMFIL_QUEUE_ACTION_CONFIG');

export const CAMFIL_PDF_MAKER = new InjectionToken<Promise<typeof import('pdfmake/build/pdfmake')>>('CAMFIL_PDF_MAKER');

export const CAMFIL_PDF_DEFAULT_STYLE = new InjectionToken<Partial<TDocumentDefinitions['defaultStyle']>>(
  'CAMFIL_PDF_DEFAULT_STYLE'
);

export const CAMFIL_PDF_FONTS = new InjectionToken<TFontDictionary>('CAMFIL_PDF_FONTS');

export const CAMFIL_PDF_LABELS_FACTORY = new InjectionToken<() => CamfilPdfLabels>('CAMFIL_PDF_TEXTS');

export const CAMFIL_APP_FULL_BASE_URL = new InjectionToken<string>('CAMFIL_APP_FULL_BASE_URL');
