<span class="has-feedback">
  <fa-icon
    *ngIf="showError; else noError"
    class="form-control-feedback"
    [icon]="['fas', 'times']"
    data-testing-id="times-icon"
  />
  <ng-template #noError>
    <span class="has-success">
      <fa-icon
        *ngIf="field?.props?.showValidation ? field.props.showValidation(field) : defaultShowValidation()"
        class="form-control-feedback"
        [icon]="['fas', 'check']"
        data-testing-id="check-icon"
      />
    </span>
  </ng-template>
</span>
