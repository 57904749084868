import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { CamfilImageTypeIds } from 'camfil-models/camfil-image/camfil-image.types';
import { CamfilProductHelper } from 'camfil-models/camfil-product/product.helper';

import { Image } from 'ish-core/models/image/image.model';
import { ProductView } from 'ish-core/models/product-view/product-view.model';

@Component({
  selector: 'camfil-product-image',
  templateUrl: './camfil-product-image.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CamfilProductImageComponent implements OnChanges {
  @Input() imageView: undefined | string;
  @Input() imageType: undefined | CamfilImageTypeIds | CamfilImageTypeIds[];
  @Input() product: undefined | ProductView;
  @Input() width: undefined | 'auto' | number;
  @Input() height: undefined | 'auto' | number;

  image:
    | undefined
    | {
        url: string;
        alt: undefined | string;
      };

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.shouldUpdateImage(changes)) {
      return;
    }

    this.resetImage();

    if (this.product === undefined || this.imageType === undefined) {
      return;
    }

    const image = this.findPrimaryImage();

    if (image === undefined) {
      return;
    }

    this.updateImageUrlAndAlt(image);
  }

  private shouldUpdateImage(changes: SimpleChanges): boolean {
    return 'imageType' in changes || 'product' in changes || 'width' in changes || 'height' in changes;
  }

  private resetImage(): void {
    this.image = undefined;
  }

  private findPrimaryImage(): Image | undefined {
    let image: undefined | Image = undefined;

    const imageTypes = Array.isArray(this.imageType) ? this.imageType : [this.imageType];

    for (let imageTypeIndex = 0; imageTypeIndex < imageTypes.length && image === undefined; imageTypeIndex++) {
      image = CamfilProductHelper.getPrimaryImage(this.product, imageTypes[imageTypeIndex]);
    }

    return image;
  }

  private updateImageUrlAndAlt(image: Image): void {
    let url: URL | undefined = undefined;

    try {
      url = new URL(image.effectiveUrl);
    } catch (error) {
      return;
    }

    this.setDimensions(url);

    this.image = {
      url: url.toString(),
      alt: this.product.name || this.product.sku || undefined,
    };
  }

  private setDimensions(url: URL): void {
    if (this.height !== undefined) {
      url.searchParams.set('height', String(this.height));
    }

    if (this.width !== undefined) {
      url.searchParams.set('width', String(this.width));
    }
  }
}
