// eslint-disable-next-line ish-custom-rules/ban-imports-file-pattern
import { isEqual, omit } from 'lodash-es';
import { OperatorFunction } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';

/**
 * Skip relations so it will not trigger the subscribe/next when relations are changed;
 *
 * @param relations
 */
export function skipRelations<T extends object>(relations: string[]): OperatorFunction<T[], T[]> {
  return source$ =>
    source$.pipe(
      map(items => items.map(item => omit(item, relations))),
      distinctUntilChanged(isEqual)
    );
}

export function unpackHeap<T>(): OperatorFunction<[], T[]> {
  return map(data => (!!data && !!data.length ? data : []));
}
