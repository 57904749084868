import { createSelector } from '@ngrx/store';
import { CamfilBasket } from 'camfil-models/camfil-basket/camfil-basket.model';

import { CamfilBasketState, camfilBasketAdapter, camfilBasketFeatureKey } from '../camfil-basket/camfil-basket.reducer';
import { getOrdersLoading } from '../camfil-orders/camfil-orders.selectors';
import { getCamfilFeatureState } from '../camfil-store';

const getCamfilBasketState = getCamfilFeatureState<CamfilBasketState>(camfilBasketFeatureKey);

const { selectEntities } = camfilBasketAdapter.getSelectors(getCamfilBasketState);

export const getCamfilBasketLoading = createSelector(getCamfilBasketState, camfilBasket => camfilBasket.loading);

export const getSelectedCamfilBasketId = createSelector(getCamfilBasketState, state => state.selected);

export const getSelectedCamfilBasket = createSelector(
  selectEntities,
  getSelectedCamfilBasketId,
  (entities, id): CamfilBasket => id && entities[id]
);

export const getCalculatedCamfilBasket = createSelector(
  getSelectedCamfilBasket,
  camfilBasket => camfilBasket?.calculated
);

export const getBucketEditMode = createSelector(
  getSelectedCamfilBasket,
  camfilBasket => camfilBasket?.currentEditedBucketId
);

export const getCamfilBasketAddresses = createSelector(
  getSelectedCamfilBasket,
  camfilBasket => camfilBasket?.basketAddresses
);

export const getCurrentCamfilBasketOrderType = createSelector(
  getSelectedCamfilBasket,
  camfilBasket => camfilBasket?.orderType
);

export const getCamfilBasketValidationResults = createSelector(
  getSelectedCamfilBasket,
  camfilBasket => camfilBasket?.validationResults
);

export const isProductsReadyToPlaceOrder = createSelector(
  getCamfilBasketLoading,
  getOrdersLoading,
  getCamfilBasketValidationResults,
  (camfilBasketLoading, ordersLoading, validation) => (!camfilBasketLoading || !ordersLoading) && validation?.valid
);

export const showSubmitedBasket = createSelector(
  getCamfilBasketState,
  camfilBasket => camfilBasket?.showSubmitedBasket
);

export const getSubmitedBasket = createSelector(getCamfilBasketState, camfilBasket => camfilBasket?.submitedBasket);
