import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { CamfilPricesService } from 'camfil-core/services/prices/prices.service';
import { filter, map, mergeMap } from 'rxjs/operators';

import { getCurrentCurrency } from 'ish-core/store/core/configuration';
import { getLoggedInCustomer, loginUserSuccess, logoutUser } from 'ish-core/store/customer/user';
import { getProductEntities } from 'ish-core/store/shopping/products';
import { mapErrorToAction, mapToPayload } from 'ish-core/utils/operators';

import {
  getCustomerPricesForProductsSuccess,
  loadCustomerPrices,
  loadCustomerPricesFail,
  loadCustomerPricesSuccess,
} from './customer-prices.actions';
import { getCustomersPrices } from './customer-prices.selectors';

@Injectable()
export class CustomerPricesEffects {
  constructor(private actions$: Actions, private store: Store, private camfilPricesService: CamfilPricesService) {}
  loadCustomerPrices$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadCustomerPrices),
      mapToPayload(),
      filter(({ skus }) => !!skus.length),
      concatLatestFrom(() => [
        this.store.pipe(select(getCustomersPrices)),
        this.store.pipe(select(getCurrentCurrency)),
      ]),
      mergeMap(([{ customerId, skus }, prices, currency]) => {
        const getAction = (arr: string[]) =>
          this.camfilPricesService.loadCustomerPrices(customerId, arr, currency).pipe(
            map(products => loadCustomerPricesSuccess({ customerId, products })),
            mapErrorToAction(loadCustomerPricesFail, { customerId })
          );

        if (!prices?.[customerId]) {
          return getAction(skus);
        } else {
          const newSkus = skus.filter(sku => !prices[customerId].find(prod => prod.sku === sku));
          return newSkus.length ? getAction(newSkus) : [loadCustomerPricesSuccess({ customerId, products: [] })];
        }
      })
    )
  );
  loginUserSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loginUserSuccess, logoutUser),
      concatLatestFrom(() => [
        this.store.pipe(select(getProductEntities)),
        this.store.pipe(select(getCurrentCurrency)),
        this.store.pipe(select(getLoggedInCustomer)),
      ]),
      filter(([, entities]) => !!Object.keys(entities).length),
      mergeMap(([, entities, currency, customer]) => {
        const skus = Object.keys(entities);
        const customerId = customer?.customerNo || '-';
        return this.camfilPricesService.loadCustomerPrices(customerId, skus, currency).pipe(
          mergeMap(products => [
            loadCustomerPricesSuccess({ customerId, products }),
            getCustomerPricesForProductsSuccess({ products }),
            // ...products.map(({ sku, ...changes }) => updateProduct({ sku, changes })),
          ]),
          mapErrorToAction(loadCustomerPricesFail, { customerId })
        );
      })
    )
  );
}
