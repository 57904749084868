import { CamfilChannelConfiguration } from 'camfil-models/camfil-configuration/camfil-configuration.model';

const camfilConfiguration: CamfilChannelConfiguration = {
  allowQuotes: true,
  allowToSelectGoodsAcceptanceTimes: true,
  continueShoppingUrl: '/home',
  timeZone: 'Europe/Berlin',
  countryCode: 'DE',
  countryCode3: 'DEU',
  countryCodeRegExp: '^\\w{2}$',
  filterDocsByLanguage: false,
  goodsAcceptanceTimeMandatory: true,
  hideTimeOnOrderHistoryDate: true,
  icmChannel: 'Camfil-CamfilDE-Site',
  includeAllCustomerCamCards: false,
  maxLength: {
    orderMark: 60,
    invoiceLabel: 60,
    company: 60,
    boxLabel: 40,
    name: 40,
    camCardTitle: 60,
    customerNote: 150,
    goodsAcceptanceNote: 60,
    addressLineOne: 50,
    addressLineTwo: 200,
    phoneNumber: 30,
    customerServiceNote: 500,
  },
  minLength: {
    searchTerm: 3,
  },
  preserveSearchFilters: false,
  preventCamCardERPIdValidation: true,
  showAHUComingSoon: true,
  showAddToCartButtonForNonLoggedInUser: true,
  showAllDocsType: true,
  showDeliveryIntervalOnCCDetailPage: true,
  showDutiesAndSurchargesTotalInBasketSummary: true,
  showPricesInCamCards: true,
  showPrintButtonOnQuotations: true,
  showSubTotalInBasketSummary: true,
  showTitleFieldInRegisterForm: true,
  showWarningWhenSystemCouldNotCalculateVolumeOrWeight: true,
  use2ndAddressLineInCamCardForm: true,
  use2ndAddressLineInOrderForm: true,
  useMaterialTypography: true,
  zipCodeRegExp: '^\\d{5}$',
};

export default camfilConfiguration;
