import { ChangeDetectionStrategy, Component, OnInit, TransferState } from '@angular/core';

import { DISPLAY_VERSION } from 'ish-core/configurations/state-keys';

@Component({
  selector: 'camfil-footer',
  templateUrl: './camfil-footer.component.html',
  styleUrls: ['./camfil-footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CamfilFooterComponent implements OnInit {
  appVersion: string;

  constructor(private transferState: TransferState) {}

  ngOnInit() {
    if (!SSR) {
      this.appVersion = this.transferState.get(DISPLAY_VERSION, '');
    }
  }
}
