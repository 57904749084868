import { Injectable } from '@angular/core';
import { CamfilUserData } from 'camfil-models/camfil-user/camfil-user.interface';
import { pick } from 'lodash-es';
import { Observable, throwError } from 'rxjs';

import { ApiService, AvailableOptions } from 'ish-core/services/api/api.service';

import { CamfilUser, CamfilUsernameReminder } from '../../../models/camfil-user/camfil-user.model';

@Injectable({ providedIn: 'root' })
export class CamfilUserService {
  constructor(private apiService: ApiService) {}

  /**
   * Request username(s) via email for the customer accounts connected with email.
   *
   * @param data  The user email.
   */
  requestUsernameReminder(data: CamfilUsernameReminder) {
    const options: AvailableOptions = {
      skipApiErrorHandling: true,
      captcha: pick(data, ['captcha', 'captchaAction']),
    };

    return this.apiService.post('mailing/remind-user-name', { answer: '', ...data }, options);
  }

  applyForAnAccount(data: CamfilUser): Observable<CamfilUserData> {
    if (!data) {
      return throwError(() => new Error('applyForAnAccount() called without data'));
    }

    return this.apiService.post<CamfilUser>('mailing/register-request', data, {
      captcha: pick(data, ['captcha', 'captchaAction']),
    });
  }
}
