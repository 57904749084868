<mat-form-field appearance="outline" floatLabel="always">
  <mat-label>{{ 'camfil.cam_card.product.quantity' | translate }}</mat-label>
  <button
    mat-icon-button
    matPrefix
    type="button"
    [attr.aria-label]="'product.quantity.decrease.text' | translate"
    (click)="onDecrease()"
    [disabled]="(cantDecrease$ | async) || amount.root.disabled"
  >
    <mat-icon svgIcon="minus" />
  </button>
  <input matInput [formControl]="amount" type="number" />
  <button
    mat-icon-button
    matSuffix
    type="button"
    [attr.aria-label]="'product.quantity.increase.text' | translate"
    (click)="onIncrease()"
    [disabled]="(cantIncrease$ | async) || amount.root.disabled"
  >
    <mat-icon svgIcon="plus" />
  </button>
  <mat-error *ngIf="amount.errors?.type">
    {{ 'camfil.modal.addNewProduct.error.quantity.type' | translate }}
  </mat-error>
  <mat-error *ngIf="amount.errors?.min">
    {{ 'camfil.modal.addNewProduct.error.min.quantity' | translate }}
  </mat-error>
  <mat-error *ngIf="amount.errors?.max">
    {{ 'camfil.modal.addNewProduct.error.max.quantity' | translate : { '0': maxQty$ | async } }}
  </mat-error>
  <mat-error *ngIf="amount.errors?.step">
    {{ 'camfil.modal.addNewProduct.error.step.quantity' | translate : { '0': amount.errors.step.step } }}
  </mat-error>
</mat-form-field>
