import { CamfilLineItem } from './camfil-line-item.model';

export class CamfilLineItemHelper {
  static equal(camfilLineItem1: CamfilLineItem, camfilLineItem2: CamfilLineItem): boolean {
    return !!camfilLineItem1 && !!camfilLineItem2 && camfilLineItem1.id === camfilLineItem2.id;
  }

  static getValFromAttrs<T = unknown>(lineItem: CamfilLineItem, name: string): T {
    if (!lineItem?.attributes) {
      return undefined as T;
    }

    const attribute = lineItem?.attributes?.find(attr => attr.name === name);

    if (!attribute) {
      return undefined as T;
    }

    return attribute.value as T;
  }
}
