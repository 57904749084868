import { CamfilAddress } from 'camfil-models/camfil-address/camfil-address.model';
import { CamfilLineItem } from 'camfil-models/camfil-line-item/camfil-line-item.model';
import { CamfilRequisition } from 'camfil-models/camfil-requisition/camfil-requisition.model';
import { CamfilBucketEditFormData } from 'camfil-shared/components/basket/camfil-order-form-dialog/camfil-order-form-dialog.component';

import { BUCKET_LIST_HEIGHT, EMPTY_BUCKET_PREFIX } from './camfil-bucket.constants';
import { CamfilBucket, CamfilQuickAddProduct } from './camfil-bucket.model';

export class CamfilBucketHelper {
  static parseEditFormData(result: string): CamfilBucketEditFormData {
    return JSON.parse(result);
  }

  static isEmptyBucket(bucket: CamfilBucket): boolean {
    return bucket.id.split('_')[0] === EMPTY_BUCKET_PREFIX;
  }

  static parseQuickAddFormData(result: string): CamfilQuickAddProduct {
    return JSON.parse(result);
  }

  static getUpdatedAddress(
    editFormData: CamfilBucketEditFormData,
    camfilBucket: CamfilBucket | CamfilRequisition
  ): Partial<CamfilAddress> {
    const { formData, additionalOrderData } = editFormData;
    const contact = additionalOrderData.selectedContact;

    const fullAddress = camfilBucket.shippingAddress;

    return {
      ...fullAddress,
      firstName: contact?.firstName || fullAddress.firstName,
      lastName: contact?.lastName || fullAddress.lastName,
      addressLine1: formData.addressLine1,
      addressLine2: formData.addressLine2,
      postalCode: formData.zipcode,
      city: formData.city,
      companyName1: formData.company,
      goodsAcceptanceNote: additionalOrderData.addressFull?.goodsAcceptanceNote || '',
    };
  }

  static getUniqueEarliestDeliveryDates(items: CamfilLineItem[]): number[] {
    const dates: number[] = [];
    for (const item of items) {
      const date = Date.parse(item.earliestDeliveryDate || '');
      if (!isNaN(date)) {
        dates.push(date);
      }
    }
    return Array.from(new Set(dates));
  }

  static stripTimeFromDate(date: Date): Date {
    if (!date) {
      return;
    }

    date.setHours(0, 0, 0);
    return date;
  }

  static getUniqueDeliveryDates(bucket: CamfilBucket): Date[] {
    if (!bucket?.lineItems) {
      return;
    }

    const uniqueDeliveryDates = new Set<number>();

    for (const item of bucket.lineItems) {
      if (item?.earliestDeliveryDate) {
        let deliveryDate = new Date(item.earliestDeliveryDate);
        if (CamfilBucketHelper.isWeekend(deliveryDate)) {
          deliveryDate = CamfilBucketHelper.adjustToNextMonday(deliveryDate);
        }
        uniqueDeliveryDates.add(deliveryDate.getTime());
      }
    }

    if (uniqueDeliveryDates?.size === 0) {
      return [];
    }

    return Array.from(uniqueDeliveryDates)
      .sort((a, b) => a - b)
      .map(date => new Date(date));
  }

  static convertToDate(value: string | number | Date): Date {
    return value instanceof Date ? value : new Date(value);
  }

  static getDeliveryDateRange(startDate: string | number | Date, endDate: string | number | Date): Date[] {
    const sDate = CamfilBucketHelper.convertToDate(startDate);
    const eDate = CamfilBucketHelper.convertToDate(endDate);

    if (sDate > eDate) {
      return []; // or throw new Error("Start date must be on or before end date.");
    }

    const days: Date[] = [];

    for (let dt = new Date(sDate); dt <= eDate; dt.setDate(dt.getDate() + 1)) {
      days.push(new Date(dt));
    }

    return days;
  }

  static isInRange(dateStringOrNumber: string | number | Date, datesRange: Date[]) {
    const date = CamfilBucketHelper.convertToDate(dateStringOrNumber);
    const day = date.getDate();
    const month = date.getMonth();
    const year = date.getFullYear();

    return datesRange.some(
      rangeDate => rangeDate.getDate() === day && rangeDate.getMonth() === month && rangeDate.getFullYear() === year
    );
  }

  static isInRangeOrAfter(dateStringOrNumber: string | number | Date, datesRange: Date[]) {
    const date = CamfilBucketHelper.convertToDate(dateStringOrNumber);

    for (const rangeDate of datesRange) {
      if (date >= rangeDate) {
        return true;
      }
    }

    return false;
  }

  static adjustToNextMonday(date: Date): Date {
    const dayOfWeek = date.getDay();
    const daysUntilMonday = (dayOfWeek === 0 ? 1 : 8 - dayOfWeek) % 7;

    date.setDate(date.getDate() + daysUntilMonday);

    return new Date(date);
  }

  static isWeekend(date: Date) {
    return date?.getDay() === 6 || date?.getDay() === 0;
  }

  static isSameDay(dateStringOrNumber1: string | number | Date, dateStringOrNumber2: string | number | Date) {
    const date1 = CamfilBucketHelper.convertToDate(dateStringOrNumber1);
    const date2 = CamfilBucketHelper.convertToDate(dateStringOrNumber2);

    return (
      date1.getDate() === date2.getDate() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getFullYear() === date2.getFullYear()
    );
  }

  static filterDaysForForm(date: Date | null, deliveryDatesRange: Date[], calendarExceptions: Set<number>) {
    if (date && deliveryDatesRange && calendarExceptions) {
      const dateOnly = new Date(date.getFullYear(), date.getMonth(), date.getDate());
      const firstRangeDateOnly = new Date(
        deliveryDatesRange[0].getFullYear(),
        deliveryDatesRange[0].getMonth(),
        deliveryDatesRange[0].getDate()
      );
      const isNotWeekend = !CamfilBucketHelper.isWeekend(date);
      const isNotException = !calendarExceptions.has(date.getTime());
      const isFirstOrAfterFirstRangeDate = deliveryDatesRange[0] && dateOnly >= firstRangeDateOnly;
      return isNotWeekend && isNotException && isFirstOrAfterFirstRangeDate;
    }
    return false;
  }

  static handleDateClassesForForm(date: Date, range: Date[], firstDate: Date, lastDate: Date) {
    if (!date || !range?.length) {
      return '';
    }

    const isInRange = CamfilBucketHelper.isInRange(date, range);

    if (!isInRange) {
      return '';
    }

    const isFirstDay = CamfilBucketHelper.isSameDay(firstDate, date);
    const isLastDay = CamfilBucketHelper.isSameDay(lastDate, date);
    const isOneDayRange = range.length === 1 && isFirstDay;

    return [
      isOneDayRange ? '' : 'in-range-day mat-calendar-body-in-range',
      isFirstDay ? 'in-range-day-start mat-calendar-body-range-start' : '',
      isLastDay ? 'in-range-day-end mat-calendar-body-range-end' : '',
    ]
      .join(' ')
      .trim();
  }

  static isMoreItems(currentScrollList: number, index: number, listHeight = BUCKET_LIST_HEIGHT) {
    const itemsOnLoad = (listHeight / 100) * 2;
    return currentScrollList / 100 + itemsOnLoad > index;
  }

  static calculateEarliestDeliveryDate(exeptions: string[], days: number, day: Date): Date {
    if (!days) {
      return day;
    }

    day.setDate(day.getDate() + 1);
    let correction = days;

    if (!exeptions?.includes(day.toDateString())) {
      --correction;
    }

    return CamfilBucketHelper.calculateEarliestDeliveryDate(exeptions, correction, day);
  }

  static isFullDelivery(lastDate: Date, bucket: CamfilBucket) {
    const fullDeliveryDateTime = CamfilBucketHelper.stripTimeFromDate(lastDate)?.getTime();
    const selectedDeliveryDateTime = CamfilBucketHelper.stripTimeFromDate(new Date(bucket.deliveryDate))?.getTime();

    if (fullDeliveryDateTime === undefined || selectedDeliveryDateTime === undefined) {
      return false;
    }

    return fullDeliveryDateTime === selectedDeliveryDateTime || selectedDeliveryDateTime > fullDeliveryDateTime;
  }
}
