import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Angulartics2GoogleTagManager } from 'angulartics2';
import { CamfilShoppingFacade } from 'camfil-core/facades/camfil-shopping.facade';
import { CamCardEffects } from 'camfil-core/store/cam-cards/cam-cards.effects';
import { CamfilAuthorizationToggleService } from 'camfil-core/utils/auth/authorization-toggle.service';
import { CamCard } from 'camfil-models/camfil-cam-card/cam-card.model';
import { CamfilLineItem } from 'camfil-models/camfil-line-item/camfil-line-item.model';
import { CamfilQuote } from 'camfil-models/camfil-quotes/camfil-quote/camfil-quote.model';
import {
  DataLayerEvent,
  DataLayerEventType,
  DataLayerItem,
  DataLayerOrderType,
  DataLayerPageType,
  TrackingCategoryInfo,
  TrackingProductInfo,
} from 'camfil-models/camfil-tracking/data-layer-event.model';
import { Observable, combineLatest, forkJoin, iif, map, mergeMap, of, shareReplay, take } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';

import { BasketTotal } from 'ish-core/models/basket-total/basket-total.model';
import { BasketView } from 'ish-core/models/basket/basket.model';
import { CategoryView } from 'ish-core/models/category-view/category-view.model';
import { ProductView } from 'ish-core/models/product-view/product-view.model';
import { getCategory, getSelectedCategory } from 'ish-core/store/shopping/categories';
import { whenTruthy } from 'ish-core/utils/operators';

import { CamfilConfigurationService } from '../configuration/camfil-configuration.service';

@Injectable({ providedIn: 'root' })
export class CamfilTrackingService {
  constructor(
    private store: Store,
    private camfilShoppingFacade: CamfilShoppingFacade,
    private angulartics2GoogleTagManager: Angulartics2GoogleTagManager,
    private camfilConfigurationService: CamfilConfigurationService,
    private camfilAuthorizationToggleService: CamfilAuthorizationToggleService
  ) {}

  private canLoadCustomerPrices$ = combineLatest([
    this.camfilAuthorizationToggleService.isAuthorizedToCheckArrAll(CamCardEffects.PRICE_PERMISSIONS),
    this.camfilConfigurationService.isEnabled$('showPricesInCamCards'),
  ]).pipe(map(([isAuthorized, isEnabled]) => isAuthorized && isEnabled));

  trackCartAddItem(items: CamfilLineItem[], pageType?: DataLayerPageType) {
    this.trackCommonBasketOperation(DataLayerEventType.CartAddItem, items, pageType);
  }

  trackCartRemoveItem(items: CamfilLineItem[], pageType?: DataLayerPageType) {
    this.trackCommonBasketOperation(DataLayerEventType.CartRemoveItem, items, pageType);
  }

  trackBeginCheckout(items: CamfilLineItem[], pageType?: DataLayerPageType) {
    this.trackCommonBasketOperation(DataLayerEventType.BeginCheckout, items, pageType);
  }

  // trackViewCart(basket: BasketView) {
  //   this.trackCommonBasketOperation(DataLayerEventType.CartView, basket);
  // }

  trackSelectItem(item: ProductView, pageType: DataLayerPageType) {
    this.trackCommonProductOperation(DataLayerEventType.ItemSelect, item, pageType);
  }

  trackViewItem(item: ProductView) {
    this.trackCommonProductOperation(DataLayerEventType.ItemView, item, DataLayerPageType.ProductDetail);
  }

  trackCamCardCreate(camCard: CamCard, pageType?: DataLayerPageType) {
    this.trackCommonCamCardOperation(DataLayerEventType.CamCardCreate, { ...camCard, camCardItems: [] }, pageType);
  }

  trackCamCardEdit(camCard: CamCard, pageType?: DataLayerPageType) {
    this.trackCommonCamCardOperation(DataLayerEventType.CamCardEdit, { ...camCard, camCardItems: [] }, pageType);
  }

  trackCamCardAddItem(camCard: CamCard, pageType?: DataLayerPageType) {
    this.trackCommonCamCardOperation(DataLayerEventType.CamCardAddItem, camCard, pageType);
  }

  trackCamCardDelete(camCardId: string, pageType?: DataLayerPageType) {
    const event: DataLayerEvent = {
      event: DataLayerEventType.CamCardDelete,
      item_list_id: camCardId,
      page_type: pageType,
    };
    this.pushEvent(DataLayerEventType.CamCardDelete, event);
  }

  trackOrder(basket: BasketView, orderType: DataLayerOrderType, items: CamfilLineItem[]) {
    this.getProductsFromBasket(items).subscribe(products => {
      const eventData: DataLayerEvent = {
        ...this.buildEventDataFromBasket(DataLayerEventType.Purchase, items, products),
        transaction_id: basket?.id,
        tax: basket?.totals?.taxTotal?.value,
        shipping: this.extractShippingCosts(basket?.totals),
        value: basket?.totals?.total?.gross,
        order_type: orderType,
      };
      this.pushEvent(DataLayerEventType.Purchase, eventData);
    });
  }

  trackViewItemList(skus: string[], pageType: DataLayerPageType, pageId?: string) {
    const items = skus.map(sku => ({ product: { sku } }));
    this.trackViewItemListGeneric(
      items,
      pageType,
      (item, products) => {
        const productInfo = products.find(p => p.product?.sku === item.product.sku);
        return {
          item_id: item.product.sku,
          discount: 0,
          index: 0,
          item_category: productInfo?.categoryInfo?.category?.name,
          price: productInfo.price?.salePrice?.value,
          item_name: productInfo.product?.name,
          item_brand: productInfo.product?.manufacturer,
          item_category2: productInfo.categoryInfo?.category?.name,
        };
      },
      { item_list_id: pageId }
    );
  }

  trackViewItemListFromQuotation(quotation: CamfilQuote) {
    this.trackViewItemListGeneric(
      quotation.items,
      DataLayerPageType.QuoteDetail,
      (item, products, index) => {
        const productInfo = products.find(p => p.product?.sku === item.product.sku);

        const productInfoData = productInfo
          ? {
              price: productInfo.price.salePrice?.value,
              item_brand: productInfo.product.manufacturer,
              item_category: productInfo.categoryInfo?.category?.name,
              item_category2: productInfo.categoryInfo?.parent?.category?.name,
            }
          : undefined;
        return {
          item_id: item?.product.sku,
          index,
          discount: 0,
          quantity: item?.quantity?.value,
          item_name: item?.product.name,
          ...productInfoData,
        };
      },
      { item_list_id: quotation.id, item_list_name: quotation.displayName }
    );
  }

  trackViewItemListFromCamCard(camCard: CamCard) {
    this.trackViewItemListGeneric(
      camCard.camCardItems,
      DataLayerPageType.CamCardDetail,
      (item, products, index) => {
        const productInfo = products.find(p => p.product?.sku === item?.product?.sku);

        const price = productInfo ? { price: productInfo?.price?.salePrice?.value } : undefined;
        return {
          item_id: item?.product?.sku,
          discount: 0,
          index,
          quantity: item?.quantity,
          ...price,
        };
      },
      { item_list_id: camCard.id, item_list_name: camCard.name },
      camCard.customer.id || camCard.customerId
    );
  }

  private trackCommonProductOperation(eventType: DataLayerEventType, item: ProductView, pageType: DataLayerPageType) {
    this.getProducts([item.sku]).subscribe(([productInfo]) =>
      this.pushEvent(eventType, this.buildSingleProductEvent(eventType, productInfo, pageType))
    );
  }

  private buildSingleProductEvent(
    eventType: DataLayerEventType,
    productInfo: TrackingProductInfo,
    pageType: DataLayerPageType
  ): DataLayerEvent {
    return {
      event: eventType,
      currency: productInfo.price?.salePrice?.currency,
      value: productInfo.price?.salePrice?.value,
      items: [this.getItemDataFromProductInfo(productInfo)],
      page_type: pageType,
    };
  }

  private getItemDataFromProductInfo(productInfo: TrackingProductInfo): DataLayerItem {
    return {
      item_id: productInfo.product?.sku,
      discount: 0,
      index: 0,
      price: productInfo.price?.salePrice?.value,
      item_name: productInfo.product?.name,
      item_brand: productInfo.product?.manufacturer,
      item_category2: productInfo.categoryInfo?.category?.name,
      item_category: productInfo.categoryInfo?.parent?.category?.name,
    };
  }

  private trackCommonBasketOperation(
    eventType: DataLayerEventType,
    items: CamfilLineItem[],
    pageType?: DataLayerPageType
  ) {
    this.getProductsFromBasket(items, true).subscribe(products =>
      this.pushEvent(eventType, this.buildEventDataFromBasket(eventType, items, products, pageType))
    );
  }

  private trackCommonCamCardOperation(eventType: DataLayerEventType, camCard: CamCard, pageType?: DataLayerPageType) {
    this.getProductsFromCamCard(camCard).subscribe(products =>
      this.pushEvent(eventType, this.buildEventDataFromCamCard(eventType, camCard, products, pageType))
    );
  }

  private trackViewItemListGeneric<T extends { product?: { sku: string } }>(
    items: T[],
    pageType: DataLayerPageType,
    mapFunc: (item: T, products: TrackingProductInfo[], index: number) => DataLayerItem,
    extraEventFields: Partial<DataLayerEvent> = {},
    pricesForCustomer?: string // customerId
  ) {
    const skus = items.map(item => item.product.sku);
    const products$ = this.createTrackViewItemList(skus, pricesForCustomer);

    products$.subscribe(products => {
      const mappedItems = items.map((item, i) => mapFunc(item, products, i));
      const event: DataLayerEvent = {
        event: DataLayerEventType.ItemListView,
        page_type: pageType,
        items: mappedItems,
        ...extraEventFields,
      };
      this.pushEvent(DataLayerEventType.ItemListView, event);
    });
  }

  private createTrackViewItemList(skus: string[], customerId: string): Observable<TrackingProductInfo[]> {
    return !customerId
      ? this.getProducts(skus)
      : this.canLoadCustomerPrices$.pipe(
          switchMap(can =>
            iif(
              () => can,
              this.camfilShoppingFacade.getCustomerPrices$(customerId).pipe(
                whenTruthy(),
                map(ps => ps.map(product => ({ product, price: { salePrice: { ...product.salePrice } } })))
              ),
              of(skus.map(sku => ({ product: { sku } })))
            )
          )
        );
  }

  private getProductsFromBasket(
    lineItems: CamfilLineItem[],
    evenIfNotExist?: boolean
  ): Observable<TrackingProductInfo[]> {
    return this.getProducts(
      lineItems?.map(item => item.productSKU),
      evenIfNotExist
    ).pipe(shareReplay(1));
  }

  private getProducts(skus: string[], evenIfNotExist?: boolean): Observable<TrackingProductInfo[]> {
    if (!skus?.length) {
      return of([]);
    }

    return (
      evenIfNotExist
        ? this.camfilShoppingFacade.getProductsEvenIfNotExistBySkus$(skus)
        : this.camfilShoppingFacade.getProductsWithPricesBySkus$(skus)
    ).pipe(
      mergeMap(productsWithPrices =>
        forkJoin(
          productsWithPrices.map(({ product, price }) => {
            if (product.defaultCategory) {
              return this.getTrackingCategoryInfo(product.defaultCategory).pipe(
                map(categoryInfo => ({ product, price, categoryInfo }))
              );
            }

            return this.store.pipe(
              select(getSelectedCategory),
              take(1),
              mergeMap(category =>
                !category
                  ? of({ product, price, categoryInfo: { category, parent: undefined } })
                  : this.getTrackingCategoryInfo(category).pipe(map(categoryInfo => ({ product, price, categoryInfo })))
              )
            );
          })
        )
      ),
      filter((products: TrackingProductInfo[]) => !!products?.length),
      take(1)
    );
  }

  private buildEventDataFromBasket(
    eventType: DataLayerEventType,
    lineItems: CamfilLineItem[],
    products: TrackingProductInfo[] = [],
    pageType?: DataLayerPageType
  ): DataLayerEvent {
    return {
      event: eventType,
      currency: lineItems?.[0].price.currency,
      value: lineItems?.reduce((prev, curr) => prev + (curr.price?.net || 0) * (curr.quantity?.value || 0), 0) ?? 0,
      items:
        lineItems?.map(item =>
          this.getItemDataFormBasketItem(
            item,
            products.find(p => p?.product && p.product.sku === item.productSKU)
          )
        ) ?? [],
      page_type: pageType,
    };
  }

  private getItemDataFormBasketItem(item: CamfilLineItem, productInfo?: TrackingProductInfo): DataLayerItem {
    return {
      item_id: item.productSKU,
      discount: 0,
      index: item.position,
      price: item?.salePrice?.net,
      quantity: item.quantity?.value,
      item_name: productInfo?.product?.name,
      item_brand: productInfo?.product?.manufacturer,
      item_category2: productInfo?.categoryInfo?.category?.name,
      item_category: productInfo?.categoryInfo?.parent?.category?.name,
    };
  }

  private getTrackingCategoryInfo(category: CategoryView): Observable<TrackingCategoryInfo> {
    if (category?.categoryPath?.length > 1) {
      return this.store.pipe(
        select(getCategory(category.categoryPath[category.categoryPath.length - 2])),
        take(1),
        mergeMap(parentCategory =>
          this.getTrackingCategoryInfo(parentCategory).pipe(map(parent => ({ category, parent })))
        )
      );
    }

    return of({ category, parent: undefined });
  }

  private getProductsFromCamCard(camCard: CamCard): Observable<TrackingProductInfo[]> {
    if (camCard?.camCardItems) {
      return this.getProducts(camCard.camCardItems.map(item => item.product.sku));
    } else {
      return of([]);
    }
  }

  private pushEvent(eventType: DataLayerEventType, eventData: DataLayerEvent) {
    try {
      const event = { event: eventType, ...eventData };
      this.angulartics2GoogleTagManager.pushLayer(event);
    } catch (error) {
      console.error(`Failed to push event: ${error}`);
    }
  }
  private extractShippingCosts(totals: BasketTotal) {
    let shipping = 0;
    if (totals.dutiesAndSurchargesTotal) {
      shipping += totals.dutiesAndSurchargesTotal?.net;
    }
    if (totals.shippingTotal) {
      shipping += totals.shippingTotal?.net;
    }
    return shipping;
  }

  private buildEventDataFromCamCard(
    eventType: DataLayerEventType,
    camCard: CamCard,
    products: TrackingProductInfo[] = [],
    pageType?: DataLayerPageType
  ): DataLayerEvent {
    const items = camCard.camCardItems
      ? camCard.camCardItems.map(item => {
          const dataLayerItem: DataLayerItem = {
            item_id: item?.product?.sku,
            discount: 0,
            index: item?.position,
            quantity: item?.quantity,
            item_name: item?.product?.name,
          };
          const productInfo = products
            ? products.find(p => p?.product && p.product.sku === item?.product?.sku)
            : undefined;
          if (productInfo) {
            dataLayerItem.price = productInfo.price.salePrice.value;
            dataLayerItem.item_brand = productInfo.product.manufacturer;
            dataLayerItem.item_category = productInfo.categoryInfo?.category?.name;
            dataLayerItem.item_category2 = productInfo.categoryInfo?.parent?.category?.name;
          }
          return dataLayerItem;
        })
      : [];
    return {
      event: eventType,
      items,
      item_list_id: camCard.id,
      page_type: pageType,
    };
  }
}
