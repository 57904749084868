import { AddToCamCardItem } from 'camfil-models/camfil-cam-card/cam-card.model';
import * as qs from 'qs';

import { AhuProductItem } from './ahu.model';

const manufacturerParam = 'manufacturer';

const unitParam = 'unit';

export class AhuHelper {
  static MANUFACTURER_ID_QUERY_PARAM_NAME = 'manufacturerId';

  static UNIT_ID_QUERY_PARAM_NAME = 'unitId';

  static SLOTS_QUERY_PARAM_NAME = 'slots';

  static rootPath = 'air-handling-unit-guide';

  static manufacturerPath = `${manufacturerParam}/:${AhuHelper.MANUFACTURER_ID_QUERY_PARAM_NAME}`;

  static unitPath = `${AhuHelper.manufacturerPath}/${unitParam}/:${AhuHelper.UNIT_ID_QUERY_PARAM_NAME}`;

  static urlRegExp = /^\/air-handling-unit-guide.*/;

  static generateAhuUrl(manufacturerId?: string, unitId?: number): string {
    let route = `/${AhuHelper.rootPath}`;

    if (manufacturerId) {
      route += `/${manufacturerParam}/${manufacturerId}`;
    }

    if (manufacturerId && unitId) {
      route += `/${unitParam}/${unitId}`;
    }

    return route;
  }

  static stringifyToQs(obj: {}) {
    return qs.stringify(obj, { encode: false });
  }

  static parseQs(str: string) {
    return { ...JSON.parse(JSON.stringify(qs.parse(str))) };
  }

  static createAddToCamCardItems(items: AhuProductItem[]): AddToCamCardItem[] {
    return items.map(({ form, product }) => ({
      quantity: form.get('quantity').value,
      comment: {
        label: form.get('boxLabel').value,
      },
      product: {
        sku: product.sku,
      },
    }));
  }
}
