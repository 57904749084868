<mat-selection-list [multiple]="multipleSelection" color="primary">
  <ng-container *ngFor="let facet of getFacets(); let last = last">
    <mat-list-option
      [value]="facet"
      [selected]="facet.selected"
      (click)="filter(facet)"
      (keydown.enter)="filter(facet)"
      [attr.data-testing-id]="'filter-link-' + (facet.name | sanitize)"
    >
      {{ facet.displayName }}
    </mat-list-option>
    <mat-divider *ngIf="!last" />
  </ng-container>
  <mat-list-option
    class="show-all-link"
    *ngIf="filterElement.limitCount !== -1 && facets.length > filterElement.limitCount"
    (click)="showAll = !showAll"
    (keydown.enter)="showAll = !showAll"
  >
    <small class="text-muted">
      {{
        (showAll ? 'search.filter.show_less.link' : 'search.filter.show_all.link')
          | translate : { '0': facets.length - filterElement.limitCount }
      }}
    </small>
  </mat-list-option>
</mat-selection-list>
