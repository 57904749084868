<div *ngIf="basket?.lineItems" class="cart-summary">
  <div *ngFor="let pli of basket.lineItems; index as i" class="row cart-summary-checkout">
    <ng-container *ngIf="isItemVisible(i)">
      <!-- product name and price -->
      <div class="col-8">
        <ish-product-name ishProductContext [sku]="pli.productSKU" />
      </div>
      <div class="col-4 text-right">{{ pli.totals.total | ishPrice }}</div>

      <!-- quantity and undiscounted price -->
      <div class="col-8 cart-pli-data">
        <span> {{ 'checkout.pli.qty.label' | translate }} {{ pli.quantity.value }} </span>
      </div>
      <div class="col-4 text-right">
        <div *ngIf="pli.totals.valueRebatesTotal" class="old-price">
          <span class="price-value">{{ pli.totals.undiscountedTotal | ishPrice }}</span>
        </div>
        <div *ngIf="pli.isFreeGift" class="list-item-promo">{{ 'checkout.pli.freegift.text' | translate }}</div>
      </div>

      <!-- promotions -->
      <div *ngIf="pli.valueRebates?.length" class="col-12">
        <ng-container *ngFor="let rebate of pli.valueRebates">
          <ish-basket-promotion [rebate]="rebate" />
        </ng-container>
      </div>
    </ng-container>
  </div>

  <!-- 'Show All' and 'Hide All' links -->
  <button *ngIf="isShowAllLinkVisible()" type="button" class="btn btn-link btn-link-action" (click)="toggleCollapse()">
    {{ 'checkout.show_all.link' | translate : { '0': basket.lineItems.length } }}&nbsp;
    <fa-icon [icon]="['fas', 'angle-down']" />
  </button>
  <button *ngIf="isHideLinkVisible()" type="button" class="btn btn-link btn-link-action" (click)="toggleCollapse()">
    {{ 'checkout.hide_all.link' | translate }}&nbsp;
    <fa-icon [icon]="['fas', 'angle-up']" />
  </button>
</div>
