import {
  ChangeDetectionStrategy, Component, createNgModule, OnInit, ViewChild, ViewContainerRef,
  Injector,
  ComponentRef,
  Input, OnChanges, 
  
  
} from '@angular/core';



import type { CheckoutReceiptRequisitionComponent as OriginalComponent } from '../../components/checkout-receipt-requisition/checkout-receipt-requisition.component';

@Component({
  selector: 'ish-lazy-checkout-receipt-requisition',
  templateUrl: './lazy-checkout-receipt-requisition.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LazyCheckoutReceiptRequisitionComponent implements OnInit , OnChanges {
  /*
   * WARNING!
   *
   * This file was automatically generated!
   * It should be updated using:
   *
   * ng g lazy-component components/checkout-receipt-requisition/checkout-receipt-requisition.component.ts
   *
   */

  @ViewChild('anchor', { read: ViewContainerRef, static: true }) anchor: ViewContainerRef;


  @Input() basket: OriginalComponent['basket'];


  private component: ComponentRef<OriginalComponent>;
  

  constructor(
    
    private injector: Injector
  ) {}

  async ngOnInit() {
    

      await this.renderComponent();

    
  }

  private async renderComponent() {
    const module = await import(`../../requisition-management.module`).then(m => m.RequisitionManagementModule);

    const { CheckoutReceiptRequisitionComponent: originalComponent } = await import('../../components/checkout-receipt-requisition/checkout-receipt-requisition.component');

    const ngModuleRef = createNgModule(module, this.injector);

    this.component = this.anchor.createComponent(originalComponent, { ngModuleRef });
  
    this.ngOnChanges(
      
    );
  
    this.component.changeDetectorRef.markForCheck();
  }


  ngOnChanges() {
    if (this.component) {
      
        this.component.instance.basket = this.basket;
      

      
    }
  }

}
