import { Injectable } from '@angular/core';

import { CamfilCalendarExceptionData } from './camfil-caledar-exceptions.interface';
import { CamfilCalendarException } from './camfil-caledar-exceptions.model';

@Injectable({ providedIn: 'root' })
export class CamfilCalendarExceptionsMapper {
  static fromListData(payload: CamfilCalendarExceptionData[]): CamfilCalendarException[] {
    return payload.map((CamfilCalendarException: CamfilCalendarException) =>
      CamfilCalendarExceptionsMapper.fromData(CamfilCalendarException)
    );
  }

  static fromData(CamfilCalendarExceptionData: CamfilCalendarExceptionData): CamfilCalendarException {
    if (CamfilCalendarExceptionData) {
      return {
        date: CamfilCalendarExceptionData.date,
      };
    } else {
      throw new Error(`CamfilCalendarExceptionsData is required`);
    }
  }
}
