import { EntityState, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { CamfilB2bCustomer } from 'camfil-models/camfil-organization/customer/camfil-b2b-customer.model';

import { HttpError } from 'ish-core/models/http-error/http-error.model';
import { setErrorOn, setLoadingOn, unsetLoadingAndErrorOn } from 'ish-core/utils/ngrx-creators';

import {
  loadCustomerContactSuccess,
  loadCustomerContactsSuccess,
  loadCustomerUserContactSuccess,
} from '../b2b-contacts/b2b-contacts.actions';
import { loadCustomerRolesSuccess } from '../b2b-roles/b2b-roles.actions';
import { loadCustomerUserSuccess, loadCustomerUsersSuccess } from '../b2b-users/b2b-users.actions';

import {
  loadCustomerSuccess,
  loadCustomers,
  loadCustomersFail,
  loadCustomersSuccess,
  selectCustomer,
} from './b2b-customers.actions';
import { canHaveOnly1ParentCompany } from './b2b-customers.config';

export const camfilB2bCustomersFeatureKey = 'b2bCustomers';

export const customerAdapter = createEntityAdapter<CamfilB2bCustomer>({
  selectId: customer => customer?.id,
});

export interface B2bCustomersState extends EntityState<CamfilB2bCustomer> {
  loading: boolean;
  selected: string;
  error: HttpError;
  initialized: boolean;
}

export const initialState: B2bCustomersState = customerAdapter.getInitialState({
  loading: false,
  selected: undefined,
  error: undefined,
  initialized: false,
});

export const reducer = createReducer(
  initialState,
  setLoadingOn(loadCustomers),
  setErrorOn(loadCustomersFail, loadCustomersFail),
  unsetLoadingAndErrorOn(loadCustomersSuccess, loadCustomerSuccess),
  on(
    selectCustomer,
    (state: B2bCustomersState, action): B2bCustomersState => ({
      ...state,
      selected: action.payload.customerId,
    })
  ),
  on(loadCustomersSuccess, (state: B2bCustomersState, action) => {
    const { customers } = action.payload;
    const assignedCustomers = customers.map(c => ({ ...c, assignedToLoggedUser: true }));

    return customerAdapter.upsertMany(assignedCustomers, { ...state, initialized: true });
  }),
  on(loadCustomerSuccess, (state: B2bCustomersState, action) => {
    const { customer } = action.payload;

    return customerAdapter.upsertOne(customer, state);
  }),
  // Customer -> Users
  on(loadCustomerUsersSuccess, (state: B2bCustomersState, action) => {
    const { customerId, users } = action.payload;

    const customerUserIDs = state?.entities?.[customerId]?.userIDs || [];
    const userIDs = [...new Set([...customerUserIDs, ...users.map(user => user.id)])];

    return customerAdapter.updateOne(
      {
        id: customerId,
        changes: {
          userIDs,
        },
      },
      state
    );
  }),
  // Customer -> User
  on(loadCustomerUserSuccess, (state: B2bCustomersState, action) => {
    const { customerId, user } = action.payload;

    const customerUserIDs = state?.entities?.[customerId]?.userIDs || [];
    const userIDs = [...new Set([...customerUserIDs, user?.id])];

    return customerAdapter.updateOne(
      {
        id: customerId,
        changes: {
          userIDs,
        },
      },
      state
    );
  }),
  // Customer -> Roles
  on(loadCustomerRolesSuccess, (state: B2bCustomersState, action) => {
    const { customerId, roles } = action.payload;

    const customerRoleIDs = state?.entities?.[customerId]?.roleIDs || [];
    const roleIDs = [...new Set([...customerRoleIDs, ...roles.map(role => role.id)])];

    if (canHaveOnly1ParentCompany) {
      const updates = Object.keys(state.entities)?.map(id => ({
        id,
        changes: {
          roleIDs,
        },
      }));

      return customerAdapter.updateMany(updates, state);
    }

    return customerAdapter.updateOne(
      {
        id: customerId,
        changes: {
          roleIDs,
        },
      },
      state
    );
  }),
  // Customer -> Contacts
  on(loadCustomerContactsSuccess, (state: B2bCustomersState, action) => {
    const { customerId, contacts } = action.payload;

    const customerContactIDs = state?.entities?.[customerId]?.contactIDs || [];
    const contactIDs = [...new Set([...customerContactIDs, ...contacts.map(contact => contact.erpId)])];

    return customerAdapter.updateOne(
      {
        id: customerId,
        changes: {
          contactIDs,
        },
      },
      state
    );
  }),
  // Customer -> Contact
  on(loadCustomerContactSuccess, (state: B2bCustomersState, action) => {
    const { customerId, contact } = action.payload;

    const contactId = contact?.erpId;
    const customerContactIDs = state?.entities?.[customerId]?.contactIDs || [];
    const contactIDs = [...new Set([...customerContactIDs, contactId])];

    return customerAdapter.updateOne(
      {
        id: customerId,
        changes: {
          contactIDs,
        },
      },
      state
    );
  }),
  // Customer -> User -> Contact
  on(loadCustomerUserContactSuccess, (state: B2bCustomersState, action) => {
    const { customerId, contact } = action.payload;

    const contactId = contact?.erpId;
    const customerContactIDs = state?.entities?.[customerId]?.contactIDs || [];
    const contactIDs = [...new Set([...customerContactIDs, contactId])];

    return customerAdapter.updateOne(
      {
        id: customerId,
        changes: {
          contactIDs,
        },
      },
      state
    );
  })
);
