import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Action, Store, select } from '@ngrx/store';
import { CamfilRequisitionsService } from 'camfil-core/services/camfil-requisition/camfil-requisitions.service';
import { ProductCompletenessLevel } from 'camfil-models/camfil-product/product.model';
import {
  CamfilRequisitionStatusCodes,
  CamfilRequisitionStatuses,
} from 'camfil-models/camfil-requisition/camfil-requisition.interface';
import { CamfilRequisition } from 'camfil-models/camfil-requisition/camfil-requisition.model';
import { Observable, concatMap, filter, map, mergeMap, of, switchMap, tap } from 'rxjs';

import { displayErrorMessage, displaySuccessMessage } from 'ish-core/store/core/messages';
import { ofUrl, selectRouteParam } from 'ish-core/store/core/router';
import { getCurrentBasketId, submitBasketSuccess } from 'ish-core/store/customer/basket';
import { getProductEntities, loadProductIfNotLoaded } from 'ish-core/store/shopping/products';
import { mapErrorToAction, mapToPayload, mapToPayloadProperty, whenTruthy } from 'ish-core/utils/operators';

import { loadCamfilCustomers } from '../camfil-customer/camfil-customer.actions';
import { getCamfilCustomers } from '../camfil-customer/camfil-customer.selectors';

import {
  addProductToCamfilRequisition,
  addProductToCamfilRequisitionFail,
  addProductToCamfilRequisitionSuccess,
  approveCamfilRequisitionLineItems,
  approveCamfilRequisitionLineItemsFail,
  checkCamfilRequisitionDeliveryDateFail,
  checkCamfilRequisitionDeliveryDateSucces,
  checkProductAvailabilityFail,
  createCamfilRequisition,
  createCamfilRequisitionFail,
  createCamfilRequisitionSuccess,
  createOrderFromApprovedRequisition,
  createOrderFromApprovedRequisitionFail,
  createOrderFromApprovedRequisitionLineItems,
  createOrderFromApprovedRequisitionLineItemsSuccess,
  createOrderFromApprovedRequisitionSuccess,
  getCamfilRequisitionData,
  loadCamfilRequisition,
  loadCamfilRequisitionFail,
  loadCamfilRequisitionSuccess,
  loadCamfilRequisitions,
  loadCamfilRequisitionsFail,
  loadCamfilRequisitionsSuccess,
  removeLastProductFromCamfilRequisition,
  removeLastProductFromCamfilRequisitionFail,
  removeLastProductFromCamfilRequisitionSuccess,
  removeProductFromCamfilRequisition,
  removeProductFromCamfilRequisitionFail,
  removeProductFromCamfilRequisitionSuccess,
  updateCamfilRequisition,
  updateCamfilRequisitionAddress,
  updateCamfilRequisitionAddressSuccess,
  updateCamfilRequisitionFail,
  updateCamfilRequisitionLineItem,
  updateCamfilRequisitionLineItemFail,
  updateCamfilRequisitionLineItemSuccess,
  updateCamfilRequisitionStatus,
  updateCamfilRequisitionStatusFail,
  updateCamfilRequisitionStatusSuccess,
  updateCamfilRequisitionSuccess,
  updateMultipleCamfilRequisitionStatus,
  updateMultipleCamfileRequisitionStatusFail,
  updateRequisitionDeliveryDate,
} from './camfil-requisitions.actions';
import { getMinDeliveryDate, getSelectedCamfilRequisitionId } from './camfil-requisitions.selectors';

@Injectable()
export class CamfilRequisitionsEffects {
  constructor(
    private actions$: Actions,
    private camfilRequisitionsService: CamfilRequisitionsService,
    private router: Router,
    private store: Store
  ) {}

  loadCamfilRequisitions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadCamfilRequisitions),
      mapToPayload(),
      concatMap(({ view }) =>
        this.camfilRequisitionsService.getCamfilRequisitions(view).pipe(
          map(requisitions => loadCamfilRequisitionsSuccess({ requisitions, view })),
          mapErrorToAction(loadCamfilRequisitionsFail)
        )
      )
    )
  );

  getCamfilRequisitionData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getCamfilRequisitionData),
      mapToPayload(),
      mergeMap(({ requisitionId }) =>
        this.camfilRequisitionsService.getCamfilRequisition(requisitionId).pipe(
          map(requisition => loadCamfilRequisitionSuccess({ requisition })),
          mapErrorToAction(loadCamfilRequisitionFail)
        )
      )
    )
  );

  checkCamfilRequisitionDeliveryDate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadCamfilRequisitionSuccess),
      mapToPayload(),
      mergeMap(({ requisition }) =>
        this.camfilRequisitionsService.checkCamfilRequisitionDeliveryDate(requisition.id).pipe(
          map(({ minDeliveryDate }) =>
            checkCamfilRequisitionDeliveryDateSucces({ id: requisition.id, date: minDeliveryDate })
          ),
          mapErrorToAction(checkCamfilRequisitionDeliveryDateFail)
        )
      )
    )
  );

  updateRequisitionDeliveryDate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateRequisitionDeliveryDate),
      mapToPayload(),
      concatLatestFrom(() => this.store.pipe(select(getMinDeliveryDate))),
      mergeMap(([{ requisition }, deliveryDate]) => {
        const data: CamfilRequisition = {
          ...requisition,
          deliveryDate,
        };
        return this.camfilRequisitionsService.updateCamfilRequisition(data).pipe(
          concatMap(({ id }) => [
            updateCamfilRequisitionSuccess({ requisition: data }),
            updateCamfilRequisitionStatus({
              requisitionId: id,
              status: 'APPROVED',
            }),
          ]),
          mapErrorToAction(checkCamfilRequisitionDeliveryDateFail)
        );
      })
    )
  );

  loadCamfilRequisition$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(loadCamfilRequisition),
      mapToPayload(),
      concatLatestFrom(() => this.store.pipe(select(getCamfilCustomers))),
      mergeMap(([requisitionId, customers]) => {
        const actions: Action[] = [getCamfilRequisitionData(requisitionId)];

        if (!customers.length) {
          actions.push(loadCamfilCustomers());
        }
        return actions;
      })
    )
  );

  routeListenerForSelectingCamfilRequisition$ = createEffect(() =>
    this.store.pipe(
      ofUrl(/^\/(account\/requisitions\/approver.*|account\/requisitions\/buyer.*)/),
      select(selectRouteParam('orderId')),
      concatLatestFrom(() => this.store.pipe(select(getSelectedCamfilRequisitionId))),
      filter(([fromAction, selectedOrderId]) => fromAction && fromAction !== selectedOrderId),
      map(([orderId]) => orderId),
      map(requisitionId => getCamfilRequisitionData({ requisitionId }))
    )
  );

  /**
   * After selecting and successfully loading a requisition, triggers a LoadProduct action
   * for each product that is missing in the current product entities state.
   */
  loadProductsForSelectedRequisition$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadCamfilRequisitionSuccess),
      mapToPayloadProperty('requisition'),
      switchMap(requisition => [
        ...requisition.lineItems.map(({ productSKU }) =>
          loadProductIfNotLoaded({ sku: productSKU, level: ProductCompletenessLevel.List })
        ),
      ])
    )
  );

  loadRequisitionAfterRequisitionItemsChangeSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateCamfilRequisitionLineItemSuccess),
      mapToPayload(),
      map(payload => loadCamfilRequisition({ requisitionId: payload.requisitionId }))
    )
  );

  updateCamfilRequisitionStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateCamfilRequisitionStatus),
      mapToPayload(),
      concatMap(payload =>
        this.camfilRequisitionsService
          .updateCamfilRequisitionStatus(payload.requisitionId, payload.status, payload.approvalComment)
          .pipe(
            map(requisition =>
              requisition.approval.statusCode === CamfilRequisitionStatusCodes.Approved
                ? createOrderFromApprovedRequisition({
                    requisitionId: requisition.id,
                  })
                : updateCamfilRequisitionStatusSuccess({
                    requisition,
                    status: requisition.approval.status,
                  })
            ),
            mapErrorToAction(updateCamfilRequisitionStatusFail)
          )
      )
    )
  );

  createOrderFromApprovedRequisition$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createOrderFromApprovedRequisition),
      mapToPayload(),
      concatMap(payload =>
        this.camfilRequisitionsService.createOrderFromApprovedRequisition(payload.requisitionId).pipe(
          map(requisition => createOrderFromApprovedRequisitionSuccess({ requisition })),
          mapErrorToAction(createOrderFromApprovedRequisitionFail)
        )
      )
    )
  );

  createOrderFromApprovedRequisitionSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createOrderFromApprovedRequisitionSuccess),
      mapToPayload(),
      map(payload =>
        updateCamfilRequisitionStatusSuccess({
          requisition: payload.requisition,
          status: payload.requisition.approval.status,
        })
      )
    )
  );

  createOrderFromApprovedRequisitionFail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createOrderFromApprovedRequisitionFail),
      mapToPayloadProperty('error'),
      whenTruthy(),
      map(error =>
        displayErrorMessage({
          message: error?.message || error?.code,
        })
      )
    )
  );

  updateCamfilRequisitionStatusSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateCamfilRequisitionStatusSuccess),
      mapToPayload(),
      mergeMap(payload => [
        displaySuccessMessage({
          message:
            payload.status === CamfilRequisitionStatuses.Approved ||
            payload.status === CamfilRequisitionStatuses.Completed
              ? 'camfil.account.approvals.status_update.approved'
              : 'camfil.account.approvals.status_update.reject',
        }),
      ])
    )
  );

  addProductToCamfilRequisition$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addProductToCamfilRequisition),
      mapToPayload(),
      concatLatestFrom(() => this.store.pipe(select(getProductEntities))),
      mergeMap(([val, entities]) => of({ payload: val, product: entities[val.item.sku] })),
      concatMap(({ payload, product }) => {
        if (product.available) {
          return this.camfilRequisitionsService.addProductToCamfilRequisition(payload.requisitionId, payload.item).pipe(
            map(requisitionId => addProductToCamfilRequisitionSuccess({ requisitionId })),
            mapErrorToAction(addProductToCamfilRequisitionFail)
          );
        } else {
          return of(checkProductAvailabilityFail());
        }
      })
    )
  );

  checkProductAvailabilityFail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(checkProductAvailabilityFail),
      mergeMap(() => [
        displayErrorMessage({
          message: 'camfil.account.approvals.product_validation_fail.text',
        }),
      ])
    )
  );

  addProductsFromRequisitionSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addProductToCamfilRequisitionSuccess),
      map(() =>
        displaySuccessMessage({
          message: 'camfil.account.approvals.product_added.text',
        })
      )
    )
  );

  loadCamfilRequisitionAfterRequisitonItemsChangedSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addProductToCamfilRequisitionSuccess, removeProductFromCamfilRequisitionSuccess),
      mapToPayload(),
      map(loadCamfilRequisition)
    )
  );

  updateCamfilRequisition$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateCamfilRequisition),
      mapToPayload(),
      mergeMap(({ requisition, address }) =>
        this.camfilRequisitionsService.updateCamfilRequisition(requisition).pipe(
          map(updated =>
            address
              ? updateCamfilRequisitionAddress({ requisition: updated, address })
              : updateCamfilRequisitionSuccess({ requisition: updated })
          ),
          mapErrorToAction(updateCamfilRequisitionFail)
        )
      )
    )
  );

  updateCamfilRequisitionSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateCamfilRequisitionSuccess),
      map(() =>
        displaySuccessMessage({
          message: 'camfil.account.approvals.requisition_updated.text',
        })
      )
    )
  );

  updateCamfilRequisitionAddress$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateCamfilRequisitionAddress),
      mapToPayload(),
      mergeMap(({ requisition, address }) =>
        this.camfilRequisitionsService.updateCamfilRequisitionAddress(requisition.id, address).pipe(
          map(() => updateCamfilRequisitionAddressSuccess({ requisition, address })),
          mapErrorToAction(updateCamfilRequisitionFail)
        )
      )
    )
  );

  createCamfilRequisition = createEffect(() =>
    this.actions$.pipe(
      ofType(createCamfilRequisition),
      concatLatestFrom(() => this.store.pipe(select(getCurrentBasketId))),
      mergeMap(([, basketId]) =>
        this.camfilRequisitionsService.createCamfilRequisition(basketId).pipe(
          tap(() => this.router.navigate(['/checkout/receipt'])),
          concatMap((requisitions: CamfilRequisition[]) => [
            createCamfilRequisitionSuccess({ requisitions }),
            submitBasketSuccess(),
          ]),
          mapErrorToAction(createCamfilRequisitionFail)
        )
      )
    )
  );

  removeProductsFromCamfilRequisition$ = createEffect(() =>
    this.actions$.pipe(
      ofType(removeProductFromCamfilRequisition),
      mapToPayload(),
      concatMap(({ lineItemId, requisitionId }) =>
        this.camfilRequisitionsService.removeProductsFromCamfilRequisition(lineItemId, requisitionId).pipe(
          map(() => removeProductFromCamfilRequisitionSuccess({ requisitionId })),
          mapErrorToAction(removeProductFromCamfilRequisitionFail)
        )
      )
    )
  );

  removeLastProductFromCamfilRequisition$ = createEffect(() =>
    this.actions$.pipe(
      ofType(removeLastProductFromCamfilRequisition),
      mapToPayload(),
      concatMap(({ lineItemId, requisitionId }) =>
        this.camfilRequisitionsService.removeProductsFromCamfilRequisition(lineItemId, requisitionId).pipe(
          map(() => removeLastProductFromCamfilRequisitionSuccess({ requisitionId })),
          mapErrorToAction(removeLastProductFromCamfilRequisitionFail)
        )
      )
    )
  );

  removeLastProductFromCamfilRequisitionSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(removeLastProductFromCamfilRequisitionSuccess),
      map(() =>
        displaySuccessMessage({
          message: 'camfil.account.approvals.last_product_removed.text',
        })
      ),
      tap(() => this.router.navigate(['/account/camfil-requisitions/approver']))
    )
  );

  removeProductsFromCamfilRequisitionSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(removeProductFromCamfilRequisitionSuccess),
      map(() =>
        displaySuccessMessage({
          message: 'camfil.account.approvals.product_removed.text',
        })
      )
    )
  );

  // // ------- Line Items Attributes -------
  // addCamfilRequisitionLineItemAttribute$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(addCamfilRequisitionLineItemAttribute),
  //     mapToPayload(),
  //     mergeMap(({ requisitionId, lineItemId, lineItemAttribute }) =>
  //       this.camfilRequisitionsService
  //         .addLineItemAttribute(requisitionId, lineItemId, lineItemAttribute)
  //         .pipe(
  //           map(addCamfilRequisitionLineItemAttributeSuccess),
  //           mapErrorToAction(addCamfilRequisitionLineItemAttributeFail)
  //         )
  //     )
  //   )
  // );

  // updateCamfilRequisitionLineItemAttribute$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(updateCamfilRequisitionLineItemAttribute),
  //     mapToPayload(),
  //     mergeMap(payload =>
  //       this.camfilRequisitionsService
  //         .updateLineItemAttribute(payload.requisitionId, payload.lineItemId, payload.lineItemAttribute)
  //         .pipe(
  //           map(updateCamfilRequisitionLineItemAttributeSuccess),
  //           mapErrorToAction(updateCamfilRequisitionLineItemAttributeFail)
  //         )
  //     )
  //   )
  // );

  // deleteLineItemAttributte$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(deleteCamfilRequisitionLineItemAttribute),
  //     mapToPayload(),
  //     mergeMap(({ requisitionId, lineItemId, lineItemAttribute }) =>
  //       this.camfilRequisitionsService
  //         .deleteLineItemAttribute(requisitionId, lineItemId, lineItemAttribute)
  //         .pipe(
  //           map(deleteCamfilRequisitionLineItemAttributeSuccess),
  //           mapErrorToAction(deleteCamfilRequisitionLineItemAttributeFail)
  //         )
  //     )
  //   )
  // );

  // Line items update

  updateCamfilRequisitionLineItem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateCamfilRequisitionLineItem),
      mapToPayload(),
      mergeMap(({ requisitionId, lineItemUpdate }) =>
        this.camfilRequisitionsService.updateLineItem(requisitionId, lineItemUpdate).pipe(
          map(() => updateCamfilRequisitionLineItemSuccess({ requisitionId, lineItemUpdate })),
          mapErrorToAction(updateCamfilRequisitionLineItemFail)
        )
      )
    )
  );

  approveCamfilRequisitionLineItems$ = createEffect(() =>
    this.actions$.pipe(
      ofType(approveCamfilRequisitionLineItems),
      mapToPayload(),
      mergeMap(({ requisitionId, lineItemIds, requisition }) =>
        this.camfilRequisitionsService.approveSelectedLineItems(requisitionId, lineItemIds, requisition).pipe(
          map(() => createOrderFromApprovedRequisitionLineItems({ requisition, lineItemIds })),
          mapErrorToAction(approveCamfilRequisitionLineItemsFail)
        )
      )
    )
  );

  createOrderWithApprovedLineItems$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createOrderFromApprovedRequisitionLineItems),
      mapToPayload(),
      mergeMap(({ requisition, lineItemIds }) =>
        this.camfilRequisitionsService.createOrderFromApprovedRequisition(requisition.id, lineItemIds).pipe(
          map(() => createOrderFromApprovedRequisitionLineItemsSuccess({ requisition, lineItemIds })),
          mapErrorToAction(createOrderFromApprovedRequisitionFail)
        )
      )
    )
  );

  updateMultipleCamfilRequisitionStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateMultipleCamfilRequisitionStatus),
      mapToPayload(),
      concatMap(payload =>
        this.camfilRequisitionsService
          .updateMultipleCamfilRequisitionStatus(payload.requisitionIds, payload.status, payload.approvalComment)
          .pipe(
            // TODO: Change effect to createOrderFromMultipleApprovedRequisition
            map(requisition =>
              requisition.approval.statusCode === CamfilRequisitionStatusCodes.Approved
                ? createOrderFromApprovedRequisition({
                    requisitionId: requisition.id,
                  })
                : updateCamfilRequisitionStatusSuccess({
                    requisition,
                    status: requisition.approval.status,
                  })
            ),
            mapErrorToAction(updateMultipleCamfileRequisitionStatusFail)
          )
      )
    )
  );

  updateMultipleCamfileRequisitionStatusFail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateMultipleCamfileRequisitionStatusFail),
      map(() =>
        displayErrorMessage({
          message: 'camfil.account.approvals.status_update.fail.text',
        })
      )
    )
  );
}
