<ng-container *ngIf="selected && selected.length > 0">
  <mat-chip-listbox>
    <mat-chip
      *ngIf="
        selected.length >= 1 ||
        (!selected.length && appliedFilters) ||
        countNotNull([width, height, depth]) >= 2 ||
        (selected.length >= 1 && countNotNull([width, height, depth]) >= 1)
      "
    >
      {{ 'camfil.navigation.filterClearAll.message' | translate }}
      <button type="button" (click)="clear()" matChipRemove>
        <mat-icon>cancel</mat-icon>
      </button>
    </mat-chip>

    <!-- Render all filters except Width, Height, Depth -->
    <ng-container *ngFor="let select of selected">
      <ng-container *ngIf="select.filterId !== 'Width' && select.filterId !== 'Height' && select.filterId !== 'Depth'">
        <mat-chip>
          {{ select.filterName }} - {{ select.displayName }}
          <button type="button" color="primary" (click)="apply(select.searchParameter)" matChipRemove>
            <mat-icon>cancel</mat-icon>
          </button>
        </mat-chip>
      </ng-container>
    </ng-container>

    <!-- Always display measurement filters Width, Height, Depth when applied, even if not considered 'selected' -->
    <mat-chip *ngIf="width">
      {{ 'camfil.navigation.filter.measurements.width' | translate }} - {{ width }}
      <button type="button" (click)="removeFilter('Width')" matChipRemove>
        <mat-icon>cancel</mat-icon>
      </button>
    </mat-chip>
    <mat-chip *ngIf="height">
      {{ 'camfil.navigation.filter.measurements.height' | translate }} - {{ height }}
      <button type="button" (click)="removeFilter('Height')" matChipRemove>
        <mat-icon>cancel</mat-icon>
      </button>
    </mat-chip>
    <mat-chip *ngIf="depth">
      {{ 'camfil.navigation.filter.measurements.depth' | translate }} - {{ depth }}
      <button type="button" (click)="removeFilter('Depth')" matChipRemove>
        <mat-icon>cancel</mat-icon>
      </button>
    </mat-chip>
  </mat-chip-listbox>
</ng-container>
