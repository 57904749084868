/* eslint-disable ish-custom-rules/ban-imports-file-pattern */
import { Injectable, InjectionToken, NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { ActionReducerMap, StoreConfig, StoreModule } from '@ngrx/store';
import { CamfilBasketValidationEffects } from 'camfil-core/store/camfil-basket/camfil-basket-validation.effects';
import { CamfilOrderTrackAndTraceEffects } from 'camfil-core/store/camfil-order-track-and-trace/camfil-order-track-and-trace-effects.service';
import * as fromCamfilTrackAndTrace from 'camfil-core/store/camfil-order-track-and-trace/camfil-order-track-and-trace.reducer';
import { CamfilQueueEffects } from 'camfil-core/store/camfil-queue/camfil-queue.effects';
import { CamfilQuotesEffects } from 'camfil-core/store/camfil-quotes/camfil-quotes.effects';
import * as fromCamfilQuotes from 'camfil-core/store/camfil-quotes/camfil-quotes.reducer';
import { CamfilZipCodeAddressEffects } from 'camfil-core/store/camfil-zip-code-address/camfil-zip-code-address.effects';
import * as fromCamfilZipcodes from 'camfil-core/store/camfil-zip-code-address/camfil-zip-code-address.reducer';
import { camfilResetWithoutSubStatesOnActionsMetaReducer } from 'camfil-core/utils/meta-reducers';
import { CAMFIL_NAMESPACE } from 'camfil-shared/camfil-constants';
import { pick } from 'lodash-es';

import { logoutUserSuccess } from 'ish-core/store/customer/user';

import { AhuEffects } from './ahu/ahu.effects';
import { AhuManufacturerEffects } from './ahu/manufacturer/manufacturer.effects';
import * as fromAhuManufacturer from './ahu/manufacturer/manufacturer.reducer';
import { AhuUnitFilterEffects } from './ahu/unit-filter/unit-filter.effects';
import * as fromAhuUnitFilter from './ahu/unit-filter/unit-filter.reducer';
import { AhuUnitEffects } from './ahu/unit/unit.effects';
import * as fromAhuUnit from './ahu/unit/unit.reducer';
import { B2bContactsEffects } from './b2b/b2b-contacts/b2b-contacts.effects';
import * as fromB2bContacts from './b2b/b2b-contacts/b2b-contacts.reducer';
import { B2bCustomersEffects } from './b2b/b2b-customers/b2b-customers.effects';
import * as fromB2bCustomers from './b2b/b2b-customers/b2b-customers.reducer';
import { B2bRolesEffects } from './b2b/b2b-roles/b2b-roles.effects';
import * as fromB2bRoles from './b2b/b2b-roles/b2b-roles.reducer';
import { B2bUsersEffects } from './b2b/b2b-users/b2b-users.effects';
import * as fromB2bUsers from './b2b/b2b-users/b2b-users.reducer';
import { CamCardEffects } from './cam-cards/cam-cards.effects';
import * as fromCamCard from './cam-cards/cam-cards.reducer';
import { CamfilBasketEffects } from './camfil-basket/camfil-basket.effects';
import * as fromBasket from './camfil-basket/camfil-basket.reducer';
import { CamfilBucketEffects } from './camfil-bucket/camfil-bucket.effects';
import * as fromBucket from './camfil-bucket/camfil-bucket.reducer';
import { CamfilCalendarExceptionsEffects } from './camfil-calendar-exceptions/camfil-calendar-exceptions.effects';
import * as fromCalendarExceptions from './camfil-calendar-exceptions/camfil-calendar-exceptions.reducer';
import { CamfilConfigurationEffects } from './camfil-configuration/camfil-configuration.effects';
import * as fromConfiguration from './camfil-configuration/camfil-configuration.reducer';
import { CamfilCustomerDeliveryTermsEffects } from './camfil-customer-delivery-terms/camfil-customer-delivery-terms.effects';
import * as fromCamfilCustomerDeliveryTerm from './camfil-customer-delivery-terms/camfil-customer-delivery-terms.reducer';
import { CamfilCustomerEffects } from './camfil-customer/camfil-customer.effects';
import * as fromCamfilCustomers from './camfil-customer/camfil-customer.reducer';
import { CamfilEditBasketEffects } from './camfil-edit-basket/camfil-edit-basket.effects';
import * as fromCamfilBasketEdit from './camfil-edit-basket/camfil-edit-basket.reducer';
import { CamfilOrdersEffects } from './camfil-orders/camfil-orders.effects';
import * as fromOrders from './camfil-orders/camfil-orders.reducer';
import * as fromCamfilQueue from './camfil-queue/camfil-queue.reducer';
import { CamfilRequisitionsEffects } from './camfil-requisitions/camfil-requisitions.effects';
import * as fromRequisitions from './camfil-requisitions/camfil-requisitions.reducer';
import { CamfilState } from './camfil-store';
import * as fromCamfilSearch from './shopping/camfil-search/camfil-search.reducer';
import { CamfilCategoriesEffects } from './shopping/categories/categories.effects';
import * as fromCamfilCategories from './shopping/categories/categories.reducer';
import { CustomerPricesEffects } from './shopping/customer-prices/customer-prices.effects';
import * as fromCamfilCustomerPriceKey from './shopping/customer-prices/customer-prices.reducer';
import { CamfilUserEffects } from './user/camfil-user.effects';
import * as fromCamfilUser from './user/camfil-user.reducer';

const camfilReducers = {
  [fromAhuManufacturer.ahuManufacturerFeatureKey]: fromAhuManufacturer.reducer,
  [fromAhuUnit.ahuUnitFeatureKey]: fromAhuUnit.reducer,
  [fromAhuUnitFilter.ahuUnitFilterFeatureKey]: fromAhuUnitFilter.reducer,
  [fromB2bContacts.camfilB2bContactsFeatureKey]: fromB2bContacts.reducer,
  [fromB2bCustomers.camfilB2bCustomersFeatureKey]: fromB2bCustomers.reducer,
  [fromB2bRoles.camfilB2bRolesFeatureKey]: fromB2bRoles.reducer,
  [fromB2bUsers.camfilB2bUsersFeatureKey]: fromB2bUsers.reducer,
  [fromBasket.camfilBasketFeatureKey]: fromBasket.reducer,
  [fromBucket.camfilBucketFeatureKey]: fromBucket.reducer,
  [fromCalendarExceptions.camfilCalendarExceptionsFeatureKey]: fromCalendarExceptions.reducer,
  [fromCamCard.camfilCamCardFeatureKey]: fromCamCard.camCardReducer,
  [fromCamfilBasketEdit.camfilEditBasketFeatureKey]: fromCamfilBasketEdit.reducer,
  [fromCamfilCustomerDeliveryTerm.camfilCustomerDeliveryTermFeatureKey]: fromCamfilCustomerDeliveryTerm.reducer,
  [fromCamfilCustomerPriceKey.camfilCustomerPriceKey]: fromCamfilCustomerPriceKey.reducer,
  [fromCamfilCustomers.camfilCustomerFeatureKey]: fromCamfilCustomers.reducer,
  [fromCamfilQueue.camfilQueueFeatureKey]: fromCamfilQueue.reducer,
  [fromCamfilQuotes.camfilQuotesFeatureKey]: fromCamfilQuotes.reducer,
  [fromCamfilSearch.camfilSearchFeatureKey]: fromCamfilSearch.reducer,
  [fromCamfilTrackAndTrace.camfilOrderTrackAndTraceFeatureKey]: fromCamfilTrackAndTrace.reducer,
  [fromCamfilUser.camfilUserFeatureKey]: fromCamfilUser.reducer,
  [fromCamfilZipcodes.camfilZipcodesFeatureKey]: fromCamfilZipcodes.reducer,
  [fromConfiguration.configurationFeatureKey]: fromConfiguration.reducer,
  [fromOrders.camfilOrdersFeatureKey]: fromOrders.reducer,
  [fromRequisitions.camfilRequisitionsFeatureKey]: fromRequisitions.reducer,
  [fromCamfilCategories.camfilCategoriesStateKey]: fromCamfilCategories.reducer,
};

const camfilEffects = [
  AhuEffects,
  AhuManufacturerEffects,
  AhuUnitEffects,
  AhuUnitFilterEffects,
  B2bContactsEffects,
  B2bCustomersEffects,
  B2bRolesEffects,
  B2bUsersEffects,
  CamCardEffects,
  CamfilBasketEffects,
  CamfilBasketValidationEffects,
  CamfilBucketEffects,
  CamfilCalendarExceptionsEffects,
  CamfilConfigurationEffects,
  CamfilCustomerDeliveryTermsEffects,
  CamfilCustomerEffects,
  CamfilEditBasketEffects,
  CamfilOrderTrackAndTraceEffects,
  CamfilOrdersEffects,
  CamfilQueueEffects,
  CamfilQuotesEffects,
  CamfilRequisitionsEffects,
  CamfilUserEffects,
  CamfilZipCodeAddressEffects,
  CustomerPricesEffects,
  CamfilCategoriesEffects,
];

type Reducers = typeof camfilReducers;

export type ReducerStates = {
  [K in keyof Reducers]: ReturnType<Reducers[K]>;
};

@Injectable()
class DefaultCamfilStoreConfig implements StoreConfig<CamfilState> {
  metaReducers = [
    camfilResetWithoutSubStatesOnActionsMetaReducer<CamfilState>(['camfilConfiguration'], [logoutUserSuccess]),
  ];
}

const CAMFIL_STORE_CONFIG = new InjectionToken<StoreConfig<CamfilState>>('camfilStoreConfig');

@NgModule({
  imports: [
    EffectsModule.forFeature(camfilEffects),
    StoreModule.forFeature(CAMFIL_NAMESPACE, camfilReducers, CAMFIL_STORE_CONFIG),
  ],
  providers: [{ provide: CAMFIL_STORE_CONFIG, useClass: DefaultCamfilStoreConfig }],
})
export class CamfilStoreModule {
  static forTesting(...reducers: (keyof ActionReducerMap<CamfilState>)[]) {
    return StoreModule.forFeature(CAMFIL_NAMESPACE, pick(camfilReducers, reducers));
  }
}
