<div class="filters-row row align-items-center">
  <div class="col-md-7 col-sm-10">
    <div class="row align-items-center">
      <div class="col-sm-3 col-4">
        <div *ngIf="itemCount >= 0" class="pagination-total text-nowrap">
          {{ 'product.items.label' | translate : { '0': itemCount } }}
        </div>
      </div>
      <div class="col-sm-9 col-8">
        <ish-product-list-paging
          *ngIf="pageIndices?.length && currentPage"
          [currentPage]="currentPage"
          [pageIndices]="pageIndices"
          [fragmentOnRouting]="fragmentOnRouting"
        />
      </div>
    </div>
  </div>
  <div
    class="col-4 col-sm-2 switch-layout"
    [ngClass]="{ 'col-md-2': sortOptions?.length, 'col-md-5': !sortOptions?.length }"
  >
    <div class="sort-by align-items-center justify-content-end">
      <a
        [routerLink]="[]"
        queryParamsHandling="merge"
        [queryParams]="{ view: 'grid' }"
        [replaceUrl]="true"
        [fragment]="fragmentOnRouting"
        class="grid-view-link"
        [ngClass]="{ 'grid-active': gridView }"
        data-testing-id="grid-view-link"
        role="button"
        [attr.aria-label]="'product.items.view.grid_view.aria_label' | translate"
      >
        <fa-icon [icon]="['fas', 'th']" />
      </a>
      <a
        [routerLink]="[]"
        queryParamsHandling="merge"
        [queryParams]="{ view: 'list' }"
        [replaceUrl]="true"
        [fragment]="fragmentOnRouting"
        class="list-view-link"
        [ngClass]="{ 'list-active': listView }"
        data-testing-id="list-view-link"
        role="button"
        [attr.aria-label]="'product.items.view.list_view.aria_label' | translate"
      >
        <fa-icon [icon]="['fas', 'list']" />
      </a>
    </div>
  </div>
  <div *ngIf="sortOptions?.length" class="col-8 col-sm-12 col-md-3 sort-by">
    <select
      class="form-control sort-by-filter"
      id="SortingAttribute"
      name="SortingAttribute"
      (change)="changeSortBy($event.target)"
    >
      <option value="default" [selected]="sortBy === 'default'">
        {{ 'product.items.sorting.default.label' | translate }}
      </option>
      <option *ngFor="let o of sortOptions" [value]="o.value" [selected]="o.value === sortBy">{{ o.label }}</option>
    </select>
  </div>
</div>
