import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { Facet } from 'ish-core/models/facet/facet.model';
import { Filter } from 'ish-core/models/filter/filter.model';
import { URLFormParams } from 'ish-core/utils/url-form-params';

/**
 * The Filter Text Component displays a filter group. The facets of the filter group are presented as links with optional clear-button.
 *
 * TODO: Replace template driven forms with reactive
 *
 * @example
 * <camfil-filter-text
 *               [filterElement]="element"
 *               (applyFilter)="applyFilter($event)"
 * </camfil-filter-text>
 */
@Component({
  selector: 'camfil-filter-text',
  templateUrl: './camfil-filter-text.component.html',
  styleUrls: ['./camfil-filter-text.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CamfilFilterTextComponent implements OnInit {
  checked = true;

  @Input() filterElement: Filter;
  @Output() applyFilter: EventEmitter<{ searchParameter: URLFormParams }> = new EventEmitter();

  /**
   * two-way-binding (banana in a box) [(showAll)]="showAllElements[element.name]"
   */
  @Output()
  showAllChange = new EventEmitter<boolean>();

  private showAllValue = false;

  @Input()
  get showAll() {
    return this.showAllValue;
  }

  set showAll(val) {
    this.showAllValue = val;
    this.showAllChange.emit(this.showAllValue);
  }

  maxLevel = 0;

  facets: Facet[] = [];

  get multipleSelection() {
    return this.filterElement?.selectionType?.startsWith('multi');
  }

  ngOnInit() {
    this.maxLevel = Math.max(...this.filterElement.facets.map(o => o.level)) || 0;
    this.facets = this.filterElement.facets.filter(x => x.selected || !this.maxLevel || x.level >= this.maxLevel);

    this.showAll = !!this.facets?.some(f => f.selected);
  }

  filter(facet: Facet) {
    this.applyFilter.emit({ searchParameter: facet.searchParameter });
  }

  /**
   * sort selected to top, increase limitCount to selectedCount on showLess
   */
  getFacets() {
    const facets = [...this.facets.filter(facet => facet.count > 0)];

    if (this.showAll || this.maxLevel >= 1 || this.filterElement.limitCount === -1) {
      return facets;
    }

    const selectedFacetsCount = facets.filter(x => x.selected).length;

    return facets
      .sort((a, b) => (a.selected > b.selected ? -1 : a.selected < b.selected ? 1 : 0))
      .slice(0, Math.max(this.filterElement.limitCount || 0, selectedFacetsCount));
  }
}
