import { Injectable } from '@angular/core';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { CamfilAccountFacade } from 'camfil-core/facades/camfil-account.facade';
import { CamfilZipCodeAddress } from 'camfil-models/camfil-zip-code-address/camfil-zip-code-address.model';
import { getOptions$ } from 'camfil-shared/formly/utils/camfil-form-utils';

import { FieldLibraryConfiguration } from 'ish-shared/formly/field-library/configurations/field-library-configuration';

@Injectable()
export class DeliveryAddressCityConfiguration extends FieldLibraryConfiguration {
  constructor(private accountFacade: CamfilAccountFacade) {
    super();
  }

  id = 'deliveryAddressCityFLC';

  getFieldConfig(): FormlyFieldConfig {
    return {
      key: 'deliveryAddress.city',
      type: 'camfil-select-field',
      props: {
        label: 'camfil.form.pick_city',
        required: true,
        valueProp: (option: CamfilZipCodeAddress) => option?.city,
        labelProp: (option: CamfilZipCodeAddress) => option?.city,
      },
      expressions: {
        'props.disabled': (field: FormlyFieldConfig) => !field?.model?.deliveryAddress?.postalCode,
      },
      hooks: {
        onInit: (field: FormlyFieldConfig) => {
          const options$ = getOptions$<CamfilZipCodeAddress, [string]>({
            field,
            addEmptyOption: true,
            autoSelectSingleOption: true,
            autoDisableSingleOption: true,
            paths: ['deliveryAddress.postalCode'],
            observableFn: postalCode => this.accountFacade.getZipCodeAddress$(postalCode),
          });

          field.props.options = options$;
        },
      },
    };
  }
}
