// eslint-disable-next-line ish-custom-rules/ordered-imports
import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { routerNavigatedAction } from '@ngrx/router-store';
import { Store, select } from '@ngrx/store';
import { CamfilTrackingService } from 'camfil-core/services/camfil-tracking/camfil-tracking.service';
import {
  addBasketToNewCamCardSuccess,
  addProductToCamCardSuccess,
  addToCartFromCamCardsSuccess,
  createCamCardSuccess,
  deleteCamCardSuccess,
  removeItemFromCamCardSuccess,
  updateCamCardProductSuccess,
  updateCamCardSuccess,
} from 'camfil-core/store/cam-cards/cam-cards.actions';
import {
  getSelectedCamCardDetails,
  getSelectedCamCardFullDetails,
} from 'camfil-core/store/cam-cards/cam-cards.selectors';
import {
  camfilDeleteBucketItemSuccess,
  camfilUpdateBucketItemSuccess,
} from 'camfil-core/store/camfil-bucket/camfil-bucket.actions';
import { loadCamfilQuoteSuccess } from 'camfil-core/store/camfil-quotes/camfil-quotes.actions';
import { CookiesService } from 'ish-core/utils/cookies/cookies.service';
import { Observable, filter, map, mergeMap, pairwise, skipWhile, switchMap, take, tap } from 'rxjs';

import { selectRouteParam, selectRouter } from 'ish-core/store/core/router';
import { getCurrentBasket, getSubmittedBasket } from 'ish-core/store/customer/basket';
import { getSelectedProduct } from 'ish-core/store/shopping/products/products.selectors';
import { mapToPayload, mapToPayloadProperty, whenTruthy } from 'ish-core/utils/operators';

import { DataLayerOrderType, DataLayerPageType } from '../../../models/camfil-tracking/data-layer-event.model';
import { setProductListingPages } from 'ish-core/store/shopping/product-listing';
import { trackCreateOrderCamfil } from './camfil-tracking-events.actions';
import { getCurrentCamfilBucketsItems } from '../camfil-bucket/camfil-bucket.selectors';

@Injectable()
export class TrackingEventsEffects {
  constructor(
    private actions$: Actions,
    private store: Store,
    private camfilTrackingService: CamfilTrackingService,
    private cookiesService: CookiesService
  ) {}

  private conditionalActions$ = this.actions$.pipe(filter(() => this.cookiesService.cookieConsentFor('tracking')));

  trackAddItemsToBasket$ = createEffect(
    () =>
      this.conditionalActions$.pipe(
        ofType(addToCartFromCamCardsSuccess),
        concatLatestFrom(() => this.store.pipe(select(getCurrentBasket))),
        map(([, oldBasket]) => oldBasket),
        mergeMap(oldBasket =>
          this.store.pipe(select(getCurrentCamfilBucketsItems)).pipe(
            whenTruthy(),
            filter(lineItems => !oldBasket || oldBasket.lineItems.length < lineItems.length),
            take(1)
          )
        ),
        mergeMap(lineItems => this.getPageTypeFromRouter().pipe(map(pageType => ({ lineItems, pageType })))),
        tap(({ lineItems, pageType }) => this.camfilTrackingService.trackCartAddItem(lineItems, pageType))
      ),
    { dispatch: false }
  );

  trackChangeItemInBasket$ = createEffect(
    () =>
      this.conditionalActions$.pipe(
        ofType(camfilUpdateBucketItemSuccess),
        concatLatestFrom(() => this.store.pipe(select(getCurrentCamfilBucketsItems))),
        map(([result, items]) => ({ updatedItem: result.payload.item, items })),
        tap(({ updatedItem, items }) => {
          let diff: number;

          const lineItems = items.map(item => {
            if (item.id === updatedItem.itemId) {
              diff = item.quantity.value - updatedItem.quantity;
              return { ...item, quantity: { value: Math.abs(diff) } };
            }

            return item;
          });

          if (diff !== undefined) {
            if (diff > 0) {
              return this.camfilTrackingService.trackCartRemoveItem(lineItems, DataLayerPageType.Checkout);
            }

            this.camfilTrackingService.trackCartAddItem(lineItems, DataLayerPageType.Checkout);
          }
        })
      ),
    { dispatch: false }
  );

  trackDeleteItemsFromBasket$ = createEffect(
    () =>
      this.conditionalActions$.pipe(
        ofType(camfilDeleteBucketItemSuccess),
        concatLatestFrom(() => this.store.pipe(select(getCurrentCamfilBucketsItems))),
        map(([deleteItemPayload, lineItems]) => lineItems.filter(i => i.id === deleteItemPayload.payload.itemId)),
        tap(lineItems => this.camfilTrackingService.trackCartRemoveItem(lineItems, DataLayerPageType.Checkout))
      ),
    { dispatch: false }
  );

  trackBeginCheckout$ = createEffect(
    () =>
      this.conditionalActions$.pipe(
        ofType(routerNavigatedAction),
        mapToPayloadProperty('routerState'),
        filter(routerState => routerState.url === '/checkout/onestep'),
        switchMap(() =>
          this.store.pipe(
            select(getCurrentCamfilBucketsItems),
            filter(items => !!items.length),
            whenTruthy(),
            take(1)
          )
        ),
        map(currentBasket => this.camfilTrackingService.trackBeginCheckout(currentBasket))
      ),
    { dispatch: false }
  );

  trackSelectItem$ = createEffect(
    () =>
      this.store.pipe(
        select(selectRouter),
        whenTruthy(),
        skipWhile(() => !this.cookiesService.cookieConsentFor('tracking')),
        pairwise(),
        filter(([prevRoute, currentRoute]) => prevRoute?.state && currentRoute?.state?.params?.sku),
        mergeMap(([prevRoute]) =>
          this.store.pipe(
            select(getSelectedProduct),
            whenTruthy(),
            skipWhile(product => !product.defaultCategory),
            take(1),
            map(product => ({
              product,
              pageType: this.getPageTypeFromPath(prevRoute.state.path, prevRoute.state.params),
            }))
          )
        ),
        tap(({ product, pageType }) => this.camfilTrackingService.trackSelectItem(product, pageType))
      ),
    { dispatch: false }
  );

  trackViewItem$ = createEffect(
    () =>
      this.store.pipe(
        select(selectRouteParam('sku')),
        whenTruthy(),
        skipWhile(() => !this.cookiesService.cookieConsentFor('tracking')),
        switchMap(() =>
          this.store.pipe(
            select(getSelectedProduct),
            whenTruthy(),
            skipWhile(product => !product.defaultCategory),
            take(1)
          )
        ),
        map(product => this.camfilTrackingService.trackViewItem(product))
      ),
    { dispatch: false }
  );

  trackOrder$ = createEffect(
    () =>
      this.conditionalActions$.pipe(
        ofType(trackCreateOrderCamfil),
        mapToPayload(),
        mergeMap(({ order, items }) =>
          this.store.pipe(
            select(getSubmittedBasket),
            take(1),
            map(submittedBasket => ({ order, items, submittedBasket }))
          )
        ),
        tap(({ order, items, submittedBasket }) =>
          this.camfilTrackingService.trackOrder(
            submittedBasket,
            order.statusCode === 'RFQ' ? DataLayerOrderType.Quotation : DataLayerOrderType.Order,
            items
          )
        )
      ),
    { dispatch: false }
  );

  trackCamCardCreate$ = createEffect(
    () =>
      this.conditionalActions$.pipe(
        ofType(createCamCardSuccess),
        mapToPayloadProperty('camCard'),
        mergeMap(camCard => this.getPageTypeFromRouter().pipe(map(pageType => ({ camCard, pageType })))),
        tap(({ camCard, pageType }) => this.camfilTrackingService.trackCamCardCreate(camCard, pageType))
      ),
    { dispatch: false }
  );

  trackCamCardCreateFromBasket$ = createEffect(
    () =>
      this.conditionalActions$.pipe(
        ofType(addBasketToNewCamCardSuccess),
        mapToPayloadProperty('camCard'),
        tap(camCard => {
          this.camfilTrackingService.trackCamCardCreate(camCard, DataLayerPageType.Checkout);
          this.camfilTrackingService.trackCamCardAddItem(camCard, DataLayerPageType.Checkout);
        })
      ),
    { dispatch: false }
  );

  trackCamCardEdit$ = createEffect(
    () =>
      this.conditionalActions$.pipe(
        ofType(updateCamCardSuccess),
        concatLatestFrom(() => this.store.pipe(select(getSelectedCamCardDetails))),
        map(([, camCardDetails]) => camCardDetails),
        tap(camCard => this.camfilTrackingService.trackCamCardEdit(camCard))
      ),
    { dispatch: false }
  );

  trackCamCardAddItem$ = createEffect(
    () =>
      this.conditionalActions$.pipe(
        ofType(addProductToCamCardSuccess),
        mapToPayloadProperty('camCard'),
        mergeMap(() => this.store.pipe(select(getSelectedCamCardDetails), whenTruthy(), take(1))),
        mergeMap(camCard => this.getPageTypeFromRouter().pipe(map(pageType => ({ camCard, pageType })))),
        tap(({ camCard, pageType }) => this.camfilTrackingService.trackCamCardAddItem(camCard, pageType))
      ),
    { dispatch: false }
  );

  trackCamCardEditItem$ = createEffect(
    () =>
      this.conditionalActions$.pipe(
        ofType(updateCamCardProductSuccess),
        concatLatestFrom(() => this.store.pipe(select(getSelectedCamCardDetails))),
        map(([, camCardDetails]) => camCardDetails),
        mergeMap(camCard => this.getPageTypeFromRouter().pipe(map(pageType => ({ camCard, pageType })))),
        tap(({ camCard, pageType }) => this.camfilTrackingService.trackCamCardEdit(camCard, pageType))
      ),
    { dispatch: false }
  );

  trackCamCardRemoveItem$ = createEffect(
    () =>
      this.conditionalActions$.pipe(
        ofType(removeItemFromCamCardSuccess),
        concatLatestFrom(() => this.store.pipe(select(getSelectedCamCardDetails))),
        map(([, camCardDetails]) => camCardDetails),
        tap(camCard => this.camfilTrackingService.trackCamCardEdit(camCard))
      ),
    { dispatch: false }
  );

  trackCamCardDelete$ = createEffect(
    () =>
      this.conditionalActions$.pipe(
        ofType(deleteCamCardSuccess),
        mapToPayloadProperty('camCardId'),
        mergeMap(camCardId => this.getPageTypeFromRouter().pipe(map(pageType => ({ camCardId, pageType })))),
        tap(({ camCardId, pageType }) => this.camfilTrackingService.trackCamCardDelete(camCardId, pageType))
      ),
    { dispatch: false }
  );

  // Items lists tracking
  trackViewItemList$ = createEffect(
    () =>
      this.conditionalActions$.pipe(
        ofType(setProductListingPages),
        whenTruthy(),
        map(action => action.payload),
        tap(productListingType => {
          // Get [page: number] key from ProductListingType
          const pageKey: string | undefined = Object.keys(productListingType).find(key => {
            const pageNumber = parseInt(key, 10);
            return !isNaN(pageNumber) && Array.isArray(productListingType[pageNumber]);
          });

          if (pageKey !== undefined) {
            const pageNumber = parseInt(pageKey, 10);
            const skus = productListingType[pageNumber];

            if (!skus?.length) {
              return;
            }

            if (productListingType.id.type === 'category') {
              this.camfilTrackingService.trackViewItemList(
                skus,
                DataLayerPageType.ProductListing,
                productListingType.id.value
              );
            } else if (productListingType.id.type === 'search') {
              this.camfilTrackingService.trackViewItemList(skus, DataLayerPageType.SearchResult);
            }
          }
        })
      ),
    { dispatch: false }
  );

  trackViewItemListInQuoteDetails$ = createEffect(
    () =>
      this.conditionalActions$.pipe(
        ofType(loadCamfilQuoteSuccess),
        mapToPayloadProperty('quote'),
        tap(quoteDetails => this.camfilTrackingService.trackViewItemListFromQuotation(quoteDetails))
      ),
    { dispatch: false }
  );

  trackViewItemListInCamCardDetails$ = createEffect(
    () =>
      this.store.pipe(
        select(selectRouter),
        whenTruthy(),
        skipWhile(() => !this.cookiesService.cookieConsentFor('tracking')),
        skipWhile(router => !router.state),
        map(router => this.getPageTypeFromPath(router.state.path, router.state.params)),
        filter(pageType => pageType === DataLayerPageType.CamCardDetail),
        switchMap(() =>
          this.store.pipe(
            select(getSelectedCamCardFullDetails),
            whenTruthy(),
            take(1),
            tap(camCard => {
              if (camCard?.camCardItems || camCard?.subCamCards) {
                this.camfilTrackingService.trackViewItemListFromCamCard(camCard);
              }
            })
          )
        )
      ),
    { dispatch: false }
  );

  private getPageTypeFromRouter(): Observable<DataLayerPageType> {
    return this.store.pipe(select(selectRouter)).pipe(
      whenTruthy(),
      skipWhile(() => !this.cookiesService.cookieConsentFor('tracking')),
      skipWhile(router => !router.state?.path),
      map(router => this.getPageTypeFromPath(router.state.path, router.state.params)),
      take(1)
    );
  }

  getPageTypeFromPath(path: string, params: Params): DataLayerPageType {
    if (path === 'account/camcards') {
      return DataLayerPageType.CamCardListing;
    }

    if (path === 'account/camcards/create') {
      return DataLayerPageType.CamCardDetail;
    }

    if (path === 'account/camcards/:camCardName') {
      return DataLayerPageType.CamCardDetail;
    }

    if (path === 'account/quotes/:id') {
      return DataLayerPageType.QuoteDetail;
    }

    if (path === 'search/:searchTerm') {
      return DataLayerPageType.SearchResult;
    }

    if (`${path}`.includes('checkout')) {
      return DataLayerPageType.Checkout;
    }

    if (params?.sku) {
      return DataLayerPageType.ProductDetail;
    }

    return DataLayerPageType.ProductListing;
  }
}
