<ul class="filter-list" [id]="'filter-list_' + filterElement.name">
  <ng-container *ngFor="let facet of getFacets()">
    <li class="filter-item filter-layer{{ facet.level }}" [ngClass]="{ 'filter-selected': facet.selected }">
      <!-- selected -->
      <ng-container *ngIf="facet.selected; else notSelectedBox">
        <button
          type="button"
          class="btn btn-link btn-link-action"
          (click)="filter(facet)"
          [attr.data-testing-id]="'filter-link-' + (facet.name | sanitize)"
        >
          <span class="filter-item-name"> {{ facet.displayName }} </span>
          <span class="count"> ({{ facet.count }}) </span>
          <ng-container *ngIf="filterElement.displayType === 'text_clear'">
            <fa-icon [icon]="['fas', 'times']" class="float-right filter-clear" />
          </ng-container>
        </button>
      </ng-container>

      <!-- not selected -->
      <ng-template #notSelectedBox>
        <button
          type="button"
          class="filter-item-name btn btn-link btn-link-action"
          (click)="filter(facet)"
          [attr.data-testing-id]="'filter-link-' + (facet.name | sanitize)"
        >
          {{ facet.displayName }}
          <ng-container *ngIf="facet.level === maxLevel"> ({{ facet.count }}) </ng-container>
        </button>
      </ng-template>
    </li>
  </ng-container>
  <!-- show all/less -->
  <li class="filter-item" *ngIf="filterElement.limitCount !== -1 && facets.length > filterElement.limitCount">
    <button type="button" class="btn btn-link btn-link-action" (click)="showAll = !showAll">
      {{ (showAll ? 'search.filter.show_less.link' : 'search.filter.show_all.link') | translate }}
    </button>
  </li>
</ul>
