<mat-checkbox
  (click)="(props.click)"
  [formControl]="formControl"
  [id]="id"
  [name]="field.name"
  [formlyAttributes]="field"
  [tabIndex]="props.tabindex"
  [indeterminate]="props.indeterminate && formControl.value === null"
  [color]="props.color"
  [labelPosition]="props.labelPosition"
>
  {{ props.label }}
  <span
    *ngIf="props.required && props.hideRequiredMarker !== true"
    aria-hidden="true"
    class="mat-form-field-required-marker mat-mdc-form-field-required-marker"
    >*</span
  >
</mat-checkbox>
