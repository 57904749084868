<div class="filter-group" [ngClass]="{ collapsed: isCollapsed }">
  <h3
    (click)="toggle()"
    (keydown.enter)="toggle()"
    tabindex="0"
    [attr.aria-expanded]="!isCollapsed"
    [attr.aria-controls]="'filter-list_' + title"
  >
    {{ title }}
    <fa-icon [icon]="['fas', isCollapsed ? 'angle-right' : 'angle-down']" class="float-right" />
  </h3>

  <ng-content></ng-content>
</div>
