import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'camfilOrderStatusText',
  pure: true,
})
export class CamfilOrderStatusTextPipe implements PipeTransform {
  transform(value: string): string {
    return value ? `camfil.dynamic.account.orderlist.status.${value.trim().replace(' ', '_').toLowerCase()}` : '---';
  }
}
