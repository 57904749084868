import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CamfilLoginOnBehalfQueryParams } from 'camfil-core/identity-provider/camfil-login-on-behalf-identity-provider';
import {
  CamfilTrackAndTraceCollectionData,
  CamfilTrackAndTraceData,
} from 'camfil-models/camfil-order-track-and-trace/camfil-order-track-and-trace.interface';
import { CamfilOrderTrackAndTraceMapper } from 'camfil-models/camfil-order-track-and-trace/camfil-order-track-and-trace.mapper';
import { CamfilOrderData } from 'camfil-models/camfil-order/camfil-order.interface';
import { CamfilOrderMapper } from 'camfil-models/camfil-order/camfil-order.mapper';
import { CamfilOrder } from 'camfil-models/camfil-order/camfil-order.model';
import { Observable, throwError } from 'rxjs';
import { defaultIfEmpty, map } from 'rxjs/operators';

import { OrderData } from 'ish-core/models/order/order.interface';
import { Order } from 'ish-core/models/order/order.model';
import { ApiService, unpackEnvelope } from 'ish-core/services/api/api.service';

type OrderIncludeType =
  | 'invoiceToAddress'
  | 'commonShipToAddress'
  | 'commonShippingMethod'
  | 'discounts'
  | 'lineItems_discounts'
  | 'lineItems'
  | 'payments'
  | 'payments_paymentMethod'
  | 'payments_paymentInstrument';

@Injectable({
  providedIn: 'root',
})
export class CamfilOrderService {
  constructor(private apiService: ApiService) {}

  private orderHeaders = new HttpHeaders({
    'content-type': 'application/json',
    Accept: 'application/vnd.intershop.order.v1+json',
  });

  private allOrderIncludes: OrderIncludeType[] = [
    'invoiceToAddress',
    'commonShipToAddress',
    'commonShippingMethod',
    'discounts',
    'lineItems_discounts',
    'lineItems',
    'payments',
    'payments_paymentMethod',
    'payments_paymentInstrument',
  ];

  getCamfilOrders(): Observable<CamfilOrder[]> {
    return this.apiService.get('camfilorder').pipe(
      unpackEnvelope(),
      map((orders: CamfilOrderData[]) => orders.map(order => CamfilOrderMapper.fromData(order))),
      defaultIfEmpty([])
    );
  }

  getCamfilOrder(orderId: string): Observable<CamfilOrder> {
    if (!orderId) {
      throw new Error(`getCamfilOrder() called without orderId`);
    }

    return this.apiService
      .get<CamfilOrderData>(`camfilorder/${orderId}`)
      .pipe(map(orderData => CamfilOrderMapper.fromData(orderData)));
  }

  cloneCamfilOrder(orderId: string, merge?: boolean): Observable<CamfilOrder> {
    if (!orderId) {
      throw new Error('cloneCamfilOrder() called without orderId');
    }
    const params = merge ? '?merge=true' : '';
    return this.apiService
      .post<CamfilOrderData>(`camfilorder/${orderId}${params}`)
      .pipe(map(orderData => CamfilOrderMapper.fromData(orderData)));
  }

  createCamfilOrder(
    basketId: string,
    termsAndConditionsAccepted: boolean = false,
    statusCode?: string
  ): Observable<Order[]> {
    const params = new HttpParams().set('include', this.allOrderIncludes.join());

    if (!basketId) {
      throw new Error('createOrder() called without basketId');
    }

    const externalOrderReference =
      window?.localStorage?.getItem(CamfilLoginOnBehalfQueryParams.ERPEmployeeID) || undefined;

    const body = {
      basket: basketId,
      termsAndConditionsAccepted,
      externalOrderReference,
      statusCode,
    };

    return this.apiService
      .post<OrderData>('orders', body, {
        headers: this.orderHeaders,
        params,
      })
      .pipe(map(payload => CamfilOrderMapper.fromOrderCreateData({ data: payload?.data })));
  }

  getCamfilOrderTrackAndTrace(orderId: string) {
    if (!orderId) {
      return throwError(() => new Error('getCamfilOrderTrackAndTrace() called without orderId'));
    }

    return this.apiService.get<CamfilTrackAndTraceCollectionData>(`camfilorder/${orderId}/trackandtrace`).pipe(
      unpackEnvelope<CamfilTrackAndTraceData>(),
      map(trackAndTraceList => CamfilOrderTrackAndTraceMapper.fromListData(trackAndTraceList))
    );
  }
}
