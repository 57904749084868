/* eslint-disable ish-custom-rules/ban-imports-file-pattern */
import { createSelector } from '@ngrx/store';
import { CamfilMaxLengthKey, CamfilMinLengthKey } from 'camfil-models/camfil-configuration/camfil-configuration.model';

import { ConfigurationState } from 'ish-core/store/core/configuration/configuration.reducer';
import { getConfigurationState } from 'ish-core/store/core/configuration/configuration.selectors';

import { getCamfilFeatureState } from '../camfil-store';

import { CamfilConfigurationState, configurationFeatureKey } from './camfil-configuration.reducer';

export const getCamfilConfigurationState = getCamfilFeatureState<CamfilConfigurationState>(configurationFeatureKey);

export const selectIcmChannel = createSelector(getConfigurationState, (state: ConfigurationState) => state.channel);

export const selectAvailableCountryCodes = createSelector(
  getCamfilConfigurationState,
  state => state.availableCountryCodes
);

export const selectTimeZone = createSelector(getCamfilConfigurationState, state => state.timeZone);

export const selectMaintenanceWindows = createSelector(getCamfilConfigurationState, state => state.maintenanceWindows);

export const selectExceptionWindows = createSelector(getCamfilConfigurationState, state => state.exceptionWindows);

export const selectCountryCode = createSelector(getCamfilConfigurationState, state => state.countryCode);

export const selectCountryCode3 = createSelector(getCamfilConfigurationState, state => state.countryCode3);

const getCamfilSettings = createSelector(getCamfilConfigurationState, state => {
  const { countryCode } = state;
  const countryCodeObject: { [key: string]: boolean } = {};

  if (countryCode) {
    countryCodeObject[countryCode] = true;
  }

  return Object.entries(state)
    .filter(([_, value]) => typeof value === 'boolean')
    .reduce((acc, [key, value]) => ({ ...acc, [key]: value, ...countryCodeObject }), {});
});

export const getCamfilConfigurationParameter = <T, O extends string>(path: O) =>
  createSelector(getCamfilSettings, (state): T => {
    const value = path
      .split('.')
      .reduce((obj, key) => (obj?.[key] !== undefined ? obj[key] : undefined), state as Record<string, unknown>);
    return typeof value === 'boolean' ? (value as T) : (undefined as T);
  });

export const getContinueShoppingUrl = createSelector(getCamfilConfigurationState, state => state.continueShoppingUrl);

export const getZipCodeRegExp = createSelector(getCamfilConfigurationState, state => state.zipCodeRegExp);

export const getCountryCodeRegExp = createSelector(getCamfilConfigurationState, state => state.countryCodeRegExp);

export const getBasketSurchargeOrder = createSelector(getCamfilConfigurationState, state => state.basketSurchargeOrder);

export const getBucketSurchargeOrder = createSelector(getCamfilConfigurationState, state => state.bucketSurchargeOrder);

const getMinLength = createSelector(getCamfilConfigurationState, state => state.minLength);

export const getCamfilMinLengthByName = (name: CamfilMinLengthKey) =>
  createSelector(getMinLength, minLength => minLength?.[name]);

export const getMaxLength = createSelector(getCamfilConfigurationState, state => state.maxLength);

export const getCamfilMaxLengthByName = (name: CamfilMaxLengthKey) =>
  createSelector(getMaxLength, maxLength => maxLength[name]);

export const getUiConfig = createSelector(getCamfilConfigurationState, state => ({
  useMaterialTypography: state.useMaterialTypography,
}));
