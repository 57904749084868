import { Pipe, PipeTransform } from '@angular/core';
import { CamfilCustomer } from 'camfil-models/camfil-customer/camfil-customer.model';

export function formatCamfilCustomer(c: CamfilCustomer): string {
  return [c?.department, c?.customerNo, c?.companyName].filter(Boolean).join(', ');
}

@Pipe({ name: 'camfilCustomer', pure: true })
export class CamfilCustomerPipe implements PipeTransform {
  transform(c: CamfilCustomer): string {
    return formatCamfilCustomer(c);
  }
}
