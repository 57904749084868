import { Injectable } from '@angular/core';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { CamfilCustomersFacade } from 'camfil-core/facades/camfil-customers.facade';
import { CamfilCustomer } from 'camfil-models/camfil-customer/camfil-customer.model';
import { getOptions$ } from 'camfil-shared/formly/utils/camfil-form-utils';

import { FieldLibraryConfiguration } from 'ish-shared/formly/field-library/configurations/field-library-configuration';

@Injectable()
export class CustomerConfiguration extends FieldLibraryConfiguration {
  constructor(private customersFacade: CamfilCustomersFacade) {
    super();
  }

  id = 'customerFLC';

  getFieldConfig(): FormlyFieldConfig {
    return {
      key: 'customer',
      type: 'camfil-select-field',
      props: {
        label: 'camfil.account.cam_card.add_to_form.customer_name',
        required: true,
        valueProp: (option: CamfilCustomer) => option,
        labelProp: (option: CamfilCustomer) => option?.fullCustomerName,
        compareWith: (o1: CamfilCustomer, o2: CamfilCustomer) => o1?.id === o2?.id,
      },
      hooks: {
        onInit: (field: FormlyFieldConfig) => {
          const options$ = getOptions$({
            field,
            addEmptyOption: true,
            autoSelectSingleOption: true,
            autoDisableSingleOption: true,
            observableFn: () => this.customersFacade.customers$,
          });

          field.props.options = options$;
        },
      },
    };
  }
}
