import { Injectable } from '@angular/core';
import { Validators } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { CamfilConfigurationFacade } from 'camfil-core/facades/camfil-configuration.facade';
import { map } from 'rxjs';

import { FieldLibraryConfiguration } from 'ish-shared/formly/field-library/configurations/field-library-configuration';

@Injectable()
export class DeliveryAddressLine2CompanyNameConfiguration extends FieldLibraryConfiguration {
  constructor(private camfilConfigurationFacade: CamfilConfigurationFacade) {
    super();
  }

  id = 'deliveryAddressLine2FLC';

  getFieldConfig(): FormlyFieldConfig {
    return {
      key: 'deliveryAddress.addressLine2',
      type: 'camfil-textarea-field',
      props: {
        label: 'account.default_address.street2.label',
        rows: 3,
        autosize: false,
      },
      expressions: {
        hide: this.camfilConfigurationFacade.isEnabled$('use2ndAddressLineInOrderForm').pipe(map(v => !v)),
      },
      hooks: {
        onInit: (field: FormlyFieldConfig) => {
          this.camfilConfigurationFacade.maxLength$('addressLineTwo').subscribe(maxLength => {
            field.formControl.setValidators([Validators.maxLength(maxLength)]);
          });
        },
      },
    };
  }
}
