<ng-container>
  <div class="d-flex align-items-center justify-content-center form-header">
    <mat-icon [svgIcon]="'user'" />
    <div
      camfilDialogTitle
      class="mt-3 mb-5 dialog-title"
      [ishServerHtml]="'camfil.account.cam_card.access_user.dialog.title' | translate"
    ></div>
  </div>
  <div class="form-content__container" camfilDialogContent separated>
    <form [formGroup]="accessForm" (ngSubmit)="submit()">
      <mat-radio-group aria-label="Select an option" formControlName="user" [(ngModel)]="selectedUser">
        <mat-radio-button class="d-block" *ngFor="let user of users" [value]="user">
          {{ 'camfil.account.cam_card.access_user.dialog.input_user_radio.' + user | translate }}
        </mat-radio-button>
      </mat-radio-group>

      <mat-form-field appearance="fill" class="offset-sm-1">
        <mat-label>{{ 'camfil.account.cam_card.access_user.dialog.select.label' | translate }}</mat-label>
        <mat-select multiple formControlName="contact" [disabled]="!isUserSingle()" [required]="isUserSingle()">
          <mat-option *ngFor="let item of allContacts$ | async" [value]="item.profileId">
            {{ item.firstName }} {{ item.lastName }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </form>
    <camfil-error
      class="test-error"
      [errors]="getField('user').errors"
      [errorValidators]="validators.user"
      [touched]="getField('user').touched || getField('user').dirty"
    />

    <camfil-error
      class="test-error"
      [errors]="getField('contact').errors"
      [errorValidators]="validators.contact"
      [touched]="getField('contact').touched || getField('contact').dirty"
    />
  </div>
  <div camfilDialogActions>
    <button mat-raised-button type="button" class="secondary-button create-order__button" (click)="closeDialog()">
      {{ 'camfil.account.cam_card.access_user.dialog.btn.cancel' | translate }}
    </button>
    <button mat-raised-button color="primary" type="button" class="btn-tool" (click)="submit()">
      {{ 'camfil.account.cam_card.access_user.dialog.btn.save' | translate }}
    </button>
  </div>
</ng-container>
