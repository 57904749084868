<ng-container *ngIf="resizedImages?.length > 0; else empty">
  <swiper class="main" [loop]="true" [enabled]="resizedImages.length > 1" [thumbs]="{ swiper: thumbnailsSwiper }">
    <ng-template *ngFor="let image of resizedImages" swiperSlide>
      <img [src]="image.effectiveUrl" alt="" />
    </ng-template>
  </swiper>
  <swiper
    class="thumbnails"
    *ngIf="resizedImages.length > 1"
    [watchSlidesProgress]="true"
    [slidesPerView]="'auto'"
    [centerInsufficientSlides]="true"
    (swiper)="onThumbnailsSwiperCreated($event)"
  >
    <ng-template *ngFor="let image of resizedImages" swiperSlide>
      <img [src]="image.effectiveUrl" alt="" fill />
    </ng-template>
  </swiper>
</ng-container>
<ng-template #empty>
  <img
    class="empty"
    [src]="'/assets/img/not-available.svg'"
    [attr.alt]="'product.image.not_available.alttext' | translate"
  />
</ng-template>
