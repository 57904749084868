import { createReducer, on } from '@ngrx/store';

import { CamfilCalendarException } from '../../../models/camfil-caledar-exceptions/camfil-caledar-exceptions.model';

import { getWarehouseCalendarSuccess } from './camfil-calendar-exceptions.actions';

export const camfilCalendarExceptionsFeatureKey = 'camfilCalendarExceptions';

export interface CamfilExceptionsState {
  dates: CamfilCalendarException[];
}

const initialState: CamfilExceptionsState = {
  dates: undefined,
};

export const reducer = createReducer(
  initialState,
  on(
    getWarehouseCalendarSuccess,
    (state, action): CamfilExceptionsState => ({
      ...state,
      dates: action.payload.dates,
    })
  )
);
