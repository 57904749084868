import { DestroyRef, Pipe, PipeTransform, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { AccountFacade } from 'ish-core/facades/account.facade';
import { PriceItemHelper } from 'ish-core/models/price-item/price-item.helper';
import { PriceItem } from 'ish-core/models/price-item/price-item.model';
import { Price } from 'ish-core/models/price/price.model';

export function formatSummaryPrice(data: Price | PriceItem, priceType?: 'gross' | 'net'): Price {
  let raw: Price;

  if (data?.type === 'PriceItem') {
    raw = PriceItemHelper.selectType(data, priceType);
  } else {
    raw = data;
  }

  return raw;
}

@Pipe({ name: 'camfilPriceSummary', pure: true })
export class CamfilPriceSummaryPipe implements PipeTransform {
  private destroyRef = inject(DestroyRef);

  constructor(private accountFacade: AccountFacade) {}

  transform(data: Price | PriceItem, quantity = 1, priceType?: 'gross' | 'net'): Price {
    if (!data) {
      return undefined as Price;
    }

    let raw = formatSummaryPrice(data, priceType);

    if (data.type === 'PriceItem') {
      this.accountFacade.userPriceDisplayType$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(type => {
        raw = PriceItemHelper.selectType(data, type);
      });
    }

    const price: Price = { currency: 'USD', value: raw.value || 0, ...raw };

    price.value *= quantity;

    return price;
  }
}
