import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { CamfilConfigurationFacade } from 'camfil-core/facades/camfil-configuration.facade';
import { CamfilProductHelper } from 'camfil-models/camfil-product/camfil-product.helper';
import { TechnicalDocumentTypes } from 'camfil-models/camfil-technical-document/camfil-technical-document.types';
import { Observable, combineLatest, map } from 'rxjs';

import { AppFacade } from 'ish-core/facades/app.facade';
import { ProductContextFacade } from 'ish-core/facades/product-context.facade';
import { Image } from 'ish-core/models/image/image.model';

@Component({
  selector: 'camfil-product-documents',
  templateUrl: './camfil-product-documents.component.html',
  styleUrls: ['./camfil-product-documents.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CamfilProductDocumentsComponent implements OnInit {
  TECHNICAL_DOCUMENT_TYPES = TechnicalDocumentTypes;

  productSKU$: Observable<string>;
  productTechnicalDocuments$: Observable<Image[]>;

  constructor(
    private appFacade: AppFacade,
    private camfilConfigurationFacade: CamfilConfigurationFacade,
    private productContext: ProductContextFacade
  ) {}

  ngOnInit(): void {
    this.productSKU$ = this.productContext.select('sku');
    this.productTechnicalDocuments$ = combineLatest([
      this.productContext.select('product'),
      this.camfilConfigurationFacade.isEnabled$('showAllDocsType'),
      this.camfilConfigurationFacade.isEnabled$('filterDocsByLanguage'),
      this.appFacade.currentLocale$,
    ]).pipe(
      map(([product, showAllDocsType, filterDocsByLanguage, currentLocale]) =>
        CamfilProductHelper.getTechnicalDocuments(product, showAllDocsType, filterDocsByLanguage, currentLocale)
      )
    );
  }
}
