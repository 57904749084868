<ng-template [ngIf]="dialogData.icon">
  <ng-template [ngIf]="iconNameIsSVG(dialogData.icon)">
    <mat-icon [svgIcon]="dialogData.icon.svg" color="primary" camfilDialogIcon />
  </ng-template>
  <ng-template [ngIf]="iconNameIsFont(dialogData.icon)">
    <mat-icon [fontIcon]="dialogData.icon.font" color="primary" camfilDialogIcon />
  </ng-template>
</ng-template>

<h2 camfilDialogTitle>
  <camfil-translatable-content [content]="dialogData.title" />
</h2>

<form [formGroup]="form" camfilDialogFormly>
  <formly-form [form]="form" [fields]="fields" />
</form>

<div camfilDialogActions>
  <button mat-button color="primary" type="button" (click)="dismiss()">
    <camfil-translatable-content [content]="dialogData.dismissButtonLabel" />
  </button>
  <button mat-button color="primary" type="button" (click)="confirm()" [disabled]="!form.valid">
    <camfil-translatable-content [content]="dialogData.confirmButtonLabel" />
  </button>
</div>
