import { createSelector } from '@ngrx/store';
import { CamfilQueueStats, CamfilQueueTaskStatus } from 'camfil-models/camfil-queue/camfil-queue.model';

import { getCamfilFeatureState } from '../camfil-store';

import { State, camfilQueueFeatureKey, initialState, queueAdapter } from './camfil-queue.reducer';

const selectTaskState = getCamfilFeatureState<State>(camfilQueueFeatureKey, initialState);

export const { selectEntities, selectAll } = queueAdapter.getSelectors();

export const selectAllTasks = createSelector(selectTaskState, selectAll);

export const selectCurrentProcessingTask = createSelector(selectAllTasks, tasks =>
  tasks?.find(task => task?.status === CamfilQueueTaskStatus.Processing)
);

export const selectAllTasksByStatus = (status: CamfilQueueTaskStatus) =>
  createSelector(selectAllTasks, tasks => tasks?.filter(task => task?.status === status));

export const selectAllTasksByType = (type: string) =>
  createSelector(selectAllTasks, tasks => tasks?.filter(task => task?.actionType === type));

export const selectAllTaskGroups = createSelector(selectAllTasks, tasks =>
  Object.values(
    tasks?.reduce((acc, task) => {
      acc[task.actionType] = acc[task.actionType] || {
        label: task.actionType,
        type: task.actionType,
        ids: [],
        total: 0,
        completed: 0,
        failed: 0,
        progress: 0,
        remaining: 0,
        processed: 0,
        processing: 0,
        processingId: undefined,
      };
      acc[task.actionType].ids.push(task.id);
      acc[task.actionType].total++;
      switch (task.status) {
        case CamfilQueueTaskStatus.Completed:
          acc[task.actionType].completed++;
          break;
        case CamfilQueueTaskStatus.Failed:
          acc[task.actionType].failed++;
          break;
        case CamfilQueueTaskStatus.Processing:
          acc[task.actionType].processing++;
          acc[task.actionType].processingId = task.id;
          break;
      }
      acc[task.actionType].processed = acc[task.actionType].completed + acc[task.actionType].failed;
      acc[task.actionType].remaining =
        acc[task.actionType].total - acc[task.actionType].completed - acc[task.actionType].failed;
      acc[task.actionType].progress = acc[task.actionType].total
        ? parseFloat(
            (
              ((acc[task.actionType].completed + acc[task.actionType].failed) / acc[task.actionType].total) *
              100
            ).toFixed(2)
          )
        : 0;

      return acc;
      // eslint-disable-next-line ish-custom-rules/no-object-literal-type-assertion
    }, {} as { [type: string]: CamfilQueueStats })
  )
);
