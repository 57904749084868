import { createSelector } from '@ngrx/store';
import { getCamfilFeatureState } from 'camfil-core/store/camfil-store';
import { CamfilB2bUser } from 'camfil-models/camfil-organization/user/camfil-b2b-user.model';

import { B2bUsersState, camfilB2bUsersFeatureKey, userAdapter } from './b2b-users.reducer';

export const getB2bUsersState = getCamfilFeatureState<B2bUsersState>(camfilB2bUsersFeatureKey);

export const getUsersLoading = createSelector(getB2bUsersState, state => state.loading);

export const getUsersError = createSelector(getB2bUsersState, state => state.error);

const { selectAll, selectEntities, selectTotal } = userAdapter.getSelectors(getB2bUsersState);

export const getUsers = selectAll;

export const getUser = (id: string) => createSelector(getUsers, users => users?.find(u => u.id === id));

export const getUsersCount = selectTotal;

export const getSelectedUserId = createSelector(getB2bUsersState, state => state.selected);

export const getSelectedUser = createSelector(
  selectEntities,
  getSelectedUserId,
  (entities, id): CamfilB2bUser => id && entities[id]
);

export const isUserInitialized = createSelector(getB2bUsersState, state => state.initialized);
