import { CamfilContact } from 'camfil-models/camfil-customer/camfil-customer.model';

export class ContactMapper {
  static fromData(payload: CamfilContact[]): CamfilContact[] {
    return payload.map(ContactMapper.handleFullName);
  }

  static handleFullName(contact: CamfilContact): CamfilContact {
    const { firstName, lastName } = contact;
    return {
      ...contact,
      fullName: `${firstName} ${lastName}`,
    };
  }
}
