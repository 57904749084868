import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { CamfilConfigurationService } from 'camfil-core/services/configuration/camfil-configuration.service';
import { assembleURLParamsWithQueryParamControl } from 'camfil-core/utils/camfil-url-form-params';
import { flatten } from 'lodash-es';
import { map, mergeMap } from 'rxjs/operators';

import { FilterService } from 'ish-core/services/filter/filter.service';
import { applyFilter, applyFilterFail, applyFilterSuccess, getAvailableFilter } from 'ish-core/store/shopping/filter';
import { FilterEffects } from 'ish-core/store/shopping/filter/filter.effects';
import { mapErrorToAction, mapToPayload } from 'ish-core/utils/operators';

/* eslint-disable @typescript-eslint/dot-notation */

@Injectable()
export class CamfilFilterEffects extends FilterEffects {
  constructor(
    actions$: Actions,
    filterService: FilterService,
    private store: Store,
    private camfilConfigurationService: CamfilConfigurationService
  ) {
    super(actions$, filterService);
  }

  private currentSearchParameters$ = this.store.pipe(
    select(getAvailableFilter),
    map(filterNavigation => filterNavigation?.filter || []),
    map(filters => flatten(filters.map(filter => filter?.facets))),
    map(facets => facets?.filter(facet => !!facet?.selected)?.map(facet => facet?.searchParameter)),
    map(searchParameters => searchParameters.reduce((acc, searchParameter) => ({ ...acc, ...searchParameter }), {}))
  );

  override applyFilter$ = createEffect(() =>
    this['actions$'].pipe(
      ofType(applyFilter),
      mapToPayload(),
      concatLatestFrom(() => [
        this.currentSearchParameters$,
        this.camfilConfigurationService.isEnabled$('preserveSearchFilters'),
      ]),
      mergeMap(([payload, currentSearchParameters, preserveSearchFilters]) => {
        const searchParameter = preserveSearchFilters
          ? assembleURLParamsWithQueryParamControl([currentSearchParameters], [payload?.searchParameter])
          : payload?.searchParameter;

        return this['filterService'].applyFilter(searchParameter).pipe(
          map(availableFilter => applyFilterSuccess({ availableFilter, searchParameter })),
          mapErrorToAction(applyFilterFail)
        );
      })
    )
  );
}
