import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import {
  closeSearchResultsOverlay,
  openSearchResultsOverlay,
  redirectAfterClearSearchValue,
  setSearchTerm,
  unsetSearchTerm,
} from 'camfil-core/store/shopping/camfil-search/camfil-search.actions';
import {
  getCamfilSearchError,
  getCamfilSearchLoading,
  getCamfilSearchOverlayOpen,
  getCamfilSearchProductListingId,
  getCamfilSearchTerm,
  getCamfilSearchTermValid,
} from 'camfil-core/store/shopping/camfil-search/camfil-search.selectors';
import { Observable, combineLatest, of, switchMap } from 'rxjs';
import { defaultIfEmpty, filter, map } from 'rxjs/operators';

import { ShoppingFacade } from 'ish-core/facades/shopping.facade';
import { CategoryView } from 'ish-core/models/category-view/category-view.model';
import { ProductListingID, ProductListingView } from 'ish-core/models/product-listing/product-listing.model';
import { getCategoryEntities } from 'ish-core/store/shopping/categories';
import { whenTruthy } from 'ish-core/utils/operators';

@Injectable({ providedIn: 'root' })
export class CamfilSearchFacade {
  constructor(private store: Store, private shoppingFacade: ShoppingFacade) {}

  overlayOpen$ = this.store.pipe(select(getCamfilSearchOverlayOpen));

  productListingId$: Observable<ProductListingID> = this.store.pipe(select(getCamfilSearchProductListingId));

  searchLoading$ = this.store.pipe(select(getCamfilSearchLoading));

  searchError$ = this.store.pipe(select(getCamfilSearchError));

  searchTermValid$ = this.store.pipe(select(getCamfilSearchTermValid));

  searchTerm$ = this.store.pipe(select(getCamfilSearchTerm));

  categoriesResults$: Observable<CategoryView[]> = this.searchTerm$.pipe(
    filter(searchTerm => searchTerm?.trim() !== ''),
    switchMap(searchTerm =>
      this.store.pipe(
        select(getCategoryEntities),
        switchMap(categories =>
          combineLatest(Object.keys(categories).map(uniqueId => this.shoppingFacade.category$(uniqueId)))
        ),
        map(list => Object.values(list).filter(item => item.name?.toLowerCase().includes(searchTerm?.toLowerCase())))
      )
    ),
    defaultIfEmpty([])
  );

  categoriesCount$ = this.categoriesResults$.pipe(
    map(categories => categories?.length || 0),
    defaultIfEmpty(0)
  );

  productListingView$ = this.searchTermValid$.pipe(
    switchMap(searchTermValid => {
      if (searchTermValid) {
        return this.productListingId$.pipe(
          whenTruthy(),
          switchMap(id => this.shoppingFacade.productListingView$(id))
        );
      } else {
        // eslint-disable-next-line ish-custom-rules/no-object-literal-type-assertion
        return of({ itemCount: 0, empty: () => true, products: () => [] } as ProductListingView);
      }
    })
  );

  productsCount$ = this.productListingView$.pipe(map(view => Number(view?.itemCount)));

  openOverlay() {
    this.store.dispatch(openSearchResultsOverlay());
  }

  closeOverlay() {
    this.store.dispatch(closeSearchResultsOverlay());
  }

  setSearchTerm(searchTerm: string) {
    this.store.dispatch(setSearchTerm({ searchTerm }));
  }

  unsetSearchTerm() {
    this.store.dispatch(unsetSearchTerm());
  }

  redirectAfterClearSearchValue(searchType: number) {
    this.store.dispatch(redirectAfterClearSearchValue({ searchType }));
  }
}
