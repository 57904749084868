import { ChangeDetectionStrategy, Component, DestroyRef, Input, OnInit, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AbstractControl, FormControl, ValidationErrors } from '@angular/forms';
import { Observable, Subject, combineLatest, map, startWith, withLatestFrom } from 'rxjs';

import { ProductContextFacade } from 'ish-core/facades/product-context.facade';

function closestPreviousValue(value: null | number, min: number, max: number, step: number, zero: boolean): number {
  if (value === undefined) {
    return zero ? 0 : min;
  }
  const rest = value % step;
  const centered = value - rest;
  const previous = centered - step;
  if (previous <= 0 && zero) {
    return 0;
  }
  if (previous < min) {
    return min;
  }
  if (previous > max) {
    return max;
  }
  return previous;
}

function closestNextValue(value: null | number, min: number, max: number, step: number, zero: boolean): number {
  if (value === undefined) {
    return zero ? 0 : min;
  }
  const rest = value % step;
  const centered = value - rest;
  const next = centered + step;
  if (next <= 0 && zero) {
    return 0;
  }
  if (next < min) {
    return min;
  }
  if (next > max) {
    return max;
  }
  return next;
}

enum QuantityChangeType {
  Reset,
  Increase,
  Decrease,
}

/*
  Originaly Intershop support retail sets and provide special label `product.add_to_cart.retail_set.link`.
  But retails sets were broken before upgrade, therefore we have single label for add to cart.

  Missing translations:
    camfil.modal.addNewProduct.error.quantity.type - manfolmed value
    product.quantity.increase.text - + button
    product.quantity.decrease.text - - button

  There is case when showAddToCamCardButtonForNonLoggedInUser && showAddToCartButtonForNonLoggedInUser are both false, there is no point of showing component.
*/
@Component({
  selector: 'camfil-product-counter',
  templateUrl: './camfil-product-counter.component.html',
  styleUrls: ['./camfil-product-counter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CamfilProductCounterComponent implements OnInit {
  /* Must be set before ngOnInit. */
  @Input() amount: FormControl<number>;
  cantIncrease$: Observable<boolean>;
  cantDecrease$: Observable<boolean>;
  maxQty$: Observable<number>;
  private change$ = new Subject<QuantityChangeType>();
  private destroyRef = inject(DestroyRef);

  constructor(private productContext: ProductContextFacade) {}

  ngOnInit(): void {
    const product$ = this.productContext.select('product');
    const quantityValue$ = this.amount.valueChanges.pipe(startWith(this.amount.value));
    const productMinQuantity$ = this.productContext.select('minQuantity');
    const productMaxQuantity$ = this.productContext.select('maxQuantity');
    const productStepQuantity$ = this.productContext.select('stepQuantity');
    const productAllowZeroQuantity$ = this.productContext.select('allowZeroQuantity');
    const productQuantityData$ = combineLatest([
      quantityValue$,
      productMinQuantity$,
      productMaxQuantity$,
      productStepQuantity$,
      productAllowZeroQuantity$,
    ]);
    combineLatest([product$, productMinQuantity$, productMaxQuantity$, productStepQuantity$, productAllowZeroQuantity$])
      .pipe(
        /* That the exact type defintion from angular/core ! */
        /* eslint-disable unicorn/no-null */
        map(([, min, max, step, zero]) => (control: AbstractControl): ValidationErrors | null => {
          let errors: ValidationErrors | null = null;
          /* eslint-enable unicorn/no-null */
          const value = control.value;

          if (!Number.isInteger(value)) {
            errors = { ...errors, type: true };
          }
          if (value < min && !(value === 0 && zero)) {
            errors = { ...errors, min: true };
          }
          if (value > max) {
            errors = { ...errors, max: true };
          }
          if (value % step !== 0) {
            errors = { ...errors, step: { step } };
          }

          return errors;
        }),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(validator => {
        this.amount.setValidators(validator);
      });
    this.cantIncrease$ = productQuantityData$.pipe(
      map(([quantity, min, max, step, zero]) => closestNextValue(quantity, min, max, step, zero) === quantity)
    );
    this.cantDecrease$ = productQuantityData$.pipe(
      map(([quantity, min, max, step, zero]) => closestPreviousValue(quantity, min, max, step, zero) === quantity)
    );
    this.maxQty$ = productQuantityData$.pipe(map(([_, __, max]) => max));
    this.change$
      .pipe(withLatestFrom(productQuantityData$), takeUntilDestroyed(this.destroyRef))
      .subscribe(([change, [quantity, min, max, step, zero]]) => {
        switch (change) {
          case QuantityChangeType.Increase:
            this.amount.patchValue(closestNextValue(quantity, min, max, step, zero));
            break;
          case QuantityChangeType.Decrease:
            this.amount.patchValue(closestPreviousValue(quantity, min, max, step, zero));
            break;
          case QuantityChangeType.Reset:
            break;
        }
      });
    product$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      this.change$.next(QuantityChangeType.Reset);
    });
  }

  onIncrease(): void {
    this.change$.next(QuantityChangeType.Increase);
  }

  onDecrease(): void {
    this.change$.next(QuantityChangeType.Decrease);
  }
}
