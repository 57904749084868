import { ViewportScroller } from '@angular/common';
import { APP_ID, NgModule, TransferState } from '@angular/core';
import { BrowserModule, provideClientHydration } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router, Scroll, UrlSerializer } from '@angular/router';
import { LiveChatWidgetModule } from '@livechat/widget-angular';
import { FormlyModule } from '@ngx-formly/core';
import { TranslateModule } from '@ngx-translate/core';
import { CamfilCoreModule } from 'camfil-core/camfil-core.module';
import { CamfilQueueModule } from 'camfil-core/queue/camfil-queue.module';
import { CamfilLastRoutingModule } from 'camfil-pages/camfil-last-routing.module';
import { CamfilRoutingModule } from 'camfil-pages/camfil-routing.module';
import { CAMFIL_STAY_SCROLL_HOST_PARTS, CAMFIL_STAY_SCROLL_PARAMS } from 'camfil-shared/camfil-constants';
import { CamfilSharedModule } from 'camfil-shared/camfil-shared.module';
import { CamfilShellModule } from 'camfil-shell/camfil-shell.module';
import { filter, pairwise } from 'rxjs';
import { environment } from 'src/environments/environment';

import { COOKIE_CONSENT_VERSION } from 'ish-core/configurations/state-keys';
import { CoreModule } from 'ish-core/core.module';
import { PWAUrlSerializer } from 'ish-core/routing/pwa-url.serializer';
import { CompareRoutingModule } from 'ish-extensions/compare/pages/compare-routing.module';
import { ContactUsRoutingModule } from 'ish-extensions/contact-us/pages/contact-us-routing.module';
import { PunchoutRoutingModule } from 'ish-extensions/punchout/pages/punchout-routing.module';
import { QuickorderRoutingModule } from 'ish-extensions/quickorder/pages/quickorder-routing.module';
import { QuotingRoutingModule } from 'ish-extensions/quoting/pages/quoting-routing.module';
import { RecentlyRoutingModule } from 'ish-extensions/recently/pages/recently-routing.module';
import { StoreLocatorRoutingModule } from 'ish-extensions/store-locator/pages/store-locator-routing.module';
import { TactonRoutingModule } from 'ish-extensions/tacton/pages/tacton-routing.module';
import { AppRoutingModule } from 'ish-pages/app-routing.module';
import { ShellModule } from 'ish-shell/shell.module.camfil';

import { CamfilAppComponent } from './camfil-app.component';
import { CamfilPwaModule } from './camfil-pwa.module';

@NgModule({
  declarations: [CamfilAppComponent],
  /* eslint-disable @angular-eslint/sort-ngmodule-metadata-arrays */
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    TranslateModule,
    CoreModule,
    ShellModule,
    /** Start Camfil-PWA Modules */
    CamfilPwaModule,
    CamfilCoreModule,
    CamfilShellModule,
    CamfilQueueModule,
    CamfilRoutingModule,
    LiveChatWidgetModule,
    /** End Camfil-PWA Modules */
    AppRoutingModule,
    QuickorderRoutingModule,
    QuotingRoutingModule,
    PunchoutRoutingModule,
    TactonRoutingModule,
    StoreLocatorRoutingModule,
    RecentlyRoutingModule,
    CompareRoutingModule,
    ContactUsRoutingModule,
    CamfilSharedModule,
    FormlyModule,
    CamfilLastRoutingModule, // <-- must be last
  ],
  /* eslint-enable @angular-eslint/sort-ngmodule-metadata-arrays */
  bootstrap: [CamfilAppComponent],
  providers: [
    { provide: UrlSerializer, useClass: PWAUrlSerializer },
    { provide: APP_ID, useValue: 'camfil-pwa' },
    PRODUCTION_MODE ? provideClientHydration() : [],
  ],
})
export class CamfilAppModule {
  constructor(transferState: TransferState, private router: Router, private viewportScroller: ViewportScroller) {
    if (!transferState.hasKey<number>(COOKIE_CONSENT_VERSION)) {
      transferState.set(COOKIE_CONSENT_VERSION, environment.cookieConsentVersion);
    }
    // Disable automatic scroll restoration to avoid race conditions
    this.viewportScroller.setHistoryScrollRestoration('manual');
    this.handleScrollOnNavigation();
  }

  /**
   * Related to: https://github.com/angular/angular/issues/26744
   */
  private handleScrollOnNavigation(): void {
    this.router.events
      .pipe(
        filter((e: Scroll) => e instanceof Scroll),
        pairwise()
      )
      .subscribe((e: Scroll[]) => {
        const previous = e[0];
        const current = e[1];

        if (current.position) {
          // Backward navigation
          this.viewportScroller.scrollToPosition(current.position);
        } else if (current.anchor) {
          // Anchor navigation
          this.viewportScroller.scrollToAnchor(current.anchor);
        } else {
          // Check if routes match, or if it is only a query param change
          if (this.getBaseRoute(previous.routerEvent.url, 0) !== this.getBaseRoute(current.routerEvent.url, 0)) {
            // Routes don't match, this is actual forward navigation
            // Default behavior: scroll to top
            let curr: [number, number] = [0, 0];

            if (this.stayIsHost(this.getBaseRoute(current.routerEvent.url, 0))) {
              curr = this.viewportScroller.getScrollPosition();
            }

            this.viewportScroller.scrollToPosition(curr);
          } else {
            if (this.stayIsParam(this.getBaseRoute(current.routerEvent.url, 1))) {
              const curr = this.viewportScroller.getScrollPosition();
              this.viewportScroller.scrollToPosition(curr);
            } else {
              this.viewportScroller.scrollToPosition([0, 0]);
            }
          }
        }
      });
  }

  private getBaseRoute(url: string, part: 0 | 1): string {
    // return url without query params
    return url.split('?')?.[part];
  }

  private stayIsParam(url: string): boolean {
    return CAMFIL_STAY_SCROLL_PARAMS.some(p => url?.includes(p));
  }

  private stayIsHost(url: string): boolean {
    return CAMFIL_STAY_SCROLL_HOST_PARTS.some(p => url?.includes(p[0]) && !url?.includes(p[1]));
  }
}
