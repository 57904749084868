import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FORMLY_CONFIG, FormlyModule as FormlyBaseModule } from '@ngx-formly/core';
import { FormlyPresetModule } from '@ngx-formly/core/preset';
import { registerCamfilLibraryConfigReplacementExtension } from 'camfil-shared/formly/field-library/camfil-library-config-replacement.extension';
import { CamCardNameConfiguration } from 'camfil-shared/formly/field-library/configurations/cam-card-name.configuration';
import { CamCardReminderFlagConfiguration } from 'camfil-shared/formly/field-library/configurations/cam-card-reminder-flag.configuration';
import { CustomerConfiguration } from 'camfil-shared/formly/field-library/configurations/customer.configuration';
import { DeliveryAddressCityConfiguration } from 'camfil-shared/formly/field-library/configurations/delivery-address-city.configuration';
import { DeliveryAddressCompanyNameConfiguration } from 'camfil-shared/formly/field-library/configurations/delivery-address-company-name.configuration';
import { DeliveryAddressCountryCodeConfiguration } from 'camfil-shared/formly/field-library/configurations/delivery-address-country-code.configuration';
import { DeliveryAddressGoodsAcceptanceNoteConfiguration } from 'camfil-shared/formly/field-library/configurations/delivery-address-goods-acceptance-note.configuration';
import { DeliveryAddressIdConfiguration } from 'camfil-shared/formly/field-library/configurations/delivery-address-id.configuration';
import { DeliveryAddressLine1CompanyNameConfiguration } from 'camfil-shared/formly/field-library/configurations/delivery-address-line1.configuration';
import { DeliveryAddressLine2CompanyNameConfiguration } from 'camfil-shared/formly/field-library/configurations/delivery-address-line2.configuration';
import { DeliveryAddressPostalCodeConfiguration } from 'camfil-shared/formly/field-library/configurations/delivery-address-postal-code.configuration';
import { DeliveryIntervalConfiguration } from 'camfil-shared/formly/field-library/configurations/delivery-interval.configuration';
import { ErpIdConfiguration } from 'camfil-shared/formly/field-library/configurations/erp-id.configuration';
import { IdConfiguration } from 'camfil-shared/formly/field-library/configurations/id.configuration';
import { InvoiceMarkConfiguration } from 'camfil-shared/formly/field-library/configurations/invoice-mark.configuration';
import { LastDeliveryDateConfiguration } from 'camfil-shared/formly/field-library/configurations/last-delivery-date.configuration';
import { MeasurementDepthConfiguration } from 'camfil-shared/formly/field-library/configurations/measurement-depth.configuration';
import { MeasurementDiameterConfiguration } from 'camfil-shared/formly/field-library/configurations/measurement-diameter.configuration';
import { MeasurementHeightConfiguration } from 'camfil-shared/formly/field-library/configurations/measurement-height.configuration';
import { MeasurementWidthConfiguration } from 'camfil-shared/formly/field-library/configurations/measurement-width.configuration';
import { NextDeliveryDateConfiguration } from 'camfil-shared/formly/field-library/configurations/next-delivery-date.configuration';
import { OrderMarkConfiguration } from 'camfil-shared/formly/field-library/configurations/order-mark.configuration';

import {
  FIELD_LIBRARY_CONFIGURATION as FLC,
  FIELD_LIBRARY_CONFIGURATION_GROUP as FLG,
  FieldLibrary,
} from 'ish-shared/formly/field-library/field-library';
import { FormsSharedModule } from 'ish-shared/forms/forms.module';

import { CustomerServiceNoteConfiguration } from './configurations/customer-service-note.configuration';
import { PhoneNumberConfiguration } from './configurations/phone-number.configuration';

@NgModule({
  imports: [CommonModule, FormlyPresetModule, FormsSharedModule, FormlyBaseModule.forChild({})],
  providers: [
    FieldLibrary,
    { provide: FLC, useClass: CamCardNameConfiguration, multi: true },
    { provide: FLC, useClass: CustomerConfiguration, multi: true },
    { provide: FLC, useClass: OrderMarkConfiguration, multi: true },
    { provide: FLC, useClass: PhoneNumberConfiguration, multi: true },
    { provide: FLC, useClass: InvoiceMarkConfiguration, multi: true },
    { provide: FLC, useClass: IdConfiguration, multi: true },
    { provide: FLC, useClass: ErpIdConfiguration, multi: true },
    { provide: FLC, useClass: DeliveryAddressIdConfiguration, multi: true },
    { provide: FLC, useClass: DeliveryAddressCompanyNameConfiguration, multi: true },
    {
      provide: FLC,
      useClass: DeliveryAddressLine1CompanyNameConfiguration,
      multi: true,
    },
    {
      provide: FLC,
      useClass: DeliveryAddressLine2CompanyNameConfiguration,
      multi: true,
    },
    {
      provide: FLC,
      useClass: DeliveryAddressPostalCodeConfiguration,
      multi: true,
    },
    {
      provide: FLC,
      useClass: DeliveryAddressCityConfiguration,
      multi: true,
    },
    {
      provide: FLC,
      useClass: DeliveryAddressCountryCodeConfiguration,
      multi: true,
    },
    { provide: FLC, useClass: DeliveryIntervalConfiguration, multi: true },
    { provide: FLC, useClass: LastDeliveryDateConfiguration, multi: true },
    { provide: FLC, useClass: NextDeliveryDateConfiguration, multi: true },
    { provide: FLC, useClass: CamCardReminderFlagConfiguration, multi: true },
    { provide: FLC, useClass: DeliveryAddressGoodsAcceptanceNoteConfiguration, multi: true },
    { provide: FLC, useClass: MeasurementWidthConfiguration, multi: true },
    { provide: FLC, useClass: MeasurementHeightConfiguration, multi: true },
    { provide: FLC, useClass: MeasurementDepthConfiguration, multi: true },
    { provide: FLC, useClass: MeasurementDiameterConfiguration, multi: true },
    { provide: FLC, useClass: CustomerServiceNoteConfiguration, multi: true },
    {
      provide: FLG,
      useValue: {
        id: 'camCardDetailsFLG',
        shortcutFor: ['camCardNameFLC', 'customerFLC', 'orderMarkFLC', 'invoiceMarkFLC', 'erpIdFLC'],
      },
      multi: true,
    },
    {
      provide: FLG,
      useValue: {
        id: 'deliveryAddressFLG',
        shortcutFor: [
          'deliveryAddressIdFLC',
          'deliveryAddressCompanyNameFLC',
          'deliveryAddressLine1FLC',
          'deliveryAddressLine2FLC',
        ],
      },
      multi: true,
    },
    {
      provide: FLG,
      useValue: {
        id: 'deliveryAddressPostalCodeAndCityFLG',
        shortcutFor: ['deliveryAddressPostalCodeFLC', 'deliveryAddressCityFLC'],
      },
      multi: true,
    },
    {
      provide: FLG,
      useValue: {
        id: 'camCardDeliveryDetailsFLG',
        shortcutFor: [
          'lastDeliveryDateFLC',
          'deliveryIntervalFLC',
          'nextDeliveryDateFLC',
          'camCardReminderFlagFLC',
          'deliveryAddressGoodsAcceptanceNoteFLC',
        ],
      },
      multi: true,
    },
    {
      provide: FLG,
      useValue: {
        id: 'measurementsFLG',
        shortcutFor: ['measurementWidthFLC', 'measurementHeightFLC', 'measurementDepthFLC'],
      },
      multi: true,
    },
    {
      provide: FORMLY_CONFIG,
      useFactory: registerCamfilLibraryConfigReplacementExtension,
      deps: [FieldLibrary],
      multi: true,
    },
  ],
})
export class CamfilFieldLibraryModule {}
