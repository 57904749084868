import { createAction } from '@ngrx/store';
import { BucketExtension } from 'camfil-models/bucket-extension/bucket-extension.model';
import { CamfilAddress } from 'camfil-models/camfil-address/camfil-address.model';
import { Attribute } from 'camfil-models/camfil-attribute/attribute.model';
import { CamfilAddItem, CamfilBasket } from 'camfil-models/camfil-basket/camfil-basket.model';
import { CamfilBucket } from 'camfil-models/camfil-bucket/camfil-bucket.model';

import { LineItemUpdate } from 'ish-core/models/line-item-update/line-item-update.model';
import { LineItem } from 'ish-core/models/line-item/line-item.model';
import { httpError, payload } from 'ish-core/utils/ngrx-creators';

export const loadCamfilBuckets = createAction('[Camfil Bucket Internal] Load CamfilBuckets');

export const loadCamfilBucketsSuccess = createAction(
  '[Camfil Bucket API] Load CamfilBuckets Success',
  payload<{ buckets: CamfilBucket[] }>()
);

export const loadCamfilBucketsFailure = createAction('[Camfil Bucket API] Load CamfilBuckets Failure', httpError());

export const loadCamfilBucketsExtensions = createAction(
  '[Camfil Bucket Internal] Load CamfilBuckets Extensions',
  payload<{ buckets: CamfilBucket[] }>()
);

export const loadCamfilBucketsExtensionsSuccess = createAction(
  '[Camfil Bucket API] Load CamfilBuckets Extensions Success',
  payload<{ camfilBucketUpdates: Partial<CamfilBucket>[] }>()
);

export const loadCamfilBucketsExtensionsFailure = createAction(
  '[Camfil Bucket API] Load CamfilBuckets Extensions Failure',
  httpError()
);

export const addEmptyCamfilBucket = createAction(
  '[Camfil Bucket Internal] Add Empty Bucket',
  payload<{ bucket: CamfilBucket }>()
);

export const addEmptyCamfilBucketSuccess = createAction(
  '[Camfil Bucket Internal] Add Empty Bucket Success',
  payload<{ bucket: CamfilBucket }>()
);

export const updateEmptyCamfilBucket = createAction(
  '[Camfil Bucket Internal] Update Empty Bucket',
  payload<{ bucket: CamfilBucket }>()
);

export const deleteEmptyCamfilBucket = createAction(
  '[Camfil Bucket Internal] Remove Empty Bucket',
  payload<{ bucketId: string }>()
);
export const deleteCamfilBucket = createAction(
  '[Camfil Bucket Internal] Delete Bucket',
  payload<{ basketId: string; bucketId: string; deliveryAddressId: string }>()
);

export const deleteCamfilBucketFail = createAction('[Camfil Bucket API] Delete Bucket Fail', httpError());

export const deleteCamfilBucketSuccess = createAction('[Camfil Bucket API] Delete Bucket Success');

export const deleteCamfilBuckets = createAction(
  '[Camfil Bucket Internal] Delete Buckets',
  payload<{ orders: CamfilBucket[] }>()
);

export const deleteCamfilBucketsSuccess = createAction('[Camfil Bucket API] Delete Buckets Success');

export const deleteCamfilBucketsFail = createAction('[Camfil Bucket API] Delete Buckets Fail', httpError());

export const camfilDeleteBucketItem = createAction(
  '[Camfil Bucket Internal] Delete Basket Item',
  payload<{ itemId: string }>()
);

export const camfilDeleteBucketItemSuccess = createAction(
  '[Camfil Bucket API]  Delete Basket Item in Edit Mode',
  payload<{ itemId: string; bucketId: string }>()
);

export const addBucketItemAttribute = createAction(
  '[Camfil Bucket Internal] Add Attributes for selected line item ',
  payload<{ basketId: string; lineItemId: string; bucketId: string; lineItemAttribute: Attribute }>()
);

export const addBucketItemAttributeSuccess = createAction(
  '[Camfil Bucket API] Add Attributes for selected line item Success ',
  payload<{ basketId: string; lineItemId: string; bucketId: string; lineItemAttribute: Attribute }>()
);

export const updateBucketItemAttributes = createAction(
  '[Camfil Bucket Internal] Update Basket Item Attribute',
  payload<{
    basketId: string;
    lineItemId: string;
    bucketId: string;
    lineItemAttribute: Attribute;
  }>()
);

export const updateBucketItemAttributesSuccess = createAction(
  '[Camfil Bucket API] Update Basket Item Attribute Success',
  payload<{
    basketId: string;
    lineItemId: string;
    bucketId: string;
    lineItemAttribute: Attribute;
  }>()
);

export const camfilUpdateBucketItem = createAction(
  '[Camfil Bucket Internal] Update Basket Item',
  payload<{ lineItemUpdate: LineItemUpdate }>()
);

export const camfilUpdateBucketItemSuccess = createAction(
  '[Camfil Bucket Internal] Update Basket Item Success',
  payload<{ item: LineItemUpdate; bucketId: string }>()
);

export const cancelBucketEditMode = createAction(
  '[Camfil Bucket Internal] Cancel Bucket Edit Mode',
  payload<{ camfilBuckets: CamfilBucket[] }>()
);

export const camfilDragLineItem = createAction(
  '[Camfil Bucket Internal] Move Product between buckets',
  payload<{ updatedLineItems: LineItem[]; bucketId: string }>()
);

export const prepareCamfilAddToCart = createAction(
  '[Camfil Bucket Internal] prepare Add to cart',
  payload<{
    products: CamfilAddItem[];
    urn: string;
    address?: CamfilAddress;
    bucket?: Partial<CamfilBucket>;
  }>()
);

export const camfilAddToCart = createAction(
  '[Camfil Bucket Internal] Add to cart',
  payload<{ items: CamfilAddItem[]; address?: CamfilAddress; bucketExtension?: BucketExtension }>()
);

export const camfilAddToCartSuccess = createAction(
  '[Camfil Bucket API] Add to cart Success',
  payload<{ items: CamfilAddItem[]; address?: CamfilAddress; bucketExtension?: BucketExtension }>()
);

export const camfilCreateBucket = createAction(
  '[Camfil Bucket Internal] Add to cart - Create Bucket',
  payload<{ items: CamfilAddItem[]; address: CamfilAddress; bucketExtension: BucketExtension }>()
);

export const camfilCreateBucketSuccess = createAction(
  '[Camfil Bucket API] Add to cart - Create Bucket Success',
  payload<{ items: CamfilAddItem[]; addressId: string; bucketExtension: BucketExtension }>()
);

export const camfilUpdateBucket = createAction(
  '[Camfil Bucket Internal] Add to cart - Update Bucket',
  payload<{ items: CamfilAddItem[]; addressId: string; bucketExtension: BucketExtension }>()
);

export const camfilUpdateBucketSuccess = createAction(
  '[Camfil Bucket API] Add to cart - Update Bucket Success',
  payload<{ items: CamfilAddItem[]; urn?: string }>()
);

export const camfilUpdateBucketFail = createAction('[Camfil Bucket API] Add to cart - Update Bucket Fail', httpError());

export const camfilAddItemsToBucket = createAction(
  '[Camfil Bucket Internal] Add to cart - Add Items To Bucket',
  payload<{ items: CamfilAddItem[] }>()
);

export const camfilAddItemsToBucketSuccess = createAction(
  '[Camfil Bucket API] Add to cart - Add Items To Bucket Success'
);

export const camfilAddItemsToBucketFail = createAction(
  '[Camfil Bucket API] Add to cart - Add Items To Bucket Fail',
  httpError()
);

export const updateBuckets = createAction(
  '[Camfil Bucket Internal] Update Buckets',
  payload<{
    buckets: CamfilBucket[];
    basket: CamfilBasket;
  }>()
);
