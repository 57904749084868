import { PriceItemData } from 'ish-core/models/price-item/price-item.interface';
import { PriceItemMapper } from 'ish-core/models/price-item/price-item.mapper';

import { CamfilPriceItem } from './camfil-price-item.model';

export class CamfilPriceItemMapper extends PriceItemMapper {
  /**
   * Maps a price item to client side model instance.
   *
   * @param priceItem Server representation.
   * @returns         The {@link CamfilPriceItem}.
   */
  static fromPriceItem(priceItem: PriceItemData): CamfilPriceItem {
    if (priceItem?.gross && priceItem?.net) {
      return {
        type: 'PriceItem',
        gross: priceItem.gross.value,
        net: priceItem.net.value,
        tax: priceItem?.tax?.value,
        currency: priceItem.gross.currency,
      };
    }
  }

  static toPriceItem(price: CamfilPriceItem): PriceItemData {
    const { gross, net, currency } = price;

    if (gross && net) {
      return {
        gross: { value: gross, currency },
        net: { value: gross, currency },
        tax: price?.tax ? { value: price.tax, currency } : undefined,
      };
    }
  }
}
