<ng-container>
  <div class="d-flex align-items-center justify-content-center form-header">
    <mat-icon [svgIcon]="'delivery-time'" />
    <h2 camfilDialogTitle class="mt-3 mb-5">{{ 'camfil.checkout.cta.select_goods_acceptace.header' | translate }}</h2>
    <p class="text-center">
      <span>{{ 'camfil.checkout.cta.select_goods_acceptace.subheader' | translate }}</span>
    </p>
  </div>
  <div class="form-content__container" camfilDialogContent separated>
    <form (ngSubmit)="submitEditGoodsAcceptanceTime()" [formGroup]="goodsAcceptanceTimeForm">
      <mat-form-field appearance="fill" class="d-block">
        <mat-label>{{ 'camfil.checkout.form.goods_acceptance_note' | translate }}</mat-label>
        <input
          aria-controls="my-helper-id"
          formControlName="goodsAcceptanceNote"
          matInput
          type="text"
          [camfilMaxLength]="'goodsAcceptanceNote'"
        />
      </mat-form-field>
    </form>
  </div>
  <div camfilDialogActions>
    <button (click)="closeDialog()" class="btn-tool text-primary" mat-flat-button type="button">
      {{ 'camfil.account.cam_card.move.dialog.btn.cancel' | translate }}
    </button>
    <button
      (click)="submitEditGoodsAcceptanceTime()"
      attr.aria-label="{{ 'camfil.account.cam_card.move.dialog.btn.aria' | translate }}"
      class="btn-tool text-primary"
      data-testing-id="cam-card-dialog-move-submit"
      mat-flat-button
      type="submit"
      type="submit"
    >
      {{ 'camfil.checkout.cta.recipients_save' | translate }}
    </button>
  </div>
</ng-container>
