<ng-template #modal>
  <div class="cta-modal-container">
    <div *ngIf="showCloseIcon" class="clearfix">
      <button
        class="close"
        [title]="'dialog.close.text' | translate"
        [attr.aria-label]="'dialog.close.text' | translate"
        (mousedown)="hide()"
      >
        <mat-icon svgIcon="inactive" aria-hidden="true" />
      </button>
    </div>

    <div class="cta-modal__header text-center">
      <mat-icon *ngIf="hasError" svgIcon="error" />
      <mat-icon *ngIf="!hasError && !hideIcon" svgIcon="{{ icon || 'add-camcard' }}" />
      <h2 class="cta-modal__header--text">{{ actionTitle }}</h2>
      <ng-container *ngIf="pageletIds">
        <ng-container *ngFor="let pageletId of pageletIds">
          <ish-content-include class="cta-modal__pagelet--text" includeId="{{ pageletId }}" />
        </ng-container>
      </ng-container>
    </div>

    <div class="cta-modal__action">
      <ng-container [ngTemplateOutlet]="actionTemplate" />
    </div>

    <div *ngIf="onlyAccept" class="text-center">
      <button class="btn btn-link cta-modal__links--link" (mousedown)="hide()">
        {{ 'camfil.modal.cta.button.ok' | translate }}
      </button>
    </div>

    <div *ngIf="!onlyAccept && actionButtonTitle" class="cta-modal__links">
      <button class="btn btn-link cta-modal__links--link" (mousedown)="hide()">
        {{ cancelButtonTitle ? cancelButtonTitle : ('camfil.modal.cta.button.cancel' | translate) }}
      </button>

      <button class="btn btn-link cta-modal__links--link" type="submit" (click)="emitAction()" cdkFocusInitial>
        {{ actionButtonTitle }}
      </button>
    </div>
  </div>
</ng-template>
