import { ChangeDetectionStrategy, Component, HostBinding } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { CamfilRequisitionContextFacade } from 'camfil-core/facades/cam-requisition-context.facade';

@Component({
  selector: 'camfil-update-delivery-date-dialog',
  templateUrl: './camfil-dialog-update-delivery-date.component.html',
  styleUrls: ['./camfil-dialog-update-delivery-date.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [CamfilRequisitionContextFacade],
})
export class CamfilDialogUpdateDeliveryDateComponent {
  @HostBinding('class.camfil-dialog') camfilDialogClass = true;

  constructor(
    private context: CamfilRequisitionContextFacade,
    public matDialogRef: MatDialogRef<CamfilDialogUpdateDeliveryDateComponent>
  ) {}

  minDeliveryDate = this.context.select('minDeliveryDate');

  approveRequisition() {
    this.context.updateRequisitionDeliveryDate$();
    this.matDialogRef.close();
  }
}
