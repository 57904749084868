import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export type CamfilUnavailableProductsDialogData = { sku: string; availability: boolean }[];

@Component({
  selector: 'camfil-unavailable-products-dialog',
  templateUrl: './camfil-unavailable-products-dialog.component.html',
  styleUrls: ['./camfil-unavailable-products-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CamfilUnavailableProductsDialogComponent {
  constructor(
    public matDialogRef: MatDialogRef<CamfilUnavailableProductsDialogComponent>,
    // eslint-disable-next-line ish-custom-rules/use-type-safe-injection-token
    @Inject(MAT_DIALOG_DATA) public dialogData: CamfilUnavailableProductsDialogData
  ) {}
}
