import { createAction } from '@ngrx/store';
import { CamfilAddress } from 'camfil-models/camfil-address/camfil-address.model';
import { CamfilContact, CamfilCustomer } from 'camfil-models/camfil-customer/camfil-customer.model';

import { httpError, payload } from 'ish-core/utils/ngrx-creators';

export const loadCamfilCustomers = createAction('[Camfil Customer  Internal] Load Camfil Customers');

export const loadCamfilCustomersSuccess = createAction(
  '[Camfil Customers API] Load available customers Success',
  payload<{ customers: CamfilCustomer[] }>()
);

export const loadCamfilCustomersFail = createAction(
  '[Camfil Customers API] Load available customers Fail',
  httpError()
);

export const loadContactsByCustomer = createAction(
  '[Camfil Customers Internal] Load Contacts by customer',
  payload<{ customerId: string }>()
);

export const loadContactsByCustomerSuccess = createAction(
  '[Camfil Customers API] Load Contacts by customer Success',
  payload<{ customerId: string; contacts: CamfilContact[] }>()
);

export const loadContactsByCustomerFail = createAction(
  '[Camfil Customers API] Load Contacts by customer Fail',
  httpError()
);

export const loadUserContactForCustomers = createAction(
  '[Camfil Customers Internal] Load User Contact for all User customers',
  payload<{ customers: CamfilCustomer[] }>()
);

export const loadUserContactForCustomer = createAction(
  '[Camfil Customers Internal] Load User Contact for customer',
  payload<{ customerId: string; userKey?: string }>()
);

export const loadUserContactForCustomerSuccess = createAction(
  '[Camfil Customers API] Load User Contact for customer Success',
  payload<{ customerId: string; contact: CamfilContact }>()
);

export const loadUserContactForCustomerFail = createAction(
  '[Camfil Customers API] Load User Contact for customer Fail',
  httpError()
);

export const loadDeliveryAddresses = createAction(
  '[Camfil Customers Internal] Load available addresses',
  payload<{ customerId: string }>()
);

export const loadDeliveryAddressesIfNotLoaded = createAction(
  '[Camfil Customers Internal] Load available addresses if not loaded',
  payload<{ customerId: string }>()
);

export const loadDeliveryAddressesSuccess = createAction(
  '[Camfil Customers API] Load available addresses Success',
  payload<{ customerId: string; addresses: CamfilAddress[] }>()
);

export const loadDeliveryAddressesFail = createAction(
  '[Camfil Customers API] Load available addresses Fail',
  httpError()
);
