import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { CamfilBasketService } from 'camfil-core/services/camfil-basket/camfil-basket.service';
import {
  continueCamfilCheckout,
  continueCamfilCheckoutFail,
  continueCamfilCheckoutSuccess,
  continueCamfilCheckoutWithIssues,
  startCamfilCheckout,
  startCamfilCheckoutFail,
  startCamfilCheckoutSuccess,
} from 'camfil-core/store/camfil-basket/camfil-basket.actions';
import { createCamfilOrder } from 'camfil-core/store/camfil-orders/camfil-orders.actions';
import { CamfilBasketFeedbackViewModel } from 'camfil-models/camfil-basket-feedback/camfil-basket-feedback.model';
import {
  CamfilBasketValidationResultType,
  CamfilBasketValidationScopeType,
} from 'camfil-models/camfil-basket-validation/camfil-basket-validation.model';
import { intersection } from 'lodash-es';
import { EMPTY, Observable, concatMap, filter, from, map, switchMap, tap } from 'rxjs';

import { CheckoutStepType } from 'ish-core/models/checkout/checkout-step.type';
import { getServerConfigParameter } from 'ish-core/store/core/server-config';
import {
  continueCheckoutSuccess,
  continueCheckoutWithIssues,
  getCurrentBasket,
  getCurrentBasketId,
  loadBasketEligiblePaymentMethods,
  loadBasketEligibleShippingMethods,
  submitBasket,
} from 'ish-core/store/customer/basket';
import { loadProduct } from 'ish-core/store/shopping/products';
import { mapErrorToAction, mapToPayload, mapToPayloadProperty, whenTruthy } from 'ish-core/utils/operators';

@Injectable()
export class CamfilBasketValidationEffects {
  constructor(
    private actions$: Actions,
    private store: Store,
    private router: Router,
    private camfilBasketService: CamfilBasketService
  ) {}

  /**
   * Check the basket before starting the basket acceleration
   */
  startCamfilCheckout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(startCamfilCheckout),
      switchMap(() => this.store.pipe(select(getCurrentBasketId))),
      whenTruthy(),
      concatLatestFrom(() => this.store.pipe(select(getServerConfigParameter<boolean>('basket.acceleration')))),
      filter(([, acc]) => acc),
      concatMap(() =>
        this.camfilBasketService
          .validateCamfilBasket(this.validationSteps[CheckoutStepType.BeforeCheckout].scopes)
          .pipe(
            map(basketValidation => startCamfilCheckoutSuccess({ basketValidation })),
            mapErrorToAction(startCamfilCheckoutFail)
          )
      )
    )
  );

  validateBasketAndContinueCheckout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(continueCamfilCheckout),
      mapToPayloadProperty('targetStep'),
      whenTruthy(),
      concatMap(targetStep => {
        const targetRoute = this.validationSteps[targetStep].route;

        return this.camfilBasketService.validateCamfilBasket(this.validationSteps[targetStep - 1].scopes).pipe(
          concatLatestFrom(() => this.store.pipe(select(getCurrentBasket))),
          concatMap(([basketValidation, basket]) =>
            basketValidation.results.valid
              ? targetStep === 5 && !basketValidation.results.adjusted
                ? basket.approval?.approvalRequired
                  ? [continueCamfilCheckoutSuccess({ targetRoute: undefined, basketValidation }), submitBasket()] // using camfil action
                  : [continueCamfilCheckoutSuccess({ targetRoute: undefined, basketValidation }), createCamfilOrder()]
                : [continueCamfilCheckoutSuccess({ targetRoute, basketValidation })]
              : [continueCamfilCheckoutWithIssues({ targetRoute, basketValidation })]
          ),
          mapErrorToAction(continueCamfilCheckoutFail)
        );
      })
    )
  );

  /**
   * Jumps to the next checkout step after basket validation. In case of adjustments related data like product data, eligible shipping methods etc. are loaded.
   */
  jumpToNextCheckoutStep$ = createEffect(() =>
    this.actions$.pipe(
      ofType(continueCheckoutSuccess, continueCheckoutWithIssues),
      mapToPayload(),
      tap(payload => {
        this.jumpToTargetRoute(payload.targetRoute, payload?.basketValidation.results);
      }),
      filter(payload => payload?.basketValidation.results.adjusted && !!payload.basketValidation.results.infos),
      map(payload => payload.basketValidation),
      concatMap(validation => {
        // Load eligible shipping methods if shipping infos are available
        if (validation.scopes.includes('Shipping')) {
          return [loadBasketEligibleShippingMethods()];
          // Load eligible payment methods if payment infos are available
        } else if (validation.scopes.includes('Payment')) {
          return [loadBasketEligiblePaymentMethods()];
        } else {
          // Load products if product related infos are available
          return validation.results.infos
            .filter(info => info?.parameters.productSku)
            .map(info => loadProduct({ sku: info.parameters.productSku }));
        }
      })
    )
  );

  /**
   * Note: due to fact that Camfil is using `one-step` checkout we need to validate "All" no matter what.
   */
  private validationSteps: {
    [targetStep: string | number]: { scopes: CamfilBasketValidationScopeType[]; route: string };
  } = {
    [CheckoutStepType.BeforeCheckout]: {
      scopes: ['Products', 'Promotion', 'Value', 'CostCenter', 'Camfil', 'CamfilInfo'],
      route: '/checkout',
    },
    [CheckoutStepType.Addresses]: {
      scopes: ['InvoiceAddress', 'ShippingAddress', 'Addresses'],
      route: '/checkout/address',
    },
    [CheckoutStepType.Shipping]: { scopes: ['Shipping'], route: '/checkout/shipping' },
    [CheckoutStepType.Payment]: { scopes: ['Payment'], route: '/checkout/payment' },
    [CheckoutStepType.Review]: {
      scopes: ['All', 'CostCenter', 'Camfil'],
      route: '/checkout/review',
    },
    [CheckoutStepType.Receipt]: { scopes: ['All'], route: 'auto' }, // targetRoute will be calculated in dependence of the validation result
  };

  /**
   * Navigates to the target route, in case targetRoute equals 'auto' the target route will be calculated based on the calculation result
   */
  private jumpToTargetRoute(targetRoute: string, results: CamfilBasketValidationResultType): Observable<boolean> {
    if (!targetRoute || !results) {
      return EMPTY;
    }

    if (targetRoute === 'auto') {
      let scopes = this.extractScopes(results.errors);
      if (!scopes?.length) {
        scopes = this.extractScopes(results.infos);
      }

      const foundKey = Object.keys(this.validationSteps).find(
        key => intersection(this.validationSteps[key].scopes, scopes).length
      );

      const foundStep = this.validationSteps[foundKey];

      if (foundStep) {
        return from(this.router.navigate([foundStep.route], { queryParams: { error: true } }));
      }
      // otherwise stay on the current page
    } else if (results.valid && !results.adjusted) {
      return from(this.router.navigate([targetRoute]));
    }

    return EMPTY;
  }

  private extractScopes(elements: CamfilBasketFeedbackViewModel[]): string[] {
    return elements
      ?.filter(el => !!el.parameters?.scopes?.length)
      .reduce((acc, el) => [...acc, ...el.parameters.scopes], [])
      .filter((val, idx, arr) => !!val && arr.indexOf(val) === idx);
  }
}
