import { ComponentType } from '@angular/cdk/overlay';
import { Injectable } from '@angular/core';
import { MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { CamfilDialogService } from 'camfil-core/services/camfil-dialog/camfil-dialog.service';
import {
  CamfilDialogAlertComponent,
  CamfilDialogAlertData,
  CamfilDialogAlertResult,
} from 'camfil-shared/dialog/camfil-dialog-alert/camfil-dialog-alert.component';
import {
  CamfilDialogConfirmComponent,
  CamfilDialogConfirmData,
  CamfilDialogConfirmResult,
} from 'camfil-shared/dialog/camfil-dialog-confirm/camfil-dialog-confirm.component';
import {
  CamfilDialogFormlyComponent,
  CamfilDialogFormlyData,
  CamfilDialogFormlyResult,
} from 'camfil-shared/dialog/camfil-dialog-formly/camfil-dialog-formly.component';

@Injectable({
  providedIn: 'root',
})
export class CamfilDialogFacade {
  constructor(private camfilDialogService: CamfilDialogService) {}

  open<T, D = unknown, R = unknown>(component: ComponentType<T>, config?: MatDialogConfig<D>): MatDialogRef<T, R> {
    return this.camfilDialogService.open(component, config);
  }

  confirm<D = CamfilDialogConfirmData, R = CamfilDialogConfirmResult>(config?: MatDialogConfig<D>) {
    return this.camfilDialogService.open<CamfilDialogConfirmComponent, D, R>(CamfilDialogConfirmComponent, config);
  }

  alert<D = CamfilDialogAlertData, R = CamfilDialogAlertResult>(config?: MatDialogConfig<D>) {
    return this.camfilDialogService.open<CamfilDialogAlertComponent, D, R>(CamfilDialogAlertComponent, config);
  }

  formly<D = CamfilDialogFormlyData, R = CamfilDialogFormlyResult>(config?: MatDialogConfig<D>) {
    return this.camfilDialogService.open<CamfilDialogFormlyComponent, D, R>(CamfilDialogFormlyComponent, config);
  }

  close(): void {
    this.camfilDialogService.close();
  }

  closeAll(): void {
    this.camfilDialogService.closeAll();
  }

  onOpen(callback: (ref: MatDialogRef<unknown>) => void): void {
    this.camfilDialogService.onOpen(callback);
  }

  onClose(callback: () => void): void {
    this.camfilDialogService.onClose(callback);
  }
}
