import { HttpEvent, HttpHandler, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Observable, of, race, throwError } from 'rxjs';
import { catchError, concatMap, delay, filter, first, map, switchMap, take } from 'rxjs/operators';

import { AccountFacade } from 'ish-core/facades/account.facade';
import { AppFacade } from 'ish-core/facades/app.facade';
import { IdentityProvider, TriggerReturnType } from 'ish-core/identity-provider/identity-provider.interface';
import { selectQueryParam } from 'ish-core/store/core/router';
import { ApiTokenService } from 'ish-core/utils/api-token/api-token.service';
import { whenTruthy } from 'ish-core/utils/operators';

export enum CamfilLoginOnBehalfQueryParams {
  AccessToken = 'access-token',
  ERPEmployeeID = 'ERPEmployeeID',
  ReturnUrl = 'returnUrl',
}

@Injectable({ providedIn: 'root' })
export class CamfilLoginOnBehalfIdentityProvider implements IdentityProvider {
  constructor(
    protected router: Router,
    protected store: Store,
    protected apiTokenService: ApiTokenService,
    private appFacade: AppFacade,
    private accountFacade: AccountFacade
  ) {}

  getCapabilities() {
    return {
      editPassword: true,
      editEmail: true,
      editProfile: true,
    };
  }

  init() {
    this.apiTokenService.getCookieVanishes$().subscribe(type => {
      if (type === 'user') {
        this.accountFacade.logoutUser({ revokeApiToken: false });
      }
    });
  }

  triggerLogin(route: ActivatedRouteSnapshot): TriggerReturnType {
    let accessToken = route.queryParamMap.get(CamfilLoginOnBehalfQueryParams.AccessToken);
    // token is not encoded by ICM URL, so we need to reinsert '+'
    accessToken = decodeURIComponent(accessToken?.replace(/\s/g, '+'));

    let hasAccessToken = route.queryParamMap.has(CamfilLoginOnBehalfQueryParams.AccessToken);
    hasAccessToken = hasAccessToken && accessToken !== 'null';

    if (!hasAccessToken) {
      this.appFacade.setBusinessError('camfil.login_on_behalf.error.missing.parameters');
      return false;
    }

    const erpEmployeeId = decodeURIComponent(route.queryParamMap.get(CamfilLoginOnBehalfQueryParams.ERPEmployeeID));
    let hasErpEmployeeId = route.queryParamMap.has(CamfilLoginOnBehalfQueryParams.ERPEmployeeID);
    hasErpEmployeeId = hasErpEmployeeId && erpEmployeeId !== 'null';

    const returnUrl = route?.queryParamMap?.get(CamfilLoginOnBehalfQueryParams.ReturnUrl) || '/home';

    if (hasErpEmployeeId) {
      window?.localStorage?.setItem(CamfilLoginOnBehalfQueryParams.ERPEmployeeID, erpEmployeeId);
    }

    this.accountFacade.loginUserWithToken(accessToken);

    return race(
      // throw an error if a user login error occurs
      this.accountFacade.userError$.pipe(
        whenTruthy(),
        take(1),
        concatMap(userError => throwError(() => userError))
      ),
      // continue once the user is logged in
      this.accountFacade.isLoggedIn$.pipe(
        whenTruthy(),
        take(1),
        switchMap(() => of(this.router.parseUrl(returnUrl))),
        // error after successful authentication (needs to logout)
        catchError(error =>
          this.accountFacade.userLoading$.pipe(
            first(loading => !loading),
            delay(0),
            switchMap(() => {
              this.accountFacade.logoutUser();
              this.apiTokenService.removeApiToken();
              this.appFacade.setBusinessError(error);
              return of(this.router.parseUrl('/error'));
            })
          )
        )
      )
    ).pipe(
      // general error handling (parameter missing, authentication error)
      catchError(error => {
        this.appFacade.setBusinessError(error);
        return of(this.router.parseUrl('/error'));
      })
    );
  }

  triggerLogout(): TriggerReturnType {
    window?.localStorage?.removeItem(CamfilLoginOnBehalfQueryParams.ERPEmployeeID);
    this.accountFacade.logoutUser(); // user will be logged out and related refresh token is revoked on server
    return this.accountFacade.isLoggedIn$.pipe(
      // wait until the user is logged out before you go to homepage to prevent unnecessary REST calls
      filter(loggedIn => !loggedIn),
      take(1),
      switchMap(() =>
        this.store.pipe(
          select(selectQueryParam('returnUrl')),
          map(returnUrl => returnUrl || '/home'),
          map(returnUrl => this.router.parseUrl(returnUrl))
        )
      )
    );
  }

  triggerRegister(): TriggerReturnType {
    return true;
  }

  triggerInvite(route: ActivatedRouteSnapshot): TriggerReturnType {
    return this.router.createUrlTree(['forgotPassword', 'updatePassword'], {
      queryParams: { uid: route.queryParams.uid, Hash: route.queryParams.Hash },
    });
  }

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return this.apiTokenService.intercept(req, next);
  }
}
