import { createSelector } from '@ngrx/store';

import { getCamfilFeatureState } from '../camfil-store';

import { CamfilUserState, camfilUserFeatureKey } from './camfil-user.reducer';

export const getCamfilUserState = getCamfilFeatureState<CamfilUserState>(camfilUserFeatureKey);

export const getCamfilUserReminderSuccess = createSelector(getCamfilUserState, state => state.usernameReminderSuccess);

export const getCamfilUserReminderError = createSelector(getCamfilUserState, state => state.usernameReminderError);

export const getCamfilUserLoading = createSelector(getCamfilUserState, state => state.loading);

export const getCamfilUserError = createSelector(getCamfilUserState, state => state.error);

export const getCamfilUser = createSelector(getCamfilUserState, state => state.user);
