<div class="info">
  <div class="info-category" *ngIf="categoryName">
    {{ 'camfil.product.items.showing.text' | translate }}
    <span class="info-category-name">{{ categoryName }}</span>
  </div>
  <span *ngIf="itemCount >= 0" class="info-count">
    {{ 'camfil.product.items.label' | translate : { '0': itemCount } }}
  </span>
</div>

<mat-button-toggle-group class="layout" [formControl]="viewTypeControl">
  <mat-button-toggle value="simple">{{ 'camfil.product.list.toolbar.simpleView.text' | translate }}</mat-button-toggle>
  <mat-button-toggle value="detailed">
    {{ 'camfil.product.list.toolbar.detailedView.text' | translate }}
  </mat-button-toggle>
</mat-button-toggle-group>

<!-- TODO: There was information that green dot means that product can be quickly delivered.
      It's the same as in stock? Because dot in product means A1 value on Arrigocode attribute. -->

<mat-form-field class="sort" appearance="outline" *ngIf="sortOptions.length">
  <mat-label>{{ 'camfil.product.list.sorting.label' | translate }}</mat-label>
  <mat-select
    [formControl]="sortDropdown"
    (selectionChange)="changeSortBy($event)"
    id="SortingAttribute"
    name="SortingAttribute"
  >
    <mat-option *ngFor="let o of sortOptions" [value]="o.value">{{ o.label }}</mat-option>
  </mat-select>
</mat-form-field>

<camfil-filter-navigation-badges
  *ngIf="filter$ | async as filterNavigation"
  class="badges"
  [filterNavigation]="filterNavigation"
  [appliedFilters]="appliedFilters"
  (applyFilter)="applyFilter($event)"
  (clearFilters)="clearFilters()"
/>
