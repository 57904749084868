<nav *ngIf="currentPage && lastPage" [attr.aria-label]="'navigation.paging.aria_label' | translate">
  <ul class="pagination">
    <li class="page-item">
      <button
        type="button"
        class="btn btn-primary mb-0"
        [disabled]="currentPage === 1"
        (click)="setPage(currentPage - 1)"
        [title]="'navigation.paging.previous_page.label' | translate"
        [attr.aria-disabled]="currentPage === 1"
        data-testing-id="paging-previous-button"
      >
        <fa-icon [icon]="['fas', 'angle-left']" />
      </button>
    </li>
    <li
      *ngFor="let p of pageIndices"
      class="page-item pt-2"
      [ngClass]="{ active: p === currentPage }"
      data-testing-id="paging-link"
    >
      <a
        *ngIf="p !== -1; else more"
        [attr.aria-current]="p === currentPage ? 'page' : null"
        [title]="
          p === currentPage
            ? ('navigation.paging.current_page.label' | translate : { '0': p })
            : ('navigation.paging.go_to_page.label' | translate : { '0': p })
        "
        tabindex="0"
        (click)="setPage(p)"
        (keyup.enter)="setPage(p)"
        >{{ p }}</a
      >
      <ng-template #more>...</ng-template>
    </li>
    <li class="page-item">
      <button
        type="button"
        class="btn btn-primary m-0"
        [disabled]="currentPage === lastPage"
        [title]="'navigation.paging.next_page.label' | translate"
        (click)="setPage(currentPage + 1)"
        [attr.aria-disabled]="currentPage === lastPage"
        data-testing-id="paging-next-button"
      >
        <fa-icon [icon]="['fas', 'angle-right']" />
      </button>
    </li>
  </ul>
</nav>
