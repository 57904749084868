import { ChangeDetectionStrategy, Component, HostBinding, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CamfilAttributeGroupHelper } from 'camfil-models/camfil-attribute-group/camfil-attribute-group.helper';
import { CamfilAttributeGroupTypes } from 'camfil-models/camfil-attribute-group/camfil-attribute-group.types';
import { CamfilAttributeHelper } from 'camfil-models/camfil-attribute/attribute.helper';
import { Attribute } from 'camfil-models/camfil-attribute/attribute.model';
import { CamfilProductHelper } from 'camfil-models/camfil-product/camfil-product.helper';
import { ProductCompletenessLevel } from 'camfil-models/camfil-product/product.helper';
import { Observable, map } from 'rxjs';

import { ProductContextFacade } from 'ish-core/facades/product-context.facade';
import { AttributeHelper } from 'ish-core/models/attribute/attribute.helper';
import { Image } from 'ish-core/models/image/image.model';
import { ProductView } from 'ish-core/models/product-view/product-view.model';

export type CamfilProductQuickViewDialogData = string;

@Component({
  selector: 'camfil-product-quick-view-modal',
  templateUrl: './camfil-product-quick-view-dialog.component.html',
  styleUrls: ['./camfil-product-quick-view-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{ provide: ProductContextFacade, useClass: ProductContextFacade }],
})
export class CamfilProductQuickViewDialogComponent implements OnInit {
  @HostBinding('class.camfil-dialog') camfilDialogClass = true;

  loading$: Observable<boolean>;
  product$: Observable<ProductView>;
  productName$: Observable<string>;
  productCategoryName$: Observable<string>;
  productImages$: Observable<Image[]>;
  productLongDescription$: Observable<undefined | string>;
  productIsInStock$: Observable<boolean>;
  productAttributeDimensions$: Observable<undefined | { width: Attribute; height: Attribute; depth: Attribute }>;
  productAttributeIsoClassAndEfficiency$: Observable<undefined | Attribute>;
  productAttributeEuroventEnergyClass$: Observable<undefined | Attribute>;
  productAttributeFilterClass$: Observable<undefined | Attribute>;
  productAttributeFrameProduct$: Observable<undefined | Attribute>;
  productAttributePressureDrop$: Observable<undefined | Attribute>;
  productAttributeFilterBags$: Observable<undefined | Attribute>;

  constructor(
    private matDialogRef: MatDialogRef<CamfilProductQuickViewDialogComponent, CamfilProductQuickViewDialogData>,
    // eslint-disable-next-line ish-custom-rules/use-type-safe-injection-token
    @Inject(MAT_DIALOG_DATA) public dialogData: CamfilProductQuickViewDialogData,
    private productContext: ProductContextFacade
  ) {}

  ngOnInit(): void {
    this.productContext.set('sku', () => this.dialogData);
    this.productContext.set('requiredCompletenessLevel', () => ProductCompletenessLevel.Detail);
    this.loading$ = this.productContext.select('loading');
    this.product$ = this.productContext.select('product');
    this.productName$ = this.productContext.select('product', 'name');
    this.productCategoryName$ = this.product$.pipe(map(product => product?.defaultCategory?.name));
    this.productImages$ = this.product$.pipe(map(CamfilProductHelper.getImages));
    this.productLongDescription$ = this.product$.pipe(
      map(product => product.longDescription),
      map(longDescription =>
        typeof longDescription === 'string' && longDescription.trim().length > 0 ? longDescription : undefined
      )
    );
    this.productIsInStock$ = this.product$.pipe(map(CamfilProductHelper.isInStock));
    this.productName$ = this.product$.pipe(map(product => product.name));
    this.productIsInStock$ = this.product$.pipe(map(CamfilProductHelper.isInStock));
    const productAttributes$ = this.product$.pipe(
      map(product =>
        CamfilAttributeGroupHelper.getAttributesGroupByType(
          product,
          CamfilAttributeGroupTypes.ProductsListLabelAttributes
        )
      )
    );
    const selectAttribute = (name: string) =>
      map<Attribute[], undefined | Attribute>(attributes =>
        AttributeHelper.getAttributeByAttributeName(attributes, name)
      );
    this.productAttributeDimensions$ = productAttributes$.pipe(
      map(attributes => {
        const width = AttributeHelper.getAttributeByAttributeName(attributes, 'Width');
        if (width === undefined || CamfilAttributeHelper.isZero(width)) {
          return;
        }
        const height = AttributeHelper.getAttributeByAttributeName(attributes, 'Height');
        if (height === undefined || CamfilAttributeHelper.isZero(height)) {
          return;
        }
        const depth = AttributeHelper.getAttributeByAttributeName(attributes, 'Depth');
        if (depth === undefined || CamfilAttributeHelper.isZero(depth)) {
          return;
        }
        return { width, height, depth };
      })
    );
    this.productAttributeIsoClassAndEfficiency$ = productAttributes$.pipe(selectAttribute('IsoClassAndEfficiency'));
    this.productAttributeEuroventEnergyClass$ = productAttributes$.pipe(
      selectAttribute('EuroventEnergyClass'),
      map(attribute => {
        if (!attribute || attribute.type === 'String' || attribute.value === 'None') {
          return;
        }
        return attribute;
      })
    );
    this.productAttributeFilterClass$ = productAttributes$.pipe(selectAttribute('EN 1822:2009'));
    this.productAttributeFrameProduct$ = productAttributes$.pipe(selectAttribute('FrameProduct'));
    this.productAttributePressureDrop$ = productAttributes$.pipe(selectAttribute('PressureDrop'));
    this.productAttributeFilterBags$ = productAttributes$.pipe(
      selectAttribute('Filterbags'),
      map(attribute => {
        if (attribute === undefined || CamfilAttributeHelper.isZero(attribute)) {
          return;
        }
        return attribute;
      })
    );
  }

  dismiss(): void {
    this.matDialogRef.close();
  }
}
