<ng-container>
  <div class="d-flex align-items-center justify-content-center form-header">
    <mat-icon [svgIcon]="'move'" />
    <h2 camfilDialogTitle class="mt-3 mb-5">{{ 'camfil.account.cam_card.move.dialog.title' | translate }}</h2>
  </div>
  <div class="form-content__container" camfilDialogContent separated>
    <form [formGroup]="moveForm" (ngSubmit)="submitMoveCamCardForm()" class="form-horizontal" novalidate="novalidate">
      <mat-form-field appearance="fill" class="w-100">
        <mat-label>{{ 'camfil.account.cam_card.move.dialog.customers.label' | translate }}</mat-label>
        <mat-select formControlName="customers" (selectionChange)="handleCustomers($event)" [(value)]="selectCustomer">
          <mat-option *ngFor="let customer of customers$ | async" [value]="customer.id">
            <!-- // TODO: create some pipeline to show customer info/name -->
            {{ customer?.companyName || 'camfil.common.label.none' | translate }}, {{ customer?.customerNo }}
            <ng-container *ngIf="customer?.department">, {{ customer.department }}</ng-container>
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="fill" class="w-100 mt-3">
        <mat-label>{{ 'camfil.account.cam_card.move.dialog.contacts.label' | translate }}</mat-label>
        <mat-select
          formControlName="contacts"
          multiple
          [disabled]="!selectCustomer"
          (selectionChange)="handleContacts($event)"
        >
          <mat-option value="all">{{ 'camfil.account.cam_card.move.dialog.all_contacts' | translate }}</mat-option>
          <mat-option *ngFor="let contact of contacts" [value]="contact.profileId" [disabled]="selectAllContacts">
            {{ contact.firstName }} {{ contact.lastName }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </form>
  </div>
  <div camfilDialogActions>
    <button mat-raised-button type="button" class="secondary-button create-order__button" (click)="closeDialog()">
      {{ 'camfil.account.cam_card.move.dialog.btn.cancel' | translate }}
    </button>
    <button
      mat-raised-button
      color="primary"
      type="button"
      class="btn-tool"
      attr.aria-label="{{ 'camfil.account.cam_card.move.dialog.btn.aria' | translate }}"
      (click)="submitMoveCamCardForm()"
    >
      {{ 'camfil.account.cam_card.move.dialog.btn.text' | translate }}
    </button>
  </div>
</ng-container>
