import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { getUserPermissions } from 'ish-core/store/customer/authorization';
import { checkPermission } from 'ish-core/utils/authorization-toggle/authorization-toggle.service';
import { whenTruthy } from 'ish-core/utils/operators';

@Injectable({ providedIn: 'root' })
export class CamfilAuthorizationToggleService {
  private permissions$: Observable<string[]>;

  constructor(store: Store) {
    this.permissions$ = store.pipe(select(getUserPermissions));
  }

  /**
   *
   * @param permissions
   * @returns true, if all the provided permissions are matching
   */
  isAuthorizedToCheckArrAll(permissions: string[]): Observable<boolean> {
    // special case shortcut
    if (permissions.includes('always') || permissions.includes('never')) {
      return of(
        checkPermission(
          [],
          permissions.find(p => p === 'always' || p === 'never')
        )
      );
    }
    return this.permissions$.pipe(
      // wait for permissions to be loaded
      whenTruthy(),
      map(allPermissions => permissions.filter(p => checkPermission(allPermissions, p)).length === permissions.length)
    );
  }
  /**
   *
   * @param permissions
   * @returns true, if any of the provided permissions are matching
   */
  isAuthorizedToCheckArrAny(permissions: string[]): Observable<boolean> {
    // special case shortcut
    if (permissions.includes('always') || permissions.includes('never')) {
      return of(
        checkPermission(
          [],
          permissions.find(p => p === 'always' || p === 'never')
        )
      );
    }
    return this.permissions$.pipe(
      // wait for permissions to be loaded
      whenTruthy(),
      map(allPermissions => permissions.filter(p => checkPermission(allPermissions, p)).length >= 1)
    );
  }
}
