<mat-card class="mat-elevation-z4 summary-container sticky-container">
  <camfil-content-by-role class="px-3">
    <h2 class="role-default summary-title">{{ 'camfil.checkout.total_order_summary' | translate }}</h2>
    <h2 class="role-quote summary-title">{{ 'camfil.checkout.total_order_summary_quote' | translate }}</h2>
    <h2 class="role-requisition summary-title">{{ 'camfil.checkout.total_order_summary_requisition' | translate }}</h2>
  </camfil-content-by-role>

  <mat-card-content>
    <mat-divider />

    <!-- SubTotal -->
    <ng-container *camfilChannelToggle="'showSubTotalInBasketSummary'">
      <ng-container *ngIf="totals?.itemTotal">
        <div class="summary-line">
          <p>
            {{ 'checkout.cart.subtotal.heading' | translate }}
          </p>
          <p>{{ totals?.itemTotal | ishPrice }}</p>
        </div>
      </ng-container>
    </ng-container>

    <!-- Volume Discount -->
    <!-- TODO (extMlk): Current implementation does not make sense since volumeDiscount is not applied in BE when calculating totals -->
    <ng-container *ngIf="bucketsVolumeDiscounts$ | async as bucketsVolumeDiscounts">
      <div *ngIf="bucketsVolumeDiscounts.value" class="summary-line">
        <p>{{ 'camfil.checkout.volume_discount' | translate }}</p>
        <p>{{ bucketsVolumeDiscounts | ishPrice }}</p>
      </div>
    </ng-container>

    <!-- list all applied order level promotions -->
    <ng-container *ngIf="totals?.valueRebates?.length > 0">
      <ng-container *ngFor="let rebate of totals.valueRebates">
        <div class="summary-line">
          <p *ngIf="rebate.promotionId">
            <ish-basket-promotion [rebate]="rebate" />
          </p>
          <p>{{ invert(rebate.amount) | ishPrice }}</p>
        </div>
      </ng-container>
    </ng-container>

    <!-- Shipping -->
    <ng-container *ngIf="!checkIfZeroPrice(totals?.undiscountedShippingTotal)">
      <div class="summary-line">
        <p *ngIf="totals?.isEstimated">{{ 'checkout.cart.estimated_shipping_handling.label' | translate }}</p>
        <p *ngIf="!totals?.isEstimated">{{ 'checkout.order.shipping.label' | translate }}</p>
        <p>
          {{ totals.undiscountedShippingTotal | ishPrice }}
        </p>
      </div>
    </ng-container>

    <!-- Shipping promotions -->
    <ng-container *ngIf="totals?.shippingRebates?.length > 0">
      <ng-container *ngFor="let rebate of totals.shippingRebates">
        <div class="summary-line">
          <div>
            <ish-basket-promotion [rebate]="rebate" />
          </div>
          <p>
            {{ invert(rebate.amount) | ishPrice }}
          </p>
        </div>
      </ng-container>
    </ng-container>

    <ng-container *camfilChannelToggle="'showDutiesAndSurchargesTotalInBasketSummary'">
      <!-- Item Surcharges -->
      <ng-container *ngFor="let surcharge of totals.itemSurchargeTotalsByType">
        <ng-container *ngTemplateOutlet="surchargeItemTemplate; context: { surcharge: surcharge }" />
      </ng-container>
      <!-- Bucket Surcharges -->
      <ng-container *ngFor="let surcharge of totals.bucketSurchargeTotalsByType">
        <ng-container *ngTemplateOutlet="surchargeItemTemplate; context: { surcharge: surcharge }" />
      </ng-container>
    </ng-container>

    <!-- Payment costs -->
    <ng-container *ngIf="hasPaymentCostsTotal">
      <p>{{ 'checkout.cart.payment_cost.label' | translate }}</p>
      <p>{{ totals.paymentCostsTotal | ishPrice }}</p>
    </ng-container>

    <!-- Discount total -->
    <ng-container *ngIf="totals.discountTotal">
      <div class="summary-line">
        <p>
          {{ 'checkout.widget.promotion.discount' | translate }}
        </p>
        <p>
          {{ totals.discountTotal | ishPrice }}
        </p>
      </div>
    </ng-container>

    <ng-container *camfilChannelToggle="'showTotalWithoutTaxInBasketSummary'">
      <ng-container *ngIf="totals?.total">
        <div class="summary-line font-weight-bold">
          <span *ngIf="totals.isEstimated">{{ 'checkout.cart.estimated_total_net.label' | translate }}</span>
          <span *ngIf="!totals.isEstimated">{{ 'checkout.order.total_cost_net.label' | translate }}</span>
          <p>{{ totals?.total | ishPrice : 'net' }}</p>
        </div>
      </ng-container>
    </ng-container>

    <!-- Tax -->
    <ng-container *ngIf="totals?.taxTotal && totals?.taxTotal.value">
      <div class="summary-line">
        <p>{{ taxTranslation$ | async | translate }}</p>
        <p>{{ totals?.taxTotal | ishPrice }}</p>
      </div>
    </ng-container>

    <!-- Cart total -->
    <ng-container *ngIf="totals?.total">
      <div class="summary-line font-weight-bold lead">
        <span *ngIf="totals.isEstimated">{{ 'checkout.cart.estimated_total.label' | translate }}</span>
        <span *ngIf="!totals.isEstimated">{{ 'checkout.order.total_cost.label' | translate }}</span>
        <p>{{ totals?.total | ishPrice : 'gross' }}</p>
      </div>
    </ng-container>

    <mat-divider />

    <ng-template #notConfirmed>
      <ng-container *ngIf="(isLoggedIn$ | async) === false">
        <form [formGroup]="guestGdprForm" class="summary-gdpr-form">
          <mat-checkbox color="primary" formControlName="gdprAcceptance">
            <span>
              {{ 'camfil.account.apply_form.gdpr' | translate }}
              <a href="{{ 'camfil.account.apply_form.gdpr.link' | translate }}">
                {{ 'camfil.account.apply_form.gdpr.link' | translate }}
              </a>
            </span>
          </mat-checkbox>
        </form>
      </ng-container>

      <ng-container *ishIsAuthorizedTo="'APP_B2B_MAKE_QUOTATION'">
        <ng-container *ngTemplateOutlet="requestQuotationBtn" />
      </ng-container>

      <ng-container *ishIsAuthorizedTo="'APP_B2B_MAKE_REQUISITION'">
        <camfil-requisition-checkout-button [disabledButton$]="canSubmit$" />
      </ng-container>

      <ng-container *camfilIsNotAuthorizedTo="'APP_B2B_MAKE_REQUISITION'">
        <ng-container *ishHasNotRole="['APP_B2B_REQUEST_QUOTATION']">
          <ng-container *ngTemplateOutlet="submitOrderBtn" />
        </ng-container>
      </ng-container>

      <ng-template #requestQuotationBtn>
        <ng-container *camfilChannelToggle="'allowQuotes'">
          <button
            *ngIf="canSubmit$ | async"
            (click)="submitOrder('RFQ')"
            class="request-quotation-button mt-2"
            color="primary"
            mat-stroked-button
          >
            {{ 'camfil.checkout.request_quotation' | translate }}
          </button>
        </ng-container>
      </ng-template>

      <ng-template #submitOrderBtn>
        <button
          *ishHasNotRole="['APP_B2B_CXML_USER', 'APP_B2B_OCI_USER']"
          (click)="submitOrder()"
          [disabled]="(canSubmit$ | async) === false"
          class="submit-button"
          color="primary"
          mat-flat-button
        >
          <mat-icon class="color-light" svgIcon="spinner" *ngIf="isSubmitting$ | async" />
          <ng-template #readyToOrder>
            {{ 'camfil.checkout.place_your_orders' | translate }}
          </ng-template>
          <ng-template #notReadyToOrder>
            {{ 'camfil.checkout.place_your_orders' | translate }}
          </ng-template>
          <ng-container *ngIf="canSubmit$ | async; else notReadyToOrder">
            <ng-container *ngTemplateOutlet="readyToOrder" />
          </ng-container>
        </button>
        <ish-lazy-punchout-transfer-basket *ishIsAuthorizedTo="'APP_B2B_SEND_PUNCHOUT_BASKET'" />
      </ng-template>
    </ng-template>

    <ng-container *ngIf="!isEditable; else notConfirmed">
      <button (click)="continueShopping()" color="primary" mat-flat-button>
        {{ 'camfil.checkout.confirmation.continue_shopping' | translate }}
      </button>
    </ng-container>

    <mat-divider />

    <ish-content-include includeId="camfil.include.checkout.content.pagelet2-Include" />
  </mat-card-content>
</mat-card>

<!-- Surcharge outlet template -->

<ng-template #surchargeItemTemplate let-surcharge="surcharge">
  <div class="summary-line">
    <p [class.font-weight-bold]="surcharge?.bold">
      {{ surcharge.displayName | slugify : 'camfil.dynamic' | translate }}
      <mat-icon *ngIf="surcharge.description" [matTooltip]="surcharge.description" svgIcon="question-mark" />
    </p>
    <p [class.text-line-through]="surcharge?.strikethrough" [class.font-weight-bold]="surcharge?.bold">
      {{ surcharge.amount | ishPrice }}
    </p>
  </div>
</ng-template>
