<dl *ngIf="attribute && value" [ngClass]="classObject">
  <ng-container *ngIf="!hideAttributeName">
    <dt class="camfil-product-attribute__label">{{ name }}:</dt>
  </ng-container>
  <dd
    [attr.itemprop]="itemProp"
    [innerHTML]="attribute | ishAttribute : multipleValuesSeparator"
    class="camfil-product-attribute__value"
    [ngClass]="{ 'overflow-visible': overflowVisible }"
    [title]="attribute | ishAttribute : multipleValuesSeparator"
  ></dd>
</dl>
