<ng-container>
  <div class="d-flex align-items-center justify-content-center form-header">
    <mat-icon [svgIcon]="'error'" />
    <h2 camfilDialogTitle>
      {{ 'camfil.freight_cost_invalid.heading.text' | translate }}
    </h2>
  </div>
  <div class="form-content__container" camfilDialogContent separated>
    {{ 'camfil.freight_cost_invalid.body.text' | translate }}
  </div>
  <div camfilDialogActions>
    <button mat-raised-button type="button" (click)="close()">
      {{ 'camfil.modal.cta.button.ok' | translate }}
    </button>
  </div>
</ng-container>
