import { Injectable } from '@angular/core';

import { CamfilCustomerData, CamfilCustomersData } from './camfil-customer.interface';
import { CamfilCustomer } from './camfil-customer.model';

@Injectable({ providedIn: 'root' })
export class CamfilCustomerMapper {
  static fromData(camfilCustomerData: CamfilCustomerData): CamfilCustomer {
    if (camfilCustomerData?.id) {
      return {
        ...camfilCustomerData,
        fullCustomerName: CamfilCustomerMapper.getFullCustomerName(camfilCustomerData),
      };
    } else {
      throw new Error(`camfilCustomerData is required`);
    }
  }

  static getFullCustomerName(camfilCustomer: CamfilCustomer): string {
    return [camfilCustomer?.companyName, camfilCustomer?.customerNo, camfilCustomer?.department]
      .filter(Boolean)
      .join(', ');
  }

  static fromListData(payload: CamfilCustomersData): CamfilCustomer[] {
    if (Array.isArray(payload.elements)) {
      return payload.elements.map(data => ({
        ...CamfilCustomerMapper.fromData(data),
      }));
    }
  }
}
