<div ngbDropdown autoClose="outside">
  <button
    ngbDropdownToggle
    class="form-control"
    role="button"
    id="dropdownMenuLink"
    data-toggle="dropdown"
    aria-haspopup="true"
    aria-expanded="false"
    [attr.title]="placeholder !== filterElement.name ? placeholder : null"
  >
    <span>{{ placeholder }}</span>
  </button>
  <div ngbDropdownMenu aria-labelledby="dropdownMenuLink">
    <button
      *ngFor="let facet of filterElement.facets; trackBy: trackByFn"
      type="button"
      class="dropdown-item btn btn-link btn-link-action"
      [ngClass]="{ selected: facet.selected }"
      (click)="apply(facet)"
    >
      {{ facet.displayName }}
      <ng-container *ngIf="facet.count"> ({{ facet.count }})</ng-container>
      <fa-icon *ngIf="facet.selected" [icon]="['fas', 'check']" class="icon-checked" />
    </button>
  </div>
</div>
