import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { CamfilConfigurationFacade } from 'camfil-core/facades/camfil-configuration.facade';
import { Observable } from 'rxjs';

import { AccountFacade } from 'ish-core/facades/account.facade';
import { User } from 'ish-core/models/user/user.model';

@Component({
  selector: 'camfil-login-status',
  templateUrl: './camfil-login-status.component.html',
  styleUrls: ['./camfil-login-status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CamfilLoginStatusComponent implements OnInit {
  @Input() logoutOnly = false;

  user$: Observable<User>;
  returnUrl$: Observable<string>;

  constructor(private accountFacade: AccountFacade, private camfilConfigurationFacade: CamfilConfigurationFacade) {}

  ngOnInit(): void {
    this.user$ = this.accountFacade.user$;
    this.returnUrl$ = this.camfilConfigurationFacade.continueShoppingUrl$;
  }
}
