/* eslint-disable no-restricted-imports */
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { CamfilDirectivesModule } from 'camfil-core/camfil-directives.module';
import { CamfilPipesModule } from 'camfil-core/camfil-pipes.module';
import { CamfilMaterialModule } from 'camfil-core/material/camfil-material.module';
import { CamfilEditQuoteDialogComponent } from 'camfil-pages/camfil-account-quotes/camfil-edit-quote-dialog/camfil-edit-quote-dialog.component';
import { CamfilEditQuoteComponent } from 'camfil-pages/camfil-account-quotes/camfil-edit-quote/camfil-edit-quote.component';
import { CamfilPrintQuoteComponent } from 'camfil-pages/camfil-account-quotes/camfil-print-quote/camfil-print-quote.component';
import { CamfilShoppingBucketEmptyComponent } from 'camfil-pages/camfil-checkout-onestep/camfil-shopping-bucket-empty/camfil-shopping-bucket-empty.component';
import { CamCardCreateDialogComponent } from 'camfil-shared/cam-cards/components/cam-card-create-dialog/cam-card-create-dialog.component';
import { CamCardDeliveryIntervalComponent } from 'camfil-shared/cam-cards/components/cam-card-delivery-interval/cam-card-delivery-interval.component';
import { CamCardEditModeComponent } from 'camfil-shared/cam-cards/components/cam-card-edit-mode/cam-card-edit-mode.component';
import { CamCardLastDeliveryDateComponent } from 'camfil-shared/cam-cards/components/cam-card-last-delivery-date/cam-card-last-delivery-date.component';
import { AccountCamCardDetailLineItemComponent } from 'camfil-shared/cam-cards/components/camfil-account-cam-card-detail-line-item/camfil-account-cam-card-detail-line-item.component';
import { CamfilCamCardGoodsAcceptanceModalComponent } from 'camfil-shared/cam-cards/components/camfil-cam-card-goods-acceptance-modal/camfil-cam-card-goods-acceptance-modal.component';
import { CamCardPreferencesComponent } from 'camfil-shared/cam-cards/components/camfil-cam-card-preferences/camfil-cam-card-preferences.component';
import { CamfilMoveCamCardDialogComponent } from 'camfil-shared/cam-cards/components/camfil-move-cam-card-dialog/camfil-move-cam-card-dialog.component';
import { CamfilUserAccessCamCardDialogComponent } from 'camfil-shared/cam-cards/components/camfil-user-access-cam-card-dialog/camfil-user-access-cam-card-dialog.component';
import { CamfilCMSBigBannerWithButtonComponent } from 'camfil-shared/cms/camfil-cms-big-banner-with-button/camfil-cms-big-banner-with-button.component';
import { CamfilCmsCookieDisclaimerComponent } from 'camfil-shared/cms/camfil-cms-cookie-disclaimer/camfil-cms-cookie-disclaimer.component';
import { CamfilCMSFAQComponent } from 'camfil-shared/cms/camfil-cms-faq/camfil-cms-faq.component';
import { CamfilCMSImageTextButtonComponent } from 'camfil-shared/cms/camfil-cms-image-text-button/camfil-cms-image-text-button.component';
import { CamfilCmsLightboxArticleComponent } from 'camfil-shared/cms/camfil-cms-lightbox-article/camfil-cms-lightbox-article.component';
import { CamfilCmsLightboxLinkComponent } from 'camfil-shared/cms/camfil-cms-lightbox-link/camfil-cms-lightbox-link.component';
import { CamfilContentDesignViewWrapperComponent } from 'camfil-shared/cms/components/camfil-content-design-view-wrapper/camfil-content-design-view-wrapper.component';
import { CamfilContentNavigationComponent } from 'camfil-shared/cms/components/camfil-content-navigation/camfil-content-navigation.component';
import { CamfilContentPageletComponent } from 'camfil-shared/cms/components/camfil-content-pagelet/camfil-content-pagelet.component';
import { CamfilContentSlotComponent } from 'camfil-shared/cms/components/camfil-content-slot/camfil-content-slot.component';
import { CamfilContentViewcontextComponent } from 'camfil-shared/cms/components/camfil-content-viewcontext/camfil-content-viewcontext.component';
import { CamfilMyPageHeaderComponent } from 'camfil-shared/components/account/camfil-my-page-header/camfil-my-page-header.component';
import { CamfilAddressComponent } from 'camfil-shared/components/address/camfil-address/camfil-address.component';
import { CamfilAddEmailRecipientFormComponent } from 'camfil-shared/components/basket/camfil-add-email-recipient-form/camfil-add-email-recipient-form.component';
import { CamfilBasketCostSummaryComponent } from 'camfil-shared/components/basket/camfil-basket-cost-summary/camfil-basket-cost-summary.component';
import { CamfilBasketValidationDeliveryDateDialogComponent } from 'camfil-shared/components/basket/camfil-basket-validation-delivery-date-dialog/camfil-basket-validation-delivery-date-dialog.component';
import { CamfilBasketValidationResultsComponent } from 'camfil-shared/components/basket/camfil-basket-validation-results/camfil-basket-validation-results.component';
import { CamfilBucketValidationResultsComponent } from 'camfil-shared/components/basket/camfil-bucket-validation-results/camfil-bucket-validation-results.component';
import { CamfilBucketsValidationSummaryComponent } from 'camfil-shared/components/basket/camfil-buckets-validation-summary/camfil-buckets-validation-summary.component';
import { CamfilCheckoutBucketSummaryComponent } from 'camfil-shared/components/basket/camfil-checkout-bucket-summary/camfil-checkout-bucket-summary.component';
import { CamfilBulkDeliveryDateConfirmModalComponent } from 'camfil-shared/components/basket/camfil-checkout-bucket/camfil-bulk-delivery-date/camfil-bulk-delivery-date-confirm-modal/camfil-bulk-delivery-date-confirm-modal.component';
import { CamfilDeleteOrderComponent } from 'camfil-shared/components/basket/camfil-checkout-bucket/camfil-delete-order/camfil-delete-order.component';
import { CamfilDialogDeleteOrderComponent } from 'camfil-shared/components/basket/camfil-checkout-bucket/camfil-delete-order/camfil-dialog-delete-order/camfil-dialog-delete-order.component';
import { CamfilCheckoutGoodsAcceptanceModalComponent } from 'camfil-shared/components/basket/camfil-checkout-goods-acceptance-modal/camfil-checkout-goods-acceptance-modal.component';
import { CamfilCheckoutSummaryComponent } from 'camfil-shared/components/basket/camfil-checkout-summary/camfil-checkout-summary.component';
import { CamfilFreightcostModalComponent } from 'camfil-shared/components/basket/camfil-freightcost-modal/camfil-freightcost-modal.component';
import { CamfilOrderFormDialogComponent } from 'camfil-shared/components/basket/camfil-order-form-dialog/camfil-order-form-dialog.component';
import { CamfilCityFieldComponent } from 'camfil-shared/components/common/camfil-city-field/camfil-city-field.component';
import { CamfilContentByRoleComponent } from 'camfil-shared/components/common/camfil-content-by-role/camfil-content-by-role.component';
import { CamfilCounterComponent } from 'camfil-shared/components/common/camfil-counter/camfil-counter.component';
import { CamfilDialogAddNewProductComponent } from 'camfil-shared/components/common/camfil-dialog-add-new-product/camfil-dialog-add-new-product.component';
import { CamfilErrorMessageComponent } from 'camfil-shared/components/common/camfil-error-message/camfil-error-message.component';
import { CamfilErrorComponent } from 'camfil-shared/components/common/camfil-error/camfil-error.component';
import { CamfilLoadingComponent } from 'camfil-shared/components/common/camfil-loading/camfil-loading.component';
import { CamfilSearchInputComponent } from 'camfil-shared/components/common/camfil-search-input/camfil-search-input.component';
import { CamfilSmallCtaModalComponent } from 'camfil-shared/components/common/camfil-small-cta-modal/camfil-small-cta-modal.component';
import { CamfilTranslatableContentComponent } from 'camfil-shared/components/common/camfil-translatable-content/camfil-translatable-content.component';
import { CamfilProductAddToCompareComponent } from 'camfil-shared/components/compare/camfil-product-add-to-compare/camfil-product-add-to-compare.component';
import { CamfilFilterCollapsableComponent } from 'camfil-shared/components/filter/camfil-filter-collapsable/camfil-filter-collapsable.component';
import { CamfilFilterDropdownComponent } from 'camfil-shared/components/filter/camfil-filter-dropdown/camfil-filter-dropdown.component';
import { CamfilFilterMeasurementsComponent } from 'camfil-shared/components/filter/camfil-filter-measurements/camfil-filter-measurements.component';
import { CamfilFilterNavigationBadgesComponent } from 'camfil-shared/components/filter/camfil-filter-navigation-badges/camfil-filter-navigation-badges.component';
import { CamfilFilterNavigationSidebarComponent } from 'camfil-shared/components/filter/camfil-filter-navigation-sidebar/camfil-filter-navigation-sidebar.component';
import { CamfilFilterNavigationComponent } from 'camfil-shared/components/filter/camfil-filter-navigation/camfil-filter-navigation.component';
import { CamfilFilterTextComponent } from 'camfil-shared/components/filter/camfil-filter-text/camfil-filter-text.component';
import { CamfilReorderItemsErrorDialogComponent } from 'camfil-shared/components/orders/camfil-reorder-items-error-dialog/camfil-reorder-items-error-dialog.component';
import { CamfilDialogUpdateDeliveryDateComponent } from 'camfil-shared/components/requisitions/camfil-dialog-update-delivery-date/camfil-dialog-update-delivery-date.component';
import { CamfilRequisitionCheckoutButtonComponent } from 'camfil-shared/components/requisitions/camfil-requisition-checkout-button/camfil-requisition-checkout-button.component';
import { CamfilRequisitionLineItemBoxLabelComponent } from 'camfil-shared/components/requisitions/camfil-requisition-line-item-box-label/camfil-requisition-line-item-box-label.component';
import { CamfilRequisitionLineItemQuantityComponent } from 'camfil-shared/components/requisitions/camfil-requisition-line-item-quantity/camfil-requisition-line-item-quantity.component';
import { CamfilRequisitionRejectFormComponent } from 'camfil-shared/components/requisitions/camfil-requisition-reject-form/camfil-requisition-reject-form.component';
import { CamfilUnavailableProductsDialogComponent } from 'camfil-shared/components/requisitions/camfil-unavailable-products-dialog/camfil-unavailable-products-dialog.component';
import { CamfilHeaderBoxComponent } from 'camfil-shared/components/user/camfil-header-box/camfil-header-box.component';
import { CamfilDialogModule } from 'camfil-shared/dialog/camfil-dialog.module';
import { CamfilFormlyModule } from 'camfil-shared/formly/camfil-formly.module';
import { CamCardProductCommentComponent } from 'camfil-shared/product/cam-card-product-comment/cam-card-product-comment.component';
import { CamfilProductAttributeComponent } from 'camfil-shared/product/camfil-product-attribute/camfil-product-attribute.component';
import { CamfilProductDocumentsComponent } from 'camfil-shared/product/camfil-product-documents/camfil-product-documents.component';
import { CamfilProductFormComponent } from 'camfil-shared/product/camfil-product-form/camfil-product-form.component';
import { CamfilProductGalleryComponent } from 'camfil-shared/product/camfil-product-gallery/camfil-product-gallery.component';
import { CamfilProductImageComponent } from 'camfil-shared/product/camfil-product-image/camfil-product-image.component';
import { CamfilProductItemComponent } from 'camfil-shared/product/camfil-product-item/camfil-product-item.component';
import { CamfilProductListToolbarComponent } from 'camfil-shared/product/camfil-product-list-toolbar/camfil-product-list-toolbar.component';
import { CamfilProductListComponent } from 'camfil-shared/product/camfil-product-list/camfil-product-list.component';
import { CamfilProductListingComponent } from 'camfil-shared/product/camfil-product-listing/camfil-product-listing.component';
import { CamfilProductPriceComponent } from 'camfil-shared/product/camfil-product-price/camfil-product-price.component';
import { CamfilProductQuantityComponent } from 'camfil-shared/product/camfil-product-quantity/camfil-product-quantity.component';
import { CamfilProductQuickViewDialogComponent } from 'camfil-shared/product/camfil-product-quick-view-dialog/camfil-product-quick-view-dialog.component';
import { CamfilProductTitleComponent } from 'camfil-shared/product/camfil-product-title/camfil-product-title.component';
import SwiperCore, { Thumbs } from 'swiper';

import { FormsSharedModule } from 'ish-shared/forms/forms.module';
import { SharedModule as IshSharedModule } from 'ish-shared/shared.module';

import { CamfilSearchComponent } from '../components/camfil-search/camfil-search.component';
import { CamfilPwaExportsModule } from '../exports/camfil-pwa-exports.module';

import { CamCardDeliveryIntervalCheckingDialogComponent } from './cam-cards/components/cam-card-delivery-interval-checking-dialog/cam-card-delivery-interval-checking-dialog.component';
import { AccountCamCardScrollListComponent } from './cam-cards/components/camfil-account-cam-card-scroll-list/camfil-account-cam-card-scroll-list.component';
import { CamfilBulkDeliveryDateModalComponent } from './components/basket/camfil-checkout-bucket/camfil-bulk-delivery-date/camfil-bulk-delivery-date-modal/camfil-bulk-delivery-date-modal.component';
import { CamfilBulkDeliveryDateComponent } from './components/basket/camfil-checkout-bucket/camfil-bulk-delivery-date/camfil-bulk-delivery-date.component';
import { CamfilCategoryBoxComponent } from './components/category/camfil-category-box/camfil-category-box.component';
import { CamfilProductCounterComponent } from './product/camfil-product-counter/camfil-product-counter.component';
import { CamfilProductFormDialogCamCardSuccessComponent } from './product/camfil-product-form-dialog-cam-card-success/camfil-product-form-dialog-cam-card-success.component';
import { CamfilProductFormDialogCamCardComponent } from './product/camfil-product-form-dialog-cam-card/camfil-product-form-dialog-cam-card.component';
import { CamfilProductFormDialogProductComponent } from './product/camfil-product-form-dialog-product/camfil-product-form-dialog-product.component';
import { CamfilProductFormDialogComponent } from './product/camfil-product-form-dialog/camfil-product-form-dialog.component';
import { CamfilProductLeadTimeComponent } from './product/camfil-product-lead-time/camfil-product-lead-time.component';
import { CamfilProductLinksListComponent } from './product/camfil-product-links-list/camfil-product-links-list.component';
import { CamfilProductLinksComponent } from './product/camfil-product-links/camfil-product-links.component';
import { CamfilProductStockComponent } from './product/camfil-product-stock/camfil-product-stock.component';

const importExportModules = [
  CamfilDialogModule,
  CamfilDirectivesModule,
  CamfilFormlyModule,
  CamfilMaterialModule,
  CamfilPipesModule,
  CamfilPwaExportsModule,
  CommonModule,
  FormsModule,
  FormsSharedModule,
  HttpClientModule,
  NgOptimizedImage,
  RouterModule,
  TranslateModule,
  IshSharedModule,
];

const standaloneComponents = [
  CamfilTranslatableContentComponent,
  CamfilLoadingComponent,
  CamfilContentByRoleComponent,
  CamfilSearchInputComponent,
];

const exportedComponents = [
  CamfilCategoryBoxComponent,
  CamfilSearchComponent,
  CamfilBulkDeliveryDateConfirmModalComponent,
  AccountCamCardDetailLineItemComponent,
  AccountCamCardScrollListComponent,
  CamCardCreateDialogComponent,
  CamCardDeliveryIntervalCheckingDialogComponent,
  CamCardDeliveryIntervalComponent,
  CamCardEditModeComponent,
  CamCardLastDeliveryDateComponent,
  CamCardPreferencesComponent,
  CamCardProductCommentComponent,
  CamfilAddEmailRecipientFormComponent,
  CamfilAddressComponent,
  CamfilBasketCostSummaryComponent,
  CamfilBasketValidationDeliveryDateDialogComponent,
  CamfilBasketValidationResultsComponent,
  CamfilBucketValidationResultsComponent,
  CamfilBucketsValidationSummaryComponent,
  CamfilBulkDeliveryDateComponent,
  CamfilBulkDeliveryDateConfirmModalComponent,
  CamfilBulkDeliveryDateModalComponent,
  CamfilCMSBigBannerWithButtonComponent,
  CamfilCMSFAQComponent,
  CamfilCMSImageTextButtonComponent,
  CamfilCamCardGoodsAcceptanceModalComponent,
  CamfilCheckoutBucketSummaryComponent,
  CamfilCheckoutGoodsAcceptanceModalComponent,
  CamfilCheckoutSummaryComponent,
  CamfilCityFieldComponent,
  CamfilCmsCookieDisclaimerComponent,
  CamfilCmsLightboxArticleComponent,
  CamfilCmsLightboxLinkComponent,
  CamfilContentDesignViewWrapperComponent,
  CamfilContentNavigationComponent,
  CamfilContentPageletComponent,
  CamfilContentSlotComponent,
  CamfilContentViewcontextComponent,
  CamfilCounterComponent,
  CamfilDeleteOrderComponent,
  CamfilDialogDeleteOrderComponent,
  CamfilEditQuoteComponent,
  CamfilEditQuoteDialogComponent,
  CamfilErrorComponent,
  CamfilErrorMessageComponent,
  CamfilFilterCollapsableComponent,
  CamfilFilterDropdownComponent,
  CamfilFilterMeasurementsComponent,
  CamfilFilterNavigationBadgesComponent,
  CamfilFilterNavigationComponent,
  CamfilFilterNavigationSidebarComponent,
  CamfilFilterTextComponent,
  CamfilFreightcostModalComponent,
  CamfilHeaderBoxComponent,
  CamfilDialogAddNewProductComponent,
  CamfilMoveCamCardDialogComponent,
  CamfilMyPageHeaderComponent,
  CamfilOrderFormDialogComponent,
  CamfilPrintQuoteComponent,
  CamfilProductAddToCompareComponent,
  CamfilProductAttributeComponent,
  CamfilProductCounterComponent,
  CamfilProductDocumentsComponent,
  CamfilProductFormComponent,
  CamfilProductFormDialogCamCardComponent,
  CamfilProductFormDialogCamCardSuccessComponent,
  CamfilProductFormDialogComponent,
  CamfilProductFormDialogProductComponent,
  CamfilProductGalleryComponent,
  CamfilProductImageComponent,
  CamfilProductItemComponent,
  CamfilProductLeadTimeComponent,
  CamfilProductLinksComponent,
  CamfilProductLinksListComponent,
  CamfilProductListComponent,
  CamfilProductListToolbarComponent,
  CamfilProductListingComponent,
  CamfilProductPriceComponent,
  CamfilProductQuantityComponent,
  CamfilProductQuickViewDialogComponent,
  CamfilProductStockComponent,
  CamfilProductTitleComponent,
  CamfilReorderItemsErrorDialogComponent,
  CamfilRequisitionCheckoutButtonComponent,
  CamfilRequisitionLineItemBoxLabelComponent,
  CamfilRequisitionLineItemQuantityComponent,
  CamfilRequisitionRejectFormComponent,
  CamfilShoppingBucketEmptyComponent,
  CamfilSmallCtaModalComponent,
  CamfilUnavailableProductsDialogComponent,
  CamfilDialogUpdateDeliveryDateComponent,
  CamfilUserAccessCamCardDialogComponent,
];

SwiperCore.use([Thumbs]);

@NgModule({
  /* eslint-disable @angular-eslint/sort-ngmodule-metadata-arrays */
  imports: [...importExportModules, ...standaloneComponents],
  /* eslint-enable @angular-eslint/sort-ngmodule-metadata-arrays */
  declarations: [...exportedComponents],
  exports: [...exportedComponents, ...importExportModules, ...standaloneComponents],
})
export class CamfilSharedModule {}
