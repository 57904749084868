import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { CamfilCustomerService } from 'camfil-core/services/camfil-customer/camfil-customer.service';
import { filter, map, mergeMap } from 'rxjs';

import { loginUserSuccess } from 'ish-core/store/customer/user';
import { mapErrorToAction, mapToPayload, mapToPayloadProperty } from 'ish-core/utils/operators';

import {
  loadCamfilCustomers,
  loadCamfilCustomersFail,
  loadCamfilCustomersSuccess,
  loadContactsByCustomer,
  loadContactsByCustomerFail,
  loadContactsByCustomerSuccess,
  loadDeliveryAddresses,
  loadDeliveryAddressesFail,
  loadDeliveryAddressesIfNotLoaded,
  loadDeliveryAddressesSuccess,
  loadUserContactForCustomer,
  loadUserContactForCustomerFail,
  loadUserContactForCustomerSuccess,
  loadUserContactForCustomers,
} from './camfil-customer.actions';
import { getCamfilCustomers } from './camfil-customer.selectors';

@Injectable()
export class CamfilCustomerEffects {
  constructor(private actions$: Actions, private store: Store, private customerService: CamfilCustomerService) {}

  loadCamfilCustomers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadCamfilCustomers),
      mergeMap(() =>
        this.customerService.getCustomers().pipe(
          /* Make sure to do not remove `loadUserContactForCustomers` since this is required to be fulfilled and it is used in CamCard Helper */
          mergeMap(customers => [
            loadCamfilCustomersSuccess({ customers }),
            loadUserContactForCustomers({ customers }),
          ]),
          mapErrorToAction(loadCamfilCustomersFail)
        )
      )
    )
  );

  loadCamfilCustomersAfterLogin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loginUserSuccess),
      mapToPayload(),
      filter(payload => payload.customer.isBusinessCustomer),
      map(() => loadCamfilCustomers())
    )
  );

  loadCustomerContacts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadContactsByCustomer),
      mapToPayload(),
      mergeMap(({ customerId }) =>
        this.customerService.getContactsByCustomerId(customerId).pipe(
          map(contacts => loadContactsByCustomerSuccess({ customerId, contacts })),
          mapErrorToAction(loadContactsByCustomerFail)
        )
      )
    )
  );

  loadUserContactForCustomers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadUserContactForCustomers),
      mapToPayload(),
      mergeMap(({ customers }) => customers.map(customer => loadUserContactForCustomer({ customerId: customer.id })))
    )
  );

  loadUserContactForCustomer$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadUserContactForCustomer),
      mapToPayload(),
      mergeMap(({ customerId, userKey }) =>
        this.customerService.getUserContactForCustomer(customerId, userKey).pipe(
          map(contact => loadUserContactForCustomerSuccess({ customerId, contact })),
          mapErrorToAction(loadUserContactForCustomerFail)
        )
      )
    )
  );

  loadDeliveryAddressesForAllCustomers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadCamfilCustomersSuccess),
      mapToPayloadProperty('customers'),
      mergeMap(customers => [
        ...customers.map(customer => loadDeliveryAddressesIfNotLoaded({ customerId: customer.id })),
      ])
    )
  );

  loadDeliveryAddresses$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadDeliveryAddresses),
      mapToPayloadProperty('customerId'),
      mergeMap(customerId =>
        this.customerService.getDeliveryAddresses(customerId).pipe(
          map(addresses => loadDeliveryAddressesSuccess({ customerId, addresses })),
          mapErrorToAction(loadDeliveryAddressesFail)
        )
      )
    )
  );

  loadDeliveryAddressesIfNotLoaded$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadDeliveryAddressesIfNotLoaded),
      mapToPayload(),
      concatLatestFrom(() => this.store.pipe(select(getCamfilCustomers))),
      filter(
        ([{ customerId }, customers]) =>
          customerId && !customers?.find(customer => customer.id === customerId)?.deliveryAddresses
      ),
      map(([{ customerId }]) => loadDeliveryAddresses({ customerId }))
    )
  );
}
