import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { CamfilPdfService } from 'camfil-core/services/camfil-pdf/camfil-pdf.service';
import { CamfilQuotesService } from 'camfil-core/services/camfil-quotes/camfil-quotes.service';
import {
  areCamfilQuotesLoaded,
  getCamfilQuotes,
  getSelectedCamfilQuoteId,
} from 'camfil-core/store/camfil-quotes/camfil-quotes.selectors';
import { CamfilProductView, ProductCompletenessLevel } from 'camfil-models/camfil-product/product.model';
import { CamfilQuoteHelper } from 'camfil-models/camfil-quotes/camfil-quote/camfil-quote.helper';
import { forkJoin, of } from 'rxjs';
import { concatMap, filter, map, mergeMap, switchMap, take } from 'rxjs/operators';

import { AuthorizationToggleService } from 'ish-core/authorization-toggle.module';
import { displayErrorMessage, displaySuccessMessage } from 'ish-core/store/core/messages';
import { ofUrl, selectRouteParam } from 'ish-core/store/core/router';
import { createBasket } from 'ish-core/store/customer/basket';
import { createOrderSuccess } from 'ish-core/store/customer/orders';
import { getProduct, loadProductIfNotLoaded } from 'ish-core/store/shopping/products';
import {
  distinctCompareWith,
  mapErrorToAction,
  mapToPayload,
  mapToPayloadProperty,
  whenFalsy,
  whenTruthy,
} from 'ish-core/utils/operators';

import {
  approveCamfilQuote,
  approveCamfilQuoteFail,
  approveCamfilQuoteSuccess,
  approveCamfilQuotes,
  approveCamfilQuotesFail,
  approveCamfilQuotesSuccess,
  createCamfilQuoteSuccess,
  loadCamfilQuote,
  loadCamfilQuoteIfNotLoaded,
  loadCamfilQuoteSuccess,
  loadCamfilQuotes,
  loadCamfilQuotesSuccess,
  printCamfilQuote,
  printCamfilQuoteLastStep,
  rejectCamfilQuote,
  rejectCamfilQuoteFail,
  rejectCamfilQuoteSuccess,
  rejectCamfilQuotes,
  rejectCamfilQuotesFail,
  rejectCamfilQuotesSuccess,
  selectCamfilQuote,
  tryPrintCamfilQuoteAgain,
  updateCamfilQuote,
  updateCamfilQuoteFail,
  updateCamfilQuoteSuccess,
} from './camfil-quotes.actions';

@Injectable()
export class CamfilQuotesEffects {
  constructor(
    private actions$: Actions,
    private camfilPdfService: CamfilPdfService,
    private camfilQuotesService: CamfilQuotesService,
    private store: Store,
    private translateService: TranslateService,
    private authorizationService: AuthorizationToggleService
  ) {}

  loadCamfilQuotes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadCamfilQuotes),
      mergeMap(() => this.camfilQuotesService.getQuotes().pipe(map(quotes => loadCamfilQuotesSuccess({ quotes }))))
    )
  );

  loadCamfilQuote$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadCamfilQuote),
      mapToPayloadProperty('quoteId'),
      mergeMap(quoteId =>
        this.camfilQuotesService.getQuote(quoteId).pipe(map(quote => loadCamfilQuoteSuccess({ quote })))
      )
    )
  );

  loadCamfilQuoteIfNotLoaded$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadCamfilQuoteIfNotLoaded),
      mapToPayload(),
      concatLatestFrom(() => this.store.pipe(select(getCamfilQuotes))),
      filter(([{ quoteId }, quotes]) => !CamfilQuoteHelper.isSufficientlyLoaded(quotes?.find(q => q.id === quoteId))),
      map(([{ quoteId }]) => loadCamfilQuote({ quoteId }))
    )
  );

  loadCamfilQuoteForSelectedSelectedCamfilQuote$ = createEffect(() =>
    this.store.pipe(
      select(getSelectedCamfilQuoteId),
      whenTruthy(),
      map(quoteId => loadCamfilQuoteIfNotLoaded({ quoteId }))
    )
  );

  routeListenerForQuotes$ = createEffect(() =>
    this.store.pipe(
      ofUrl(CamfilQuoteHelper.urlRegExp),
      select(areCamfilQuotesLoaded),
      whenFalsy(),
      map(loadCamfilQuotes)
    )
  );

  routeListenerForSelectedCamfilQuote$ = createEffect(() =>
    this.store.pipe(
      select(selectRouteParam('camfilQuoteId')),
      distinctCompareWith(this.store.pipe(select(getSelectedCamfilQuoteId))),
      map(quoteId => selectCamfilQuote({ quoteId }))
    )
  );

  createQuoteSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createOrderSuccess),
      mapToPayloadProperty('order'),
      filter(order => order && order.statusCode === 'RFQ'),
      concatMap(() => [createCamfilQuoteSuccess(), createBasket()])
    )
  );

  approveCamfilQuote$ = createEffect(() =>
    this.actions$.pipe(
      ofType(approveCamfilQuote),
      mapToPayload(),
      mergeMap(({ request }) =>
        this.camfilQuotesService.approveQuote(request).pipe(
          mergeMap(response => [approveCamfilQuoteSuccess({ response }), loadCamfilQuote({ quoteId: request.id })]),
          mapErrorToAction(approveCamfilQuoteFail)
        )
      )
    )
  );

  approveCamfilQuoteError$ = createEffect(() =>
    this.actions$.pipe(
      ofType(approveCamfilQuoteFail),
      map(() =>
        displayErrorMessage({
          message: 'camfil.quotes.quoteslist.approve_quote_error',
          duration: 3000,
        })
      )
    )
  );

  approveCamfilQuoteSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(approveCamfilQuoteSuccess),
      mapToPayload(),
      map(({ response }) =>
        displaySuccessMessage({
          message: 'camfil.quotes.quoteslist.approve_quote_success',
          messageParams: {
            0: this.translateService.instant(
              CamfilQuoteHelper.isQuotation(response)
                ? 'camfil.quotes.quoteslist.filters.type.quotation'
                : 'camfil.quotes.quoteslist.filters.type.proposal'
            ),
          },
        })
      )
    )
  );

  approveCamfilQuotes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(approveCamfilQuotes),
      mapToPayload(),
      mergeMap(({ request }) =>
        forkJoin(request.map(r => this.camfilQuotesService.approveQuote(r))).pipe(
          mergeMap(response => [approveCamfilQuotesSuccess({ response }), loadCamfilQuotes()]),
          mapErrorToAction(approveCamfilQuotesFail)
        )
      )
    )
  );

  approveCamfilQuotesError$ = createEffect(() =>
    this.actions$.pipe(
      ofType(approveCamfilQuotesFail),
      map(() =>
        displayErrorMessage({
          message: 'camfil.quotes.quoteslist.approve_quotes_error',
          duration: 3000,
        })
      )
    )
  );

  rejectCamfilQuote$ = createEffect(() =>
    this.actions$.pipe(
      ofType(rejectCamfilQuote),
      mapToPayload(),
      mergeMap(({ request, rejectionReason }) =>
        this.camfilQuotesService.rejectQuote(request, rejectionReason).pipe(
          mergeMap(response => [rejectCamfilQuoteSuccess({ response }), loadCamfilQuote({ quoteId: request.id })]),
          mapErrorToAction(rejectCamfilQuoteFail)
        )
      )
    )
  );

  rejectCamfilQuoteError$ = createEffect(() =>
    this.actions$.pipe(
      ofType(rejectCamfilQuoteFail),
      map(() =>
        displayErrorMessage({
          message: 'camfil.quotes.quoteslist.reject_quote_error',
          duration: 3000,
        })
      )
    )
  );

  rejectCamfilQuoteSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(rejectCamfilQuoteSuccess),
      map(() =>
        displaySuccessMessage({
          message: 'camfil.quotes.quoteslist.reject_quote_success',
        })
      )
    )
  );

  rejectCamfilQuotes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(rejectCamfilQuotes),
      mapToPayload(),
      mergeMap(({ request, rejectionReason }) =>
        forkJoin(request.map(r => this.camfilQuotesService.rejectQuote(r, rejectionReason))).pipe(
          mergeMap(response => [
            rejectCamfilQuotesSuccess({ response }),
            loadCamfilQuotes(),
            displaySuccessMessage({
              message: 'camfil.quotes.quoteslist.reject_quotes_modal.success',
            }),
          ]),
          mapErrorToAction(rejectCamfilQuotesFail)
        )
      )
    )
  );

  rejectCamfilQuotesError$ = createEffect(() =>
    this.actions$.pipe(
      ofType(rejectCamfilQuotesFail),
      map(() =>
        displayErrorMessage({
          message: 'camfil.quotes.quoteslist.reject_quotes_error',
          duration: 3000,
        })
      )
    )
  );

  updateCamfilQuote$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateCamfilQuote),
      mapToPayload(),
      mergeMap(payload =>
        this.camfilQuotesService.updateQuote(payload.quote).pipe(
          map(quote => updateCamfilQuoteSuccess({ quote })),
          mapErrorToAction(updateCamfilQuoteFail)
        )
      )
    )
  );

  printCamfilQuote$ = createEffect(() =>
    this.actions$.pipe(
      ofType(printCamfilQuote),
      mapToPayload(),
      concatLatestFrom(() => this.store.pipe(select(getCamfilQuotes))),
      mergeMap(([{ quoteId, products }, quotes]) => {
        const quote = quotes?.find(q => q.id === quoteId);
        const isSufficientlyLoaded = CamfilQuoteHelper.isSufficientlyLoaded(quote);
        if (isSufficientlyLoaded) {
          return [printCamfilQuoteLastStep({ quote, products })];
        }
        return this.camfilQuotesService.getQuote(quoteId).pipe(
          map(quote => ({
            quote,
            skus: CamfilQuoteHelper.getQuotationSkus(quote),
          })),
          concatMap(({ quote, skus }) => [
            loadCamfilQuoteSuccess({ quote }),
            ...skus.map(sku => loadProductIfNotLoaded({ sku, level: ProductCompletenessLevel.Detail })),
            tryPrintCamfilQuoteAgain({ quoteId, skus }),
          ])
        );
      })
    )
  );

  printCamfilQuoteLastStep$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(printCamfilQuoteLastStep),
        mapToPayload(),
        concatLatestFrom(() => [
          this.authorizationService.isAuthorizedTo('APP_B2B_VIEW_PRICES'),
          this.authorizationService.isAuthorizedTo('APP_B2B_PRINT_PRICES'),
        ]),
        mergeMap(([{ quote, products }, showPrice, printPrice]) =>
          of(quote).pipe(
            map(quote => this.camfilQuotesService.quoteDataToPdf(quote, products, showPrice && printPrice)),
            map(data => this.camfilPdfService.createPdf(data).print())
          )
        )
      ),
    { dispatch: false }
  );

  tryPrintCamfilQuoteAgain$ = createEffect(() =>
    this.actions$.pipe(
      ofType(tryPrintCamfilQuoteAgain),
      mapToPayload(),
      switchMap(({ quoteId, skus }) =>
        forkJoin(skus.map(sku => this.store.pipe(select(getProduct(sku)), whenTruthy(), take(1)))).pipe(
          map(products => printCamfilQuote({ quoteId, products: products as CamfilProductView[] }))
        )
      )
    )
  );
}
