import { createSelector } from '@ngrx/store';
import { getCamfilFeatureState } from 'camfil-core/store/camfil-store';

import { B2bRolesState, camfilB2bRolesFeatureKey, roleAdapter } from './b2b-roles.reducer';

export const getB2bRolesState = getCamfilFeatureState<B2bRolesState>(camfilB2bRolesFeatureKey);

export const getRolesLoading = createSelector(getB2bRolesState, state => state.loading);

export const getRolesError = createSelector(getB2bRolesState, state => state.error);

const { selectAll, selectTotal } = roleAdapter.getSelectors(getB2bRolesState);

export const getRoles = selectAll;

export const getRole = (roleID: string) => createSelector(getRoles, roles => roles?.find(u => u.id === roleID));

export const getRolesByIds = (roleIDs: string[]) =>
  createSelector(getRoles, roles =>
    // preserve order from state
    roles.filter(r => roleIDs?.includes(r.id))
  );

export const getRolesCount = selectTotal;

export const isRoleInitialized = createSelector(getB2bRolesState, state => state.initialized);
