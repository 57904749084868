import { EntityState, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { CamfilB2bRole } from 'camfil-models/camfil-organization/role/camfil-b2b-role.model';

import { HttpError } from 'ish-core/models/http-error/http-error.model';
import { setErrorOn, setLoadingOn, unsetLoadingAndErrorOn } from 'ish-core/utils/ngrx-creators';

import {
  loadCustomerRoles,
  loadCustomerRolesFail,
  loadCustomerRolesSuccess,
  updateCustomerUserRoles,
  updateCustomerUserRolesFail,
  updateCustomerUserRolesSuccess,
} from './b2b-roles.actions';

export const camfilB2bRolesFeatureKey = 'b2bRoles';

export const roleAdapter = createEntityAdapter<CamfilB2bRole>({
  selectId: role => role.id,
});

export interface B2bRolesState extends EntityState<CamfilB2bRole> {
  loading: boolean;
  error: HttpError;
  initialized: boolean;
}

export const initialState: B2bRolesState = roleAdapter.getInitialState({
  loading: false,
  error: undefined,
  initialized: false,
});

export const reducer = createReducer(
  initialState,
  setLoadingOn(loadCustomerRoles, updateCustomerUserRoles),
  setErrorOn(loadCustomerRolesFail, updateCustomerUserRolesFail),
  unsetLoadingAndErrorOn(loadCustomerRolesSuccess, updateCustomerUserRolesSuccess),
  on(loadCustomerRolesSuccess, (state: B2bRolesState, action) => {
    const { roles } = action.payload;

    return roleAdapter.upsertMany(roles, { ...state, initialized: true });
  }),
  on(updateCustomerUserRolesSuccess, (state: B2bRolesState, action) => {
    const { roles } = action.payload;

    return roleAdapter.upsertMany(roles, { ...state, initialized: true });
  })
);
