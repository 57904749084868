<ng-container *ngIf="filter$ | async as filterNavigation">
  <ng-container *ngIf="isSideBar; else horizontal">
    <ish-filter-navigation-sidebar [filterNavigation]="filterNavigation" (applyFilter)="applyFilter($event)" />
  </ng-container>
  <ng-template #horizontal>
    <ish-filter-navigation-horizontal [filterNavigation]="filterNavigation" (applyFilter)="applyFilter($event)" />
    <ish-filter-navigation-badges
      [filterNavigation]="filterNavigation"
      (applyFilter)="applyFilter($event)"
      (clearFilters)="clearFilters()"
    />
  </ng-template>
</ng-container>
