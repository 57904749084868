import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { CamfilConfigurationFacade } from 'camfil-core/facades/camfil-configuration.facade';
import { EMPTY, Observable, combineLatest, map, switchMap } from 'rxjs';

import { AccountFacade } from 'ish-core/facades/account.facade';
import { ProductContextFacade } from 'ish-core/facades/product-context.facade';
import { Price } from 'ish-core/models/price/price.model';
import { AuthorizationToggleService } from 'ish-core/utils/authorization-toggle/authorization-toggle.service';

@Component({
  selector: 'camfil-product-price',
  templateUrl: './camfil-product-price.component.html',
  styleUrls: ['./camfil-product-price.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CamfilProductPriceComponent implements OnInit {
  show$: Observable<boolean>;
  listPrice$: Observable<Price>;
  salePrice$: Observable<Price>;

  constructor(
    private accountFacade: AccountFacade,
    private camfilConfigurationFacade: CamfilConfigurationFacade,
    private context: ProductContextFacade,
    private authorizationService: AuthorizationToggleService
  ) {}

  ngOnInit() {
    this.show$ = combineLatest([
      this.accountFacade.isLoggedIn$,
      this.camfilConfigurationFacade.isEnabled$('showPricesForNonLoggedInUser'),
    ]).pipe(
      map(([isAuthorized, showPricesForNonLoggedInUser]) => (showPricesForNonLoggedInUser ? true : isAuthorized))
    );

    this.listPrice$ = this.authorizationService
      .isAuthorizedTo('APP_B2B_VIEW_PRICES')
      .pipe(switchMap(isAuthorized => (isAuthorized ? this.context.select('prices', 'listPrice') : EMPTY)));

    this.salePrice$ = this.authorizationService
      .isAuthorizedTo('APP_B2B_VIEW_PRICES')
      .pipe(switchMap(isAuthorized => (isAuthorized ? this.context.select('prices', 'salePrice') : EMPTY)));
  }
}
