import { LocationStrategy } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

import { AppFacade } from 'ish-core/facades/app.facade';
import { CookiesService } from 'ish-core/utils/cookies/cookies.service';
import { FeatureToggleService } from 'ish-core/utils/feature-toggle/feature-toggle.service';
import { LanguageSwitchComponent } from 'ish-shell/header/language-switch/language-switch.component';

@Component({
  selector: 'camfil-language-switch',
  templateUrl: './camfil-language-switch.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CamfilLanguageSwitchComponent extends LanguageSwitchComponent implements OnInit {
  locale$: Observable<string>;
  availableLocales$: Observable<string[]>;

  constructor(
    appFacade: AppFacade,
    cookiesService: CookiesService,
    featureToggleService: FeatureToggleService,
    public location: LocationStrategy
  ) {
    super(appFacade, cookiesService, featureToggleService, location);
  }
}
