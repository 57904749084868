import { createAction } from '@ngrx/store';
import { CamfilCustomerDeliveryTerm } from 'camfil-models/camfil-customer-delivery-term/camfil-customer-delivery-term.model';

import { httpError, payload } from 'ish-core/utils/ngrx-creators';

export const loadCustomerDeliveryTerm = createAction(
  '[CamfilCustomerDeliveryTerm] Load Customer Delivery Term',
  payload<{ customerId: string }>()
);

export const loadCustomerDeliveryTermFail = createAction(
  '[CamfilCustomerDeliveryTerm] Load Customer Delivery Term Fail',
  httpError()
);

export const loadCustomerDeliveryTermSuccess = createAction(
  '[CamfilCustomerDeliveryTerm] Load Customer Delivery Term Success',
  payload<{ term: CamfilCustomerDeliveryTerm }>()
);

export const selectloadCustomerDeliveryTerm = createAction(
  '[CamfilCustomerDeliveryTerm] Select CamfilCustomerDeliveryTerm',
  payload<{ customerId: string }>()
);
