<span *ngIf="productLabel$ | async as productLabel" class="product-label" [ngClass]="'product-label-' + productLabel">
  <ng-container [ngSwitch]="productLabel">
    <!-- check for the value and show the according localized text in the ribbon -->
    <ng-container *ngSwitchCase="'new'">
      {{ 'product.label.new.text' | translate }}
    </ng-container>
    <ng-container *ngSwitchCase="'sale'">
      {{ 'product.label.sale.text' | translate }}
    </ng-container>
    <ng-container *ngSwitchCase="'topseller'">
      {{ 'product.label.topseller.text' | translate }}
    </ng-container>
  </ng-container>
</span>
