import { Injectable } from '@angular/core';
import { FormlyFieldConfig } from '@ngx-formly/core';

import { FieldLibraryConfiguration } from 'ish-shared/formly/field-library/configurations/field-library-configuration';

@Injectable()
export class IdConfiguration extends FieldLibraryConfiguration {
  id = 'idFLC';

  getFieldConfig(): FormlyFieldConfig {
    return {
      key: 'id',
      type: 'camfil-plain-text-field',
      props: {
        label: 'camfil.quotes.quote_detail.id',
        required: false,
        disabled: true,
        inputClass: 'mdc-text-field__input',
      },
      wrappers: ['form-field'],
    };
  }
}
