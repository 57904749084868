import { EntityState, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { CamfilOrder } from 'camfil-models/camfil-order/camfil-order.model';

import { HttpError } from 'ish-core/models/http-error/http-error.model';
import { createOrderSuccess } from 'ish-core/store/customer/orders';
import { setLoadingOn, unsetLoadingAndErrorOn } from 'ish-core/utils/ngrx-creators';

import { addBasketToNewCamCard, addBasketToNewCamCardSuccess } from '../cam-cards/cam-cards.actions';

import {
  cloneCamfilOrder,
  cloneCamfilOrderSuccess,
  createCamfilOrder,
  loadCamfilOrder,
  loadCamfilOrderFail,
  loadCamfilOrderSuccess,
  loadCamfilOrders,
  loadCamfilOrdersFailure,
  loadCamfilOrdersSuccess,
  selectOrder,
} from './camfil-orders.actions';

export const camfilOrdersFeatureKey = 'orders';

export interface State extends EntityState<CamfilOrder> {
  loading: boolean;
  selected?: string;
  error: HttpError;
}

export const orderAdapter = createEntityAdapter<CamfilOrder>({
  selectId: order => order.id,
});

export const initialState: State = orderAdapter.getInitialState({
  loading: false,
  selected: undefined,
  error: undefined,
});

export const reducer = createReducer(
  initialState,
  setLoadingOn(loadCamfilOrders, loadCamfilOrder, createCamfilOrder, addBasketToNewCamCard, cloneCamfilOrder),
  unsetLoadingAndErrorOn(
    loadCamfilOrdersSuccess,
    loadCamfilOrderSuccess,
    addBasketToNewCamCardSuccess,
    createOrderSuccess,
    cloneCamfilOrderSuccess
  ),
  on(loadCamfilOrdersSuccess, (state, action): State => orderAdapter.upsertMany(action.payload.orders, state)),
  on(loadCamfilOrdersFailure, loadCamfilOrderFail, (state): State => state),
  on(
    selectOrder,
    (state, action): State => ({
      ...state,
      selected: action.payload.camfilOrderId,
    })
  ),
  on(
    loadCamfilOrderSuccess,
    (state, action): State =>
      orderAdapter.upsertOne(action.payload.order, { ...state, selected: action.payload.order.id })
  )
);
