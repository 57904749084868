import { components } from 'camfil-generated/types/quoting';
import { CamfilAddress } from 'camfil-models/camfil-address/camfil-address.model';
import { CamfilCustomer } from 'camfil-models/camfil-customer/camfil-customer.model';
import { ExtractApiComponentSchema } from 'camfil-models/camfil-generated-types.helper';

import { PriceItem } from 'ish-core/models/price-item/price-item.model';
import { Price } from 'ish-core/models/price/price.model';

import { CamfilQuoteSurchargeItem } from '../camfil-quote-surcharge-item/camfil-quote-surcharge-item.model';

export interface CamfilQuoteLineItem extends ExtractApiComponentSchema<components, 'CamfilQuotationLineItemRO'> {
  originSinglePrice: Price;
  originTotalPrice: Price;
  singlePrice: Price;
  totalPrice: Price;
  totalTax: Price;
}

export enum CamfilQuoteStatus {
  Requested,
  Received,
  Approved,
  Rejected,
  Expired,
  ReadyToApprove, // artificial status
}

export type CamfilQuoteType = 'quotation' | 'proposal';

export type CamfilQuoteOrderChannel = 'WEB' | 'CAMFIL-WEB' | 'ERP';

export interface CamfilQuote extends ExtractApiComponentSchema<components, 'CamfilQuotationRO'> {
  ERPnumber?: string; // erpnumber
  customer?: CamfilCustomer;
  customerNo?: string; // customerId
  customerQuoteNumber?: string; // number
  deliveryAddress?: CamfilAddress;
  items?: CamfilQuoteLineItem[]; // CustomType
  orderMark?: string; // quotationReference
  originTotal?: PriceItem;
  quotationDate?: string; // submittedDate
  quotationType?: CamfilQuoteType;
  requestedDate?: string; // creationDate
  status?: CamfilQuoteStatus; // CustomType
  surchargeItems?: CamfilQuoteSurchargeItem[];
  // goodsAcceptanceNote?: string; // TODO (extMlk): Not available in API response. Check if needed. Mapped from the customer deliveryAddress.
  // fieldSalesContactCamfil (from ERP) // TODO (extMlk): Not available in API response. Check if needed.
  // internalSalesContact (from ERP) // TODO (extMlk): Not available in API response. Check if needed.
}
