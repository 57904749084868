<div class="content-page">
  <div class="row">
    <ng-container
      *ngIf="pagelet.booleanParam('ShowSidePanel') || pagelet.booleanParam('ShowNavigation'); else noSidePanel"
    >
      <div class="page-navigation col-md-3">
        <div *ngIf="pagelet.booleanParam('ShowNavigation')" class="page-navigation-contents">
          <ng-container *ngIf="contentPageTree$ | async as contentPageTree">
            <ish-content-navigation
              [contentPageTree]="contentPageTree"
              [depth]="pagelet.numberParam('NavigationDepth', 0)"
            />
          </ng-container>
        </div>
        <div class="marketing-area">
          <ish-content-slot [slot]="'app_sf_base_cm:slot.marketing.sidePanel.pagelet2-Slot'" [pagelet]="pagelet" />
        </div>
      </div>
      <div class="col-md-9">
        <ish-breadcrumb />
        <div class="marketing-area">
          <ish-content-slot [slot]="'app_sf_base_cm:slot.marketing.base.pagelet2-Slot'" [pagelet]="pagelet" />
        </div>
        <ish-content-slot [slot]="'app_sf_base_cm:slot.staticPage.content.pagelet2-Slot'" [pagelet]="pagelet" />
      </div>
    </ng-container>

    <ng-template #noSidePanel>
      <div class="col-md-12">
        <ish-breadcrumb />
        <div class="marketing-area">
          <ish-content-slot [slot]="'app_sf_base_cm:slot.marketing.base.pagelet2-Slot'" [pagelet]="pagelet" />
        </div>
        <ish-content-slot [slot]="'app_sf_base_cm:slot.staticPage.content.pagelet2-Slot'" [pagelet]="pagelet" />
      </div>
    </ng-template>
  </div>
</div>
