import { EntityState, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { Manufacturer } from 'camfil-models/camfil-ahu/manufacturer/manufacturer.model';

import { HttpError } from 'ish-core/models/http-error/http-error.model';
import { setErrorOn, setLoadingOn, unsetLoadingAndErrorOn } from 'ish-core/utils/ngrx-creators';

import {
  deselectAhuManufacturer,
  loadAhuManufacturers,
  loadAhuManufacturersFail,
  loadAhuManufacturersSuccess,
  selectAhuManufacturer,
} from './manufacturer.actions';

export const ahuManufacturerFeatureKey = 'ahuManufacturer';

export interface AhuManufacturerState extends EntityState<Manufacturer> {
  loading: boolean;
  selected: string;
  error: HttpError;
  initialized: boolean;
}

export const manufacturerAdapter = createEntityAdapter<Manufacturer>({
  selectId: manufacturer => manufacturer.id,
});

const initialState: AhuManufacturerState = manufacturerAdapter.getInitialState({
  loading: false,
  selected: undefined,
  error: undefined,
  initialized: false,
});

export const reducer = createReducer(
  initialState,
  setLoadingOn(loadAhuManufacturers),
  setErrorOn(loadAhuManufacturersFail),
  unsetLoadingAndErrorOn(loadAhuManufacturersSuccess, deselectAhuManufacturer),
  // eslint-disable-next-line @ngrx/avoid-duplicate-actions-in-reducer
  on(loadAhuManufacturersSuccess, (state): AhuManufacturerState => ({ ...state, initialized: true })),
  on(
    // eslint-disable-next-line @ngrx/avoid-duplicate-actions-in-reducer
    loadAhuManufacturersSuccess,
    (state, action): AhuManufacturerState => ({
      ...manufacturerAdapter.upsertMany(action.payload.manufacturers, state),
    })
  ),
  on(
    loadAhuManufacturersFail,
    deselectAhuManufacturer,
    (state): AhuManufacturerState => ({
      ...state,
      selected: undefined as string,
    })
  ),
  on(
    selectAhuManufacturer,
    (state, action): AhuManufacturerState => ({
      ...state,
      selected: action.payload.manufacturerId,
    })
  )
);
