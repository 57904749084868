<ng-container *ngIf="(visible$ | async) && ('compare' | ishFeature)">
  <ng-container [ngSwitch]="density">
    <ng-container *ngSwitchCase="'default'">
      <button
        title="{{ 'product.compare.link' | translate }}"
        [ngClass]="cssClass"
        [class.is-selected]="isInCompareList$ | async"
        (click)="toggleCompare()"
        mat-stroked-button
      >
        <mat-icon svgIcon="compare" />
        {{ 'product.compare.link' | translate }}
      </button>
    </ng-container>
    <ng-container *ngSwitchCase="'compact'">
      <button
        [ngClass]="cssClass"
        [matTooltip]="'product.compare.link' | translate"
        [class.is-selected]="isInCompareList$ | async"
        (click)="toggleCompare()"
        mat-icon-button
      >
        <mat-icon svgIcon="compare" />
      </button>
    </ng-container>
  </ng-container>
</ng-container>
