import { WidgetConfig } from '@livechat/widget-angular';

export interface Environment {
  debugStream: 'stderr' | 'stdout';
  iccBaseUrl: string;
  iccServer: string;
  iccToken?: string;
  strapiBaseUrl: string;
  strapiToken?: string;
  livechatLicense?: WidgetConfig['license'];
  livechatCustomerName?: WidgetConfig['customerName'];
  livechatGroup?: WidgetConfig['group'];
  livechatCustomerEmail?: WidgetConfig['customerEmail'];
  livechatChatBetweenGroups?: WidgetConfig['chatBetweenGroups'];
  livechatSessionVariables?: WidgetConfig['sessionVariables'];
  livechatVisibility?: WidgetConfig['visibility'];
  liveChatCustomIdentityProvider?(): WidgetConfig['customIdentityProvider'];
}

export const ENVIRONMENT_DEFAULTS: Environment = {
  debugStream: 'stderr',
  strapiBaseUrl: 'http://localhost:1337/api',
  iccBaseUrl: 'https://apim-icc-uat.azure-api.net',
  iccServer: 'CAMFIL/icc',
};
