import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CamfilCheckoutFacade } from 'camfil-core/facades/camfil-checkout.facade';

@Component({
  selector: 'camfil-mini-basket',
  templateUrl: './camfil-mini-basket.component.html',
  styleUrls: ['./camfil-mini-basket.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CamfilMiniBasketComponent {
  basketLoading$ = this.camfilCheckoutFacade.isLoading$;
  bucketsCount$ = this.camfilCheckoutFacade.camfilBucketsLength$;

  constructor(private camfilCheckoutFacade: CamfilCheckoutFacade) {}
}
