import { createAction } from '@ngrx/store';
import { CamfilZipCodeAddress } from 'camfil-models/camfil-zip-code-address/camfil-zip-code-address.model';

import { httpError, payload } from 'ish-core/utils/ngrx-creators';

export const loadZipCodeAddress = createAction(
  '[Camfil Zipcodes] load Zip Code Address',
  payload<{ code: string; countryCode?: string }>()
);

export const loadZipCodeAddressIfNotLoaded = createAction(
  '[Camfil Zipcodes] load Zip Code Address if not loaded',
  payload<{ code: string; countryCode?: string }>()
);

export const loadZipCodeAddressSuccess = createAction(
  '[Camfil Zipcodes API] load Zip Code Address Success',
  payload<{ code: string; address: CamfilZipCodeAddress[] }>()
);

export const loadZipCodeAddressFail = createAction('[Camfil Zipcodes API] load Zip Code Fail', httpError());
