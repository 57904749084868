<mat-icon svgIcon="camcard" color="primary" camfilDialogIcon />
<h2 camfilDialogTitle>{{ 'camfil.modal.addToCamcard.header' | translate }}</h2>
<form class="content" [formGroup]="form" (ngSubmit)="onSubmit()" camfilDialogContent separated>
  <camfil-product-form-dialog-product
    *ngFor="let product of dialogData; index as index"
    class="product"
    [name]="product.name"
    [sku]="product.sku"
    [control]="form.controls.products.controls[index]"
  />

  <h3 class="camcards-header">{{ 'camfil.camcards.modal.add.search.subtitle' | translate }}</h3>
  <mat-form-field appearance="outline" class="camcards-search">
    <mat-label>{{ 'camfil.search.searchbox.btn.text' | translate }}</mat-label>
    <mat-icon matPrefix svgIcon="search" />
    <input matInput [formControl]="search" />
  </mat-form-field>
  <div class="camcards">
    <div class="camcard" *ngFor="let c of camCards$ | async" [class.camcard-selected]="camCard.value === c.id">
      <label matRipple class="camcard-main">
        <dl class="camcard-properties">
          <dt class="camcard-properties-name">{{ 'camfil.modal.addToCamcard.camcard.name' | translate }}</dt>
          <dd>{{ c.name }}</dd>
          <ng-container *ngIf="c.orderLabel?.trim().length > 0">
            <dt>{{ camCardOrderLabelLabel$ | async | translate }}</dt>
            <dd>{{ c.orderLabel }}</dd>
          </ng-container>
          <ng-container *ngIf="camCard.value === c.id">
            <ng-container *ngIf="c.invoiceLabel?.trim().length > 0">
              <dt>{{ 'camfil.modal.addToCamcard.camcard.invoice_mark' | translate }}</dt>
              <dd>{{ c.invoiceLabel }}</dd>
            </ng-container>
            <dt>{{ 'camfil.modal.addToCamcard.camcard.delivery_address' | translate }}</dt>
            <dd>
              <camfil-address
                class="camcard-properties-address-content"
                [address]="c.deliveryAddress"
                [displayEmail]="false"
              />
            </dd>
          </ng-container>
        </dl>
        <input type="radio" [value]="c.id" formControlName="camCard" class="camcard-input" />
      </label>
      <ng-container *ngIf="camCard.value === c.id">
        <mat-divider class="subcamcard-divider" />
        <mat-form-field class="subcamcard-select" appearance="outline">
          <mat-label>{{ 'camfil.modal.createCamcard.input.new_subcamcard' | translate }}</mat-label>
          <mat-select formControlName="subCamCard">
            <!-- TODO: Create custom label -->
            <mat-option [value]="undefined">{{ 'camfil.common.label.none' | translate }}</mat-option>
            <mat-option *ngFor="let s of c.subCamCards" [value]="s.id">{{ s.name }}</mat-option>
            <!-- TODO: Create custom label -->
            <mat-option [value]="'new'">{{
              'camfil.account.cam_card.details.button.add_section.label' | translate
            }}</mat-option>
          </mat-select>
        </mat-form-field>
        <ng-container *ngIf="subCamCard.value === 'new'">
          <mat-form-field appearance="outline" class="subcamcard-new">
            <mat-label>{{ 'camfil.modal.addToCamcard.camcard.new_segment' | translate }}</mat-label>
            <input matInput formControlName="subCamCardName" #subCamCardNameInput />
            <mat-error *ngIf="subCamCardName.errors?.required">
              {{ 'camfil.modal.addToCamcard.camcard.new_segment.error.required' | translate }}
            </mat-error>
            <mat-error *ngIf="subCamCardName.errors?.maxlength">
              {{ 'camfil.modal.addToCamcard.camcard.new_segment.error.maxLength' | translate }}
            </mat-error>
          </mat-form-field>
        </ng-container>
      </ng-container>
    </div>
  </div>
</form>
<div camfilDialogActions>
  <button mat-button color="primary" type="button" [disabled]="form.invalid" (click)="createNewCamCard()">
    {{ 'camfil.modal.addToCamcard.button.create_new' | translate }}
  </button>
  <button
    mat-raised-button
    color="primary"
    type="button"
    [disabled]="form.invalid || !camCard.value || form.disabled"
    (click)="onSubmit()"
  >
    {{ 'camfil.modal.addToCamcard.button.add_to_camcard' | translate }}
  </button>
</div>
