<div camfilDialogContent>
  <button (click)="hide()" aria-label="Close" class="close">
    <mat-icon aria-hidden="true" svgIcon="inactive" />
  </button>

  <h2 camfilDialogTitle class="text-no-transform">
    {{
      'camfil.checkout.validationDeliveryDate.dialog.header'
        | translate
          : {
              count: (invalidDeliveryDates$ | async)?.length,
              indexes: invalidOrderIndexes$ | async | join
            }
    }}
  </h2>

  <ng-template #missingCommonDeliveryDateTemplate>
    <p class="text-muted">
      <small>
        {{ 'camfil.checkout.validationDeliveryDate.dialog.missingCommonDeliveryDate.hint' | translate }}
      </small>
    </p>
  </ng-template>

  <ng-container *ngIf="commonDeliveryDate$ | async as commonDeliveryDate; else missingCommonDeliveryDateTemplate">
    <p class="text-muted">
      <small>
        {{
          'camfil.checkout.validationDeliveryDate.dialog.commonDeliveryDate.hint'
            | translate : { commonDeliveryDate: commonDeliveryDate | camfilDate }
        }}
      </small>
    </p>
  </ng-container>

  <form [formGroup]="form.instance" (ngSubmit)="submitForm()">
    <formly-form [form]="form.instance" [model]="form.model$ | async" [fields]="form.fields$ | async" />
  </form>

  <div camfilDialogActions class="text-center mt-4">
    <button
      mat-button
      mat-flat-button
      color="primary"
      [disabled]="!form?.instance?.valid"
      type="button"
      (click)="submitForm()"
    >
      {{ 'camfil.modal.edit_order.button.save' | translate }}
    </button>
  </div>
  <br />
</div>
