import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';

import { selectQueryParam } from 'ish-core/store/core/router';

@Injectable({ providedIn: 'root' })
export class CamfilQueryParametersFacade {
  constructor(private store: Store) {}

  queryParam$(parameter: string): Observable<string | undefined> {
    return this.store.pipe(select(selectQueryParam(parameter)));
  }
}
