import { ChangeDetectionStrategy, Component, DestroyRef, HostBinding, Inject, OnInit, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { CamfilCheckoutFacade } from 'camfil-core/facades/camfil-checkout.facade';
import { CamfilBucketHelper } from 'camfil-models/camfil-bucket/camfil-bucket.helper';
import { CamfilBucket } from 'camfil-models/camfil-bucket/camfil-bucket.model';
import { CamfilFormClass } from 'camfil-shared/formly/utils/camfil-form';
import { Observable, combineLatest, filter, of, take } from 'rxjs';

import { FieldLibrary } from 'ish-shared/formly/field-library/field-library';

export type CamfilBulkDeliveryDateModalData = CamfilBucket[];

export type CamfilBulkDeliveryDateModalResult = Date;

@Component({
  selector: 'camfil-bulk-delivery-date-modal',
  templateUrl: './camfil-bulk-delivery-date-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CamfilBulkDeliveryDateModalComponent implements OnInit {
  @HostBinding('class.camfil-dialog') camfilDialogClass = true;

  constructor(
    private camfilCheckoutFacade: CamfilCheckoutFacade,
    private fieldLibrary: FieldLibrary,
    public matDialogRef: MatDialogRef<CamfilBulkDeliveryDateModalComponent, CamfilBulkDeliveryDateModalResult>,
    // eslint-disable-next-line ish-custom-rules/use-type-safe-injection-token
    @Inject(MAT_DIALOG_DATA) public dialogData: CamfilBulkDeliveryDateModalData
  ) {}

  private calendarExceptions = new Set<number>();
  private destroyRef = inject(DestroyRef);

  buckets = this.dialogData;
  rangeDates$: Observable<Date[]>;
  rangeDates: Date[];
  selectedDate: Date;
  calendarForm: CamfilFormClass<{ deliveryDate: string }>;
  oneDayScope = false;

  ngOnInit() {
    this.rangeDates$ = this.camfilCheckoutFacade.commonDatesForSelectedBuckets$(of(this.buckets));

    combineLatest([this.rangeDates$, this.camfilCheckoutFacade.calendarExceptions$])
      .pipe(
        filter(([dates]) => !!dates.length),
        take(1),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(([dates, exceptions]) => {
        exceptions?.forEach(exception => {
          this.calendarExceptions.add(CamfilBucketHelper.stripTimeFromDate(new Date(exception.date)).getTime());
        });

        if (dates.length === 2) {
          const diff = dates[1].getTime() - dates[0].getTime();
          const oneDay = 1000 * 3600 * 24;
          const diffDays = Math.round(diff / oneDay);
          this.oneDayScope = diffDays === 1;

          if (diffDays > 1) {
            const daysBetween = [];
            for (let i = 1; i <= diffDays; i++) {
              daysBetween.push(new Date(dates[0].getTime() + oneDay * i));
            }
            dates.splice(1, 0, ...daysBetween);
          }
        }

        this.rangeDates = dates;
        this.calendarForm = this.createCalendarFormForm();
      });
  }

  private createCalendarFormForm() {
    const form = new CamfilFormClass({
      fields$: this.getCalendarFormFields$(),
      model$: of({
        deliveryDate: undefined,
      }),
    });

    form
      .valueChanges$()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(({ deliveryDate }) => {
        this.selectedDate = new Date(deliveryDate);
      });

    return form;
  }

  private getCalendarFormFields$(): Observable<FormlyFieldConfig[]> {
    const fields = [
      {
        fieldGroup: [
          this.fieldLibrary.getConfiguration('lastDeliveryDateFLC', {
            key: 'deliveryDate',
            modelOptions: {
              updateOn: 'change',
            },
            props: {
              label: 'camfil.checkout.form.delivery_date',
              datepickerOptions: {
                min: this.rangeDates[0] || new Date(),
                filter: (date: Date | null): boolean =>
                  CamfilBucketHelper.filterDaysForForm(date, this.rangeDates, this.calendarExceptions),
                dateClass: (date: Date): string =>
                  CamfilBucketHelper.handleDateClassesForForm(
                    date,
                    this.rangeDates,
                    this.rangeDates[0],
                    this.rangeDates?.[this.rangeDates.length - 1]
                  ),
              },
            },
            validation: {
              messages: {
                required: 'camfil.checkout.order_header.invoice_label.error.required',
                maxlength: 'camfil.checkout.order_header.invoice_label.error.maxLength',
              },
            },
          }),
        ],
        fieldGroupClassName: 'formly-wrapper',
      },
    ];

    return of(fields);
  }

  confirmChnageDate() {
    // eslint-disable-next-line rxjs-angular/prefer-takeuntil
    this.rangeDates$.pipe(take(1)).subscribe(dates => {
      if (dates.length) {
        if (this.selectedDate) {
          this.matDialogRef.close(this.selectedDate);
        }
      } else {
        this.matDialogRef.close();
      }
    });
  }
}
