import { SlicePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'camfilTruncate',
})
export class CamfilTruncatePipe implements PipeTransform {
  constructor(private slicePipe: SlicePipe) {}

  transform(value: string, limit: number = 20, completeWords: boolean = false, ellipsis: string = '...'): string {
    if (!value) {
      return '';
    }

    if (value.length <= limit) {
      return value;
    }

    let finalLimit = limit;

    if (completeWords) {
      const upToLimit = value.substring(0, limit);

      finalLimit = upToLimit.lastIndexOf(' ');

      if (finalLimit === -1) {
        // No space found, revert to original limit
        finalLimit = limit;
      }
    }

    const truncatedText = this.slicePipe.transform(value, 0, finalLimit);

    return truncatedText + (value.length > finalLimit ? ellipsis : '');
  }
}
