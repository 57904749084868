<ng-container *ngIf="trail$ | async as items">
  <div *ngIf="items?.length" class="breadcrumbs px-0">
    <div class="container-lg" *ngIf="(isHomePage | async) === false">
      <ol class="breadcrumbs-list">
        <li *ngIf="showHome" class="breadcrumbs-list">
          <a class="breadcrumbs-list-link breadcrumbs-list-link-home" rel="home" routerLink="/home">
            {{ 'camfil.common.home.link' | translate }}
          </a>
          <mat-icon aria-hidden="false" class="breadcrumbs-list-separator"> navigate_next </mat-icon>
        </li>
        <li
          *ngFor="let item of items; index as index; let last = last"
          [ngClass]="{ 'breadcrumbs-list-active': last }"
          class="breadcrumbs-list"
        >
          <a
            *ngIf="productDetail && item.link; else noProductPage"
            [routerLink]="item.link"
            class="breadcrumbs-list-link"
          >
            {{ item.text || (item.key | translate) }}
          </a>
          <ng-template #noProductPage>
            <a
              *ngIf="item.link; else noLink"
              [routerLink]="item.link"
              [queryParams]="{ filters }"
              class="breadcrumbs-list-link"
            >
              {{ item.text || (item.key | translate) }}
            </a>
          </ng-template>
          <ng-template #noLink>{{ item.text || (item.key | translate) }}</ng-template>
          <mat-icon *ngIf="!last" aria-hidden="false" class="breadcrumbs-list-separator"> navigate_next </mat-icon>
        </li>
      </ol>
    </div>
  </div>
</ng-container>
