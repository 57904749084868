import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { CamfilUserService } from 'camfil-core/services/user/camfil-user.service';
import { concatMap, map, mergeMap } from 'rxjs/operators';

import { displaySuccessMessage } from 'ish-core/store/core/messages';
import { mapErrorToAction, mapToPayloadProperty } from 'ish-core/utils/operators';

import {
  applyForAnAccount,
  applyForAnAccountFail,
  applyForAnAccountSuccess,
  requestUsernameReminder,
  requestUsernameReminderFail,
  requestUsernameReminderSuccess,
} from './camfil-user.actions';

@Injectable()
export class CamfilUserEffects {
  constructor(private actions$: Actions, private userService: CamfilUserService) {}

  requestUsernameReminder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(requestUsernameReminder),
      mapToPayloadProperty('data'),
      concatMap(data =>
        this.userService
          .requestUsernameReminder(data)
          .pipe(map(requestUsernameReminderSuccess), mapErrorToAction(requestUsernameReminderFail))
      )
    )
  );

  applyForAnAccount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(applyForAnAccount),
      mapToPayloadProperty('user'),
      concatMap(newUser =>
        this.userService.applyForAnAccount(newUser).pipe(
          mergeMap(user => [
            applyForAnAccountSuccess({ user }),
            displaySuccessMessage({
              message: 'camfil.register.form.valid.text',
            }),
          ]),
          mapErrorToAction(applyForAnAccountFail)
        )
      )
    )
  );
}
