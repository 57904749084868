/* eslint-disable ish-custom-rules/ban-imports-file-pattern */
import { CamfilMaintenanceWindow } from 'camfil-models/camfil-configuration/camfil-configuration.model';
import { formatISO } from 'date-fns';
import { camelCase, isArray, isPlainObject } from 'lodash-es';

/**
 * Interpolate params in string template.
 * @param template
 * @param params
 */
export const interpolateParams = (
  template: string,
  params: Record<string, string | number | string[] | number[]> = {}
) =>
  Object.entries(params).reduce((res, [key, value]) => {
    const mainRe = new RegExp(`({{\\s*${key}\\s*}})`, 'g');
    const escapeRe = new RegExp(`\\\\({{\\s*${key}\\s*}})`, 'g');
    return res.replace(mainRe, value.toString()).replace(escapeRe, '$1');
  }, template);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isObject(value: any): value is Record<string, any> {
  // eslint-disable-next-line unicorn/no-null
  return typeof value === 'object' && value !== null && !Array.isArray(value);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function isDate(value: any): value is Date {
  return value instanceof Date;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any, unicorn/no-null
type PayloadFormatter = (obj: any) => any;

const defaultFormatters: PayloadFormatter[] = [formatDateDeep];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function formatPayload<T>(payload: T, extraFormatters: PayloadFormatter[] = []): any {
  const combinedFormatters = [...defaultFormatters, ...extraFormatters];

  // eslint-disable-next-line unicorn/no-null
  if (typeof payload !== 'object' || payload === null) {
    return payload;
  }

  return combinedFormatters.reduce((currentPayload, formatter) => formatter(currentPayload), payload);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function formatDateDeep(obj: any): any {
  if (isDate(obj)) {
    return formatISO(obj, { representation: 'date' });
  } else if (Array.isArray(obj)) {
    return obj.map(formatDateDeep);
  } else if (isObject(obj)) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const formattedObj: Record<string, any> = {};
    Object.keys(obj).forEach(key => {
      formattedObj[key] = formatDateDeep(obj[key]);
    });
    return formattedObj;
  }
  return obj;
}

function formatDateTimeFromUTC(dateTime: string, timeZone: string): Date {
  return new Date(
    new Intl.DateTimeFormat('en-US', {
      timeZone,
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false,
    })
      .format(new Date(dateTime))
      .replace(/(\d+)\/(\d+)\/(\d+), (\d+):(\d+):(\d+)/, '$3-$1-$2T$4:$5:$6')
  );
}

const dateTimeRegex = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})(\.\d{3})?Z$/;

function isValidDateTimeString(dateTime: string): boolean {
  return dateTimeRegex.test(dateTime);
}

function convertToLocalTime(dateTime: string, timeZone: string): Date {
  return formatDateTimeFromUTC(dateTime, timeZone);
}

export function isWithinWindows(currentDate: Date, windows: CamfilMaintenanceWindow[], timeZone: string): boolean {
  if (!currentDate || !timeZone || !windows || windows.length === 0) {
    return false;
  }

  return windows.some(window => {
    if (!isValidDateTimeString(window.startDateTimeUTC) || !isValidDateTimeString(window.endDateTimeUTC)) {
      throw new Error('Invalid date-time format in maintenance windows');
    }
    const startDate = convertToLocalTime(window.startDateTimeUTC, timeZone);
    const endDate = convertToLocalTime(window.endDateTimeUTC, timeZone);
    return currentDate >= startDate && currentDate < endDate;
  });
}

interface CamelCaseKeysOptions {
  deep?: boolean;
  exclude?: string[];
  stopPaths?: string[];
}

type PlainObject = { [key: string]: unknown };

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function isPlainObjectWithType(value: any): value is PlainObject {
  return isPlainObject(value);
}

export function camelCaseKeys<T extends PlainObject>(
  obj: T,
  options: CamelCaseKeysOptions = {},
  currentPath: string[] = []
): T {
  const { deep = true, exclude = [], stopPaths = [] } = options;

  if (isArray(obj)) {
    return obj.map((v, index) =>
      camelCaseKeys(v as PlainObject, options, [...currentPath, String(index)])
    ) as unknown as T;
  } else if (isPlainObjectWithType(obj)) {
    return Object.keys(obj).reduce((result, key) => {
      const newPath = [...currentPath, key].join('.');
      if (stopPaths.includes(newPath)) {
        result[key] = obj[key];
        return result;
      }
      const camelKey = exclude.includes(key) ? key : camelCase(key);
      result[camelKey] = deep ? camelCaseKeys(obj[key] as PlainObject, options, [...currentPath, key]) : obj[key];
      return result;
      // eslint-disable-next-line ish-custom-rules/no-object-literal-type-assertion
    }, {} as PlainObject) as T;
  }
  return obj;
}
