import { CamfilOrderLineItem } from 'camfil-models/camfil-order-line-item/camfil-order-line-item.model';

import { Address } from 'ish-core/models/address/address.model';

import { CamfilOrderAdditionalTotalCost } from '../camfil-order-additional-total-cost/camfil-order-additional-total-cost.model';
import { CamfilOrderDeliveryAddress } from '../camfil-order-delivery-address/camfil-order-delivery-address.model';
import { CamfilOrderTrackAndTrace } from '../camfil-order-track-and-trace/camfil-order-track-and-trace.model';
import { CamfilSurcharge } from '../camfil-surcharge/camfil-surcharge.model';

import { CamfilOredrRebates } from './camfil-order.interface';

export enum CamfilOrderType {
  ORDER = 'ORDER', // Order (Fallback)
  RFQ = 'RFQ', //  Request for Quotation
  QC = 'QC', //  Quotation Confirmed
  QR = 'QR', // Quotation Rejected
}

export enum CamfilOrderStatus {
  Created = 'Created',
  Received = 'Received',
  Confirmed = 'Confirmed',
  Delivered = 'Delivered',
  Invoiced = 'Invoiced',
  PartInvoiced = 'PartInvoiced',
  Other = 'NoKnownStatus',
}

export interface CamfilOrder {
  id: string;
  contactPerson?: string;
  currency?: string;
  customerName?: string;
  customerDepartment?: string;
  customerNo?: string;
  customerOrderNumber?: string;
  deliveryDate?: number;
  deliveryAddress: CamfilOrderDeliveryAddress;
  camfilNo?: string;
  ishOrderUUID?: string;
  orderChannel?: string;
  orderComment?: string;
  orderDate?: string;
  orderGoodsMark?: string;
  orderStatus?: string;
  orderNumber?: string;
  taxAmount?: number;
  totalCustomerPriceSum: number;
  totalDeliveredQty?: number;
  totalOrderedQty?: number;
  totalPriceAfterDiscountExVAT: number;
  lineItems?: CamfilOrderLineItem[];
  trackAndTrace?: CamfilOrderTrackAndTrace;
  additionalTotalCost?: CamfilOrderAdditionalTotalCost[];
  phoneNotification: string;
  volumeDiscount: number;
  itemSurchargeTotalsByType: CamfilSurcharge[];
  bucketSurchargeTotalsByType: CamfilSurcharge[];
  goodsAcceptanceNote: string;
  // UI
  deliveryDates?: number[];
  isPartialDelivery?: boolean;
  canReOrder?: boolean;
  commonDeliveryAddress?: Address;
  rebates?: CamfilOredrRebates[];
}
