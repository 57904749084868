import { ChangeDetectionStrategy, Component, DestroyRef, HostListener, OnInit, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CamfilQueueFacade } from 'camfil-core/facades/camfil-queue.facade';
import { BehaviorSubject, filter, map } from 'rxjs';

import { whenTruthy } from 'ish-core/utils/operators';

@Component({
  selector: 'camfil-queue',
  templateUrl: './camfil-queue.component.html',
  styleUrls: ['./camfil-queue.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CamfilQueueComponent implements OnInit {
  private destroyRef = inject(DestroyRef);
  private showQueueSubject = new BehaviorSubject<boolean>(false);
  private expandQueueSubject = new BehaviorSubject<boolean>(false);
  private pageClassNameSubject = new BehaviorSubject<string>('');

  constructor(
    private camfilQueueFacade: CamfilQueueFacade,
    private translateService: TranslateService,
    private router: Router
  ) {}

  taskGroups$ = this.camfilQueueFacade.taskGroups$;
  hasRemainingTasks$ = this.camfilQueueFacade.hasRemainingTasks$;
  showQueue$ = this.showQueueSubject.asObservable();
  expandQueue$ = this.expandQueueSubject.asObservable();
  pageClassName$ = this.pageClassNameSubject.asObservable();

  @HostListener('window:beforeunload', ['$event'])
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  unloadNotification($event: BeforeUnloadEvent): string | void {
    if (this.camfilQueueFacade.isProcessing) {
      const message = this.translateService.instant('camfil.queue.warning.unfinished_tasks.message');
      $event.returnValue = message;
      return message;
    }
  }

  ngOnInit() {
    this.hasRemainingTasks$.pipe(whenTruthy(), takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      this.showQueue();
      this.expandQueue();
    });

    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        map(({ url }: NavigationEnd) => this.getClassFromPath(url)),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(classText => {
        this.pageClassNameSubject.next(classText);
      });
  }

  getClassFromPath(url: string) {
    const i = url.indexOf('?');
    const index = i === -1 ? undefined : i;
    return url.substring(0, index).split('/').filter(Boolean).join('_');
  }

  clearQueues() {
    this.camfilQueueFacade.clearQueues();
  }

  showQueue() {
    this.showQueueSubject.next(true);
  }

  hideQueue() {
    this.showQueueSubject.next(false);
    this.clearQueues();
  }

  expandQueue() {
    this.expandQueueSubject.next(true);
  }

  collapseQueue() {
    this.expandQueueSubject.next(false);
  }

  retryFailedTasksByType(type: string) {
    this.camfilQueueFacade.retryFailedTasksByType(type);
  }
}
