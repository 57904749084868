import { Injectable } from '@angular/core';
import { Validators } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { CamfilConfigurationFacade } from 'camfil-core/facades/camfil-configuration.facade';

import { FieldLibraryConfiguration } from 'ish-shared/formly/field-library/configurations/field-library-configuration';

@Injectable()
export class CamCardNameConfiguration extends FieldLibraryConfiguration {
  constructor(private camfilConfigurationFacade: CamfilConfigurationFacade) {
    super();
  }

  id = 'camCardNameFLC';

  getFieldConfig(): FormlyFieldConfig {
    return {
      key: 'name',
      type: 'camfil-text-input-field',
      props: {
        label: 'camfil.account.cam_card.add_to_form.camcards_name',
        required: true,
      },
      validation: {
        messages: {
          required: 'camfil.modal.createCamcard.input.name.error.required',
        },
      },
      hooks: {
        onInit: (field: FormlyFieldConfig) => {
          this.camfilConfigurationFacade.maxLength$('camCardTitle').subscribe(maxLength => {
            field.formControl.setValidators([Validators.maxLength(maxLength)]);
          });
        },
      },
    };
  }
}
