import { ChangeDetectionStrategy, Component, DestroyRef, Input, OnInit, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup } from '@angular/forms';
import { CamRequisitionManagementFacade } from 'camfil-core/facades/cam-requisition-management.facade';
import { CamfilLineItem } from 'camfil-models/camfil-line-item/camfil-line-item.model';
import {
  VariationProductMasterView,
  VariationProductView,
} from 'camfil-models/camfil-product-view/camfil-product-view.model';
import { CamfilRequisition } from 'camfil-models/camfil-requisition/camfil-requisition.model';
import { debounceTime } from 'rxjs/operators';

import { ProductView } from 'ish-core/models/product-view/product-view.model';
import { ProductHelper } from 'ish-core/models/product/product.model';

@Component({
  selector: 'camfil-requisition-line-item-quantity',
  templateUrl: './camfil-requisition-line-item-quantity.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CamfilRequisitionLineItemQuantityComponent implements OnInit {
  @Input() product: ProductView | VariationProductView | VariationProductMasterView;
  @Input() lineItem: CamfilLineItem;
  @Input() isEditable = false;
  @Input() requisition: CamfilRequisition;
  productItemForm: FormGroup;
  isMasterProduct = ProductHelper.isMasterProduct;
  updatedQuantity: number;

  readonly quantityControlName = 'quantity';

  private destroyRef = inject(DestroyRef);

  constructor(private camRequisitionManagementFacade: CamRequisitionManagementFacade) {}

  ngOnInit() {
    this.productItemForm = new FormGroup({
      [this.quantityControlName]: new FormControl(this.lineItem?.quantity?.value || 1, { updateOn: 'blur' }),
    });

    this.productItemForm
      .get(this.quantityControlName)
      ?.valueChanges.pipe(debounceTime(500), takeUntilDestroyed(this.destroyRef))
      .subscribe(quantity => {
        const { minOrderQuantity } = this.product;

        if (quantity < minOrderQuantity) {
          return;
        }

        // maxOrderQuantity is not present in product data
        // if (quantity > maxOrderQuantity) {
        //   return;
        // }

        const lineItemUpdate = {
          lineItemId: this.lineItem.id,
          quantity,
        };

        if (this.productItemForm.get(this.quantityControlName)?.value !== this.lineItem?.quantity?.value) {
          this.camRequisitionManagementFacade.updateCamfilRequisitionLineItem(this.requisition.id, lineItemUpdate);
        }
      });
  }
}
