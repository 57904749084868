import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { FilterNavigationData } from 'ish-core/models/filter-navigation/filter-navigation.interface';
import { FilterNavigation } from 'ish-core/models/filter-navigation/filter-navigation.model';
import { FilterService } from 'ish-core/services/filter/filter.service';
import { omit } from 'ish-core/utils/functions';
import { URLFormParams, appendFormParamsToHttpParams } from 'ish-core/utils/url-form-params';

/* eslint-disable @typescript-eslint/dot-notation */

@Injectable({ providedIn: 'root' })
export class CamfilFilterService extends FilterService {
  static sanitizeSearchTerm(searchTerm: string): string {
    // Note: The ICM API does not find any results when searchTerm contains a slash so we cut it off.
    // @see #63128
    try {
      return searchTerm?.toLowerCase()?.split('/')?.[0];
    } catch (e) {
      // Handle error
      return searchTerm;
    }
  }

  static sanitizeParams(params: HttpParams) {
    return params.keys().reduce((newParams, key) => {
      if (key === 'searchTerm') {
        return newParams.set(key, CamfilFilterService.sanitizeSearchTerm(params.get(key)));
      } else {
        return newParams.set(key, params.get(key));
      }
    }, new HttpParams());
  }

  override getFilterForSearch(searchTerm: string): Observable<FilterNavigation> {
    const sanitizedSearchTerm = CamfilFilterService.sanitizeSearchTerm(searchTerm);
    return super.getFilterForSearch(sanitizedSearchTerm);
  }

  override applyFilter(searchParameter: URLFormParams): Observable<FilterNavigation> {
    const params = appendFormParamsToHttpParams(omit(searchParameter, 'category'));
    const sanitizedParams = CamfilFilterService.sanitizeParams(params);

    const resource = searchParameter.category
      ? `categories/${searchParameter.category[0]}/productfilters`
      : 'productfilters';

    return this['apiService']
      .get<FilterNavigationData>(resource, { params: sanitizedParams, sendSPGID: true })
      .pipe(map(filter => this['filterNavigationMapper'].fromData(filter)));
  }
}
