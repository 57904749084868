<input
  *ngIf="type !== 'number'; else numberTmp"
  matInput
  [matAutocomplete]="auto"
  [id]="id"
  [name]="field.name"
  [type]="type || 'text'"
  [readonly]="props.readonly"
  [required]="required"
  [errorStateMatcher]="errorStateMatcher"
  [formControl]="formControl"
  [formlyAttributes]="field"
  [tabIndex]="props.tabindex"
  [placeholder]="props.placeholder"
/>

<ng-template #numberTmp>
  <input
    matInput
    [id]="id"
    [matAutocomplete]="auto"
    [name]="field.name"
    type="number"
    [readonly]="props.readonly"
    [required]="required"
    [errorStateMatcher]="errorStateMatcher"
    [formControl]="formControl"
    [formlyAttributes]="field"
    [tabIndex]="props.tabindex"
    [placeholder]="props.placeholder"
  />
</ng-template>

<mat-autocomplete
  #auto="matAutocomplete"
  [autoActiveFirstOption]="this.props.autoActiveFirstOption"
  [autoSelectActiveOption]="this.props.autoSelectActiveOption"
  [hideSingleSelectionIndicator]="this.props.hideSingleSelectionIndicator"
  [disableRipple]="this.props.disableRipple"
  [displayWith]="this.displayWith"
  [panelWidth]="this.props.panelWidth"
>
  <mat-option *ngFor="let option of filteredOptions$ | async" [value]="option">
    {{ option }}
  </mat-option>
</mat-autocomplete>
