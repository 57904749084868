<ng-container *ngIf="promotion$ | async as promotion">
  <ng-container *ngIf="promotion && !promotion.disableMessages; else fallback">
    <div *ngIf="promotion.title" class="promotion-title" [ishServerHtml]="promotion.title"></div>
    <div class="promotion-details-and-remove-links">
      <ish-promotion-details [promotion]="promotion" />
      <ish-promotion-remove [code]="rebate.code" />
    </div>
  </ng-container>
  <ng-template #fallback>{{ 'checkout.widget.promotion.discount' | translate }}</ng-template>
</ng-container>
