import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { CamfilOrganizationManagementService } from 'camfil-core/services/organization/camfil-organization.service';
import { EMPTY, iif } from 'rxjs';
import { concatMap, filter, map } from 'rxjs/operators';

import { displayErrorMessage, displaySuccessMessage } from 'ish-core/store/core/messages';
import {
  mapErrorToAction,
  mapToPayload,
  mapToPayloadProperty,
  mapToProperty,
  whenTruthy,
} from 'ish-core/utils/operators';

import { selectCustomer } from '../b2b-customers/b2b-customers.actions';
import { getSelectedCustomerId } from '../b2b-customers/b2b-customers.selectors';
import { selectUser } from '../b2b-users/b2b-users.actions';

import {
  loadCustomerRoles,
  loadCustomerRolesFail,
  loadCustomerRolesSuccess,
  loadCustomerUserRoles,
  loadCustomerUserRolesFail,
  loadCustomerUserRolesSuccess,
  updateCustomerUserRoles,
  updateCustomerUserRolesFail,
  updateCustomerUserRolesSuccess,
} from './b2b-roles.actions';

@Injectable()
export class B2bRolesEffects {
  constructor(
    private actions$: Actions,
    private managementService: CamfilOrganizationManagementService,
    private store: Store
  ) {}

  loadCustomerRolesForSelectedCustomerId$ = createEffect(() =>
    iif(
      () => !SSR,
      this.actions$.pipe(
        ofType(selectCustomer),
        mapToPayloadProperty('customerId'),
        whenTruthy(),
        map(customerId => loadCustomerRoles({ customerId }))
      ),
      EMPTY
    )
  );

  loadCustomerRoles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadCustomerRoles),
      mapToPayloadProperty('customerId'),
      whenTruthy(),
      concatMap(customerId =>
        this.managementService.getCustomerRoles(customerId).pipe(
          map(roles => loadCustomerRolesSuccess({ customerId, roles })),
          mapErrorToAction(loadCustomerRolesFail, { customerId })
        )
      )
    )
  );

  loadCustomerUserRolesForSelectedCustomerIdAndSelectedUserId$ = createEffect(() =>
    iif(
      () => !SSR,
      this.actions$.pipe(
        ofType(selectUser),
        mapToPayloadProperty('userId'),
        whenTruthy(),
        concatLatestFrom(() => this.store.pipe(select(getSelectedCustomerId), whenTruthy())),
        map(([userId, customerId]) => loadCustomerUserRoles({ customerId, userId }))
      ),
      EMPTY
    )
  );

  loadCustomerUserRoles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadCustomerUserRoles),
      mapToPayload(),
      concatMap(({ customerId, userId }) =>
        this.managementService.getCustomerUserRoles(customerId, userId).pipe(
          map(roles => loadCustomerUserRolesSuccess({ customerId, userId, roles })),
          mapErrorToAction(loadCustomerUserRolesFail, { customerId, userId })
        )
      )
    )
  );

  updateCustomerUserRoles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateCustomerUserRoles),
      mapToPayload(),
      concatMap(({ customerId, userId, roleIDs }) =>
        this.managementService.updateCustomerUserRoles(customerId, userId, roleIDs).pipe(
          map(roles =>
            updateCustomerUserRolesSuccess({
              customerId,
              userId,
              roles,
              successMessage: 'camfil.account.organization.edit_user.update_customer_user_roles_success.modal.text',
            })
          ),
          mapErrorToAction(updateCustomerUserRolesFail, { customerId, userId })
        )
      )
    )
  );

  displayUpdateCustomerUserRolesSuccessMessage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateCustomerUserRolesSuccess),
      mapToPayload(),
      mapToProperty('successMessage'),
      filter(successMessage => !!successMessage),
      map(successMessage =>
        displaySuccessMessage({
          message: successMessage,
        })
      )
    )
  );

  displayUpdateCustomerUserRolesFailMessage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateCustomerUserRolesFail),
      mapToPayloadProperty('error'),
      whenTruthy(),
      map(error =>
        displayErrorMessage({
          message: error?.message || error?.code,
        })
      )
    )
  );
}
