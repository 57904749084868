<ng-container *ngIf="logoutOnly; else loginStatus">
  <ng-container *ngIf="user$ | async as user; else notLoggedIn">
    <a data-testing-id="link-logout" routerLink="/logout">
      <mat-icon class="header-icon login-icon" svgIcon="profile-fab" />
      <span> {{ 'account.logout.link' | translate }} </span>
    </a>
  </ng-container>
  <ng-template #notLoggedIn> &nbsp;</ng-template>
</ng-container>

<ng-template #loginStatus>
  <!-- logged in -->
  <ng-container *ngIf="user$ | async as user; else notLoggedIn">
    <div class="account-links">
      <a class="my-account-link" data-testing-id="link-myaccount" routerLink="/account">
        <mat-icon class="header-icon login-icon" svgIcon="profile-fab" />
        <span *ngIf="user.lastName; else nameNotDisplayed" class="login-name">
          {{ 'camfil.account.login_status.my_pages' | translate }}
        </span>
        <ng-template #nameNotDisplayed>
          <span class="login-name">
            {{ 'account.login_status.myaccount.link' | translate }}
          </span>
        </ng-template>
      </a>
      <span class="separator">&#124;</span>
      <a class="my-account-link my-account-logout" data-testing-id="link-logout" routerLink="/logout">
        <span>
          {{ 'account.logout.link' | translate }}
        </span>
      </a>
    </div>
  </ng-container>
  <!-- not logged in -->
  <ng-template #notLoggedIn>
    <div class="account-links">
      <a
        class="my-account-link my-account-login"
        rel="nofollow"
        routerLink="/login"
        [queryParams]="{ returnUrl: returnUrl$ | async }"
      >
        <mat-icon class="header-icon login-icon" svgIcon="profile-fab" />
        <span>{{ 'account.login.link' | translate }}</span>
      </a>
      <span class="separator">&#124;</span>
      <a
        [queryParams]="{ returnUrl: '/account' }"
        class="my-account-link my-account-register"
        rel="nofollow"
        routerLink="/register"
      >
        <span>{{ 'account.register.link' | translate }}</span>
      </a>
    </div>
  </ng-template>
</ng-template>
