import { DOCUMENT } from '@angular/common';
import { Inject, InjectionToken, NgModule, Optional, Renderer2, RendererFactory2, SkipSelf } from '@angular/core';
import { CamfilSvgIconRegistryService } from 'camfil-generated/icons/camfil-svg-icon-registry.service';
import { CamfilChannelConfiguration } from 'camfil-models/camfil-configuration/camfil-configuration.model';
import { Observable } from 'rxjs';

import { InjectSingle } from 'ish-core/utils/injection';

type CamfilUIConfig = Pick<CamfilChannelConfiguration, 'useMaterialTypography'>;

export const CAMFIL_UI_CONFIG = new InjectionToken<Observable<CamfilUIConfig>>('CamfilUIModule Configuration');

@NgModule({})
export class CamfilAppearanceModule {
  static config$: Observable<CamfilUIConfig>;
  private renderer: Renderer2;

  constructor(
    @Optional() @SkipSelf() parentModule: CamfilAppearanceModule,
    @Inject(CAMFIL_UI_CONFIG) config$: InjectSingle<typeof CAMFIL_UI_CONFIG>,
    @Inject(DOCUMENT) private document: Document,
    private rendererFactory: RendererFactory2,
    private camfilSvgIconRegistryService: CamfilSvgIconRegistryService
  ) {
    if (parentModule) {
      throw new Error('CamfilAppearanceModule is already loaded. Import it in the CamfilCoreModule only');
    }

    this.renderer = this.rendererFactory.createRenderer(undefined, undefined);

    this.camfilSvgIconRegistryService.registerIcons();

    CamfilAppearanceModule.config$ = config$;
    CamfilAppearanceModule?.config$?.subscribe(config => {
      this.setupFonts(config);
    });
  }

  private setupFonts(config: CamfilUIConfig) {
    if (config.useMaterialTypography) {
      this.document.body.classList.add('mat-typography');
    }

    const preconnectLink = this.renderer.createElement('link');

    this.renderer.setAttribute(preconnectLink, 'name', 'preconnect');
    this.renderer.setAttribute(preconnectLink, 'href', 'https://fonts.gstatic.com');
    this.renderer.appendChild(this.document.head, preconnectLink);

    const fonts = [
      'https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700&display=swap',
      'https://fonts.googleapis.com/icon?family=Material+Icons',
      'https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300;400;700&display=swap',
      'https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@300;400;700&display=swap',
    ];

    fonts.forEach(font => {
      const fontLink = this.renderer.createElement('link');
      this.renderer.setAttribute(fontLink, 'name', 'link');
      this.renderer.setAttribute(fontLink, 'href', font);
      this.renderer.setAttribute(fontLink, 'rel', 'stylesheet');
      this.renderer.appendChild(this.document.head, fontLink);
    });
  }
}
