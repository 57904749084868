<div camfilDialogContent>
  <button (click)="hide()" aria-label="Close" class="close">
    <mat-icon aria-hidden="true" svgIcon="inactive" />
  </button>

  <h2 camfilDialogTitle class="text-no-transform mt-0">
    {{ 'camfil.quotes.quoteslist.dialog.edit_quote.header' | translate }}
  </h2>

  <form [formGroup]="form.instance" (ngSubmit)="submitForm()">
    <formly-form [form]="form.instance" [model]="form.model$ | async" [fields]="form.fields$ | async" />
  </form>

  <div camfilDialogActions class="text-center mt-4">
    <button
      mat-button
      mat-flat-button
      type="button"
      color="primary"
      [disabled]="!form?.formValid"
      (click)="submitForm()"
    >
      {{ 'camfil.modal.edit_order.button.save' | translate }}
    </button>
  </div>
  <br />
</div>
