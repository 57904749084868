import { createAction } from '@ngrx/store';
import { CamfilConfigurationState } from 'camfil-core/store/camfil-configuration/camfil-configuration.reducer';

import { httpError, payload } from 'ish-core/utils/ngrx-creators';

export const loadConfiguration = createAction('[Camfil Configuration] Load Configuration');

export type ConfigurationType = Partial<CamfilConfigurationState>;

export const loadConfigurationSuccess = createAction(
  '[Camfil Configuration] Load Configuration Success',
  payload<{ configuration: ConfigurationType }>()
);

export const loadConfigurationFailure = createAction('[Camfil Configuration] Load Configuration Failure', httpError());
