<div *ngIf="fragmentOnRouting === 'product-list-top'" id="product-list-top"></div>
<ng-container *ngIf="productListingView$ | async as listing">
  <ng-container *ngIf="!onlyList; else list">
    <div
      *ngIf="isEndlessScrolling"
      infiniteScroll
      (scrolled)="loadMoreProducts('down')"
      (scrolledUp)="loadMoreProducts('up')"
      [alwaysCallback]="true"
      [immediateCheck]="true"
    >
      <camfil-product-list-toolbar
        [itemCount]="listing.itemCount"
        [sortableAttributes]="listing.sortableAttributes"
        [viewType]="viewType$ | async"
        [sortBy]="sortBy$ | async"
        [categoryName]="category ? category.name : null"
        [fragmentOnRouting]="fragmentOnRouting"
      />

      <camfil-product-list [products]="listing.products()" [category]="category" [viewType]="viewType$ | async" />
    </div>

    <div *ngIf="isPaging">
      <ng-container *ngIf="currentPage$ | async as currentPage">
        <ng-template #pagingToolbar>
          <camfil-product-list-toolbar
            [itemCount]="listing.itemCount"
            [sortableAttributes]="listing.sortableAttributes"
            [viewType]="viewType$ | async"
            [sortBy]="sortBy$ | async"
            [currentPage]="currentPage"
            [pageIndices]="listing.pageIndices(currentPage)"
            [fragmentOnRouting]="fragmentOnRouting"
            [isPaging]="true"
          />
        </ng-template>

        <ng-container *ngTemplateOutlet="pagingToolbar" />

        <camfil-product-list
          [products]="listing.productsOfPage(currentPage)"
          [category]="category"
          [viewType]="viewType$ | async"
        />

        <ng-container *ngTemplateOutlet="pagingToolbar" />
      </ng-container>
    </div>
  </ng-container>

  <ng-template #list>
    <ng-container *ngIf="listing.itemCount">
      <camfil-product-list [products]="listing.products()" viewType="simple" [limit]="limitItemsOnList" />
    </ng-container>
  </ng-template>
</ng-container>
