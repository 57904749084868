import { OnDestroy, Pipe, PipeTransform } from '@angular/core';
import { CamfilQueueFacade } from 'camfil-core/facades/camfil-queue.facade';
import { Observable, Subscription, combineLatest, isObservable, map, of } from 'rxjs';

/**
 * The pipe which suppose to override the value of the input based on the queue processing status.
 * If the queue is processing, the value will be overridden to false so you can have full control on the input value.
 *
 * Important: This pipe is impure to handle internal async operations. Make sure to always use the async pipe when using this pipe.
 *
 * @example
 * <button [disabled]="isDisabled$ | camfilNotInQueue | async">Click me</button>
 * <button [disabled="isAdding | camfilNotInQueue | async">Click me</button>
 */
@Pipe({
  name: 'camfilNotInQueue',
  pure: true, // Marking the pipe as impure to handle internal async operations
})
export class CamfilNotInQueuePipe implements PipeTransform, OnDestroy {
  private subscription: Subscription;

  constructor(private camfilQueueFacade: CamfilQueueFacade) {}

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  transform(value: boolean | Observable<boolean>): Observable<boolean> {
    const value$ = isObservable(value) ? value : of(value);
    return combineLatest([value$, this.camfilQueueFacade.isProcessing$]).pipe(
      map(([value, isProcessing]) => (isProcessing ? false : value))
    );
  }

  ngOnDestroy() {
    if (this.subscription) {
      // eslint-disable-next-line ban/ban
      this.subscription.unsubscribe();
    }
  }
}
