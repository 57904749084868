import { ChangeDetectionStrategy, Component, DestroyRef, HostBinding, Inject, OnInit, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CamfilCheckoutFacade } from 'camfil-core/facades/camfil-checkout.facade';
import { CamfilConfigurationFacade } from 'camfil-core/facades/camfil-configuration.facade';
import { CamfilBucket } from 'camfil-models/camfil-bucket/camfil-bucket.model';
import { take } from 'rxjs';

import { markAsDirtyRecursive } from 'ish-shared/forms/utils/form-utils';

export type CamfilCheckoutGoodsAcceptanceModalData = CamfilBucket;

export type CamfilCheckoutGoodsAcceptanceModalResult = void;

@Component({
  selector: 'camfil-checkout-goods-acceptance-modal',
  templateUrl: './camfil-checkout-goods-acceptance-modal.component.html',
  styleUrls: ['./camfil-checkout-goods-acceptance-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CamfilCheckoutGoodsAcceptanceModalComponent implements OnInit {
  @HostBinding('class.camfil-dialog') camfilDialogClass = true;

  goodsAcceptanceTimeForm: FormGroup;

  private destroyRef = inject(DestroyRef);

  constructor(
    private camfilConfigurationFacade: CamfilConfigurationFacade,
    private camfilCheckoutFacade: CamfilCheckoutFacade,
    private fb: FormBuilder,
    private matDialogRef: MatDialogRef<
      CamfilCheckoutGoodsAcceptanceModalComponent,
      CamfilCheckoutGoodsAcceptanceModalResult
    >,
    // eslint-disable-next-line ish-custom-rules/use-type-safe-injection-token
    @Inject(MAT_DIALOG_DATA) public dialogData: CamfilCheckoutGoodsAcceptanceModalData
  ) {}

  private initForm() {
    this.camfilConfigurationFacade
      .isEnabled$('goodsAcceptanceTimeMandatory')
      .pipe(take(1), takeUntilDestroyed(this.destroyRef))
      .subscribe(isMandatory => {
        this.goodsAcceptanceTimeForm = this.fb.group({
          goodsAcceptanceNote: [
            this.dialogData.deliveryAddress.goodsAcceptanceNote,
            isMandatory ? [Validators.required] : [],
          ],
        });
      });
  }

  ngOnInit() {
    this.initForm();
  }

  getField(name: string) {
    return this.goodsAcceptanceTimeForm.get(name);
  }

  submitEditGoodsAcceptanceTime() {
    if (this.goodsAcceptanceTimeForm.valid) {
      const goodsAcceptanceNote = this.goodsAcceptanceTimeForm.get('goodsAcceptanceNote').value;

      const updatedBasketAddress = {
        ...this.dialogData.deliveryAddress,
        goodsAcceptanceNote,
      };

      this.camfilCheckoutFacade.updateBucketEditMode(
        this.dialogData.basket,
        this.dialogData.deliveryAddress.id,
        { ...this.dialogData, deliveryAddress: updatedBasketAddress },
        updatedBasketAddress
      );

      this.closeDialog();
    } else {
      markAsDirtyRecursive(this.goodsAcceptanceTimeForm);
    }
  }

  closeDialog(): void {
    this.matDialogRef.close();
  }
}
