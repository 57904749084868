import { createAction } from '@ngrx/store';
import { Attribute } from 'camfil-models/camfil-attribute/attribute.model';
import { CamfilContact } from 'camfil-models/camfil-customer/camfil-customer.model';
import {
  AddCamCardToCart,
  AddProductToNewSubCamCard,
  AddToCamCardItem,
  BasicCamCard,
  CamCard,
  CamCardImportValidationResponse,
  CamCardItem,
  CamCardItemComment,
  CamCardMeasurement,
  CamCardToBasketFeedback,
} from 'projects/camfil-pwa/src/app/models/camfil-cam-card/cam-card.model';

import { Order } from 'ish-core/models/order/order.model';
import { httpError, payload } from 'ish-core/utils/ngrx-creators';

export const loadCamCardsBasicList = createAction(
  '[Camfil CamCards Internal] Load Cam Cards Basic List',
  payload<{ includeAllCustomerCamCards: boolean }>()
);

export const loadCamCardsBasicListSuccess = createAction(
  '[Camfil CamCards Internal] Load Cam Cards Basic List Success',
  payload<{
    camCards: BasicCamCard[];
    includeAllCustomerCamCards: boolean;
  }>()
);

export const refreshCamCards = createAction('[Camfil CamCards Internal] Refresh Cam Cards Basic List');

export const loadCamCardsIfNotLoaded = createAction(
  '[Camfil CamCards Internal] Load Cam Cards If Not Loaded',
  payload<{ includeAllCustomerCamCards: boolean }>()
);

export const loadCamCardsSuccess = createAction(
  '[Camfil CamCards API] Load Cam Cards Success',
  payload<{
    camCards: CamCard[];
    includeAllCustomerCamCards?: boolean;
  }>()
);

export const loadCamCardsFail = createAction('[Camfil CamCards API] Load Cam Cards Fail', httpError());

export const createCamCard = createAction(
  '[Camfil CamCards] Create Cam Card',
  payload<{ camCard: CamCard; newItems?: AddToCamCardItem[] }>()
);

export const createVirtualCamCard = createAction(
  '[Camfil CamCards] Create Virtual Cam Card',
  payload<{ camCard: CamCard }>()
);

export const createVirtualCamCardSuccess = createAction(
  '[Camfil CamCards] Create Virtual Cam Card Success',
  payload<{ camCard: CamCard }>()
);

export const createVirtualCamCardFail = createAction('[Camfil CamCards] Create Virtual Cam Card Fail');

export const clearVirtualCamCard = createAction('[Camfil CamCards] Clear Virtual Cam Card');

export const createSubCamCard = createAction(
  '[Camfil CamCards] Create Sub Cam Card',
  payload<{ subCamCard: CamCard; rootCamCardId: string }>()
);
export const loadCamCardIfNotLoaded = createAction(
  '[Camfil CamCards API] Load Cam Card If Not Loaded',
  payload<{ camCardId: string }>()
);

export const loadCamCardFail = createAction(
  '[Camfil CamCards API] Load Cam Card Fail',
  httpError<{ camCardId: string }>()
);

export const loadCamCard = createAction('[Camfil CamCards API] Load Cam Card', payload<{ camCardId: string }>());

export const loadCamCardSuccess = createAction(
  '[Camfil CamCards API] Load Cam Card Success',
  payload<{ camCard: CamCard }>()
);

export const createCamCardSuccess = createAction(
  '[Camfil CamCards API] Create Cam Card Success',
  payload<{ camCard: CamCard }>()
);

export const createCamCardFail = createAction('[Camfil CamCards API] Create Cam Card Fail', httpError());

export const updateCamCard = createAction('[Camfil CamCards] Update Cam Card', payload<{ camCard: CamCard }>());

export const updateCamCardSuccess = createAction(
  '[Camfil CamCards API] Update Cam Card Success',
  payload<{ camCard: CamCard }>()
);

export const cloneAndEditCamCard = createAction(
  '[Camfil CamCards API] Clone And Update Cam Card',
  payload<{ camCardId: string; camCardName: string }>()
);

export const updateSubCamCardFail = createAction('[Camfil CamCards API] Update Sub Cam Card Fail', httpError());

export const updateSubCamCard = createAction('[Camfil CamCards] Update Sub Cam Card', payload<{ sub: CamCard }>());

export const updateSubCamCardSuccess = createAction(
  '[Camfil CamCards API] Update Sub Cam Card Success',
  payload<{ camCard: CamCard }>()
);

export const updateCamCardFail = createAction('[Camfil CamCards API] Update Cam Card Fail', httpError());

export const deleteCamCard = createAction('[Camfil CamCards] Delete Cam Card', payload<{ camCardId: string }>());

export const deleteCamCardSuccess = createAction(
  '[Camfil CamCards API] Delete Cam Card Success',
  payload<{ camCardId: string }>()
);

export const deleteCamCardFail = createAction('[Camfil CamCards API] Delete Cam Card Fail', httpError());

export const deleteSubCamCard = createAction(
  '[Camfil CamCards] Delete Sub Cam Card',
  payload<{ rootId: string; id: string }>()
);

export const deleteSubCamCardSuccess = createAction(
  '[Camfil CamCards API] Delete Sub Cam Card Success',
  payload<{ camCard: CamCard }>()
);

export const deleteSubCamCardFail = createAction('[Camfil CamCards API] Delete Sub Cam Card Fail', httpError());

export const copyCamCard = createAction(
  '[Camfil CamCards] Copy CamCard',
  payload<{ camCardId: string; name: string }>()
);

export const copyCamCardSuccess = createAction(
  '[Camfil CamCards] Copy CamCard Success',
  payload<{ camCard: CamCard }>()
);

export const copyCamCardFail = createAction('[Camfil CamCards API] copy Cam Card Fail', httpError());

export const addProductToCamCard = createAction(
  '[Camfil CamCards] Add Item to Cam Card',
  payload<{
    camCardId: string;
    sku: string;
    quantity?: number;
    position?: number;
    comment?: CamCardItemComment;
    measurement?: CamCardMeasurement;
    showSuccessToast?: boolean;
  }>()
);

export const addProductToSubCamCard = createAction(
  '[Camfil CamCards] Add Item to Sub Cam Card',
  payload<{
    camCardId: string;
    refreshCamCardId: string;
    sku: string;
    quantity?: number;
    boxLabel?: string;
    measurement?: CamCardMeasurement;
  }>()
);

export const addProductToNewSubCamCard = createAction(
  '[Camfil CamCards] Add Product To New Sub Cam Card',
  payload<AddProductToNewSubCamCard>()
);

export const updateCamCardProduct = createAction(
  '[Camfil CamCards] Update Cam Card Product',
  payload<{ rootCamCard: string; camCardId: string; camCardItem: CamCardItem; forceUpdateCamCard?: boolean }>()
);

export const updateCamCardProcessing = createAction(
  '[Camfil CamCards] Update Cam Card Processing',
  payload<{ processing: boolean }>()
);

export const updateCamCardProductSuccess = createAction(
  '[Camfil CamCards API] Update Cam Card Product Success',
  payload<{ rootCamCard: string; camCardId: string; camCardItem: CamCardItem }>()
);

export const moveProductOnCamCardSuccess = createAction(
  '[Camfil CamCards API] MOve Cam Card Product Success',
  payload<{ camCard: CamCard; camCardId?: string; rootCamCard?: string }>()
);

export const addProductToCamCardSuccess = createAction(
  '[Camfil CamCards API] Add Item to Cam Card Success',
  payload<{ camCard: CamCard }>()
);

export const addProductToCamCardFail = createAction('[Camfil CamCards API] Add Item to Cam Card Fail', httpError());

export const addProductToNewCamCard = createAction(
  '[Camfil CamCards Internal] Add Product To New Cam Card',
  payload<{ name: string; sku: string; quantity?: number }>()
);

export const addToNewCamCardWithNewSubCamCard = createAction(
  '[Camfil CamCards Internal] Add To New Cam Card With New Sub Cam Card',
  payload<{
    newCamCard: CamCard;
    newSubCamCard: CamCard;
    sku: string;
    quantity?: number;
    boxLabel?: string;
    measurement?: CamCardMeasurement;
    edit?: boolean;
  }>()
);

export const addProductToNewCamCardAndEdit = createAction(
  '[Camfil CamCards Internal] Add Product To Cam Card And Update',
  payload<{
    camCard: CamCard;
    sku: string;
    quantity?: number;
    boxLabel?: string;
    measurement?: CamCardMeasurement;
    edit?: boolean;
  }>()
);

export const editCamCard = createAction('[Camfil CamCards Internal] Edit Cam Card', payload<{ camCardId?: string }>());

export const loadCamCardsEdit = createAction('[Camfil CamCard Internal] Load Cam Cards Edit');

export const updateCamCardContacts = createAction(
  '[Camfil CamCards] Update Cam Card Contacts',
  payload<{ camCardId: string; camCardContacts: CamfilContact[] }>()
);

export const updateCamCardContactsSuccess = createAction(
  '[Camfil CamCards API] Update Cam Card Contacts Success',
  payload<{ camCardId: string; contacts: CamfilContact[] }>()
);

export const updateCamCardContactsFail = createAction(
  '[Camfil CamCards API] Update Cam Card contacts Fail',
  httpError()
);

export const removeItemFromCamCard = createAction(
  '[Camfil CamCards] Remove Item from Cam Card',
  payload<{ camCardId: string; camCardItemId: string; rootCamCard?: string }>()
);

export const removeItemFromCamCardSuccess = createAction(
  '[Camfil CamCards API] Remove Item from Cam Card Success',
  payload<{ camCard: CamCard }>()
);

export const moveCamCard = createAction(
  '[Camfil CamCards] move Cam Card',
  payload<{ camCardId: string; newCustomerId: string; newContacts: CamfilContact[] }>()
);

export const moveCamCardSuccess = createAction(
  '[Camfil CamCards API] move Cam Card Success',
  payload<{ camCard: CamCard; newContacts: CamfilContact[] }>()
);

export const moveCamCardFail = createAction('[Camfil CamCards API] move Cam Card Fail', httpError());

export const updateContactsWhileMoveCamCardFail = createAction(
  '[Camfil CamCards API] updating contacts while moving Cam Card Fail',
  httpError()
);

export const selectCamCard = createAction(
  '[Camfil CamCards Internal] Select Cam Card',
  payload<{ camCardId: string }>()
);

export const unselectCamCard = createAction('[Camfil CamCards Internal] Unselect Cam Card');

export const addBasketToNewCamCard = createAction(
  '[Camfil CamCards] Add basket to New Cam Card]',
  payload<{ camCard: CamCard }>()
);

export const addBasketToNewCamCardFail = createAction(
  '[Camfil CamCards API] Add basket to New Cam Card Fail]',
  httpError()
);

export const addBasketToNewCamCardSuccess = createAction(
  '[Camfil CamCards API] Add basket to New Cam Card Success]',
  payload<{ camCard: CamCard }>()
);

export const detectCamCardToolbar = createAction('[Camfil CamCards API] Detect Cam Card toolbar');

export const setStickyCamCardToolbar = createAction(
  '[Camfil CamCards API] Set Sticky Toolbar',
  payload<{ sticky: boolean }>()
);

export const validateCamCardImport = createAction(
  '[Camfil CamCards] Validate Cam Card Import',
  payload<{
    camCardData: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      [x: string]: any[];
    };
  }>()
);

export const validateCamCardImportSuccess = createAction(
  '[Camfil CamCards API] Validate Cam Card Import Success',
  payload<{ validationResponse: CamCardImportValidationResponse[] }>()
);

export const validateCamCardImportFail = createAction(
  '[Camfil CamCards API] Validate Cam Card Import Fail',
  httpError()
);

export const importCamCard = createAction('[Camfil CamCards] Import Cam Card', payload<{ camCardData: {} }>());

export const importCamCardSuccess = createAction(
  '[Camfil CamCards API] Import Cam Card Success',
  payload<{ camCardData: unknown }>()
);

export const importCamCardFail = createAction('[Camfil CamCards API] Import Cam Card Fail', httpError());

export const updateCamCardAttribute = createAction(
  '[Camfil CamCards] Update Cam Card Attribute',
  payload<{ camCardId: string; camCardAttribute: Attribute }>()
);

export const updateCamCardAttributeSuccess = createAction(
  '[Camfil CamCards API] Update Cam Card Attribute Success',
  payload<{ camCard: CamCard }>()
);

export const updateCamCardAttributeFail = createAction(
  '[Camfil CamCards API] Update Cam Card Attribute Fail',
  httpError()
);

export const checkCamCardsInBasketsForAllUsers = createAction(
  '[Camfil CamCards] check Cam Cards In Baskets',
  payload<{ camCardsId: string[] }>()
);

export const checkCamCardsInBasketsForAllUsersSuccess = createAction(
  '[Camfil CamCards API] check Cam Cards In Baskets Success',
  payload<{ camCardsId: string[] }>()
);

export const checkCamCardsInBasketsForAllUsersFail = createAction(
  '[Camfil CamCards API] check Cam Cards In Baskets Fail',
  httpError()
);

export const addProductsToCamCard = createAction(
  '[Camfil CamCards] Add Items to Cam Card',
  payload<{
    camCardId: string;
    items: AddToCamCardItem[];
    rootId?: string;
    showSuccessToast?: boolean;
  }>()
);

export const addProductsToCamCardFail = createAction(
  '[Camfil CamCards API] Add Items to Cam Card Fail',
  httpError<{ camCardId: string; items: AddToCamCardItem[] }>()
);

export const addProductsToCamCardSuccess = createAction(
  '[Camfil CamCards API] Add Items to Cam Card Success',
  payload<{ camCard: CamCard }>()
);

export const addProductsToCamCardAfterCreateCamCard = createAction(
  '[Camfil CamCards] Add Items to Cam Card After Create CamCard',
  payload<{
    camCardId: string;
    items: AddToCamCardItem[];
    rootId?: string;
  }>()
);

export const addProductsToNewCamCard = createAction(
  '[Camfil CamCards Internal] Add Products To New Cam Card',
  payload<{
    camCard: CamCard;
    items: AddToCamCardItem[];
    edit?: boolean;
    subCamCard?: CamCard;
  }>()
);

export const addProductsToNewSubCamCard = createAction(
  '[Camfil CamCards] Add Products To New Sub Cam Card',
  payload<{
    subCamCard: CamCard;
    rootCamCard: string;
    items: AddToCamCardItem[];
    edit?: boolean;
  }>()
);

export const setEditMode = createAction('[Camfil CamCards API] Set CamCard as Edited', payload<{ edit?: boolean }>());

export const setEditModeSuccess = createAction(
  '[Camfil CamCards API] Set CamCard as Edited Success',
  payload<{ camCard?: CamCard }>()
);

export const turnOffEditMode = createAction('[Camfil CamCards API] Turn Off CamCard Edit mode');

export const updateEditedCamCard = createAction(
  '[Camfil CamCards] Update of the edited Cam Card',
  payload<{ camCard: CamCard }>()
);

export const createEditedSubCamCard = createAction(
  '[Camfil CamCards] Create edited Sub Cam Card',
  payload<{ subCamCard: CamCard; rootCamCardId: string }>()
);

export const addProductToEditedCamCard = createAction(
  '[Camfil CamCards] Add Item to edited Cam Card',
  payload<{
    camCardId: string;
    sku: string;
    quantity?: number;
    comment?: CamCardItemComment;
    measurement?: CamCardMeasurement;
  }>()
);

export const addToCartFromCamCards = createAction(
  '[Camfil CamCards] Add Cam Card to basket',
  payload<{ camCards: AddCamCardToCart[] }>()
);

export const addToCartFromCamCardNextStep = createAction(
  '[Camfil CamCards] Add Cam Card to basket next step',
  payload<{ camCards: AddCamCardToCart[] }>()
);

export const addToCartFromCamCardsSuccess = createAction(
  '[Camfil CamCards] Add Cam Card to basket Success',
  payload<{ infos?: CamCardToBasketFeedback[] }>()
);

export const addToCartFromCamCardsFail = createAction('[Camfil CamCards API] Add Cam Card to basket Fail', httpError());

export const updateCamCardsAfterCreateOrder = createAction(
  '[Camfil CamCards API] Load CamCards after order created from CC',
  payload<{ orders: Order[] }>()
);

export const cleanUpCamCardsFeedback = createAction('[Camfil CamCards API] cleanUp CamCard Feedback');
