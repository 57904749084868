<input
  type="radio"
  [formControl]="formControl"
  [value]="props.value"
  [id]="id"
  [attr.name]="radioName"
  class="form-check-input"
  [ngClass]="props.inputClass"
  [attr.data-testing-id]="'radio-' + props.label"
  [attr.aria-label]="props.ariaLabel"
/>
