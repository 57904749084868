import { AnimationEvent } from '@angular/animations';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  Input,
  OnInit,
  TransferState,
  inject,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

import bottomOutAnimation from 'ish-core/animations/bottom-out.animation';
import { COOKIE_CONSENT_VERSION } from 'ish-core/configurations/state-keys';
import { ContentViewHelper } from 'ish-core/models/content-view/content-view.helper';
import { ContentPageletView } from 'ish-core/models/content-view/content-view.model';
import { CookieConsentSettings } from 'ish-core/models/cookies/cookies.model';
import { CookiesService } from 'ish-core/utils/cookies/cookies.service';
import { CMSComponent } from 'ish-shared/cms/models/cms-component/cms-component.model';

@Component({
  selector: 'camfil-cookie-disclaimer',
  templateUrl: './camfil-cms-cookie-disclaimer.component.html',
  styleUrls: ['./camfil-cms-cookie-disclaimer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [bottomOutAnimation()],
})
export class CamfilCmsCookieDisclaimerComponent implements CMSComponent, OnInit {
  @Input() pagelet: ContentPageletView;
  showBanner = false;
  transitionBanner: string | undefined = undefined;
  isRouterLink = ContentViewHelper.isRouterLink;
  routerLink = ContentViewHelper.getRouterLink;

  private destroyRef = inject(DestroyRef);

  constructor(
    private transferState: TransferState,
    private cookiesService: CookiesService,
    private router: Router,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(() => {
        this.hideBanner();
      });

    this.showBannerIfNecessary();
  }

  /**
   * show banner if:
   * - consent not yet given
   * - consent outdated
   */
  showBannerIfNecessary() {
    if (!SSR) {
      const cookieConsentSettings = JSON.parse(
        this.cookiesService.get('cookieConsent') || 'null'
      ) as CookieConsentSettings;
      const cookieConsentVersion = this.transferState.get<number>(COOKIE_CONSENT_VERSION, 1);
      if (!cookieConsentSettings || cookieConsentSettings.version < cookieConsentVersion) {
        this.showBanner = true;
      }
    }
  }

  hideBanner() {
    this.showBanner = false;
    this.cdr.detectChanges();
  }

  acceptAll() {
    this.transitionBanner = 'bottom-out';
    this.cookiesService.setCookiesConsentForAll();
    window.location.reload();
  }

  rejectAll() {
    this.transitionBanner = 'bottom-out';
    this.cookiesService.setCookiesConsentFor([]);
  }

  acceptAllAnimationDone(event: AnimationEvent): void {
    if (event.toState === 'bottom-out') {
      this.hideBanner();
    }
  }
}
