import AT from './AT';
import CH from './CH';
import DE from './DE';
import DK from './DK';
import FI from './FI';
import FR from './FR';
import IT from './IT';
import NL from './NL';
import NO from './NO';
import SE from './SE';

// eslint-disable-next-line ish-custom-rules/no-object-literal-type-assertion
export default { AT, CH, DE, DK, FI, FR, IT, NL, NO, SE } as const;
