import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

import { ProductContextFacade } from 'ish-core/facades/product-context.facade';
import { CompareFacade } from 'ish-extensions/compare/facades/compare.facade';

/**
 * The Product Add To Compare Component add and remove a product to the compare view.
 */
@Component({
  selector: 'camfil-product-add-to-compare',
  templateUrl: './camfil-product-add-to-compare.component.html',
  styleUrls: ['./camfil-product-add-to-compare.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CamfilProductAddToCompareComponent implements OnInit {
  @Input() density: undefined | 'default' | 'compact' = 'default';
  @Input() cssClass: string;

  isInCompareList$: Observable<boolean>;
  visible$: Observable<boolean>;

  constructor(private context: ProductContextFacade, private compareFacade: CompareFacade) {}

  ngOnInit() {
    this.isInCompareList$ = this.compareFacade.inCompareProducts$(this.context.select('sku'));
    this.visible$ = this.context.select('displayProperties', 'addToCompare');
  }

  toggleCompare() {
    this.compareFacade.toggleProductCompare(this.context.get('sku'));
  }
}
