import { createSelector } from '@ngrx/store';
import { getCamfilFeatureState } from 'camfil-core/store/camfil-store';

import { CustomerPricesState, camfilCustomerPriceKey } from './customer-prices.reducer';

const getShoppingState = getCamfilFeatureState<CustomerPricesState>(camfilCustomerPriceKey);

export const getCustomersPrices = createSelector(getShoppingState, state => state.customerPrices);

export const getCustomerPrices = (props: { customerId: string }) =>
  createSelector(getCustomersPrices, list => list && props.customerId && list[props.customerId]);

export const getCustomerPriceBySku = (props: { customerId: string; sku: string }) =>
  createSelector(getCustomersPrices, list =>
    (list && props.customerId && list[props.customerId])?.find(({ sku }) => sku === props.sku)
  );
