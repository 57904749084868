import { Dictionary, EntityState, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { CamfilRequisition } from 'camfil-models/camfil-requisition/camfil-requisition.model';

import { HttpError } from 'ish-core/models/http-error/http-error.model';
import { setErrorOn, setLoadingOn, unsetLoadingAndErrorOn } from 'ish-core/utils/ngrx-creators';

import {
  approveCamfilRequisitionLineItems,
  checkCamfilRequisitionDeliveryDateFail,
  checkCamfilRequisitionDeliveryDateSucces,
  checkProductAvailabilityFail,
  createCamfilRequisition,
  createCamfilRequisitionFail,
  createCamfilRequisitionSuccess,
  createOrderFromApprovedRequisitionFail,
  createOrderFromApprovedRequisitionLineItems,
  createOrderFromApprovedRequisitionLineItemsSuccess,
  createOrderFromApprovedRequisitionSuccess,
  getCamfilRequisitionData,
  loadCamfilRequisition,
  loadCamfilRequisitionFail,
  loadCamfilRequisitionSuccess,
  loadCamfilRequisitions,
  loadCamfilRequisitionsFail,
  loadCamfilRequisitionsSuccess,
  removeLastProductFromCamfilRequisitionSuccess,
  updateCamfilRequisition,
  updateCamfilRequisitionAddress,
  updateCamfilRequisitionAddressSuccess,
  updateCamfilRequisitionFail,
  updateCamfilRequisitionLineItem,
  updateCamfilRequisitionLineItemFail,
  updateCamfilRequisitionLineItemSuccess,
  updateCamfilRequisitionStatus,
  updateCamfilRequisitionStatusFail,
  updateCamfilRequisitionStatusSuccess,
  updateCamfilRequisitionSuccess,
  updateMultipleCamfilRequisitionStatus,
  updateMultipleCamfilRequisitionStatusSuccess,
  updateMultipleCamfileRequisitionStatusFail,
} from './camfil-requisitions.actions';

export const camfilRequisitionsFeatureKey = 'camfilRequisitions';

export const camfilRequisitionsAdapter = createEntityAdapter<CamfilRequisition>();

export interface CamfilRequisitionsState extends EntityState<CamfilRequisition> {
  loading: boolean;
  selectedCamfilRequisition: string;
  error: HttpError;
  filters: {
    buyerPENDING: string[];
    buyerAPPROVED: string[];
    buyerREJECTED: string[];
    approverPENDING: string[];
    approverAPPROVED: string[];
    approverREJECTED: string[];
  };
  minDeliveryDates: Dictionary<string>;
}

const initialState: CamfilRequisitionsState = camfilRequisitionsAdapter.getInitialState({
  loading: false,
  selectedCamfilRequisition: undefined,
  error: undefined,
  filters: {
    buyerPENDING: [],
    buyerAPPROVED: [],
    buyerREJECTED: [],
    approverPENDING: [],
    approverAPPROVED: [],
    approverREJECTED: [],
  },
  minDeliveryDates: {},
});

export const reducer = createReducer(
  initialState,
  setLoadingOn(
    loadCamfilRequisitions,
    loadCamfilRequisition,
    updateCamfilRequisitionStatus,
    updateMultipleCamfilRequisitionStatus,
    createCamfilRequisition,
    updateCamfilRequisition,
    updateCamfilRequisitionLineItem,
    updateCamfilRequisitionAddress,
    createOrderFromApprovedRequisitionLineItems
  ),
  unsetLoadingAndErrorOn(
    loadCamfilRequisitionsSuccess,
    loadCamfilRequisitionSuccess,
    updateCamfilRequisitionStatusSuccess,
    updateMultipleCamfilRequisitionStatusSuccess,
    createCamfilRequisitionSuccess,
    createOrderFromApprovedRequisitionSuccess,
    updateCamfilRequisitionSuccess,
    updateCamfilRequisitionAddressSuccess,
    updateCamfilRequisitionLineItemSuccess,
    createOrderFromApprovedRequisitionLineItemsSuccess
  ),
  setErrorOn(
    loadCamfilRequisitionsFail,
    loadCamfilRequisitionFail,
    updateCamfilRequisitionStatusFail,
    updateMultipleCamfileRequisitionStatusFail,
    updateCamfilRequisitionFail,
    createCamfilRequisitionFail,
    createOrderFromApprovedRequisitionFail,
    checkProductAvailabilityFail,
    updateCamfilRequisitionLineItemFail,
    checkCamfilRequisitionDeliveryDateFail
  ),
  on(
    getCamfilRequisitionData,
    (state: CamfilRequisitionsState, action): CamfilRequisitionsState => ({
      ...state,
      selectedCamfilRequisition: action.payload.requisitionId,
    })
  ),
  on(loadCamfilRequisitionsSuccess, (state: CamfilRequisitionsState, action) =>
    camfilRequisitionsAdapter.upsertMany(action.payload.requisitions, {
      ...state,
      filters: {
        ...state.filters,
        [action.payload.view + action.payload.status]: action.payload.requisitions.map(requisition => requisition.id),
      },
    })
  ),
  on(loadCamfilRequisitionSuccess, (state: CamfilRequisitionsState, action) =>
    camfilRequisitionsAdapter.upsertOne(action.payload.requisition, state)
  ),
  on(
    updateCamfilRequisitionStatusSuccess,
    createOrderFromApprovedRequisitionSuccess,
    (state: CamfilRequisitionsState, action) => {
      const { requisition } = action.payload;
      const { approval } = requisition;
      const approvedRequisition = {
        ...state.entities[requisition?.id],
        approval,
      };

      return camfilRequisitionsAdapter.upsertOne(approvedRequisition, state);
    }
  ),
  on(updateCamfilRequisitionSuccess, (state: CamfilRequisitionsState, action) =>
    camfilRequisitionsAdapter.upsertOne(action.payload.requisition, state)
  ),
  on(createCamfilRequisitionSuccess, (state: CamfilRequisitionsState, action) =>
    camfilRequisitionsAdapter.upsertMany(action.payload.requisitions, state)
  ),
  on(updateCamfilRequisitionAddressSuccess, (state: CamfilRequisitionsState, action) => {
    const { requisition, address } = action.payload;

    const updatedRequisition = {
      ...requisition,
      shippingAddress: address,
    };

    return camfilRequisitionsAdapter.upsertOne(updatedRequisition, state);
  }),
  on(updateCamfilRequisitionLineItemSuccess, (state: CamfilRequisitionsState, action) => {
    const { requisitionId, lineItemUpdate } = action.payload;
    const updateLineItems = state.entities[requisitionId].lineItems.map(lineItem =>
      lineItem.id === lineItemUpdate.lineItemId
        ? {
            ...lineItem,
            quantity: {
              value: lineItemUpdate.quantity,
            },
          }
        : lineItem
    );

    const updatedRequisition = {
      ...state.entities[requisitionId],
      lineItems: updateLineItems,
    };

    return camfilRequisitionsAdapter.upsertOne(updatedRequisition, state);
  }),
  on(approveCamfilRequisitionLineItems, (state: CamfilRequisitionsState, action) => {
    const { requisition } = action.payload;
    const updatedRequisition = {
      ...requisition,
      partiallyApproved: false,
    };
    return camfilRequisitionsAdapter.upsertOne(updatedRequisition, state);
  }),
  on(createOrderFromApprovedRequisitionLineItemsSuccess, (state: CamfilRequisitionsState, action) => {
    const { requisition, lineItemIds } = action.payload;

    const updatedRequisition = {
      ...requisition,
      approval: {
        ...requisition.approval,
        status: 'Partial Approved',
        statusCode: 'PARTLY_APPROVED',
        lineItemStatuses: requisition.approval.lineItemStatuses.map(lineItem =>
          lineItemIds.includes(lineItem.lineItemId) ? { ...lineItem, status: 'APPROVED' } : lineItem
        ),
      },
      lineItems: requisition.lineItems.map(lineItem =>
        lineItemIds.includes(lineItem.id) ? { ...lineItem, requisitionLineItemStatus: 'APPROVED' } : lineItem
      ),
      partiallyApproved: true,
    };

    return camfilRequisitionsAdapter.upsertOne(updatedRequisition, state);
  }),
  // TODO: Update with upsert many
  on(updateMultipleCamfilRequisitionStatusSuccess, (state: CamfilRequisitionsState, action) => {
    const { requisition } = action.payload;
    const { approval } = requisition;
    const approvedRequisition = {
      ...state.entities[requisition?.id],
      approval,
    };

    return camfilRequisitionsAdapter.upsertOne(approvedRequisition, state);
  }),
  on(removeLastProductFromCamfilRequisitionSuccess, (state: CamfilRequisitionsState, action) => {
    const { requisitionId } = action.payload;

    return camfilRequisitionsAdapter.removeOne(requisitionId, state);
  }),

  on(checkCamfilRequisitionDeliveryDateSucces, (state: CamfilRequisitionsState, action): CamfilRequisitionsState => {
    const { id, date } = action.payload;

    return {
      ...state,
      minDeliveryDates: {
        ...state.minDeliveryDates,
        [id]: date,
      },
    };
  })
);
