import { Injectable } from '@angular/core';
import { Validators } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { CamfilConfigurationFacade } from 'camfil-core/facades/camfil-configuration.facade';
import { map } from 'rxjs';

import { FieldLibraryConfiguration } from 'ish-shared/formly/field-library/configurations/field-library-configuration';

@Injectable()
export class DeliveryAddressLine1CompanyNameConfiguration extends FieldLibraryConfiguration {
  constructor(private camfilConfigurationFacade: CamfilConfigurationFacade) {
    super();
  }

  id = 'deliveryAddressLine1FLC';

  getFieldConfig(): FormlyFieldConfig {
    return {
      key: 'deliveryAddress.addressLine1',
      type: 'camfil-textarea-field',
      props: {
        label: 'camfil.modal.createOrder.order-form.input.addressLine1',
        required: true,
        autosize: false,
      },
      validation: {
        messages: {
          required: 'camfil.modal.createOrder.order-form.input.addressLine1.error.required',
        },
      },
      expressions: {
        'props.rows': this.camfilConfigurationFacade
          .isEnabled$('use2ndAddressLineInOrderForm')
          .pipe(map(v => (v ? 3 : 5))),
      },
      hooks: {
        onInit: (field: FormlyFieldConfig) => {
          this.camfilConfigurationFacade.maxLength$('addressLineOne').subscribe(maxLength => {
            field.formControl.setValidators([Validators.maxLength(maxLength)]);
          });
        },
      },
    };
  }
}
