import { Injectable } from '@angular/core';
import { MatCheckboxClickAction } from '@angular/material/checkbox';
import { DomSanitizer } from '@angular/platform-browser';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { CamfilDialogFacade } from 'camfil-core/facades/camfil-dialog.facade';
import { CamfilDialogConfirmResult } from 'camfil-shared/dialog/camfil-dialog-confirm/camfil-dialog-confirm.component';
import { createDestroy$ } from 'camfil-shared/formly/utils/camfil-form-utils';
import { takeUntil } from 'rxjs';

import { FieldLibraryConfiguration } from 'ish-shared/formly/field-library/configurations/field-library-configuration';

@Injectable()
export class CamCardReminderFlagConfiguration extends FieldLibraryConfiguration {
  constructor(
    private camfilDialogFacade: CamfilDialogFacade,
    private translate: TranslateService,
    private sanitizer: DomSanitizer
  ) {
    super();
  }

  id = 'camCardReminderFlagFLC';

  getFieldConfig(): FormlyFieldConfig {
    return {
      key: 'reminderFlag',
      type: 'camfil-checkbox-field',
      props: {
        label: 'camfil.account.cam_card.add_to_form.reminder',
        required: false,
        clickAction: 'noop' as MatCheckboxClickAction,
      },
      hooks: {
        // eslint-disable-next-line no-unused-vars
        onInit: (field: FormlyFieldConfig) => {
          const destroy$ = createDestroy$();

          field.hooks.onDestroy = destroy$.onDestroy;

          field.props.click = (field: FormlyFieldConfig) => {
            const body = this.sanitizer.bypassSecurityTrustHtml(
              [
                'camfil.account.cam_card.add_to_form.dialog.other_users',
                'camfil.account.cam_card.add_to_form.dialog.do_you_want',
              ]
                .map(key => `<p>${this.translate.instant(key)}</p>`)
                .join('')
            );

            const dialogRef = this.camfilDialogFacade.confirm({
              data: {
                body,
                title: 'camfil.account.cam_card.add_to_form.dialog.title',
                confirmButtonLabel: 'camfil.account.cam_card.add_to_form.dialog.yes',
                dismissButtonLabel: 'camfil.account.cam_card.add_to_form.dialog.no',
              },
            });

            dialogRef
              .afterClosed()
              .pipe(takeUntil(destroy$.subject))
              .subscribe(result => {
                const lastValue = field.formControl.value;

                if (result === CamfilDialogConfirmResult.CONFIRMED) {
                  field.formControl.setValue(!lastValue);
                }
              });
          };
        },
      },
    };
  }
}
