import { EntityState, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { CamfilQuoteHelper } from 'camfil-models/camfil-quotes/camfil-quote/camfil-quote.helper';
import { CamfilQuote } from 'camfil-models/camfil-quotes/camfil-quote/camfil-quote.model';

import { HttpError } from 'ish-core/models/http-error/http-error.model';
import { setErrorOn, setLoadingOn, unsetLoadingAndErrorOn } from 'ish-core/utils/ngrx-creators';

import {
  approveCamfilQuote,
  approveCamfilQuoteFail,
  approveCamfilQuotes,
  approveCamfilQuotesFail,
  approveCamfilQuotesSuccess,
  loadCamfilQuote,
  loadCamfilQuoteSuccess,
  loadCamfilQuotes,
  loadCamfilQuotesSuccess,
  rejectCamfilQuote,
  rejectCamfilQuoteFail,
  rejectCamfilQuotes,
  rejectCamfilQuotesFail,
  rejectCamfilQuotesSuccess,
  selectCamfilQuote,
  updateCamfilQuote,
  updateCamfilQuoteSuccess,
} from './camfil-quotes.actions';

export const camfilQuotesFeatureKey = 'camfilQuotes';

export interface CamfilQuotesState extends EntityState<CamfilQuote> {
  loading: boolean;
  selected: string;
  error: HttpError;
  approvedQuotesSuccess: boolean;
  rejectedQuotesSuccess: boolean;
}

export const quotesAdapter = createEntityAdapter<CamfilQuote>({
  selectId: quote => quote.id,
});

const initialState: CamfilQuotesState = quotesAdapter.getInitialState({
  loading: false,
  selected: undefined,
  error: undefined,
  approvedQuotesSuccess: false,
  rejectedQuotesSuccess: false,
});

export const reducer = createReducer(
  initialState,
  setLoadingOn(
    loadCamfilQuotes,
    loadCamfilQuote,
    approveCamfilQuote,
    rejectCamfilQuote,
    approveCamfilQuotes,
    rejectCamfilQuotes,
    updateCamfilQuote
  ),
  setErrorOn(approveCamfilQuoteFail, rejectCamfilQuoteFail, approveCamfilQuotesFail, rejectCamfilQuotesFail),
  on(
    selectCamfilQuote,
    (state, action): CamfilQuotesState => ({
      ...state,
      selected: action.payload.quoteId,
    })
  ),
  on(
    approveCamfilQuoteFail,
    rejectCamfilQuoteFail,
    approveCamfilQuotesFail,
    rejectCamfilQuotesFail,
    (state): CamfilQuotesState => ({
      ...state,
      selected: undefined as string,
    })
  ),
  unsetLoadingAndErrorOn(
    loadCamfilQuotesSuccess,
    loadCamfilQuoteSuccess,
    approveCamfilQuotesSuccess,
    rejectCamfilQuotesSuccess,
    updateCamfilQuoteSuccess
  ),
  on(loadCamfilQuotesSuccess, (state, action): CamfilQuotesState => {
    const { quotes } = action.payload;
    const corrections: CamfilQuote[] = quotes.map(quote => correction(quote));

    return quotesAdapter.upsertMany(corrections, {
      ...state,
      approvedQuotesSuccess: false,
      rejectedQuotesSuccess: false,
    });
  }),
  on(
    approveCamfilQuotesSuccess,
    (state): CamfilQuotesState => ({
      ...state,
      approvedQuotesSuccess: true,
    })
  ),
  on(
    rejectCamfilQuotesSuccess,
    (state): CamfilQuotesState => ({
      ...state,
      rejectedQuotesSuccess: true,
    })
  ),
  on(loadCamfilQuoteSuccess, (state, action): CamfilQuotesState => {
    const { quote } = action.payload;
    const updated: CamfilQuote = correction(quote);
    return quotesAdapter.upsertOne(updated, state);
  }),
  on(updateCamfilQuoteSuccess, (state, action): CamfilQuotesState => {
    const { quote } = action.payload;
    const updated: CamfilQuote = correction(quote);
    return quotesAdapter.setOne(updated, state);
  })
);

function correction(quote: CamfilQuote) {
  return {
    ...quote,
    status: CamfilQuoteHelper.correctionForStatusNumber(quote),
  };
}
