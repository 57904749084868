import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CamfilAddress } from 'camfil-models/camfil-address/camfil-address.model';
import { CamfilContact, CamfilCustomer } from 'camfil-models/camfil-customer/camfil-customer.model';
import { Observable, map, of } from 'rxjs';

import { SelectOption } from 'ish-core/models/select-option/select-option.model';
import { whenTruthy } from 'ish-core/utils/operators';

@Injectable({ providedIn: 'root' })
export class CamfilFormsService {
  constructor(private translateService: TranslateService) {}

  /**
   * Get customers select options for customers in order to render them in an customers select box.
   *
   * @returns customers select options observable
   * @param customers$
   */
  static getCamfilCustomersOptions(customers$: Observable<CamfilCustomer[]>): Observable<SelectOption[]> {
    if (!customers$) {
      return of([{ label: '--', value: '' }]);
    }

    return customers$.pipe(
      whenTruthy(),
      map(customers =>
        customers
          .map(c => ({
            label: `${c.companyName}, ${c.customerNo}${c.department ? `, ${c.department}` : ''}`,
            value: c.id,
          }))
          .sort((a, b) => a.label?.localeCompare(b.label))
      )
    );
  }

  static getCamfilContactsOptions(contacts$: Observable<CamfilContact[]>): Observable<SelectOption[]> {
    if (!contacts$) {
      return of([{ label: '--', value: '' }]);
    }

    return contacts$.pipe(
      whenTruthy(),
      map(contacts =>
        contacts
          .map(c => ({
            label: `${c.firstName} ${c.lastName}, ${c.erpId}`,
            value: c.erpId,
          }))
          .sort((a, b) => a.label?.localeCompare(b.label))
      )
    );
  }

  static getDeliveryAddressOptions(addresses$: Observable<CamfilAddress[]>): Observable<SelectOption[]> {
    const emptyOption = { label: '--', value: '' };
    if (!addresses$) {
      return of([emptyOption]);
    }

    return addresses$.pipe(
      map(addresses => {
        if (!addresses?.length) {
          return [emptyOption];
        }

        const mappedAddresses = CamfilFormsService.sortAddresOptions(addresses).map(a => ({
          label: CamfilFormsService.getAddresLabel(a),
          value: a?.id,
        }));

        return [emptyOption, ...mappedAddresses];
      })
    );
  }

  static getAddresLabel(option: CamfilAddress): string {
    const { addressLine1, addressLine2 } = option;
    if (!/[a-z]/gi.test(addressLine1) || !addressLine2) {
      return addressLine1;
    }
    return [addressLine1, addressLine2].join(', ');
  }

  static sortAddresOptions(options: CamfilAddress[]): CamfilAddress[] {
    return [...options].sort((x, y) => {
      const line1 = x.addressLine1?.toLowerCase()?.localeCompare(y.addressLine1?.toLowerCase());
      if (line1 === 0) {
        if (!x.addressLine2) {
          return -1;
        }
        if (!y.addressLine2) {
          return 1;
        }
        return x.addressLine2?.toLowerCase()?.localeCompare(y.addressLine2?.toLowerCase());
      }
      return line1;
    });
  }

  getTitleOptionsForLanguage(): SelectOption[] {
    const currentLang = this.translateService.currentLang.substring(0, 2);
    return this.getTitles(currentLang).map(title => ({
      value: this.translateService.instant(title),
      label: title,
    }));
  }

  private getTitles(lang: string): string[] {
    let titleLabels: string[] = [];
    switch (lang) {
      case 'de': {
        titleLabels = ['camfil.dynamic.salutation.mr', 'camfil.dynamic.salutation.ms'];
        break;
      }
      case 'fi':
      case 'it': {
        titleLabels = [
          'camfil.dynamic.salutation.mr',
          'camfil.dynamic.salutation.miss',
          'camfil.dynamic.salutation.mrs',
        ];
      }
      default: {
        titleLabels = [
          'camfil.dynamic.salutation.mr',
          'camfil.dynamic.salutation.ms',
          'camfil.dynamic.salutation.miss',
          'camfil.dynamic.salutation.mrs',
        ];
      }
    }
    return titleLabels;
  }
}
