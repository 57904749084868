import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'camfil-small-cta-modal',
  templateUrl: './camfil-small-cta-modal.component.html',
  styleUrls: ['./camfil-small-cta-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CamfilSmallCtaModalComponent {
  constructor(public dialog: MatDialog) {}

  @ViewChild('modal', { static: false }) modalTemplate: TemplateRef<unknown>;
  @Input() actionTemplate: TemplateRef<unknown>;
  @Input() actionTitle: string;
  @Input() actionButtonTitle: string;
  @Input() cancelButtonTitle: string;
  @Output() actionClicked = new EventEmitter<void>();
  @Input() icon: string;
  @Input() showCloseIcon = false;
  @Input() onlyAccept = false;
  @Input() hasError = false;
  @Input() hideIcon = false;
  @Input() pageletIds: string[];

  emitAction() {
    this.actionClicked.emit();
  }

  /** close modal */
  hide() {
    this.dialog.closeAll();
  }

  /** open modal */
  show() {
    return this.modalTemplate;
  }

  /**
   * Callback function to hide modal dialog (used with ishServerHtml). - is needed for closing the dialog after the user clicks a message link
   */
  get callbackHideDialogModal() {
    return () => {
      this.hide();
    };
  }
}
