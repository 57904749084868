<div *ngIf="product.available" [formGroup]="parentForm" class="product-quantity">
  <ng-container *ngIf="!readOnly">
    <ng-container [ngSwitch]="type">
      <mat-form-field *ngSwitchCase="'select'" appearance="fill">
        <mat-label>{{ quantityLabel | translate }}</mat-label>
        <mat-select [formControlName]="controlName" [formGroup]="parentForm">
          <mat-option *ngFor="let quantityOption of quantityOptions" [value]="quantityOption.value">
            {{ quantityOption.label }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field *ngSwitchCase="'input'" appearance="fill">
        <mat-label>{{ quantityLabel | translate }}</mat-label>
        <input
          [formControlName]="controlName"
          [formGroup]="parentForm"
          [max]="maxFromContext"
          [min]="allowZeroQuantity ? '0' : product.minOrderQuantity"
          [placeholder]="placeholderQuantity()"
          [type]="'number'"
          matInput
          (focusInput)="focus()"
        />
      </mat-form-field>
      <ng-container *ngSwitchDefault>
        <camfil-counter
          *ngIf="parentForm"
          [form]="parentForm"
          [controlName]="controlName"
          [isInLineItem]="isInLineItem"
          [lineItemId]="lineItemId"
          [max]="maxFromContext"
          [min]="allowZeroQuantity ? 0 : product.minOrderQuantity"
          [stepQuantityValue]="product.stepQuantity"
          [focusInputOnly]="focusInputOnly"
          (focusInput)="focus()"
          inputClass=""
        />
      </ng-container>
    </ng-container>

    <camfil-error
      *ngIf="showErrors"
      [errorValidators]="validators.quantity"
      [errors]="parentForm?.get(controlName)?.errors"
      [touched]="parentForm?.get(controlName)?.touched || parentForm?.get(controlName)?.dirty"
    />
  </ng-container>
  <ng-container *ngIf="readOnly">
    <span>{{ quantityLabel | translate }}: {{ quantity }}</span>
  </ng-container>
</div>
