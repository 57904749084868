import { Price, Pricing } from 'ish-core/models/price/price.model';

import { AhuHelper } from '../ahu.helper';
import {
  UnitFilterSlot,
  UnitFilterSlotItemParams,
  UnitFilterSlotItemQueryParam,
  UnitFilterSlotParams,
} from '../unit-filter/unit-filter.model';

import { Unit } from './unit.model';

export class UnitHelper {
  static equal(unit1: Unit, unit2: Unit): boolean {
    return !!unit1 && !!unit2 && unit1?.id === unit2?.id;
  }

  static addAhuSlotItemToList(
    slotsQueryParam: string,
    ahuSlotItemParams: UnitFilterSlotItemParams & { quantity: number }
  ) {
    const { slotId, itemNumber, quantity } = ahuSlotItemParams;

    const prevSlots: UnitFilterSlotItemQueryParam = AhuHelper.parseQs(slotsQueryParam);
    const nextSlots: UnitFilterSlotItemQueryParam = {};

    const skus = new Array(quantity).fill(itemNumber);

    nextSlots[slotId] = prevSlots[slotId] ? [...prevSlots[slotId], ...skus] : skus;

    const newSlots = { ...prevSlots, ...nextSlots };

    const slots = Object.keys(newSlots)?.length ? AhuHelper.stringifyToQs(newSlots) : undefined;

    return {
      [AhuHelper.SLOTS_QUERY_PARAM_NAME]: slots,
    };
  }

  static removeAhuSlotItemFromList(slotsQueryParam: string, ahuSlotItemParams: UnitFilterSlotItemParams) {
    const { slotId, itemNumber } = ahuSlotItemParams;

    const prevSlots: UnitFilterSlotItemQueryParam = AhuHelper.parseQs(slotsQueryParam);
    const nextSlots: UnitFilterSlotItemQueryParam = {};

    nextSlots[slotId] = prevSlots[slotId] ? [...prevSlots[slotId]].filter(item => item !== itemNumber) : [];

    const newSlots = { ...prevSlots, ...nextSlots };

    if (newSlots[slotId].length === 0) {
      delete newSlots[slotId];
    }

    const slots = Object.keys(newSlots)?.length ? AhuHelper.stringifyToQs(newSlots) : undefined;

    return {
      [AhuHelper.SLOTS_QUERY_PARAM_NAME]: slots,
    };
  }

  static countAddedItemsBySku(slotsQueryParam: string, ahuSlotItemParams: UnitFilterSlotItemParams) {
    const { slotId, itemNumber } = ahuSlotItemParams;
    const slots: UnitFilterSlotItemQueryParam = AhuHelper.parseQs(slotsQueryParam);

    return [].concat(slots?.[slotId]).filter(item => item === itemNumber)?.length;
  }

  static isAhuUnitSlotItemAdded(slotsQueryParam: string, ahuSlotItemParams: UnitFilterSlotItemParams) {
    return Boolean(UnitHelper.countAddedItemsBySku(slotsQueryParam, ahuSlotItemParams));
  }

  static isAhuUnitSlotValid(
    slotsQueryParam: string,
    ahuSlotParams: UnitFilterSlotParams,
    ahuUnitUnitFilterSlots: UnitFilterSlot[]
  ) {
    const { slotId } = ahuSlotParams;
    const slots: UnitFilterSlotItemQueryParam = AhuHelper.parseQs(slotsQueryParam);
    const currentSlotAmount = slots?.[slotId]?.length || 0;
    const requiredSlotAmount = Number(ahuUnitUnitFilterSlots.find(slot => slot.slotId === slotId)?.amount || 0);

    return currentSlotAmount === requiredSlotAmount;
  }

  static getDefaultPrice(): Price {
    return {
      type: 'Money',
      currency: 'USD',
      value: 0,
    };
  }

  static determineCurrency(items: Pricing[]): string {
    for (const item of items) {
      if (item.salePrice?.currency) {
        return item.salePrice.currency;
      }
      if (item.listPrice?.currency) {
        return item.listPrice.currency;
      }
    }
    return 'USD';
  }

  static totalPrice(items: Pricing[]): Price {
    const getValue = (element: Pricing) => element.salePrice?.value || element.listPrice?.value || 0;

    if (items.length === 0) {
      return UnitHelper.getDefaultPrice();
    }

    const currency = UnitHelper.determineCurrency(items);
    const totalValue = items.reduce((acc, item) => acc + getValue(item), 0);

    return {
      type: 'Money',
      currency,
      value: totalValue,
    };
  }
}
