import { Platform } from '@angular/cdk/platform';
import { DestroyRef, Directive, ElementRef, Input, OnDestroy, OnInit, Renderer2, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Subscription, fromEvent } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

/**
 * Directive to apply classes to an element based on the current platform (mobile, tablet, desktop).
 * The directive listens for window resize and orientation change events and applies the classes accordingly.
 * The classes to apply are defined as an object with the following structure:
 *  @example
 *  <div [camfilPlatformClass]="{ mobile: 'mobile-class', tablet: ['tablet-class1', 'tablet-class2'], desktop: 'desktop-class' }"></div>
 */
@Directive({
  selector: '[camfilPlatformClass]',
})
export class CamfilPlatformClassDirective implements OnInit, OnDestroy {
  @Input('camfilPlatformClass') platformClasses: {
    mobile?: string | string[];
    tablet?: string | string[];
    desktop?: string | string[];
  };
  private resizeSubscription: Subscription;
  private destroyRef = inject(DestroyRef);

  constructor(private platform: Platform, private el: ElementRef, private renderer: Renderer2) {}

  ngOnInit(): void {
    this.applyClassBasedOnPlatform();

    // Listen for window resize and orientation change events
    if (!SSR) {
      this.resizeSubscription = fromEvent(window, 'resize')
        .pipe(debounceTime(150), takeUntilDestroyed(this.destroyRef))
        .subscribe(() => this.applyClassBasedOnPlatform());

      fromEvent(window, 'orientationchange')
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe(() => this.applyClassBasedOnPlatform());
    }
  }

  ngOnDestroy(): void {
    // Clean up the subscription when the directive is destroyed
    if (this.resizeSubscription) {
      // eslint-disable-next-line ban/ban
      this.resizeSubscription.unsubscribe();
    }
  }

  private applyClassBasedOnPlatform(): void {
    let width = 0;

    if (!SSR) {
      width = window.innerWidth;
    }

    // Remove all potential classes first
    this.removeClasses(this.platformClasses.mobile);
    this.removeClasses(this.platformClasses.tablet);
    this.removeClasses(this.platformClasses.desktop);

    // Determine which classes to apply based on the current platform
    let classesToApply: string | string[] | undefined;
    if (this.platform.ANDROID || this.platform.IOS || width < 768) {
      classesToApply = this.platformClasses.mobile;
    } else if (width >= 768 && width <= 1024) {
      classesToApply = this.platformClasses.tablet;
    } else if (width > 1024) {
      classesToApply = this.platformClasses.desktop;
    }

    // Apply the relevant classes
    this.addClasses(classesToApply);
  }

  private addClasses(classes: string | string[] | undefined): void {
    if (classes) {
      const classList = Array.isArray(classes) ? classes : classes.split(' ');
      classList.forEach(className => {
        this.renderer.addClass(this.el.nativeElement, className);
      });
    }
  }

  private removeClasses(classes: string | string[] | undefined): void {
    if (classes) {
      const classList = Array.isArray(classes) ? classes : classes.split(' ');
      classList.forEach(className => {
        this.renderer.removeClass(this.el.nativeElement, className);
      });
    }
  }
}
