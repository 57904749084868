import { createSelector } from '@ngrx/store';
import { CamfilCustomer } from 'camfil-models/camfil-customer/camfil-customer.model';

import { getCamfilFeatureState } from '../camfil-store';

import { CamfilCustomersState, camfilCustomerFeatureKey, camfilCustomersAdapter } from './camfil-customer.reducer';

const getCamfilCustomersState = getCamfilFeatureState<CamfilCustomersState>(camfilCustomerFeatureKey);

const { selectAll, selectEntities } = camfilCustomersAdapter.getSelectors(getCamfilCustomersState);

export const getCamfilCustomers = selectAll;

export const getCamfilCustomersEntities = selectEntities;

export const getCamfilCustomer = (id: string) =>
  createSelector(getCamfilCustomers, camfilCustomers => camfilCustomers?.find(customer => customer.id === id));

const getSelectedCamfilCustomerId = createSelector(getCamfilCustomersState, state => state.selected);

const getSelectedCamfilCustomer = createSelector(
  selectEntities,
  getSelectedCamfilCustomerId,
  (entities, id): CamfilCustomer => id && entities[id]
);

export const getUserContact = createSelector(getSelectedCamfilCustomer, state => state.userContact);

export const getUserContactByCustomerNo = (customerNo: string) =>
  createSelector(
    getCamfilCustomers,
    camfilCustomers => camfilCustomers?.find(customer => customer.customerNo === customerNo)?.userContact
  );

export const getContactsByCustomerId = (id: string) =>
  createSelector(
    getCamfilCustomers,
    camfilCustomers => camfilCustomers?.find(customer => customer.id === id)?.contacts
  );

export const getDeliveryAddressesByCustomerId = (id: string) =>
  createSelector(
    getCamfilCustomers,
    camfilCustomers => camfilCustomers?.find(customer => customer.id === id)?.deliveryAddresses
  );
