<ng-container>
  <div class="d-flex flex-column align-items-center justify-content-center form-header">
    <mat-icon class="contact-icon" svgIcon="contact" />
    <p camfilDialogTitle class="text-center">
      <strong>
        <camfil-content-by-role [returnString]="'camfil.dynamic.checkout.cta.add_email_recipients_for'" />
      </strong>
    </p>
  </div>

  <div class="form-content__container" camfilDialogContent separated>
    <form [formGroup]="form" (ngSubmit)="submitAddEmailRecipientForm()">
      <formly-form [form]="form" [fields]="fields" />
    </form>
  </div>
  <div camfilDialogActions>
    <button mat-flat-button type="button" class="secondary-button" (click)="matDialogRef.close()">
      {{ 'camfil.account.cam_card.move.dialog.btn.cancel' | translate }}
    </button>
    <button
      (click)="submitAddEmailRecipientForm()"
      attr.aria-label="{{ 'camfil.account.cam_card.move.dialog.btn.aria' | translate }}"
      class="btn-tool text-primary"
      mat-flat-button
    >
      {{ 'camfil.account.cam_card.edit_mode.toolbar.save.btn' | translate }}
    </button>
  </div>
</ng-container>
