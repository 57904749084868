import { createAction } from '@ngrx/store';
import { CamfilProduct } from 'camfil-models/camfil-product/product.model';

import { httpError, payload } from 'ish-core/utils/ngrx-creators';

export const loadCustomerPrices = createAction(
  '[Camfil Customer Prices Internal] Load Customer Prices',
  payload<{ customerId: string; skus: string[] }>()
);

export const loadCustomerPricesFail = createAction(
  '[Camfil Customer Prices API] Load Customer Prices Fail',
  httpError<{ customerId: string }>()
);

export const loadCustomerPricesSuccess = createAction(
  '[Camfil Customer Prices API] Load Customer Prices Success',
  payload<{ customerId: string; products: CamfilProduct[] }>()
);

export const getCustomerPricesForProductsSuccess = createAction(
  '[Camfil Customer Prices API] Customer Prices For Products Success',
  payload<{ products: CamfilProduct[] }>()
);
