import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

import { CompareFacade } from 'ish-extensions/compare/facades/compare.facade';

@Component({
  selector: 'camfil-product-compare-status',
  templateUrl: './camfil-product-compare-status.component.html',
  styleUrls: ['./camfil-product-compare-status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CamfilProductCompareStatusComponent implements OnInit {
  productCompareCount$: Observable<number>;

  constructor(private compareFacade: CompareFacade) {}

  ngOnInit() {
    this.productCompareCount$ = this.compareFacade.compareProductsCount$;
  }
}
