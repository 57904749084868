<ng-container *ngIf="pagelet?.hasParam('Link') && !pagelet.hasParam('LinkText'); else imageEnhanced">
  <ng-container *ngIf="isRouterLink(pagelet, 'Link'); else externalLink">
    <a [routerLink]="routerLink(pagelet, 'Link')" [attr.title]="pagelet.stringParam('LinkTitle')" class="teaser-link">
      <ng-container *ngTemplateOutlet="imageEnhanced" />
    </a>
  </ng-container>
  <ng-template #externalLink>
    <a [href]="pagelet.stringParam('Link')" [attr.title]="pagelet.stringParam('LinkTitle')" class="teaser-link">
      <ng-container *ngTemplateOutlet="imageEnhanced" />
    </a>
  </ng-template>
</ng-container>

<ng-template #imageEnhanced>
  <div class="enhanced-image-container" [ngClass]="pagelet.stringParam('CSSClass')">
    <picture class="enhanced-image">
      <source
        *ngIf="pagelet.hasParam('ImageXS')"
        [srcset]="pagelet.stringParam('ImageXS')"
        media="(max-width: 767px)"
      />
      <source
        *ngIf="pagelet.hasParam('ImageSM')"
        [srcset]="pagelet.stringParam('ImageSM')"
        media="(max-width: 991px)"
      />
      <source
        *ngIf="pagelet.hasParam('ImageMD')"
        [srcset]="pagelet.stringParam('ImageMD')"
        media="(max-width: 1199px)"
      />
      <img
        [attr.loading]="loading"
        [src]="pagelet.stringParam('Image')"
        [attr.alt]="pagelet.stringParam('AlternateText') || ('image.alt_text.default' | translate)"
        class="enhanced-image"
      />
    </picture>
    <div class="enhanced-image-text">
      <h1 *ngIf="pagelet.hasParam('Heading1')">{{ pagelet.stringParam('Heading1') }}</h1>
      <h2 *ngIf="pagelet.hasParam('Heading2')">{{ pagelet.stringParam('Heading2') }}</h2>
      <div
        *ngIf="pagelet.hasParam('Description')"
        class="d-none d-sm-inline"
        [ishServerHtml]="pagelet.stringParam('Description')"
      ></div>
      <a
        *ngIf="pagelet.hasParam('Link') && pagelet.hasParam('LinkText')"
        [routerLink]="routerLink(pagelet, 'Link')"
        [attr.title]="pagelet.stringParam('LinkTitle')"
        class="btn btn-lg btn-primary"
      >
        {{ pagelet.stringParam('LinkText') }}
      </a>
    </div>
  </div>
</ng-template>
