/* eslint-disable ish-custom-rules/ordered-imports, ish-custom-rules/ban-imports-file-pattern, no-restricted-imports */
import { sortBy } from 'lodash-es';
import { BasketSurchargeTypes } from 'camfil-models/camfil-basket-surcharge/camfil-basket-surcharge.types';

import { PriceHelper } from 'ish-core/models/price/price.helper';

import { CamfilQuoteSurchargeItemData } from './camfil-quote-surcharge-item.interface';
import { CamfilQuoteSurchargeItem } from 'camfil-models/camfil-quotes/camfil-quote-surcharge-item/camfil-quote-surcharge-item.model';

export class CamfilQuoteSurchargeItemMapper {
  static fromData(data: CamfilQuoteSurchargeItemData): CamfilQuoteSurchargeItem {
    if (data) {
      return {
        amount: {
          gross: parseFloat(data.lineAmount),
          net: parseFloat(data.lineAmount),
          currency: data.currencyCode,
          type: 'PriceItem',
        },
        displayName: data.name,
        description: '',
        strikethrough: parseFloat(data.lineAmount) <= 0,
      };
    }
  }

  static fromListData(data: CamfilQuoteSurchargeItemData[]): CamfilQuoteSurchargeItem[] {
    if (data?.length) {
      let surcharges = data.map(CamfilQuoteSurchargeItemMapper.fromData);

      const shippingFee = surcharges.find(i => i.displayName === BasketSurchargeTypes.ExtraFreightCostRule);
      const shippingDiscount = surcharges.find(i => i.displayName === BasketSurchargeTypes.CamfilExtraFreightDiscount);

      if (
        shippingFee &&
        shippingDiscount &&
        Math.abs(shippingFee.amount.net) === Math.abs(shippingDiscount.amount.net)
      ) {
        surcharges = surcharges
          .map(surcharge => {
            switch (surcharge.displayName) {
              case BasketSurchargeTypes.ExtraFreightCostRule:
                return;
              case BasketSurchargeTypes.CamfilExtraFreightDiscount:
                return { ...surcharge, amount: PriceHelper.invert(surcharge.amount) };
              default:
                return surcharge;
            }
          })
          .filter(Boolean);
      }
      return sortBy(surcharges, 'displayName');
    }
  }
}
