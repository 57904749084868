import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Params } from '@angular/router';

import { CategoryView } from 'ish-core/models/category-view/category-view.model';
import { ProductView } from 'ish-core/models/product-view/product-view.model';

@Component({
  selector: 'camfil-product-title',
  templateUrl: './camfil-product-title.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CamfilProductTitleComponent {
  @Input() category: CategoryView;
  @Input() product: ProductView;
  @Input() class: string;
  @Input() queryParams: Params;
}
