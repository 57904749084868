import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  TemplateRef,
  inject,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AbstractControl, FormControl, FormGroup, ValidationErrors } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { CamfilConfigurationFacade } from 'camfil-core/facades/camfil-configuration.facade';
import { CamfilDialogFacade } from 'camfil-core/facades/camfil-dialog.facade';
import { CamfilQueueFacade } from 'camfil-core/facades/camfil-queue.facade';
import { BehaviorSubject, Observable, Subject, combineLatest, map, startWith, withLatestFrom } from 'rxjs';

import { AccountFacade } from 'ish-core/facades/account.facade';
import { ProductContextFacade } from 'ish-core/facades/product-context.facade';

import {
  CamfilProductFormDialogCamCardComponent,
  CamfilProductFormDialogCamCardComponentData,
  CamfilProductFormDialogCamCardComponentResult,
} from '../camfil-product-form-dialog-cam-card/camfil-product-form-dialog-cam-card.component';
import {
  CamfilProductFormDialogComponent,
  CamfilProductFormDialogComponentData,
  CamfilProductFormDialogComponentResult,
} from '../camfil-product-form-dialog/camfil-product-form-dialog.component';

// eslint-disable-next-line unicorn/no-null
function closestPreviousValue(value: null | number, min: number, max: number, step: number, zero: boolean): number {
  // eslint-disable-next-line unicorn/no-null
  if (value === null) {
    return zero ? 0 : min;
  }
  const rest = value % step;
  const centered = value - rest;
  const previous = centered - step;
  if (previous <= 0 && zero) {
    return 0;
  }
  if (previous < 0) {
    return 0;
  }
  if (previous > max) {
    return max;
  }
  return previous;
}

function closestNextValue(value: null | number, min: number, max: number, step: number, zero: boolean): number {
  if (value === undefined) {
    return zero ? 0 : min;
  }
  const rest = value % step;
  const centered = value - rest;
  const next = centered + step;
  if (next <= 0 && zero) {
    return 0;
  }
  if (next < min) {
    return min;
  }
  if (next > max) {
    return max;
  }
  return next;
}

export enum ActionType {
  Default,
  AddToBasket,
  AddToCamCard,
}

enum QuantityChangeType {
  Reset,
  Increase,
  Decrease,
}

enum ButtonType {
  Normal,
  Fake,
  None,
}

/*
  Originaly Intershop support retail sets and provide special label `product.add_to_cart.retail_set.link`.
  But retails sets were broken before upgrade, therefore we have single label for add to cart.

  Missing translations:
    camfil.modal.addNewProduct.error.quantity.type - manfolmed value
    product.quantity.increase.text - + button
    product.quantity.decrease.text - - button

  There is case when showAddToCamCardButtonForNonLoggedInUser && showAddToCartButtonForNonLoggedInUser are both false, there is no point of showing component.
*/
@Component({
  selector: 'camfil-product-form',
  templateUrl: './camfil-product-form.component.html',
  styleUrls: ['./camfil-product-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CamfilProductFormComponent implements OnInit, OnChanges {
  ACTION_TYPE = ActionType;
  BUTTON_TYPE = ButtonType;
  /* Must be set before ngOnInit. */

  @Input() initialQuantity = 0;
  @Input() initialMinQuantity: undefined | number;
  @Input() initialMaxQuantity: undefined | number;
  @Input() customActions: undefined | TemplateRef<unknown>;
  @Input() density: undefined | 'default' | 'compact' = 'default';
  @Input() addToCartOnly = false;

  quantityControl: FormControl<number>;
  form: FormGroup;
  cantIncrease$: Observable<boolean>;
  cantDecrease$: Observable<boolean>;
  maxQty$: Observable<number>;
  addToCartButtonType$: Observable<ButtonType>;
  addToCamCardButtonType$: Observable<ButtonType>;
  fakeButtonReturnParams: { returnUrl: string; messageKey: string };

  addToCartButtonDisabled$ = new BehaviorSubject(false);
  addToCamCardButtonDisabled$ = new BehaviorSubject(false);

  private initialMinQuantity$ = new BehaviorSubject<number | undefined>(undefined);
  private initialMaxQuantity$ = new BehaviorSubject<number | undefined>(undefined);

  private change$ = new Subject<QuantityChangeType>();
  private destroyRef = inject(DestroyRef);

  constructor(
    private accountFacade: AccountFacade,
    private productContext: ProductContextFacade,
    private camfilDialogFacade: CamfilDialogFacade,
    private camfilConfigurationFacade: CamfilConfigurationFacade,
    private activatedRoute: ActivatedRoute,
    private queueFacade: CamfilQueueFacade
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.initialMinQuantity) {
      this.initialMinQuantity$.next(changes.initialMinQuantity.currentValue);
    }
    if (changes.initialMaxQuantity) {
      this.initialMaxQuantity$.next(changes.initialMaxQuantity.currentValue);
    }
  }

  ngOnInit(): void {
    this.quantityControl = new FormControl<number>(this.initialQuantity || 0);

    this.form = new FormGroup({
      quantity: this.quantityControl,
    });

    this.queueFacade.isCreatingOrder$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(isCreatingOrder => {
      this.addToCartButtonDisabled$.next(isCreatingOrder);
    });

    const productMinQuantity$ = combineLatest([
      this.initialMinQuantity$,
      this.productContext.select('minQuantity'),
    ]).pipe(map(([initial, productMin]) => (initial !== undefined ? initial : productMin)));

    const productMaxQuantity$ = combineLatest([
      this.initialMaxQuantity$,
      this.productContext.select('maxQuantity'),
    ]).pipe(map(([initial, productMax]) => (initial !== undefined ? initial : productMax)));

    const product$ = this.productContext.select('product');
    const quantityValue$ = this.quantityControl.valueChanges.pipe(startWith(this.quantityControl.value));
    const productStepQuantity$ = this.productContext.select('stepQuantity');
    const productAllowZeroQuantity$ = this.productContext.select('allowZeroQuantity');
    const productQuantityData$ = combineLatest([
      quantityValue$,
      productMinQuantity$,
      productMaxQuantity$,
      productStepQuantity$,
      productAllowZeroQuantity$,
    ]);
    combineLatest([productMinQuantity$, productMaxQuantity$, productStepQuantity$, productAllowZeroQuantity$])
      .pipe(
        /* That the exact type defintion from angular/core ! */
        /* eslint-disable unicorn/no-null */
        map(([min, max, step, zero]) => (control: AbstractControl): ValidationErrors | null => {
          /* eslint-enable unicorn/no-null */
          const errors: ValidationErrors = {};
          const value = control.value;
          if (!Number.isInteger(value)) {
            errors.type = true;
            return errors;
          }
          if (value < min && !(value === 0 && zero)) {
            errors.min = true;
          }
          if (value > max) {
            errors.max = true;
          }
          if (value % step !== 0) {
            errors.step = { step };
          }

          return errors;
        }),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(validator => {
        this.quantityControl.setValidators(validator);
      });
    this.cantIncrease$ = productQuantityData$.pipe(
      map(([quantity, min, max, step, zero]) => closestNextValue(quantity, min, max, step, zero) === quantity)
    );
    this.cantDecrease$ = productQuantityData$.pipe(
      map(([quantity, min, max, step, zero]) => closestPreviousValue(quantity, min, max, step, zero) === quantity)
    );
    this.maxQty$ = productQuantityData$.pipe(map(([_, __, max]) => max));
    this.change$
      .pipe(withLatestFrom(productQuantityData$), takeUntilDestroyed(this.destroyRef))
      .subscribe(([change, [quantity, min, max, step, zero]]) => {
        switch (change) {
          case QuantityChangeType.Increase:
            this.quantityControl.patchValue(closestNextValue(quantity, min, max, step, zero));
            break;
          case QuantityChangeType.Decrease:
            this.quantityControl.patchValue(closestPreviousValue(quantity, min, max, step, zero));
            break;
          case QuantityChangeType.Reset:
            break;
        }
      });
    product$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      this.change$.next(QuantityChangeType.Reset);
    });
    this.addToCartButtonType$ = combineLatest([
      this.accountFacade.isLoggedIn$,
      this.camfilConfigurationFacade.isEnabled$('showAddToCartButtonForNonLoggedInUser'),
    ]).pipe(
      map(([isLoggedIn, showAddToCartButtonForNonLoggedInUser]) => {
        if (isLoggedIn) {
          return ButtonType.Normal;
        }
        if (showAddToCartButtonForNonLoggedInUser) {
          return ButtonType.Fake;
        }
        return ButtonType.None;
      })
    );
    this.addToCamCardButtonType$ = combineLatest([
      this.accountFacade.isLoggedIn$,
      this.camfilConfigurationFacade.isEnabled$('showAddToCamCardButtonForNonLoggedInUser'),
    ]).pipe(
      map(([isLoggedIn, showAddToCamCardButtonForNonLoggedInUser]) => {
        if (isLoggedIn) {
          return ButtonType.Normal;
        }
        if (showAddToCamCardButtonForNonLoggedInUser) {
          return ButtonType.Fake;
        }
        return ButtonType.None;
      })
    );
    this.fakeButtonReturnParams = {
      returnUrl: this.activatedRoute.snapshot.url.join('/'),
      messageKey: 'login_to_continue',
    };
  }

  onIncrease(): void {
    this.change$.next(QuantityChangeType.Increase);
  }

  onDecrease(): void {
    this.change$.next(QuantityChangeType.Decrease);
  }

  onSubmit(actionType: ActionType): void {
    if (!this.form.valid && !this.addToCartOnly) {
      return;
    }
    switch (actionType) {
      case ActionType.AddToBasket:
        combineLatest([this.productContext.select('sku'), this.productContext.select('product', 'name')])
          .pipe(takeUntilDestroyed(this.destroyRef))
          .subscribe(([sku, name]) => {
            this.camfilDialogFacade.open<
              CamfilProductFormDialogComponent,
              CamfilProductFormDialogComponentData,
              CamfilProductFormDialogComponentResult
            >(CamfilProductFormDialogComponent, {
              data: [
                {
                  name,
                  sku,
                  quantity: this.quantityControl.value,
                },
              ],
            });
          });
        break;
      case ActionType.AddToCamCard:
        combineLatest([this.productContext.select('sku'), this.productContext.select('product', 'name')])
          .pipe(takeUntilDestroyed(this.destroyRef))
          .subscribe(([sku, name]) => {
            this.camfilDialogFacade.open<
              CamfilProductFormDialogCamCardComponent,
              CamfilProductFormDialogCamCardComponentData,
              CamfilProductFormDialogCamCardComponentResult
            >(CamfilProductFormDialogCamCardComponent, {
              data: [
                {
                  name,
                  sku,
                  quantity: this.quantityControl.value,
                },
              ],
            });
          });
        break;
      case ActionType.Default:
        /* Normally we would call same action ass AddToBasket but due AHU custom code we are better with disabling this. */
        break;
    }
  }
}
