/* eslint-disable ish-custom-rules/ban-imports-file-pattern */
import { APP_BASE_HREF, DOCUMENT } from '@angular/common';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CAMFIL_UI_CONFIG, CamfilAppearanceModule } from 'camfil-core/appearance/camfil-appearance.module';
import { CAMFIL_APP_FULL_BASE_URL } from 'camfil-core/configurations/camfil-injection-keys';
import { CamRequisitionManagementFacade } from 'camfil-core/facades/cam-requisition-management.facade';
import { CamfilConfigurationFacade } from 'camfil-core/facades/camfil-configuration.facade';
import { CamfilLoginOnBehalfIdentityProviderModule } from 'camfil-core/identity-provider/camfil-login-on-behalf-identity-provider.module';
import { CamfilMaterialModule } from 'camfil-core/material/camfil-material.module';
import { CamfilPrintModule } from 'camfil-core/print/camfil-print.module';
import { CamfilFilterService } from 'camfil-core/services/camfil-filter/camfil-filter.service';
import { CamfilProductsService } from 'camfil-core/services/camfil-products/camfil-products.service';
import { CamfilToastrService } from 'camfil-core/services/camfil-toastr/camfil-toastr.service';
import { fullBaseUrlFactory } from 'camfil-core/services/configuration/camfil-configuration.service';
import { CamfilStoreModule } from 'camfil-core/store/camfil-store.module';
import { CamfilFilterEffects } from 'camfil-core/store/shopping/camfil-filter/camfil-filter.effects';
import { CamfilProductListingEffects } from 'camfil-core/store/shopping/camfil-product-listing/camfil-product-listing.effects';
import { CamfilProductsEffects } from 'camfil-core/store/shopping/camfil-products/camfil-products.effects';
import { CamfilSearchEffects } from 'camfil-core/store/shopping/camfil-search/camfil-search.effects';
import { CamfilFilterNavigationMapper } from 'camfil-models/camfil-filter-navigation/camfil-filter-navigation.mapper';
import { CamfilProductsMapper } from 'camfil-models/camfil-product/camfil-product.mapper';
import { ToastrService } from 'ngx-toastr';

import { USER_REGISTRATION_LOGIN_TYPE } from 'ish-core/configurations/injection-keys';
import { FilterNavigationMapper } from 'ish-core/models/filter-navigation/filter-navigation.mapper';
import { ProductMapper } from 'ish-core/models/product/product.mapper';
import { FilterService } from 'ish-core/services/filter/filter.service';
import { ProductsService } from 'ish-core/services/products/products.service';
import { FilterEffects } from 'ish-core/store/shopping/filter/filter.effects';
import { ProductListingEffects } from 'ish-core/store/shopping/product-listing/product-listing.effects';
import { ProductsEffects } from 'ish-core/store/shopping/products/products.effects';
import { SearchEffects } from 'ish-core/store/shopping/search/search.effects';

import { RequisitionManagementFacade } from '../../../../requisition-management/src/app/facades/requisition-management.facade';

@NgModule({
  /* eslint-disable @angular-eslint/sort-ngmodule-metadata-arrays */
  imports: [
    CamfilLoginOnBehalfIdentityProviderModule,
    CamfilPrintModule,
    // CamfilQueueModule,
    CamfilStoreModule,
    CamfilAppearanceModule,
    CamfilMaterialModule,
  ],
  /* eslint-enable @angular-eslint/sort-ngmodule-metadata-arrays */
  providers: [
    { provide: FilterEffects, useClass: CamfilFilterEffects },
    { provide: FilterNavigationMapper, useClass: CamfilFilterNavigationMapper },
    { provide: FilterService, useClass: CamfilFilterService },
    { provide: ProductListingEffects, useClass: CamfilProductListingEffects },
    { provide: ProductMapper, useClass: CamfilProductsMapper },
    { provide: ProductsEffects, useClass: CamfilProductsEffects },
    { provide: ProductsService, useClass: CamfilProductsService },
    { provide: RequisitionManagementFacade, useClass: CamRequisitionManagementFacade },
    { provide: SearchEffects, useClass: CamfilSearchEffects },
    { provide: ToastrService, useClass: CamfilToastrService },
    { provide: USER_REGISTRATION_LOGIN_TYPE, useValue: 'username' }, // email | username
    {
      provide: CAMFIL_UI_CONFIG,
      useFactory: (camfilConfigurationFacade: CamfilConfigurationFacade) => camfilConfigurationFacade.uiConfig$,
      deps: [CamfilConfigurationFacade],
    },
    {
      provide: CAMFIL_APP_FULL_BASE_URL,
      useFactory: fullBaseUrlFactory,
      deps: [APP_BASE_HREF, DOCUMENT],
    },
  ],
})
export class CamfilCoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CamfilCoreModule) {
    if (parentModule) {
      throw new Error('CamfilCoreModule is already loaded. Import it in the CamfilAppModule only');
    }
  }
}
