import { CamCardData } from './cam-card.interface';
import { CamCard, CamCardItem, CamCardMaintenanceStatus, CamCardProductItem } from './cam-card.model';

export class CamCardHelper {
  static maintenance(camCard: CamCard, arr: CamCardMaintenanceStatus[]) {
    return arr.includes(camCard.maintenanceStatus as CamCardMaintenanceStatus);
  }

  static getRealCamCards(camCards: CamCard[]) {
    return camCards?.filter(camCard => !camCard.transient);
  }

  static getItems(items: CamCardItem[], onlyAvailable: boolean) {
    return items?.filter(item => !onlyAvailable || (onlyAvailable && item.product.available)) || [];
  }

  static getInvalidItems(items: CamCardItem[]) {
    return items?.filter(item => !item.product.available) || [];
  }

  static getCamCardItems(camCard: CamCard, onlyAvailable = false) {
    return camCard.subCamCards?.reduce(
      (acc, { camCardItems }) => [...acc, ...CamCardHelper.getItems(camCardItems, onlyAvailable)],
      CamCardHelper.getItems(camCard.camCardItems, onlyAvailable)
    );
  }

  static getCamCardItemsIds(camCard: CamCard, onlyAvailable = false) {
    return CamCardHelper.getCamCardItems(camCard, onlyAvailable)?.map(({ id }) => id) || [];
  }

  static getCamCardSkus(camCard: CamCard, onlyMainSkus = false): string[] {
    const getSkusRecursively = (card: CamCard, acc: string[] = []): string[] => {
      const mainSkus = card.camCardItems?.map(({ product }) => product.sku) || [];
      acc.push(...mainSkus);

      if (card.subCamCards && !onlyMainSkus) {
        card.subCamCards.forEach(subCard => getSkusRecursively(subCard, acc));
      }

      return acc;
    };

    const allSkus = getSkusRecursively(camCard);

    return [...new Set(allSkus)];
  }

  static handleCamCardsToGetCustomerPrice(camCards: CamCard[]) {
    return camCards.reduce((list: { [key: string]: string[] }, item) => {
      const customerId = item.customerId;
      if (list[customerId]) {
        list[customerId].push(...CamCardHelper.getCamCardSkus(item));
      }
      return {
        ...list,
        [customerId]: list[customerId]
          ? list[customerId].filter((it: string, i: number, arr: string[]) => arr.findIndex(el => el === it) === i)
          : CamCardHelper.getCamCardSkus(item),
      };
    }, {});
  }

  static ccSorter(cc: CamCardData[]) {
    return cc.sort((a, b) => {
      if (!a.position) {
        return 1;
      }
      if (!b.position) {
        return -1;
      }
      return Number(a.position) - Number(b.position);
    });
  }

  static resetPositions(cc: CamCard): CamCard {
    return {
      ...cc,
      camCardItems: cc.camCardItems?.map((el, i) => ({ ...el, position: i * 1000 })),
      subCamCards: cc.subCamCards?.map(sub => ({
        ...sub,
        camCardItems: sub.camCardItems?.map((el, i) => ({ ...el, position: i * 1000 })),
      })),
    };
  }

  static removeDeprecations(camCard: CamCard): CamCard {
    try {
      const { deliveryAddress } = camCard;
      if (!deliveryAddress) {
        return camCard;
      }
      // @ts-expect-error: street2 and street3 are deprecated
      // eslint-disable-next-line no-unused-vars
      const { street, street2, street3, ...rest } = deliveryAddress;
      return { ...camCard, deliveryAddress: { ...rest } };
    } catch (e) {
      console.error('We have an error in removeDeprecations', e);
    }

    return camCard;
  }

  // eslint-disable-next-line complexity
  static isSufficientlyLoaded(camCard: CamCard): boolean {
    if (!camCard) {
      return false;
    }

    // Check if necessary properties are present and defined
    const necessaryCamCardProperties: (keyof CamCard)[] = ['name', 'type', 'id'];

    if (!CamCardHelper.hasNecessaryProperties(camCard, necessaryCamCardProperties)) {
      return false;
    }

    return !(
      !CamCardHelper.hasValidCamCardItems(camCard.camCardItems) &&
      !camCard.subCamCards?.some(subCard => CamCardHelper.isSufficientlyLoaded(subCard))
    );
  }

  static verifyBasicCamCardsHaveSubsWithItems(withItems: CamCard[], basic: CamCard[]) {
    if (!withItems?.length) {
      return basic;
    }

    return basic.map(cc => {
      const subCamCards = withItems.find(({ id }) => cc.id === id)?.subCamCards;
      if (subCamCards) {
        return { ...cc, subCamCards };
      }
      return cc;
    });
  }

  static hasNecessaryProperties(camCard: CamCard, properties: (keyof CamCard)[]): boolean {
    return properties.every(prop => camCard[prop] !== undefined);
  }

  static hasValidCamCardItems(camCardItems: CamCardProductItem[] | undefined): boolean {
    if (!camCardItems || camCardItems.length === 0) {
      return false;
    }

    for (const item of camCardItems) {
      if (item.measurement?.valid === undefined) {
        return false;
      }
      if (item.product && item.product.name === undefined) {
        return false;
      }
    }

    return true;
  }
}
