import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  HostBinding,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  TemplateRef,
  inject,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { CamfilDialogFacade } from 'camfil-core/facades/camfil-dialog.facade';
import { CamfilAttributeGroupHelper } from 'camfil-models/camfil-attribute-group/camfil-attribute-group.helper';
import { CamfilAttributeGroupTypes } from 'camfil-models/camfil-attribute-group/camfil-attribute-group.types';
import { CamfilAttributeHelper } from 'camfil-models/camfil-attribute/attribute.helper';
import { Attribute } from 'camfil-models/camfil-attribute/attribute.model';
import { CamfilViewType } from 'camfil-models/camfil-viewtype/camfil-viewtype.types';
import {
  CamfilProductQuickViewDialogComponent,
  CamfilProductQuickViewDialogData,
} from 'camfil-shared/product/camfil-product-quick-view-dialog/camfil-product-quick-view-dialog.component';
import { Observable, map } from 'rxjs';
import { shareReplay, take } from 'rxjs/operators';

import { ProductContextFacade } from 'ish-core/facades/product-context.facade';
import { AttributeHelper } from 'ish-core/models/attribute/attribute.helper';
import { AttributeToStringPipe } from 'ish-core/models/attribute/attribute.pipe';
import { Category } from 'ish-core/models/category/category.model';
import { ProductView } from 'ish-core/models/product-view/product-view.model';
import { whenTruthy } from 'ish-core/utils/operators';

@Component({
  selector: 'camfil-product-item',
  templateUrl: './camfil-product-item.component.html',
  styleUrls: ['./camfil-product-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{ provide: ProductContextFacade, useClass: ProductContextFacade }],
})
export class CamfilProductItemComponent implements OnInit, OnChanges {
  @Input() category: Category;
  @Input() viewType: CamfilViewType = 'simple';
  @Input() initialQuantity = 0;
  @Input() initialMinQuantity: undefined | number;
  @Input() initialMaxQuantity: undefined | number;
  @Input() customFormActions: TemplateRef<unknown>;

  loading$: Observable<boolean>;
  product$: Observable<ProductView>;
  productURL$: Observable<string>;
  productName$: Observable<string>;
  productCategoryName$: Observable<string>;
  productAttributeDimensions$: Observable<string>;
  productAttributeIsoClassAndEfficiency$: Observable<undefined | Attribute>;
  productAttributeEuroventEnergyClass$: Observable<undefined | Attribute>;
  productAttributeFrameProduct$: Observable<undefined | Attribute>;
  productAttributePressureDrop$: Observable<undefined | Attribute>;
  productAttributeFilterbags$: Observable<undefined | Attribute>;

  @HostBinding('class') viewTypeClass: 'display-type-simple' | 'display-type-detailed' | 'display-type-grid' =
    'display-type-simple';

  private destroyRef = inject(DestroyRef);

  constructor(
    private context: ProductContextFacade,
    private camfilDialogFacade: CamfilDialogFacade,
    private attributeToStringPipe: AttributeToStringPipe
  ) {}

  ngOnInit(): void {
    this.loading$ = this.context.select('loading');
    this.product$ = this.context.select('product');
    this.productURL$ = this.context.select('productURL');
    this.productName$ = this.context.select('product', 'name');
    this.productCategoryName$ = this.product$.pipe(map(product => product?.defaultCategory?.name));
    this.productName$ = this.product$.pipe(map(product => product.name));
    const productAttributes$ = this.product$.pipe(
      map(product =>
        CamfilAttributeGroupHelper.getAttributesGroupByType(
          product,
          CamfilAttributeGroupTypes.ProductsListLabelAttributes
        )
      )
    );
    const selectAttribute = (name: string) =>
      map<Attribute[], undefined | Attribute>(attributes =>
        AttributeHelper.getAttributeByAttributeName(attributes, name)
      );
    this.productAttributeDimensions$ = productAttributes$.pipe(
      whenTruthy(),
      map(attributes => {
        const measurements = ['Width', 'Height', 'Depth']
          .map(measurement => AttributeHelper.getAttributeByAttributeName(attributes, measurement))
          .filter(Boolean)
          .map(attribute => Number(this.attributeToStringPipe.transform(attribute)));

        if (measurements?.length <= 1) {
          // At least two dimensions are required to display the dimensions
          return;
        }

        return CamfilAttributeHelper.formatMeasurements(measurements);
      })
    );
    this.productAttributeIsoClassAndEfficiency$ = productAttributes$.pipe(selectAttribute('IsoClassAndEfficiency'));
    this.productAttributeEuroventEnergyClass$ = productAttributes$.pipe(
      selectAttribute('EuroventEnergyClass'),
      map(attribute => {
        if (!attribute || attribute.type === 'String' || attribute.value === 'None') {
          return;
        }
        return attribute;
      })
    );
    this.productAttributeFrameProduct$ = productAttributes$.pipe(selectAttribute('FrameProduct'));
    this.productAttributePressureDrop$ = productAttributes$.pipe(selectAttribute('PressureDrop'));
    this.productAttributeFilterbags$ = productAttributes$.pipe(
      selectAttribute('Filterbags'),
      map(attribute => {
        if (attribute === undefined || CamfilAttributeHelper.isZero(attribute)) {
          return;
        }
        return attribute;
      })
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('viewType' in changes) {
      switch (this.viewType) {
        case 'grid':
          this.viewTypeClass = 'display-type-grid';
          return;
        case 'detailed':
          this.viewTypeClass = 'display-type-detailed';
          return;
        default:
          this.viewTypeClass = 'display-type-simple';
          return;
      }
    }
  }

  showProductModal(): void {
    this.context
      .select('sku')
      .pipe(shareReplay(1), take(1), takeUntilDestroyed(this.destroyRef))
      .subscribe(sku => {
        this.camfilDialogFacade.open<CamfilProductQuickViewDialogComponent, CamfilProductQuickViewDialogData>(
          CamfilProductQuickViewDialogComponent,
          {
            data: sku,
          }
        );
      });
  }
}
