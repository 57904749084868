import { ValidationErrors } from '@angular/forms';
import { ConfigOption, FormlyExtension, FormlyFieldConfig } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';

// eslint-disable-next-line @typescript-eslint/ban-types
type TranslationInput = string | { key: string | string[]; interpolateParams?: Object };

function translateMessage(translateService: TranslateService, message: TranslationInput): string {
  if (typeof message === 'string') {
    return translateService.instant(message);
  } else if (typeof message === 'object' && message.key) {
    return translateService.instant(message.key, message.interpolateParams);
  }
  return '';
}

function translateMessageObservable(translateService: TranslateService, message: TranslationInput): Observable<string> {
  if (typeof message === 'string') {
    return translateService.stream(message);
  } else if (typeof message === 'object' && message.key) {
    return translateService.stream(message.key, message.interpolateParams);
  }
  return new Observable<string>();
}

function handleFunctionMessage(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  func: (error: any, field: FormlyFieldConfig) => string | Observable<string>,
  translateService: TranslateService
) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (error: any, field: FormlyFieldConfig) => {
    // eslint-disable-next-line rxjs/finnish
    const result = func(error, field);

    if (typeof result === 'string') {
      return translateService.get(result).pipe(switchMap(translatedValue => of(translatedValue)));
    } else if (result instanceof Observable) {
      return result.pipe(switchMap(str => translateService.get(str)));
    }

    return result;
  };
}

// eslint-disable-next-line complexity
function populate(field: FormlyFieldConfig, translateService: TranslateService) {
  const to = field.props || {};
  if (Array.isArray(to.options)) {
    const options = to.options;
    const labels = options.map(o => o.label).filter(label => !!label);
    if (labels.length > 0) {
      to.options = translateService
        .stream(labels)
        .pipe(map(translatedLabels => options.map(o => ({ ...o, label: translatedLabels[o.label] }))));
    }
  }

  if (to.options instanceof Observable) {
    to.options = to.options.pipe(
      tap(options => {
        if (options?.length) {
          const labels = options.map(o => o.label).filter(label => !!label);
          if (labels.length > 0) {
            to.options = translateService
              .stream(labels)
              .pipe(map(translatedLabels => options.map(o => ({ ...o, label: translatedLabels[o.label] }))));
          }
        }
      })
    );
  }

  const validators = field.validators || {};
  for (const [k, validator] of Object.entries(validators)) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const v = validator as any;
    if (v.message) {
      validators[k].message = translateMessage(translateService, v.message);
    }
  }

  field.expressions = {
    ...(field.expressions || {}),
    ...(to.label ? { 'props.label': translateMessageObservable(translateService, to.label) } : {}),
    ...(to.clearLabel ? { 'props.clearLabel': translateMessageObservable(translateService, to.clearLabel) } : {}),
    ...(to.description ? { 'props.description': translateMessageObservable(translateService, to.description) } : {}),
    ...(to.placeholder ? { 'props.placeholder': translateMessageObservable(translateService, to.placeholder) } : {}),
    ...(to.button ? { 'props.button': translateMessageObservable(translateService, to.button) } : {}),
    ...(to.hintStart ? { 'props.hintStart': translateMessageObservable(translateService, to.hintStart) } : {}),
    ...(to.hintEnd ? { 'props.hintEnd': translateMessageObservable(translateService, to.hintEnd) } : {}),
    ...(to.legend ? { 'props.legend': translateMessageObservable(translateService, to.legend) } : {}),
  };

  if (field.validation?.messages) {
    for (const [key, message] of Object.entries(field.validation.messages)) {
      if (typeof message === 'function') {
        field.validation.messages[key] = handleFunctionMessage(message, translateService);
      } else if (typeof message === 'string') {
        field.validation.messages[key] = handleFunctionMessage(() => of(message), translateService);
      } else {
        field.validation.messages[key] = handleFunctionMessage(() => of(''), translateService);
      }
    }
  }
}

// eslint-disable-next-line ish-custom-rules/require-formly-code-documentation
class CamfilFormlyTranslateExtension implements FormlyExtension {
  constructor(private translate: TranslateService) {}

  prePopulate(field: FormlyFieldConfig): void {
    populate(field, this.translate);
  }

  postPopulate(field: FormlyFieldConfig) {
    populate(field, this.translate);
  }
}

export function registerCamfilFormlyTranslateExtension(translate: TranslateService): ConfigOption {
  return {
    validationMessages: [
      {
        name: 'required',
        message() {
          return translate.stream('account.required_field.message');
        },
      },
      {
        /* @deprecated */
        name: 'maxlength',
        message(error: ValidationErrors) {
          return translate.instant('camfil.form.error.maxLength', { 0: error?.requiredLength });
        },
      },
      {
        name: 'maxLength',
        message(error: ValidationErrors) {
          return translate.instant('camfil.form.error.maxLength', { 0: error?.requiredLength });
        },
      },
    ],
    extensions: [
      {
        name: 'camfil-formly-translate',
        extension: new CamfilFormlyTranslateExtension(translate),
      },
    ],
  };
}
