import { createAction } from '@ngrx/store';

export interface CamfilQueueActionConfig {
  label: string;
  actionType: ReturnType<typeof createAction>;
  successActionType: ReturnType<typeof createAction>;
  failureActionType: ReturnType<typeof createAction>;
}

export enum CamfilQueueTaskStatus {
  Queued = 'queued',
  Processing = 'processing',
  Completed = 'completed',
  Failed = 'failed',
}

export interface CamfilQueueTask {
  id: string;
  actionType: string;
  actionPayload?: object;
  successActionType: string;
  failureActionType: string;
  status: CamfilQueueTaskStatus;
  enqueueTime: number;
}

export interface CamfilQueueStats {
  type: string;
  label: string;
  ids: string[];
  total: number;
  completed: number;
  failed: number;
  processed: number;
  processing: number;
  processingId: string;
  remaining: number;
  progress: number;
}
